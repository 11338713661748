/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-useless-escape */

import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { deleteTicketComment, reset, updateTicketComment } from '../../../../../redux/features/ticketComment/ticketCommentSlice';
import CommentInput from './CommentInput';



function SingleComment({ item }) {

    const { isTicketCommentUpdateSuccess } = useSelector(state => state.ticketComment)
    const { user } = useSelector(state => state.user)
    const [isShowTools, setIsShowTools] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [comment, setComment] = useState(item.description)
    const dispatch = useDispatch();

    const formattedComment = comment.replace(/\@\[(.*?)\]\(\d+\)/g, '<span class="mention-highlight">@$1</span>')

    const handelEdit = () => {
        setIsShowTools(false)
        setIsEdit(true)
    }

    const handelDelete = () => {
        dispatch(deleteTicketComment(item.id))
        setIsShowTools(false)
    }

    const habdelEditComment = () => {
        const ids = comment.match(/\((\d+)\)/g)?.map(id => +id.replace(/\(|\)/g, ''));
        if (comment) {
            dispatch(updateTicketComment({ id: item.id, description: comment, mentioned_users: ids }))
        }
    }

    const handelCancelEdit = () => {
        setIsEdit(false)
        setComment(item.description)
    }

    useEffect(() => {
        if (isTicketCommentUpdateSuccess) {
            setIsEdit(false)
            dispatch(reset())
        }
    }, [isTicketCommentUpdateSuccess])


    return (
        <div className="comment-section-comment-container">
            {!isEdit ? <>
                <div className='comment-section-upper-part'>
                    <div className='comment-section-upper-part-left'>
                        <img
                            // src="https://via.placeholder.com/34"
                            src={item.created_by.image}
                            alt="user-avatar"
                            className="comment-section-avatar"
                        />
                        <div>
                            <span className="comment-section-name">{item.created_by.first_name} {item.created_by.last_name}</span> <br />
                            <span className="comment-section-role">{item.created_by.role === "CliAdm" ? "Admin" : item.created_by.role}</span>
                        </div>
                    </div>
                    {
                        (user.id === item?.created_by?.id || user?.role === "CliAdm") &&
                        <div className='comment-section-upper-part-right'>
                        {isShowTools && <div className="tck-overlay" onClick={() => setIsShowTools(!isShowTools)} />}
                        <button type='button' onClick={() => setIsShowTools(!isShowTools)}>
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                        </button>
                        {isShowTools &&
                            <ul className="comment-tools">
                                {user.id === item?.created_by?.id && <li onClick={handelEdit}>Edit</li>}
                                {(user.id === item?.created_by?.id || user?.role === "CliAdm") &&
                                    <li onClick={handelDelete}>Delete</li>
                                }
                            </ul>
                        }
                    </div>}
                </div>
                <div className="comment-section-comment-content">
                    <div className="comment-section-comment-text">
                        <span className="comment-section-mention">
                            {parse(formattedComment)}
                        </span>
                    </div>
                    <div className="comment-section-timestamp">{moment(item.created_at).format("DD MMMM YYYY hh:mm A")}</div>
                </div>
            </>
                :
                <div className='comment-section-edit'>
                    {isEdit && <div className="tck-overlay" onClick={() => setIsEdit(!isEdit)} />}
                    <div className='comment-section-edit-wrapper'>
                        <CommentInput edit state={comment} setState={setComment} />
                        <div className='comment-section-edit-buttons'>
                            <button type='button' className='comment-section-edit-button'
                                onClick={habdelEditComment}
                            >Edit</button>
                            <button type='button' className='comment-section-edit-button'
                                onClick={handelCancelEdit}
                            >Cancel</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default SingleComment