import React from 'react'
import { useSelector } from 'react-redux';
import Card from '../../../../Ui/Card'
import classes from "./assetForm.module.css"
// import { SelectAssignedTo } from '../../../../components/select/select';
import { setAssetAssignedTo } from '../../../../redux/features/asset/assetSlice';
import { SelectPersons } from '../../../../components/select/select';

function AssetDepricationForm(
    { handelEditDepricationChange,
      handelChangeDepricationForm, 
      enableDeprication,
      assetDepricationForm 
  }) {
  const teams = useSelector(state => state.teams.teams.results)
  const { assetAssignedTo } = useSelector(state => state.asset)
  // const teamData  = teams?.filter(team =>  team.role !== "Requester")
  return ( 
    <div className={classes.assetForm}>
    <Card> 
     <div className={classes.assetInfo}>
     <div className={classes.assetDeprication}>
     <h3>Asset Depreciation Details</h3>
     <p><input type="checkbox" checked ={enableDeprication}
      id="depreciation_checkbox" onChange={handelEditDepricationChange} /> Track asset depreciation</p>
     </div>
     <div className={classes.changecontrolsGrid}>
   <div className={classes.controls}>
    <div className={classes.control}>
       <p>
       Salvage Price <span className='red'>*</span>
       </p>
     <input
     onChange={handelChangeDepricationForm} 
     value={assetDepricationForm?.salvage_price}
     name='salvage_price'
     disabled= {!enableDeprication} 
     type="text" placeholder='RM 0.00'  
     />
     </div>
     <div className={classes.control}>
       <p>
       Service Life <span className='red'>*</span>
       </p>
    <div className={classes.wraperSelect}>

     <input onChange={handelChangeDepricationForm} 
     value={assetDepricationForm.service_life}  
     type="number" 
     disabled= {!enableDeprication}
     name='service_life'
     />
     <select name='filter'
     placeholder='Select Intervals' 
     onChange={handelChangeDepricationForm} 
     value={assetDepricationForm.filter} 
     disabled= {!enableDeprication}>
      <option value="">Select Interval</option>
     {[
      {label : "Year" , value: "year"}, 
      {label : "Month" , value: "month"}, 
      {label : "Week" , value: "week"}, 
      ].map(opt=>(
        <option key={opt.value} value={opt.value}>{opt.label}</option>
      )
      )}
     </select>
     </div>
     </div>
     <div  className={classes.controlReactSelect} >
     {/* <SelectAssignedTo   disabled={!enableDeprication} teams = {teamData} /> */}
     <SelectPersons 
                  teams={teams}
                  assignedTo={assetAssignedTo}
                  setAssignedTo={setAssetAssignedTo}
                  stopped= {!enableDeprication}
                /> 
     </div>
     <div className={classes.control}>
       <p>
       Depreciation Start Date *
       </p>
     <input  name='start_date' 
     onChange={handelChangeDepricationForm} 
     value={assetDepricationForm.start_date} 
     type="date" 
     disabled= {!enableDeprication}  />
     </div>
     </div>
   </div> 
   </div>
   </Card>
 </div>
  )
}

export default AssetDepricationForm



