import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getAssetCategoriesByAuthentication,
  closeAssetCategoryModalCreate,
  closeAssetCategoryModalUpdate,
  closeAssetCategoryModalDelete,
  openAssetCategoryModalCreate,
  openAssetCategoryModalUpdate,
  openAssetCategoryModalDelete,
  setAssetCategory,
  reset as resetAssetCategory,
  createAssetCategory,
  updateAssetCategory,
  deleteAssetCategory,
} from '../../../redux/features/assetCategory/assetCategorySlice';
import {
  getAssetStatusByAuthentication,
  createAssetStatus,
  closeAssetStatusModalCreate,
  openAssetStatusModalCreate,
  closeAssetStatusModalDelete,
  openAssetStatusModalDelete,
  setAssetStatus,
  updateAssetStatus,
  deleteAssetStatus,
  closeAssetStatusModalUpdate,
  openAssetStatusModalUpdate,
  reset as resetAssetStatus,
} from '../../../redux/features/assetStatus/assetStatusSlice';
import PopUpDeleteModal from '../../popUpModals/editCategory/popUpDeleteModal';
import PopUpEditModal from '../../popUpModals/editCategory/popUpEditModal';
import PopUpAddModal from '../../popUpModals/editCategory/popUpAddModal';
import { setAssetType } from '../../../redux/features/editCategory/editCategorySlice';
import { openAssetTypeModalDelete , setAssetType as AsType, closeAssetTypeModalDelete, deleteAssetType, openAssetTypeModalUpdate,
   updateAssetType, closeAssetTypeModalUpdate, closeAssetTypeModalCreate, createAssetType ,  reset as resetAssetType, openAssetTypeModalCreate, getAssetTypesByAuthentication,

  } from '../../../redux/features/assetType/assetTypeSlice';

export default function AssetsTab () {
  const {
    assetCategories,
    assetCategoryModalCreate,
    assetCategoryModalUpdate,
    assetCategoryModalDelete,
    assetCategory,
    isAssetCategoryCreateError,
    isAssetCategoryCreateSuccess,
    isAssetCategoryUpdateError,
    isAssetCategoryUpdateLoading,
    isAssetCategoryUpdateSuccess,
    isAssetCategoryDeleteError,
    isAssetCategoryDeleteLoading,
    isAssetCategoryDeleteSuccess,
  } = useSelector(state => state.assetCategory);
  const {
    assetStatusModalCreate,
    assetStatusModalUpdate,
    assetStatusModalDelete,
    assetStatus,
    assetStatuses,
    isAssetStatusCreateError,
    isAssetStatusCreateSuccess,
    isAssetStatusUpdateError,
    isAssetStatusUpdateLoading,
    isAssetStatusUpdateSuccess,
    isAssetStatusDeleteError,
    isAssetStatusDeleteLoading,
    isAssetStatusDeleteSuccess,
  } = useSelector(state => state.assetStatus);
  const {
    assetTypes,
    assetTypeModalCreate,
    assetTypeModalUpdate,
    assetTypeModalDelete,
    // assetType,
    isAssetTypeCreateError,
    isAssetTypeCreateSuccess,
    isAssetTypeUpdateError,
    isAssetTypeUpdateLoading,
    isAssetTypeUpdateSuccess,
    isAssetTypeDeleteError,
    isAssetTypeDeleteLoading,
    isAssetTypeDeleteSuccess,
  } = useSelector(state => state.assetType);
  const assetTypeObject = useSelector(state => state.assetType.assetType);
  
  const { assetType } = useSelector(state => state.editCategory);
  const dispatch = useDispatch();
  const [assetCategoryData, setAssetCategoryData] = useState(null);
  const [assetStatusData, setAssetStatusData] = useState(null);
  const [assetTypeData, setAssetTypeData] = useState(null);

  const handleConfirm = (id, type) => {
    if (type === 'category') {
      dispatch(openAssetCategoryModalDelete());
      dispatch(setAssetCategory(id));
    }
    if (type === 'status') {
      dispatch(openAssetStatusModalDelete());
      dispatch(setAssetStatus(id));
    }
    if (type === 'type') {
      dispatch(openAssetTypeModalDelete());
      dispatch(AsType(id));
    }
  };
  const handleDelete = () => {
    if (assetType === 'category') {
      dispatch(deleteAssetCategory(assetCategory));
      dispatch(closeAssetCategoryModalDelete());
    } else if (assetType === 'status') {
      dispatch(deleteAssetStatus(assetStatus));
      dispatch(closeAssetStatusModalDelete());
    }
    else if (assetType === 'type') {
      dispatch(deleteAssetType(assetTypeObject));
      dispatch(closeAssetTypeModalDelete());
    }
  };
  const handleEdit = (data, type) => {
    if (type === 'category') {
      dispatch(openAssetCategoryModalUpdate());
      dispatch(setAssetCategory(data.id));
      setAssetCategoryData(data);
    }
    if (type === 'status') {
      dispatch(openAssetStatusModalUpdate());
      dispatch(setAssetStatus(data.id));
      setAssetStatusData(data);
    }
    if (type === 'type') {
      dispatch(openAssetTypeModalUpdate());
      dispatch(AsType(data.id));
      setAssetTypeData(data);
    }
  };

  const handleEditSave = () => {
    if (assetType === 'category') {
      dispatch(
        updateAssetCategory({
          id: assetCategory,
          name: assetCategoryData.name,
        }),
      );
      dispatch(closeAssetCategoryModalUpdate());
    }
    if (assetType === 'status') {
      dispatch(
        updateAssetStatus({
          id: assetStatus,
          name: assetStatusData.name,
        }),
      );
      dispatch(closeAssetCategoryModalUpdate());
    }
    if (assetType === 'type') {
      dispatch(
        updateAssetType({
          id: assetTypeObject,
          name: assetTypeData.name,
        }),
      );
      dispatch(closeAssetTypeModalUpdate());
    }
  };

  const handleCreate = type => {
    if (type === 'category') {
      dispatch(openAssetCategoryModalCreate());
    } else if (type === 'status') {
      dispatch(openAssetStatusModalCreate());
    } else if (type === 'type') {
      dispatch(openAssetTypeModalCreate());
    }
  };

  const handleCreateSave = () => {
    if (assetType === 'category') {
      dispatch(createAssetCategory({ name: assetCategoryData.name }));
      dispatch(closeAssetCategoryModalCreate());
      dispatch(resetAssetCategory());
    } else if (assetType === 'status') {
      dispatch(createAssetStatus({ name: assetStatusData.name }));
      dispatch(closeAssetStatusModalCreate());
      dispatch(resetAssetStatus());
    }
     else if (assetType === 'type') {
      dispatch(createAssetType({ name: assetTypeData.name }));
      dispatch(closeAssetTypeModalCreate());
      dispatch(resetAssetType());
    }
  };

  useEffect(() => {
    dispatch(getAssetCategoriesByAuthentication());
    dispatch(getAssetStatusByAuthentication());
    dispatch(getAssetTypesByAuthentication())
    dispatch(resetAssetCategory());
    dispatch(resetAssetStatus());
    dispatch(resetAssetType());
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetAssetCategory());
  }, [
    assetCategories,
    assetCategory,
    isAssetCategoryCreateError,
    isAssetCategoryCreateSuccess,
    isAssetCategoryUpdateError,
    isAssetCategoryUpdateLoading,
    isAssetCategoryUpdateSuccess,
    isAssetCategoryDeleteError,
    isAssetCategoryDeleteLoading,
    isAssetCategoryDeleteSuccess,
  ]);
  useEffect(() => {
    dispatch(resetAssetType());
  }, [
    assetTypes,
    assetTypeObject,
    isAssetTypeCreateError,
    isAssetTypeCreateSuccess,
    isAssetTypeUpdateError,
    isAssetTypeUpdateLoading,
    isAssetTypeUpdateSuccess,
    isAssetTypeDeleteError,
    isAssetTypeDeleteLoading,
    isAssetTypeDeleteSuccess,
  ]);
  useEffect(() => {
    dispatch(resetAssetStatus());
  }, [
    assetStatuses,
    assetStatus,
    isAssetStatusCreateError,
    isAssetStatusCreateSuccess,
    isAssetStatusUpdateError,
    isAssetStatusUpdateLoading,
    isAssetStatusUpdateSuccess,
    isAssetStatusDeleteError,
    isAssetStatusDeleteLoading,
    isAssetStatusDeleteSuccess,
  ]);

  function displayToast () {
    if (isAssetCategoryCreateSuccess) {
      toast.success('Asset Category Created Successfully', {
        toastId: 'assetCategoryCreateSuccess',
      });
    }
    if (isAssetCategoryCreateError) {
      toast.error('Asset Category Creation Failed', {
        toastId: 'assetCategoryCreateError',
      });
    }
    if (isAssetCategoryUpdateSuccess) {
      toast.success('Asset Category Updated Successfully', {
        toastId: 'assetCategoryUpdateSuccess',
      });
    }
    if (isAssetCategoryUpdateError) {
      toast.error('Asset Category Update Failed', {
        toastId: 'assetCategoryUpdateError',
      });
    }
    if (isAssetCategoryDeleteSuccess) {
      toast.success('Asset Category Deleted Successfully', {
        toastId: 'assetCategoryDeleteSuccess',
      });
    }
    if (isAssetCategoryDeleteError) {
      toast.error('Asset Category Delete Failed', {
        toastId: 'assetCategoryDeleteError',
      });
    }
    if (isAssetTypeCreateSuccess) {
      toast.success('Asset Type Created Successfully', {
        toastId: 'assetTypeCreateSuccess',
      });
    }
    if (isAssetTypeCreateError) {
      toast.error('Asset Type Creation Failed', {
        toastId: 'assetTypeCreateError',
      });
    }
    if (isAssetTypeUpdateSuccess) {
      toast.success('Asset Type Updated Successfully', {
        toastId: 'assetTypeUpdateSuccess',
      });
    }
    if (isAssetTypeUpdateError) {
      toast.error('Asset Type Update Failed', {
        toastId: 'assetTypeUpdateError',
      });
    }
    if (isAssetTypeDeleteSuccess) {
      toast.success('Asset Type Deleted Successfully', {
        toastId: 'assetTypeDeleteSuccess',
      });
    }
    if (isAssetTypeDeleteError) {
      toast.error('Asset Type Delete Failed', {
        toastId: 'assetTypeDeleteError',
      });
    }
    if (isAssetStatusCreateSuccess) {
      toast.success('Asset Status Created Successfully', {
        toastId: 'assetStatusCreateSuccess',
      });
    }
    if (isAssetStatusCreateError) {
      toast.error('Asset Status Creation Failed', {
        toastId: 'assetStatusCreateError',
      });
    }
    if (isAssetStatusUpdateSuccess) {
      toast.success('Asset Status Updated Successfully', {
        toastId: 'assetStatusUpdateSuccess',
      });
    }
    if (isAssetStatusUpdateError) {
      toast.error('Asset Status Update Failed', {
        toastId: 'assetStatusUpdateError',
      });
    }
    if (isAssetStatusDeleteSuccess) {
      toast.success('Asset Status Deleted Successfully', {
        toastId: 'assetStatusDeleteSuccess',
      });
    }
    if (isAssetStatusDeleteError) {
      toast.error('Asset Status Delete Failed', {
        toastId: 'assetStatusDeleteError',
      });
    }
  }
  return isAssetCategoryDeleteLoading ||
    isAssetCategoryUpdateLoading ||
    isAssetStatusDeleteLoading ||
    isAssetStatusUpdateLoading ||
    isAssetTypeDeleteLoading ||
    isAssetTypeUpdateLoading ? (
    <div className="loader" />
  ) : (
    <>
      {displayToast()}
      {assetCategoryModalUpdate && (
        <PopUpEditModal
          handleClose={() => dispatch(closeAssetCategoryModalUpdate())}
          showModal={assetCategoryModalUpdate}
          handleSave={handleEditSave}
          modalTitle="Edit Asset Category"
          primaryButtonText="Save Changes"
          modalBodyTextInput={assetCategoryData.name}
          value={assetCategoryData && assetCategoryData.name}
          onChange={e => {
            setAssetCategoryData({
              ...assetCategoryData,
              name: e.target.value,
            });
          }}
        />
      )}
      {assetCategoryModalDelete && (
        <PopUpDeleteModal
          showModal={assetCategoryModalDelete}
          handleClose={() => dispatch(closeAssetCategoryModalDelete())}
          handleConfirm={handleDelete}
          modalTitle="Delete Asset Category"
          modalBodyText="Are you sure you want to delete this Asset Category?"
        />
      )}
      {assetCategoryModalCreate && (
        <PopUpAddModal
          showModal={assetCategoryModalCreate}
          handleCreateSave={handleCreateSave}
          handleClose={() => dispatch(closeAssetCategoryModalCreate())}
          modalTitle="Add Asset Category"
          primaryButtonText="Create Asset Category"
          value={assetCategoryData && assetCategoryData.name}
          onChange={e => {
            setAssetCategoryData({
              ...assetCategoryData,
              name: e.target.value,
            });
          }}
        >
          <div className="col">
            <div className="row">
              <input type="hidden" id="inventory_id" value="" />
              <span className="font-weight-normal">
                Name <span className="font-danger m-0">*</span>
              </span>
              <input
                name="inventory_name"
                type="text"
                className="form-control mb-2"
                placeholder="Enter asset category name"
                // value={assetCategoryData?.name}
                onChange={e =>
                  setAssetCategoryData({
                    ...assetCategoryData,
                    name: e.target.value,
                  })
                }
              />

              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_name_error" />
              </div>
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_error_msg" />
              </div>
            </div>
          </div>
        </PopUpAddModal>
      )}
      {assetStatusModalUpdate && (
        <PopUpEditModal
          handleClose={() => dispatch(closeAssetStatusModalUpdate())}
          showModal={assetStatusModalUpdate}
          handleSave={handleEditSave}
          modalTitle="Edit Asset Status"
          primaryButtonText="Save Changes"
          modalBodyTextInput={assetStatusData.name}
          value={assetStatusData && assetStatusData.name}
          onChange={e => {
            setAssetStatusData({
              ...assetStatusData,
              name: e.target.value,
            });
          }}
        />
      )}
      {assetStatusModalDelete && (
        <PopUpDeleteModal
          showModal={assetStatusModalDelete}
          handleClose={() => dispatch(closeAssetStatusModalDelete())}
          handleConfirm={handleDelete}
          modalTitle="Delete Asset Status"
          modalBodyText="Are you sure you want to delete this Asset Status?"
        />
      )}
      {assetType === 'status' && assetStatusModalCreate && (
        <PopUpAddModal
          showModal={assetStatusModalCreate}
          handleCreateSave={handleCreateSave}
          handleClose={() => dispatch(closeAssetStatusModalCreate())}
          modalTitle="Add Asset Status"
          primaryButtonText="Create Asset Status"
          value={assetStatusData && assetStatusData.name}
          onChange={e => {
            setAssetStatusData({
              ...assetStatusData,
              name: e.target.value,
            });
          }}
        >
          <div className="col">
            <div className="row">
              <input type="hidden" id="inventory_id" value="" />
              <span className="font-weight-normal">
                Name <span className="font-danger m-0">*</span>
              </span>
              <input
                name="department_name"
                type="text"
                className="form-control mb-2"
                placeholder="Enter asset status name"
                // value={assetStatusData?.name}
                onChange={e =>
                  setAssetStatusData({
                    ...assetStatusData,
                    name: e.target.value,
                  })
                }
              />
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_name_error" />
              </div>
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_error_msg" />
              </div>
            </div>
          </div>
        </PopUpAddModal>
      )}
      {assetTypeModalUpdate && (
        <PopUpEditModal
          handleClose={() => dispatch(closeAssetTypeModalUpdate())}
          showModal={assetTypeModalUpdate}
          handleSave={handleEditSave}
          modalTitle="Edit Asset Type"
          primaryButtonText="Save Changes"
          modalBodyTextInput={assetTypeData.name}
          value={assetTypeData && assetTypeData.name}
          onChange={e => {
            setAssetTypeData({
              ...assetTypeData,
              name: e.target.value,
            });
          }}
        />
      )}
      {assetTypeModalDelete && (
        <PopUpDeleteModal
          showModal={assetTypeModalDelete}
          handleClose={() => dispatch(closeAssetTypeModalDelete())}
          handleConfirm={handleDelete}
          modalTitle="Delete Asset Type"
          modalBodyText="Are you sure you want to delete this Asset Type?"
        />
      )}
      {assetType === 'type' && assetTypeModalCreate && (
        <PopUpAddModal
          showModal={assetTypeModalCreate}
          handleCreateSave={handleCreateSave}
          handleClose={() => dispatch(closeAssetTypeModalCreate())}
          modalTitle="Add Asset Type"
          primaryButtonText="Create Asset Type"
          value={assetTypeData && assetTypeData.name}
          onChange={e => {
            setAssetTypeData({
              ...assetTypeData,
              name: e.target.value,
            });
          }}
        >
          <div className="col">
            <div className="row">
              <input type="hidden" id="inventory_id" value="" />
              <span className="font-weight-normal">
                Name <span className="font-danger m-0">*</span>
              </span>
              <input
                name="department_name"
                type="text"
                className="form-control mb-2"
                placeholder="Enter asset type name"
                // value={assetTypeData?.name}
                onChange={e =>
                  setAssetTypeData({
                    ...assetTypeData,
                    name: e.target.value,
                  })
                }
              />
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_name_error" />
              </div>
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_error_msg" />
              </div>
            </div>
          </div>
        </PopUpAddModal>
      )}
      
      <h3 className="card-title py-3 px-3 col-lg-12">Asset Fieldset</h3>
      <div className="card-body p-0 m-0" id="location-block">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title">
                  <span>
                    <b>Categories</b>
                    <i>(Option select)</i>
                  </span>
                </span>
                <div className="card-tools">
                  <button
                    onClick={() => {
                      dispatch(setAssetType('category'));
                      handleCreate('category');
                    }}
                    className="mr-2 cursor_pointer"
                    title="Add type"
                    data-target="#modal-add-category"
                    data-toggle="modal"
                    type="button"
                  >
                    Add Category
                  </button>
                </div>
              </div>
              <div className="card-body p-0" id="category-block">
                {assetCategories && assetCategories.length > 0 ? (
                  assetCategories?.map(category => (
                    <div
                      className="card edit-category-card-inline"
                      id="category-block-{{category.id}}"
                    >
                      <div className="card-header">
                        <span className="card-title edit-category-card-title-inline">
                          <span title="{{category.type}}">
                            <b id="category-value-{{category.id}}">{category.name}</b>
                          </span>
                        </span>
                        <div className="card-tools">
                          {category?.is_changeable ? (
                            <span>
                              <button
                                href=""
                                className="fa fa-edit m-1 cursor_pointer"
                                type="button"
                                onClick={() => {
                                  dispatch(setAssetType('category'));
                                  handleEdit(category, 'category');
                                }}
                              >
                                {' '}
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  dispatch(setAssetType('category'));
                                  handleConfirm(category.id, 'category');
                                }}
                              >
                                <i
                                  className="fa fa-trash m-1 font-danger cursor_pointer"
                                  title="Delete Category"
                                />
                              </button>
                            </span>
                          ) : (
                            <i className="fa fa-lock text-black" />
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="card edit-category-card-inline">
                    <div className="card-header">
                      <span className="card-title edit-category-title-inline">
                        <span title="No asset Category">
                          <b>No asset Category</b>
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                <input type="hidden" id="category-{{category.id}}" value="{{category.type}}" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title">
                  <span>
                    <b>Status</b>
                    <i>(Option select)</i>
                  </span>
                </span>
                <div className="card-tools">
                  <button
                    className="mr-2 cursor_pointer"
                    title="Add type"
                    data-target="#modal-add-status"
                    onClick={() => {
                      dispatch(setAssetType('status'));
                      handleCreate('status');
                    }}
                    data-toggle="modal"
                    type="button"
                  >
                    Add Status
                  </button>
                </div>
              </div>
              <div className="card-body p-0" id="status-block">
                {assetStatuses && assetStatuses.length > 0 ? (
                  assetStatuses?.map(status => (
                    <div className="card edit-category-card-inline" id="status-block-{{status.id}}">
                      <div className="card-header">
                        <span className="card-title edit-category-card-title-inline">
                          <span title="{{status.status}}">
                            <b id="wocategory-value-{{status.id}}">{status.name}</b>
                          </span>
                        </span>
                        {/* <div className="card-tools">
                            <i className="fa fa-lock m-1" title="Lock" />
                          </div> */}
                        <div className="card-tools">
                          {status?.is_changeable ? (
                            <span>
                              <button
                                className="fa fa-edit m-1 cursor_pointer"
                                title="Edit Status"
                                data-toggle="modal"
                                data-target="#modal-add-status"
                                type="button"
                                onClick={() => {
                                  dispatch(setAssetType('status'));
                                  handleEdit(status, 'status');
                                }}
                              >
                                {' '}
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  dispatch(setAssetType('status'));
                                  handleConfirm(status.id, 'status');
                                }}
                              >
                                <i
                                  className="fa fa-trash m-1 font-danger cursor_pointer"
                                  title="Delete Status"
                                  data-target="#modal-delete-status"
                                  data-toggle="modal"
                                />
                              </button>
                            </span>
                          ) : (
                            <i className="fa fa-lock text-black" />
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="card edit-category-card-inline">
                    <div className="card-header">
                      <span className="card-title edit-category-title-inline">
                        <span title="No asset Status">
                          <b>No asset Status</b>
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                <input type="hidden" id="status-{{status.id}}" value="{{status.status}}" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title">
                  <span>
                    <b>Type</b>
                    <i>(Option select)</i>
                  </span>
                </span>
                <div className="card-tools">
                  <button
                    className="mr-2 cursor_pointer"
                    title="Add type"
                    data-target="#modal-add-type"
                    onClick={() => {
                      dispatch(setAssetType('type'));
                      handleCreate('type');
                    }}
                    data-toggle="modal"
                    type="button"
                  >
                    Add Type
                  </button>
                </div>
              </div>
              <div className="card-body p-0" id="type-block">
                {assetTypes && assetTypes.length > 0 ? (
                  assetTypes?.map(type => (
                    <div className="card edit-category-card-inline" id="type-block-{{type.id}}">
                      <div className="card-header">
                        <span className="card-title edit-category-card-title-inline">
                          <span title="{{type.type}}">
                            <b id="wocategory-value-{{type.id}}">{type.name}</b>
                          </span>
                        </span>
                        {/* <div className="card-tools">
                            <i className="fa fa-lock m-1" title="Lock" />
                          </div> */}
                        <div className="card-tools">
                          {type?.is_changeable ? (
                            <span>
                              <button
                                className="fa fa-edit m-1 cursor_pointer"
                                title="Edit Status"
                                data-toggle="modal"
                                data-target="#modal-add-type"
                                type="button"
                                onClick={() => {
                                  dispatch(setAssetType('type'));
                                  handleEdit(type, 'type');
                                }}
                              >
                                {' '}
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  dispatch(setAssetType('type'));
                                  handleConfirm(type.id, 'type');
                                }}
                              >
                                <i
                                  className="fa fa-trash m-1 font-danger cursor_pointer"
                                  title="Delete Status"
                                  data-target="#modal-delete-type"
                                  data-toggle="modal"
                                />
                              </button>
                            </span>
                          ) : (
                            <i className="fa fa-lock text-black" />
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="card edit-category-card-inline">
                    <div className="card-header">
                      <span className="card-title edit-category-title-inline">
                        <span title="No asset Type">
                          <b>No Asset Type</b>
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                <input type="hidden" id="type-{{type.id}}" value="{{type.type}}" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
