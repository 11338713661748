/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */

import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  SelectAsset,
  SelectLocation,
  SelectPersons,
  SelectSite,
} from '../../../components/select/select';
import {
  setTab,
  setTitle,
  setDescription,
  setAssignedTo,
  setCategory,
  setPriority,
  setDueFinish,
  setUnit,
  setFrequency,
  setInterval,
  createMeter,
  setDueStart,
  addAttachment,
  setInitialReading,
  setReadingAttachmentFile,
  setIsScheduleEnabled
} from '../../../redux/features/meter/meterSlice';
import formatNumber from '../../../utils/formatNumber';
import { getMeterMeasurementByAuthentication } from '../../../redux/features/meterMeasurement/meterMeasurementSlice';

function AddMeterModal({ openModalCreate, closeModalCreate }) {
  const dispatch = useDispatch();

  const handelTabs = e => {
    dispatch(setTab(e.target.id));
  };

  const handelTitle = e => {
    dispatch(setTitle(e.target.value));
  };
  const handelPriority = e => {
    dispatch(setPriority(e.target.value));
  };
  const handelCategory = e => {
    dispatch(setCategory(e.target.value));
  };
  const handelDescription = e => {
    dispatch(setDescription(e.target.value));
  };
  const handleStart = e => {
    dispatch(setDueStart(e.target.value));
  };
  const handleDateTime = (e, startService) => {
    const selectedDateTime = new Date(e.target.value).getTime();
    const startDateTime = new Date(startService).getTime();
    if (selectedDateTime < startDateTime) {
      e.target.value = startService;
    }
    dispatch(setDueFinish(e.target.value));
  };
  const handleInterval = e => {
    dispatch(setInterval(e.target.value));
  };
  const handleFrequency = e => {
    dispatch(setFrequency(e.target.value));
  };
  const handleUnit = e => {
    dispatch(setUnit(e.target.value));
  };


  const handelInitialValue = e => {
    const input = e.target;
    const { value } = input;
    const formattedValue = formatNumber(value)[0];
    dispatch(setInitialReading(formattedValue));
  };
  const handelReadingAttachment = e => {
    dispatch(setReadingAttachmentFile(e.target.files[0]));
  };
  const handelFile = e => {
    dispatch(addAttachment([{ file: e.target.files[0] }]));
  };

  const assetListSearch = useSelector(state => state.asset?.assetListSearch);
  const meterCategory = useSelector(state => state.meterCategory?.meterCategories);
  const meterMeasurment = useSelector(state => state.meterMeasurement?.meterMeasurements);
  const teams = useSelector(state => state.teams?.teams?.results);
  const { locations, sublocations } = useSelector(state => state.location)
  const { selectedSite, idOfSite, idOfLocation, idOfAsset, assetInfo } = useSelector(
    state => state.selectOptions,
  );

  useEffect(() => {
    dispatch(getMeterMeasurementByAuthentication());
  }, [])


  const allSubLocations = [];

  const {
    tab,
    title,
    description,
    priority,
    category,
    due_finish,
    startService,
    assigned_to,
    interval,
    frequency,
    unit,
    attachments,
    reading_attachment_file,
    initial_reading,
    isScheduleEnabled
  } = useSelector(state => state.meter);

  const handelSave = async e => {
    const formData = new FormData();
    if (title) {
      formData.append('title', title);
    }
    if (description) {
      formData.append('description', description);
    }
    if (priority) {
      formData.append('priority', priority);
    }
    if (category) {
      formData.append('category', category);
    }
    if (idOfSite) {
      formData.append('location', idOfSite);
    }
    if (idOfLocation) {
      formData.append('sub_location', idOfLocation);
    }
    if (idOfAsset) {
      formData.append('asset', idOfAsset);
    }
    if (assigned_to?.length > 0) {
      assigned_to?.forEach(ele => formData.append('assigned_to', ele?.value));
    }
    formData.append('enable_interval', isScheduleEnabled);
    if (isScheduleEnabled) {
      formData.append(
        'service_start_date',
        moment.utc(startService).format('YYYY-MM-DDTHH:mm:ss.SSZ'),
      );
      formData.append('service_end_date', moment.utc(due_finish).format('YYYY-MM-DDTHH:mm:ss.SSZ'));
      formData.append('interval', interval);
      formData.append('frequency', frequency);
    }
    formData.append('measurement_unit', unit);
    if (attachments?.length > 0) {
      for (let i = 0; i < attachments.length; i += 1) {
        if (attachments[i].file.type.includes('image')) {
          formData.append(`attachments[${i}][photo]`, attachments[i].file);
        } else {
          formData.append(`attachments[${i}][file]`, attachments[i].file);
        }
      }
    }
    if (reading_attachment_file) {
      formData.append('reading_attachment', reading_attachment_file);
    }
    formData.append('initial_reading', initial_reading);
    e.preventDefault();
    if (title && category && unit) {
      if (isScheduleEnabled) {
        if (!due_finish || !startService) {
          toast.warning('Please fill the required fields on Meter Schedule Tab', {
            toastId: 'CreatedEventWarning',
          });
          return
        }
      }
      if (!formatNumber(initial_reading)[1] && initial_reading) {
        toast.warning("Please write a correct Number on Initial Value field (write the decimal part or remove decimal point)", {
          toastId: 'CreatedEventWarninggg',
        });
      } else {
        await dispatch(createMeter(formData));
      }
    } else {
      toast.warning('Please fill the required fields and submit again', {
        toastId: 'CreatedEventWarning',
      });
    }
  };

  return (
    <Drawer
      anchor="right"
      open={openModalCreate}
      onClose={closeModalCreate}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '600px',
          height: '100%',
        },
      }}
    >
      <form className="h-100 w-100">
        <div className="h-100">
          <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">
            <div className="col-12 mb-2">
              <div className="row px-2">
                <div className="my-auto">
                  <b>Add Meter</b>
                </div>

                <button
                  onClick={closeModalCreate}
                  type="button"
                  className="btn secondary-btn ml-auto"
                  data-dismiss="modal"
                >
                  <div className="fas fa-times"></div>
                </button>
              </div>
            </div>

            <div className="row justify-content-start mt-auto col-lg-12 col-sm-12 ">
              <ul className="nav nav-pills" id="custom-tabs-two-tab" role="tablist">
                <li className="nav-item">
                  <span
                    onClick={handelTabs}
                    className={`nav-link ${tab === 'meter-tab' && 'active'} cursor-pointer`}
                    id="meter-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="custom-tabs-two-meter"
                    aria-selected="false"
                  >
                    Meter Details <label className="font-danger m-0">*</label>
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    onClick={handelTabs}
                    className={`nav-link ${tab === 'schedule-tab' && 'active'} cursor-pointer`}
                    id="schedule-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="custom-tabs-two-schedule"
                    aria-selected="false"
                  >
                    Meter Schedule <label className="font-danger m-0">*</label>
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    onClick={handelTabs}
                    className={`nav-link ${tab === 'attachment-tab' && 'active'} cursor-pointer`}
                    id="attachment-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="custom-tabs-two-attachment"
                    aria-selected="false"
                  >
                    Attachments
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="" style={{ minHeight: '78.5%' }}>
            <div className="tab-content col-lg-12 col-sm-12" id="custom-tabs-two-tabContent">
              {tab === 'meter-tab' && (
                <div
                  className="tab-pane fade active show"
                  id="meter-tab"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-two-meter-tab"
                >
                  <div className="card-body">
                    <div className="row pb-5">
                      <div className="col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal">
                          Meter Name <label className="font-danger m-0">*</label>
                        </label>
                        <input
                          required
                          name="meter_name"
                          type="text"
                          className="form-control mb-2"
                          placeholder="Meter name"
                          id="meter_name"
                          value={title}
                          onChange={handelTitle}
                        />
                        <div className="col-lg-12 col-sm-12 m-0">
                          <span className="font-danger" id="meter_error"></span>
                        </div>
                      </div>

                      <div className="col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal">Description</label>
                        <textarea
                          name="description"
                          className="form-control mb-2"
                          placeholder="Description"
                          id="description"
                          onChange={handelDescription}
                          value={description}
                        ></textarea>
                      </div>
                      <div className="col-lg-12 col-sm-12 pt-3">
                        <div id="ifAsset" className="col-12 p-0">
                          <label className="font-weight-normal">Assets</label>
                          <SelectAsset
                            width="100%"
                            setAssignedTo={setAssignedTo}
                            assetData={assetListSearch}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-sm-12 pt-3">
                        <div id="ifLocation" className="col-12 p-0">
                          <SelectSite locations={locations} assetInfo={assetInfo} width="100%" noStar />
                          {selectedSite && (
                            <div className="col-12 p-0" id="building_show">
                              <SelectLocation
                                assetInfo={assetInfo}
                                subLocation={sublocations?.results}
                                allSubLocations={allSubLocations}
                                width="100%"
                                noStar
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal">
                          Assigned to
                        </label>
                        <div className="col-12 p-0">
                          <SelectPersons
                            teams={teams}
                            assignedTo={assigned_to}
                            setAssignedTo={setAssignedTo}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal ">Priority</label>
                        <select
                          className="form-control select-dash custom-select2 mb-2"
                          name="priority2"
                          id="priority2"
                          value={priority}
                          onChange={handelPriority}
                        >
                          <option value="" disabled selected>
                            --SELECT--
                          </option>
                          {['High', 'Medium', 'Low'].map(val => (
                            <option value={val}>{val}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal">
                          Category<span className="font-danger">*</span>
                        </label>
                        <select
                          className="form-control select-dash custom-select2 mb-2"
                          name="category"
                          id="category2"
                          value={category}
                          onChange={handelCategory}
                        >
                          <option value="" disabled selected>
                            --SELECT--
                          </option>
                          {meterCategory &&
                            meterCategory.length > 0 &&
                            meterCategory.map(cat => <option value={cat.id}>{cat.name}</option>)}
                        </select>
                      </div>
                    </div>
                    <p className="font-danger float-center" id="code_error"></p>
                  </div>
                </div>
              )}

              {tab === 'schedule-tab' && (
                <div
                  id="schedule-tab"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-two-schedule-tab"
                >
                  <div className="card-body ">
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 pt-2 pb-2">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span >
                            <input type="checkbox" className='cost-status'
                              onChange={() => dispatch(setIsScheduleEnabled(!isScheduleEnabled))} checked={isScheduleEnabled}
                            />
                          </span>
                          <label className="font-weight-normal">Enable Schedule</label>
                        </div>
                      </div>
                      {isScheduleEnabled && <>
                        <div className=" col-lg-6 col-md-6 col-sm-12">
                          <label className="font-weight-normal">
                            Start Date <label className="font-danger m-0">*</label>
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control mb-2"
                            name="service_date"
                            id="meter_service_date"
                            placeholder="Start Date"
                            required
                            value={startService}
                            onChange={handleStart}
                          />
                        </div>
                        <div className=" col-lg-6 col-md-6 col-sm-12">
                          <label className="font-weight-normal">
                            End Date <label className="font-danger m-0">*</label>
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control mb-2"
                            name="service_date"
                            id="meter_service_date"
                            placeholder="Start Date"
                            required
                            value={due_finish}
                            onChange={e => handleDateTime(e, startService)}
                          // min={startService}
                          />
                        </div>
                        <div className=" col-lg-3 col-md-3 col-sm-4">
                          <label className="font-weight-normal">
                            Interval <label className="font-danger m-0">*</label>
                            <i className="far fa-question-circle small-font gcolor">
                              <span className=" tooltiptext">Meter Interval</span>
                            </i>
                          </label>
                          <input
                            name="interval"
                            type="number"
                            className="form-control mb-2"
                            placeholder="Every"
                            id="meter_interval"
                            min="0"
                            value={interval}
                            onChange={handleInterval}
                            required
                          />
                          <div className="col-lg-12 col-sm-12 m-0">
                            <span className="font-danger" id="meter_error"></span>
                          </div>
                        </div>

                        <div className=" col-lg-9 col-md-9 col-sm-8">
                          <label className="font-weight-normal">
                            Frequency <label className="font-danger m-0">*</label>
                          </label>
                          <select
                            className=" mb-2 select-meter"
                            name="frequency"
                            value={frequency}
                            onChange={handleFrequency}
                            id="meter_frequency"
                            required
                          >
                            <option value="">Select a Frequency</option>

                            {['day', 'week', 'month', 'year'].map(val => (
                              <option value={val}>{val}(s)</option>
                            ))}
                          </select>
                        </div>
                      </>
                      }
                      <div className=" col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal">
                          Unit of Measurement<label className="font-danger m-0">*</label>
                        </label>
                        <select
                          className=" mb-2 select-meter"
                          name="measurement_unit"
                          id="measurement_unit"
                          required
                          value={unit}
                          onChange={handleUnit}
                        >
                          <option value="">--SELECT--</option>
                          {meterMeasurment &&
                            meterMeasurment.length > 0 &&
                            meterMeasurment.map(u => <option value={u.id}>{u.name}</option>)}
                        </select>
                      </div>
                      <div className="col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal">Initial Value</label>
                        <input
                          type="text"
                          className="form-control mb-2 font-weight-normal"
                          name="meter_reading"
                          id="meter_reading"
                          onChange={handelInitialValue}
                          value={initial_reading}
                          required
                          placeholder='Initial Value'
                        />
                      </div>
                      <div className="col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal">Reading Attachment</label>
                        <div style={{ background: '#f8f8f8' }}>
                          <input
                            type="file"
                            onChange={handelReadingAttachment}
                            accept="image/*"
                          />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {tab === 'attachment-tab' && (
                <div
                  id="attachment-tab"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-two-attachment-tab"
                >
                  <div className="card-body">
                    <div style={{ background: '#f8f8f8' }}>
                      <input
                        type="file"
                        id="file-input"
                        onChange={handelFile}
                        multiple
                        name="file-input"
                      />
                      <label id="file-input-label" htmlFor="file-input">
                        Choose Files
                      </label>
                    </div>
                    {attachments?.length > 0 && (
                      <p style={{ marginBottom: '5px' }}>Files Choosen</p>
                    )}
                    <ul>
                      {attachments?.map((file, index) => (
                        <span style={{ marginRight: '10px' }} key={index}>
                          {file?.file?.name}
                        </span>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className=" m-0 p-2 page-profile-footer">
            <button id="ajax_button" className="btn primary-btn" type="submit" onClick={handelSave}>
              Save
            </button>
          </div>
        </div>
      </form>
    </Drawer>
  );
}

export default AddMeterModal;

{
  /* <div className="modal fade" id="modal-delete-meter">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Delete meter</h4>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div className="modal-body">
        <input type="hidden" id="delete-meter-id" />
        <input type="hidden" id="csrf-meter-delete" value=" csrf_token "/>
        <p>
            Are you sure you want to delete this
            meter?
        </p>
    </div>
    
    <div className="modal-footer float-right ">
        <a id="delete-meter-data" href="/delete-meter/obj_s.id/" style = "color:white">
            <button className="btn btn-primary mx-0" 
                    type="button"
                    
                    id="delete-meter-data"
                    href = "/delete-meter/obj_s.id/"
                    onClick="delete_meter()">
                    Delete
            <a id="delete-meter-data" href="/delete-meter/obj_s.id/"></a></button>
        
        <button type="button"
                className="btn secondary-btn"
                
                data-dismiss="modal">
                Cancel
        </button>
    </div>
    </div>
    </div>
    </div>
    <div className="modal fade" id="modal-default">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Delete Attachment</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this Attachment?</p>
            </div>
            <div className="modal-footer float-right">
                <a id="delete-data" href="#">
                    <button type="button" className="btn btn-primary">Delete</button>
                </a>
                <button type="button" className="btn secondary-btn"  data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
    </div> */
}
