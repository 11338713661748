import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getTechnicianPositionsByAuthentication = async () => {
  const res = await http.get(
    `${BASE_URL}/technician-position/`
  );

  return assert(
    res,
    res.data,
    "Retrieval of technician position by its company ID failed",
    res
  );
};

const getTechnicianPositionById = async (technicianPositionId) => {
  const res = await http.get(
    `${BASE_URL}/technician-position/${technicianPositionId}`
  );

  return assert(
    res,
    res.data,
    "Retrieval of technician position by its ID failed",
    res
  );
};

const createTechnicianPosition = async (technicianPositionData) => {
  const res = await http.post(
    `${BASE_URL}/technician-position/`,
    technicianPositionData
  );

  return assert(res, res.data, "Creation of technician position failed", res);
};

const deleteTechnicianPosition = async (technicianPositionId) => {
  const res = await http.delete(
    `${BASE_URL}/technician-position/${technicianPositionId}/`
  );

  return assert(res, technicianPositionId, "Deletion of technician position failed", res);
};

const updateTechnicianPosition = async (technicianPositionData) => {
  const res = await http.put(
    `${BASE_URL}/technician-position/${technicianPositionData.id}/`,
    technicianPositionData
  );

  return assert(res, res.data, "Update of technician position failed", res);
};

const technicianPositionService = {
  getTechnicianPositionsByAuthentication,
  getTechnicianPositionById,
  createTechnicianPosition,
  deleteTechnicianPosition,
  updateTechnicianPosition,
};

export default technicianPositionService;
