import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';



const getQRById = async (QRID) => {
  const res = await http.get(`${BASE_URL}/asset-service/${QRID}/`);

  return assert(res, res.data, 'Retrieval of Data by its ID failed', res);
};

const createQR = async (payload) => {
  const res = await http.post(`${BASE_URL}/asset-service/`
  , payload);

  return assert(res, res.data, 'Creation of Data failed', res);
};

const getQRLocById = async (QRID) => {
  const res = await http.get(`${BASE_URL}/location-service/${QRID}/`);
  return assert(res, res.data, 'Retrieval of Data by its ID failed', res);
};

const createQRLoc = async (payload) => {
  const res = await http.post(`${BASE_URL}/location-service/`
  , payload);
  return assert(res, res.data, 'Creation of Data failed', res);
};
const getWorkOrderInfo = async (tktNum) => {
  const res = await http.get(`${BASE_URL}/ticket-view/${tktNum}/`);
  return assert(res, res.data, 'Retrieval of Data by its ID failed', res);
};


const QRService = {
  getQRById,
  createQR,
  getQRLocById,
  createQRLoc,
  getWorkOrderInfo
};

export default QRService;
