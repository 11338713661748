/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */

import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSORHistoryByPagination, setHistoryCurrentPage, setHistoryRowPerPage, setOpenSORModal, setSOR } from '../../../redux/features/SOR/SORSlice';
import EpqTable from '../epqTable';
import Loader from '../../../components/loader/loader';
import Pagination from '../../../components/pagination/pagination';


function SORHistory() {
  const dispatch = useDispatch();
  const {
    historyRowPerPage,
    historyCurrentPage,
    searchValue,
    SORHistoryData
  } = useSelector(state => state.SOR);

  const [iSShowFilter, setISShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);



  // const getSelectedRowwValues = async (selectedRow, cell) => {
  //   console.log("cell", cell);
  //   if (cell.column.id !== "checkbox") {
  //     dispatch(setSOR(selectedRow))
  //     dispatch(setOpenSORModal(true))
  //   }
  // };


  const getDataRows = async () => {
    const filters =
    {
      rowPerPage: historyRowPerPage,
      currentPage: historyCurrentPage,
      searchValue,
    }
    await dispatch(getSORHistoryByPagination(filters))
    setIsLoading(false)
  }
  useEffect(() => {
    getDataRows()
  }, [historyRowPerPage, historyCurrentPage, searchValue])
  
  
  const modifiedData = useMemo(() =>
    SORHistoryData?.results?.length > 0 ? SORHistoryData?.results?.map(item => {
      const date = new Date(item.history_date);
      const formattedDate = date.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
      return {
        "id": item?.reference,
        "history_date": formattedDate,
        "activity": item?.changes,
        "updated_by": item?.updated_by,
      }
    }) : []
    , [SORHistoryData])

  const cols = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Date',
        accessor: 'history_date',
      },
      {
        Header: 'Activity',
        accessor: 'activity',
      },
      {
        Header: 'Updated by',
        accessor: 'updated_by',
      },
    ],
    [],
  );

  return (
    isLoading ?
      <Loader /> :
      <>
        <EpqTable
          iSShowFilter={iSShowFilter}
          setISShowFilter={setISShowFilter}
          // getSelectedRowwValues={getSelectedRowwValues}
          data={modifiedData}
          columns={cols}
          getDataRows={getDataRows}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
        <Pagination
          totalRows={SORHistoryData?.count || 0}
          rowPerPage={historyRowPerPage}
          pageChangeHandler={setHistoryCurrentPage}
          changeRowsPerPage={setHistoryRowPerPage}
          currentPage={historyCurrentPage}
        />
      </>
  )
}

export default SORHistory