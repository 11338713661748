/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { deleteBillById, getBillById, getBills, reset, resetState, setBOQCurrentPage, setBOQRowPerPage, setIsPrint } from '../../../redux/features/SOR/SORSlice';
import EpqItem from '../epqItem';
import EpqTable from '../epqTable';
import Loader from '../../../components/loader/loader';
import Pagination from '../../../components/pagination/pagination';
import IconDownload from '../../../assets/svg-icons/IconDownload';
import DeleteIcon from '../../../assets/svg-icons/DeleteIcon';
import PreviewIcon from '../../../assets/svg-icons/PreviewIcon';
import EditIcon from '../../../assets/svg-icons/EditIcon';
import migrateBillData from '../ebqUtils';
import EbqPdf from '../ebqPdf';
import NewModal from '../../../components/modals/NewModal';


function BOQ() {
    const dispatch = useDispatch();
    const pdf = true
    const {
        openSORModal,
        SORTab,
        searchValue,
        BOQData,
        BOQRowPerPage,
        BOQCurrentPage,
        singleBill,
        isPrint,
        isBillDeleteSuccess,
        isBillDeleteError
    } = useSelector(state => state.SOR);

    const [iSShowFilter, setISShowFilter] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [deletePopUp, setDeletePopUp] = useState({ flag: false, id: null })
    const history = useHistory()



    // const getSelectedRowwValues = async (selectedRow, cell) => {
    //     console.log("cell", cell);
    //     if (cell.column.id !== "checkbox") {
    //         dispatch(setSOR(selectedRow))
    //         dispatch(setOpenSORModal(true))
    //     }
    // };

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef?.current,
        onAfterPrint: () => {
            dispatch(resetState())
        }
    });

    const handleClickEdit = (id) => {
        history.push(`/ebq/bill/${id}`)
    }
    const handleClickPreview = (id) => {
        history.push(`/ebq/bill/preview/${id}`)
    }
    const handleClickDownload = async (id) => {
        const theBill = await dispatch(getBillById(id))
        migrateBillData(theBill.payload, dispatch)
        dispatch(setIsPrint(true))
    }

    const handleClickDelete = async (id) => {
        setDeletePopUp({ flag: true, id })
        // await dispatch(deleteBillById(id))
    }

    const handleDelete = async () => {
        await dispatch(deleteBillById(deletePopUp.id))
    }

    useEffect(() => {
        if (isBillDeleteSuccess) {
            toast.success('Bill Deleted Successfully', {
                toastId: 'integrationSyncStarted',
            })
        }
        if (isBillDeleteError) {
            toast.success('Something went wrong while Deleting Bill', {
                toastId: 'integrationSyncStarted',
            })
        }
        dispatch(reset());
        setDeletePopUp({ flag: false, id: null })

    }, [isBillDeleteSuccess, isBillDeleteError])


    useEffect(() => {
        if (singleBill && isPrint) {
            handlePrint()
        }
    }, [singleBill, isPrint])


    const cols = React.useMemo(() =>
        [
            {
                Header: 'Job no.',
                accessor: 'jobNo',
            },
            {
                Header: 'Created date',
                Cell: ({ row }) => {
                    return (
                        <span>{moment(row?.original?.createdDate).format("MMMM DD, YYYY hh:mm a")}
                        </span>
                    )
                },
            },
            {
                Header: 'Work title',
                accessor: 'workTitle',
            },
            {
                Header: 'Status',
                Cell: ({ row }) => {
                    return (
                        <button
                            style={{
                                padding: "4px 8px", borderRadius: "4px",
                                width: "80px",
                                backgroundColor: row?.original?.status === "draft" ? "#72747829" : "#4472B829",
                                color: row?.original?.status === "draft" ? "#727478" : "#4472B8",
                            }}
                            type='button'>{row?.original?.status === "draft" ? "Draft" : "Published"}
                        </button>
                    )
                },
            },
            {
                Header: 'Actions',
                Cell: ({ row }) => {
                    return (
                        // <button
                        // style={{padding: "4px 8px", borderRadius: "4px", 
                        //     width: "67px",
                        //     backgroundColor: row?.original?.status === "draft" ? "#72747829" : "#4472B829",
                        //     color: row?.original?.status === "draft" ? "#727478" : "#4472B8",
                        // }}
                        // type='button'>{row?.original?.status === "draft" ? "Draft" : "Published"}
                        // </button>
                        <>
                            <button type='button' className='new-btn' onClick={() => handleClickPreview(row?.original?.id)}> <PreviewIcon /> </button>
                            {row?.original?.status === "draft" ?
                                <button onClick={() => handleClickEdit(row?.original?.id)} type='button' className='new-btn'> <EditIcon /> </button> :
                                <button type='button' className='new-btn' onClick={() => handleClickDownload(row?.original?.id)}> <IconDownload /> </button>}
                            <button type='button' className='new-btn' onClick={() => handleClickDelete(row?.original?.id)}> <DeleteIcon /> </button>
                        </>
                    )
                },
            },
        ], [SORTab])


    const getDataRows = async () => {
        const filters =
        {
            rowPerPage: BOQRowPerPage,
            currentPage: BOQCurrentPage,
            searchValue
        }
        await dispatch(getBills(filters))
        setIsLoading(false)
    }

    useEffect(() => {
        getDataRows()
    }, [searchValue, BOQRowPerPage, BOQCurrentPage])

    const modifiedData = useMemo(() =>
        BOQData?.results?.length > 0 ? BOQData?.results?.map(item => {
            return {
                "id": item?.id,
                "jobNo": item?.job_num,
                "workTitle": item?.work_title,
                "status": item?.status,
                "createdDate": item?.created_at,
            }
        }) : []
        , [BOQData])

    return (
        isLoading ?
            <Loader /> :
            <>
                <EpqTable
                    iSShowFilter={iSShowFilter}
                    setISShowFilter={setISShowFilter}
                    getSelectedRowwValues={null}
                    data={modifiedData}
                    columns={cols}
                    getDataRows={getDataRows}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
                <Pagination
                    totalRows={BOQData?.count || 0}
                    rowPerPage={BOQRowPerPage}
                    pageChangeHandler={setBOQCurrentPage}
                    changeRowsPerPage={setBOQRowPerPage}
                    currentPage={BOQCurrentPage}
                />
                {openSORModal && <EpqItem />}
                {deletePopUp.flag && (
                    <NewModal
                        showModal={deletePopUp.flag}
                        handleClose={() => setDeletePopUp({ flag: false, id: null })}
                        handleCreateSave={handleDelete}
                        primaryButtonText="Delete"
                        modalTitle='Delete this item?'
                    >
                        This item will be permanently deleted.
                    </NewModal>
                )}
                <EbqPdf ref={componentRef} pdf={pdf} />
            </>
    )
}

export default BOQ