import React, { useState } from 'react';
import moment from "moment";
import { useDispatch } from 'react-redux';
import { getMaintenanceById, openWorkOrderModalUpdate, resetWorkOrder, setOpenFrom, setWorkOrder } from '../../redux/features/workOrder/workOrderSlice';
import { setAsset } from '../../redux/features/asset/assetSlice';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';
import { getPagination } from '../../redux/features/maintenance/maintenanceSlice';
import MaintenancePlannerCalendar from './components/MaintenancePlannerCalendar';
import MaintenancePlannerBoard from './components/MaintenancePlannerBoard';
// import Spanning from './components/Spanning';

/* eslint-disable no-nested-ternary */

export default function MaintenancePlanner({ selectedMenu, selectedViewMode }) {
  // Select calendar mode (month, week)
  const [selectedCalendarMode, setSelectedCalendarMode] = useState('Month');
  const dispatch = useDispatch()

  const handleSelectedEvent = async (event) => {
    const filterData = {
      site: '',
      sublocation: '',
      filterCategory: '',
      type: '',
      staff: '',
      status: '',
      asset: '',
    };
    dispatch(setWorkOrder({}))
    dispatch(resetWorkOrder())
    dispatch(setAsset(null))
    dispatch(dispatch(selectActions.reset()))
    await dispatch(getMaintenanceById(event.id));
    dispatch(
      getPagination({
        rowPerPage: 10,
        currentPage: 1,
        searchValue: event?.title,
        ...filterData,
      }),
    );
    dispatch(openWorkOrderModalUpdate());
    dispatch(setOpenFrom("Maintenance"));
  };

  const getFreQ = (item) => {
    const frqType = item?.schedule_data?.frequency || item?.schedule?.frequency
    const frqNumber = item?.schedule_data?.frequency_number || item?.schedule?.frequency_number
    const weekDay = item?.schedule_data?.weekly_day || item?.schedule?.weekly_day
    const dayOfMonth = item?.schedule_data?.day_of_month || item?.schedule?.day_of_month
    const monthOfYear = item?.schedule_data?.month_of_year || item?.schedule?.month_of_year

    const THEFRQ = frqType === "day" ? { frequencyType: "Daily", frqSentence: `Every ${frqNumber} Day` } :
      frqType === "week" ? { frequencyType: "Weekly", frqSentence: `Every ${frqNumber} Week${weekDay ? `, on ${weekDay}` : ""}` } :
        frqType === "month" ? { frequencyType: "Monthly", frqSentence: `Every ${frqNumber} Month${dayOfMonth ? `, on ${moment(dayOfMonth, 'D').format('Do')}` : ""}` } :
          { frequencyType: "Yearly", frqSentence: `Every ${frqNumber} Year${(dayOfMonth && monthOfYear) ? `, on ${moment(dayOfMonth, 'D').format('Do')} ${moment(monthOfYear, 'M').format('MMMM')}` : ""}` }
    return THEFRQ
  }

  return (
    <div>
      {selectedViewMode === 'Board mode' && (
        <MaintenancePlannerBoard
          handleSelectedEvent={handleSelectedEvent}
          getFreQ={getFreQ}
          selectedMenu={selectedMenu}
        />
      )}

      {selectedViewMode === 'Calendar mode' && (
        <MaintenancePlannerCalendar
          getFreQ={getFreQ}
          selectedCalendarMode={selectedCalendarMode}
          setSelectedCalendarMode={setSelectedCalendarMode}
        />
      )}
    </div>
  );
}
