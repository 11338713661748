import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify"
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetListSearch, setAsset } from '../../redux/features/asset/assetSlice';
import classes from "../assetsPage/assestsPage.module.css"
import Pagination from '../../components/pagination/pagination';
import Loader from '../../components/loader/loader';
import { getTeamsByAuthentication } from '../../redux/features/teams/teamSlice';
import { getLocationsByAuthentication } from '../../redux/features/location/locationSlice';
// import PMTable from './Componets/PMTable';
import PMHeader from './Componets/PMHeader';
import AddPMModal from './Componets/AddPMModal';
import { setTab, closeMaintenanceModalCreate, closeMaintenanceModalUpdate, getMaintenanceById as getMaintenanceByIdPMTab, getPagination, openMaintenanceModalUpdate, reset as PMReset, resetMaintenance, setMaintenance, setCurrentPage, setRowPerPage } from '../../redux/features/maintenance/maintenanceSlice';
import { getChecklistByAuthentication } from '../../redux/features/checklist/checklistSlice';
import { getMaintenanceCategoryByAuthentication } from '../../redux/features/maintenanceCategory/maintenanceCategorySlice';
import { getInventorysByAuthentication } from '../../redux/features/inventory/inventorySlice';
import { closeWorkOrderModalCreate, resetWorkOrder, reset as WOReset, getWorkOrdersByAuthentication, openWorkOrderModalUpdate, setOpenFrom, getMaintenanceById as getMaintenanceByIdWOTab, setWorkOrder, setStoreWorkOrderId } from '../../redux/features/workOrder/workOrderSlice';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';
import PMTable from './Componets/PMTable';
import { getMaintenanceTypeByAuthentication } from '../../redux/features/maintenanceType/maintenanceTypeSlice';
import PMScheduleTabs from './PMScheduleTabs';

/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */

export default function MaintenancePage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMaintenanceCategoryByAuthentication())
    dispatch(getTeamsByAuthentication())
    dispatch(getLocationsByAuthentication())
    dispatch(getAssetListSearch({ rowPerPage: 10, currentPage: 1, searchValue: "" }))
    dispatch(getChecklistByAuthentication());
    dispatch(getInventorysByAuthentication());
    dispatch(getWorkOrdersByAuthentication())
    dispatch(getMaintenanceTypeByAuthentication())
  }, [])

  const
    checklists
      = useSelector((state) => state.checklists.checklists.results);


  const { maintenances, maintenanceModalCreate,
    // maintenanceModalUpdate,
    isMaintenanceCreateSuccess,
    isMaintenanceDeleteSuccess,
    isMaintenanceUpdateSuccess,
    isMaintenanceCreateError,
    isMaintenanceDeleteError,
    isMaintenanceUpdateError,
    searchValue,
    filterCategory,
    filtertype,
    filterSite,
    filterSublocation,
    currentPage,
    rowPerPage,
    staff,
    filterStatus,
    pmSchedule,
    filterStartDate,
    filterEndDate,
    filterAsset,
    filterPrioirty,
    filterFrequency
  } = useSelector(state => state.maintenance)

  const pmDataCount = useSelector(state => state.maintenance?.maintenances?.count)
  const [data, setData] = useState([])
  const [iSShowFilter, setISShowFilter] = useState(false)
  const [iSShowCheckboxs, setISShowCheckboxs] = useState(false)

  const [isLoading, setIsLoading] = useState(true);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowPerPage, setRowPerPage] = useState(10);


  const getDataRows = async () => {
    const queryString = filterPrioirty
      .filter(priority => priority.selected) // Only keep selected items
      .map(priority => `priority=${priority.label}`) // Convert to lowercase and map to query string
      .join('&');

    setIsLoading(true)
    const filters = {
      rowPerPage,
      currentPage,
      searchValue,
      startDate: filterStartDate ? moment(filterStartDate).format("YYYY-MM-DD") : "",
      endDate: filterEndDate ? moment(filterEndDate).format("YYYY-MM-DD") : "",
      pmSchedule: pmSchedule !== "PM Schedule",
      site: filterSite?.value || null,
      sublocation: filterSublocation?.value || null,
      filterCategory: filterCategory?.value || null,
      type: filtertype?.value || null,
      staff: staff?.value || null,
      asset: filterAsset?.value || null,
      status: filterStatus?.label && filterStatus?.label !== "All" ? filterStatus?.label : null,
      prioirty: queryString,
      frequency: filterFrequency?.label && filterFrequency?.label !== "All" ? filterFrequency?.label.toLowerCase() : null,
    }
    await dispatch(getPagination(filters))
    setIsLoading(false)
  }

  useEffect(() => {
    getDataRows()
  }, [rowPerPage, currentPage, searchValue, pmSchedule]);



  // useEffect(() => {
  //   if (searchValue !== "") {
  //     setCurrentPage(1)
  //   }
  // }, [searchValue])

  const getSelectedRowwValues = async (selectedRow, cell) => {
    if (pmSchedule === "PM Schedule") {
      if (cell.column.id !== "delete-table-column") {
        dispatch(setMaintenance({}))
        dispatch(resetMaintenance())
        dispatch(setAsset(null))
        dispatch(selectActions.reset())
        await dispatch(getMaintenanceByIdPMTab(selectedRow.id))
        dispatch(openMaintenanceModalUpdate())
        dispatch(setTab("service-tab"))
      }
    } else {
      // dispatch(
      //     getPaginationPM({ rowPerPage: 10, currentPage: 1, searchValue: ticketTitle, ...filterData }),
      // );
      dispatch(setWorkOrder({}))
      dispatch(resetWorkOrder())
      dispatch(setAsset(null))
      dispatch(dispatch(selectActions.reset()))
      await dispatch(getMaintenanceByIdWOTab(selectedRow.id));
      dispatch(openWorkOrderModalUpdate());
      dispatch(setOpenFrom("Maintenance"));
    }
  };


  useEffect(() => {
    const modifiedData = maintenances?.results?.map((d) => {
      const frqType = d?.schedule_data?.frequency || d?.schedule?.frequency
      const frqNumber = d?.schedule_data?.frequency_number || d?.schedule?.frequency_number
      const weekDay = d?.schedule_data?.weekly_day || d?.schedule?.weekly_day
      const dayOfMonth = d?.schedule_data?.day_of_month || d?.schedule?.day_of_month
      const monthOfYear = d?.schedule_data?.month_of_year || d?.schedule?.month_of_year
      const THEFRQ = frqType === "day" ? { frequencyType: "Daily", frqSentence: `Every ${frqNumber} Day` } :
        frqType === "week" ? { frequencyType: "Weekly", frqSentence: `Every ${frqNumber} Week${weekDay ? `, on ${weekDay}` : "" }` } :
          frqType === "month" ? { frequencyType: "Monthly", frqSentence: `Every ${frqNumber} Month${dayOfMonth ? `, on ${moment(dayOfMonth, 'D').format('Do')}`: ""}` } :
            { frequencyType: "Yearly", frqSentence: `Every ${frqNumber} Year${(dayOfMonth && monthOfYear) ? `, on ${moment(dayOfMonth, 'D').format('Do')} ${moment(monthOfYear, 'M').format('MMMM')}`: ""}` }
      return {
        id: d?.id,
        title: d?.title,
        assignedPersons: d?.assigned_to?.length > 0 ? d?.assigned_to?.map(a => a) : "—",
        start_date: d?.due_finish,
        created_at: d?.created_at,
        asset: d?.asset?.name || <p>—</p>,
        assetId: d?.asset?.asset_number || <p>—</p>,
        status: d?.status,
        priority: d?.priority,
        category: d?.category,
        location: d?.location?.site || <p>—</p>,
        completedDate: d?.completed_date || "—",
        createdBy: d?.service_request?.created_by?.role,
        frequencyNumber: THEFRQ.frqSentence,
        frequencyType: THEFRQ.frequencyType,
        startSchedule: d?.schedule_data?.start_date || d?.schedule?.start_date,
        endSchedule: d?.schedule_data?.end_date || d?.schedule?.end_date,
        ticketNumber: d?.ticket_number,
      };
    });
    setData(modifiedData)
  }, [maintenances])



  const {
    isWorkOrderCreateSuccess,
    isWorkOrderCreateError,
    storeWorkOrderId
  } = useSelector(state => state.workOrder)

  function displayToast() {
    if (isMaintenanceCreateError) {
      toast.error('Something went wrong Creating Maintenance', {
        toastId: 'CreatedMaintenanceError',
      });
      dispatch(PMReset())
    } else if (isMaintenanceUpdateError) {
      toast.error(' Something went wrong Updating Maintenance', {
        toastId: 'UpdatingMaintenanceError',
      });
      dispatch(PMReset())
    } else if (isMaintenanceDeleteError) {
      toast.error(' Something went wrong Deleting Maintenance', {
        toastId: 'DeletingMaintenanceError',
      });
      dispatch(PMReset())
    } else if (isMaintenanceCreateSuccess) {
      toast.success(' Maintenance Created successfully', {
        toastId: 'Maintenance Created Success',
      });
      dispatch(closeMaintenanceModalCreate())
      dispatch(resetMaintenance())
      dispatch(PMReset())
      if (pmSchedule !== "PM Schedule") {
        getDataRows()
      }
    } else if (isMaintenanceDeleteSuccess) {
      toast.success(' Maintenance Deleted successfully', {
        toastId: 'DeletedMaintenanceError',
      });
      dispatch(PMReset())
      dispatch(resetMaintenance())
      dispatch(closeMaintenanceModalUpdate())
    }
    else if (isMaintenanceUpdateSuccess) {
      toast.success(' Maintenance Updated successfully', {
        toastId: 'UpdatedMaintenanceError',
      });
      dispatch(PMReset())
    }
    else if (isWorkOrderCreateError) {
      toast.error('Something went wrong Creating WorkOrder', {
        toastId: 'CreatedWorkOrderError',
      });
      dispatch(WOReset())

    } else if (isWorkOrderCreateSuccess) {
      toast.success(' WorkOrder Created successfully', {
        toastId: 'WorkOrder Created Success',
      });
      dispatch(closeWorkOrderModalCreate())
      dispatch(resetWorkOrder())
      dispatch(WOReset())
      if (storeWorkOrderId) {
        dispatch(setWorkOrder({}))
        dispatch(resetWorkOrder())
        dispatch(getMaintenanceByIdWOTab(storeWorkOrderId));
        dispatch(openWorkOrderModalUpdate());
        dispatch(setOpenFrom("Maintenance"));
        dispatch(setStoreWorkOrderId(null))
      }
    }
  }
  return (
    <div>
      {displayToast()}
      <div className={classes.assets}>
        <PMHeader
          setISShowFilter={setISShowFilter}
          iSShowFilter={iSShowFilter}
        />
        <PMScheduleTabs />
        {/* <PMFilter
        /> */}
        {
          isLoading ? <Loader /> :
            <>
              <PMTable
                iSShowFilter={iSShowFilter}
                setISShowFilter={setISShowFilter}
                iSShowCheckboxs={iSShowCheckboxs}
                setISShowCheckboxs={setISShowCheckboxs}
                getSelectedRowwValues={getSelectedRowwValues}
                meterData={data || []}
                setIsLoading={setIsLoading}
              />
              <Pagination
                totalRows={pmDataCount}
                rowPerPage={rowPerPage}
                pageChangeHandler={setCurrentPage}
                changeRowsPerPage={setRowPerPage}
                currentPage={currentPage}
              />
            </>
        }
      </div>
      {maintenanceModalCreate && <AddPMModal
        openModalCreate={maintenanceModalCreate}
        closeModalCreate={() => dispatch(closeMaintenanceModalCreate())}
        checklistData={checklists}
      />}
      {/* { maintenanceModalUpdate && <EditPMModal 
          openModalUpdate = {maintenanceModalUpdate} 
          closeModalUpdate={() => dispatch(closeMaintenanceModalUpdate())}
        />} */}
    </div>
  );
}