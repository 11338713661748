import React from 'react'
/* eslint-disable no-unneeded-ternary  */

function DeleteIcon({ color }) {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5963 12.0237L12.5963 12.0327V12.0327L11.5963 12.0237ZM1.66797 2.99992C1.11568 2.99992 0.667969 3.44763 0.667969 3.99992C0.667969 4.5522 1.11568 4.99992 1.66797 4.99992V2.99992ZM12.3346 4.99992C12.8869 4.99992 13.3346 4.5522 13.3346 3.99992C13.3346 3.44763 12.8869 2.99992 12.3346 2.99992V4.99992ZM6.66797 6.66659C6.66797 6.1143 6.22025 5.66659 5.66797 5.66659C5.11568 5.66659 4.66797 6.1143 4.66797 6.66659H6.66797ZM4.66797 11.9999C4.66797 12.5522 5.11568 12.9999 5.66797 12.9999C6.22025 12.9999 6.66797 12.5522 6.66797 11.9999H4.66797ZM9.33463 6.66659C9.33463 6.1143 8.88692 5.66659 8.33463 5.66659C7.78235 5.66659 7.33463 6.1143 7.33463 6.66659H9.33463ZM7.33463 11.9999C7.33463 12.5522 7.78235 12.9999 8.33463 12.9999C8.88692 12.9999 9.33463 12.5522 9.33463 11.9999H7.33463ZM10.668 3.99099L10.5964 12.0148L12.5963 12.0327L12.6679 4.00885L10.668 3.99099ZM8.92977 13.6666H5.0013V15.6666H8.92977V13.6666ZM1.33464 3.99992V11.9999H3.33464V3.99992H1.33464ZM1.66797 4.99992H2.33464V2.99992H1.66797V4.99992ZM2.33464 4.99992H4.33464V2.99992H2.33464V4.99992ZM4.33464 4.99992H9.66797V2.99992H4.33464V4.99992ZM9.66797 4.99992H11.668V2.99992H9.66797V4.99992ZM11.668 4.99992H12.3346V2.99992H11.668V4.99992ZM5.33464 3.70362C5.33464 3.05426 5.96685 2.33325 7.0013 2.33325V0.333252C5.09024 0.333252 3.33464 1.73474 3.33464 3.70362H5.33464ZM7.0013 2.33325C8.03576 2.33325 8.66797 3.05426 8.66797 3.70362H10.668C10.668 1.73474 8.91236 0.333252 7.0013 0.333252V2.33325ZM3.33464 3.70362V3.99992H5.33464V3.70362H3.33464ZM8.66797 3.70362V3.99992H10.668V3.70362H8.66797ZM5.0013 13.6666C4.08083 13.6666 3.33464 12.9204 3.33464 11.9999H1.33464C1.33464 14.025 2.97626 15.6666 5.0013 15.6666V13.6666ZM10.5964 12.0148C10.5882 12.9294 9.84444 13.6666 8.92977 13.6666V15.6666C10.942 15.6666 12.5783 14.0448 12.5963 12.0327L10.5964 12.0148ZM4.66797 6.66659V11.9999H6.66797V6.66659H4.66797ZM7.33463 6.66659V11.9999H9.33463V6.66659H7.33463Z" 
            fill={color ? color : "#36383C"}
        />
        </svg>


    )
}

export default DeleteIcon