import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import ScrollToTop from '../scrollToTop';

import {
  closeAddFloor,
  addFloor,
  deleteFloor,
  openAssociatedLocation,
  closeAssociatedLocation,
  // setAssociatedLocation,
  updateFloor,
  getFloor,
  // getLocationById,
  openSiteModeul,
  getFloorList,
  setSearchValueOfPlan,
} from '../../redux/features/location/locationSlice';
import AssociatedLocation from './assosiatedLocationPopUp';
import './locationstyle.scss';
import Pagination from '../pagination/pagination';
import classes from "../../pages/assetsPage/assestsPage.module.css"


pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
export default function AddFloor({ id }) {
  const [floorname, setFloorName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const { location, showAssociatedFloor , floorPlans , floorPlansCount , searchValueOfPlan } = useSelector(state => state.location);
  const dispatch = useDispatch();

  const handleAddFloor = e => {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append('name', floorname);
    formdata.append('image', selectedFile);
    const info = {
      id: location.id,
      data: formdata,
    };
    dispatch(addFloor(info));
    setFloorName('');
    setSelectedFile(null);
    setPreviewUrl(null);
  };

  function changecursor(e) {
    e.target.style.cursor = 'pointer';
  }

  function deleteAssociatedLocation(locationid, subid) {
    let arraydata = [];
    let floorplan;
    if (floorPlans !== undefined) {
      floorplan = floorPlans?.find(e => {
        return e.id === locationid;
      });
      arraydata = floorplan.associated_location.filter(e => e.id !== subid);
    }
    const lastdata = [];
    arraydata.map(e => lastdata.push(e.id));
    const info = {
      id: locationid,
      data: { associated_location: lastdata },
    };
    dispatch(updateFloor(info));
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  useEffect(() => {
    const getDataRows = async () => {
      if (id) {
        await dispatch(getFloorList({ id, rowPerPage, currentPage, searchValue : searchValueOfPlan }));
      }
    };
    getDataRows();
  }, [id, rowPerPage, currentPage , searchValueOfPlan]);
  const handelChange = (e)=>{
    dispatch(setSearchValueOfPlan(e.target.value))
  }

  async function checkUploadTypeAndConvertToPdfIfNeeded(uploadedFile) {
    if (uploadedFile) {
      const fileType = uploadedFile.type;
      if ((!fileType.startsWith("image")) && fileType !== "application/pdf") {
        setSelectedFile(null)
        toast.warning('The only supported file types are images and PDF', {
          toastId: 'uploadTypeError',
        });
      }

      if (fileType.startsWith("image/")) {
        setSelectedFile(uploadedFile)        // dispatch(
      }
      if (fileType === "application/pdf") {
        const arrayBuffer = await new Response(uploadedFile).arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);

        const pdf = await pdfjs.getDocument(uint8Array).promise;
        const page = await pdf.getPage(1); // First page image will be converted in FOX , more can be specified

        const viewport = page.getViewport({ scale: 1.0 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        await page.render({ canvasContext: context, viewport }).promise;

        // Convert the canvas to a data URL
        const imageDataUrl = canvas.toDataURL('image/jpeg');

        const handleSaveFile = (base64String) => {
          // Extract the file format from the base64 string (e.g., 'image/png')
          const format = base64String.split(';')[0].split('/')[1];

          // Remove the data:image/<format>;base64, prefix to get just the base64 data
          const data = atob(base64String.split(',')[1]);

          // Create a Uint8Array to hold the binary data
          const array = new Uint8Array(data.length);

          // Fill the Uint8Array with the binary data
          for (let i = 0; i < data.length; i += 1) {
            array[i] = data.charCodeAt(i);
          }

          // Create a Blob from the Uint8Array
          const blob = new Blob([array], { type: `image/${format}` });

          // Create a File object with the desired properties
          const fileName = 'image (3).png'; // Replace with your desired filename
          const lastModified = Date.now(); // Replace with the last modified timestamp if needed
          const file = new File([blob], fileName, { type: blob.type, lastModified });
          return file
        };
        setSelectedFile(handleSaveFile(imageDataUrl))
      }
    }
    else {
      toast.error('Error converting and uploading image / pdf', {
        toastId: 'uploadTypeErrorPDF',
      });
    }
    // setSelectedFile(imageDataURL)

  }

  return (
    <>
      <ScrollToTop />
      <AssociatedLocation
        showPop={showAssociatedFloor}
        onHidePop={() => dispatch(closeAssociatedLocation())}
        primaryButtonText="Associate"
      />
      <form onSubmit={handleAddFloor}>
        <div className=" card m-4 p-2 card-header-background">
          <div className="card-header " id="floor-plan-add">
            <div className="card-header">
              <h4 className="card-title mt-2">
                <b>Add Floor Plan</b>
              </h4>
            </div>
            <div className="card-body ">
              <div className="col-lg-12 col-sm-12 pt-0">
                <div
                  className="row"
                  style={{
                    display: 'flex',
                    justifyContent: ' center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div className=" col-lg-6 col-md-6 col-sm-6">
                    <label className="font-weight-normal" htmlFor="layout_name">
                      Floor layout name
                    </label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      name="layout_name"
                      id="layout_name"
                      placeholder="Floor layout name"
                      required
                      onChange={e => {
                        setFloorName(e.target.value);
                      }}
                      value={floorname}
                    />
                  </div>

                  <div className=" col-lg-6 col-md-6 col-sm-6">
                    <label className="font-weight-normal" htmlFor="floor_plan">
                      Attach drawing{' '}
                      <span className="text-secondary small-font">(Max upload size is 5MB)</span>
                    </label>
                    <input
                      type="file"
                      className="form-control mb-2"
                      name="floor_plan"
                      id="floor_plan"
                      placeholder="Floor plan"
                      accept="image/*,.pdf"
                      onChange={event => {
                        // setSelectedFile(event.target.files[0]);
                        setPreviewUrl(URL.createObjectURL(event.target.files[0]));
                        checkUploadTypeAndConvertToPdfIfNeeded(event.target.files[0])
                      }}
                      style={{ display: 'none' }}
                    />
                  </div>
                  <div className=" col-lg-6 col-md-6 col-smh-6">
                    <label className="font-weight-normal" htmlFor="floor_plan">
                      <span className="text-secondary small-font">(Max upload size is 5MB)</span>
                    </label>
                    {!previewUrl && (
                      <label
                        className="drop-zone "
                        htmlFor="floor_plan"
                        onDragOver={event => {
                          event.preventDefault();
                        }}
                        onDragEnter={event => {
                          event.preventDefault();
                        }}
                        onDrop={event => {
                          event.preventDefault();
                          // setSelectedFile(event.dataTransfer.files[0]);
                          setPreviewUrl(URL.createObjectURL(event.dataTransfer.files[0]));
                          checkUploadTypeAndConvertToPdfIfNeeded(event.dataTransfer.files[0])

                        }}
                      >
                        <span className="drop-zone__prompt">
                          Drop drawing here or click to upload
                        </span>
                      </label>
                    )}
                    {previewUrl && (
                      <img src={previewUrl} alt="Preview" id="floor_plan" className="drop-zone" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="float-right row mt-3">
              <div className="row float-right">
                <button title="Update Site" className="btn primary-btn ml-2 " type="submit">
                  Add Floor Plan
                </button>
                <button
                  title="Cancel Update Site"
                  onClick={() => {
                    dispatch(closeAddFloor());
                    dispatch(openSiteModeul());
                  }}
                  className="btn secondary-btn ml-2 "
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div
            className="mt-3 btn-group2"
            id="floor-plan-list"
            style={{ borderRadius: '0.5em', display: 'block;' }}
          >
            {/* <div className="card-header">
              <h4 className="card-title mt-2 gcolor">
                <b>Floor plans</b>
              </h4>
              <input type="text" placeholder='Search' value={searchValueOfPlan} onChange={handelChange} />
            </div> */}
            <div className={`${classes.assetsTop} p-3`}>
            <h4 className={`${classes.assetsTitle} card-title mt-2 gcolor`}>Floor plans</h4>
            <div className={classes.assetActions}>
            <input type="text" placeholder='Search' value={searchValueOfPlan} onChange={handelChange} />
            </div>
            </div>
            <div style={{ overflow: 'scroll' }}>
              <div className="card-body pt-0 table-responsive text-nowrap stripped">
                <input type="hidden" id="floor_id" value="" />
                <table
                  id="floor-plan-table"
                  className="table table-togglable w-100  table-borderless table-striped"
                >
                  <thead>
                    <tr>
                      <th className="">
                        Layout Name
                        <br />
                      </th>
                      <th className="">Image</th>
                      <th className="">Associated Locations</th>
                      <th className=""> </th>
                    </tr>
                  </thead>
                  <tbody id="floor-plan-list-block">
                    {floorPlans?.length > 0 ? floorPlans?.map(e => (
                      <tr
                        style={{ height: 'auto' }}
                        onMouseOver={changecursor}
                        onFocus={() => { }}
                        className="floorRow"
                      >
                        {/* <td>{location?.floor_plan.name}</td> */}
                        <button
                          type="button"
                          onKeyDown={() => dispatch(openAssociatedLocation())}
                          onClick={() => {
                            dispatch(openAssociatedLocation());
                            // dispatch(setAssociatedLocation(e));
                            dispatch(getFloor(e?.id));
                          }}
                        >
                          <td data-toggle="modal" data-target="#modal-location" title={e.name}>
                            <h5>{e.name}</h5>
                          </td>
                        </button>
                        <td>
                          <div className="container col-2 m-0  text-center" title={e.name}>
                            <button
                              type="button"
                              title={e.name}
                              onClick={() => {
                                dispatch(openAssociatedLocation());
                                // dispatch(setAssociatedLocation(e));
                                dispatch(getFloor(e?.id));
                              }}
                            >
                              <img
                                id="myImg"
                                style={{ width: '100px', height: '80px' }}
                                className="image p-2 photo"
                                // src="{{floor_plan.image}}"
                                data-toggle="modal"
                                data-target="#myModal"
                                src={e?.image}
                                alt="flor_im"
                              />
                            </button>
                          </div>
                        </td>
                        <td style={{ width: '100%', position: 'relative' }}>
                          <div
                            id="wrapLocation"
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              justifyContent: 'space-between',
                              position: 'relative',
                              alignItems: 'center',
                              maxWidth: '100%',
                            }}
                          >
                            {e.associated_location.map(loc => (
                              <div
                                id="locationCard"
                                className="pill col-6 h-priority"
                                style={{
                                  padding: ' 0px 10px 0px 7px ',
                                  marginBottom: '5px',
                                  marginRight: '5px',
                                  maxWidth: 'fit-content',
                                  minWidth: '40%',
                                }}
                              >
                                <div className="pill text-center p-2 mr-2 my-1" title="Location">
                                  {loc.room}
                                  <div className="middle">
                                    <button
                                      // href="#"
                                      type="button"
                                      onClick={() => deleteAssociatedLocation(e.id, loc.id)}
                                      data-toggle="modal"
                                      data-target="#modal-dissociate-location"
                                      title="Dissociate Location"
                                      className="text font-danger close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}

                            {/* {% if floor_plan.locations %} */}
                            {/* {% for location in floor_plan.locations  %}                                */}
                            {/* <div id="locationCard"  className="pill col-6 m-0 h-priority" style="padding: 0px 10px 0px 7px;margin-bottom: 5px!important; margin-right: 5px!important; max-width: fit-content;min-width: 33%;" title="{{floor_plan.name}}">
                                    <div className="text-center p-2 mr-2 my-1" title="Location">
                                      {% if location.name %}, {{location.name}}{% endif %} 
                                      {% if location.level %}, {{location.level}}{% endif %} 
                                      {% if location.suite %}, {{location.suite}}{% endif %} 
                                      {% if location.room_new %}, {{location.room_new}}{% endif %} 
                                      <div className="middle">
                                        <a href="#" onclick="delete_location_floor_plan('/delete-location-floor-plan/{{floor_plan.id}}/{{location.id}}/')"
                                        data-toggle="modal" data-target="#modal-dissociate-location"
                                        title="Dissociate Location" className="text font-danger">
                                            <i className="fa fa-close"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div> */}

                            {/* {% endfor %}
                                  {% else %}
                                  &mdash;
                                  {% endif %} */}
                          </div>
                          {/* {% if floor_plan.locations|length > 3 %}
                                <button className="btn btn-link" style="position: relative; float: right; bottom: 0.5remm;" id="showMoreLocations">Show more</button>
                                {%endif%} */}
                        </td>
                        <td>
                          <button
                            id="bin_icon_hide"
                            // href="#"
                            onClick={() => dispatch(deleteFloor(e.id))}
                            data-toggle="modal"
                            data-target="#modal-delete-floor-plan"
                            title="Delete Floor Plan"
                            className="text font-danger m-auto"
                            style={{ display: 'inline-table' }}
                            type="button"
                          >
                            <i className="fa fa-trash"> </i>
                          </button>
                        </td>
                      </tr>
                    )) : <tr> <td className={classes.noResults} colSpan="4">No Results Found </td></tr>}
                    {/* {% endfor %} */}
                  </tbody>
                </table>
              </div>
              <Pagination
              totalRows={floorPlansCount}
              rowPerPage={rowPerPage}
              pageChangeHandler={setCurrentPage}
              changeRowsPerPage={setRowPerPage}
              currentPage={currentPage}
            />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
