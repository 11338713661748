/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getAssetsByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/asset/`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};
const getAssetsByPageSize = async numPage => {
  const res = await http.get(`${BASE_URL}/asset/?page=${numPage}`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};

const getAssetsByRows = async data => {
  const queryParamsString = Object.entries(data.filterData)
    .filter(([key, value]) => value !== null) // Filter out empty values
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  const res = await http.get(
    `${BASE_URL}/asset/?search=${data.assetSearchValue}&page=${data.currentPage}&page_size=${data.rowPerPage}&${queryParamsString}`,
  );
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};
const assetSearch = async text => {
  const res = await http.get(`${BASE_URL}/asset/?search=${text}`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};

const exportAsset = async data => {
  const res = await http.post(`${BASE_URL}/asset-export/`, data);
  return assert(res, res.data, 'Export of asset failed', res);
};

const importAsset = async data => {
  const res = await http.postImg(`${BASE_URL}/asset-import/`, data);
  return assert(res, res.data, 'Import of asset failed', res);
};
const downloadTemplate = async params => {
  const res = await http.postImg(`${BASE_URL}/template_download/download_template/`, params, {
    responseType: 'blob',
  });
  return assert(res, res.data, 'Import of asset failed', res);
};

const getAssetById = async assetId => {
  const res = await http.get(`${BASE_URL}/asset/${assetId}/`);

  return assert(res, res.data, 'Retrieval of asset by its ID failed', res);
};

const createAsset = async assetData => {
  const res = await http.post(`${BASE_URL}/asset/`, assetData);

  return assert(res, res.data, 'Creation of asset failed', res);
};

const deleteAsset = async assetId => {
  const res = await http.delete(`${BASE_URL}/asset/${assetId}/`);

  return assert(res, assetId, 'Deletion of asset failed', res);
};
const deleteAttachment = async ids => {
  const res = await http.delete(`${BASE_URL}/delete-asset-attachment/${ids.id}/${ids.assetId}/`);

  return assert(res, 'Deletion of asset failed', res);
};
const deletePhoto = async ids => {
  const res = await http.delete(`${BASE_URL}/delete-asset-photo/${ids.id}/${ids.assetId}/`);

  return assert(res, 'Deletion of asset failed', res);
};
const deleteSpecification = async ids => {
  const res = await http.delete(`${BASE_URL}/delete-asset-specification/${ids.id}/${ids.assetId}/`);

  return assert(res, 'Deletion of asset failed', res);
};

const deleteAssetInventory = async ids => {
  const res = await http.delete(`${BASE_URL}/delete-asset-inventory/${ids?.uuid}/${ids.assetId}/`);

  return assert(res, 'Deletion of asset failed', res);
};

const updateAsset = async assetData => {
  const res = await http.put(`${BASE_URL}/asset/${assetData.id}/`, assetData.formData);

  return assert(res, res.data, 'Update of asset failed', res);
};
const getAssetView = async (data) => {
  // const res = await http.get(`${BASE_URL}/asset/?search=${data.searchValue ? data.searchValue : ""}&page=${1}&page_size=${10}&fm=${data?.bool}`);
  const res = await http.get(`${BASE_URL}/asset/?search=${data.searchValue ? data.searchValue : ""}&page=${1}&page_size=${data.rowPerPage}`);
  return assert(res, res.data, 'Get of asset failed', res);
};

// const getAssetView = async () => {
//   const res = await http.get(`${BASE_URL}/asset-list/`);
//   return assert(res, res.data, 'Get of asset failed', res);
// };
const getAssetDepreciation = async id => {
  const res = await http.get(`${BASE_URL}/asset-depreciation/${id}/`);
  return assert(res, res.data, 'Get of asset failed', res);
};

const getAssetHistoryById = async data => {
  let res = '';
  if (data.id) {
    res = await http.get(
      `${BASE_URL}/asset-history/${data.id}/?search=${data.searchValue}&?page=${data.currentPage}&page_size=${data.rowPerPage}`,
    );
  }
  return assert(res, res.data, 'Retrieval of location history by its ID failed', res);
};

const AssetService = {
  downloadTemplate,
  getAssetDepreciation,
  getAssetView,
  getAssetsByAuthentication,
  getAssetById,
  getAssetHistoryById,
  createAsset,
  deleteAsset,
  updateAsset,
  exportAsset,
  importAsset,
  assetSearch,
  getAssetsByPageSize,
  getAssetsByRows,
  deleteAttachment,
  deleteSpecification,
  deleteAssetInventory,
  deletePhoto,
};

export default AssetService;
