 /* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */

import React from 'react';
import { ProgressBar } from 'react-bootstrap';

function OverallProgress ({ type, percent, color }) {
  return (
    <section style={{
        marginBottom:"52px"
    }}>
      <div className="row col-lg-12 col-12 align-items-center">
        <div className="col-3">
          <p
            style={{
              fontFamily: 'Source Sans 3',
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '22px',
              margin: 0, // Remove margin to align properly
            }}
          >
            {type}
          </p>
        </div>
        <div className="col-9">
          <div
            className="progress"
            role="progressbar"
            aria-label="Example 20px high"
            aria-valuenow={percent}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{
                width:'100%',
              height: '20px',
              borderRadius: '30px',
              position: 'relative',
              overflow: 'visible',
            }}
          >
            <div
              className="progress-bar"
              style={{
                width: `${percent}%`,
                height: '100%',
                backgroundColor: color,
                position: 'relative',
                borderRadius: '30px',
              }}
            ></div>
            <div
              className="triangle"
              style={{
                width: '0',
                height: '0',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderTop: `18px solid ${color}`,
                position: 'absolute',
                bottom: '25px',
                left: `calc(${percent}% - 10px)`, // percent progress - half the width of the triangle
              }}
            ></div>
            <div
              className="triangle-label"
              style={{
                position: 'absolute',
                bottom: '53px', // Adjust to position text above the triangle
                left: `calc(${percent}% - 0px)`, // percent progress - adjust based on text width
                transform: 'translateX(-50%)', // Center the text
                whiteSpace: 'nowrap', // Prevents text from wrapping
                fontFamily: 'Source Sans 3',
                fontWeight: 400,
                fontSize: '14px',
              }}
            >
              {percent}%
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OverallProgress;
