import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SearchHeader from '../../components/searchHeader/searchHeader';
import Table from '../../components/crudTable/crudTable';

import InventoryUpdateModal from '../../components/inventory/inventoryUpdateModal';
import InventoryCreateModal from '../../components/inventory/inventoryCreateModal';
import {
  // getInventorysByAuthentication,
  // setInventory,
  // closeInventoryModalCreate,/
  closeInventoryModalUpdate,
  openInventoryModalUpdate,
  reset,
  resetInventory,
  getPagination,
  setSearchValue,
  getInventoryById,
  setCurrentPage,
  setRowPerPage,
  // openInventoryModalCreate,
} from '../../redux/features/inventory/inventorySlice';
import './inventory.scss';
import Pagination from '../../components/pagination/pagination';
import Loader from '../../components/loader/loader';
import { getDepartmentsByAuthentication } from '../../redux/features/department/departmentSlice';
import INVFilter from './INVFilter';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';

export default function Inventories() {
  const [openCreate, setOpenCreate] = useState(false);
  const [columns, setColumns] = useState([
    {
      Header: 'Inventory ID',
      accessor: 'inventory_id',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Price',
      accessor: 'price',
    },
    {
      Header: 'Quantity',
      accessor: 'total',
    },
    {
      Header: 'Minimum Quantity',
      accessor: 'minimum_inventory',
    },
    {
      Header: 'Location',
      accessor: 'location.site',
    },
  ]);
  const [checkedTabs, setCheckedTabs] = useState({
    serial: true,
    name: true,
    type: true,
    price: true,
    quantity: true,
    minquan: true,
    location: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!checkedTabs.serial) {
      const ids = columns.filter((item) => item.accessor !== 'inventory_id');
      
      setColumns(ids);
    } else if (checkedTabs.serial  && !(columns.some(e=>e.accessor === 'inventory_id'))) {
      setColumns([
        ...columns,
        {
          Header: 'Inventory ID',
          accessor: 'inventory_id',
        },
      ]);
    
    } 
    else if (checkedTabs.name  && !(columns.some(e=>e.accessor === 'name'))) {
      setColumns([
        ...columns,
        {
          Header: 'Name',
          accessor: 'name',
        },
      ]);
    }
    else if (!checkedTabs.name) {
      const ids = columns.filter((item) => item.accessor !== 'name');

      setColumns(ids);
    }
    else if (checkedTabs.type  && !(columns.some(e=>e.accessor === 'type'))) {
      setColumns([
        ...columns,
        {
          Header: 'Type',
          accessor: 'type',
        },
      ]);
    }
    else if (!checkedTabs.type) {
      const ids = columns.filter((item) => item.accessor !== 'type');

      setColumns(ids);
    }
    else if (checkedTabs.price  && !(columns.some(e=>e.accessor === 'price'))) {
      setColumns([
        ...columns,
        {
          Header: 'Price',
          accessor: 'price',
        },
      ]);
    }
    else if (!checkedTabs.price) {
      const ids = columns.filter((item) => item.accessor !== 'price');

      setColumns(ids);
    }
    else if (checkedTabs.quantity  && !(columns.some(e=>e.accessor === 'total'))) {
      setColumns([
        ...columns,
        {
          Header: 'Quantity',
          accessor: 'total',
        },
      ]);
    }
    else if (!checkedTabs.quantity) {
      const ids = columns.filter((item) => item.accessor !== 'total');

      setColumns(ids);
    }
    else if (checkedTabs.minquan  && !(columns.some(e=>e.accessor === 'minimum_inventory'))) {
      setColumns([
        ...columns,
        {
          Header: 'Minimum Quantity',
          accessor: 'minimum_inventory',
        },
      ]);
    }
    else if (!checkedTabs.minquan) {
      const ids = columns.filter((item) => item.accessor !== 'minimum_inventory');

      setColumns(ids);
    }
    else if (checkedTabs.location  && !(columns.some(e=>e.accessor === 'location.site'))) {
      setColumns([
        ...columns,
        {
          Header: 'Location',
          accessor: 'location.site',
        },
      ]);
    }
    else if (!checkedTabs.location) {
      const ids = columns.filter((item) => item.accessor !== 'location.site');

      setColumns(ids);
    }
  }, [
    checkedTabs.serial,
    checkedTabs.name,
    checkedTabs.type,
    checkedTabs.price,
    checkedTabs.quantity,
    checkedTabs.minquan,
    checkedTabs.location,
  ]);

  const {
    inventorys,
    inventory,
    isInventoryCreateSuccess,
    isInventoryCreateLoading,
    isInventoryCreateError,
    isInventoryUpdateSuccess,
    isInventoryUpdateLoading,
    isInventoryUpdateError,
    InventoryModalUpdate,
    isInventoryGetLoading,
    isInventoryDeleteSuccess,
    isInventoryDeleteLoading,
    isInventoryDeleteError,
    isInventoryImported,
    isInventoryImportLoading,
    isInventoryImportError,
    searchValue,
    isInventoryGetSuccess,
    filterCategory, 
    filterSite,
    filterSublocation,
    currentPage, 
    rowPerPage
  } = useSelector((state) => state.inventory);
  const invsDataCount = useSelector(state => state.inventory?.inventorys?.count)
  const [inventoryData, setInventoryData] = useState(inventorys.results || []);


const [isLoading, setIsLoading] = useState(false);
// const [currentPage, setCurrentPage] = useState(1);
// const [rowPerPage, setRowPerPage] = useState(10);

// useEffect(() => {
//   if (searchValue !== "") {
//     setCurrentPage(1)
//   }
// }, [searchValue])



useEffect(() => {
  const getDataRows = async ()=>{
    setIsLoading(true)
    await dispatch(getPagination({rowPerPage  , currentPage , searchValue , site: filterSite?.value || null, sublocation: filterSublocation?.value || null, filterCategory: filterCategory?.value || null}))
    setIsLoading(false)
   }
   getDataRows()
 }, [rowPerPage , currentPage , searchValue , filterCategory, filterSite, filterSublocation]);


  useEffect(() => {
    dispatch(reset());
  }, [
    isInventoryCreateSuccess,
    isInventoryCreateLoading,
    isInventoryCreateError,
    isInventoryUpdateSuccess,
    isInventoryUpdateLoading,
    isInventoryUpdateError,
    isInventoryDeleteSuccess,
    isInventoryDeleteLoading,
    isInventoryDeleteError,
    isInventoryGetSuccess
  ]);

  useEffect(() => {
    dispatch(getDepartmentsByAuthentication())
  }, [dispatch]);
  useEffect(() => {
    const modifiedInventoryData = inventorys.results?.map(item => {
      if (Object.prototype.hasOwnProperty.call(item, 'inventory_id')) {
        // Modify the inventory_id attribute
        const modifiedIdNumber = item.inventory_id.length > 30 ?
          `${item.inventory_id.substring(0, 30)}...` : 
          item.inventory_id; 
        return {
          ...item,
          inventory_id: modifiedIdNumber ,
          original_serial:item.inventory_id
        };
      }
      return item;
    });
    setInventoryData(modifiedInventoryData || []);
  }, [inventorys]);

  const openInventoryPopUp = async (row)=>{
    await dispatch(getInventoryById(row?.id))
  }

  useEffect(() => {
    if (isInventoryGetSuccess) {
      dispatch(openInventoryModalUpdate());
    }
  }, [isInventoryGetSuccess])
  



  function displayToast() {
    if (isInventoryCreateError) {
      toast.error('Something went wrong Creating Inventory', {
        toastId: 'CreatingInventoryError',
      });
    } else if (isInventoryCreateSuccess) {
      toast.success('Creating Inventory successfully', {
        toastId: 'Inventory Created Success',
      });
    } else if (isInventoryUpdateError) {
      toast.error('Something went wrong Updating Inventory', {
        toastId: 'UpdatingInventoryError',
      });
    } else if (isInventoryUpdateSuccess) {
      toast.success('Updating Inventory successfully', {
        toastId: 'Inventory Updated Success',
      });
    } else if (isInventoryDeleteError) {
      toast.error('Something went wrong Delteing Inventory', {
        toastId: 'DeletingInventoryError',
      });
    } else if (isInventoryDeleteSuccess) {
      toast.success('Deleting Inventory successfully', {
        toastId: 'Inventory deleted Success',
      });
    
    } else if (isInventoryImported) {
      toast.success('Importing Inventory successfully', {
        toastId: 'Inventory importing Success',
      });
    
    } else if (isInventoryImportError) {
      toast.error('Something went wrong Importing Inventory', {
        toastId: 'ImportingInventoryError',
      });
    }
  }



  return  isInventoryCreateLoading || isInventoryUpdateLoading || isInventoryDeleteLoading || isInventoryImportLoading ? (
    <Loader />
  ) : (
    <div className="assets">
      {displayToast()}
      <SearchHeader
        pageName="Inventories/Parts"
        includeFilterButton
        includeEllipsisButton
        includeAddButton
        addButtonTitle="Add Inventories/Parts"
        pagePath="/inventories"
        type="inventory"
        invTabs={checkedTabs}
        handleInvTabs={setCheckedTabs}
      >
        <div className="row searc-head">
          <input
            placeholder="Search"
            className="rgfloat search-bar float-right "
            // value={search}
            onChange={(e) => dispatch(setSearchValue(e.target.value))}
          />
          <button
            type="button"
            title="Add Inventories/Parts"
            onClick={() => {
            setOpenCreate(true)
             dispatch(resetInventory()) 
             dispatch(selectActions.reset())
            }}
            
            className="btn primary-btn ml-2 "
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </SearchHeader>
      <INVFilter />
      {isInventoryGetLoading ? <Loader /> :
      <div className="min-h-screen text-gray-900">
        <div className="mt-6">
          {inventoryData && inventoryData.length >= 0 ? (
            <>            
            <Table
              columns={columns}
              data={inventoryData}
              isLoading={isLoading}
              onRowClick={(row) => {               
                openInventoryPopUp(row)
              }}
            >
              <InventoryUpdateModal
                closeModalUpdate={() => dispatch(closeInventoryModalUpdate())}
                inventoryData={inventory}
                openModalUpdate={InventoryModalUpdate}
              />
              <InventoryCreateModal
                closeModalCreate={() => setOpenCreate(false)}
                openModalCreate={openCreate}
                inventoryData={inventoryData}
              />
            </Table>
            <Pagination 
            totalRows={invsDataCount}
            rowPerPage={rowPerPage}
            pageChangeHandler={setCurrentPage}
            changeRowsPerPage={setRowPerPage}
            currentPage={currentPage}
            />
            </>
          ) : (
            //   <Table
            //   columns={columns}
            //   data={[]}
            //   // onRowClick={(row) => {
            //   //   dispatch(setInventory(row));
            //   //   dispatch(openInventoryModalUpdate());
            //   // }}
            // >
            <>
              <InventoryUpdateModal
                closeModalUpdate={() => dispatch(closeInventoryModalUpdate())}
                inventoryData={inventory}
                openModalUpdate={InventoryModalUpdate}
              />
              <InventoryCreateModal
                closeModalCreate={() => setOpenCreate(false)}
                openModalCreate={openCreate}
                inventoryData={inventoryData}
              />
            </>
            // </Table>/
          )}
        </div>
      </div>
      }
    </div>
  );
}
