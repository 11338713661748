import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getWorkOrderCategoryByAuthentication  = async () => {
  const res = await http.get(
    `${BASE_URL}/work-order-category/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of workOrderCategory by its company ID failed",
    res
  );
};

const getWorkOrderCategoryById = async (workOrderCategoryId) => {
  const res = await http.get(
    `${BASE_URL}/work-order-category/${workOrderCategoryId}`
  );

  return assert(
    res,
    res.data,
    "Retrieval of workOrderCategory by its ID failed",
    res
  );
};

const createWorkOrderCategory = async (data) => {
  const res = await http.post(
    `${BASE_URL}/work-order-category/`,
    data
  );

  return assert(res, res.data, "Creation of workOrderCategory failed", res);
};

const updateWorkOrderCategory = async (data) => {
  const res = await http.put(
    `${BASE_URL}/work-order-category/${data.id}/`,
    data
  );
  return assert(res, res.data, "Update of workOrderCategory failed", res);
};

const deleteWorkOrderCategory = async (workOrderCategoryId) => {
  const res = await http.delete(
    `${BASE_URL}/work-order-category/${workOrderCategoryId}/`
  );
  return assert(res, workOrderCategoryId, "Deletion of workOrderCategory failed", res);
};

const workOrderCategoryService = {
  getWorkOrderCategoryByAuthentication,
  getWorkOrderCategoryById,
  createWorkOrderCategory,
  updateWorkOrderCategory,
  deleteWorkOrderCategory,
};

export default workOrderCategoryService;
