import { createSlice } from "@reduxjs/toolkit";

const initState = {
  teamType: null,
  assetType: null,
  categoryType: null,
  inventoryType: null,
  tab: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
};

export const editCategorySlice = createSlice({
  name: "editCategory",
  initialState: initState,
  reducers: {
    setTeamType: (state, action) => {
      state.teamType = action.payload;
    },
    setAssetType: (state, action) => {
      state.assetType = action.payload;
    },
    setCategoryType: (state, action) => {
      state.categoryType = action.payload;
    },
    setInventoryType: (state, action) => {
      state.inventoryType = action.payload;
    },
    setTab: (state, action) => {
      state.tab = action.payload;
    }
  },
  extraReducers: {},
});

export const {
  setTeamType,
  setAssetType,
  setCategoryType,
  setInventoryType,
  setTab,
} = editCategorySlice.actions;

export default editCategorySlice.reducer;
