/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable */

// /* eslint-disable prefer-template, no-unsafe-optional-chaining */
// import React from 'react';
// import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

// const styles = StyleSheet.create({
//   detailsText: {
//     textAlign: 'left',
//     fontSize: '11px',
//     // fontFamily: 'Open Sans, sans-serif',
//     fontWeight: '500',
//   },
// });

// const SubLocationQrPrint = ({ location, company }) => {
//   return (
//     <Document>
//       <Page size={{ width: 227, height: 113 }}>
//         <View
//           style={{
//             flexDirection: 'row',
//             justifyContent: 'space-between',
//           }}
//         >
//           <View>
//             <Image
//               style={{ width: '100px', height: '105px' }}
//               src={`${location?.qr_code}?noChache=shimon9696`}
//               source={{
//                 header: {
//                   'Access-Control-Allow-Origin': '*',
//                 },
//               }}
//               alt="Qr code"
//             />
//           </View>
//           <View style={{ paddingLeft: '3px', paddingRight: '3px', width: 110 }}>
//             <View
//               style={{
//                 flexDirection: 'row',
//                 justifyContent: 'center',
//               }}
//             >
//               {/* <Image src="https://foxasset-development.s3.amazonaws.com/media/company_logos/example.png?noChache=shimon969696" source={{
//           header:{
//             'Access-Control-Allow-Origin': '*'
//           }
//         }} /> */}
//               <Image
//                 style={{ width: '70px', height: '60px', marginTop: '-3px' }}
//                 src={`${company}?noChache=shimon969696`}
//                 source={{
//                   header: {
//                     'Access-Control-Allow-Origin': '*',
//                   },
//                 }}
//                 alt="Company logo"
//               />
//             </View>
//             <View>
//               <Text style={styles.detailsText}>
//                 {location?.room ? 'Room: ' : 'Location: '}{' '}
//                 {location?.site
//                   ? location?.site
//                   : location?.building +
//                     ' ' +
//                     location?.level +
//                     ' ' +
//                     location?.suite +
//                     ' ' +
//                     location?.room}
//               </Text>
//             </View>
//           </View>
//         </View>
//       </Page>
//     </Document>
//   );
// };

// export default SubLocationQrPrint;

import React from 'react';
import { jsPDF } from "jspdf";
import imageSample from '../../assets/img/inventory_qr_code.png'
import companyLogo from '../../assets/img/download (1).png'

const SubLocationQrPrint = ({ location, company }) => {
  const generatePDF = () => {
    const pdf = new jsPDF({
      orientation: 'landscape', // or 'landscape'
      unit: 'pt',
      format: [245,140]// specify your custom page size in points
    });

    pdf.setFontSize(11);
    // const companyLogoUrl = companyLogo;
    const companyLogoUrl = `${company}?noChache=shimon969696`;
    pdf.addImage(companyLogoUrl, 'PNG', 110,20,50,50);
    
    // const qrCodeDataUrl = imageSample;
    const qrCodeDataUrl = `${location?.qr_code}?noChache=shimon9696`;
    pdf.addImage(qrCodeDataUrl, 'PNG',0, 7, 110, 118);

    let textToDisplay = '';
    if(location?.room){
        const arr = [location?.building, location?.level, location?.suite, location?.room].filter(item => item !== "");
        textToDisplay = "Room: " + arr.join(", ");
    } else {
        textToDisplay = "Location: " + location?.site;
    }

    const textLines = pdf.splitTextToSize(textToDisplay, 130); // Adjust width as needed
    pdf.text(textLines, 110, 85);

    // Save the generated PDF and open in a new window
    const pdfBlob = pdf.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);

    window.open(pdfUrl, '_blank');
};

    return (
        <span onClick={generatePDF}>QR</span>
    );
};

export default SubLocationQrPrint;