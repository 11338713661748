/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-for */

import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getSLAsByAuthentication,
  closeSLAModalCreate,
  closeSLAModalDelete,
  closeSLAModalUpdate,
  createSLA,
  deleteSLA,
  openSLAModalCreate,
  openSLAModalUpdate,
  setSLA,
  updateSLA,
  reset,
  setPriority,
  setInterval,
  setTimeUnit,
  resetSLA,
  openSLAModalDelete,
} from '../../../redux/features/SLA/SLASlice';
import PopUpDeleteModal from '../../../components/popUpModals/editCategory/popUpDeleteModal';
import PopUpEditModal from '../../../components/popUpModals/editCategory/popUpEditModal';
import PopUpAddModal from '../../../components/popUpModals/editCategory/popUpAddModal';

export default function SLATab() {
  const {
    priority,
    interval,
    timeUnit,
    SLAs,
    SLAModalDelete,
    SLAModalUpdate,
    SLAModalCreate,
    SLA,
    isSLACreateLoading,
    isSLACreateError,
    isSLACreateSuccess,
    isSLADeleteLoading,
    isSLADeleteError,
    isSLADeleteSuccess,
    isSLAUpdateLoading,
    isSLAUpdateError,
    isSLAUpdateSuccess,
    isSLAGetLoading,
    isSLAGetError,
  } = useSelector(state => state.SLA);
  const dispatch = useDispatch();
  const [deletedItem, setDeletedItem] = useState(null)
  const handleDelete = id => {
    dispatch(openSLAModalDelete())
    setDeletedItem(id)
  };
  const handleConfirm = () => {
    dispatch(deleteSLA(deletedItem));
    dispatch(closeSLAModalDelete());
    setDeletedItem(null)
  };

  const handleEdit = data => {
    dispatch(openSLAModalUpdate());
    dispatch(setPriority(data.priority))
    dispatch(setInterval(data.interval))
    dispatch(setTimeUnit(data.time_unit))
    dispatch(setSLA(data))
  };

  const handleEditSave = () => {
    if (priority && interval && timeUnit) {
      dispatch(updateSLA({ ...SLA, priority, interval, time_unit: timeUnit }));
      dispatch(closeSLAModalUpdate());
    }
  };

  const handleCreateSave = () => {
    if (priority && interval && timeUnit) {
      dispatch(createSLA({ priority, interval, time_unit: timeUnit }));
      dispatch(closeSLAModalCreate());
      dispatch(reset());
    }
  };

  useEffect(() => {
    dispatch(reset());
  }, [
    SLAs,
    SLA,
    isSLACreateLoading,
    isSLACreateError,
    isSLACreateSuccess,
    isSLADeleteLoading,
    isSLADeleteError,
    isSLADeleteSuccess,
    isSLAUpdateLoading,
    isSLAUpdateError,
    isSLAUpdateSuccess,
    isSLAGetLoading,
    isSLAGetError,
  ]);

  const handelPriority = (e) => {
    dispatch(setPriority(e.target.value))
  }
  const handelInterval = (e) => {
    dispatch(setInterval(e.target.value))
  }
  const handelTimeUnit = (e) => {
    dispatch(setTimeUnit(e.target.value))
  }
  function displayToast() {
    if (isSLACreateSuccess) {
      toast.success('SLA created successfully', {
        toastId: 'SLACreateSuccess',
      });
    }
    if (isSLADeleteSuccess) {
      toast.success('SLA deleted successfully', {
        toastId: 'SLADeleteSuccess',
      });
    }
    if (isSLAUpdateSuccess) {
      toast.success('SLA updated successfully', {
        toastId: 'SLAUpdateSuccess',
      });
    }
    if (isSLAGetError) {
      toast.error('Error getting SLA', {
        toastId: 'SLAGetError',
      });
    }
    if (isSLACreateError) {
      toast.error('Error creating SLA', {
        toastId: 'SLACreateError',
      });
    }
    if (isSLADeleteError) {
      toast.error('Error deleting SLA', {
        toastId: 'SLADeleteError',
      });
    }
    if (isSLAUpdateError) {
      toast.error('Error updating SLA', {
        toastId: 'SLAUpdateError',
      });
    }
    if (isSLAGetError) {
      toast.error('Error getting SLA', {
        toastId: 'SLAGetError',
      });
    }
  }

  useEffect(() => {
    dispatch(getSLAsByAuthentication());
    dispatch(reset());
  }, [dispatch]);
  return isSLACreateLoading ||
    isSLADeleteLoading ||
    isSLAUpdateLoading ||
    isSLAGetLoading ? (
    <div id="loader" />
  ) : (
    <>
      {displayToast()}
      {SLAModalUpdate && (
        <PopUpEditModal
          handleClose={() => dispatch(closeSLAModalUpdate())}
          showModal={SLAModalUpdate}
          handleSave={handleEditSave}
          modalTitle="Edit SLA"
          primaryButtonText="Save Changes"
        >
          <>
            <div className="col-lg-12 col-sm-12">
              <label className="font-weight-normal ">Priority<label className="font-danger m-0">*</label></label>
              <select className="form-control select-dash custom-select2 mb-2"
                name="priority2" id="priority2"
                onChange={handelPriority}
                value={priority}
              >
                <option value="" disabled selected>--SELECT--</option>
                {['Critical', 'High', 'Medium', 'Low'].map(val => (
                  <option
                    value={val}
                    key={val}
                  >{val}</option>
                ))}
              </select>
            </div>
            <div className="col flex justify-content-between align-items-center">
              <div className=" col-lg-4 col-md-4 col-sm-5">
                <label className="font-weight-normal" >Response time  <label className="font-danger m-0">*</label></label>
                <input name='interval' type="number" onChange={handelInterval} className="form-control mb-2"
                  value={interval} placeholder="Every" id="meter_interval" min="0"
                />

              </div>

              <div className=" col-lg-8 col-md-8 col-sm-7">
                <label className="font-weight-normal">Time Unit <label className="font-danger m-0">*</label></label>
                <select className="select-meter p-0 mb-2"
                  name="frequency"
                  id="meter_frequency" required
                  onChange={handelTimeUnit}
                  value={timeUnit}
                >

                  <option selected disabled value="">Select a Time Unit</option>
                  {
                    ['Day', 'Hour'].map(freq =>
                      <option key={freq} value={freq}>{freq}(s)</option>
                    )
                  }

                </select>
              </div>
            </div>
          </>
        </PopUpEditModal>
      )}
      {SLAModalDelete && (
        <PopUpDeleteModal
          showModal={SLAModalDelete}
          handleClose={() => dispatch(closeSLAModalDelete())}
          handleConfirm={handleConfirm}
          modalTitle="Delete SLA"
          modalBodyText="Are you sure you want to delete this SLA?"
        />

      )}

      {SLAModalCreate && (
        <PopUpAddModal
          showModal={SLAModalCreate}
          handleClose={() => dispatch(closeSLAModalCreate())}
          modalTitle="Add SLA"
          primaryButtonText="Create SLA"
          handleCreateSave={handleCreateSave}
        >
          <>
            {/* <div className="col-lg-12 col-sm-12 pt-2">
              <label className="font-weight-normal">SLA Title  <label className="font-danger m-0">*</label></label>
              <input required name='meter_name' onChange={handelTitle} type="text" className="form-control mb-2"
                value={title} placeholder="SLA Title" id="meter_name" />
              <div className="col-lg-12 col-sm-12 m-0"><span className="font-danger" id="meter_error"></span></div>
            </div> */}
            <div className="col-lg-12 col-sm-12 ">
              <label className="font-weight-normal ">Priority<label className="font-danger m-0">*</label></label>
              <select className="form-control select-dash custom-select2 mb-2"
                name="priority2" id="priority2"
                onChange={handelPriority}
                value={priority}
              >
                <option value="" disabled selected>--SELECT--</option>
                {['Critical', 'High', 'Medium', 'Low'].map(val => (
                  <option
                    value={val}
                    key={val}
                  >{val}</option>
                ))}
              </select>
            </div>
            <div className="col flex justify-content-between align-items-center">
              <div className=" col-lg-4 col-md-4 col-sm-5">
                <label className="font-weight-normal" >Response time  <label className="font-danger m-0">*</label></label>
                <input name='interval' type="number" onChange={handelInterval} className="form-control mb-2"
                  value={interval} placeholder="Every" id="meter_interval" min="0"
                />

              </div>

              <div className=" col-lg-8 col-md-8 col-sm-7">
                <label className="font-weight-normal">Time Unit <label className="font-danger m-0">*</label></label>
                <select className="select-meter p-0 mb-2"
                  name="frequency"
                  id="meter_frequency" required
                  onChange={handelTimeUnit}
                  value={timeUnit}
                >

                  <option selected disabled value="">Select a Time Unit</option>
                  {
                    ['Day', 'Hour'].map(freq =>
                      <option key={freq} value={freq}>{freq}(s)</option>
                    )
                  }

                </select>
              </div>


            </div>
          </>
        </PopUpAddModal>
      )}
      <h3 className="card-title py-3 px-3 col-lg-12">Service Level Agreement (SLA)</h3>
      <div className="card-body p-0 m-0" id="location-block">
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title edit-category-title-inline">
                  <span>
                    <b>SLA Details</b> <i>(Option select)</i>
                  </span>
                </span>
                <div className="card-tools">
                  {SLAs?.length < 4 && <button
                    type="button"
                    className="font-danger cursor_pointer"
                    data-card-widget="collapse"
                    onClick={() => {
                      dispatch(openSLAModalCreate())
                      dispatch(resetSLA())
                    }}
                  >
                    Add
                  </button>}
                </div>
              </div>
              <div className="card-body p-0" id="spareparts-category-block">
                {SLAs && SLAs.length > 0 ? (
                  SLAs?.map(SLA => (
                    <div
                      className="card edit-category-card-inline"
                      id="spareparts-category-block-{{spareparts_category.id}}"
                    >
                      <div className="card-header">
                        <span className="card-title edit-category-card-title-inline">
                          <span
                            title="{{spareparts_category.category}}"
                            data-target="#modal-add-spareparts-category"
                            data-toggle="modal"
                          >
                            <b id="spareparts-category-value-{{spareparts_category.id}}">
                              {SLA?.priority}
                            </b>
                          </span>
                        </span>
                        <div className="card-tools">
                          <button
                            type="button"
                            onClick={() => {
                              handleEdit(SLA);
                            }}
                            className="fa fa-edit font-danger cursor_pointer"
                          >
                            {' '}
                          </button>
                          <button
                            type="button"
                            className="btn btn-tool"
                            onClick={() => {
                              handleDelete(SLA?.id);
                            }}
                          >
                            <i
                              className="fa fa-trash m-1 font-danger cursor_pointer"
                              title="Delete "
                              data-target="#modal-delete-spareparts-category"
                              data-toggle="modal"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="card edit-category-card-inline">
                    <div className="card-header">
                      <span className="card-title edit-category-card-title-inline">
                        <span
                          title="No SLA"
                          data-target="#modal-add-spareparts-category"
                          data-toggle="modal"
                        >
                          <b id="spareparts-category-value-">No SLA</b>
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                <input type="hidden" value="{{spareparts_category.category}}" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
