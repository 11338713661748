import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import triggerService  from './workOrderTriggerService';
import { smartTryCatch } from '../../../utils';


const initState = {
  // Ticket Log
  triggers :[],
  trigger : {},

  isShowTrigger : false,
  isEditing : false,
  // Log fields
  triggerId: "",
  trValue: "",
  is_onetime: false,
  trTitle :"" ,
  trDescription : "",
  trPriority : "",
  is_everytime : false,
  condition : "",
  trCategory : "",
  trAssigned_to : [],
  meter : "",

  
  

  // success states
  isTriggerGetSuccess: false,
  isTriggerCreateSuccess: false,
  isTriggerDeleteSuccess: false,
  isTriggerUpdateSuccess: false,
 

  // loading states
  isTriggerCreateLoading: false,
  isTriggerGetLoading: false,
  isTriggerDeleteLoading: false,
  isTriggerUpdateLoading: false,


  // error states
  isTriggerGetError: false,
  isTriggerCreateError: false,
  isTriggerDeleteError: false,
  isTriggerUpdateError: false,


};

export const createTrigger = createAsyncThunk('createTrigger', async (data, thunkAPI) => {
  const createTriggerRes = await smartTryCatch(triggerService.createTrigger, data, thunkAPI);
  return createTriggerRes;
});

export const getTriggersByAuthentication = createAsyncThunk(
  'getTriggersByAuthentication',
  async (data, thunkAPI) => {
    const getTriggers = await smartTryCatch(
      triggerService.getTriggersByAuthentication,
      data,
      thunkAPI,
    );
    return getTriggers;
  },
);

export const getTriggerById = createAsyncThunk('getTriggerById', async (data, thunkAPI) => {
  const getTrigger = await smartTryCatch(triggerService.getTriggerById, data, thunkAPI);
  return getTrigger;
});


export const deleteTrigger = createAsyncThunk('deleteTrigger', async (data, thunkAPI) => {
  const deleteTriggerRes = await smartTryCatch(triggerService.deleteTrigger, data, thunkAPI);
  return deleteTriggerRes;
});

export const updateTrigger = createAsyncThunk('updateTrigger', async (data, thunkAPI) => {
  const updateTriggerRes = await smartTryCatch(triggerService.updateTrigger, data, thunkAPI);
  return updateTriggerRes;
});


export const triggerSlice = createSlice({
  name: 'trigger',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isTriggerCreateError = false;
      state.isTriggerCreateSuccess = false;
      state.isTriggerCreateLoading = false;
      state.isTriggerGetError = false;
      state.isTriggerGetSuccess = false;
      state.isTriggerGetLoading = false;
      state.isTriggerDeleteError = false;
      state.isTriggerDeleteSuccess = false;
      state.isTriggerDeleteLoading = false;
      state.isTriggerUpdateError = false;
      state.isTriggerUpdateSuccess = false;
      state.isTriggerUpdateLoading = false;
      // state.Trigger = null;
    },
    showTrigger : (state)=>{
      state.isShowTrigger = true
    },
    closeTrigger : (state)=>{
      state.isShowTrigger = false
    },
    setIsEditing : (state , action)=>{
      state.isEditing  = action.payload;
    },
   
    setTriggerId : (state, action) => {
      state.triggerId = action.payload;
    },
    setTrValue : (state, action) => {
      state.trValue = action.payload;
    },
    setTrDescription : (state, action) => {
      state.trDescription = action.payload;
    },
    setIsEverytime : (state, action) => {
      state.is_everytime = action.payload;
    },
  setOneTime : (state, action) => {
    state.is_onetime = action.payload;
  },
  setTrTitle : (state, action) => {
    state.trTitle = action.payload;
  },
  setTrPriority : (state, action) => {
    state.trPriority = action.payload;
  },
  setTrCategory : (state, action) => {
    state.trCategory = action.payload;
  },
  
  setCondition : (state, action) => {
    state.condition = action.payload;
  },
  setTrAssignedTo : (state, action) => {
    state.trAssigned_to = action.payload;
  },
  setMeter : (state, action) => {
    state.meter = action.payload;
  },
    resetTrigger: (state) => {
    state.isShowTrigger=false
    state.isEditing=false
    state.trValue= ""
    state.is_onetime= false
    state.trTitle = ""
    state.trDescription = ""
    state.trPriority = ""
    state.is_everytime = ""
    state.condition = ""
    state.trCategory = ""
    state.trAssigned_to = []
    state.meter = ""
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTrigger.pending, (state) => {
        state.isTriggerCreateLoading = true;
        state.isTriggerCreateSuccess = false;
        state.isTriggerCreateError = false;
      })
      .addCase(createTrigger.fulfilled, (state, action) => {
        state.isTriggerCreateLoading = false;
        state.isTriggerCreateSuccess = true;
        state.isTriggerCreateError = false;
        state.triggers.push(action.payload);
      })
      .addCase(createTrigger.rejected, (state) => {
        state.isTriggerCreateLoading = false;
        state.isTriggerCreateSuccess = false;
        state.isTriggerCreateError = true;
      })
      .addCase(getTriggersByAuthentication.pending, (state) => {
        state.isTriggerGetLoading = true;
        state.isTriggerGetSuccess = false;
        state.isTriggerGetError = false;
      })
      .addCase(getTriggersByAuthentication.fulfilled, (state, action) => {
        state.isTriggerGetLoading = false;
        state.isTriggerGetSuccess = true;
        state.isTriggerGetError = false;
        state.triggers = action.payload;
      })
      .addCase(getTriggersByAuthentication.rejected, (state) => {
        state.isTriggerGetLoading = false;
        state.isTriggerGetSuccess = false;
        state.isTriggerGetError = true;
      })
      .addCase(getTriggerById.pending, (state) => {
        state.isTriggerGetLoading = true;
        state.isTriggerGetSuccess = false;
        state.isTriggerGetError = false;
      })
      .addCase(getTriggerById.fulfilled, (state, action) => {
        state.isTriggerGetLoading = false;
        state.isTriggerGetSuccess = true;
        state.isTriggerGetError = false;
        state.trigger = action.payload;
      })
      .addCase(getTriggerById.rejected, (state) => {
        state.isTriggerGetLoading = false;
        state.isTriggerGetSuccess = false;
        state.isTriggerGetError = true;
      })
      .addCase(deleteTrigger.pending, (state) => {
        state.isTriggerDeleteLoading = true;
        state.isTriggerDeleteSuccess = false;
        state.isTriggerDeleteError = false;
      })
      .addCase(deleteTrigger.fulfilled, (state, action) => {
        state.isTriggerDeleteLoading = false;
        state.isTriggerDeleteSuccess = true;
        state.isTriggerDeleteError = false;
        state.triggers = state.triggers.filter((trigger) => trigger.id !== action.payload);
      })
      .addCase(deleteTrigger.rejected, (state) => {
        state.isTriggerDeleteLoading = false;
        state.isTriggerDeleteSuccess = false;
        state.isTriggerDeleteError = true;
      })
      .addCase(updateTrigger.pending, (state) => {
        state.isTriggerUpdateLoading = true;
        state.isTriggerUpdateSuccess = false;
        state.isTriggerUpdateError = false;
      })
      .addCase(updateTrigger.fulfilled, (state, action) => {
        state.isTriggerUpdateLoading = false;
        state.isTriggerUpdateSuccess = true;
        state.isTriggerUpdateError = false;
        state.triggers = state.triggers?.map((trigger) =>
        trigger.id === action.payload.id ? action.payload : trigger,
        );
      })
      .addCase(updateTrigger.rejected, (state) => {
        state.isTriggerUpdateLoading = false;
        state.isTriggerUpdateSuccess = false;
        state.isTriggerUpdateError = true;
      })
  
},
});

export const {
  reset,
  resetTrigger,
  showTrigger,
  closeTrigger,
  setIsEditing,
  setTriggerId,
  setTrValue,
  setTrDescription,
  setOneTime,
  setTrTitle,
  setTrPriority,
  setTrCategory,
  setIsEverytime,
  setCondition,
  setTrAssignedTo,
  setMeter,
} = triggerSlice.actions;

export default triggerSlice.reducer;
