/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Modal } from 'react-bootstrap/';

function PopUpAddModal({
  showModal,
  handleClose,
  modalTitle,
  primaryButtonText,
  handleCreateSave,
  children,
}) {
  return (
    <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={handleClose}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleCreateSave}>
            {primaryButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default PopUpAddModal;
