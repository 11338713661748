import React from 'react'

function PreviewIcon() {
    return (
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.45426 5.58588C1.28348 5.21432 1.28348 4.78568 1.45426 4.41412C2.24481 2.69421 3.98281 1.5 5.99972 1.5C8.01664 1.5 9.75463 2.69421 10.5452 4.41412C10.716 4.78568 10.716 5.21432 10.5452 5.58588C9.75463 7.30579 8.01664 8.5 5.99972 8.5C3.98281 8.5 2.24481 7.30579 1.45426 5.58588Z"
                stroke="#36383C" 
                strokeWidth="1.5" />
            <path d="M7.49972 5C7.49972 5.82843 6.82815 6.5 5.99972 6.5C5.1713 6.5 4.49972 5.82843 4.49972 5C4.49972 4.17157 5.1713 3.5 5.99972 3.5C6.82815 3.5 7.49972 4.17157 7.49972 5Z"
                stroke="#36383C" 
                strokeWidth="1.5" />
        </svg>
    )
}

export default PreviewIcon