import React from 'react';

export default function NavBarsIcon() {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="14" height="4" viewBox="0 0 14 4" fill="none">
    //   <path
    //     d="M6.74992 1.74997H7.24992M6.74992 2.24997H7.24992M2.08325 1.74997H2.58325M2.08325 2.24997H2.58325M11.4166 1.74997H11.9166M11.4166 2.24997H11.9166M7.66675 2.00001C7.66675 2.3682 7.36827 2.66668 7.00008 2.66668C6.63189 2.66668 6.33341 2.3682 6.33341 2.00001C6.33341 1.63182 6.63189 1.33334 7.00008 1.33334C7.36827 1.33334 7.66675 1.63182 7.66675 2.00001ZM3.00008 2.00001C3.00008 2.3682 2.7016 2.66668 2.33341 2.66668C1.96523 2.66668 1.66675 2.3682 1.66675 2.00001C1.66675 1.63182 1.96523 1.33334 2.33341 1.33334C2.7016 1.33334 3.00008 1.63182 3.00008 2.00001ZM12.3334 2.00001C12.3334 2.3682 12.0349 2.66668 11.6667 2.66668C11.2986 2.66668 11.0001 2.3682 11.0001 2.00001C11.0001 1.63182 11.2986 1.33334 11.6667 1.33334C12.0349 1.33334 12.3334 1.63182 12.3334 2.00001Z"
    //     stroke="#36383C"
    //     strokeWidth="1.75"
    //     strokeLinecap="round"
    //   />
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.66675 1.33334C7.40313 1.33334 8.00008 1.9303 8.00008 2.66668C8.00008 3.40306 7.40313 4.00001 6.66675 4.00001H4.00008C3.2637 4.00001 2.66675 3.40306 2.66675 2.66668C2.66675 1.9303 3.2637 1.33334 4.00008 1.33334L6.66675 1.33334Z"
        stroke="#36383C"
        strokeWidth="1.75"
      />
      <path
        d="M9.33341 6.66668C10.0698 6.66668 10.6667 7.26363 10.6667 8.00001C10.6667 8.73639 10.0698 9.33334 9.33341 9.33334H4.00008C3.2637 9.33334 2.66675 8.73639 2.66675 8.00001C2.66675 7.26363 3.2637 6.66668 4.00008 6.66668H9.33341Z"
        stroke="#36383C"
        strokeWidth="1.75"
      />
      <path
        d="M12.0001 12C12.7365 12 13.3334 12.597 13.3334 13.3333C13.3334 14.0697 12.7365 14.6667 12.0001 14.6667H4.00008C3.2637 14.6667 2.66675 14.0697 2.66675 13.3333C2.66675 12.597 3.2637 12 4.00008 12H12.0001Z"
        stroke="#36383C"
        strokeWidth="1.75"
      />
    </svg>
  );
}
