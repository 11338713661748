/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase  */
/* eslint-disable jsx-a11y/label-has-associated-control  */
/* eslint-disable jsx-a11y/label-has-for  */

import React from 'react';
import { useSelector } from 'react-redux'
import Card from "../../../Ui/Card"
import classes from "../addAsset/addAssetForm/assetForm.module.css"
import {
  SelectAssetType, SelectCategory,
  SelectLocation, SelectPersons, SelectSite, SelectStatus
} from '../../../components/select/select'
import { setAssignedTo } from '../../../redux/features/asset/assetSlice';
import AssetLoan from '../../../components/loan/assetLoan';

function FetchAssetDetailsForm({ subLocation,
  allSubLocations, assetCategories, locations,
  assetStatuses, assetDetailsForm, handelChange, generateAssetNumber , handleChangeLoanEligability }) {
  const { assigned_to, asset  } = useSelector(state => state.asset)
  const teams = useSelector(state => state.teams?.teams?.results)
  const { assetTypes } = useSelector(state => state.assetType);
  const { departments } = useSelector(state => state.department);
  const { selectedSite } = useSelector(
    state => state.selectOptions,
  );
  const { user } = useSelector(state => state.user);
  return (
    <div className={classes.assetForm}>
      <Card>
        <h3 className={classes.locTitle}>Location Details</h3>
        <div className={classes.seletions}>
          <SelectSite assetInfo={asset} locations={locations} />
          {selectedSite && <SelectLocation assetInfo={asset} subLocation={subLocation}
            allSubLocations={allSubLocations} />}
        </div>
      </Card>
      <Card>
        <div className={classes.assetInfo}>
          <h3>Asset Details</h3>
          <div className={classes.controls}>
            <div className={classes.control}>
              <p>
                Name <span>*</span>
              </p>
              <input onChange={handelChange}
                value={assetDetailsForm.assetName}
                name='assetName'
                type="text" placeholder='Asset Name' />
            </div>
            <div className={classes.control}>
              <p>
                Asset ID <span>*</span>
              </p>
              <input onChange={handelChange}
                value={assetDetailsForm.assetNumber}
                name='assetNumber' type="text" placeholder='Asset ID' />
              <button
                className="a-color"
                id="auto_serial_number_btn"
                type="button"
                onClick={generateAssetNumber}
              >
                <span className="button__text">Generate Asset ID </span>
                <i className="fa fa-refresh" aria-hidden="true">
                  {' '}
                </i>
              </button>
            </div>
            <div className={classes.control}>
              <p>
                Serial Number
              </p>
              <input onChange={handelChange}
                value={assetDetailsForm.serialNumber}
                name='serialNumber' type="text" placeholder='Serial Number' />
              {/* <button
          className="a-color"
          id="auto_serial_number_btn"
          type="button"
          onClick={generateSerialNumber}
              >
                <span className="button__text">Generate Serial Number </span>
                <i className="fa fa-refresh" aria-hidden="true">
                  {' '}
                </i>
          </button> */}
            </div>
            <div className={classes.control}>
              <SelectAssetType assetTypes={assetTypes} />
            </div>
            <div className={classes.control}>
              <div className={classes.control}>
                <p>
                  Brand
                </p>
              </div>
              <input onChange={handelChange}
                value={assetDetailsForm.brand}
                name='brand' type="text" placeholder='Brand' />
            </div>
            <div className={classes.control}>

              <SelectCategory assetCategories={assetCategories} />
            </div>
            <div className={classes.control}>
              <SelectStatus assetStatuses={assetStatuses} />
            </div>
            <div className={classes.control}>
              {user.role === 'CliAdm' &&
                <div className={classes.select} style={{ marginTop: "-5px", marginBottom: "20px", padding: 0, marginLeft: "20px", paddingRight: "20px" }}>
                  <label className="font-weight-normal">Asset Department</label>
                  <div className="col-12 p-0">
                    <select
                      name='department'
                      onChange={handelChange}
                      value={assetDetailsForm.department}
                      className='form-control select-dash custom-select2'
                    >
                      <option value="" disabled selected>--Select--</option>
                      {departments?.map((singleDepartment) => (
                        <option key={departments.id} value={singleDepartment.id}>{singleDepartment.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="col-lg-12 col-sm-12" style={{ marginTop: "-15px", marginBottom: "20px", padding: 0 }}>
            <label className="font-weight-normal">Custodian</label>
            <div className="col-12 p-0">
              <SelectPersons teams={teams} assignedTo={assigned_to} setAssignedTo={setAssignedTo} />
            </div>
          </div>
        </div>
        <div className={classes.textarea}>
          <p>
            Description
          </p>
          <textarea onChange={handelChange}
            value={assetDetailsForm.description}
            name='description' placeholder="Description" />
        </div>
      </Card>
        <Card>
        <h3 className={classes.locTitle}>Asset Tracking</h3>
        {user.role === 'CliAdm' &&

            <div style={{ display: 'inline-block' }}>
          <p style={{ display: 'inline-block', marginRight: '8px' }}>
            Enable Check-in / Check-out
          </p>
          <input type='checkbox' onChange={handleChangeLoanEligability} checked={assetDetailsForm?.loan_eligibility} name='loan_eligibility'/>
        </div>

        }
        {assetDetailsForm.loan_eligibility &&
        <div className={classes.control}>
          <AssetLoan id={assetDetailsForm.id} status={assetStatuses.filter((e) => e.id === assetDetailsForm.id)} />
        </div>
        }
      </Card>
    </div>
  )
}

export default FetchAssetDetailsForm