import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Modal } from 'react-bootstrap/';
import { useDispatch } from 'react-redux';

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-curly-brace-presence */

function Signature({ signature, setSignature, isShowSignModal, setIsShowSignModal }) {
  // const [isShowSignModal, setIsShowSignModal] = React.useState(false);
  const [sign, setSign] = React.useState(null);
  // const [signFile, setSignFile] = React.useState(null);
  // const [signFilePhoto, setSignFilePhoto] = React.useState(null);
  const [signError, setSignError] = React.useState(false);
  const [theSignatureURL, setTheSignatureURL] = React.useState(signature);
  const dispatch = useDispatch();

  const handleCloseSignModal = () => {
    setIsShowSignModal(false);
  };

  const handleCreateTrim = () => {
      sign.clear();
      dispatch(setSignature(null));
      setTheSignatureURL(null);
  };

  const handleSave = () => {
    if (sign) {
      const signImg = sign.getTrimmedCanvas().toDataURL('image/png');
      if (!sign?.isEmpty()) {
        setTheSignatureURL(signImg);
        dispatch(setSignature(signImg));
        setIsShowSignModal(false);
      } else {
        setTheSignatureURL(null);
        setSignError(true);
        setTimeout(() => {
          setSignError(false);
        }, 3000);
      }
    }
  };

  return (
    <>
      <div
        className="mt-3 mb-3"
        style={{ cursor: 'pointer', width: '100%' }}
        onClick={() => setIsShowSignModal(!isShowSignModal)}
      >
        {/* <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', padding: '10px' }}><img src={theSignatureURL} /> */}
        {/* setIsShowSignModal(!isShowSignModal) */}
        Signature
        <div className="mx-3" />
        {theSignatureURL ? (
          <div title="Click here to edit your signature" className="ptwcards">
            <img
              className="sign-image"
              style={{ height: '120px' }}
              src={theSignatureURL}
              alt="Signature"
            />
          </div>
        ) : (
          <button type="button" className="btn secondary-btn rounded-full mb-2 mt-2">
            Add Signature
          </button>
        )}
      </div>
      {isShowSignModal && (
        <MyVerticallyCenteredModal
          showModal={isShowSignModal}
          handleClose={handleCloseSignModal}
          modalTitle="Signature"
          primaryButtonText="Confirm"
          handleCreateSave={handleSave}
          handleCreateTrim={handleCreateTrim}
          clearButtonText="Clear"
          // setSignFile={setSignFile}
          // setSignFilePhoto={setSignFilePhoto}
        >
          <>
            <h5 className="pb-2">Sign to confirm work has been completed</h5>
            {signError && <h5 className="red pb-2">Add your Signature</h5>}
            <div className="sigContainer" style={{ border: '1px solid' }}>
              <SignatureCanvas
                penColor="black"
                canvasProps={{ className: 'sigCanvas' }}
                ref={(data) => setSign(data)}
              />
            </div>
            {/* {theSignatureURL ? (
              <img className="sign-image" src={theSignatureURL} alt="signature" />
            ) : (
              <h5 className="nosign-image">Sign⬆️</h5>
            )} */}
          </>
        </MyVerticallyCenteredModal>
      )}
    </>
  );
}
function MyVerticallyCenteredModal({
  showModal,
  handleClose,
  primaryButtonText,
  handleCreateSave,
  children,
  handleCreateTrim,
  clearButtonText,
  // setTab,
  // tab,
  // setSignFile,
  // setSignFilePhoto,
}) {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      onHide={handleClose}
      style={{ zIndex: 100000 }}
    >
      <Modal.Header style={{ minWidth: '100%' }} closeButton>
        <Modal.Title style={{ width: '100%' }} id="contained-modal-title-vcenter">
          {/* {modalTitle} */}
          <div className="signature-header">
            {/* <div 
                        onClick={() => {
                            setTab("sign")
                            setSignFile(null)
                            setSignFilePhoto(null)
                        }}
                        >
                            <DrawIcon />
                            <p>Draw</p>
                        </div> */}
            Confirmation
          </div>
        </Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer style={{ minWidth: '100%', justifyContent: 'space-between' }}>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <div>
          {
            <Button variant="primary" onClick={handleCreateTrim}>
              {clearButtonText}
            </Button>
          }
          <span className="mr-2" />
          <Button variant="primary" onClick={handleCreateSave}>
            {primaryButtonText}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default Signature;
