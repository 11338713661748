/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { classNames } from '../../utils';
import { SortDownIcon, SortIcon, SortUpIcon } from '../tableIcons/tableIcons';
import classes from '../assetsTable/assetsTable.module.css';

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter ({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows?.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <label htmlFor="select" className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render('Header')}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

export function StatusPill ({ value }) {
  return (
    <span
      className={classNames(
        'px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm',
        value === 'True' ? 'bg-green-100 text-green-800' : null,
        value === 'False' ? 'bg-red-100 text-red-800' : null,
      )}
    >
      {value === 'True' ? 'Active' : 'Inactive'}
    </span>
  );
}

export function AvatarCell ({ value, column, row }) {
  return (

    <div className="d-flex align-items-center">
  <div className="position-relative">
  <img className="h-10 w-10 rounded-circle" style={{ maxHeight: 40, maxWidth: 40 }} src={row?.original?.profile?.image} alt="user_image" />
    {/* Availability Indicator */}
    {row?.original?.status===true?<div className="position-absolute top-9 right-2 start-100 translate-middle p-1 bg-success border border-light rounded-circle" title="Available"></div>:<div className="position-absolute top-9 right-2 start-100 translate-middle p-1 bg-danger border border-light rounded-circle" title="UnAvailable"></div>}
  </div>
  
  <div className="ms-4">
    <div className="text-sm font-medium text-gray-900">{value}</div>
    <div className="text-sm text-gray-500">{row.original[column.emailAccessor]}</div>
  </div>
</div>
  );
}

function Table ({
  // pageNum,
  columns,
  data,
  children,
  onRowClick,
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    // The rest of these things are super handy, too ;)
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,

    // state,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination, // new
  );

  // Render the UI for your table
  // const [currentPage, setCurrentPage] = useState(1);

  // const pageSize = Math.round(teamsDataCount/2 -1)

  return (
    <>
      {/* table */}
      <div>
        <div className={classes.scrollX}>
          <table {...getTableProps()} className={classes.table}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th scope="col" {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <div className="flex items-center justify-between">
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <SortDownIcon className="w-4 h-4 text-gray-400" />
                            ) : (
                              <SortUpIcon className="w-4 h-4 text-gray-400" />
                            )
                          ) : (
                            <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length !== 0 ? (
                // If row array is not empty, render table rows
                rows?.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td
                          {...cell.getCellProps()}
                          role="cell"
                          onClick={() => onRowClick(row.original)}
                          // style={{textAlign:'center'}}
                        >
                          {cell.title}
                          {cell.column.Cell.name === 'defaultRenderer' ? (
                            <div>
                              {cell.value !== '' && cell.value !== null
                                ? (cell.value === 'CliAdm'
                                  ? 'Administrator'
                                  : cell.render('Cell'))
                                : '—'}
                            </div>
                          ) : cell.value !== '' && cell.value !== null ? (
                            cell.render('Cell')
                          ) : (
                            '—'
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                // If row array is empty, render "No Results Found" message
                <tr>
                  <td className={classes.noResults} colSpan="6">
                    No Results Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {children}
      </div>
    </>
  );
}

export default Table;