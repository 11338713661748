import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getWorkOrderTypeByAuthentication  = async () => {
  const res = await http.get(
    `${BASE_URL}/work-order-type/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of workOrderType by its company ID failed",
    res
  );
};

const getWorkOrderTypeById = async (workOrderTypeId) => {
  const res = await http.get(
    `${BASE_URL}/work-order-type/${workOrderTypeId}`
  );

  return assert(
    res,
    res.data,
    "Retrieval of workOrderType by its ID failed",
    res
  );
};

const createWorkOrderType = async (data) => {
  const res = await http.post(
    `${BASE_URL}/work-order-type/`,
    data
  );

  return assert(res, res.data, "Creation of workOrderType failed", res);
};

const updateWorkOrderType = async (data) => {
  const res = await http.put(
    `${BASE_URL}/work-order-type/${data.id}/`,
    data
  );
  return assert(res, res.data, "Update of workOrderType failed", res);
};

const deleteWorkOrderType = async (workOrderTypeId) => {
  const res = await http.delete(
    `${BASE_URL}/work-order-type/${workOrderTypeId}/`
  );
  return assert(res, workOrderTypeId, "Deletion of workOrderType failed", res);
};

const workOrderTypeService = {
  getWorkOrderTypeByAuthentication,
  getWorkOrderTypeById,
  createWorkOrderType,
  updateWorkOrderType,
  deleteWorkOrderType,
};

export default workOrderTypeService;
