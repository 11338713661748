import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import businessHourService from './businessHourService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // businessHour
  businessHours:[],
  businessHour: {},
  resetBHAfterClose : [],



  // modal
  businessHourModalCreate: false,
  businessHourModalDelete: false,
  businessHourModalUpdate: false,

  // success
  isBusinessHourGetSuccess: false,
  isBusinessHourCreateSuccess: false,
  isBusinessHourDeleteSuccess: false,
  isBusinessHourUpdateSuccess: false,

  // loading
  isBusinessHourGetLoading: false,
  isBusinessHourDeleteLoading: false,
  isBusinessHourUpdateLoading: false,
  isBusinessHourCreateLoading: false,

  // error
  isBusinessHourGetError: false,
  isBusinessHourCreateError: false,
  isBusinessHourDeleteError: false,
  isBusinessHourUpdateError: false,
};

export const getBusinessHoursByAuthentication = createAsyncThunk(
  'getBusinessHoursByAuthentication',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      businessHourService.getBusinessHoursByAuthentication,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const getBusinessHourById = createAsyncThunk(
  'getBusinessHourById',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(businessHourService.getBusinessHourById, data, thunkAPI);
    return res;
  },
);

export const createBusinessHour = createAsyncThunk(
  'createBusinessHour',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(businessHourService.createBusinessHour, data, thunkAPI);
    return res;
  },
);

export const deleteBusinessHour = createAsyncThunk(
  'deleteBusinessHour',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(businessHourService.deleteBusinessHour, data, thunkAPI);
    return res;
  },
);

export const updateBusinessHour = createAsyncThunk(
  'updateBusinessHour',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(businessHourService.updateBusinessHour, data, thunkAPI);
    return res;
  },
);

export const businessHourSlice = createSlice({
  name: 'businessHour',
  initialState: initState,
  reducers: {
    setBusinessHour: (state, action) => {
      state.businessHour = action.payload;
    },
    openBusinessHourModalDelete: state => {
      state.businessHourModalDelete = true;
    },
    closeBusinessHourModalDelete: state => {
      state.businessHourModalDelete = false;
    },
    openBusinessHourModalUpdate: state => {
      state.businessHourModalUpdate = true;
    },
    closeBusinessHourModalUpdate: state => {
      state.businessHourModalUpdate = false;
    },
    openBusinessHourModalCreate: state => {
      state.businessHourModalCreate = true;
    },
    closeBusinessHourModalCreate: state => {
      state.businessHourModalCreate = false;
    },
    setBusinessHourName: (state, action) => {
      state.businessHourName = action.payload;
    },
    setBusinessHours: (state, action) => {
      state.businessHours = action.payload;
    },
 

    reset: state => {
      state.isBusinessHourCreateError = false;
      state.isBusinessHourCreateSuccess = false;
      state.isBusinessHourCreateLoading = false;
      state.isBusinessHourGetError = false;
      state.isBusinessHourGetSuccess = false;
      state.isBusinessHourGetLoading = false;
      state.isBusinessHourDeleteError = false;
      state.isBusinessHourDeleteSuccess = false;
      state.isBusinessHourDeleteLoading = false;
      state.isBusinessHourUpdateError = false;
      state.isBusinessHourUpdateSuccess = false;
      state.isBusinessHourUpdateLoading = false;
      state.businessHourModalCreate = false;
      state.businessHourModalDelete = false;
      state.businessHourModalUpdate = false;
      // state.businessHour = {};
      // state.businessHourName = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getBusinessHoursByAuthentication.pending, state => {
        state.isBusinessHourGetLoading = true;
        state.isBusinessHourGetSuccess = false;
        state.isBusinessHourGetError = false;
      })
      .addCase(getBusinessHoursByAuthentication.fulfilled, (state, action) => {
        state.isBusinessHourGetLoading = false;
        state.isBusinessHourGetSuccess = true;
        state.isBusinessHourGetError = false;
        state.businessHours = action.payload;
        state.resetBHAfterClose = action.payload;
      })
      .addCase(getBusinessHoursByAuthentication.rejected, state => {
        state.isBusinessHourGetLoading = false;
        state.isBusinessHourGetSuccess = false;
        state.isBusinessHourGetError = true;
      })
      .addCase(getBusinessHourById.pending, state => {
        state.isBusinessHourGetLoading = true;
        state.isBusinessHourGetSuccess = false;
        state.isBusinessHourGetError = false;
      })
      .addCase(getBusinessHourById.fulfilled, (state, action) => {
        state.isBusinessHourGetLoading = false;
        state.isBusinessHourGetSuccess = true;
        state.isBusinessHourGetError = false;
        state.businessHours = action.payload;
      })
      .addCase(getBusinessHourById.rejected, state => {
        state.isBusinessHourGetLoading = false;
        state.isBusinessHourGetSuccess = false;
        state.isBusinessHourGetError = true;
      })
      .addCase(createBusinessHour.pending, state => {
        state.isBusinessHourCreateLoading = true;
        state.isBusinessHourCreateSuccess = false;
        state.isBusinessHourCreateError = false;
      })
      .addCase(createBusinessHour.fulfilled, (state, action) => {
        state.isBusinessHourCreateLoading = false;
        state.isBusinessHourCreateSuccess = true;
        state.isBusinessHourCreateError = false;
        state.businessHours.push(action.payload);
      })
      .addCase(createBusinessHour.rejected, (state) => {
        state.isBusinessHourCreateLoading = false;
        state.isBusinessHourCreateSuccess = false;
        state.isBusinessHourCreateError = true;
       
      })
      .addCase(deleteBusinessHour.pending, state => {
        state.isBusinessHourDeleteLoading = true;
        state.isBusinessHourDeleteSuccess = false;
        state.isBusinessHourDeleteError = false;
      })
      .addCase(deleteBusinessHour.fulfilled, (state, action) => {
        state.isBusinessHourDeleteLoading = false;
        state.isBusinessHourDeleteSuccess = true;
        state.isBusinessHourDeleteError = false;
        state.businessHours = state.businessHours.filter(
          businessHour => businessHour.id !== action.payload,
        );
      })
      .addCase(deleteBusinessHour.rejected, state => {
        state.isBusinessHourDeleteLoading = false;
        state.isBusinessHourDeleteSuccess = false;
        state.isBusinessHourDeleteError = true;
      })
      .addCase(updateBusinessHour.pending, state => {
        state.isBusinessHourUpdateLoading = true;
        state.isBusinessHourUpdateSuccess = false;
        state.isBusinessHourUpdateError = false;
      })
      .addCase(updateBusinessHour.fulfilled, (state, action) => {
        state.isBusinessHourUpdateLoading = false;
        state.isBusinessHourUpdateSuccess = true;
        state.isBusinessHourUpdateError = false;
        state.businessHours = state.businessHours.map(businessHour =>
          businessHour.id === action.payload.id ? action.payload : businessHour,
        );
      })
      .addCase(updateBusinessHour.rejected, state => {
        state.isBusinessHourUpdateLoading = false;
        state.isBusinessHourUpdateSuccess = false;
        state.isBusinessHourUpdateError = true;
      });
  },
});

export const {
  setBusinessHour,
  openBusinessHourModalDelete,
  closeBusinessHourModalDelete,
  openBusinessHourModalUpdate,
  closeBusinessHourModalUpdate,
  openBusinessHourModalCreate,
  closeBusinessHourModalCreate,
  reset,
  setBusinessHours,
} = businessHourSlice.actions;

export default businessHourSlice.reducer;
