import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope,
    faUser,
    faPhone,
    faX,
} from '@fortawesome/free-solid-svg-icons';
import {
    // Backdrop,
    Modal,
} from '@material-ui/core';

import BookDemoImg from '../../assets/img/GIF.gif';
import WhatsappIcon from '../../assets/img/WhatsappIcon.png';

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

function BookDemo({ handleCloseAdvFilter, openAdvFilter, handleOpenAdvFilter }) {
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        compemail: "",
        phone: "",
        compdesc: "",
    });

    const [errors, setErrors] = useState({}); // Track validation errors

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));        
    };

    const mailForm = {
        to: "hello@foxmy.io", // preset recipient
        subject: "FOX Demo Request", // preset subject
        body: "", // preset body
    };

    const generateEmailBody = () => {
        return `
    Name: ${formData.firstname} ${formData.lastname}
    Company Email: ${formData.compemail}
    Mobile Phone: ${formData.phone}
    Company Description: ${formData.compdesc}
        `.trim(); // Trim extra whitespace
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.firstname.trim())
            newErrors.firstname = "First name is required";
        if (!formData.lastname.trim()) newErrors.lastname = "Last name is required";
        if (!formData.compemail.trim()) {
            newErrors.compemail = "Company email is required";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.compemail)) {
            newErrors.compemail = "Invalid email format";
        }
        if (!formData.phone.trim()) newErrors.phone = "Phone number is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    // Handle send button click
    const handleSend = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const emailBody = encodeURIComponent(generateEmailBody());
            const mailtoLink = `mailto:${mailForm.to}?subject=${encodeURIComponent(
                mailForm.subject
            )}&body=${emailBody}`;
            window.location.href = mailtoLink; // Open mail client with mailto link
        }
    };

    return (
        <Modal
            open={openAdvFilter}
            onClose={handleCloseAdvFilter}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //   timeout: 0,
        //   style: {
        //     backdropFilter: "blur(4px)", // Blur effect
        //     backgroundColor: "rgba(255, 255, 255, 0.1)", // Optional: Light transparent background
        //   },
        // }}
        >
            <div
                style={{
                    width: 'fit-content',
                    margin: '50px 140px',
                    backgroundColor: 'transparent',
                    outline: 'none',
                    display: 'flex',
                    gap: '30px',
                }}
            >
                <div
                    style={{
                        padding: '40px',
                        backgroundColor: 'white',
                        borderRadius: '16px',
                        flex: '1',
                        height: 'fit-content',
                    }}
                >
                    <div
                        style={{
                            fontWeight: '700',
                            fontSize: '28px',
                            color: '#36383C',
                            marginBottom: '4px',
                        }}
                    >
                        Book a Demo Now
                    </div>
                    <div
                        style={{
                            fontWeight: '400',
                            fontSize: '18px',
                            color: '#727478',
                            marginBottom: '16px',
                        }}
                    >
                        Excited to see how it works? Schedule your demo today, and we&apos;ll show you how
                        our solution can make your work better. It&apos;s quick, free, and all about you.
                        Let&apos;s dive in together!
                    </div>
                    <div style={{ marginBottom: '24px' }}>
                        <img src={BookDemoImg} alt="Book Demo" width="100%" />
                    </div>
                    <div
                        style={{
                            fontWeight: '400',
                            fontSize: '18px',
                            color: '#727478',
                            display: 'flex',
                            gap: '6px',
                            alignItems: 'center',
                            marginBottom: '4px'
                        }}
                    >
                        <div>
                            {/* <IconWhatsapp /> */}
                            <img width={30} src={WhatsappIcon} alt="" />
                        </div>
                        <div>
                            You may Whatsapp us if it&apos;s more convenient for you!
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        padding: '0 40px 0 40px',
                        backgroundColor: 'white',
                        borderRadius: '16px',
                        flex: '1',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div style={{ alignSelf: 'end', marginTop: '20px' }}>
                        <button
                            type="button"
                            style={{ padding: '4px' }}
                            onClick={handleCloseAdvFilter}
                        >
                            <span>
                                <FontAwesomeIcon icon={faX} />{' '}
                            </span>
                        </button>
                    </div>
                    <div
                        style={{
                            fontWeight: '700',
                            fontSize: '28px',
                            color: '#36383C',
                            marginBottom: '4px',
                        }}
                    >
                        Plan and Implement
                    </div>
                    <div
                        style={{
                            fontWeight: '400',
                            fontSize: '18px',
                            color: '#727478',
                            marginBottom: '16px',
                        }}
                    >
                        Register for a 30 mins Demo
                    </div>
                    <form onSubmit={() => { }}>
                        <div style={{ display: 'flex', gap: '24px' }}>
                            <div className="mb-4">
                                <label
                                    htmlFor="firstname"
                                    style={{
                                        fontWeight: '400',
                                    }}
                                >
                                    First Name
                                </label>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <div className="input-group-text inline-show-password pointer">
                                            <span style={{ cursor: 'pointer' }} id="eye">
                                                <FontAwesomeIcon icon={faUser} />
                                            </span>
                                        </div>
                                    </div>
                                    <input
                                        name="firstname"
                                        type="text"
                                        className="form-control inline-username"
                                        placeholder="Enter your first name"
                                        id="firstname"
                                        onChange={handleChange}
                                        value={formData.firstname}
                                        required
                                    />
                                </div>
                                {errors.firstname && (
                                    <p style={{ color: "#D9314A" }}>{errors.firstname}</p>
                                )}
                            </div>
                            <div className="mb-4">
                                <label
                                    htmlFor="lastname"
                                    style={{
                                        fontWeight: '400',
                                    }}
                                >
                                    Last Name
                                </label>
                                <div className="input-group">
                                    <div className="input-group-append">
                                        <div className="input-group-text inline-show-password pointer">
                                            <span style={{ cursor: 'pointer' }} id="eye">
                                                <FontAwesomeIcon icon={faUser} />
                                            </span>
                                        </div>
                                    </div>
                                    <input
                                        name="lastname"
                                        type="text"
                                        className="form-control inline-username"
                                        placeholder="Enter your last name"
                                        id="lastname"
                                        onChange={handleChange}
                                        value={formData.lastname}
                                        required
                                    />
                                </div>
                                {errors.lastname && (
                                    <p style={{ color: "#D9314A" }}>{errors.lastname}</p>
                                )}
                            </div>
                        </div>

                        <div className="mb-4">
                            <label
                                htmlFor="compemail"
                                style={{
                                    fontWeight: '400',
                                }}
                            >
                                Company Email
                            </label>
                            <div className="input-group">
                                <div className="input-group-append">
                                    <div className="input-group-text inline-show-password pointer">
                                        <span style={{ cursor: 'pointer' }} id="eye">
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </span>
                                    </div>
                                </div>
                                <input
                                    name="compemail"
                                    type="text"
                                    className="form-control inline-username"
                                    placeholder="Enter the email"
                                    id="compemail"
                                    onChange={handleChange}
                                    value={formData.compemail}
                                    required
                                />
                            </div>
                            {errors.compemail && (
                                <p style={{ color: "#D9314A" }}>{errors.compemail}</p>
                            )}
                        </div>

                        <div className="mb-4">
                            <label
                                htmlFor="phone"
                                style={{
                                    fontWeight: '400',
                                }}
                            >
                                Mobile Phone
                            </label>
                            <div className="input-group">
                                <div className="input-group-append">
                                    <div className="input-group-text inline-show-password pointer">
                                        <span style={{ cursor: 'pointer' }} id="eye">
                                            <FontAwesomeIcon icon={faPhone} />
                                        </span>
                                    </div>
                                </div>
                                <input
                                    name="phone"
                                    type="text"
                                    className="form-control inline-username"
                                    placeholder="Enter your phone number"
                                    id="phone"
                                    onChange={handleChange}
                                    value={formData.phone}
                                    required
                                />
                            </div>
                            {errors.phone && <p style={{ color: "#D9314A" }}>{errors.phone}</p>}
                        </div>

                        <div className="mb-4">
                            <label
                                htmlFor="compdesc"
                                style={{
                                    fontWeight: '400',
                                }}
                            >
                                What is the best to describe your company?
                            </label>
                            <div className="input-group">
                                <textarea
                                    name="compdesc"
                                    id="compdesc"
                                    placeholder="E.g. Facilities Management, Property Management"
                                    style={{ width: '100%', padding: '16px 12px', outline: 'none' }}
                                    onChange={handleChange}
                                    value={formData.compdesc}
                                />
                            </div>
                            <div className="col-lg-12 col-sm-12 m-0">
                                <span className="font-danger" id="username_error" />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-12 col-sm-12 m-0">
                                <button
                                    type="submit"
                                    className="btn btn-block get-fox-btn"
                                    style={{ fontWeight: '600', padding: '16px 0' }}
                                    onClick={handleSend}
                                >
                                    Get FOX Now
                                </button>
                            </div>
                        </div>

                        <div
                            className="mt-2 mb-3"
                            style={{
                                borderBottom: '1px solid #E1E1E1',
                                width: '100%',
                            }}
                        />
                        <div style={{ color: '#727478', marginBottom: "30px" }}>
                            By clicking submit, you agree to our{' '}
                            <span onClick={handleOpenAdvFilter} className="login-contact">
                                Privacy Policy
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default BookDemo