import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import managerPositionService from "./managerPositionService";
import { smartTryCatch } from "../../../utils";

const initState = {
  // data
  managers: [],
  manager: {},
  
  // fields
  managerPostionName: "",

  // modals
  managerPositionModalDelete: false,
  managerPositionModalUpdate: false,
  managerPositionModalCreate: false,

  // success
  isManagerCreateSuccess: false,
  isManagerGetSuccess: false,
  isManagerDeleteSuccess: false,
  isManagerUpdateSuccess: false,

  // loading
  isManagerCreateLoading: false,
  isManagerGetLoading: false,
  isManagerDeleteLoading: false,
  isManagerUpdateLoading: false,

  // error
  isManagerCreateError: false,
  isManagerGetError: false,
  isManagerDeleteError: false,
  isManagerUpdateError: false,
};

export const createManagerPosition = createAsyncThunk(
  "createManagerPosition",
  async (data, thunkAPI) => {
    const create = await smartTryCatch(
      managerPositionService.createManagerPosition,
      data,
      thunkAPI
    );
    return create;
  }
);

export const getManagerPositionByAuthentication = createAsyncThunk(
  "getManagerPositionByAuthentication",
  async (data, thunkAPI) => {
    const getTech = await smartTryCatch(
      managerPositionService.getManagerPositionByAuthentication,
      data,
      thunkAPI
    );
    return getTech;
  }
);

export const getManagerPositionById = createAsyncThunk(
  "getManagerPositionById",
  async (data, thunkAPI) => {
    const getTech = await smartTryCatch(
      managerPositionService.getManagerPositionById,
      data,
      thunkAPI
    );
    return getTech;
  }
);

export const deleteManagerPosition = createAsyncThunk(
  "deleteManagerPosition",
  async (data, thunkAPI) => {
    const deleteTech = await smartTryCatch(
      managerPositionService.deleteManagerPosition,
      data,
      thunkAPI
    );
    return deleteTech;
  }
);

export const updateManagerPosition = createAsyncThunk(
  "updateManagerPosition",
  async (data, thunkAPI) => {
    const updateTech = await smartTryCatch(
      managerPositionService.updateManagerPosition,
      data,
      thunkAPI
    );
    return updateTech;
  }
);

export const managerPositionSlice = createSlice({
  name: "managerPosition",
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isManagerCreateError = false;
      state.isManagerCreateSuccess = false;
      state.isManagerCreateLoading = false;
      state.isManagerGetError = false;
      state.isManagerGetSuccess = false;
      state.isManagerGetLoading = false;
      state.isManagerDeleteError = false;
      state.isManagerDeleteSuccess = false;
      state.isManagerDeleteLoading = false;
      state.isManagerUpdateError = false;
      state.isManagerUpdateSuccess = false;
      state.isManagerUpdateLoading = false;
  
      state.managerPositionModalCreate = false;
      state.managerPositionModalDelete = false;
      state.managerPositionModalUpdate = false;
    },
    openManagerPositionModalDelete: (state) => {
      state.managerPositionModalDelete = true;
    },
    closeManagerPositionModalDelete: (state) => {
      state.managerPositionModalDelete = false;
    },
    openManagerPositionModalUpdate: (state) => {
      state.managerPositionModalUpdate = true;
    },
    closeManagerPositionModalUpdate: (state) => {
      state.managerPositionModalUpdate = false;
    },
    openManagerPositionModalCreate: (state) => {
      state.managerPositionModalCreate = true;
    },
    closeManagerPositionModalCreate: (state) => {
      state.managerPositionModalCreate = false;
    },
    setManager: (state, action) => {
      state.manager = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManagerPositionByAuthentication.pending, (state) => {
        state.isManagerGetLoading = true;
        state.isManagerGetSuccess = false;
        state.isManagerGetError = false;
      })
      .addCase(
        getManagerPositionByAuthentication.fulfilled,
        (state, action) => {
          state.isManagerGetLoading = false;
          state.isManagerGetSuccess = true;
          state.isManagerGetError = false;
          state.managers = action.payload;
        }
      )
      .addCase(getManagerPositionByAuthentication.rejected, (state) => {
        state.isManagerGetLoading = false;
        state.isManagerGetSuccess = false;
        state.isManagerGetError = true;
      })
      .addCase(getManagerPositionById.pending, (state) => {
        state.isManagerGetLoading = true;
        state.isManagerGetSuccess = false;
        state.isManagerGetError = false;
      })
      .addCase(getManagerPositionById.fulfilled, (state, action) => {
        state.isManagerGetLoading = false;
        state.isManagerGetSuccess = true;
        state.isManagerGetError = false;
        state.managers = action.payload;
      })
      .addCase(getManagerPositionById.rejected, (state) => {
        state.isManagerGetLoading = false;
        state.isManagerGetSuccess = false;
        state.isManagerGetError = true;
      })
      .addCase(createManagerPosition.pending, (state) => {
        state.isManagerCreateLoading = true;
        state.isManagerCreateSuccess = false;
        state.isManagerCreateError = false;
      })
      .addCase(createManagerPosition.fulfilled, (state, action) => {
        state.isManagerCreateLoading = false;
        state.isManagerCreateSuccess = true;
        state.isManagerCreateError = false;
        state.managers.push(action.payload);
      })
      .addCase(createManagerPosition.rejected, (state) => {
        state.isManagerCreateLoading = false;
        state.isManagerCreateSuccess = false;
        state.isManagerCreateError = true;
      })
      .addCase(deleteManagerPosition.pending, (state) => {
        state.isManagerDeleteLoading = true;
        state.isManagerDeleteSuccess = false;
        state.isManagerDeleteError = false;
      })
      .addCase(deleteManagerPosition.fulfilled, (state, action) => {
        state.isManagerDeleteLoading = false;
        state.isManagerDeleteSuccess = true;
        state.isManagerDeleteError = false;
        state.managers = state.managers.filter(
          (manager) => manager.id !== action.payload
        );
      })
      .addCase(deleteManagerPosition.rejected, (state) => {
        state.isManagerDeleteLoading = false;
        state.isManagerDeleteSuccess = false;
        state.isManagerDeleteError = true;
      })
      .addCase(updateManagerPosition.pending, (state) => {
        state.isManagerUpdateLoading = true;
        state.isManagerUpdateSuccess = false;
        state.isManagerUpdateError = false;
      })
      .addCase(updateManagerPosition.fulfilled, (state, action) => {
        state.isManagerUpdateLoading = false;
        state.isManagerUpdateSuccess = true;
        state.isManagerUpdateError = false;
        state.managers = state.managers.map((manager) =>
          manager.id === action.payload.id ? action.payload : manager
        );
      })
      .addCase(updateManagerPosition.rejected, (state) => {
        state.isManagerUpdateLoading = false;
        state.isManagerUpdateSuccess = false;
        state.isManagerUpdateError = true;
      });
  },
});

export const {
  reset,
  closeManagerPositionModalCreate,
  closeManagerPositionModalDelete,
  closeManagerPositionModalUpdate,
  openManagerPositionModalCreate,
  openManagerPositionModalDelete,
  setManager,
  openManagerPositionModalUpdate,
} = managerPositionSlice.actions;

export default managerPositionSlice.reducer;
