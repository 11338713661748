import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import React, { useState, useEffect } from 'react';
import {toast} from "react-toastify";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import useApiCallStatusNotificationHandler from '../../utils/apiCallStatusHandler';
import { resetAuth } from '../../redux/features/auth/authSlice';
import tokenEx from "../../assets/img/tokenEx.jpg"
// import foxLogo from '../../assets/img/FOX_Logo2.png.webp';
import './resetPasswordByEmail.scss';
import { BASE_URL } from '../../utils/axios';

export default function ResetPasswodByEmail () {
  const history = useHistory();
  const { isLoading, isError, isSuccess  } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [newPassword, setnewPassword] = useState('');
  const [confirmPass, setConfirmPassword] = useState('');
  const [otp, setotp] = useState('');
  const [errorMsg, setErrorMsg] = useState("");

  const submitConfirmPass = async (e) => {
    e.preventDefault();
    // eslint-disable-next-line object-shorthand
    const userinfo = { new_password: newPassword, confirm_password: confirmPass, otp: otp };
      try {
        const response = await fetch(`${BASE_URL}/password-confirm/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userinfo),
        });

        if (!response.ok) {
          const result = await response.json();
          throw new Error(result.details);
        }

      // Process the result
      toast.success("Password Created successfully", {
        toastId: "success",
      });
      setTimeout(() => {
        history.push('/login');
      }, 2000)
      
      } catch (error) {
        console.error('Error posting data:', error.message);
        setErrorMsg(error.message);
        toast.error(error.message, {
          toastId: "error",
        });
      }
  };

  // const { showSpinner } = useApiCallStatusNotificationHandler({
  //   isSuccess,
  //   isLoading,
  //   isError,
  //   successCallBack: () =>
  //     setTimeout(() => {
  //       history.push('/login');
  //     }, 3500),
  // });

  


// "OTP expired"
  useEffect(() => {
    dispatch(resetAuth());
  }, [isError, isSuccess, isLoading, dispatch, history]);
  return (
    errorMsg?.includes("OTP expired") ? <div className='token-wrapper'>
    <img src={tokenEx} alt="tokenEx" className='token-image' />
    <p className='token-content'>Oops! This OTP has expired.</p>
    <p className='token-content'>For further assistance, contact the system administrator.</p>
    <button onClick={()=> {
      history.push('/login');
    }} className='btn primary-btn mt-3' type='button'>Redirect to login page</button>
    </div> :
    <div>
      {/* {showSpinner ? <div id="loader" /> : null} */}
      <div className="row d-flex justify-content-center mt-4">
        <div className="col-lg-7 col-sm-12 col-md-10 ">
          {/* <div className="login-logo-check-email">
            <img src={foxLogo} alt="fox logo" height="130" width="250" />
          </div> */}
          {/* <p className="mb-2 text-success">{{msg}}</p> */}
          <p className="text-center mb-2 text-success">Please Check your email for OTP</p>

          <form action="{{action}}" method="POST" onSubmit={submitConfirmPass}>
            <div className="card col-lg-12 col-sm-12 col-xs-12 col-md-12 reset-pass-card">
              <div className="card-header medium-title" style={{ borderBottom: 'none' }}>
                <b>Create new password</b>
              </div>
              <div className="card-body">
                <div className="row">
                  <span> </span>
                  <label className="font-weight-normal" htmlFor="newpassword">
                    New Password{' '}
                    <label className="font-danger m-0" htmlFor="newpassword">
                      *
                    </label>
                  </label>
                  <input
                    name="newpassword"
                    type="password"
                    className="form-control mb-2"
                    required
                    placeholder="New password"
                    id="newpassword"
                    onChange={e => setnewPassword(e.target.value)}
                  />
                  <span className="font-danger col-lg-12 col-sm-12 ml-0" id="newpassword_error">
                    {' '}
                  </span>

                  <label className="font-weight-normal" htmlFor="confirm_password">
                    Confirm Password{' '}
                    <label className="font-danger m-0" htmlFor="confirm_password">
                      *
                    </label>
                  </label>
                  <input
                    name="confirm_password"
                    type="password"
                    className="form-control mb-2"
                    required
                    placeholder="Confirm password"
                    id="confirm_password"
                    onChange={e => setConfirmPassword(e.target.value)}
                  />
                  <span
                    className="font-danger col-lg-12 col-sm-12 ml-0"
                    id="confirm_password_error"
                  >
                    {' '}
                  </span>

                  <label className="font-weight-normal" htmlFor="otp">
                    OTP{' '}
                    <label className="font-danger m-0" htmlFor="otp">
                      *
                    </label>
                  </label>
                  <input
                    name="otp"
                    type="text"
                    className="form-control mb-2"
                    placeholder="Enter OTP"
                    id="otp"
                    onChange={e => setotp(e.target.value)}
                  />
                  <span className="font-danger col-lg-12 col-sm-12 ml-0" id="otp_error">
                    {' '}
                  </span>
                </div>
              </div>
            </div>

            <div className="float-right">
              <div className="float-right">
                <button className="btn primary-btn" type="submit">
                  Save
                </button>
                <button
                  onClick={() => history.push('/forgot-password')}
                  className="btn secondary-btn ml-2"
                  type="button"
                >
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="text-center my-3" style={{ color: '#9B9B9B !important' }}>
        {' '}
        © 2022 Infinity Wave Sdn. Bhd.
      </div>
      <ReactTooltip
        anchorSelect="#newpassword"
        place="top"
        content=<div style={{ width: '250px' }}>
          <p>{`Your password cant't be too similar to your other personal information`}</p>
          <p>Password must contain at least 8 characters</p>
          <p>{`Your password cant't be a commonly used password`}</p>
          <p>{`Your password cant't be entirely numeric`}</p>
        </div>
      />
    </div>
  );
}
