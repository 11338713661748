/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/order */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { endOfWeek, startOfWeek } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import ReloadIcon from '../../../../assets/svg-icons/ReloadIcon';
import DateRangeFilter from '../../../viewMaintenance/Componets/DateRangeFilter';
import { ButtonBase, Modal } from '@material-ui/core';
import { SelectLocationFilter, SelectStaff, SelectSublocationFilter } from '../../../../components/select/select';
import { getMaintenacePlannerBoard, setMaintenanceBoardFrequency, setMaintenanceBoardSite, setMaintenanceBoardStaff, setMaintenanceBoardSublocation, setMaintenanceDateFilterState, setMaintenanceFilterEndDate, setMaintenanceFilterStartDate } from '../../../../redux/features/dashboard/dashboardSlice';


const buttonStyle = {
  padding: '8px 16px',
  fontSize: '14px',
  fontWeight: '600',
  color: '#36383C',
  backgroundColor: '#F8F9F9',
  border: '1px solid #E1E1E1',
  borderRadius: '8px',
};

const buttonDisabledStyle = {
  padding: '8px 16px',
  fontSize: '14px',
  fontWeight: '600',
  color: 'white',
  backgroundColor: '#D9314A',
  borderRadius: '8px',
};

export default function PMBoardFilter({ selectedMenu, getMaintenacePlannerBoardData }) {
  const dispatch = useDispatch();

  const { maintenanceBoardSite, maintenanceBoardSublocation, maintenanceBoardStaff, maintenanceBoardFrequency,
    maintenanceDateFilterState, maintenanceFilterStartDate, maintenanceFilterEndDate
  } = useSelector((state) => state.dashboard);

  // BEGIN date period things
  const [show, setShow] = useState(false);
  const handleUpdate = async () => {
    setShow(false);
    dispatch(setMaintenanceFilterStartDate(maintenanceDateFilterState?.find((item) => item.startDate)?.startDate));
    dispatch(setMaintenanceFilterEndDate(maintenanceDateFilterState?.find((item) => item.endDate)?.endDate));
    await getMaintenacePlannerBoardData()
  };
  const handleClose = () => {
    setShow(false);
  };
  // END date period things

  // BEGIN Advance filter dialog
  const [openAdvFilter, setOpenAdvFilter] = useState(false);

  const handleOpenAdvFilter = () => {
    setOpenAdvFilter(true);
  };

  const handleCloseAdvFilter = () => {
    setOpenAdvFilter(false);
  };
  // END Advance filter dialog

  // BEGIN Inside advance filter
  const [locationsData, setLocations] = useState([]);
  const { locations } = useSelector((state) => state.location);
  const sublocations = useSelector((state) => state.location?.sublocations?.results);
  const { teams } = useSelector((state) => state.teams);

  const resetState = () => {
    dispatch(setMaintenanceBoardSite(null))
    dispatch(setMaintenanceBoardSublocation(null))
    dispatch(setMaintenanceBoardStaff(null))
    dispatch(setMaintenanceBoardFrequency([
      { label: "Yearly", selected: true, value: "year" },
      { label: "Weekly", selected: true, value: "week" },
      { label: "Monthly", selected: true, value: "month" },
      { label: "Daily", selected: true, value: "day" }
    ]))
  };
  const handleEraserFilterClick = () => {
    setLocations([]);
    resetState(); // reset the state and store
  };
  const handleActiveAdvFilter = async () => {
    handleCloseAdvFilter();
    await getMaintenacePlannerBoardData();
  };
  // END Inside advance filter


  const handelFrequencyChange = (item) => {
    const updatedFrequency = maintenanceBoardFrequency.map(ele => {
      if (ele.label === item.label) {
        return { ...ele, selected: !item.selected }
      }
      return ele
    })
    dispatch(setMaintenanceBoardFrequency(updatedFrequency))
  }

  useEffect(() => {
    setLocations(sublocations || [])
  }, [sublocations])

  const getQueryString = (data, filterName) => {
    return data
      .filter(feature => feature.selected) // Only keep selected items
      .map(feature => `${filterName}=${feature.value}`)
      .join('&');
  }

  const handleReset = async () => {
    dispatch(setMaintenanceDateFilterState(
      [
        {
          startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
          endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
          key: 'selection',
        },
      ]
    ))
    dispatch(setMaintenanceFilterStartDate(moment.utc(new Date()).startOf("isoWeek").format("YYYY-MM-DD")))
    dispatch(setMaintenanceFilterEndDate(moment.utc(new Date()).endOf("isoWeek").format("YYYY-MM-DD")))

    const filters = {
      startDate: moment.utc(new Date()).startOf("isoWeek").format("YYYY-MM-DD"),
      endDate: moment.utc(new Date()).endOf("isoWeek").format("YYYY-MM-DD"),
      feature: '',
      site: maintenanceBoardSite?.value || null,
      sublocation: maintenanceBoardSublocation?.value || null,
      staff: maintenanceBoardStaff?.value || null,
      frequency: getQueryString(maintenanceBoardFrequency, "frequency"),
    }
    await dispatch(getMaintenacePlannerBoard(filters));
  }

  return (
    <div style={{ margin: '16px 16px 0 16px', display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', gap: '16px' }}>
        {selectedMenu === 'task_planner' && (
          <div style={{ width: '222px' }}>
            {/* <label style={{ color: '#d63c3c', fontWeight: 500 }}>Location </label> */}
            <label style={{ color: '#36383C', fontWeight: 500 }}>Services type </label>
            <select
              // className="form-control select-dash custom-select2 mb-2"
              className="planner_select_mode"
              name="planner_mode"
              id="planner_mode"
              style={{ width: '100%' }}
            >
              {['All services'].map((val) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="filters-left-wrapper-period" style={{ width: '222px' }}>
          <label style={{ color: '#36383C', fontWeight: 500 }}>Period </label>
          <div
            className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md "
            style={{ fontSize: '14px', cursor: 'pointer' }}
            onClick={() => setShow(!show)}
          >
            {maintenanceFilterStartDate ? (
              <>
                <span>{moment(maintenanceFilterStartDate).format('DD MMM YYYY')}</span> -{' '}
                <span>{moment(maintenanceFilterEndDate).format('DD MMM YYYY')}</span>{' '}
              </>
            ) : (
              'All time'
            )}
          </div>
          {show && (
            <>
              <div className="tck-overlay" onClick={() => setShow(!show)} />
              <DateRangeFilter
                show={show}
                handleUpdate={handleUpdate}
                handleClose={handleClose}
                state={maintenanceDateFilterState}
                setState={setMaintenanceDateFilterState}
                forPMBoard
              // advance
              />
            </>
          )}
        </div>
        <button
          type="button"
          style={{ alignSelf: 'flex-end' }}
          className="new-btn btn"
          title="Reset"
          onClick={handleReset}
        >
          <ReloadIcon />
        </button>
      </div>
      <div style={{ alignSelf: 'end' }}>
        <Button
          variant="contained"
          style={{
            backgroundColor: '#36383C',
            color: '#F8F9F9',
            fontSize: '14px',
            fontWeight: '600',
            padding: '8px 16px',
            borderRadius: '8px',
          }}
          onClick={handleOpenAdvFilter}
          className="adv-button"
        >
          Advance filter
        </Button>
      </div>
      <Modal
        open={openAdvFilter}
        onClose={handleCloseAdvFilter}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '480px',
            padding: '12px 16px',
            backgroundColor: '#F8F9F9',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              fontWeight: '600',
              fontSize: '21px',
              marginBottom: '20px',
            }}
          >
            Advance filter
          </div>
          <div
            className="wo-filter-wrapper"
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            <div className="filter-wrapper">
              <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Assigned to </label>
              <SelectStaff staff={maintenanceBoardStaff} setStaff={setMaintenanceBoardStaff} teams={teams?.results} />
            </div>
            <div className="filter-wrapper" style={{ width: '100%', display: 'flex', gap: '16px' }}>
              <div style={{ flex: 1 }}>
                <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>
                  Location
                </label>
                <SelectLocationFilter
                  locations={locations}
                  site={maintenanceBoardSite}
                  setFilterSite={setMaintenanceBoardSite}
                  setFilterSublocation={setMaintenanceBoardSublocation}
                  setLocations={setLocations}
                />
              </div>
              <div style={{ flex: 1 }}>
                <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>
                  Sub location
                </label>
                <SelectSublocationFilter
                  sublocationsData={locationsData}
                  sublocation={maintenanceBoardSublocation}
                  setFilterSublocation={setMaintenanceBoardSublocation}
                  site={maintenanceBoardSite}
                />{' '}
              </div>
            </div>
            <div>
              <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Frequency</label>
              <div
                style={{
                  marginBottom: '16px',
                  width: '100%',
                  display: 'flex',
                  gap: '16px',
                  flexWrap: 'wrap',
                }}
              >
                {maintenanceBoardFrequency.map((item) => (
                  <div className="filter-wrapper-status">
                    <label className="weekly-frequency-switch black-switch">
                      <input
                        type="checkbox"
                        onChange={() => handelFrequencyChange(item)}
                        checked={item.selected}
                      />
                      <span className="weekly-frequency-slider" />
                    </label>
                    <span className="weekly-frequency-label">{item.label}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
            <ButtonBase autoCapitalize="none" onClick={handleEraserFilterClick} style={buttonStyle}>
              Reset
            </ButtonBase>
            <div style={{ display: 'flex', gap: '12px' }}>
              <ButtonBase onClick={handleCloseAdvFilter} style={buttonStyle}>
                Close
              </ButtonBase>
              <ButtonBase onClick={handleActiveAdvFilter} style={buttonDisabledStyle}>
                Activate
              </ButtonBase>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
