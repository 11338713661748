/* eslint-disable max-len */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import meterService from './meterService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // data
  meters: [],
  meter: {},

   // tab
   tab : "meter-tab",
   SearchVal : "",
  // fields
  meterName: '',
  ticketType: "",
  current: "",
  title: "",
  description: "",
  priority: "",
  category: "",
  due_finish: "",
  startService : "",
  start_date : "",
  completed_date : "",
  assigned_to: [],
  asset: "",
  location: "",
  time : "",
  status : "",
  interval: "",
  frequency : "",
  unit : "",
  reading_attachment : null,
  reading_attachment_file : null,
  initial_reading : "",
  attachments: [],
  files: [],
  isScheduleEnabled: true,

  // pagination
  currentPage: 1,
  rowPerPage: 10,

  // modal
  meterModalCreate: false,
  meterModalUpdate: false,
  meterModalDelete: false,

  // success
  isMeterGetSuccess: false,
  isMeterDeleteSuccess: false,
  isMeterCreateSuccess: false,
  isMeterUpdateSuccess: false,
 isDeleteMeterAttachmentSuccess: false, 
  // loading
  isMeterCreateLoading: false,
  isMeterDeleteLoading: false,
  isMeterGetLoading: false,
  isMeterUpdateLoading: false,

  // error
  isMeterCreateError: false,
  isMeterGetError: false,
  isMeterDeleteError: false,
  isMeterUpdateError: false,
};

export const createMeter = createAsyncThunk(
  'createMeter',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(meterService.createMeter, data, thunkAPI);
    return res;
  },
);

export const getMetersByAuthentication = createAsyncThunk(
  'getMetersByAuthentication',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      meterService.getMetersByAuthentication,
      data,
      thunkAPI,
    );
    return res;
  },
);
export const getMetersByPagination = createAsyncThunk(
  'getMetersByPagination',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      meterService.getMetersByPagination,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const getMeterById = createAsyncThunk(
  'getMeterById',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      meterService.getMeterById,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const deleteMeter = createAsyncThunk(
  'deleteMeter',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(meterService.deleteMeter, data, thunkAPI);
    return res;
  },
);
export const deleteMeterAttachment = createAsyncThunk('deleteMeterAttachment', async (data, thunkAPI) => {
  const deleteMeterAttachmentRES = await smartTryCatch(meterService.deleteMeterAttachment, data, thunkAPI);
  return deleteMeterAttachmentRES;
});
export const updateMeter = createAsyncThunk(
  'updateMeter',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(meterService.updateMeter, data, thunkAPI);
    return res;
  },
);
export const patchMeter = createAsyncThunk(
  'patchMeter',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(meterService.patchMeter, data, thunkAPI);
    return res;
  },
);

export const meterSlice = createSlice({
  name: 'meter',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isMeterCreateError = false;
      state.isMeterCreateSuccess = false;
      state.isMeterCreateLoading = false;
      state.isMeterGetError = false;
      state.isMeterGetSuccess = false;
      state.isMeterGetLoading = false;
      state.isMeterDeleteError = false;
      state.isMeterDeleteSuccess = false;
      state.isMeterDeleteLoading = false;
      state.isMeterUpdateError = false;
      state.isMeterUpdateSuccess = false;
      state.isMeterUpdateLoading = false;
      state.isDeleteMeterAttachmentSuccess = false;
    },
    openMeterModalCreate: (state) => {
      state.meterModalCreate = true;
    },
    closeMeterModalCreate: (state) => {
      state.meterModalCreate = false;
      state.attachments = []
    },
    openMeterModalUpdate: (state) => {
      state.meterModalUpdate = true;
    },
    closeMeterModalUpdate: (state) => {
      state.meterModalUpdate = false;
      state.files = []
      state.attachments = []
    },
    openMeterModalDelete: (state) => {
      state.meterModalDelete = true;
    },
    closeMeterModalDelete: (state) => {
      state.meterModalDelete = false;
    },
    setTab : (state , action) => {
      state.tab = action.payload
    },
    setTicketType : (state, action) => {
      state.ticketType = action.payload;
    },
    setCurrent : (state, action) => {
      state.current = action.payload;
    },
    setTitle : (state, action) => {
      state.title = action.payload;
    },
    setDescription : (state, action) => {
      state.description = action.payload;
    },
    setPriority : (state, action) => {
      state.priority = action.payload;
    },
    setCategory : (state, action) => {
      state.category = action.payload;
    },
    setDueFinish : (state, action) => {
      state.due_finish = action.payload;
    },
    setDueStart : (state, action) => {
      state.startService = action.payload;
    },
    setAssignedTo : (state, action) => {
      state.assigned_to = action.payload;
    },
    setLocation : (state, action) => {
      state.location = Number(action.payload);
    },
    setAsset : (state, action) => {
      state.asset = Number(action.payload);
    },
    setInterval : (state, action) => {
      state.interval = action.payload
    },
    setFrequency : (state, action) => {
      state.frequency = action.payload
    },
    setUnit : (state, action) => {
      state.unit = action.payload
    },

    setTime : (state, action) => {
      state.time = action.payload;
    },
    setStatus : (state, action) => {
      state.status = action.payload;
    },
    setStartedDate : (state, action) => {
      state.start_date = action.payload;
    },
    setCompletedDate : (state, action) => {
      state.completed_date = action.payload;
    },

    setReadingAttachment : (state, action) => {
      state.reading_attachment = action.payload;
    },
    setReadingAttachmentFile : (state, action) => {
      state.reading_attachment_file = action.payload;
    },
    setInitialReading : (state, action) => {
      state.initial_reading = action.payload;
    },
    addAttachment: (state, action) => {
      state.attachments.push(...action.payload);
    },
    setFiles: (state, action) => {
      state.files = [...state.files, ...action.payload];
    },
    getAttachment: (state, action) => {
      state.attachments= action.payload;
    },

    setMeter: (state, action) => {
      state.meter = action.payload;
    },
    setMeterName: (state, action) => {
      state.meterName = action.payload;
    },
    setSearchVal: (state, action) => {
      state.SearchVal = action.payload;
    },
    setIsScheduleEnabled: (state, action) => {
      state.isScheduleEnabled = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowPerPage: (state, action) => {
      state.rowPerPage = action.payload;
    },
    resetMeter: (state) => {
      state.ticketType = ""
      state.current= ""
      state.title= ""
      state.description= ""
      state.priority= ""
      state.category= ""
      state.due_finish= ""
      state.startService= ""
      state.assigned_to= []
      state.asset= ""
      state.location= ""
      state.time = ""
      state.status = ""
      state.start_date = ""
      state.completed_date = ""
      state.interval= ""
      state.frequency = ""
      state.unit = ""
      state.reading_attachment = null
      state.reading_attachment_file = null
      state.initial_reading = ""
      state.attachments = []
      state.files = []
      state.isScheduleEnabled = true
      state.meterModalDelete = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMetersByAuthentication.pending, (state) => {
        state.isMeterGetLoading = true;
        state.isMeterGetSuccess = false;
        state.isMeterGetError = false;
      })
      .addCase(getMetersByAuthentication.fulfilled, (state, action) => {
        state.isMeterGetLoading = false;
        state.isMeterGetSuccess = true;
        state.isMeterGetError = false;
        state.meters = action.payload;
      })
      .addCase(getMetersByAuthentication.rejected, (state) => {
        state.isMeterGetLoading = false;
        state.isMeterGetSuccess = false;
        state.isMeterGetError = true;
      })
      .addCase(getMetersByPagination.pending, (state) => {
        state.isMeterGetLoading = true;
        state.isMeterGetSuccess = false;
        state.isMeterGetError = false;
      })
      .addCase(getMetersByPagination.fulfilled, (state, action) => {
        state.isMeterGetLoading = false;
        state.isMeterGetSuccess = true;
        state.isMeterGetError = false;
        state.meters = action.payload;
      })
      .addCase(getMetersByPagination.rejected, (state) => {
        state.isMeterGetLoading = false;
        state.isMeterGetSuccess = false;
        state.isMeterGetError = true;
      })
      .addCase(getMeterById.pending, (state) => {
        state.isMeterGetLoading = true;
        state.isMeterGetSuccess = false;
        state.isMeterGetError = false;
      })
      .addCase(getMeterById.fulfilled, (state, action) => {
        state.isMeterGetLoading = false;
        state.isMeterGetSuccess = true;
        state.isMeterGetError = false;
        state.meter = action.payload;
      })
      .addCase(getMeterById.rejected, (state) => {
        state.isMeterGetLoading = false;
        state.isMeterGetSuccess = false;
        state.isMeterGetError = true;
      })
      .addCase(createMeter.pending, (state) => {
        state.isMeterCreateLoading = true;
        state.isMeterCreateSuccess = false;
        state.isMeterCreateError = false;
      })
      .addCase(createMeter.fulfilled, (state, action) => {
        state.isMeterCreateLoading = false;
        state.isMeterCreateSuccess = true;
        state.isMeterCreateError = false;
        state.meters.results =  [action.payload , ...state.meters.results];
        state.attachments = []
      })
      .addCase(createMeter.rejected, (state) => {
        state.isMeterCreateLoading = false;
        state.isMeterCreateSuccess = false;
        state.isMeterCreateError = true;
      })
      .addCase(deleteMeter.pending, (state) => {
        state.isMeterDeleteLoading = true;
        state.isMeterDeleteSuccess = false;
        state.isMeterDeleteError = false;
      })
      .addCase(deleteMeter.fulfilled, (state, action) => {
        state.isMeterDeleteLoading = false;
        state.isMeterDeleteSuccess = true;
        state.isMeterDeleteError = false;
        state.meters.results = state.meters.results.filter(
          (meter) => meter.id !== action.payload,
        );
      })
      .addCase(deleteMeter.rejected, (state) => {
        state.isMeterDeleteLoading = false;
        state.isMeterDeleteSuccess = false;
        state.isMeterDeleteError = true;
      })
      .addCase(updateMeter.pending, (state) => {
        state.isMeterUpdateLoading = true;
        state.isMeterUpdateSuccess = false;
        state.isMeterUpdateError = false;
      })
      .addCase(updateMeter.fulfilled, (state, action) => {
        state.isMeterUpdateLoading = false;
        state.isMeterUpdateSuccess = true;
        state.isMeterUpdateError = false;
        state.meters.results = 
        state.meters.results.map((meter) => 
        (meter.id === action.payload.id ? action.payload : meter));
        state.meter = action.payload
        state.files = []
      })
      .addCase(updateMeter.rejected, (state) => {
        state.isMeterUpdateLoading = false;
        state.isMeterUpdateSuccess = false;
        state.isMeterUpdateError = true;
      }).addCase(patchMeter.pending, (state) => {
        state.isMeterUpdateLoading = true;
        state.isMeterUpdateSuccess = false;
        state.isMeterUpdateError = false;
      })
      .addCase(patchMeter.fulfilled, (state, action) => {
        state.isMeterUpdateLoading = false;
        state.isMeterUpdateSuccess = true;
        state.isMeterUpdateError = false;
        state.meters.results = 
        state.meters.results.map((meter) => 
        (meter.id === action.payload.id ? action.payload : meter));
        state.meter = action.payload
        state.files = []
      })
      .addCase(patchMeter.rejected, (state) => {
        state.isMeterUpdateLoading = false;
        state.isMeterUpdateSuccess = false;
        state.isMeterUpdateError = true;
      }).addCase(deleteMeterAttachment.pending, (state) => {
        state.isDeleteMeterAttachmentSuccess = false;
      })
      .addCase(deleteMeterAttachment.fulfilled, (state , action) => {
        state.isDeleteMeterAttachmentSuccess = true;
        state.attachments = state.attachments.filter(attachment => attachment.id !== action.payload)

      })
      .addCase(deleteMeterAttachment.rejected, (state) => {
        state.isDeleteMeterAttachmentSuccess = false;
      })
  },
});

export const {
  reset,
  resetMeter,
  openMeterModalCreate,
  closeMeterModalCreate,
  openMeterModalUpdate,
  closeMeterModalUpdate,
  openMeterModalDelete,
  closeMeterModalDelete,
  setMeter,
  setMeterName,
  setSearchVal,
  setTab,
  setTicketType,
  setCurrent,
  setTitle,
  setDescription,
  setPriority,
  setCategory,
  setDueFinish,
  setDueStart,
  setAssignedTo,
  setLocation,
  setAsset,
  setInterval,
  setFrequency,
  setUnit,
  setTime,
  setStatus,
  setStartedDate,
  setCompletedDate,
  setReadingAttachment,
  setInitialReading,
  addAttachment,
  setFiles,
  getAttachment,
  setReadingAttachmentFile,
  setIsScheduleEnabled,
  setCurrentPage,
  setRowPerPage 
} = meterSlice.actions;

export default meterSlice.reducer;
