
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/self-closing-comp */


import React, { useEffect, useState } from 'react';
import { MapContainer, ImageOverlay } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import CustomMarker from './CustomMarker';
import defaultImage from '../../assets/img/FloorPlanNotAvailable.png';

export default function LeafletMap({ img, setAssetId, setbounds, bounds }) {
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);

  useEffect(() => {
    const imgElement = new Image();
    imgElement.src = img || defaultImage;

    imgElement.onload = () => {
      const width = imgElement.width;
      const height = imgElement.height;

      if (!Number.isNaN(width) && !Number.isNaN(height) && width > 0 && height > 0) {
        setImageWidth(width);
        setImageHeight(height);
      }
    };
  }, [img]);

  useEffect(() => {
    if (imageWidth > 0 && imageHeight > 0) {
      const getBounds = (w, h) => {
        const scale = Math.ceil(w / 2);
        const southWest = [-h / scale / 2.0, -w / scale / 2.0];
        const northEast = [h / scale / 2.0, w / scale / 2.0];
        return [southWest, northEast];
      };

      const imageBounds = getBounds(imageWidth, imageHeight);
      setbounds(imageBounds);
    }

  }, [imageWidth, imageHeight ]);


  const center = [0, 0];

  return (
    <div>
      <MapContainer
        center={center}
        style={{ height: '100vh' }}
        minZoom={6}
        zoom={8} // Use currentZoom state here
        maxZoom={12}
      >
        <ImageOverlay url={img || defaultImage} bounds={bounds} />
        <CustomMarker setAssetId={setAssetId} />
      </MapContainer>
    </div>
  );
}

