/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
/* eslint-disable new-cap */

import React, { useRef, useState, useEffect } from 'react';
import FilterField from './FilterField/FilterField';
import { useReactToPrint } from 'react-to-print';
import './AnalyticDashboard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faArrowUp, faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import assetBanner from '../../assets/img/analyticBanner.png';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../loader/loader';
import WorkOrderAnalytics from './WorkOrderAnalytics';
import AnalyticMainPage from './AnalyticMainPage/AnalyticMainPage';
import commingSoon from '../../assets/img/coming_soon.png';
import moment from 'moment';
import icons from '../../utils/icon';

import { Pie, Line } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import OverallProgress from './AnalyticMainPage/OverallProgress';
import { getAnalyticsForDashboard } from '../../redux/features/Analytics/analyticsSlice';
import WorkOrderSummary from './AnalyticMainPage/WorkOrderSummary';
import { format, parseISO } from 'date-fns';

// Register the required components
ChartJS.register(
  ArcElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

function AnalyticDashboard() {
  const [activeButton, setActiveButton] = useState(1);
  const { user } = useSelector(state => state.user);
  const { profile } = useSelector(state => state.company);
  const backgroundImageUrl = `url(${assetBanner})`;
  const [showList, setShowList] = useState(false)

  const {
    isAnalyticsLoading,
    analytics,
    dateWord,
    custom_start,
    custom_end,
    filter,
    site,
    location,
    staff,
    categoryFilter,
    typeFilter,
    customDate,
  } = useSelector(state => state.analytics);

  const [intervals, setIntervals] = useState('day');
  const [workCategory, setWorkCategory] = useState([0, 0]);
  const [totalWorkOrder, setTotalWorkOrder] = useState(0);
  const [summaryData, setSummaryData] = useState({});
  const [overallData, setOverallData] = useState({});
  const [comparingData, setComparingData] = useState({});
  const [openWork, setOpenWork] = useState([]);
  const [closeWork, setCloseWork] = useState([]);
  const trendWorkData = {
    open: [],
    close: [],
    workDates: [],
  };
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      getAnalyticsForDashboard({
        filter:
          filter?.value === 'custom' ? customDate : filter?.value ? filter?.value : 'last7days',
        site: site?.value || null,
        location: location?.value || null,
        staff: staff?.value || null,
        type: typeFilter?.label === 'All' || !typeFilter?.label ? null : typeFilter?.label,
        category:
          categoryFilter?.label === 'All' || !categoryFilter?.label ? null : categoryFilter?.label,
      }),
    );
  }, []);
  // filter, custom_start, custom_end, site, location, staff, typeFilter, categoryFilter


  useEffect(() => {
    setWorkCategory([
      parseFloat(analytics?.tickets_based_on_category?.preventive),
      parseFloat(analytics?.tickets_based_on_category?.corrective, 10),
    ]);
    setOverallData(analytics?.overall_progress)
    setTotalWorkOrder(analytics?.tickets_count?.total_tickets || 0);
    setSummaryData(analytics?.ticket_count_by_category);
    setComparingData(analytics?.comparing_data)
    if (filter === null || filter?.value === 'last15days' || dateWord === 'custom') {
      setIntervals('day');
      setOpenWork(analytics?.tickets_by_status?.open_day);
      setCloseWork(analytics?.tickets_by_status?.close_day);
    } else if (filter?.value === 'last30days') {
      setOpenWork(analytics?.tickets_by_status?.open_day);
      setCloseWork(analytics?.tickets_by_status?.close_day);

      setIntervals('Day');
      // if (intervals === 'day') {
      //   setOpenWork(analytics?.tickets_by_status?.open_day);
      //   setCloseWork(analytics?.tickets_by_status?.close_day);
      // } else {
      //   setIntervals('week');
      //   setOpenWork(analytics?.tickets_by_status?.open_week);
      //   setCloseWork(analytics?.tickets_by_status?.close_week);
      // }
    } else if (filter?.value === 'last365days') {
      setOpenWork(analytics?.tickets_by_status?.open_month);
      setCloseWork(analytics?.tickets_by_status?.close_month);
      setIntervals('month');
      // if (intervals === 'month') {
      //   setOpenWork(analytics?.tickets_by_status?.open_month);
      //   setCloseWork(analytics?.tickets_by_status?.close_month);
      // } else {
      //   setIntervals('quarter');
      //   setOpenWork(analytics?.tickets_by_status?.open_quarter);
      //   setCloseWork(analytics?.tickets_by_status?.close_quarter);
      // }
    }
  }, [analytics, intervals, filter]);
  function handleWork() {
    openWork?.forEach(work => {
      trendWorkData.workDates.push(work[1].split('T')[0]);
      trendWorkData.open.push(work[0]);
    });
    closeWork?.forEach(work => {
      trendWorkData.close.push(work[0]);
    });
  }
  handleWork();
  const handleButtonClick = buttonIndex => {
    setActiveButton(buttonIndex);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const EmptyCardsRef = useRef(null);
  const woCardsRef = useRef(null);
  const pmCardsRef = useRef(null);
  const pieChartRef = useRef(null);
  const overallProgress = useRef(null);
  const trendChartRef = useRef(null);


  // Data for Pie Chart (Work Order vs Preventive Maintenance)
  const pieChartData = {
    labels: ["PM", "WO"],
    datasets: [{
      data: workCategory, // Example data (replace with actual data)
      backgroundColor: ['#A7C9EC', '#215F9A'],
      hoverBackgroundColor: ['#A7C9EC', '#215F9A']
    }]
  };

  // Function to generate random trend data
  const getTrendData = () => {
    return {
      // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Des"],
      labels: trendWorkData?.workDates.map(dateString => {
        const date = parseISO(dateString);
        return format(date, 'MMM, d, yyyy');
      }),
      datasets: [
        {
          label: 'Work Order Open',
          data: trendWorkData?.open,
          borderColor: '#8B5CF4',
          fill: false
        },
        {
          label: "Work Order Close",
          data: trendWorkData?.close,
          borderColor: '#A3A3A3',
          fill: false
        }
      ]
    };
  };

  const trendChartData = getTrendData();

  const [display, setDisplay] = useState({visibility: "hidden", height: 0 , overflow : "hidden" })

  const exportToPDF = () => {
    setDisplay({visibility:"visible"})
    const pdf = new jsPDF('p', 'pt', 'a4'); // Portrait format, pt units, A4 size
    // Function to add chart image to PDF
    const addChartToPDF = (canvas, xOffset, yOffset, chartName, width, height, xTextOffset, yTextOffset) => {
      const img = canvas.toDataURL("image/png");
      pdf.addImage(img, "PNG", xOffset, yOffset, width, height); // Adjust position and size as needed
      pdf.text(xTextOffset, yTextOffset, chartName); // Add chart name below chart
    };

    html2canvas(EmptyCardsRef.current, { scale: 1 }).then((canvas0) => {
      addChartToPDF(canvas0, 0, 0, "", 0, 0, 0, 0);

    // Capture Card Chart
    html2canvas(woCardsRef.current, { scale: 1 }).then((canvas1) => {
      addChartToPDF(canvas1, 20, 20, "", 550, 160, 50, 100);
    
    // Capture Card Chart
      html2canvas(pmCardsRef.current, { scale: 1 }).then((canvas2) => {
      addChartToPDF(canvas2, 20, 200, "", 550, 160, 50, 100);

        // Capture Pie Chart
        html2canvas(pieChartRef.current, { scale: 1 }).then((canvas3) => {
          addChartToPDF(canvas3, 190, 380, "", 200, 200, 50, 100);

          // Capture Progress Chart
          html2canvas(overallProgress.current, { scale: 1 }).then((canvas4) => {
            addChartToPDF(canvas4, 20, 590, "", 550, 200, 70, 280);

            pdf.addPage(); // Add new page for each chart
            // Capture Trend Chart after Pie Chart is added
            html2canvas(trendChartRef.current, { scale: 1 }).then((canvas5) => {
              addChartToPDF(canvas5, 20, 20, "", 550, 350, 70, 280);

              // Save PDF with both charts
              pdf.save("Analytics Report.pdf");
              setDisplay({visibility: "hidden", height: 0 , overflow : "hidden"})
            });
            });
          });
        });
      });
    });
  };

  const pdfReport = <div
  // style={{ paddingBottom: '1.25rem', display: "none" }}
  style={{...display}}
  >
    <div className="assetPdf" >
      <div className='pdfheader' ref={EmptyCardsRef}>
        <img
          src={profile?.logo}
          alt='Company logo'
          className='brand-image img-company'
          id='result_company_base'
          height='100'
          width='100'
          style={{ marginRight: '20px' }}
        />
        <div> <strong>{profile?.name}</strong>
          <br />
          <span>{profile?.address}</span> <br />
          <span>Tel: {profile?.phone_number ? profile?.phone_number : "—"}</span> <span style={{ marginLeft: '10px' }}> Website: {profile?.website ? profile?.website : "—"}</span>
        </div>
      </div>
      <div className="col-12">
        <div className="chart-container" ref={woCardsRef} >
          <div className="col-12">
            <p
              style={{
                fontFamily: 'Source Sans 3',
                fontSize: '22px',
                fontWeight: '600',
                lineHeight: '25.83px',
                letterSpacing: '0.01em',
                textAlign: 'left',
              }}
            >
              Work Order Summary
            </p>
          </div>
          <div
            style={{
              border: '1px solid',
              radius: '10px',
              padding: '20px 0px 20px 0px',
              borderColor: '#E7E7E7',
              borderRadius: '10px',
              boxShadow: '0px 2px 1px 0px #1018280D',
              marginLeft: '3px',
            }}
            className="row col-lg-12 col-12"
          >
            <WorkOrderSummary
              key={1}
              icon={icons.total}
              color="#8B5CF4"
              title="Total"
              value={summaryData?.total_workorder}
              percentage={comparingData?.total_workorder <= 0 ? `${comparingData?.total_workorder}%` : `+${comparingData?.total_workorder}%`}
              perBackColor={comparingData?.total_workorder === 0 ? "#fd941b2a" : comparingData?.total_workorder > 0 ? "#6DA5441A" : "#D9314A1A"}
              perColor={comparingData?.total_workorder === 0 ? "#FD931B" : comparingData?.total_workorder > 0 ? "#6DA544" : "#D9314A"}
              perIcon={{ icon: comparingData?.total_workorder === 0 ? faArrowRight : comparingData?.total_workorder > 0 ? faArrowUp : faArrowDown }}
              // date="(25 Jan - 25 Mar)"
              border="1px solid #E7E7E7"
              inPDF
            />

            <WorkOrderSummary
              key={2}
              icon={icons.completed}
              color="#76B559"
              title="Completed"
              value={summaryData?.total_completed_workorder}
              percentage={comparingData?.completed_workorder <= 0 ? `${comparingData?.completed_workorder}%` : `+${comparingData?.completed_workorder}%`}
              perBackColor={comparingData?.completed_workorder === 0 ? "#fd941b2a" : comparingData?.completed_workorder > 0 ? "#6DA5441A" : "#D9314A1A"}
              perColor={comparingData?.completed_workorder === 0 ? "#FD931B" : comparingData?.completed_workorder > 0 ? "#6DA544" : "#D9314A"}
              perIcon={{ icon: comparingData?.completed_workorder === 0 ? faArrowRight : comparingData?.completed_workorder > 0 ? faArrowUp : faArrowDown }}
              // date="(25 Jan - 25 Mar)"
              border="1px solid #E7E7E7"
              inPDF
            />
            <WorkOrderSummary
              key={3}
              icon={icons.open}
              color="#439EE0"
              title="Open"
              value={summaryData?.total_open_workorder}
              percentage={comparingData?.open_workorder <= 0 ? `${comparingData?.open_workorder}%` : `+${comparingData?.open_workorder}%`}
              perBackColor={comparingData?.open_workorder === 0 ? "#fd941b2a" : comparingData?.open_workorder > 0 ? "#6DA5441A" : "#D9314A1A"}
              perColor={comparingData?.open_workorder === 0 ? "#FD931B" : comparingData?.open_workorder > 0 ? "#6DA544" : "#D9314A"}
              perIcon={{ icon: comparingData?.open_workorder === 0 ? faArrowRight : comparingData?.open_workorder > 0 ? faArrowUp : faArrowDown }}
              // date="(25 Jan - 25 Mar)"
              border="1px solid #E7E7E7"
              inPDF
            />

            <WorkOrderSummary
              key={4}
              icon={icons.hold}
              color="#D63C3C"
              title="On Hold"
              value={summaryData?.total_on_hold_workorder}
              percentage={comparingData?.on_hold_workorder <= 0 ? `${comparingData?.on_hold_workorder}%` : `+${comparingData?.on_hold_workorder}%`}
              perBackColor={comparingData?.on_hold_workorder === 0 ? "#fd941b2a" : comparingData?.on_hold_workorder > 0 ? "#6DA5441A" : "#D9314A1A"}
              perColor={comparingData?.on_hold_workorder === 0 ? "#FD931B" : comparingData?.on_hold_workorder > 0 ? "#6DA544" : "#D9314A"}
              perIcon={{ icon: comparingData?.on_hold_workorder === 0 ? faArrowRight : comparingData?.on_hold_workorder > 0 ? faArrowUp : faArrowDown }}
              // date="(25 Jan - 25 Mar)"
              border="1px solid #E7E7E7"
              inPDF
            />

            <WorkOrderSummary
              key={5}
              icon={icons.progress}
              color="#DD6B17"
              title="In Progress"
              value={summaryData?.total_on_progress_workorder}
              percentage={comparingData?.on_progress_workorder <= 0 ? `${comparingData?.on_progress_workorder}%` : `+${comparingData?.on_progress_workorder}%`}
              perBackColor={comparingData?.on_progress_workorder === 0 ? "#fd941b2a" : comparingData?.on_progress_workorder > 0 ? "#6DA5441A" : "#D9314A1A"}
              perColor={comparingData?.on_progress_workorder === 0 ? "#FD931B" : comparingData?.on_progress_workorder > 0 ? "#6DA544" : "#D9314A"}
              perIcon={{ icon: comparingData?.on_progress_workorder === 0 ? faArrowRight : comparingData?.on_progress_workorder > 0 ? faArrowUp : faArrowDown }}
              // date="(25 Jan - 25 Mar)"
              inPDF
            />
          </div>
        </div>
        <div className="chart-container" ref={pmCardsRef} >
          <div className="col-12 mt-3">
            <p
              style={{
                fontFamily: 'Source Sans 3',
                fontSize: '22px',
                fontWeight: '600',
                lineHeight: '25.83px',
                letterSpacing: '0.01em',
                textAlign: 'left',
              }}
            >
              Preventive Maintenance Summary
            </p>
          </div>
          <div
            style={{
              border: '1px solid',
              radius: '10px',
              padding: '20px 0px 20px 0px',
              borderColor: '#E7E7E7',
              borderRadius: '10px',
              boxShadow: '0px 2px 1px 0px #1018280D',
              marginLeft: '3px',
            }}
            className="row col-lg-12 col-12"
          >
            <WorkOrderSummary
              key={1}
              icon={icons.total}
              color="#8B5CF4"
              title="Total"
              value={summaryData?.total_pm}
              percentage={comparingData?.total_pm <= 0 ? `${comparingData?.total_pm}%` : `+${comparingData?.total_pm}%`}
              perBackColor={comparingData?.total_pm === 0 ? "#fd941b2a" : comparingData?.total_pm > 0 ? "#6DA5441A" : "#D9314A1A"}
              perColor={comparingData?.total_pm === 0 ? "#FD931B" : comparingData?.total_pm > 0 ? "#6DA544" : "#D9314A"}

              perIcon={{ icon: comparingData?.total_pm === 0 ? faArrowRight : comparingData?.total_pm > 0 ? faArrowUp : faArrowDown }}
              // date="(25 Jan - 25 Mar)"
              border="1px solid #E7E7E7"
              inPDF
            />

            <WorkOrderSummary
              key={2}
              icon={icons.completed}
              color="#76B559"
              title="Completed"
              value={summaryData?.total_completed_pm}
              percentage={comparingData?.completed_pm <= 0 ? `${comparingData?.completed_pm}%` : `+${comparingData?.completed_pm}%`}
              perBackColor={comparingData?.completed_pm === 0 ? "#fd941b2a" : comparingData?.completed_pm > 0 ? "#6DA5441A" : "#D9314A1A"}
              perColor={comparingData?.completed_pm === 0 ? "#FD931B" : comparingData?.completed_pm > 0 ? "#6DA544" : "#D9314A"}
              perIcon={{ icon: comparingData?.completed_pm === 0 ? faArrowRight : comparingData?.completed_pm > 0 ? faArrowUp : faArrowDown }}
              // date="(25 Jan - 25 Mar)"
              border="1px solid #E7E7E7"
              inPDF
            />
            <WorkOrderSummary
              key={3}
              icon={icons.open}
              color="#439EE0"
              title="Open"
              value={summaryData?.total_open_pm}
              percentage={comparingData?.open_pm <= 0 ? `${comparingData?.open_pm}%` : `+${comparingData?.open_pm}%`}
              perBackColor={comparingData?.open_pm === 0 ? "#fd941b2a" : comparingData?.open_pm > 0 ? "#6DA5441A" : "#D9314A1A"}
              perColor={comparingData?.open_pm === 0 ? "#FD931B" : comparingData?.open_pm > 0 ? "#6DA544" : "#D9314A"}
              perIcon={{ icon: comparingData?.open_pm === 0 ? faArrowRight : comparingData?.open_pm > 0 ? faArrowUp : faArrowDown }}
              // date="(25 Jan - 25 Mar)"
              border="1px solid #E7E7E7"
              inPDF
            />

            <WorkOrderSummary
              key={4}
              icon={icons.hold}
              color="#D63C3C"
              title="On Hold"
              value={summaryData?.total_on_hold_pm}
              percentage={comparingData?.on_hold_pm <= 0 ? `${comparingData?.on_hold_pm}%` : `+${comparingData?.on_hold_pm}%`}
              perBackColor={comparingData?.on_hold_pm === 0 ? "#fd941b2a" : comparingData?.on_hold_pm > 0 ? "#6DA5441A" : "#D9314A1A"}
              perColor={comparingData?.on_hold_pm === 0 ? "#FD931B" : comparingData?.on_hold_pm > 0 ? "#6DA544" : "#D9314A"}

              perIcon={{ icon: comparingData?.on_hold_pm === 0 ? faArrowRight : comparingData?.on_hold_pm > 0 ? faArrowUp : faArrowDown }}
              // date="(25 Jan - 25 Mar)"
              border="1px solid #E7E7E7"
              inPDF
            />

            <WorkOrderSummary
              key={5}
              icon={icons.progress}
              color="#DD6B17"
              title="In Progress"
              value={summaryData?.total_in_progress_pm}
              percentage={comparingData?.in_progress_pm <= 0 ? `${comparingData?.in_progress_pm}%` : `+${comparingData?.in_progress_pm}%`}
              perBackColor={comparingData?.in_progress_pm === 0 ? "#fd941b2a" : comparingData?.in_progress_pm > 0 ? "#6DA5441A" : "#D9314A1A"}
              perColor={comparingData?.in_progress_pm === 0 ? "#FD931B" : comparingData?.in_progress_pm > 0 ? "#6DA544" : "#D9314A"}
              perIcon={{ icon: comparingData?.in_progress_pm === 0 ? faArrowRight : comparingData?.in_progress_pm > 0 ? faArrowUp : faArrowDown }}
              // date="(25 Jan - 25 Mar)"
              inPDF
            />
          </div>
        </div>


        <div className='wrape-pie-progress'>
          {/* Pie Chart */}
          <div className="chart-container" ref={pieChartRef}>
            <Pie
              data={pieChartData}
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text: "Work Order vs Preventive Maintenance",
                    font: { size: 50 }
                  },
                  legend: {
                    display: true,
                    position: "bottom",
                    font: { size: 100 },
                    labels:{
                      font: { size: 20 },
                    }
                  }
                }
              }}
            />
          </div>
          <div className="chart-container" ref={overallProgress}>
            <p
              style={{
                fontFamily: 'Source Sans 3',
                fontSize: '22px',
                fontWeight: 600,
                lineHeight: '25.83px',
                letterSpacing: '0.01em',
                textAlign: 'left',
              }}
            >
              Overall Progress
            </p>
            <div
              style={{
                border: '1px solid #EAEBEC',
                borderRadius: '11px',
                padding: '23px',
                boxShadow: '0px 2px 1px 0px #1018280D',
                paddingTop: '60px',
                height: "333px"
              }}
            >
              <OverallProgress type={'Work Orders'} percent={overallData?.wo_completion} color={'#439EE0'} />
              <OverallProgress type={'PM'} percent={overallData?.pm_completion} color={'#DD6B17'} />
              <OverallProgress type={'Overall'} percent={overallData?.overall} color={'#76B559'} />
            </div>
          </div>
        </div>
        {/* Trend Chart */}
        <div className="chart-container" ref={trendChartRef}>
          <Line
            data={trendChartData}
            options={{
              responsive: true,
              plugins: {
                title: {
                  display: true,
                  text: "Work Order Trends",
                  font: { size: 30 }
                },
                legend: {
                  display: true,
                  position: "bottom"
                }
              },
              scales: {
                x: {
                  type: 'category', // Use 'category' type for X axis
                  labels: trendChartData.labels
                },
                y: {
                  beginAtZero: true // Start y-axis from 0
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  </div>


  const printMenu = <div className="row ml-auto">
    <div className="dropdown" onClick={() => setShowList(!showList)}>
      {showList && <div className='tck-overlay' onClick={() => setShowList(!showList)}></div>}
      <button
        style={{
          width: '42px',
          height: '40px',
          borderRadius: '5px',
          padding: '10px 14px 10px 14px',
          backgroundColor: '#FFFFFF',
          opacity: '0px',
        }}
        className={` mx-4`}
      >
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </button>
    <ul className={`dropdown-menu ${showList && "dropdown-menu-active"}`} role="menu" aria-labelledby="dropdownMenu1">
        <li role="presentation">
          <button type='button'
            data-toggle="modal" data-target="#modal-print-Work-Order"
            title="Print Analytics Report" onClick={exportToPDF} > Print </button>
        </li>
      </ul>
    </div>
  </div>

  return (
    <div
      style={{
        backgroundColor: 'White',
        marginLeft: '10px',
        marginRight: '10px',
        paddingTop: '10px',
        borderRadius: '5px',
        marginBottom: '20px',
      }}
    >
      <div className="row col-lg-12 col-12 mx-auto mt-3">
        <div className="col-12">
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) , rgba(0, 0, 0, 0.8) ),
               ${backgroundImageUrl}`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              // width: '1140px',
              height: '240px',
              borderRadius: '10px',
              overflow: 'hidden',
            }}
          >
            <div className="d-flex justify-content-center">
              <div className="mt-4">
                <p className="banner-title">Smart Asset Facility Management</p>
                <p className="sub-text">Powered by FOX</p>
              </div>
            </div>
            <div className="d-flex justify-between mx-4 mt-5">
              <div
                style={{
                  color: '#FFFFFF',
                  fontFamily: 'Source Sans 3',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '16.44px',
                  letterSpacing: '0.01em',
                  textAlign: 'left'
                }}
              >
                <div style={{ fontSize: "20px" }}>
                  Welcome {user?.first_name} {user?.last_name}
                </div>
                <h5>{profile?.name}</h5>
              </div>
              <div className="d-flex">
                <div
                  style={{
                    backgroundColor: '#FFFFFF',
                    width: '339px',
                    height: '40px',
                    borderRadius: '6px',
                  }}
                  className=""
                >
                  <button
                    className={`button-txt buttonGroup ${activeButton === 1 ? 'activeButton1' : ''
                      }`}
                    onClick={() => handleButtonClick(1)}
                  >
                    Main
                  </button>
                  <button
                    className={`button-txt buttonGroup ${activeButton === 2 ? 'activeButton1' : ''
                      }`}
                    onClick={() => handleButtonClick(2)}
                  >
                    Team
                  </button>
                  <button
                    className={`button-txt buttonGroup ${activeButton === 3 ? 'activeButton1' : ''
                      }`}
                    onClick={() => handleButtonClick(3)}
                  >
                    Meter
                  </button>
                </div>
                <div>
                  {printMenu}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row col-lg-12 col-12  mt-3">
        {activeButton === 1 ? (
          <>
            <div className="col-12 ms-3">
              {/* <div className="col-lg col-xs-6"> */}
              <p
                style={{
                  fontFamily: 'Source Sans 3',
                  fontWeight: 600,
                  fontSize: '22px',
                  lineHeight: '25.83px',
                  color: '#191919',
                }}
              >
                FOX Analytics{' '} ({moment(analytics?.start_date).format("DD MMM")} - {moment(analytics?.end_date).format("DD MMM")})
              </p>
              {/* </div> */}
              <FilterField />
            </div>
            <div className="col-12">
              <AnalyticMainPage />
            </div>
          </>
        ) : (
          <div style={{ height: '100vh', marginLeft: "auto", marginRight: "auto" }}>
            <div>
              <img src={commingSoon} alt="Kola" />
            </div>
          </div>
        )}
        {/* {isAnalyticsLoading ? (
          <Loader />
        ) : (
            <div>
                {
                    activeButton === 1 || 0?<div>
                        <WorkOrderAnalytics/>
                    </div> 
                    :<div>nothing</div>
                }
            </div>
        )} */}
      {pdfReport}
      </div>
    </div>
  );
}

export default AnalyticDashboard;
