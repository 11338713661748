import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getSORByAuthentication = async () => {
  const res = await http.get(
    `${BASE_URL}/sor/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of SOR by its company ID failed",
    res
  );
};
const getSORSoucrcesTardes = async () => {
  const res = await http.get(
    `${BASE_URL}/sor/sources-trades/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of SOR by its company ID failed",
    res
  );
};
const getSORSummary = async () => {
  const res = await http.get(
    `${BASE_URL}/sor/summary/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of SOR by its company ID failed",
    res
  );
};
const getSORByPagination = async (data) => {
  const advancedKeyWord = `&all_keywords=${data.all}&exact_keywords=${data.exact}&any_keywords=${data.any}&none_keywords=${data.none}&bookmark=${data.isBookmarked}`
  const res = await http.get(
    `${BASE_URL}/sor/?search=${data.searchValue}&page=${data.currentPage}&page_size=${data.rowPerPage}&source=${data.source}&trade=${data.trade}${advancedKeyWord}`
  );
  return assert(
    res,
    res.data,
    "Retrieval of SOR by its company ID failed",
    res
  );
};

const getSORHistoryByPagination = async (data) => {
  const res = await http.get(
    `${BASE_URL}/sor/history/?search=${data.searchValue}&page=${data.currentPage}&page_size=${data.rowPerPage}`
  );
  return assert(
    res,
    res.data,
    "Retrieval of SOR by its company ID failed",
    res
  );
};

const getSORById = async (id) => {
  const res = await http.get(
    `${BASE_URL}/sor/${id}`,
  );
  return assert(
    res,
    res.data,
    "Retrieval of SOR by its company ID failed",
    res
  );
};
const deleteBillById = async (id) => {
  const res = await http.delete(
    `${BASE_URL}/bills/${id}/`,
  );
  return assert(
    res,
    id,
    "Retrieval of Bill by its company ID failed",
    res
  );
};

const createBill = async (data) => {
  const res = await http.post(
    `${BASE_URL}/bill/`, data
  );
  return assert(
    res,
    res.data,
    "Retrieval of SOR by its company ID failed",
    res
  );
};
const getBills = async (data) => {
  const res = await http.get(
    `${BASE_URL}/bill/?search=${data.searchValue}&page=${data.currentPage}&page_size=${data.rowPerPage}`,
  );
  return assert(
    res,
    res.data,
    "Retrieval of SOR by its company ID failed",
    res
  );
};
const getBillById = async (id) => {
  const res = await http.get(
    `${BASE_URL}/bills/${id}/`,
  );
  return assert(
    res,
    res.data,
    "Retrieval of SOR by its company ID failed",
    res
  );
};

const updateBill = async (data) => {
  const res = await http.put(
    `${BASE_URL}/bills/${data.id}/`, data
  );
  return assert(
    res,
    res.data,
    "Retrieval of SOR by its company ID failed",
    res
  );
};

const updateBookmark = async (id) => {
  const res = await http.post(
    `${BASE_URL}/sor/${id}/bookmark/`, 
  );
  return assert(
    res,
    res.data,
    "Retrieval of SOR by its company ID failed",
    res
  );
};

const exportSOR = async data => {
  const res = await http.post(`${BASE_URL}/sor/export/`, data);
  return assert(res, res.data, 'Export of SOR failed', res);
};

const importSOR = async data => {
  const res = await http.postImg(`${BASE_URL}/sor/import/`, data);
  return assert(res, res.data, 'Import of SOR failed', res);
};
const downloadTemplate = async params => {
  const res = await http.postImg(`${BASE_URL}/template_download/download_template/`, params, {
    responseType: 'blob',
  });
  return assert(res, res.data, 'Import of SOR failed', res);
};


const SORService = {
  getSORByAuthentication,
  getSORByPagination,
  getSORHistoryByPagination,
  exportSOR,
  importSOR,
  downloadTemplate,
  getSORSoucrcesTardes,
  getSORSummary,
  createBill,
  getBills,
  getBillById,
  updateBill,
  updateBookmark,
  getSORById,
  deleteBillById
};

export default SORService;
