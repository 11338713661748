/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

import { getTechnicianPositionsByAuthentication } from '../../redux/features/technicianPosition/technicianPositionSlice';
import { getDepartmentsByAuthentication } from '../../redux/features/department/departmentSlice';
import { updateTeam } from '../../redux/features/teams/teamSlice';
import { updateUserProfile } from '../../redux/features/user/userSlice';

export default function TechTeamPopUp ({ team, departmentManagers }) {
  const { departments } = useSelector(state => state.department);
  const { teams } = useSelector(state => state.teams);
  const { user } = useSelector(state => state.user);
  const statuses = [true, false]
  const [deps, setDeps] = useState([]);
  const [techs, setTechs] = useState([]);
  const [lineManagers, setLineManagers] = useState([]);
  const { technicians } = useSelector(state => state.technicianPosition);

  const [userInfo, setUserInfo] = useState({
    first_name: team.first_name,
    last_name: team.last_name,
    is_active: team.is_active,
    profile: {
      department: team.profile.department?.id || null,
    },
    technicalteam: {
      position: team.technicalteam?.position?.id || null,
      hourly_rate: team.technicalteam?.hourly_rate,
      tech_manager: team?.technicalteam?.tech_manager?.id || 0,
    },
    status: team?.status,
  });

  useEffect(() => {
    const departmentName = departments?.find(
      item => item?.id === +userInfo.profile.department,
    )?.name;
    setLineManagers(
      departmentManagers?.find(item => item?.department_name === departmentName)?.managers,
    );
  }, [userInfo.profile.department]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartmentsByAuthentication());
    dispatch(getTechnicianPositionsByAuthentication());
  }, [dispatch]);

  useEffect(() => {
    setTechs(technicians);
  }, [technicians]);

  useEffect(() => {
    setDeps(departments);
  }, [departments]);

  const handleUpdate = e => {
    e.preventDefault();
    const data = { id: team.id, userInfo: userInfo };
    if (userInfo.first_name && userInfo.last_name) {
      dispatch(updateTeam(data));
    } else {
      toast.warning('Please enter first name and last name', {
        toastId: 'invalid first and last name teams tech',
      });
    }
  };

  return (
    <>
      <div
        className="card-body mx-auto row col-lg-12 pb-0  mb-5"
        style={{ paddingBottom: '0rem !important' }}
      >
        <div className="col col-lg-12 col-sm-12 mr-2">
          <div className="row mb-0">
            <div className=" col-lg-12 col-sm-12">
              <div className="row mb-0">
                <div className="row mb-0">
                  <div className=" col-lg-6 col-sm-12">
                    {' '}
                    <span className="font-weight-normal">
                      Email <span className="font-danger m-0">*</span>
                    </span>
                    <input
                      name="username"
                      type="email"
                      className="form-control mb-2"
                      required
                      disabled
                      placeholder="Email"
                      id="username"
                      onChange="remove_error_msg('username')"
                      value={team.email}
                    />
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <span className="font-weight-normal">
                      Account Type <span className="font-danger m-0">*</span>
                    </span>

                    <select
                      required
                      className="mb-2"
                      name="role"
                      id="role"
                      onChange="remove_error_msg('role'); show_line_manager(this)"
                      disabled
                    >
                      <option selected>
                        {team.role === 'CliAdm' ? 'Administrator' : team.role}
                      </option>
                    </select>

                    <div className="col-lg-12 col-sm-12 m-0">
                      <span className="font-danger" id="role_error" />
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    {' '}
                    <span className="font-weight-normal">
                      First Name <span className="font-danger m-0">*</span>
                    </span>
                    <input
                      name="first name"
                      type="text"
                      className="form-control mb-2"
                      required
                      placeholder="First Name"
                      id="firstname"
                      onChange={e => setUserInfo({ ...userInfo, first_name: e.target.value })}
                      value={userInfo.first_name}
                    />
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    {' '}
                    <span className="font-weight-normal">
                      Last Name <span className="font-danger m-0">*</span>
                    </span>
                    <input
                      name="lastname"
                      type="text"
                      className="form-control mb-2"
                      required
                      placeholder="Last name"
                      id="lastname"
                      onChange={e => setUserInfo({ ...userInfo, last_name: e.target.value })}
                      value={userInfo.last_name}
                    />
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <span className="font-weight-normal">Department</span>
                    <select
                      className="mb-2"
                      name="role"
                      id="role"
                      onChange={e => {
                        setUserInfo({
                          ...userInfo,
                          profile: { department: +e.target.value, job_title: e.target.name },
                          technicalteam: {
                            ...userInfo.technicalteam,
                            tech_manager: '',
                          },
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        --SELECT--
                      </option>
                      {deps?.map(t =>
                        team?.profile?.department?.id === t.id ? (
                          <option value={t.id} selected>
                            {t.name}
                          </option>
                        ) : (
                          <option value={t.id}>{t?.name}</option>
                        ),
                      )}
                    </select>

                    <div className="col-lg-12 col-sm-12 m-0">
                      <span className="font-danger" id="role_error" />
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <span className="font-weight-normal">Position</span>
                    <select
                      className="mb-2"
                      name="role"
                      id="role"
                      onChange={e =>
                        setUserInfo({
                          ...userInfo,
                          technicalteam: { ...userInfo.technicalteam, position: +e.target.value },
                        })
                      }
                    >
                      <option value="" disabled selected>
                        --SELECT--
                      </option>
                      {techs?.map(t =>
                        team?.technicalteam?.position?.id === t.id ? (
                          <option value={t.id} selected>
                            {t.name}
                          </option>
                        ) : (
                          <option value={t.id}>{t?.name}</option>
                        ),
                      )}
                    </select>

                    <div className="col-lg-12 col-sm-12 m-0">
                      <span className="font-danger" id="role_error" />
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    {' '}
                    <span className="font-weight-normal">Hourly Rate</span>
                    <input
                      name="hourate"
                      type="number"
                      className="form-control mb-2"
                      required
                      placeholder="$0"
                      id="hourate"
                      onChange={e =>
                        setUserInfo({
                          ...userInfo,
                          technicalteam: { ...userInfo.technicalteam, hourly_rate: e.target.value },
                        })
                      }
                      value={userInfo.technicalteam.hourly_rate}
                    />
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <span className="font-weight-normal">
                      Line Manager <span className="font-danger m-0">*</span>
                    </span>
                    <select
                      required
                      className="mb-2"
                      name="role"
                      id="role"
                      onChange={e =>
                        setUserInfo({
                          ...userInfo,
                          technicalteam: {
                            ...userInfo.technicalteam,
                            tech_manager: +e.target.value,
                          },
                        })
                      }
                      value={userInfo.technicalteam.tech_manager}
                    >
                      <option value="" selected>
                        --SELECT--
                      </option>
                      {lineManagers?.length > 0
                        ? lineManagers?.map(e => {
                            return (
                              <option key={e.id} value={e.id}>
                                {e.first_name} {e.last_name}
                              </option>
                            );
                          })
                        : ''}
                    </select>

                    <div className="col-lg-12 col-sm-12 m-0">
                      <span className="font-danger" id="role_error" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                          <label htmlFor="department" className="font-weight-normal">
                            Status
                          </label>
                          <select
                            className="mb-2"
                            name="status"
                            id="status"
                            disabled={user?.role !== 'CliAdm'}
                            onChange={e=>setUserInfo({
                              ...userInfo,
                              status:  e.target.value 
                            })}
                          >

                            <option value="" disabled defaultValue>
                              --SELECT--
                            </option>

                            {statuses?.map((stat) => (
                              <option key={stat} value={stat} selected={team?.status === stat}>
                                {stat ? 'Available' : 'Unavailable'}
                              </option>
                            ))}
                          </select>

                        </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" row  m-0 p-2 page-profile-footer">
        <button className="btn primary-btn" type="submit" onClick={handleUpdate}>
          Save
        </button>
      </div>
    </>
  );
}
