/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { IconButton } from '@material-ui/core';
import { getPagination, setDateFilterState, setFilterEndDate, setFilterFrequency, setFilterStartDate, setFilterStatus, setPMFilterCategory, setPMFilterSite, setPMFilterSublocation, setPMFiltertype, setStaff } from '../../redux/features/maintenance/maintenanceSlice';
import { SelectTypesAndCategories } from '../../components/select/select';
import IconVerticalBox from '../../assets/svg-icons/IconVerticalBox';
import IconDownload from '../../assets/svg-icons/IconDownload';
import ReloadIcon from '../../assets/svg-icons/ReloadIcon';
import classes from '../../components/assetsTable/assetsTable.module.css';
import ExportPM from './Componets/ExportPM';
import PMAdvanceFilter from './Componets/PMAdvanceFilter';
import DateRangeFilter from './Componets/DateRangeFilter';


const PMFilter = ({
  setISShowFilter,
  iSShowFilter,
  allColumns,
  setIsLoading
}) => {
  const dispatch = useDispatch();
  const [iSShowExportPopup, setISShowExportPopup] = useState(false)
  // Advance filter dialog start
  const [openAdvFilter, setOpenAdvFilter] = useState(false)

  const handleOpenAdvFilter = () => {
    setOpenAdvFilter(true)
  }

  const handleCloseAdvFilter = () => {
    setOpenAdvFilter(false)
  }
  // Advance filter dialog end


  const { locations } = useSelector((state) => state.location);
  const sublocations = useSelector((state) => state.location?.sublocations?.results);
  const { user } = useSelector((state) => state.user);

  // const statusData = [
  //   { id: 1, name: 'Open' },
  //   { id: 2, name: 'In-Progress' },
  //   { id: 3, name: 'On-Hold' },
  //   { id: 4, name: 'Completed' },
  // ]

  const frequencyData = [
    { id: 1, name: 'Day', value: "day" },
    { id: 2, name: 'Week', value: "week" },
    { id: 3, name: 'Month', value: "month" },
    { id: 4, name: 'Year', value: "year" },
  ]
  const
    {
      filterCategory,
      filtertype,
      filterSite,
      filterSublocation,
      staff,
      filterStatus,
      rowPerPage,
      currentPage,
      searchValue,
      pmSchedule,
      filterStartDate,
      filterEndDate,
      dateFilterState,
      filterFrequency,
      filterAsset,
      filterPrioirty
    } = useSelector(state => state.maintenance)

  const resetState = () => {
    dispatch(setPMFilterSite(null));
    dispatch(setPMFilterSublocation(null));
    dispatch(setPMFilterCategory(null));
    dispatch(setPMFiltertype(null));
    dispatch(setFilterStatus(null));
    dispatch(setStaff(null));
  }

  const handleEraserFilterClick = async () => {
    
    dispatch(setFilterFrequency(null))
    dispatch(setFilterStartDate(""))
    dispatch(setFilterEndDate(""))
    dispatch(setDateFilterState([
      {
          startDate: "",
          endDate: "",
          key: 'selection',
      },
  ]))

    const queryString = filterPrioirty
      .filter(priority => priority.selected) // Only keep selected items
      .map(priority => `priority=${priority.label}`) // Convert to lowercase and map to query string
      .join('&');
    setIsLoading(true)
    const filters = {
      rowPerPage,
      currentPage,
      searchValue,
      startDate: "",
      endDate:  "",
      pmSchedule: pmSchedule === "PM Schedule" ? "False" : "True",
      site: filterSite?.value || null,
      sublocation: filterSublocation?.value || null,
      filterCategory: filterCategory?.value || null,
      type: filtertype?.value || null,
      staff: staff?.value || null,
      asset: filterAsset?.value || null,
      status: filterStatus?.label && filterStatus?.label !== "All" ? filterStatus?.label : null,
      frequency:  null,
      prioirty: queryString
    }
    await dispatch(getPagination(filters))
    setIsLoading(false)
  };

  // useEffect(() => {
  //   setLocations(sublocations || [])
  // }, [sublocations])


  const [show, setShow] = useState(false);

  const getDataRows = async () => {
    const queryString = filterPrioirty
      .filter(priority => priority.selected) // Only keep selected items
      .map(priority => `priority=${priority.label}`) // Convert to lowercase and map to query string
      .join('&');
    setIsLoading(true)
    const filters = {
      rowPerPage,
      currentPage,
      searchValue,
      startDate: dateFilterState?.find(item => item.startDate)?.startDate ? moment(dateFilterState?.find(item => item.startDate)?.startDate).format("YYYY-MM-DD") : "",
      endDate: dateFilterState?.find(item => item.startDate)?.startDate ? moment(dateFilterState?.find(item => item.endDate)?.endDate).format("YYYY-MM-DD") : "",
      pmSchedule: pmSchedule === "PM Schedule" ? "False" : "True",
      site: filterSite?.value || null,
      sublocation: filterSublocation?.value || null,
      filterCategory: filterCategory?.value || null,
      type: filtertype?.value || null,
      staff: staff?.value || null,
      asset: filterAsset?.value || null,
      status: filterStatus?.label && filterStatus?.label !== "All" ? filterStatus?.label : null,
      frequency: filterFrequency?.label && filterFrequency?.label !== "All" ? filterFrequency?.label.toLowerCase() : null,
      prioirty: queryString
    }
    await dispatch(getPagination(filters))
    setIsLoading(false)
  }

  const handelFetchFilters = () => {
    getDataRows()
  }

  const handleClose = () => {
    setShow(false);
  };
  const handleUpdate = async () => {
    setShow(false);
    dispatch(setFilterStartDate(dateFilterState?.find(item => item.startDate)?.startDate))
    dispatch(setFilterEndDate(dateFilterState?.find(item => item.endDate)?.endDate))
    await getDataRows()
  };



  return (
    <div className='filters-container'>
      <div className="filters-left-wrapper">
        <div className='filters-left-wrapper-period'>
          <label style={{ color: '#36383C', fontWeight: 400 }}>Period </label>
          <div className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
            style={{ fontSize: "14px", cursor: "pointer" }}
            onClick={() => setShow(!show)}>
            {filterStartDate ? <><span>{moment(filterStartDate).format("DD MMM YYYY")}</span> - <span>{moment(filterEndDate).format("DD MMM YYYY")}</span> </> : "All time"}
          </div>
          {
            show && <>
              <div className="tck-overlay" onClick={() => setShow(!show)} />
              <DateRangeFilter
                show={show}
                handleUpdate={handleUpdate}
                handleClose={handleClose}
                state={dateFilterState}
                setState={setDateFilterState}
              />
            </>
          }
        </div>
        {/* <div className="filter-wrapper">
          <label style={{ color: '#36383C', fontWeight: 400 }}>Frequency </label>
          <SelectTypesAndCategories dataArray={frequencyData} stateValue={filterFrequency} setState={setFilterFrequency} />
        </div> */}
        {/*
      <div className="filter-wrapper">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Status</label>
        <SelectTypesAndCategories dataArray={statusData} stateValue={filterStatus} setState={setFilterStatus} />
      </div>
      */}
      <button style={{ alignSelf: "flex-end", padding: ".375rem .7rem", }} className='new-btn btn' onClick={handelFetchFilters} title="Reload">
          <FontAwesomeIcon icon={faRotate} style={{ color: 'black' }} />
        </button>
        <button className='new-btn btn' onClick={handleEraserFilterClick} title="Reset">
          <ReloadIcon />
        </button>
      </div>
      <div className='filters-right-wrapper'>
        <PMAdvanceFilter
          openAdvFilter={openAdvFilter}
          handleOpenAdvFilter={handleOpenAdvFilter}
          handleCloseAdvFilter={handleCloseAdvFilter}
          getDataRows={getDataRows}
          dateFilterState={dateFilterState}
          setDateFilterState={setDateFilterState}
        />
        <Button variant="contained" style={{
          backgroundColor: '#36383C',
          color: '#F8F9F9',
          fontSize: '14px',
          fontWeight: '600',
          padding: '8px 16px',
          borderRadius: '8px'
        }}
          onClick={handleOpenAdvFilter}
          className='adv-button'
        >
          Advance filter
        </Button>

        <div style={{ position: "relative" }}>
          <IconButton
            style={{
              backgroundColor: '#F8F9F9',
              borderRadius: '8px',
              border: '1px solid #E1E1E1'
            }}
            variant="contained"
            title="Column selection"
            className='outlined-button'
            onClick={() => setISShowFilter(!iSShowFilter)}
          ><IconVerticalBox />
          </IconButton>
          {iSShowFilter && (
            <>
              <div className="tck-overlay" onClick={() => setISShowFilter(!iSShowFilter)} />
              <div className={classes.newFilterTable}>
                <div className={classes.newFilterTableCheckboxs}>
                  <p className='weekly-frequency-label ml-0'>Filter column</p>
                  {/* Loop through columns data to create checkbox */}
                  {allColumns.map(column => {
                    if (column.id !== 'delete-table-column') {
                      return (
                        <div key={column.id} className={classes.newFilterTableCheckboxs}>
                          <label className="weekly-frequency-switch black-switch">
                            <input type="checkbox"  {...column.getToggleHiddenProps()} />
                            <span className="weekly-frequency-slider" />
                          </label>
                          <span className="weekly-frequency-label">{column.Header}</span>
                        </div>
                      );
                    }
                  })}
                  <br />
                </div>
              </div>
            </>
          )}
        </div>
        {user?.role !== "Technical Team" &&
          <>
            <IconButton title='Download' variant="contained"
              style={{
                backgroundColor: '#F8F9F9',
                borderRadius: '8px',
                border: '1px solid #E1E1E1'
              }}
              onClick={() => setISShowExportPopup(!iSShowExportPopup)}
              className='outlined-button'>
              <IconDownload />
            </IconButton>
            <ExportPM
              iSShowExportPopup={iSShowExportPopup}
              setISShowExportPopup={setISShowExportPopup}
            />
          </>
        }
      </div>
    </div>
  );
};

export default PMFilter;
