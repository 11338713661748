import moment from 'moment';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getEventsByAuthentication = async (date) => {
  const dateFilter = `?start_date=${moment(startOfMonth( new Date(date))).format("YYYY-MM-DD")}&end_date=${moment(endOfMonth( new Date(date))).format("YYYY-MM-DD")}`
  const res = await http.get(`${BASE_URL}/dashboard/${dateFilter}`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};

const getEventsByFilters = async (data) => {
  const filters = `site=${data.site}&sublocation=${data.sublocation}&assigned_to=${data.staff}&${data.statusAdvance || ""}&${data.feature ? data.feature: `ticket_type=""`}&start_date=${data?.startDate || ""}&end_date=${data?.endDate || ""}`
  const res = await http.get(`${BASE_URL}/task-planner/?${filters}`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};
const getTaskPlannerBoard = async (data) => {
  const filters = `site=${data.site}&sublocation=${data.sublocation}&assigned_to=${data.staff}&${data.statusAdvance || ""}&${data.feature || ""}&start_date=${data?.startDate || ""}&end_date=${data?.endDate || ""}`
  const res = await http.get(`${BASE_URL}/task-planner/?${filters}`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};

const getMaintenacePlanner = async (data) => {
  const dateFilter = data?.selectedCalendarMode === "Month" ? `&start_date=${moment(startOfMonth( new Date())).format("YYYY-MM-DD")}&end_date=${moment(endOfMonth( new Date())).format("YYYY-MM-DD")}`:
    `&start_date=${moment(startOfWeek( new Date())).format("YYYY-MM-DD")}&end_date=${moment(endOfWeek( new Date())).format("YYYY-MM-DD")}`
  const filters = `site=${data.site}&sublocation=${data.sublocation}&assigned_to=${data.staff}&${data.status}&is_pm_ticket=${false}&${data.frequency}${dateFilter}`
  const res = await http.get(`${BASE_URL}/maintenance-planner/?${filters}`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};

const getMaintenacePlannerBoard = async (data) => {
  const filters = `site=${data.site}&sublocation=${data.sublocation}&assigned_to=${data.staff}&is_pm_ticket=${true}&${data.frequency}&start_date=${data?.startDate || ""}&end_date=${data?.endDate || ""}`
  const res = await http.get(`${BASE_URL}/maintenance-planner/?${filters}`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};

const getMeterEventsByAuthentication = async (date) => {
  const dateFilter = `?start_date=${moment(startOfMonth( new Date(date))).format("YYYY-MM-DD")}&end_date=${moment(endOfMonth(new Date(date))).format("YYYY-MM-DD")}`
  const res = await http.get(`${BASE_URL}/meter-dashboard/${dateFilter}`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};


const getEventById = async (eventId) => {
  const res = await http.get(`${BASE_URL}/dashboard/${eventId}/`);

  return assert(res, res.data, 'Retrieval of event by its ID failed', res);
};

const createEvent = async (eventData) => {
  const res = await http.post(`${BASE_URL}/dashboard/`, eventData);

  return assert(res, res.data, 'Creation of event failed', res);
};

const deleteEvent = async (eventId) => {
  const res = await http.delete(`${BASE_URL}/dashboard/${eventId}`);
  return assert(res, eventId, 'Deletion of event failed', res);
};

const updateEvent = async (eventData) => {
  const res = await http.put(
    `${BASE_URL}/dashboard/${eventData.id}/`,
    eventData,
  );
  return assert(res, res.data, 'Update of event failed', res);
};

const EventService = {
  getEventsByAuthentication,
  getEventById,
  createEvent,
  deleteEvent,
  updateEvent,
  getMeterEventsByAuthentication,
  getEventsByFilters,
  getTaskPlannerBoard,
  getMaintenacePlanner,
  getMaintenacePlannerBoard
};

export default EventService;
