import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getInventoryByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/inventory/`);

  return assert(res, res.data, 'Retrieval of inventory  by its company ID failed', res);
};

const exportInventory = async data => {
  const res = await http.post(`${BASE_URL}/inventory-export/`, data);
  return assert(res, res.data, 'Export of inventory  failed', res);
};

const importInventory = async data => {
  const res = await http.postImg(`${BASE_URL}/inventory-import/`, data);
  return assert(res, res.data, 'Import of inventory  failed', res);
};

const getInventoryServiceById = async uuid => {
  const res = await http.get(`${BASE_URL}/inventory-service/${uuid}/`);
  return assert(res, res.data, 'Retrieval of inventory service by its ID failed', res);
};

const getInventoryById = async InventoryId => {
  const res = await http.get(`${BASE_URL}/inventory/${InventoryId}/`);
  return assert(res, res.data, 'Retrieval of inventory  by its ID failed', res);
};

const createInventory = async InventoryData => {
  const res = await http.post(`${BASE_URL}/inventory/`, InventoryData);

  return assert(res, res.data, 'Creation of inventory  failed', res);
};

const deleteInventory = async InventoryId => {
  const res = await http.delete(`${BASE_URL}/inventory/${InventoryId}/`);

  return assert(res, InventoryId, 'Deletion of inventory  failed', res);
};

const updateInventory = async InventoryData => {
  const res = await http.put(`${BASE_URL}/inventory/${InventoryData.id}/`, InventoryData.formdata);

  return assert(res, res.data, 'Update of inventory  failed', res);
};

const updateInventoryQuantity = async data=>{
  const res = await http.post(`${BASE_URL}/adjust_inventory/`,data);
  return assert(res, res.data, 'Update of inventory quantity failed', res);
}

const getInventoryHistoryById = async data => {
  let res = '';
  if (data.id) {
    res = await http.get(
      `${BASE_URL}/inventory-history/${data.id}/?search=${data.searchValue}&?page=${data.currentPage}&page_size=${data.rowPerPage}`,
    );
  }

  return assert(res, res.data, 'Retrieval of location history by its ID failed', res);
};
const getInventoryAdjustmentHistoryById = async data => {
  let res = '';
  if (data.id) {
    res = await http.get(
      `${BASE_URL}/adjust_inventory/adjustment-list/${data.id}/?search=${data.searchValue}&?page=${data.currentPage}&page_size=${data.rowPerPage}`,
    );
  }

  return assert(res, res.data, 'Retrieval of location history by its ID failed', res);
};
const downloadTemplate = async params => {
  const res = await http.postImg(`${BASE_URL}/template_download/download_template/`, params, {
    responseType: 'blob',
  });
  return assert(res, res.data, 'Import of asset failed', res);
};
const getPagination = async data => {
  const res = await http.get(
    `${BASE_URL}/inventory/?search=${data.searchValue}&page=${data.currentPage}&page_size=${data.rowPerPage}&site=${data.site}&sublocation=${data.sublocation}&category=${data.filterCategory}`,
  );
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};

const InventoryService = {
  getPagination,
  getInventoryByAuthentication,
  getInventoryById,
  getInventoryHistoryById,
  createInventory,
  deleteInventory,
  updateInventory,
  exportInventory,
  importInventory,
  downloadTemplate,
  getInventoryServiceById,
  updateInventoryQuantity,
  getInventoryAdjustmentHistoryById
};

export default InventoryService;
