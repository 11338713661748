/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable  no-unused-expressions */

import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import classes from "./assetForm.module.css"
import AddModal from '../../../../components/modals/AddModal';
import Card from "../../../../Ui/Card";
import { getPagination, setSearchValue } from '../../../../redux/features/inventory/inventorySlice';

function AssetSparePartsForm({ showModal,
  setShowModal,
  handleCreateSave, deleteSpare, spareTable, handelInventory, sparePartErr, sparePart }) {

  const inventorys = useSelector(state => state.inventory?.inventorys?.results)
  const { searchValue } = useSelector(state => state.inventory)

  const handleClose = () => {
    setShowModal(false)
  }

  const dispatch = useDispatch()
  const handelSearch = (e) => {
    dispatch(setSearchValue(e.target.value))
    const searchValues = {
      site: "",
      sublocation: "",
      filterCategory: "",
      rowPerPage: 10, 
      currentPage: 1, 
      searchValue: e.target.value
    }
    dispatch(getPagination(searchValues))
  }

  return (
    <div className={classes.assetForm}>
      <Card>
        <div className={classes.assetInfo}>
          <div className={classes.titleAssetDocuments}>
            <h3>Spare Parts</h3>
            <button onClick={() => setShowModal(true)}
              className='primary-btn btn' type='button'>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
          <div className={classes.scrollX}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <td>#</td>
                  <td>Part Name</td>
                  <td>Quatity	</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {spareTable?.map(s => (
                  <tr key={s.id}>
                    <td>{s?.inventory_id}</td>
                    <td>{s?.name}</td>
                    <td>{s?.total}</td>
                    <td ><button onClick={() => deleteSpare(s.id)} type='button' className='tertiary-btn'>
                      <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card>

      <AddModal modalTitle="Associate Parts"
        showModal={showModal}
        handleClose={handleClose}
        handleCreateSave={handleCreateSave}
        modelToDo="Associate">
        <div><input
          value={searchValue}
          type="text"
          id="location-list-popup-search"
          placeholder="Search"
          className="search-bar mb-3 w-100"
          onChange={handelSearch}
        /></div>
        {sparePartErr && <div> Choose item First </div>}
        <ul>
          {inventorys?.map(inv => {
            const sparePartClass = sparePart?.length > 0 && sparePart?.map(s => {
              if (s.id === inv.id) {
                return s.id
              }
            })
            let styleItem;
            sparePartClass?.length > 0 && sparePartClass?.forEach(i => {
              if (i === inv.id) {
                styleItem = i
              }
            })
            return  <li style={styleItem === inv.id ? { backgroundColor: "#ff99a8" } : {}}
              onClick={() => handelInventory(inv)} className={classes.spareItem} key={inv?.id}>
              {String(inv?.inventory_id)} - {inv?.name}</li> 
          })}
        </ul>
        {inventorys?.length ===  0 && <h4>No Results Found</h4>}
      </AddModal>

    </div>
  )
}

export default AssetSparePartsForm