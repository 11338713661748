import React  from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus , faTrashCan } from '@fortawesome/free-solid-svg-icons';
import AddModal from '../../../../components/modals/AddModal';
import classes from "./assetForm.module.css"
/* eslint-disable react/no-array-index-key */

function AssetPhotosForm({photosTable ,handelChangePhotosFormFile , handleCreateSavePhoto , showModal,
  setShowModal , handelChangePrimary , isprimary , deletePhoto}) {
  const handleClose = ()=>{
    setShowModal(false)
  }

  return (
    <div className={classes.assetForm}>
        <div className={classes.assetInfo}>
      <div className={classes.titleAssetDocuments}>
      <h3>Photos</h3>
      <button onClick={()=> setShowModal(true)} className='primary-btn btn' type='button'>
      <FontAwesomeIcon icon={faPlus} />
      </button>
      </div>
      <div className={classes.scrollX}>
      <table className={classes.table}>
        <thead>
          <tr>
            <td>Image</td>
            <td>File Name</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {photosTable?.map((ph , idx) =>(
            <tr key={idx}> 
            <td><img 
            style={{width : "100px", height : "80px" , objectFit: "cover"}} 
            src={ph?.photo} alt="" />
            </td>
            <td>{ph.assetPhotosFormFile?.name}	</td>
            <td >  < button type='button' onClick={()=> deletePhoto(idx)} className='tertiary-btn'>
              <FontAwesomeIcon icon={faTrashCan} />
            </button> 
            </td>
          </tr>
          ))}
        </tbody>
      </table>
      </div>
      </div> 
      <AddModal modalTitle = "Add Image"
              showModal= {showModal}
              handleClose ={handleClose}
              handleCreateSave ={handleCreateSavePhoto}
              modelToDo = "Upload">
      <div style={{marginBottom: 20}}>
      <p style={{marginBottom: 10}}>Select Image (Max upload size is 5MB)</p>
      <input type="file" accept="image/*" style={{maxWidth: "100%"}} onChange={handelChangePhotosFormFile}  />
        </div>  
        <div>
          <input checked= {isprimary} onChange={handelChangePrimary} style={{marginRight: 10}} type="checkbox" />
      <span>Default Image for Asset</span>
        </div>  
      </AddModal>
    </div>
  )
}

export default AssetPhotosForm