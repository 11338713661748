import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import activityService  from './ticketActivityService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // Ticket Activity
 activities :[],
 activity :{},


  // Activity fields
  description: "",
  descEdit : "",
  
  

  // success states
  isTicketActivityGetSuccess: false,
  isTicketActivityCreateSuccess: false,
  isTicketActivityDeleteSuccess: false,
  isTicketActivityUpdateSuccess: false,
 

  // loading states
  isTicketActivityCreateLoading: false,
  isTicketActivityGetLoading: false,
  isTicketActivityDeleteLoading: false,
  isTicketActivityUpdateLoading: false,


  // error states
  isTicketActivityGetError: false,
  isTicketActivityCreateError: false,
  isTicketActivityDeleteError: false,
  isTicketActivityUpdateError: false,


};

export const createActivity = createAsyncThunk('createActivity', async (data, thunkAPI) => {
  const createActivityRes = await smartTryCatch(activityService.createActivity, data, thunkAPI);
  return createActivityRes;
});

export const getActivitiesByAuthentication = createAsyncThunk(
  'getActivitiesByAuthentication',
  async (data, thunkAPI) => {
    const getActivitys = await smartTryCatch(
      activityService.getActivitiesByAuthentication,
      data,
      thunkAPI,
    );
    return getActivitys;
  },
);

export const getActivityById = createAsyncThunk('getActivityById', async (data, thunkAPI) => {
  const getActivity = await smartTryCatch(activityService.getActivityById, data, thunkAPI);
  return getActivity;
});


export const deleteActivity = createAsyncThunk('deleteActivity', async (data, thunkAPI) => {
  const deleteActivityRes = await smartTryCatch(activityService.deleteActivity, data, thunkAPI);
  return deleteActivityRes;
});

export const updateActivity = createAsyncThunk('updateActivity', async (data, thunkAPI) => {
  const updateActivityRes = await smartTryCatch(activityService.updateActivity, data, thunkAPI);
  return updateActivityRes;
});


export const activitySlice = createSlice({
  name: 'activity',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isActivityCreateError = false;
      state.isActivityCreateSuccess = false;
      state.isActivityCreateLoading = false;
      state.isActivityGetError = false;
      state.isActivityGetSuccess = false;
      state.isActivityGetLoading = false;
      state.isActivityDeleteError = false;
      state.isActivityDeleteSuccess = false;
      state.isActivityDeleteLoading = false;
      state.isActivityUpdateError = false;
      state.isActivityUpdateSuccess = false;
      state.isActivityUpdateLoading = false;
      // state.Activity = null;
    },
   
    setDescription : (state, action) => {
      state.description = action.payload;
    },
    setDescEdit : (state, action) => {
      state.descEdit = action.payload;
    },
    resetActivity: (state) => {
      state.description = ""
      state.descEdit = ""
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createActivity.pending, (state) => {
        state.isActivityCreateLoading = true;
        state.isActivityCreateSuccess = false;
        state.isActivityCreateError = false;
      })
      .addCase(createActivity.fulfilled, (state, action) => {
        state.isActivityCreateLoading = false;
        state.isActivityCreateSuccess = true;
        state.isActivityCreateError = false;
        state.activities = [action.payload , ...state.activities];
      })
      .addCase(createActivity.rejected, (state) => {
        state.isActivityCreateLoading = false;
        state.isActivityCreateSuccess = false;
        state.isActivityCreateError = true;
      })
      .addCase(getActivitiesByAuthentication.pending, (state) => {
        state.isActivityGetLoading = true;
        state.isActivityGetSuccess = false;
        state.isActivityGetError = false;
      })
      .addCase(getActivitiesByAuthentication.fulfilled, (state, action) => {
        state.isActivityGetLoading = false;
        state.isActivityGetSuccess = true;
        state.isActivityGetError = false;
        state.activities = action.payload;
      })
      .addCase(getActivitiesByAuthentication.rejected, (state) => {
        state.isActivityGetLoading = false;
        state.isActivityGetSuccess = false;
        state.isActivityGetError = true;
      })
      .addCase(getActivityById.pending, (state) => {
        state.isActivityGetLoading = true;
        state.isActivityGetSuccess = false;
        state.isActivityGetError = false;
      })
      .addCase(getActivityById.fulfilled, (state, action) => {
        state.isActivityGetLoading = false;
        state.isActivityGetSuccess = true;
        state.isActivityGetError = false;
        state.Activity = action.payload;
      })
      .addCase(getActivityById.rejected, (state) => {
        state.isActivityGetLoading = false;
        state.isActivityGetSuccess = false;
        state.isActivityGetError = true;
      })
      .addCase(deleteActivity.pending, (state) => {
        state.isActivityDeleteLoading = true;
        state.isActivityDeleteSuccess = false;
        state.isActivityDeleteError = false;
      })
      .addCase(deleteActivity.fulfilled, (state, action) => {
        state.isActivityDeleteLoading = false;
        state.isActivityDeleteSuccess = true;
        state.isActivityDeleteError = false;
        state.activities = state.activities.filter((activity) => activity.id !== action.payload);
      })
      .addCase(deleteActivity.rejected, (state) => {
        state.isActivityDeleteLoading = false;
        state.isActivityDeleteSuccess = false;
        state.isActivityDeleteError = true;
      })
      .addCase(updateActivity.pending, (state) => {
        state.isActivityUpdateLoading = true;
        state.isActivityUpdateSuccess = false;
        state.isActivityUpdateError = false;
      })
      .addCase(updateActivity.fulfilled, (state, action) => {
        state.isActivityUpdateLoading = false;
        state.isActivityUpdateSuccess = true;
        state.isActivityUpdateError = false;
        state.activities = state.activities?.map((activity) =>
        activity.id === action.payload.id ? action.payload : activity,
        );
      })
      .addCase(updateActivity.rejected, (state) => {
        state.isActivityUpdateLoading = false;
        state.isActivityUpdateSuccess = false;
        state.isActivityUpdateError = true;
      })
  
},
});

export const {
  setDescription,
  resetActivity,
  setDescEdit
} = activitySlice.actions;

export default activitySlice.reducer;
