import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getActivitiesByAuthentication = async (id) => {
  const res = await http.get(`${BASE_URL}/ticket-activity/?ticket_id=${id}`);

  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};


const getActivityById = async (ActivityId) => {
  const res = await http.get(`${BASE_URL}/ticket-activity/${ActivityId}/`);

  return assert(res, res.data, 'Retrieval of Activity by its ID failed', res);
};

const createActivity = async (data) => {
  const res = await http.post(`${BASE_URL}/ticket-activity/?ticket_id=${data.id}`
  , data.comment);

  return assert(res, res.data, 'Creation of Activity failed', res);
};

const deleteActivity = async (ActivityId) => {
  const res = await http.delete(`${BASE_URL}/ticket-activity/${ActivityId}/`);
  return assert(res, ActivityId, 'Deletion of Activity failed', res);
};

const updateActivity = async (ActivityData) => {
  const res = await http.put(
    `${BASE_URL}/ticket-activity/${ActivityData.id}/`,
    ActivityData,
  );
  return assert(res, res.data, 'Update of Activity failed', res);
};

const activityService = {
  getActivitiesByAuthentication,
  getActivityById,
  createActivity,
  deleteActivity,
  updateActivity,
};

export default activityService;
