import React, { useEffect } from 'react'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getMaintenacePlannerBoard, setMaintenancePlannerBoardData, setMaintenancePlannerBoardDataOrigin } from '../../../redux/features/dashboard/dashboardSlice'
import BoardColumn from './BoardColumn';
import PMBoardFilter from './filters/PMBoardFilter';
import Loader from '../../../components/loader/loader';

const isMantenance = true

function MaintenancePlannerBoard({
    handleSelectedEvent,
    getFreQ,
    selectedMenu,
}) {
    const dispatch = useDispatch()
    const {
        isEventsGetLoading,
        isEventUpdateLoading,
        maintenancePlannerBoardData,
        maintenancePlannerBoardDataOrigin,
        maintenanceBoardFrequency,
        maintenanceBoardSite,
        maintenanceBoardSublocation,
        maintenanceBoardStaff,
        maintenanceDateFilterState
    } = useSelector((state) => state.dashboard);

    const getQueryString = (data, filterName) => {
        return data
            .filter(feature => feature.selected) // Only keep selected items
            .map(feature => `${filterName}=${feature.value}`)
            .join('&');
    }

    const getMaintenacePlannerBoardData = async () => {
        const filters = {
            frequency: getQueryString(maintenanceBoardFrequency, "frequency"),
            site: maintenanceBoardSite?.value || null,
            sublocation: maintenanceBoardSublocation?.value || null,
            staff: maintenanceBoardStaff?.value || null,
            startDate: maintenanceDateFilterState?.find(item => item.startDate)?.startDate ?
                moment(maintenanceDateFilterState?.find(item => item.startDate)?.startDate).format("YYYY-MM-DD") :
                moment.utc(new Date()).startOf("isoWeek").format("YYYY-MM-DD"),
            endDate: maintenanceDateFilterState?.find(item => item.startDate)?.startDate ?
                moment(maintenanceDateFilterState?.find(item => item.endDate)?.endDate).format("YYYY-MM-DD") :
                moment.utc(new Date()).endOf("isoWeek").format("YYYY-MM-DD"),
        }
        await dispatch(getMaintenacePlannerBoard(filters))
    }
    useEffect(() => {
        getMaintenacePlannerBoardData()
    }, [])

    useEffect(() => {
        dispatch(setMaintenancePlannerBoardData(
            [
                { title: "Open", dotColor: "#4472B8", data: maintenancePlannerBoardDataOrigin?.filter(ev => ev.status === "Open") },
                { title: "In progress", dotColor: "#e3871e", data: maintenancePlannerBoardDataOrigin?.filter(ev => ev.status === "In-Progress") },
                { title: "Complete", dotColor: "#20BB7D", data: maintenancePlannerBoardDataOrigin?.filter(ev => ev.status === "Completed") },
                { title: "On hold", dotColor: "#D9314A", data: maintenancePlannerBoardDataOrigin?.filter(ev => ev.status === "On-Hold") }
            ]
        ))
    }, [maintenancePlannerBoardDataOrigin])


    const { workOrder } = useSelector((state) => state.workOrder);

    useEffect(() => {
        if (workOrder?.id) {
            dispatch(
                setMaintenancePlannerBoardDataOrigin(
                    maintenancePlannerBoardDataOrigin?.map((item) =>
                        item.id === workOrder?.id && item?.ticket_type === 'pm' ? workOrder : item,
                    ),
                ),
            );
        }
    }, [workOrder]);

    return (
        (isEventsGetLoading || isEventUpdateLoading) ? (
            <Loader />
        ) :
            <>
                <PMBoardFilter selectedMenu={selectedMenu}
                    getMaintenacePlannerBoardData={getMaintenacePlannerBoardData}
                />
                <div
                    style={{
                        height: 'calc(100vh - 220px)',
                        padding: '16px',
                        display: 'flex',
                        gap: '16px',
                    }}
                >
                    {maintenancePlannerBoardData?.map(task =>
                        <BoardColumn title={task?.title} dotColor={task?.dotColor} data={task?.data} handleSelectedEvent={handleSelectedEvent} getFreQ={getFreQ} isMantenance={isMantenance} />
                    )}
                </div>
            </>
    )
}

export default MaintenancePlannerBoard