import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import assetCategoryService from './assetCategoryService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // assetCategory
  assetCategories: [],
  assetCategory: {},

  // assetCategory fields
  assetCategoryName: '',

  // modal
  assetCategoryModalCreate: false,
  assetCategoryModalDelete: false,
  assetCategoryModalUpdate: false,

  // success
  isAssetCategoryGetSuccess: false,
  isAssetCategoryCreateSuccess: false,
  isAssetCategoryDeleteSuccess: false,
  isAssetCategoryUpdateSuccess: false,

  // loading
  isAssetCategoryGetLoading: false,
  isAssetCategoryDeleteLoading: false,
  isAssetCategoryUpdateLoading: false,
  isAssetCategoryCreateLoading: false,

  // error
  isAssetCategoryGetError: false,
  isAssetCategoryCreateError: false,
  isAssetCategoryDeleteError: false,
  isAssetCategoryUpdateError: false,
};

export const getAssetCategoriesByAuthentication = createAsyncThunk(
  'getAssetCategoriesByAuthentication',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      assetCategoryService.getAssetCategoriesByAuthentication,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const getAssetCategoryById = createAsyncThunk(
  'getAssetCategoryById',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(assetCategoryService.getAssetCategoryById, data, thunkAPI);
    return res;
  },
);

export const createAssetCategory = createAsyncThunk(
  'createAssetCategory',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(assetCategoryService.createAssetCategory, data, thunkAPI);
    return res;
  },
);

export const deleteAssetCategory = createAsyncThunk(
  'deleteAssetCategory',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(assetCategoryService.deleteAssetCategory, data, thunkAPI);
    return res;
  },
);

export const updateAssetCategory = createAsyncThunk(
  'updateAssetCategory',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(assetCategoryService.updateAssetCategory, data, thunkAPI);
    return res;
  },
);

export const assetCategorySlice = createSlice({
  name: 'assetCategory',
  initialState: initState,
  reducers: {
    setAssetCategory: (state, action) => {
      state.assetCategory = action.payload;
    },
    openAssetCategoryModalDelete: state => {
      state.assetCategoryModalDelete = true;
    },
    closeAssetCategoryModalDelete: state => {
      state.assetCategoryModalDelete = false;
    },
    openAssetCategoryModalUpdate: state => {
      state.assetCategoryModalUpdate = true;
    },
    closeAssetCategoryModalUpdate: state => {
      state.assetCategoryModalUpdate = false;
    },
    openAssetCategoryModalCreate: state => {
      state.assetCategoryModalCreate = true;
    },
    closeAssetCategoryModalCreate: state => {
      state.assetCategoryModalCreate = false;
    },
    setAssetCategoryName: (state, action) => {
      state.assetCategoryName = action.payload;
    },
    reset: state => {
      state.isAssetCategoryCreateError = false;
      state.isAssetCategoryCreateSuccess = false;
      state.isAssetCategoryCreateLoading = false;
      state.isAssetCategoryGetError = false;
      state.isAssetCategoryGetSuccess = false;
      state.isAssetCategoryGetLoading = false;
      state.isAssetCategoryDeleteError = false;
      state.isAssetCategoryDeleteSuccess = false;
      state.isAssetCategoryDeleteLoading = false;
      state.isAssetCategoryUpdateError = false;
      state.isAssetCategoryUpdateSuccess = false;
      state.isAssetCategoryUpdateLoading = false;
      state.assetCategoryModalCreate = false;
      state.assetCategoryModalDelete = false;
      state.assetCategoryModalUpdate = false;
      // state.assetCategory = {};
      // state.assetCategoryName = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAssetCategoriesByAuthentication.pending, state => {
        state.isAssetCategoryGetLoading = true;
        state.isAssetCategoryGetSuccess = false;
        state.isAssetCategoryGetError = false;
      })
      .addCase(getAssetCategoriesByAuthentication.fulfilled, (state, action) => {
        state.isAssetCategoryGetLoading = false;
        state.isAssetCategoryGetSuccess = true;
        state.isAssetCategoryGetError = false;
        state.assetCategories = action.payload;
      })
      .addCase(getAssetCategoriesByAuthentication.rejected, state => {
        state.isAssetCategoryGetLoading = false;
        state.isAssetCategoryGetSuccess = false;
        state.isAssetCategoryGetError = true;
      })
      .addCase(getAssetCategoryById.pending, state => {
        state.isAssetCategoryGetLoading = true;
        state.isAssetCategoryGetSuccess = false;
        state.isAssetCategoryGetError = false;
      })
      .addCase(getAssetCategoryById.fulfilled, (state, action) => {
        state.isAssetCategoryGetLoading = false;
        state.isAssetCategoryGetSuccess = true;
        state.isAssetCategoryGetError = false;
        state.assetCategories = action.payload;
      })
      .addCase(getAssetCategoryById.rejected, state => {
        state.isAssetCategoryGetLoading = false;
        state.isAssetCategoryGetSuccess = false;
        state.isAssetCategoryGetError = true;
      })
      .addCase(createAssetCategory.pending, state => {
        state.isAssetCategoryCreateLoading = true;
        state.isAssetCategoryCreateSuccess = false;
        state.isAssetCategoryCreateError = false;
      })
      .addCase(createAssetCategory.fulfilled, (state, action) => {
        state.isAssetCategoryCreateLoading = false;
        state.isAssetCategoryCreateSuccess = true;
        state.isAssetCategoryCreateError = false;
        state.assetCategories.push(action.payload);
      })
      .addCase(createAssetCategory.rejected, state => {
        state.isAssetCategoryCreateLoading = false;
        state.isAssetCategoryCreateSuccess = false;
        state.isAssetCategoryCreateError = true;
      })
      .addCase(deleteAssetCategory.pending, state => {
        state.isAssetCategoryDeleteLoading = true;
        state.isAssetCategoryDeleteSuccess = false;
        state.isAssetCategoryDeleteError = false;
      })
      .addCase(deleteAssetCategory.fulfilled, (state, action) => {
        state.isAssetCategoryDeleteLoading = false;
        state.isAssetCategoryDeleteSuccess = true;
        state.isAssetCategoryDeleteError = false;
        state.assetCategories = state.assetCategories.filter(
          assetCategory => assetCategory.id !== action.payload,
        );
      })
      .addCase(deleteAssetCategory.rejected, state => {
        state.isAssetCategoryDeleteLoading = false;
        state.isAssetCategoryDeleteSuccess = false;
        state.isAssetCategoryDeleteError = true;
      })
      .addCase(updateAssetCategory.pending, state => {
        state.isAssetCategoryUpdateLoading = true;
        state.isAssetCategoryUpdateSuccess = false;
        state.isAssetCategoryUpdateError = false;
      })
      .addCase(updateAssetCategory.fulfilled, (state, action) => {
        state.isAssetCategoryUpdateLoading = false;
        state.isAssetCategoryUpdateSuccess = true;
        state.isAssetCategoryUpdateError = false;
        state.assetCategories = state.assetCategories.map(assetCategory =>
          assetCategory.id === action.payload.id ? action.payload : assetCategory,
        );
      })
      .addCase(updateAssetCategory.rejected, state => {
        state.isAssetCategoryUpdateLoading = false;
        state.isAssetCategoryUpdateSuccess = false;
        state.isAssetCategoryUpdateError = true;
      });
  },
});

export const {
  setAssetCategory,
  openAssetCategoryModalDelete,
  closeAssetCategoryModalDelete,
  openAssetCategoryModalUpdate,
  closeAssetCategoryModalUpdate,
  openAssetCategoryModalCreate,
  closeAssetCategoryModalCreate,
  reset,
} = assetCategorySlice.actions;

export default assetCategorySlice.reducer;
