/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-target-blank */

import React, { useState, useRef, useEffect } from 'react';
// import { BlobProvider } from '@react-pdf/renderer';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
// import { useReactToPrint } from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import AddSiteDrawer from './AddSiteLocationDrawer';
import {
  openSubLocationModalUpdate,
  setsubLocation,
  deleteSubLocation,
  closeSubLocationModalUpdate,
  openAddFloor,
  // openLocationModalUpdate,
  deleteLocation,
  getPagination,
  setSearchValue,
  closeLocationModalUpdate,
  closeSiteModeul,
  closeSubLocationModalDelete,
  openLocationModalDelete,
  closeLocationModalDelete,
  reset,
} from '../../redux/features/location/locationSlice';
import UpdateSiteDrawer from './updateSiteDrawer';
import AddFloor from './addFloor';
import UpdateLocation from './updateLocation';
import Pagination from '../pagination/pagination';
import './locationstyle.scss';
import SubLocationQrPrint from './subLocationQrPrint';
// import classes from '../addAsset/addAsset.module.css';
import classes from '../../pages/assetsPage/addAsset/addAsset.module.css';
import PopUpDeleteModal from '../popUpModals/editCategory/popUpDeleteModal';
import { setCurrentPage, setRowPerPage } from '../../redux/features/subLocation/subLocationSlice';
// import LocationModal from './LocationModal';

export default function SiteLocation ({ show, close, setSearchBar, openUpdateLocation }) {
  const [isShowList, setIsShowList] = useState(false);
  const [isLinkCopied, setLinkCopied] = useState(false);
  const [locationURl, setLocationURL] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [openAddDrawer, setAddDrawer] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowPerPage, setRowPerPage] = useState(10);
  const { profile } = useSelector(state => state.company);
  const { user } = useSelector(state => state.user);
  const {
    sublocations,
    location,
    showFloorAdd,
    showLocationModalUpdate,
    showSiteModule,
    searchValue,
    showLocationModalDelete,
    isLocationDeleteSuccess
  } = useSelector(state => state.location);
const {
  rowPerPage,
  currentPage
} = useSelector(state => state.sublocation);

  const dispatch = useDispatch();

  const handleClose = () => setModalShow(false);
  const handleShow = () => {
    setIsShowList(!isShowList);
    setModalShow(true);
  };

  function changecursor (e) {
    e.target.style.cursor = 'pointer';
  }

  const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  //  Pagination
  const subLocationCount = sublocations?.count; // Because we are not geeting data count frombackend
  const sublocationsData = sublocations?.results;
  useEffect(() => {
    if (searchValue !== '') {
      setCurrentPage(1);
    }
  }, [searchValue]);
  // const subLocationIds = subLocationArray.map((subLocation) => subLocation.id);
  const locationID = location?.id;

  useEffect(() => {
    // Get the main URL (base URL)
    const mainUrl = window.location.origin;

    // Do something with the main URL
    setLocationURL(`${mainUrl}/site-service/?uuid=${location?.uuid}/`);
  }, [location]);

  useEffect(() => {
    const getDataRows = async () => {
      if (locationID) {
        await dispatch(getPagination({ locationID, rowPerPage, currentPage, searchValue }));
      }
    };
    getDataRows();
  }, [locationID, rowPerPage, currentPage, searchValue]);

  const handelChange = e => {
    dispatch(setSearchValue(e.target.value));
  };


  // Delete 
  const handelOpenDeletePopup = () => {
    dispatch(openLocationModalDelete())
    setIsShowList(!isShowList);
  };

  const handleDelete = () => {
    dispatch(deleteLocation(location.id));
    dispatch(setSearchBar(true))
    close();  
      toast.success('Location Deleted Successfully', {
        toastId: 'DeletedLocationError',
      });
      dispatch(reset())
  }


  let deleteSiteBtn = (
    <span
      onClick={handelOpenDeletePopup}
      style={{
        color: '#f63854',
      }}
      // type="button"
      title="Delete Location"
      // className="btn tertiary-btn ml-2"
      data-target="#modal-delete-location"
      data-toggle="modal"
    >
      Delete
      {/* <i className="fa fa-trash"> </i> */}
    </span>
  );
  let EditSiteBtn = (
    <span
      // className="btn tertiary-btn ml-2 "
      // onClick={() => handleShow()}
      onClick={() => {
        setIsShowList(!isShowList)
        close()
        openUpdateLocation()
      }}
      title="Edit Location"
      // type="button"
      style={{
        color: 'blue',
      }}
    >
      Edit
      {/* <div className="fas fa-pen"> </div> */}
    </span>
  );

  if (
    user?.role === 'Technical Team' ||
    (user?.role === 'Manager' && location?.created_by?.role !== 'Manager')
  ) {
    deleteSiteBtn = '';
    EditSiteBtn = '';
  }
  const handleLinkCopy = async () => {
    await navigator.clipboard.writeText(locationURl);
    setLinkCopied(true);
  };
  function displayToast () {
    if (isLinkCopied) {
      toast.success('Copied', {
        toastId: 'DeletedWorkOrderError',
      });
      setLinkCopied(false);
    }
  }
  return (
    <>
      {displayToast()}
      <AddSiteDrawer openSiteDrawr={openAddDrawer} closeSiteDrawer={() => setAddDrawer(false)} />

      <UpdateSiteDrawer />

      {/* show site ifno  */}
      {showFloorAdd && !showSiteModule && <AddFloor id={location?.id} />}

      {showLocationModalUpdate && (
        <div
          className="card m-4 p-2 card-header-background none"
          id="site-details"
          style={{ visibility: show ? '' : 'hidden' }}
        >
          <UpdateLocation />
        </div>
      )}

      {location?.id !== undefined && !showLocationModalUpdate && (
        <div
          className="card m-4 p-2 card-header-background none"
          id="site-details"
          style={{ visibility: show ? '' : 'hidden' }}
        >
          {/* view add floor */}

          {/* <div style={{ display: "none" }}><QRPRint ref={printRef} /></div> */}
          {/* <!-- view site details --> */}
          <div>
            <UpdateLocation modalShow={modalShow} handleClose={handleClose} />
            <button
              className="btn tertiary-btn ml-2 "
              onClick={() => {
                dispatch(setSearchBar(true));
                dispatch(closeSiteModeul());
                dispatch(closeLocationModalUpdate());
              }}
              title="Location table"
              type="button"
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          </div>

          <div
            className={
              showFloorAdd || showLocationModalUpdate
                ? 'card-header col-12 '
                : 'card-header col-12 '
            }
            id="site-view"
            style={{ paddingBottom: '1.25rem' }}
          >
            <input type="hidden" id="acitive_tab" value="{{form_type}}" />
            <div className="card-title col-lg-9 col-md-9 col-sm-12 row">
              <div>
                <img style={{ width: '90px' }} src={location?.qr_code} alt="location qr_code" />
                <button
                  style={{ marginRight: '20px' }}
                  className="btn tertiary-btn"
                  type="button"
                  onClick={handleLinkCopy}
                >
                  Copy link
                </button>
              </div>
              <br />
              <div className="card-title my-2 mr-2">
                <b className="red">
                  {location?.site}
                  <br />
                </b>
                {(location?.street || location?.district) && (
                  <span>
                    {location?.street && `${location?.street}${location?.district ? ',' : ''}`}
                    {location?.district && ` ${location?.district}`}
                  </span>
                )}
                {(location?.street || location?.district) && <br />}
                {(location?.city ||
                  location?.state ||
                  location?.country ||
                  location?.postal_code) && (
                  <span>
                    {location?.city &&
                      `${location?.city}${
                        location?.state || location?.country || location?.postal_code ? ',' : ''
                      } `}
                    {location?.state &&
                      `${location?.state}${location?.country || location?.postal_code ? ',' : ''} `}
                    {location?.postal_code &&
                      `${location?.postal_code}${location?.country ? ',' : ''} `}
                    {location?.country && `${location?.country}`}
                  </span>
                )}
                {(location?.city ||
                  location?.state ||
                  location?.country ||
                  location?.postal_code) && <br />}

                {location?.management && (
                  <>
                    {location?.management.owner && (
                      <span>
                        <b>Owner: </b>
                        <span>{location?.management.owner}</span>
                      </span>
                    )}
                    {location?.management.owner && <br />}
                    <span>
                      <b>Tenant: </b>
                      <span>{location?.management.tenant || ''}</span>
                    </span>
                    {location?.management.tenant && <br />}
                    {location?.management?.category && (
                      <span>
                        <b>Category: </b>
                        <span>{location?.management.category}</span>
                      </span>
                    )}
                    {location?.management?.category && <br />}
                    {location?.management?.description && (
                      <span>
                        <b>Description: </b>
                        <span>{location?.management.description || ''}</span>
                      </span>
                    )}
                    {location?.management?.description && <br />}
                    <span>
                      <b>Tenancy Agreement: </b>
                      <span>
                        {location?.management?.start_rent_date?.split('T')[0] || '-'}{' '}
                        {location?.management?.start_rent_date ? 'to' : ''}{' '}
                        {location?.management?.end_rent_date?.split('T')[0] || ''}
                      </span>
                    </span>
                  </>
                )}
              </div>
              <h3 className="card-title mt-2 "> </h3>
            </div>

            <div className="float-right row">
              <div>
                <button
                  className="btn tertiary-btn ml-2 mr-5"
                  onClick={() => {
                    dispatch(openAddFloor());
                    close();
                  }}
                  title="Add/Edit Floor Plan"
                  type="button"
                >
                  <span>Floor plan </span>
                  <div className="fas fa-plus"> </div>
                </button>
              </div>
              <div
                className={classes.addAssetActions}
                style={{
                  marginRight: '30px',
                }}
              >
                <button
                  onClick={() => setIsShowList(!isShowList)}
                  className={classes.optionsOfAsset}
                  type="button"
                >
                  <FontAwesomeIcon icon={faEllipsis} />
                </button>
                {isShowList && (
                  <div className="tck-overlay" onClick={() => setIsShowList(!isShowList)} />
                )}
                {isShowList && (
                  <ul
                    className={classes.listOptions}
                    style={{
                      left: '5px',
                    }}
                  >
                    <li>{EditSiteBtn}</li>
                    <li>{deleteSiteBtn}</li>
                    <li><SubLocationQrPrint location={location} company={profile?.logo} /></li>
                    {/* {%endif%} */}
                    {/* <BlobProvider
                      document={<SubLocationQrPrint location={location} company={profile?.logo} />}
                    >
                      {({ url, loading }) =>
                        loading ? (
                          'Loading...'
                        ) : (
                          <li>
                            <a
                              href={url}
                              style={{ textDecoration: 'none', color: 'black' }}
                              target="_blank"
                            >
                              QR
                            </a>
                          </li>
                        )
                      }
                    </BlobProvider> */}
                  </ul>
                )}
              </div>

              {/* <button
                type="button"
                className="btn tertiary-btn ml-2 black"
                title="Print QR Code"
                id="PrintLocation"
                onClick={handlePrint}
              >
                <div className="fa fa-print"> </div>
              </button> */}
            </div>
            <div className="card-body" style={{ paddingBottom: '1.25rem' }} ref={componentRef}>
              <div className="text-center p-1" id="printThisLocation">
                <center>
                  <img
                    className="qr-print"
                    src={location.qr_code}
                    id="location-qr-url"
                    width="144"
                    height="144"
                    alt=""
                  />
                  <div className="qr-print"> {location?.site}</div>
                  <br />
                </center>
              </div>
            </div>
          </div>

          {/* <!-- site locations table --> */}
          <div
            className="mt-3 btn-group2"
            id="site-locations"
            style={{ borderRadius: '0.5em', display: 'block' }}
          >
            <div
              className="card-header"
              style={{ backgroundColor: '#F8F8F8', borderRadius: '10px' }}
            >
              <input type="hidden" id="acitive_tab" value="{{form_type}}" />
              <h4 className="card-title mt-2 gcolor">
                <b>Sublocation</b>
              </h4>

              <div className="float-right">
                {/* {% if request.user.role == 'CliAdm'  %} */}
                <button
                  title="Add Sublocation"
                  className="btn primary-btn ml-2 open-modal"
                  onClick={() => setAddDrawer(true)}
                  type="button"
                >
                  <div className="fas fa-plus"> </div>
                </button>
                {/* {% endif %} */}
              </div>

              {/* <!--  Search box --> */}
              <input
                type="text"
                id="location-search"
                placeholder="Search"
                className="rgfloat search-bar p-1 float-right"
                value={searchValue}
                onChange={handelChange}
              />
            </div>
            <div
              className="card-body pt-0 table-responsive text-nowrap "
              style={{ backgroundColor: '#F8F8F8', borderRadius: '10px', paddingBottom: '1.25rem' }}
            >
              <table id="location-list" className="table  table-togglable w-100  table-borderless ">
                <thead style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                  <tr>
                    <th style={{width: "25%"}} className="toggleDisplayTitle">
                      Block
                      <br />
                    </th>
                    <th style={{width: "25%"}} className="toggleDisplayBlock">Level</th>
                    <th style={{width: "25%"}} className="toggleDisplayLevel">Suite</th>
                    <th style={{width: "25%"}} className="toggleDisplaySuite">Room</th>
                  </tr>
                </thead>
                <tbody id="location_block">
                  {sublocationsData?.length > 0 ? (
                    sublocationsData.map(e => (
                      <tr
                        onMouseOver={changecursor}
                        onFocus={() => {}}
                        onClick={element => {
                          if (element.target.id !== 'bin_icon_hide') {
                            dispatch(openSubLocationModalUpdate());
                            dispatch(setsubLocation(e));
                          } else dispatch(deleteSubLocation(e.id));
                        }}
                      >
                        <td
                          className="toggleDisplayTitle open-modal"
                          id="site-location-list-display-{{loc.id}}"
                          data-popup-url="/location-view-add/?location_id={{loc.id}}"
                          data-href="/location-view-add/?location_id={{loc.id}}"
                          title="View Location Detail"
                          style={{ fontWeight: 'bold' }}
                        >
                          {e.building !== '' ? e.building : `---`}
                        </td>

                        <td
                          className="toggleDisplayBlock open-modal"
                          id="site-location-list-display-{{loc.id}}"
                          data-popup-url="/location-view-add/?location_id={{loc.id}}"
                          data-href="/location-view-add/?location_id={{loc.id}}"
                          title="View Location Detail"
                        >
                          {e.level !== '' ? e.level : `---`}
                        </td>
                        <td
                          className="toggleDisplayLevel  open-modal"
                          id="site-location-list-display-{{loc.id}}"
                          data-popup-url="/location-view-add/?location_id={{loc.id}}"
                          data-href="/location-view-add/?location_id={{loc.id}}"
                          title="View Location Detail"
                        >
                          {e.suite !== '' ? e.suite : `---`}
                        </td>
                        <td
                          className="toggleDisplaySuite open-modal"
                          id="site-location-list-display-{{loc.id}}"
                          data-popup-url="/location-view-add/?location_id={{loc.id}}"
                          data-href="/location-view-add/?location_id={{loc.id}}"
                          title="View Location Detail"
                        >
                          {e?.room}
                        </td>
                        {/* <td
                    className="toggleDisplayLabel open-modal"
                    id="site-location-list-display-{{loc.id}}"
                    data-popup-url="/location-view-add/?location_id={{loc.id}}"
                    data-href="/location-view-add/?location_id={{loc.id}}"
                    title="View Location Detail"
                  >
                    <div
                      className="text-center p-1 mr-1 my-1"
                      title="Floorplan"
                      style={{
                        padding: '40px',
                        width: '110px',
                        color: '#232b2b',
                        backgroundColor: '#c4c3d0',
                        borderRadius: '15px',
                      }}
                    >
                      Floor plan
                    </div>
                  </td> */}
                        {/* <td className="toggleDisplayFloorPlan " id="bin_icon_hide">
                        <a
                          className="btn secondary-btn cursor_pointer float-right"
                          onClick=""
                          href="/plan?location={{loc.id}}"
                          data-toggle=""
                          data-target=""
                          title="View Floorplan"
                        >
                          <div className="fa fa-building "> </div> Floor Plan{' '}
                        </a>
                      </td> */}

                        {/* <td className="toggleDisplayBin" id="bin_icon_hide">
                          <button
                            id="bin_icon_hide"
                            className="btn tertiary-btn cursor_pointer "
                            // href="/"
                            onClick={() => {
                              dispatch(closeSubLocationModalUpdate());
                              // dispatch(deleteSubLocation(e.id));
                            }}
                            data-toggle="modal"
                            data-target="#modal-default"
                            title="Delete Location"
                            type="button"
                          >
                            {' '}
                            <div className="fa fa-trash" id="bin_icon_hide">
                              {' '}
                            </div>{' '}
                          </button>
                        </td> */}
                      </tr>
                    ))
                  ) : (
                    <td colSpan="6">No Results Found</td>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              totalRows={subLocationCount}
              rowPerPage={rowPerPage}
              pageChangeHandler={setCurrentPage}
              changeRowsPerPage={setRowPerPage}
              currentPage={currentPage}
            />
        {showLocationModalDelete && (
        <PopUpDeleteModal
          showModal={showLocationModalDelete}
          handleClose={() => dispatch(closeLocationModalDelete())}
          handleConfirm={handleDelete}
          modalTitle="Delete location"
          modalBodyText="Are you sure you want to delete this location?"
        />
      )}
          </div>
        </div>
      )}
    </>
  );
}
