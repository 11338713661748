import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import tnbLogo from '../../assets/img/tnbLogo.png';
import WorkDataPdf from './workDataPdf';
import BillsItems from './billsItems';
import Surcharge from './surcharge';
import Penalty from './penalty';
import Summary from './epqSummry';
import BackWordArrowIcon from '../../assets/svg-icons/BackWordArrowIcon';


const EbqPdf = forwardRef((props, ref) => {
    const {billWorkData}=  useSelector(state => state.SOR)
    return (
        <div className="card-body" style={{ display: props.isPreview ? "" : "none", paddingTop: "20px" }}
            ref={ref}>
            <div className='epq-pdf-header'>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {props.isPreview && <Link to="/ebq">
                        <button type='button' className='new-btn'><BackWordArrowIcon /></button>
                    </Link>}
                    <h3 className='ml-2 mb-0'>Bill of quantity</h3>
                </div>
                <img src={tnbLogo} alt="TNB logo" />
            </div>
            <WorkDataPdf />
            <hr />
            <div className='epq-large-header-text'>
                <h3>{billWorkData?.title}</h3>
                <p>{billWorkData?.description}</p>
            </div>
            <BillsItems pdf={props.pdf} />
            <hr />
            <Surcharge pdf={props.pdf} />
            <hr />
            <Penalty pdf={props.pdf} />
            <hr />
            <Summary pdf={props.pdf} />
            <hr />
            <div style={props.pdf ? { breakInside: 'avoid' } : {}} className='epq-signature'>
                <div className="epq-signature-item">
                    <p>Provided by :</p>
                    <div>
                        <p>Name & Signature</p>
                        <p>IFM Technician</p>
                        <p>Date:</p>
                    </div>
                </div>
                <div className="epq-signature-item">
                    <p>Approved by :</p>
                    <div>
                        <p>Name & Signature</p>
                        <p>Contractor Representative</p>
                        <p>Date:</p>
                    </div>
                </div>
                <div className="epq-signature-item">
                    <p>Certified by :</p>
                    <div>
                        <p>Name & Signature</p>
                        <p>IFM Executive</p>
                        <p>Date:</p>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default EbqPdf