import React, { useState } from 'react'
import TooltipIcon from '../../assets/svg-icons/TooltipIcon';
import "./tooltip.css"

function Tooltip({ content, color, input }) {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div
                className="custom-tooltip-icon"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                {input ?
                    <input type="number" disabled value="0" className='bill-item-discount' /> : 
                    <TooltipIcon color={color} />}
                {showTooltip && (
                    <div className={input ? "custom-tooltip-box bill-disabled-input": "custom-tooltip-box" }>
                        {content}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Tooltip