/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable  array-callback-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBase, Modal } from '@material-ui/core';
import {
  setStaff,
} from '../../../redux/features/maintenance/maintenanceSlice';
import {
  SelectStaff,
} from '../../../components/select/select';
import { setFilterAdvanceStatus } from '../../../redux/features/workOrder/workOrderSlice';

function PMAdvanceFilter({
  openAdvFilter,
  handleCloseAdvFilter,
  getDataRows,
}) {
  const buttonDisabledStyle = {
    padding: '8px 16px',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    backgroundColor: '#D9314A',
    borderRadius: '8px',
  };

  const buttonStyle = {
    padding: '8px 16px',
    fontSize: '14px',
    fontWeight: '600',
    color: '#36383C',
    backgroundColor: '#F8F9F9',
    border: '1px solid #E1E1E1',
    borderRadius: '8px',
  };

  const dispatch = useDispatch();

  const {
    staff,
    filterStatus,
  } = useSelector((state) => state.maintenance);
  const { teams } = useSelector((state) => state.teams);

  const resetState = () => {
    dispatch(setStaff(null));
  };

  const handleEraserFilterClick = () => {
    // reset the state and store
    resetState();
  };

  const handleActiveAdvFilter = async () => {
    handleCloseAdvFilter();
    await getDataRows();
  };

  const { filterAdvanceStatus } = useSelector((state) => state.workOrder);
  const handelStatusChange = (item) => {
    const updatedStatus = filterAdvanceStatus.map((ele) => {
      if (ele.label === item.label) {
        return { ...ele, selected: !item.selected };
      }
      return ele;
    });
    dispatch(setFilterAdvanceStatus(updatedStatus));
  };

  return (
    <Modal
      open={openAdvFilter}
      onClose={handleCloseAdvFilter}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '480px',
          padding: '12px 16px',
          backgroundColor: '#F8F9F9',
          borderRadius: '8px',
        }}
      >
        <div
          style={{
            fontWeight: '600',
            fontSize: '21px',
            marginBottom: '20px',
          }}
        >
          Advance filter
        </div>
        <div
          className="wo-filter-wrapper"
          style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginBottom: '8px' }}
        >
          <div className="filter-wrapper">
            {/* According to figma, This should be created by, not assigned to. but idk what's right tho */}
            <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>
              Assigned to{' '}
            </label>
            <SelectStaff staff={staff} setStaff={setStaff} teams={teams?.results} />{' '}
          </div>
        </div>
        <div>
          <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Status</label>
          <div
            style={{
              marginBottom: '16px',
              width: '100%',
              display: 'flex',
              gap: '16px',
              flexWrap: 'wrap',
            }}
          >
            {filterAdvanceStatus.map((item) => (
              <div className="filter-wrapper-status">
                <label className="weekly-frequency-switch black-switch">
                  <input
                    type="checkbox"
                    onChange={() => handelStatusChange(item)}
                    checked={item.selected}
                    disabled={
                      filterStatus?.label === 'Pending' || filterStatus?.label === 'Declined'
                    }
                  />
                  <span className="weekly-frequency-slider" />
                </label>
                <span className="weekly-frequency-label">{item.label}</span>
              </div>
            ))}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <ButtonBase onClick={handleEraserFilterClick} autoCapitalize="none" style={buttonStyle}>
            Reset
          </ButtonBase>
          <div style={{ display: 'flex', gap: '12px' }}>
            <ButtonBase onClick={handleCloseAdvFilter} style={buttonStyle}>
              Close
            </ButtonBase>
            <ButtonBase onClick={handleActiveAdvFilter} style={buttonDisabledStyle}>
              Activate
            </ButtonBase>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PMAdvanceFilter;
