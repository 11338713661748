/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnDown, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import trachIcoN from "../../../assets/img/trashIcon.png"
import plusIcon from "../../../assets/img/plusIcon.png"
import {
  setAssignedTo,
  setCategory,
  setPriority,
  setTab,
  setTitle,
  setDescription,
  setFrequencyNumber,
  setFrequency,
  setStartedDate,
  setEndDate,
  createMaintenance,
  addAttachment,
  setMaintenaceType,
  setTicketType,
} from '../../../redux/features/maintenance/maintenanceSlice';
import {
  SelectAsset,
  SelectLocation,
  SelectNewAsset,
  SelectNewLocation,
  SelectNewSite,
  SelectPersons,
  SelectRepetitionAndPriority,
  SelectSite,
} from '../../../components/select/select';
import { getMaintenanceTypeByAuthentication } from '../../../redux/features/maintenanceType/maintenanceTypeSlice';
import { getPagination } from '../../../redux/features/checklist/checklistSlice';
import FileIcon from '../../../assets/svg-icons/FileIcon';
import AttachmentIcon from '../../../assets/svg-icons/AttachmentIcon';
import MinusIcon from '../../../assets/svg-icons/MinusIcon';
import PlusIcon from '../../../assets/svg-icons/PlusIcon';
import Card from '../../../Ui/Card';
import DueOnDayDropdown from './DueOnDayDropdown';
import WeeklyTicketSwitches from './WeeklyTicketSwitches';
import DueOnDateDropdown from './DueOnDateDropdown';
import DeleteIcon from '../../../assets/svg-icons/DeleteIcon';
import Attachments from './Attachments';
import Schedule from './Schedule';

function PreviewTask({ item }) {
  return <div className='task-preview-container'>
    <div className='adjust-group p-0 mb-0'>
      <div className='group-name'>
        {item.name}
      </div>
      <div className='checklist-select'>
        <select disabled>
          <option selected value="Status" >Status</option>
          <option value="Done">Done</option>
          <option value="Not Done">Not Done</option>
          <option value="Fail">Fail</option>
          <option value="Pass">Pass</option>
        </select>
      </div>
      <div className='cursor-pointer ml-4 icon-preview'>
        <FileIcon gray />
      </div>
      <div className='cursor-pointer ml-4 icon-preview'>
        <AttachmentIcon gray />
      </div>
    </div>
  </div>
}

function ChecklistGroupPreview({ data }) {
  return <div>
    <div style={{ margin: "15px 0" }}>
      <h5>{data?.title}</h5>
    </div>
    {data?.items?.map(item => {
      return <PreviewTask key={item?.id} data={data} item={item} />
    })}
  </div>
}

function ChecklistSearching({ checklistSearch, setchecklistSearch, handelChecklistSearchClick, checklistSearchBox, setchecklistSearchBox, ranges, handelEnterChecklist }) {
  const [filteredChecklist, setFilteredChecklist] = useState("")
  const checklists = useSelector((state) => state?.checklists?.checklists?.results);
  useEffect(() => {
    const rangesIds = ranges?.map(item => item?.title) || []
    setFilteredChecklist(checklists.filter(item => rangesIds?.includes(item?.title) === false))
  }, [checklists])

  const dispatch = useDispatch()
  const handelChecklistSearch = async (e) => {
    setchecklistSearchBox(true)
    const { value } = e.target
    setchecklistSearch(value)
    await dispatch(
      getPagination({ rowPerPage: 10, currentPage: 1, searchValue: value }),
    );
    if (!value) {
      setchecklistSearchBox(false)
    }
  }
  const handleEnter = (e) => {
    if (e.key === 'Enter' && checklistSearch) {
      handelEnterChecklist(checklistSearch);
      setchecklistSearch("")
    }
  }

  const handelOpenbox = () => {
    setchecklistSearchBox(!checklistSearchBox)
  }

  return <div className='Checklist-Searching'>
    <div className="Checklist-Searching-box">
      <input type="text" placeholder='Search or add new checklist'
        value={checklistSearch}
        onChange={handelChecklistSearch}
        onKeyPress={handleEnter}
      />
      <span onClick={handelOpenbox}>
        {checklistSearchBox ? <span style={{ fontSize: "28px", color: "#f63854" }}>-</span> : <img src={plusIcon} alt="plusIcon" />}
      </span>
    </div>
    {checklistSearchBox && <div className='tck-overlay' onClick={() => setchecklistSearchBox(!checklistSearchBox)} />}
    {checklistSearchBox && <div className='Checklist-Searching-results'>
      {filteredChecklist?.length > 0 ? filteredChecklist?.map(checklist => {
        return <div className='Checklist-Searching-results-result'
          onClick={() => handelChecklistSearchClick(checklist)}>
          <h6 >{checklist?.title}</h6>
          <span>{checklist?.items_count} Tasks</span>
        </div>
      }) : <div style={{ textAlign: "center" }}>Press enter to create checklist</div>}
    </div>}
  </div>
}


function SelectStatusType({ setStatusType, handelStatusClick }) {
  const [isShowTypes, setIsShowTypes] = useState(false)
  return <div className='Status-Type'>
    <div className="Status-Type-box"
      onClick={() => setIsShowTypes(!isShowTypes)}
    >
      <p className='mb-0'>Status type</p>
      <span className={isShowTypes ? "rotateIcon" : ''}
      >
        <FontAwesomeIcon icon={faAngleDown} />
      </span>
    </div>
    {isShowTypes && <div className='tck-overlay' onClick={() => setIsShowTypes(!isShowTypes)} />}
    {isShowTypes && <div className='Status-Type-results'>
      <div className='Status-Type-results-result' onClick={() => {
        setStatusType("typeA")
        setIsShowTypes(false)
        handelStatusClick("typeA")
      }}>
        <h6>Status type A</h6>
        <span>Options (Done/Not done/Pending/On hold)</span>
      </div>
      <div className='Status-Type-results-result mt-2' onClick={(e) => {
        setStatusType("typeB")
        setIsShowTypes(false)
        handelStatusClick("typeB")
      }}>
        <h6>Status type B</h6>
        <span>Options (Passed/Failed/Pending/On hold)</span>
      </div>
    </div>
    }
  </div>
}

function TaskEdit({ handelDeleteTask, index, i, item, handelTaskChange }) {
  const [taskValue, setTaskValue] = useState(item?.name)
  return <div className='tasks-wraper-task'>
    <div className='task-name'>
      <input type="text" value={taskValue}
        onChange={(e) => setTaskValue(e.target.value)}
        placeholder='Task'
        onKeyPress={(e) => handelTaskChange(e, index, i, taskValue)}
      />
    </div>
    <div className='ml-4'>
      <div
        style={{ cursor: "pointer" }}
        id="add_check_list_trash"
        onClick={() => handelDeleteTask(index, i)}
        title="Delete Task">
        <img style={{ width: "15px" }}
          src={trachIcoN} alt="trachIcon" />
        <div id="add_check_list_trash"> </div>
      </div>
    </div>
  </div>
}

function ChecklistGroupEdit({ data, handleSelectRange, ranges, setRanges, index }) {
  const dispatch = useDispatch()
  const [taskName, setTaskName] = useState("")
  const [statusType, setStatusType] = useState("")


  const handleAddTask = (e, indexo) => {
    const { value } = e.target
    if (e.key === 'Enter' && value) {
      const putData = ranges.map((range, i) => {
        if (i === indexo) {
          return { ...range, items: [...range.items, { name: value, type: statusType || "typeA" }] }
        }
        return range
      })
      setRanges(putData)
      setTaskName("")
    }
    setStatusType("")
  }

  const handelStatusClick = (status, indexo) => {
    if (taskName) {
      const putData = ranges.map((range, i) => {
        if (i === indexo) {
          return { ...range, items: [...range.items, { name: taskName, type: status || "typeA" }] }
        }
        return range
      })
      setRanges(putData)
      setTaskName("")
    } else {
      toast.warning('Please fill Task Name field and try again', {
        toastId: 'CreatedEventWarning',
      });
    }
  }

  const handelDeleteTask = (checklistIndex, itemIndex) => {
    setRanges(ranges.map((item, cIndex) => {
      if (cIndex === checklistIndex) {
        const items = item.items.filter((f, fIndex) => fIndex !== itemIndex)
        return { ...item, items }
      }
      return item
    }))
  }


  const handelTaskChange = (e, itemIndex, taskItem, taskValue) => {

    if (e.key === "Enter" && taskValue) {
      const putData = ranges.map((range, i) => {
        if (i === itemIndex) {
          const items = range?.items.map((item, j) => {
            if (j === taskItem) {
              return { ...item, name: taskValue }
            }
            return item
          })
          return { ...range, items }
        }
        return range
      })
      setRanges(putData)
    }
  }


  return <div>
    <div className="checkbox-wrapper-title mt-2 mb-2">
      <div className='flex'>
        <input
          type="checkbox"
          checked={data?.selected}
          onChange={() => handleSelectRange(data.id)}
        />
        <h6 className=''>{data?.title}</h6>
      </div>
      <button className="new-btn"
        style={{ borderRadius: "5px", padding: "5px" }}
        type='button'
        onClick={() =>
          setRanges(ranges.filter((_, rin) => rin !== index))
        }
      >
        <img style={{ width: "15px" }}
          src={trachIcoN} alt="trachIcon" />
      </button>
    </div>
    <div className='tasks-wraper'>
      {data?.items?.map((item, i) => {
        return <TaskEdit
          data={data}
          i={i}
          index={index}
          item={item}
          handleAddTask={handleAddTask}
          handelDeleteTask={handelDeleteTask}
          handelTaskChange={handelTaskChange}
        />
      })}
      <div className="add-task-input">
        <input
          type="text"
          placeholder="Add additional tasks"
          onKeyPress={(e) => handleAddTask(e, index)}
          onChange={(e) => setTaskName(e.target.value)}
          value={taskName}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <SelectStatusType
            setStatusType={setStatusType}
            handelStatusClick={(type) => handelStatusClick(type, index)}
          /> */}
          <span> <FontAwesomeIcon icon={faArrowTurnDown} /></span>
        </div>
      </div>
    </div>
  </div>
}


function AssetComponent({
  item,
  index,
  onUpdateAsset,
  onDeleteAsset,
  assetsList
}) {
  const [assetIds, setAssetIds] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [openAsset, setOpenAsset] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const assetListSearch = useSelector(state => state.asset?.assetListSearch);
  const handleAssetSelection = (selectedAssetObj) => {
    // When an asset is selected, update the asset in the list
    onUpdateAsset(index,
      {
        chosenAsset: { id: selectedAssetObj.id, selected: selectedAssetObj.selected },
        chosenLocation: selectedAssetObj.asset?.location,
        chosenSubLocation: selectedAssetObj.asset?.sub_location
      });
  };

  const handleDelete = () => {
    onDeleteAsset(index); // Call the delete handler passed from the parent
  };

  useEffect(() => {
    setAssetIds(assetsList.map(asset => asset?.chosenAsset?.id))
  }, [assetsList])

  return <div className='card-add-new'>
    <div className='heading-of-card-add-new'>
      <h6> {item.chosenAsset ? item.chosenAsset.selected : `Asset #${index + 1}`} </h6>
      <button type='button' onClick={handleDelete}><DeleteIcon /></button>
    </div>
    <div className='row'>
      <div className="col-lg-12 col-sm-12 pt-2">
        <div id="ifAsset" className="col-12 p-0">
          <label className="font-weight-normal">Choose assets</label>
          <SelectNewAsset
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            openAsset={openAsset}
            setOpenAsset={setOpenAsset}
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
            width="100%"
            // setAssignedTo={setAssignedTo}
            assetData={assetListSearch}
            onSelectAsset={handleAssetSelection}
            assetValue={item.chosenAsset?.selected}
            assetIds={assetIds}
          />
        </div>
      </div>
      <div className="col-lg-12 col-sm-12 pt-1">
        <hr />
      </div>
      <div className="col-lg-6 col-sm-6 pt-1">
        Location <span className='ml-3'>{item?.chosenLocation?.site ? item?.chosenLocation?.site : "-"}</span>
      </div>
      <div className="col-lg-6 col-sm-6 pt-1">
        Sub-Location <span className='ml-3'>{item?.chosenSubLocation?.room ? item?.chosenSubLocation?.room : "-"}</span>
      </div>
    </div>
  </div>
}

function LocationComponent({  item, index, onUpdateLocation, onDeleteLocation, onUpdateSubLocation }) {
  const { locations, sublocations } = useSelector(state => state.location);
  const [searchSiteValue, setSearchSiteValue] = useState('');
  const [openSite, setOpenSite] = useState(false);
  const [searchSubLocationValue, setSearchSubLocationValue] = useState('');
  const [openLocation, setOpenLocation] = useState(false);
  const handleLocationSelection = (selectedLocationObj) => {
    // When an Location is selected, update the Location in the list
    onUpdateLocation(index,
      {
        chosenLocation: { id: selectedLocationObj.id, selected: selectedLocationObj.site },
      });
  };

  const handleSubLocationSelection = (selectedSubLocationObj) => {
    // When an Location is selected, update the Location in the list
    onUpdateSubLocation(index,
      {
        chosenSubLocation: { id: selectedSubLocationObj.id, selected: selectedSubLocationObj.selected }
      });
  };

  const handleDelete = () => {
    onDeleteLocation(index); // Call the delete handler passed from the parent
  };




  return <div className='card-add-new'>
    <div className='heading-of-card-add-new'>
      <h6> {item.chosenLocation ? item.chosenLocation.selected : `Location #${index + 1}`} </h6>
      <button type='button' onClick={handleDelete}><DeleteIcon /></button>
    </div>
    <div className='row'>
      <div className="col-lg-12 col-xs-12 pt-3">
        <div id="ifLocation" className="col-12 p-0">
          <SelectNewSite locations={locations} width="100%" noStar onSelectSite={handleLocationSelection}
            searchSiteValue={searchSiteValue}
            setSearchSiteValue={setSearchSiteValue}
            openSite={openSite}
            setOpenSite={setOpenSite}
            locationValue={item?.chosenLocation?.selected}
          />
        </div>
      </div>
      <div className="col-lg-12 col-xs-12 pt-3">
        <div id="ifLocation" className="col-12 p-0">
          <SelectNewLocation
            subLocation={sublocations?.results}
            width="100%"
            noStar
            idOfSite={item?.chosenLocation?.id}
            onSelectSubLocation={handleSubLocationSelection}
            searchSubLocationValue={searchSubLocationValue}
            setSearchSubLocationValue={setSearchSubLocationValue}
            openLocation={openLocation}
            setOpenLocation={setOpenLocation}
            subLocationValue={item?.chosenSubLocation?.selected}
          />
        </div>
      </div>
    </div>
  </div>
}


function AddPMModal({ openModalCreate, closeModalCreate, checklistData }) {

  const {
    ticketType,
    title,
    description,
    priority,
    category,
    start_date,
    end_date,
    assigned_to,
    frequency_number,
    frequency,
    attachments,
    isMaintenanceCreateLoading,
    maintenanceType,
  } = useSelector(state => state.maintenance);

  const [assetsList, setAssetsList] = useState([])
  const [locationsList, setLocationsList] = useState([])
  const [searchValue, setSearchValue] = useState('');
  const [openAsset, setOpenAsset] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [searchSiteValue, setSearchSiteValue] = useState('');
  const [openSite, setOpenSite] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const [searchSubLocationValue, setSearchSubLocationValue] = useState('');
  const [openLocation, setOpenLocation] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const hadelAddNewData = () => {
    if (ticketType === "assetCheck") {
      if (assetsList.every(item => item.chosenAsset)) {
        setAssetsList([
          { chosenAsset: null, chosenLocation: null, chosenSubLocation: null }, ...assetsList
        ])
      } else {
        toast.warning('Please fill all assets first', {
          toastId: 'CreatedlocationsWarning',
        });
      }
    } if (ticketType === "locationCheck") {
      if (locationsList.every(item => item.chosenLocation)) {
        setLocationsList([
          { chosenLocation: null, chosenSubLocation: null }, ...locationsList 
        ])
      } else {
        toast.warning('Please fill all locations first', {
          toastId: 'CreatedlocationsWarning',
        });
      }
    }
  }

  const handleUpdateAsset = (index, updatedData) => {
    const updatedAssetsList = [...assetsList];
    updatedAssetsList[index] = {
      ...updatedAssetsList[index],
      ...updatedData
    };
    setAssetsList(updatedAssetsList);
  };

  const handleUpdateLocation = (index, updatedData) => {
    const updatedLocationsList = [...locationsList];
    updatedLocationsList[index] = {
      ...updatedLocationsList[index],
      ...updatedData
    };
    setLocationsList(updatedLocationsList);
  };

  const handleUpdateSubLocation = (index, updatedData) => {
    const updatedLocationsList = [...locationsList];
    updatedLocationsList[index] = {
      ...updatedLocationsList[index],
      ...updatedData
    };
    setLocationsList(updatedLocationsList);
  };

  // Function to delete an item from the list based on index
  const handleDeleteAsset = (index) => {
    const updatedAssetsList = assetsList.filter((_, i) => i !== index);
    setAssetsList(updatedAssetsList);
  };
  // Function to delete an item from the list based on index
  const handleDeleteLocation = (index) => {
    const updatedLocationsList = locationsList.filter((_, i) => i !== index);
    setLocationsList(updatedLocationsList);
  };

  const priorityData = [
    { value: "Low", label: "Low" },
    { value: "Medium", label: "Medium" },
    { value: "High", label: "High" }
  ]



  const dispatch = useDispatch();

  const handelTabs = e => {
    dispatch(setTab(e.target.id));
  };

  const handelTicketType = e => {
    dispatch(setTicketType(e.target.id));
  };
  const handelTitle = e => {
    dispatch(setTitle(e.target.value));
  };
  const handelPriority = e => {
    dispatch(setPriority(e.target.value));
  };
  const handelCategory = e => {
    dispatch(setCategory(e.target.value));
  };
  const handelType = e => {
    dispatch(setMaintenaceType(e.target.value));
  };

  const handelDescription = e => {
    dispatch(setDescription(e.target.value));
  };

  // Schedule

  const [selectedDays, setSelectedDays] = useState(
    [
      { day: "Monday", isChoosen: false },
      { day: "Tuesday", isChoosen: false },
      { day: "Wednesday", isChoosen: false },
      { day: "Thursday", isChoosen: false },
      { day: "Friday", isChoosen: false },
      { day: "Saturday", isChoosen: false },
      { day: "Sunday", isChoosen: false }
    ]
  );
  const [selectedDay, setSelectedDay] = useState(1);
  const [selectTheDay, setSelectTheDay] = useState(25);
  const [selectTheMonth, setSelectTheMonth] = useState(1);

  const toggleDay = (day) => {
    const toggledDay = selectedDays.map(item => {
      if (item.day === day) {
        return { ...item, isChoosen: !item.isChoosen }
      }
      return { ...item, isChoosen: false }
    })
    setSelectedDays(toggledDay)
  };


  const handleDayChange = (e) => {
    setSelectedDay(e.target.value);
  };
  const handleSelectDayChange = (e) => {
    setSelectTheDay(e.target.value);
  };
  const handleSelectMonthChange = (e) => {
    setSelectTheMonth(e.target.value)
  };

  const handelStartDate = e => {
    dispatch(setStartedDate(e.target.value));
  };

  const handelEndDate = e => {
    dispatch(setEndDate(e.target.value));
  };
  const handelInterval = e => {
    dispatch(setFrequencyNumber(e.target.value));
  };
  const handelFrequency = e => {
    dispatch(setFrequency(e.target.value));
  };

  const handelCounter = (type) => {
    if (type === "increase") {
      dispatch(setFrequencyNumber(+frequency_number + 1));
    } else {
      if (+frequency_number === 0) {
        return
      }
      dispatch(setFrequencyNumber(+frequency_number - 1));
    }
  }

  // Attachments

  const [files, setFiles] = useState([]);
  const handleAttachmentFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleAttachmentDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const renderFilePreview = (file, index) => {
    const fileType = file.type;
    // Check if the file is an image
    if (fileType.startsWith("image/")) {
      return (
        <div className="attachment-preview" key={index}>
          <img
            src={URL.createObjectURL(file)}
            alt={`Preview ${index}`}
            className="preview-image"
          />
          <button type="button" className="delete-btn" onClick={() => handleAttachmentDelete(index)}>
            <DeleteIcon color="white" />
          </button>
        </div>
      );
    }
    // For document files like pdf, doc, etc.
    return (
      <div className="attachment-preview" key={index}>
        <div className="doc-preview">
          <span role="img" aria-label="document">
            📄
          </span>
          <p>{file.name}</p>
        </div>
        <button type="button" className="delete-btn" onClick={() => handleAttachmentDelete(index)}>
          <DeleteIcon color="white" />
        </button>
      </div>
    );
  };

  const handelFile = e => {
    dispatch(addAttachment([{ file: e.target.files[0] }]));
  };

  const maintenanceCategory = useSelector(
    state => state.maintenanceCategory?.maintenanceCategories,
  );
  const maintenanceTypes = useSelector(
    state => state.maintenanceType?.maintenanceTypes,
  );
  const teams = useSelector(state => state.teams?.teams?.results);
  const { locations, sublocations } = useSelector(state => state.location);



  useEffect(() => {
    dispatch(getMaintenanceTypeByAuthentication())
  }, [])


  const [isEditing, setIsEditing] = useState(true)
  const [checklistSearch, setchecklistSearch] = useState("")
  const [ranges, setRanges] = useState([]);
  const [checklistSearchBox, setchecklistSearchBox] = useState(false)


  const handelChecklistSearchClick = async (checklist) => {
    const data = [...ranges, {
      title: checklist?.title,
      items: checklist?.items,
      is_template: false,
      selected: false
    }]

    setRanges(data)
    setchecklistSearchBox(false)
    setchecklistSearch("")

  }
  const handelEnterChecklist = async (checklistName) => {
    const data = [...ranges, {
      title: checklistName,
      items: [],
      is_template: false,
      selected: false
    }]
    setRanges(data)
    setchecklistSearchBox(false)
  }

  const handleSelectAll = (e) => {
    const isSelected = e.target.checked;
    setRanges(ranges.map(range => {
      return {
        ...range,
        selected: isSelected
      }
    }));
  };

  const handelDeleteBySelect = () => {
    const deleteIds = []
    ranges.forEach((range, index) => {
      if (range.selected) {
        deleteIds.push(index)
      }
    })
    setRanges(ranges.filter((_, index) => !deleteIds.includes(index)))
  };

  const handleSelectRange = (index) => {
    setRanges(ranges.map((range, i) =>
      i === index
        ? {
          ...range,
          selected: !range.selected,
        }
        : range
    ));
  };

  const handelSave = async e => {
    e.preventDefault();
    const formData = new FormData();
    if (title) {
      formData.append('title', title);
    }
    if (description) {
      formData.append('description', description);
    }
    if (priority) {
      formData.append('priority', priority);
    }
    if (category) {
      formData.append('category', category);
    }
    if (maintenanceType) {
      formData.append('cust_type', maintenanceType);
    }
    if (assigned_to?.length > 0) {
      assigned_to?.forEach(ele => formData.append('assigned_to', ele?.value));
    }

    formData.append(
      'schedule.start_date',
      moment.utc(start_date).format('YYYY-MM-DDTHH:mm:ss.SSZ'),
    );
    formData.append('schedule.end_date', moment.utc(end_date).format('YYYY-MM-DDTHH:mm:ss.SSZ'));
    formData.append('schedule.frequency_number', frequency_number);
    formData.append('schedule.frequency', frequency);

    if (frequency === "week") {
      formData.append('schedule.weekly_day', selectedDays.find(item => item.isChoosen).day);
    }
    if (frequency === "month") {
      formData.append('schedule.weekly_day', "");
      formData.append('schedule.day_of_month', selectedDay);
      formData.append('schedule.month_of_year', "");
    }
    if (frequency === "year") {
      formData.append('schedule.weekly_day', "");
      formData.append('schedule.day_of_month', selectTheDay);
      formData.append('schedule.month_of_year', selectTheMonth);
    }

    if (files?.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        if (files[i].type.includes('image')) {
          formData.append(`attachment[${i}][photo]`, files[i]);
        } else {
          formData.append(`attachment[${i}][file]`, files[i]);
        }
      }
    }

    if (ranges.length > 0) {
      for (let index = 0; index < ranges.length; index += 1) {
        const element = ranges[index];
        formData.append(`checklist[${index}].is_template`, false);
        formData.append(`checklist[${index}].title`, element?.title);
        if (element?.items?.length > 0) {
          for (let i = 0; i < element?.items?.length; i += 1) {
            formData.append(`checklist[${index}].items[${i}][name]`, element.items[i].name);
            formData.append(`checklist[${index}].items[${i}][type]`, element.items[i].type);
          }
        }
      }
    }

    formData.append('meta_type', ticketType === "assetCheck" ? "Asset" : "Location")

    if (ticketType === "assetCheck") {
      if (assetsList?.length > 0) {
        assetsList?.forEach(ele => {
          if (ele?.chosenAsset?.id) {
            formData.append('assets', ele?.chosenAsset.id)
          }
        });
      }
    } else if (ticketType === "locationCheck") {
      if (locationsList?.length > 0) {
        for (let index = 0; index < locationsList.length; index += 1) {
          const element = locationsList[index];
          formData.append(`locations[${index}].id`, element?.chosenLocation?.id);
          formData.append(`locations[${index}].sub_location_id`, element?.chosenSubLocation?.id || "");
        }
      }
    }

    if (
      title &&
      start_date &&
      end_date &&
      assigned_to.length > 0 &&
      category
    ) {
      await dispatch(createMaintenance(formData));
    } else {
      toast.warning('Please fill the required fields and submit again', {
        toastId: 'CreatedEventWarning',
      });
    }
  };

  const allSubLocations = [];
  const { tab } = useSelector(state => state.maintenance);

  return (
    <Drawer
      anchor="right"
      open={openModalCreate}
      onClose={closeModalCreate}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '600px',
          height: '100%',
        },
      }}
    >
      <div className="h-100 w-100">
        <div className="h-100">
          <div className="navbar sticky-top col-lg-12 m-0 modal-header-background pr-0">
            <div className="col-12 mb-2">
              <div className="row px-2">
                <div className="my-auto">
                  <b>Add maintenance schedule</b>
                </div>
                <button
                  onClick={closeModalCreate}
                  type="button"
                  className="btn secondary-btn ml-auto"
                  data-dismiss="modal"
                >
                  <div className="fas fa-times"></div>
                </button>
              </div>
            </div>

            <div className="row justify-content-start mt-auto col-lg-12 col-sm-12 ">
              <ul className="nav nav-pills" id="custom-tabs-two-tab" role="tablist">
                <li className="nav-item">
                  <span
                    onClick={handelTabs}
                    className={`nav-link ${tab === 'maintenance-tab' && 'active'} cursor-pointer`}
                    id="maintenance-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="custom-tabs-two-meter"
                    aria-selected="false"
                  >
                    Maintenance detail
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    onClick={handelTabs}
                    className={`nav-link ${tab === 'schedule-tab' && 'active'} cursor-pointer`}
                    id="schedule-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="custom-tabs-two-schedule"
                    aria-selected="false"
                  >
                    Schedule
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    onClick={handelTabs}
                    className={`nav-link ${tab === 'attachment-tab' && 'active'} cursor-pointer`}
                    id="attachment-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="custom-tabs-two-attachment"
                    aria-selected="false"
                  >
                    Attachments
                  </span>
                </li>
                {/* <li className="nav-item">
                  <span
                    onClick={handelTabs}
                    className={`nav-link ${tab === 'checklist-tab' && 'active'} cursor-pointer`}
                    id="checklist-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="custom-tabs-two-attachment"
                    aria-selected="false"
                  >
                    Checklist
                  </span>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="" style={{ minHeight: '78.5%' }}>
            <div className="tab-content col-lg-12 col-sm-12" id="custom-tabs-two-tabContent">
              {tab === 'maintenance-tab' && (
                <div
                  id="maintenance-tab"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-two-maintenance-tab"
                >
                  <div className="card-body">
                    <div className="row pb-5">
                      <div className=" col-lg-12 col-sm-12 row" style={{ justifyContent: "center" }}>
                        <div className='mr-5'>
                          <input
                            className="mr-2"
                            type="radio"
                            checked={ticketType === 'assetCheck'}
                            required
                            name="meter_type"
                            onClick={handelTicketType}
                            id="assetCheck"
                          />
                          <label htmlFor="assetCheck" className="font-weight-normal mb-0">By assets</label>
                        </div>
                        <div>
                          <input
                            className="mr-2"
                            checked={ticketType === 'locationCheck'}
                            type="radio"
                            name="meter_type"
                            onClick={handelTicketType}
                            id="locationCheck"
                          />
                          <label htmlFor="locationCheck" className="font-weight-normal mb-0">By location</label>
                        </div>
                      </div>
                      <div className="col-lg-12 pt-1">
                        <hr />
                      </div>
                      <div className="col-lg-12 col-sm-12 pt-1">
                        <label className="font-weight-normal">
                          Preventive maintenance title <label className="font-danger m-0">*</label>
                        </label>
                        <input
                          required
                          name="meter_name"
                          type="text"
                          className="form-control mb-2 rounded-md the-fixed-input"
                          placeholder="Add preventive maintenance title"
                          id="meter_name"
                          value={title}
                          onChange={handelTitle}
                        />
                        <div className="col-lg-12 col-sm-12 m-0">
                          <span className="font-danger" id="meter_error"></span>
                        </div>
                      </div>

                      <div className="col-lg-12 col-sm-12 pt-2">
                        <label className="font-weight-normal">Description</label>
                        <textarea
                          name="description"
                          className="form-control mb-2 the-fixed-input"
                          placeholder="Add some description"
                          id="description"
                          onChange={handelDescription}
                          value={description}
                          style={{ height: "100px" }}
                        ></textarea>
                      </div>
                      <div className="col-lg-12 col-sm-12 pt-2">
                        <label className="font-weight-normal">
                          Assigned to <label className="font-danger">*</label>
                        </label>
                        <div className="col-12 p-0">
                          <SelectPersons
                            teams={teams}
                            assignedTo={assigned_to}
                            setAssignedTo={setAssignedTo}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12 pt-3">
                        <label className="font-weight-normal mb-2">
                          Category <label className="font-danger m-0">*</label>
                        </label>
                        <select
                          className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md"
                          name="category"
                          id="category2"
                          onChange={handelCategory}
                          value={category}
                        >
                          <option value="" disabled selected>
                            Choose category
                          </option>
                          {maintenanceCategory &&
                            maintenanceCategory.length > 0 &&
                            maintenanceCategory.map(cat => (
                              <option value={cat.name} key={cat.id}>
                                {cat.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-lg-6 col-sm-12 pt-3">
                        <label className="font-weight-normal">
                          Sub Category
                        </label>
                        <select
                          className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md"
                          name="category"
                          id="category2"
                          onChange={handelType}
                          value={maintenanceType}
                        >
                          <option value="" disabled selected>
                            Choose sub-category
                          </option>
                          {maintenanceTypes &&
                            maintenanceTypes.length > 0 &&
                            maintenanceTypes.map(type => (
                              <option value={type.name} key={type.id}>
                                {type.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal">Set priority</label>
                        <div className='repetition'>
                          <SelectRepetitionAndPriority data={priorityData} setState={setPriority} state={priority} />
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12 pt-3  choose-assets-location">
                        <div> {ticketType === "assetCheck" ? `${assetsList.length} Assets` : `${locationsList.length} Locations`}  registered</div>
                        <button type='button'
                          onClick={hadelAddNewData}
                        >
                          <span className='mr-2'><PlusIcon color="#D9314A" /> </span> Add new
                        </button>
                      </div>
                      {ticketType === "assetCheck" ?
                        assetsList.length > 0 ?
                          assetsList.map((item, index) => <AssetComponent
                            key={index}
                            item={item}
                            index={index}
                            onUpdateAsset={handleUpdateAsset}
                            onDeleteAsset={handleDeleteAsset}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            openAsset={openAsset}
                            setOpenAsset={setOpenAsset}
                            selectedAsset={selectedAsset}
                            setSelectedAsset={setSelectedAsset}
                            assetsList={assetsList}
                          />
                          ) : <h6 className='mt-3 mx-auto text-align-center text-gray'>There is no asset(s) registered yet</h6>
                        :
                        locationsList.length > 0 ?
                          locationsList.map((item, index) => <LocationComponent
                            key={index}
                            item={item}
                            index={index}
                            onUpdateLocation={handleUpdateLocation}
                            onUpdateSubLocation={handleUpdateSubLocation}
                            onDeleteLocation={handleDeleteLocation}
                            searchSiteValue={searchSiteValue}
                            setSearchSiteValue={setSearchSiteValue}
                            openSite={openSite}
                            setOpenSite={setOpenSite}
                            selectedSite={selectedSite}
                            setSelectedSite={setSelectedSite}
                            searchSubLocationValue={searchSubLocationValue}
                            setSearchSubLocationValue={setSearchSubLocationValue}
                            openLocation={openLocation}
                            setOpenLocation={setOpenLocation}
                            selectedLocation={selectedLocation}
                            setSelectedLocation={setSelectedLocation}
                            locationsList={locationsList}
                          />
                          ) : <h6 className='mt-3 mx-auto text-align-center text-gray'>There is no location(s) registered yet</h6>
                      }
                    </div>
                  </div>
                </div>
              )}

              {tab === 'schedule-tab' && (
                <div
                  id="schedule-tab"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-two-schedule-tab"
                >
                  <div className="card-body ">
                    <Schedule
                      handelStartDate={handelStartDate}
                      handelEndDate={handelEndDate}
                      handelInterval={handelInterval}
                      toggleDay={toggleDay}
                      selectedDays={selectedDays}
                      selectedDay={selectedDay}
                      handleDayChange={handleDayChange}
                      selectTheDay={selectTheDay}
                      selectTheMonth={selectTheMonth}
                      handleSelectDayChange={handleSelectDayChange}
                      handleSelectMonthChange={handleSelectMonthChange}
                      handelCounter={handelCounter}
                    />
                  </div>
                </div>
              )}

              {tab === 'attachment-tab' && (
                <div
                  id="attachment-tab"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-two-attachment-tab"
                >
                  <div className="card-body">
                    <Attachments
                      files={files}
                      handleAttachmentFileChange={handleAttachmentFileChange}
                      renderFilePreview={renderFilePreview}
                    />
                  </div>
                </div>
              )}

              {tab === 'checklist-tab' && (
                <div id="checklist-tab-edit" role="tabpanel" aria-labelledby="custom-tabs-two-checklist-tab">
                  <div>
                    <div className="col-lg-12 col-sm-12 pb-5" id="">
                      <div className="card-box card-body mx-auto col-lg-12 pb-5" id="CL-view">
                        <div className='checklist-header'>
                          <div>
                            <div style={{ fontWeight: "600" }}> Checklist</div>
                            <p>{ranges?.length} Checklist issued</p>
                          </div>
                          <button type="button"
                            onClick={() => setIsEditing(!isEditing)}
                            className='new-btn btn'
                            style={{
                              color: "#f63854", borderRadius: "5px",
                              marginRight: "0px", fontWeight: "600",
                              fontSize: "14px"
                            }}
                          >
                            {isEditing ? "Preview" : "Edit Checklist"}
                          </button>
                        </div>
                        {isEditing ? <>
                          <div className='checklist-drop-down'>
                            <ChecklistSearching
                              handelChecklistSearchClick={handelChecklistSearchClick}
                              checklistSearchBox={checklistSearchBox}
                              setchecklistSearchBox={setchecklistSearchBox}
                              ranges={ranges}
                              handelEnterChecklist={handelEnterChecklist}
                              checklistSearch={checklistSearch}
                              setchecklistSearch={setchecklistSearch}
                            />
                            <div style={{ color: "#999999", marginTop: "5px", fontSize: "12px", fontStyle: "italic" }}>
                              *Enter a checklist name to create a new custom checklist
                            </div>
                          </div>
                          {ranges?.length !== 0 &&
                            <div className="select-all">
                              <div className="checkbox-wrapper-title">
                                <input
                                  type="checkbox"
                                  onChange={handleSelectAll}
                                  checked={ranges?.every(range => range?.selected) && ranges?.length !== 0}
                                />
                                <h6 className='mt-2'>Select all</h6>
                              </div>
                              <button className="new-btn"
                                style={{ borderRadius: "5px", padding: "5px" }}
                                type='button'
                                onClick={handelDeleteBySelect}
                              >
                                <img style={{ width: "15px" }}
                                  src={trachIcoN} alt="trachIcon" />
                              </button>
                            </div>
                          }
                          <div style={{ width: '100%', marginBottom: "20px", marginTop: "20px" }}>
                            {ranges && ranges.length > 0 ? ranges.map((data, index) => (
                              <ChecklistGroupEdit
                                key={index}
                                data={data}
                                handleSelectRange={() => handleSelectRange(index)}
                                ranges={ranges}
                                setRanges={setRanges}
                                index={index}
                              />
                            )) :
                              <div className='wrapper-no-checklist'>
                                <p className='no-checklist'>No checklist issued yet</p>
                              </div>
                            }
                          </div>
                        </>
                          :
                          <div style={{ width: '100%', marginBottom: "20px", marginTop: "20px" }}>
                            {ranges && ranges.length > 0 ? ranges.map((data) => (
                              <ChecklistGroupPreview key={data?.id} data={data} />
                            )) :
                              <div className='wrapper-no-checklist'>
                                <p className='no-checklist'>Nothing to preview</p>
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className=" m-0 p-2 page-profile-footer">
            <button onClick={handelSave} className="btn primary-btn" type="submit" disabled={isMaintenanceCreateLoading}>
              Save change
            </button>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default AddPMModal;
