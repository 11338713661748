

/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable  array-callback-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBase, Modal } from '@material-ui/core';
import { setFilterEndDate, setFilterStartDate, setFilterStatus, setPMFilterAsset, setPMFilterCategory, setPMFilterPrioirty, setPMFilterSite, setPMFilterSublocation, setPMFiltertype, setStaff } from '../../../redux/features/maintenance/maintenanceSlice';
import { SelectAssetFilter, SelectLocationFilter, SelectStaff, SelectSublocationFilter, SelectTypesAndCategories } from '../../../components/select/select';
import DateRangeFilter from './DateRangeFilter';


function PMAdvanceFilter(
    {
        openAdvFilter,
        handleCloseAdvFilter,
        getDataRows,
        dateFilterState,
        setDateFilterState
    }
) {
    const buttonDisabledStyle = {
        padding: '8px 16px',
        fontSize: '14px',
        fontWeight: '600',
        color: 'white',
        backgroundColor: '#D9314A',
        borderRadius: '8px',
    };

    const buttonStyle = {
        padding: '8px 16px',
        fontSize: '14px',
        fontWeight: '600',
        color: '#36383C',
        backgroundColor: '#F8F9F9',
        border: '1px solid #E1E1E1',
        borderRadius: '8px',
    };

    const dispatch = useDispatch();

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };

    const handleUpdate = async () => {
        setShow(false);
        dispatch(setFilterStartDate(dateFilterState?.find(item => item.startDate)?.startDate))
        dispatch(setFilterEndDate(dateFilterState?.find(item => item.endDate)?.endDate))
    };


    const
        {
            filterCategory,
            filtertype,
            filterSite,
            filterSublocation,
            staff,
            filterStatus,
            filterAsset,
            filterPrioirty,
            filterStartDate,
            filterEndDate,
            pmSchedule
        } = useSelector(state => state.maintenance)


    const [locationsData, setLocations] = useState([]);
    const { locations } = useSelector((state) => state.location);
    const { maintenanceCategories } = useSelector((state) => state.maintenanceCategory);
    const { maintenanceTypes } = useSelector((state) => state.maintenanceType);
    const sublocations = useSelector((state) => state.location?.sublocations?.results);
    const { teams } = useSelector((state) => state.teams);
    const { assetListSearch } = useSelector((state) => state.asset);


    useEffect(() => {
        setLocations(sublocations || [])
    }, [sublocations])

    const resetState = () => {
        dispatch(setPMFilterSite(null));
        dispatch(setPMFilterSublocation(null));
        dispatch(setPMFilterCategory(null));
        dispatch(setPMFiltertype(null));
        dispatch(setFilterStatus(null));
        dispatch(setStaff(null));
        dispatch(setPMFilterAsset(null))
        dispatch(setPMFilterPrioirty([
            { label: "High", selected: true },
            { label: "Medium", selected: true },
            { label: "Low", selected: true }
        ]))
        dispatch(setDateFilterState([
            {
                startDate: "",
                endDate: "",
                key: 'selection',
            },
        ]))
        dispatch(setFilterStartDate(""))
        dispatch(setFilterEndDate(""))
    }

    const handleEraserFilterClick = () => {
        setLocations([]);
        // reset the state and store
        resetState()
    };


    const handleActiveAdvFilter = async () => {
        handleCloseAdvFilter()
        await getDataRows()
    }


    const handelPrioirtyChange = (item) => {
        const updatedPriority = filterPrioirty.map(ele => {
            if (ele.label === item.label) {
                return { ...ele, selected: !item.selected }
            }
            return ele
        })
        dispatch(setPMFilterPrioirty(updatedPriority))
    }


    return (
        <Modal
            open={openAdvFilter}
            onClose={handleCloseAdvFilter}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    width: '480px',
                    padding: '12px 16px',
                    backgroundColor: '#F8F9F9',
                    borderRadius: '8px',
                }}
            >
                <div
                    style={{
                        fontWeight: '600',
                        fontSize: '21px',
                        marginBottom: '20px',
                    }}
                >
                    Advance filter
                </div>
                <div className='wo-filter-wrapper' style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <div className="filter-wrapper">
                        <div className='filters-left-wrapper-period'>
                            <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>{pmSchedule === "PM Schedule" ? "Period" : "Due Date"} </label>
                            <div className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                                style={{ fontSize: "14px", cursor: "pointer" }}
                                onClick={() => setShow(!show)}>
                                {filterStartDate ? <><span>{moment(filterStartDate).format("DD MMM YYYY")}</span> - <span>{moment(filterEndDate).format("DD MMM YYYY")}</span> </> : "All time"}
                            </div>
                            {
                                show && <>
                                    <div className="tck-overlay" onClick={() => setShow(!show)} />
                                    <DateRangeFilter
                                        show={show}
                                        handleUpdate={handleUpdate}
                                        handleClose={handleClose}
                                        state={dateFilterState}
                                        setState={setDateFilterState}
                                        advance
                                    />
                                </>
                            }
                        </div>
                    </div>
                    <div className="filter-wrapper">
                        <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Assigned to </label>
                        <SelectStaff staff={staff} setStaff={setStaff} teams={teams?.results} />
                    </div>
                    <div className="filter-wrapper">
                        <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Choose assets </label>
                        <SelectAssetFilter filterAsset={filterAsset} setFilterAsset={setPMFilterAsset} assets={assetListSearch} />
                    </div>
                    {/* todo: choose assets */}
                    <div>
                        <div className="filter-wrapper" style={{ width: '100%', display: 'flex', gap: '16px' }}>
                            <div style={{ flex: 1 }}>
                                <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Location</label>
                                <SelectLocationFilter locations={locations} site={filterSite} setFilterSite={setPMFilterSite}
                                    setFilterSublocation={setPMFilterSublocation} setLocations={setLocations} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Sub location</label>
                                <SelectSublocationFilter sublocationsData={locationsData} sublocation={filterSublocation} setFilterSublocation={setPMFilterSublocation} site={filterSite} />                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="filter-wrapper" style={{ width: '100%', display: 'flex', gap: '16px' }}>
                            <div style={{ flex: 1 }}>
                                <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Category</label>
                                <SelectTypesAndCategories dataArray={maintenanceCategories} stateValue={filterCategory} setState={setPMFilterCategory} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Sub Category</label>
                                <SelectTypesAndCategories dataArray={maintenanceTypes} stateValue={filtertype} setState={setPMFiltertype} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Prioirty</label>
                        <div style={{ width: '100%', display: 'flex', gap: '16px' }}>
                            {filterPrioirty.map(item => (
                                <div className='filter-wrapper-prioirty'>
                                    <label className="weekly-frequency-switch black-switch">
                                        <input type="checkbox"
                                            onChange={() => handelPrioirtyChange(item)}
                                            checked={item.selected}
                                        />
                                        <span className="weekly-frequency-slider" />
                                    </label>
                                    <span className="weekly-frequency-label">{item.label}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                    <ButtonBase onClick={handleEraserFilterClick} autoCapitalize="none" style={buttonStyle}>
                        Reset
                    </ButtonBase>
                    <div style={{ display: 'flex', gap: '12px' }}>
                        <ButtonBase onClick={handleCloseAdvFilter} style={buttonStyle}>
                            Close
                        </ButtonBase>
                        <ButtonBase onClick={handleActiveAdvFilter} style={buttonDisabledStyle}>
                            Activate
                        </ButtonBase>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default PMAdvanceFilter