import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import maintenanceTypeService from "./maintenanceTypeService";
import { smartTryCatch } from "../../../utils";

const initState = {
  // Maintenance Type
  maintenanceTypes: [],
  maintenanceType: {},

  // fields
  maintenanceTypeName: "",

  // modals
  maintenanceTypeModalCreate: false,
  maintenanceTypeModalUpdate: false,
  maintenanceTypeModalDelete: false,

  // success
  isMaintenanceTypeCreateSuccess: false,
  isMaintenanceTypeGetSuccess: false,
  isMaintenanceTypeUpdateSuccess: false,
  isMaintenanceTypeDeleteSuccess: false,

  // loading
  isMaintenanceTypeGetLoading: false,
  isMaintenanceTypeCreateLoading: false,
  isMaintenanceTypeUpdateLoading: false,
  isMaintenanceTypeDeleteLoading: false,

  // error
  isMaintenanceTypeGetError: false,
  isMaintenanceTypeCreateError: false,
  isMaintenanceTypeUpdateError: false,
  isMaintenanceTypeDeleteError: false,
};

export const createMaintenanceType = createAsyncThunk(
  "createMaintenanceType",
  async (data, thunkAPI) => {
    const create = await smartTryCatch(
      maintenanceTypeService.createMaintenanceType,
      data,
      thunkAPI
    );
    return create;
  }
);

export const getMaintenanceTypeByAuthentication = createAsyncThunk(
  "getMaintenanceTypeByAuthentication",
  async (data, thunkAPI) => {
    const getTech = await smartTryCatch(
      maintenanceTypeService.getMaintenanceTypeByAuthentication,
      data,
      thunkAPI
    );
    return getTech;
  }
);

export const getMaintenanceTypeById = createAsyncThunk(
  "getMaintenanceTypeById",
  async (data, thunkAPI) => {
    const getTech = await smartTryCatch(
      maintenanceTypeService.getMaintenanceTypeById,
      data,
      thunkAPI
    );
    return getTech;
  }
);

export const deleteMaintenanceType = createAsyncThunk(
  "deleteMaintenanceType",
  async (data, thunkAPI) => {
    const deleteTech = await smartTryCatch(
      maintenanceTypeService.deleteMaintenanceType,
      data,
      thunkAPI
    );
    return deleteTech;
  }
);

export const updateMaintenanceType = createAsyncThunk(
  "updateMaintenanceType",
  async (data, thunkAPI) => {
    const updateTech = await smartTryCatch(
      maintenanceTypeService.updateMaintenanceType,
      data,
      thunkAPI
    );
    return updateTech; 
  }
);

export const maintenanceTypeSlice = createSlice({
  name: "maintenanceType",
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isMaintenanceTypeGetLoading = false;
      state.isMaintenanceTypeGetSuccess = false;
      state.isMaintenanceTypeGetError = false;
      state.isMaintenanceTypeCreateLoading = false;
      state.isMaintenanceTypeCreateSuccess = false;
      state.isMaintenanceTypeCreateError = false;
      state.isMaintenanceTypeUpdateLoading = false;
      state.isMaintenanceTypeUpdateSuccess = false;
      state.isMaintenanceTypeUpdateError = false;
      state.isMaintenanceTypeDeleteLoading = false;
      state.isMaintenanceTypeDeleteSuccess = false;
      state.isMaintenanceTypeDeleteError = false;
      state.maintenanceType = null;
    },
    openMaintenanceTypeModalCreate: (state) => {
      state.maintenanceTypeModalCreate = true;
    },
    closeMaintenanceTypeModalCreate: (state) => {
      state.maintenanceTypeModalCreate = false;
    },
    openMaintenanceTypeModalUpdate: (state) => {
      state.maintenanceTypeModalUpdate = true;
    },
    closeMaintenanceTypeModalUpdate: (state) => {
      state.maintenanceTypeModalUpdate = false;
    },
    openMaintenanceTypeModalDelete: (state) => {
      state.maintenanceTypeModalDelete = true;
    },
    closeMaintenanceTypeModalDelete: (state) => {
      state.maintenanceTypeModalDelete = false;
    },
    setMaintenanceType: (state, action) => {
      state.maintenanceType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMaintenanceTypeByAuthentication.pending, (state) => {
        state.isMaintenanceTypeGetLoading = true;
        state.isMaintenanceTypeGetSuccess = false;
        state.isMaintenanceTypeGetError = false;
      })
      .addCase(
        getMaintenanceTypeByAuthentication.fulfilled,
        (state, action) => {
          state.isMaintenanceTypeGetLoading = false;
          state.isMaintenanceTypeGetSuccess = true;
          state.isMaintenanceTypeGetError = false;
          state.maintenanceTypes = action.payload;
        }
      )
      .addCase(getMaintenanceTypeByAuthentication.rejected, (state) => {
        state.isMaintenanceTypeGetLoading = false;
        state.isMaintenanceTypeGetSuccess = false;
        state.isMaintenanceTypeGetError = true;
      })
      .addCase(getMaintenanceTypeById.pending, (state) => {
        state.isMaintenanceTypeGetLoading = true;
        state.isMaintenanceTypeGetSuccess = false;
        state.isMaintenanceTypeGetError = false;
      })
      .addCase(getMaintenanceTypeById.fulfilled, (state, action) => {
        state.isMaintenanceTypeGetLoading = false;
        state.isMaintenanceTypeGetSuccess = true;
        state.isMaintenanceTypeGetError = false;
        state.maintenanceTypes = action.payload;
      })
      .addCase(getMaintenanceTypeById.rejected, (state) => {
        state.isMaintenanceTypeGetLoading = false;
        state.isMaintenanceTypeGetSuccess = false;
        state.isMaintenanceTypeGetError = true;
      })
      .addCase(createMaintenanceType.pending, (state) => {
        state.isMaintenanceTypeCreateLoading = true;
        state.isMaintenanceTypeCreateSuccess = false;
        state.isMaintenanceTypeCreateError = false;
      })
      .addCase(createMaintenanceType.fulfilled, (state, action) => {
        state.isMaintenanceTypeCreateLoading = false;
        state.isMaintenanceTypeCreateSuccess = true;
        state.isMaintenanceTypeCreateError = false;
        state.maintenanceTypes.push(action.payload);
      })
      .addCase(createMaintenanceType.rejected, (state) => {
        state.isMaintenanceTypeCreateLoading = false;
        state.isMaintenanceTypeCreateSuccess = false;
        state.isMaintenanceTypeCreateError = true;
      })
      .addCase(deleteMaintenanceType.pending, (state) => {
        state.isMaintenanceTypeDeleteLoading = true;
        state.isMaintenanceTypeDeleteSuccess = false;
        state.isMaintenanceTypeDeleteError = false;
      })
      .addCase(deleteMaintenanceType.fulfilled, (state, action) => {
        state.isMaintenanceTypeDeleteLoading = false;
        state.isMaintenanceTypeDeleteSuccess = true;
        state.isMaintenanceTypeDeleteError = false;
        state.maintenanceTypes = state.maintenanceTypes.filter(
          (maintenanceType) => maintenanceType.id !== action.payload
        );
      })
      .addCase(deleteMaintenanceType.rejected, (state) => {
        state.isMaintenanceTypeDeleteLoading = false;
        state.isMaintenanceTypeDeleteSuccess = false;
        state.isMaintenanceTypeDeleteError = true;
      })
      .addCase(updateMaintenanceType.pending, (state) => {
        state.isMaintenanceTypeUpdateLoading = true;
        state.isMaintenanceTypeUpdateSuccess = false;
        state.isMaintenanceTypeUpdateError = false;
      })
      .addCase(updateMaintenanceType.fulfilled, (state, action) => {
        state.isMaintenanceTypeUpdateLoading = false;
        state.isMaintenanceTypeUpdateSuccess = true;
        state.isMaintenanceTypeUpdateError = false;
        state.maintenanceTypes = state.maintenanceTypes.map(
          (maintenanceType) =>
            maintenanceType.id === action.payload.id
              ? action.payload
              : maintenanceType
        );
      })
      .addCase(updateMaintenanceType.rejected, (state) => {
        state.isMaintenanceTypeUpdateLoading = false;
        state.isMaintenanceTypeUpdateSuccess = false;
        state.isMaintenanceTypeUpdateError = true;
      });
  },
});

export const {
  reset,
  openMaintenanceTypeModalCreate,
  closeMaintenanceTypeModalCreate,
  openMaintenanceTypeModalUpdate,
  closeMaintenanceTypeModalUpdate,
  openMaintenanceTypeModalDelete,
  closeMaintenanceTypeModalDelete,
  setMaintenanceType,
} = maintenanceTypeSlice.actions;

export default maintenanceTypeSlice.reducer;
