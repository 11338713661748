import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setFilterWords } from '../../redux/features/SOR/SORSlice';

function AdvancedSearchInput({ title, keyWord, example, val }) {
    const dispatch = useDispatch()
    
    const {
        filterWords
    } = useSelector(state => state.SOR);

    const handleInputChange = (e) => {
        dispatch(setFilterWords({ ...filterWords, [e.target.id]: e.target.value }))
    };

    return (
        <div className='filter-wrapper' style={{ marginTop: "-10px", }} >
            <input
                className='block w-full px-2 py-2 border border-gray-300 rounded-md'
                type="text"
                value={val}
                onChange={handleInputChange}
                id={keyWord}
                placeholder={title} />
            <span style={{ fontSize: "14px", color: "#9DA0A6" }} >{example}</span>
        </div>
    )
}

export default AdvancedSearchInput