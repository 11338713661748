/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-template */

import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEllipsis,
  faFilter,
  faPlus,
  faDownload,
  faUpload,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import * as FileSaver from 'file-saver';
// import { BlobProvider } from '@react-pdf/renderer';
// import * as XLSX from "xlsx";
import classes from './assestsPage.module.css';
import {
  downloadTemplate,
  exportAsset,
  getAssetsByAuthentication,
  importAsset,
  reset,
  resetAsset,
  setAsset,
  setAssetSearcVal,
  setImportFile,
} from '../../redux/features/asset/assetSlice';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';
import PrintBulkQr from './printBulkQr/PrintBulkQr';
// import PrintBulkQr from './printBulkQr/PrintBulkQr';

function AssetHeader ({ setISShowFilter, iSShowFilter, assets }) {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const { profile } = useSelector(state => state.company);
  const { fileExport, assetSearchValue, template } = useSelector(state => state.asset);
  // const fileType =
  //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = '.csv';
  const fileExName = 'exported_asset_data';

  // const exportToCSV = async (apiData, fileName) => {
  //   const headers = Object.keys(apiData[0]); // Assuming all objects in apiData have the same keys
  //   // Convert the API data and headers to CSV format
  //   const csvContent =
  //     headers.join(',') +
  //     '\n' +
  //     apiData.map(row => headers.map(key => `"${row[key]}"`).join(',')).join('\n');

  //   // Create a Blob from the CSV content with the appropriate Content-Type
  //   const data = new Blob([csvContent], { type: 'text/csv' });

  //   // Save the Blob as a file
  //   FileSaver.saveAs(data, fileName + fileExtension);
  // };


  const exportToCSV = async (apiData, fileName) => {
    const headers = Object.keys(apiData[0]);
  
    // Convert data to CSV format
    const csvContent = apiData.map(row => {
      return headers.map(header => {
        // If the field contains a comma or double quote, enclose it in quotes and escape any double quotes
        let field = row[header].replace(/"/g, '""'); // Escape double quotes
        if (field.includes(',') || field.includes('"')|| field.includes('\n')) {
          field = `"${field}"`; // Enclose in quotes if necessary
        }
        return field;
      }).join(',');
    }).join('\n');
  
    // Construct the final CSV content
    const finalCSV = headers.join(',') + '\n' + csvContent;
  
    // Create a Blob from the CSV content with the appropriate Content-Type
    const data = new Blob([finalCSV], { type: 'text/csv' });
  
    // Save the Blob as a file
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  

  useEffect(() => {
    if (fileExport?.length > 0) {
      exportToCSV(fileExport, fileExName);
      dispatch(reset());
    }
  }, [fileExport]);

  function createCSVContent (headers) {
    const csvContent = headers.join(',') + '\n';
    return csvContent;
  }

  function downloadCSVFile (csvContent, filename) {
    const data = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  }

  useEffect(() => {
    if (template) {
      const handleDownload = () => {
        const csvContent = createCSVContent(template);
        downloadCSVFile(csvContent, 'Asset-Template.csv');
      };
      handleDownload();
      dispatch(reset());
    }
  }, [template]);

  const [iSShowImports, setISShowImports] = useState(false);

  const handelChange = e => {
    dispatch(setAssetSearcVal(e.target.value));
  };

  const handelReset = () => {
    dispatch(setAsset({}));
    dispatch(resetAsset());
    dispatch(selectActions.reset());
  };
  const handelImportFile = async e => {
    dispatch(setImportFile(e.target.files[0]));
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    await dispatch(importAsset(formData));
    dispatch(getAssetsByAuthentication());
  };

  const componentRef = useRef();
  const handleBulkQr = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <div className={classes.assetsTop}>
        <h4 className={classes.assetsTitle}>Assets</h4>
        <div className={classes.assetActions}>
          <input
            type="text"
            placeholder="Search"
            value={assetSearchValue}
            onChange={handelChange}
          />
          <button onClick={handelReset} type="button" className={classes.assetfaPlus}>
            <Link to="/addAsset">
              <FontAwesomeIcon icon={faPlus} />
            </Link>
          </button>
          <button
            type="button"
            onClick={() => setISShowFilter(!iSShowFilter)}
            className={classes.assetFilter}
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
          <button
            type="button"
            onClick={() => setISShowImports(!iSShowImports)}
            className={classes.assetEllipsis}
          >
            <FontAwesomeIcon icon={faEllipsis} />
          </button>
        </div>
      </div>

      {iSShowImports && (
        <div className={classes.imports}>
          {user?.role !== 'Technical Team' && (
            <>
              <div className={classes.Importfile}>
                <input
                  onChange={handelImportFile}
                  type="file"
                  name="file"
                  id="file"
                  accept=".csv"
                />
                <label htmlFor="file">
                  <FontAwesomeIcon icon={faUpload} />
                  Import From Excel
                </label>
              </div>
              <div onClick={() => dispatch(exportAsset({ extension: 'JSON' }))}>
                <FontAwesomeIcon icon={faDownload} />
                Export To Excel
              </div>
            </>
          )}
          <div
            onClick={() => dispatch(downloadTemplate({ extension: 'CSV', template_type: 'Asset' }))}
          >
            Download Template
            <FontAwesomeIcon icon={faDownload} />
          </div>
          <div>
            <Link to="/assetMapping">
              View Location Plan
              <FontAwesomeIcon icon={faEye} />
            </Link>
          </div>
          {/* <div> */}
          <div>
          <PrintBulkQr assets={assets} company={profile?.logo} /> <FontAwesomeIcon icon={faPrint} />
          </div>
          {/* </div> */}
          {/* <BlobProvider document={<PrintBulkQr assets={assets} company={profile?.logo} />}>
            {({ url, loading, ...rest }) =>
              loading ? (
                'Loading...'
              ) : (
                <div>
                  <a href={url} target="_blank">
                    Bulk QR Printing
                    <FontAwesomeIcon icon={faPrint} />
                  </a>
                </div>
              )
            }
          </BlobProvider> */}
        </div>
      )}
    </>
  );
}

export default AssetHeader;
