import React, { useState } from 'react'
import { Drawer } from '@material-ui/core';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenPenaltyModal, setPenaltyData } from '../../redux/features/SOR/SORSlice';
import PlusIcon from '../../assets/svg-icons/PlusIcon';
import DeleteIcon from '../../assets/svg-icons/DeleteIcon';

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-restricted-globals */

function AddNewPenaltyModal() {
    const { openPenaltyModal, penaltyData } = useSelector(state => state.SOR);
    const [penaltyList, setPenaltyList] = useState([])
    const dispatch = useDispatch();

    const close = () => {
        dispatch(setOpenPenaltyModal(false))
    }
    const hadelAddNewData = () => {
        const penaltyItem = {
            parameter: "",
            active: false,
            totalDays: 0,
            penaltyQuantumType: "percentage",
            penaltyQuantumRate: 0,
            startDate: "",
            endDate: "",
        }
        if (penaltyList.length === 0) {
            setPenaltyList([...penaltyList, penaltyItem])
        } else if (penaltyList.every(item => item.parameter && item.penaltyQuantumRate >= 0)) {
            setPenaltyList([...penaltyList, penaltyItem])
        } else {
            toast.warning('Please fill Penalty Parameter and Penalty quantum fields first', {
                toastId: 'CreatedEventWarning',
            });
        }
    }
    const handleDelete = (index) => {
        const updatedPenalty = [...penaltyList]
        updatedPenalty.splice(index, 1)
        setPenaltyList(updatedPenalty)
    }

    const handleChange = (e, index) => {
        const { value, name } = e.target;
        const updatedPenalty = [...penaltyList];
    
        // Extract the existing startDate and endDate values
        const currentStartDate = new Date(updatedPenalty[index].startDate);
        const currentEndDate = new Date(updatedPenalty[index].endDate);
    
        // Parse the new value
        const newDate = new Date(value);
    
        if (name === "startDate") {
            // Validate the new startDate against the existing endDate
            if (!isNaN(newDate.getTime()) && !isNaN(currentEndDate.getTime())) {
                if (newDate.getTime() > currentEndDate.getTime()) {
                    toast.warning('Start date cannot be after the end date', {
                        toastId: 'CreatedEventWarning',
                    });
                    return;
                }
            }
        } else if (name === "endDate") {
            // Validate the new endDate against the existing startDate
            if (!isNaN(newDate.getTime()) && !isNaN(currentStartDate.getTime())) {
                if (currentStartDate.getTime() > newDate.getTime()) {
                    toast.warning('End date cannot be before the start date', {
                        toastId: 'CreatedEventWarning',
                    });
                    return;
                }
            }
        }
        // If validation passes, update the value
        updatedPenalty[index][name] = value;
        setPenaltyList(updatedPenalty);
    };
    
    const handlePenaltyQuantumType = (e, index) => {
        const { value } = e.target; // Use the "value" attribute
        const updatedPenalty = [...penaltyList];
        updatedPenalty[index].penaltyQuantumType = value; // Update with the selected value
        setPenaltyList(updatedPenalty); // Update the state
      };

    const handleToggleChange = (e, index) => {
        const { name } = e.target
        const updatedPenalty = [...penaltyList]
        updatedPenalty[index][name] = !updatedPenalty[index][name]
        setPenaltyList(updatedPenalty)
    }

    console.log("penaltyList", penaltyList);

    const handleSave = () => {
        // Check if the penalty list meets the conditions
        const isValidPenaltyList = penaltyList.length > 0 && penaltyList.every(item => {
            if (item.active) {
                // Check if active, ensure valid dates
                const startDate = new Date(item.startDate);
                const endDate = new Date(item.endDate);
    
                // Validate that both dates exist and are in the correct order
                if (!item.startDate || !item.endDate || isNaN(startDate.getTime()) || isNaN(endDate.getTime()) || startDate > endDate) {
                    return false; // Invalid if active and dates are missing or incorrect
                }
            }
            // If not active, dates are optional
            return item.parameter && item.penaltyQuantumRate >= 0;
        });
    
        if (isValidPenaltyList) {
            dispatch(setPenaltyData([...penaltyData, ...penaltyList]));
            close();
        } else {
            toast.warning('Please fill the required fields and ensure all active penalties have valid start and end dates, or close without saving.', {
                toastId: 'CreatedEventWarning',
            });
        }
    };


    return (
        <Drawer anchor="right" open={openPenaltyModal} onClose={close}
            PaperProps={{
                style: {
                    width: '100%',
                    maxWidth: '600px',
                    height: '100%',
                },
            }} >
            <div className=" w-100"
            >
                <div className="col-lg-12 col-sm-12 pb-5" id="">
                    <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">
                        <div className="col-12 row px-2 ">
                            <div className="my-auto">
                                <b>Penalty</b>
                            </div>
                            <div className="row ml-auto">
                                <button
                                    type="button"
                                    className="btn tertiary-btn ml-2"
                                    data-dismiss="modal"
                                    onClick={close}
                                >
                                    <div className="fas fa-times" />
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal ">Code / <span style={{ color: "#727478" }}>Kod</span> <label className="font-danger m-0">*</label></label>
                        <select
                            value={code}
                            className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                            onChange={(e) => setCode(e.target.value)}
                        >
                            <option value="" disabled selected>
                                --SELECT--
                            </option>
                            {uniqueSources?.map((val) => (
                                <option key={val} value={val}>
                                    {val}
                                </option>
                            ))}
                        </select>
                    </div> */}

                    <div className="col-lg-12 col-sm-12 pt-3  choose-assets-location">
                        <div> {penaltyList?.length} Penalty registered</div>
                        <button type='button'
                            onClick={hadelAddNewData}
                        >
                            <span className='mr-2'><PlusIcon color="#D9314A" /> </span> Add new
                        </button>
                    </div>

                    {penaltyList.map((item, index) => (
                        <div className='card-add-new'>
                            <div className='heading-of-card-add-new'>
                                <h6> Penalty #{index + 1} </h6>
                                <button type='button' onClick={() => handleDelete(index)}><DeleteIcon /></button>
                            </div>
                            <div className='row'>
                                <div className="col-lg-12 col-sm-12 pt-2">
                                    <div className="col-12 p-0">
                                        <label className="font-weight-normal">Parameter<label className="font-danger m-0">*</label></label>
                                        <input
                                            name='parameter'
                                            placeholder='Add description'
                                            className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                                            value={item.parameter}
                                            onChange={(e) => handleChange(e, index)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-12 row pt-2">
                                    <label className="font-weight-normal">Period</label>
                                    <div className="col-lg-12 col-sm-12 row">
                                        <div style={{
                                            width: "87px"
                                        }}>
                                            <label className="weekly-frequency-switch black-switch">
                                                <input type="checkbox"
                                                    name='active'
                                                    checked={item.active}
                                                    onChange={(e) => handleToggleChange(e, index)}
                                                />
                                                <span className="weekly-frequency-slider" />
                                            </label>
                                            <span className="weekly-frequency-label">Active</span>
                                        </div>
                                        <div className="col-lg-5 col-sm-6">
                                            <input
                                                name='startDate'
                                                placeholder='period'
                                                className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                                                type='date'
                                                min="0"
                                                value={item.startDate}
                                                onChange={(e) => handleChange(e, index)}
                                                disabled={!item.active}
                                            />
                                        </div>
                                        <div className="col-lg-5 col-sm-6">
                                            <input
                                                name='endDate'
                                                placeholder='period'
                                                className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                                                type='date'
                                                min="0"
                                                value={item.endDate}
                                                onChange={(e) => handleChange(e, index)}
                                                disabled={!item.active}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-12 ">
                                    <label className="font-weight-normal">Total days</label>
                                    <input
                                        name='totalDays'
                                        placeholder='Total days'
                                        className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                                        type='number'
                                        min="0"
                                        value={item.endDate && item.startDate ? moment(item.endDate).diff(moment(item.startDate), "days") : 0}
                                        onChange={(e) => handleChange(e, index)}
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-12 col-sm-12 pt-2 ">
                                    <label className="font-weight-normal">Penalty quantum<label className="font-danger m-0">*</label></label>
                                    <div className=" col-lg-12 col-sm-12 row">
                                        <div className='mr-3'>
                                            <input
                                                className="mr-2"
                                                type="radio"
                                                checked={item.penaltyQuantumType === 'percentage'}
                                                required
                                                name={`penaltyType${index}`} // Use unique group names for each row
                                                value="percentage" // Set the value to "percentage"
                                                onChange={(e) => handlePenaltyQuantumType(e, index)}
                                                id={`percentage-${index}`}
                                            />
                                            <label
                                                htmlFor={`percentage-${index}`}
                                                className="font-weight-normal mb-0"
                                            >By percentage (%)
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                className="mr-2"
                                                checked={item.penaltyQuantumType === 'price'}
                                                type="radio"
                                                name={`penaltyType${index}`} // Use the same group name for the other radio button
                                                value="price" // Set the value to "price"
                                                onChange={(e) => handlePenaltyQuantumType(e, index)}
                                                id={`price-${index}`}
                                            />
                                            <label htmlFor={`price-${index}`} className="font-weight-normal mb-0">
                                                By price (RM)
                                            </label>
                                        </div>
                                    </div>
                                    <input
                                        name='penaltyQuantumRate'
                                        placeholder='Penalty quantum rate'
                                        className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                                        type='number'
                                        min="0"
                                        value={item.penaltyQuantumRate}
                                        onChange={(e) => handleChange(e, index)}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
                <div className=" m-0 p-2 page-profile-footer">
                    <button onClick={handleSave} className="btn primary-btn" type="submit">
                        Save
                    </button>
                </div>
            </div>
        </Drawer>
    )
}

export default AddNewPenaltyModal