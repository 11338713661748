/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SORService from "./SORService";
import { smartTryCatch } from '../../../utils';

const initState = {
  // data
  SORs: [],
  SOR: null,
  BOQData:[],
  singleBill:null,
  isPrint: false,
  SORHistoryData: [],
  sorces: [],
  trades: [],
  summaryCounts: 0,
  
  // filters
  searchValue: "",
  source: "",
  trade: "",
  filterWords:{
    all: "",
    exact: "",
    any: "",
    none: "",
  },
  selectedFilter: "all",
  SORTab: "sor",

  isBookmarked:false,

  // pagination

  // SOR
  currentPage: 1,
  rowPerPage: 10,

  // BOQ
  BOQCurrentPage: 1,
  BOQRowPerPage: 10,

  // History
  historyCurrentPage: 1,
  historyRowPerPage: 10,

  // modals
  openSORModal:false,
  openSurchargeModal:false,
  openPenaltyModal:false,


  // Bill Data
  billData:[],
  billTotalPrice:null,
  surchargeData: [],
  surchargeRates: [],
  surchargeTotalPrice:null,
  penaltyData: [],
  penaltyTotalPrice: 0,
  subTotal: null,

  billWorkData:{
    title: "",
    description : "",
    agreementNo: "",
    jobNum: "",
    order: "",
    orderType: "",
    location: "",
  },

  // request states
  isBillCreateLoading: false,
  isBillGetLoading: false,
  isBillsGetLoading: false,
  isBillDeleteLoading: false,
  isBillUpdateLoading: false,


  // error states
  isBillGetError: false,
  isBillsGetError: false,
  isBillCreateError: false,
  isBillDeleteError: false,
  isBillUpdateError: false,

  // success states
  isBillGetSuccess: false,
  isBillsGetSuccess: false,
  isBillCreateSuccess: false,
  isBillDeleteSuccess: false,
  isBillUpdateSuccess: false,

    // Sor import & export states
    fileExport: [],
    fileImport: null,
    template: null,
    isSORExported: false,
    isSORImported: false,
    isSORImportedLoading: false,
    isSORImportedError: false,
    isSORDownloadLoading: false,
    isSORDownloadError: false,
    isSORDownloadSuccess: false,
};


export const getSORByAuthentication = createAsyncThunk(
  'getSORByAuthentication',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      SORService.getSORByAuthentication,
      data,
      thunkAPI,
    );
    return res;
  },
);
export const getSORSummary = createAsyncThunk(
  'getSORSummary',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      SORService.getSORSummary,
      data,
      thunkAPI,
    );
    return res;
  },
);
export const getSORSoucrcesTardes = createAsyncThunk(
  'getSORSoucrcesTardes',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      SORService.getSORSoucrcesTardes,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const getSORByPagination = createAsyncThunk(
  'getSORByPagination',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      SORService.getSORByPagination,
      data,
      thunkAPI,
    );
    return res;
  },
);
export const getSORHistoryByPagination = createAsyncThunk(
  'getSORHistoryByPagination',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      SORService.getSORHistoryByPagination,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const getSORById = createAsyncThunk(
  'getSORById',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      SORService.getSORById,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const createBill = createAsyncThunk(
  'createBill',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      SORService.createBill,
      data,
      thunkAPI,
    );
    return res;
  },
);
export const getBills = createAsyncThunk(
  'getBills',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      SORService.getBills,
      data,
      thunkAPI,
    );
    return res;
  },
);


export const getBillById = createAsyncThunk(
  'getBillById',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      SORService.getBillById,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const deleteBillById = createAsyncThunk(
  'deleteBillById',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      SORService.deleteBillById,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const updateBill = createAsyncThunk(
  'updateBill',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      SORService.updateBill,
      data,
      thunkAPI,
    );
    return res;
  },
);
export const updateBookmark = createAsyncThunk(
  'updateBookmark',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      SORService.updateBookmark,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const exportSOR = createAsyncThunk('exportSOR', async (data, thunkAPI) => {
  const exportSORRes = await smartTryCatch(SORService.exportSOR, data, thunkAPI);
  return exportSORRes;
});
export const downloadTemplate = createAsyncThunk(
  'downloadSORTemplate',
  async (data, thunkAPI) => {
    const downloadTemplatetRes = await smartTryCatch(SORService.downloadTemplate, data, thunkAPI);
    return downloadTemplatetRes;
  },
);

export const importSOR = createAsyncThunk('importSOR', async (data, thunkAPI) => {
  const importSORRes = await smartTryCatch(SORService.importSOR, data, thunkAPI);
  return importSORRes;
});




export const SORSlice = createSlice({
  name: 'SOR',
  initialState: initState,
  reducers: {
    reset:(state) => {
      state.isSORExported = false;
      state.fileExport = [];
      state.template = null;
      state.isSORImported = false;
      state.isSORImportedLoading = false;
      state.isSORImportedError = false;
      state.isSORDownloadSuccess = false;
      state.isSORDownloadLoading = false;
      state.isSORDownloadError = false;
      state.isBillCreateLoading = false;
      state.isBillCreateError = false;
      state.isBillCreateSuccess = false;
      state.isBillDeleteLoading = false;
      state.isBillDeleteError = false;
      state.isBillDeleteSuccess = false;
    },
    resetState : (state)=>{
      // Bill Data
      state.billData=[]
      state.billTotalPrice=null
      state.surchargeData= []
      state.surchargeRates= []
      state.surchargeTotalPrice=null
      state.penaltyData= []
      state.penaltyTotalPrice= 0
      state.subTotal= null
      state.singleBill= null
      state.isPrint= null
      state.billWorkData={
        title: "",
        description : "",
        agreementNo: "",
        jobNum: "",
        order: "",
        orderType: "",
        location: "",
      }
},
    setImportFile: (state, action) => {
      state.fileImport = action.payload;
    },

    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowPerPage: (state, action) => {
      state.rowPerPage = action.payload;
    },
    setBOQCurrentPage: (state, action) => {
      state.BOQCurrentPage = action.payload;
    },
    setBOQRowPerPage: (state, action) => {
      state.BOQRowPerPage = action.payload;
    },
    setHistoryCurrentPage: (state, action) => {
      state.historyCurrentPage = action.payload;
    },
    setHistoryRowPerPage: (state, action) => {
      state.historyRowPerPage = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSOR: (state, action) => {
      state.SOR = action.payload;
    },
    setOpenSORModal: (state, action) => {
      state.openSORModal = action.payload;
    },
    setOpenSurchargeModal: (state, action) => {
      state.openSurchargeModal = action.payload;
    },
    setOpenPenaltyModal: (state, action) => {
      state.openPenaltyModal = action.payload;
    },
    setSORTab: (state, action) => {
      state.SORTab = action.payload;
    },

    // filters
    setSource: (state, action) => {
      state.source = action.payload;
    },
    setTrade: (state, action) => {
      state.trade = action.payload;
    },
    setFilterWords: (state, action) => {
      state.filterWords = action.payload;
    },
    setSelectedFilter: (state, action) => {
      state.selectedFilter = action.payload;
    },
    setIsBookmarked: (state, action) => {
      state.isBookmarked = action.payload;
    },
    setBillData: (state, action) => {
      state.billData = action.payload;
    },
    setBillTotalPrice: (state, action) => {
      state.billTotalPrice = action.payload;
    },
    setSurchargeTotalPrice: (state, action) => {
      state.surchargeTotalPrice = action.payload;
    },
    setSurchargeData: (state, action) => {
      state.surchargeData = action.payload;
    },
    setSurchargeRates: (state, action) => {
      state.surchargeRates = action.payload;
    },
    setSubTotal: (state, action) => {
      state.subTotal = action.payload;
    },
    setPenaltyData: (state, action) => {
      state.penaltyData = action.payload;
    },
    setPenaltyTotalPrice: (state, action) => {
      state.penaltyTotalPrice = action.payload;
    },
    setSingleBill: (state, action) => {
      state.singleBill = action.payload;
    },
    setIsPrint: (state, action) => {
      state.isPrint = action.payload;
    },
    setBillQty: (state, action) => {
      const {item, type , qty, dis, typeOfOrder} = action.payload
      const findIndex = state.billData.findIndex(ele => ele.id === item.id)
      const obj = state.billData[findIndex]

      if (type === "DiscountInput") {
        obj.discountRate = Number(dis)
      }

      if (typeOfOrder === "client") {
        if (type === "inc") {
          obj.quantity += 1
        }
        else if (type  === "dec") {
          if (obj.quantity > 1) {
            obj.quantity -= 1
          }
        }
        else if (type === "QTYInput") {
          obj.quantity = Number(qty)
        }
      }else{
        if (type === "inc") {
          obj.vendorQuantity += 1
        }
        else if (type  === "dec") {
          if (obj.vendorQuantity > 1) {
            obj.vendorQuantity -= 1
          }
        }
        else if (type === "QTYInput") {
          obj.vendorQuantity = Number(qty)
        }
      }
      const priceWithDiscount = (obj.rate * obj.quantity) - ((obj.discountRate *obj.rate * obj.quantity) / 100)
      const vedorPriceWithDiscount = (obj.rate * obj.vendorQuantity) - ((obj.discountRate *obj.rate * obj.vendorQuantity) / 100)
      obj.totalPrice = Number(priceWithDiscount.toFixed(2))
      obj.vendorTotalPrice = Number(vedorPriceWithDiscount.toFixed(2))
      state.billData[findIndex] = obj;
    },
    setBillWorkData: (state, action) => {
      state.billWorkData = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getSORByAuthentication.pending, (state) => {
        state.isSORGetLoading = true;
        state.isSORGetSuccess = false;
        state.isSORGetError = false;
      })
      .addCase(getSORByAuthentication.fulfilled, (state, action) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = true;
        state.isSORGetError = false;
        state.SORs = { ...action.payload };
      })
      .addCase(getSORByAuthentication.rejected, (state) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = false;
        state.isSORGetError = true;
      })
      .addCase(getSORById.pending, (state) => {
        state.isSORGetLoading = true;
        state.isSORGetSuccess = false;
        state.isSORGetError = false;
      })
      .addCase(getSORById.fulfilled, (state, action) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = true;
        state.isSORGetError = false;
        state.SOR = action.payload ;
      })
      .addCase(getSORById.rejected, (state) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = false;
        state.isSORGetError = true;
      })
      .addCase(getSORSummary.pending, (state) => {
        state.isSORGetLoading = true;
        state.isSORGetSuccess = false;
        state.isSORGetError = false;
      })
      .addCase(getSORSummary.fulfilled, (state, action) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = true;
        state.isSORGetError = false;
        state.summaryCounts = action.payload;
      })
      .addCase(getSORSummary.rejected, (state) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = false;
        state.isSORGetError = true;
      })
      .addCase(createBill.pending, (state) => {
        state.isBillCreateLoading = true;
        state.isBillCreateSuccess = false;
        state.isBillCreateError = false;
      })
      .addCase(createBill.fulfilled, (state) => {
        state.isBillCreateLoading = false;
        state.isBillCreateSuccess = true;
        state.isBillCreateError = false;
      })
      .addCase(createBill.rejected, (state) => {
        state.isBillCreateLoading = false;
        state.isBillCreateSuccess = false;
        state.isBillCreateError = true;
      })
      .addCase(getBills.pending, (state) => {
        state.isBillsGetLoading = true;
        state.isBillsGetSuccess = false;
        state.isBillsGetError = false;
      })
      .addCase(getBills.fulfilled, (state, action) => {
        state.isBillsGetLoading = false;
        state.isBillsGetSuccess = true;
        state.isBillsGetError = false;
        state.BOQData = action.payload;
      })
      .addCase(getBills.rejected, (state) => {
        state.isBillsGetLoading = false;
        state.isBillsGetSuccess = false;
        state.isBillsGetError = true;
      })
      .addCase(getBillById.pending, (state) => {
        state.isBillGetLoading = true;
        state.isBillGetSuccess = false;
        state.isBillGetError = false;
      })
      .addCase(getBillById.fulfilled, (state, action) => {
        state.isBillGetLoading = false;
        state.isBillGetSuccess = true;
        state.isBillGetError = false;
        state.singleBill = action.payload;
      })
      .addCase(getBillById.rejected, (state) => {
        state.isBillGetLoading = false;
        state.isBillGetSuccess = false;
        state.isBillGetError = true;
      })
      .addCase(deleteBillById.pending, (state) => {
        state.isBillDeleteLoading = true;
        state.isBillDeleteSuccess = false;
        state.isBillDeleteError = false;
      })
      .addCase(deleteBillById.fulfilled, (state, action) => {
        state.isBillDeleteLoading = false;
        state.isBillDeleteSuccess = true;
        state.isBillDeleteError = false;
        state.BOQData.results = state.BOQData.results.filter((boq) => boq.id !== +action.payload);
      })
      .addCase(deleteBillById.rejected, (state) => {
        state.isBillDeleteLoading = false;
        state.isBillDeleteSuccess = false;
        state.isBillDeleteError = true;
      })
      .addCase(updateBill.pending, (state) => {
        state.isBillCreateLoading = true;
        state.isBillCreateSuccess = false;
        state.isBillCreateError = false;
      })
      .addCase(updateBill.fulfilled, (state, action) => {
        state.isBillCreateLoading = false;
        state.isBillCreateSuccess = true;
        state.isBillCreateError = false;
        state.singleBill = action.payload;
      })
      .addCase(updateBill.rejected, (state) => {
        state.isBillCreateLoading = false;
        state.isBillCreateSuccess = false;
        state.isBillCreateError = true;
      })
      // .addCase(updateBookmark.fulfilled, (state, action) => {
      //   state.SOR = action.payload;
      // })
      .addCase(getSORSoucrcesTardes.pending, (state) => {
        state.isSORGetLoading = true;
        state.isSORGetSuccess = false;
        state.isSORGetError = false;
      })
      .addCase(getSORSoucrcesTardes.fulfilled, (state, action) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = true;
        state.isSORGetError = false;
        state.sources = action.payload.sources;
        state.trades = action.payload.trades;
      })
      .addCase(getSORSoucrcesTardes.rejected, (state) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = false;
        state.isSORGetError = true;
      })
      .addCase(getSORByPagination.pending, (state) => {
        state.isSORGetLoading = true;
        state.isSORGetSuccess = false;
        state.isSORGetError = false;
      })
      .addCase(getSORByPagination.fulfilled, (state, action) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = true;
        state.isSORGetError = false;
        state.SORs = action.payload;
      })
      .addCase(getSORByPagination.rejected, (state) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = false;
        state.isSORGetError = true;
      }).addCase(getSORHistoryByPagination.pending, (state) => {
        state.isSORGetLoading = true;
        state.isSORGetSuccess = false;
        state.isSORGetError = false;
      })
      .addCase(getSORHistoryByPagination.fulfilled, (state, action) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = true;
        state.isSORGetError = false;
        state.SORHistoryData = action.payload;
      })
      .addCase(getSORHistoryByPagination.rejected, (state) => {
        state.isSORGetLoading = false;
        state.isSORGetSuccess = false;
        state.isSORGetError = true;
      }).addCase(exportSOR.pending, state => {
        state.isSORExported = false;
      })
      .addCase(exportSOR.fulfilled, (state, action) => {
        state.isSORExported = true;
        state.fileExport = action.payload;
      })
      .addCase(exportSOR.rejected, state => {
        state.isSORExported = false;
      })
      .addCase(downloadTemplate.pending, state => {
        state.isSORDownloadLoading = true;
        state.isSORDownloadSuccess = false;
        state.isSORDownloadError = false;
      })
      .addCase(downloadTemplate.fulfilled, (state, action) => {
        state.isSORDownloadLoading = false;
        state.isSORDownloadSuccess = true;
        state.isSORDownloadError = false;
        state.template = action.payload.split(',');
      })
      .addCase(downloadTemplate.rejected, state => {
        state.isSORDownloadLoading = false;
        state.isSORDownloadSuccess = false;
        state.isSORDownloadError = true;
      })
      .addCase(importSOR.pending, state => {
        state.isSORImported = false;
        state.isSORImportedLoading = true;
        state.isSORImportedError = false;
      })
      .addCase(importSOR.fulfilled, (state, action) => {
        state.isSORImportedLoading = false;
        state.isSORImported = true;
        state.isSORImportedError = false;
        state.fileImport = action.payload;
      })
      .addCase(importSOR.rejected, state => {
        state.isSORImported = false;
        state.isSORImportedLoading = false;
        state.isSORImportedError = true;
      })
  },
});

export const {
  reset,
  setCurrentPage,
  setRowPerPage,
  setBOQCurrentPage,
  setBOQRowPerPage,
  setHistoryCurrentPage,
  setHistoryRowPerPage,
  setSearchValue,
  setSOR,
  setOpenSORModal,
  setOpenSurchargeModal,
  setOpenPenaltyModal,
  setSORTab,
  setImportFile,
  setSource,
  setTrade,
  setSelectedFilter,
  setFilterWords,
  setBillWorkData,
  setBillData,
  setBillTotalPrice,
  setSurchargeTotalPrice,
  setSurchargeData,
  setSurchargeRates,
  setPenaltyData,
  setPenaltyTotalPrice,
  setBillQty,
  setSubTotal,
  resetState,
  setSingleBill,
  setIsPrint,
  setIsBookmarked
} = SORSlice.actions;

export default SORSlice.reducer;


