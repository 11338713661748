import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
      <head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <title>FOX Asset</title>
        <meta
          name="description"
          content="FOX is a cloud-based solution for managing facilities. Starts from MYR60 – comes with dedicated QR Code function for asset and inventory management that helps you to streamline business operation and maintenance through online documentation, reporting and auditing."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          property="og:title"
          content="FOX | Facilities & Asset Management Software | Try for Free"
        />
        <meta property="og:url" content="" />
        <meta
          property="og:description"
          content="FOX is a cloud-based solution for managing facilities. Starts from MYR60 – comes with dedicated QR Code function for asset and inventory management that helps you to streamline business operation and maintenance through online documentation, reporting and auditing."
        />
      </head>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
