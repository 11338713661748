import React, { useState } from 'react';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { createSubLocation } from '../../redux/features/location/locationSlice';

export default function AddSiteDrawer({ openSiteDrawr, closeSiteDrawer }) {
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.location);
  const initialSublocation = {
    room: '',
    suite: '',
    level: '',
    building: '',
  };
  const [sublocation, setSubLocation] = useState(initialSublocation);

  function createSubLoc(e) {
    e.preventDefault();
    const info = {
      id: location?.id,
      data: sublocation,
    };

    dispatch(createSubLocation(info));
    setSubLocation(initialSublocation);
    closeSiteDrawer()
  }
  return (
    <Drawer
      anchor="right"
      open={openSiteDrawr}
      onClose={closeSiteDrawer}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '600px',
          height: '100%',
        },
      }}
    >
      <form
        action="{{action}}"
        className="h-100"
        method="POST"
        onSubmit={createSubLoc}
        // encType="multipart/form-data"
        //   onSubmit={handleCreateInventory}
      >
        <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">
          <div className="col-12 row px-2">
            <div className="my-auto" style={{ fontWeight: 'bold', padding: '9px' }}>
              Add Sublocation
            </div>
            <div className="row ml-auto">
              <div className="dropdown">
                {/* <button
                  type="button"
                  data-toggle="dropdown"
                  id="dropdownMenu1"
                  className="btn tertiary-btn ml-2"
                >
                  <div className="fas fa-ellipsis-h" />
                </button> */}
                <ul className="dropdown-menu" role="menu" aria-labelledby="dropdownMenu1">
                  <li role="presentation">
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#modal-delete-part"
                      title="Delete Spare part"
                      className="font-danger"
                    >
                      Delete
                    </button>
                  </li>

                  <li role="presentation">
                    <a
                      className="history-link"
                      href="/spare-part-history/{{obj_s.id}}/"
                      title="View Inventory History"
                    >
                      History
                    </a>
                  </li>

                  <li role="presentation">
                    <button href="#" title="Print QR Code" className="text-dark" type="button">
                      Print
                    </button>
                  </li>
                </ul>
              </div>

              <button
                type="button"
                className="btn secondary-btn ml-2"
                data-dismiss="modal"
                onClick={closeSiteDrawer}
              >
                <div className="fas fa-times" />
              </button>
            </div>
          </div>
        </div>

        <div className="col-12 pb-5">
          <div className="card-body row">
            <div className="col-lg-12 col-sm-12">
              <span className="font-weight-normal m-0 asset">Block(Optional)</span>
              <input
                name="spareparts"
                type="text"
                className="form-control mb-2"
                
                placeholder="e.g. D,3,III"
                id="spareparts_id"
                value={sublocation.building}
                onChange={(e) => {
                  setSubLocation({ ...sublocation, building: e.target.value });
                }}
              />
            </div>
            <div className="col-lg-12 col-sm-12">
              <span className="font-weight-normal m-0 asset">Level (Optional)</span>
              <input
                name="spareparts"
                type="text"
                className="form-control mb-2"
                placeholder="e.g Lg,G,123"
                id="spareparts_id"
                value={sublocation.level}
                onChange={(e) => {
                  setSubLocation({ ...sublocation, level: e.target.value });
                }}
              />
            </div>
            <div className="col-lg-12 col-sm-12">
              <span className="font-weight-normal m-0 asset">Suite (Optional)</span>
              <input
                name="spareparts"
                type="text"
                className="form-control mb-2"
                placeholder="e.g Lobby"
                id="spareparts_id"
                value={sublocation.suite}
                onChange={(e) => {
                  setSubLocation({ ...sublocation, suite: e.target.value });
                }}
              />
            </div>
            <div className="col-lg-12 col-sm-12">
              <span className="font-weight-normal row col-12">
                Room
                <div className="font-danger">*</div>
              </span>
              <input
                name="spareparts"
                type="text"
                className="form-control mb-2"
                required
                placeholder="e.g 218"
                id="spareparts_id"
                value={sublocation.room}
                onChange={(e) => {
                  setSubLocation({ ...sublocation, room: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="row  m-0 p-2 page-profile-footer">
          <button
            className="btn button-btn pmd-btn-fab primary-btn"
            // onClick="this.classList.toggle('button--loading')"
            type="submit"
          >
            <span className="button__text">Save</span>
          </button>
        </div>
      </form>
    </Drawer>
  );
}
