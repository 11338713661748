import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getMaintenanceTypeByAuthentication = async () => {
  const res = await http.get(
    `${BASE_URL}/maintenance-type/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of maintenanceType by its company ID failed",
    res
  );
};

const getMaintenanceTypeById = async (maintenanceTypeId) => {
  const res = await http.get(
    `${BASE_URL}/maintenance-type/${maintenanceTypeId}`
  );

  return assert(
    res,
    res.data,
    "Retrieval of maintenanceType by its ID failed",
    res
  );
};

const createMaintenanceType = async (data) => {
  const res = await http.post(
    `${BASE_URL}/maintenance-type/`,
    data
  );

  return assert(res, res.data, "Creation of maintenanceType failed", res);
};

const updateMaintenanceType = async (data) => {
  const res = await http.put(
    `${BASE_URL}/maintenance-type/${data.id}/`,
    data
  );
  return assert(res, res.data, "Update of maintenanceType failed", res);
};

const deleteMaintenanceType = async (maintenanceTypeId) => {
  const res = await http.delete(
    `${BASE_URL}/maintenance-type/${maintenanceTypeId}/`
  );
  return assert(res, maintenanceTypeId, "Deletion of maintenanceType failed", res);
};

const maintenanceTypeService = {
  getMaintenanceTypeByAuthentication,
  getMaintenanceTypeById,
  createMaintenanceType,
  updateMaintenanceType,
  deleteMaintenanceType,
};

export default maintenanceTypeService;
