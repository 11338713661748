import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getBusinessHoursByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/business-hours/`);
  return assert(res, res.data, 'Retrieval of asset Type by its company ID failed', res);
};

const getBusinessHourbyId = async businessHour => {
  const res = await http.get(`${BASE_URL}/business-hours/${businessHour}`);

  return assert(res, res.data, 'Retrieval of asset Type by its ID failed', res);
};

const createBusinessHour = async data => {
  const res = await http.post(`${BASE_URL}/business-hours/`, data);

  return assert(res, res.data, 'Creation of asset Type failed', res);
};

const updateBusinessHour = async data => {
  const res = await http.put(`${BASE_URL}/business-hours/`, data);
  return assert(res, res.data, 'Update of asset Type failed', res);
};

const deleteBusinessHour = async businessHour => {
  const res = await http.delete(`${BASE_URL}/business-hours/${businessHour}/`);
  return assert(res, businessHour, 'Deletion of asset Type failed', res);
};

const businessHourService = {
  getBusinessHoursByAuthentication,
  getBusinessHourbyId,
  createBusinessHour,
  updateBusinessHour,
  deleteBusinessHour,
};

export default businessHourService;
