import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import checklistService from './checklistService';
import { smartTryCatch } from '../../../utils';

const initState = {
  checklists: [],
  checklist: null,
  checklistItem: null,
  itemType: null,
  isChecklistExported: false,
  fileExport: [],

  // pagination
  currentPage: 1,
  rowPerPage: 10,

  // checklist fields
  searchValue : "",
  checklistTitle: '',
  checklistIsTemplate: false,
  checklistItems: [
    // {
    //   name: '',
    //   min: '',
    //   max: '',
    //   metric: '',
    //   type: '',
    // }
  ],
  checklistItemCount: '',

  // checklist fields state
  isItemNumber: false,
  isItemText: false,

  // modals
  checklistModalDelete: false,
  checklistModalUpdate: false,
  checklistModalCreate: false,

  // success
  isCreateChecklistSuccess: false,
  isGetChecklistSuccess: false,
  isDeleteChecklistSuccess: false,
  isUpdateChecklistSuccess: false,

  // loading
  isCreateChecklistLoading: false,
  isDeleteChecklistLoading: false,
  isGetChecklistLoading: false,
  isUpdateChecklistLoading: false,

  // error
  isCreateChecklistError: false,
  isGetChecklistError: false,
  isDeleteChecklistError: false,
  isUpdateChecklistError: false,



  isChecklistImported: false,
  isChecklistImportedLoading: false,
  isChecklistImportedError: false,
};

export const getChecklistByAuthentication = createAsyncThunk(
  'getChecklistByAuthentication',
  async (data, thunkAPI) =>{
    const getChecklist = await smartTryCatch(checklistService.getChecklistByAuthentication, data, thunkAPI);
    return getChecklist;
  }
);

export const getChecklistById = createAsyncThunk(
  'getChecklistById',
  async (data, thunkAPI) => {
    const getChecklist = await smartTryCatch(checklistService.getChecklistById, data, thunkAPI);
    return getChecklist;
  }
);

export const createChecklist = createAsyncThunk(
  'createChecklist',
  async (data, thunkAPI) => {
    const createChecklistRes = await smartTryCatch(checklistService.createChecklist, data, thunkAPI);
    return createChecklistRes;
  }
);

export const deleteChecklist = createAsyncThunk(
  'deleteChecklist',
  async (data, thunkAPI) => {
    const deleteChecklistRes = await smartTryCatch(checklistService.deleteChecklist, data, thunkAPI);
    return deleteChecklistRes;
  }
);
export const deleteBulkChecklist = createAsyncThunk(
  'deleteBulkChecklist',
  async (data, thunkAPI) => {
    const deleteBulkChecklistRes = await smartTryCatch(checklistService.deleteBulkChecklist, data, thunkAPI);
    return deleteBulkChecklistRes;
  }
);

export const updateChecklist = createAsyncThunk(
  'updateChecklist',
  async (data, thunkAPI) => {
    const updateChecklistRes = await smartTryCatch(checklistService.updateChecklist, data, thunkAPI);
    return updateChecklistRes;
  }
);


export const getPagination = createAsyncThunk('getPaginationForchecklist', async (data, thunkAPI) => {
  const getInv = await smartTryCatch(checklistService.getPagination, data, thunkAPI);
  return getInv;
});

export const exportChecklist = createAsyncThunk('exportChecklist', async (data, thunkAPI) => {
  const exportChecklistRes = await smartTryCatch(checklistService.exportChecklist, data, thunkAPI);
  return exportChecklistRes;
});

export const importChecklist = createAsyncThunk('importChecklist', async (data, thunkAPI) => {
  const importChecklistRes = await smartTryCatch(checklistService.importChecklist, data, thunkAPI);
  return importChecklistRes;
});

export const checklistSlice = createSlice({
  name: 'checklists',
  initialState: initState,
  reducers: {
    setChecklist: (state, action) => {
      state.checklist = action.payload;
    },
    openChecklistModalDelete: (state) => {
      state.checklistModalDelete = true;
    },
    closeChecklistModalDelete: (state) => {
      state.checklistModalDelete = false;
    },
    openChecklistModalUpdate: (state) => {
      state.checklistModalUpdate = true;
    },
    closeChecklistModalUpdate: (state) => {
      state.checklistModalUpdate = false;
    },
    openChecklistModalCreate: (state) => {
      state.checklistModalCreate = true;
    },
    closeChecklistModalCreate: (state) => {
      state.checklistModalCreate = false;
    },
    setChecklistTitle: (state, action) => {
      state.checklistTitle = action.payload;
    },
    setChecklistIsTemplate: (state, action) => {
      state.checklistIsTemplate = action.payload;
    },
    setChecklistItems: (state, action) => {
      state.checklistItems.push(action.payload);
    },
    setChecklistItemCount: (state, action) => {
      state.checklistItemCount = action.payload;
    },
    setChecklistItemNumber: (state) => {
      state.isItemNumber = true;
      state.isItemText = false;
    },
    setChecklistItemText: (state) => {
      state.isItemNumber = false;
      state.isItemText = true;
    },
    setSearchValue:(state , action)=>{
      state.searchValue = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowPerPage: (state, action) => {
      state.rowPerPage = action.payload;
    },
    reset: (state) => {
      state.isCreateChecklistError = false;
      state.isCreateChecklistSuccess = false;
      state.isCreateChecklistLoading = false;
      state.isGetChecklistError = false;
      state.isGetChecklistSuccess = false;
      state.isGetChecklistLoading = false;
      state.isDeleteChecklistError = false;
      state.isDeleteChecklistSuccess = false;
      state.isDeleteChecklistLoading = false;
      state.isUpdateChecklistError = false;
      state.isUpdateChecklistSuccess = false;
      state.isUpdateChecklistLoading = false;
      state.isChecklistImported = false;
      state.isChecklistImportedLoading = false;
      state.isChecklistImportedError = false;
      state.checklist = null;
      state.fileExport = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChecklistByAuthentication.pending, (state) => {
        state.isGetChecklistError = false;
        state.isGetChecklistSuccess = false;
        state.isGetChecklistLoading = true;
      })
      .addCase(getChecklistByAuthentication.fulfilled, (state, action) => {
        state.isGetChecklistError = false;
        state.isGetChecklistSuccess = true;
        state.isGetChecklistLoading = false;
        state.checklists = action.payload;
      })
      .addCase(getChecklistByAuthentication.rejected, (state) => {
        state.isGetChecklistError = true;
        state.isGetChecklistSuccess = false;
        state.isGetChecklistLoading = false;
      })
      .addCase(getPagination.pending, (state) => {
        state.isGetChecklistError = false;
        state.isGetChecklistSuccess = false;
        state.isGetChecklistLoading = true;
      })
      .addCase(getPagination.fulfilled, (state, action) => {
        state.isGetChecklistError = false;
        state.isGetChecklistSuccess = true;
        state.isGetChecklistLoading = false;
        state.checklists = action.payload;
      })
      .addCase(getPagination.rejected, (state) => {
        state.isGetChecklistError = true;
        state.isGetChecklistSuccess = false;
        state.isGetChecklistLoading = false;
      })
      .addCase(getChecklistById.pending, (state) => {
        state.isGetChecklistError = false;
        state.isGetChecklistSuccess = false;
        state.isGetChecklistLoading = true;
      })
      .addCase(getChecklistById.fulfilled, (state, action) => {
        state.isGetChecklistError = false;
        state.isGetChecklistSuccess = true;
        state.isGetChecklistLoading = false;
        state.checklist = action.payload;
      })
      .addCase(getChecklistById.rejected, (state) => {
        state.isGetChecklistError = true;
        state.isGetChecklistSuccess = false;
        state.isGetChecklistLoading = false;
      })
      .addCase(createChecklist.pending, (state) => {
        state.isCreateChecklistError = false;
        state.isCreateChecklistSuccess = false;
        state.isCreateChecklistLoading = true;
      })
      .addCase(createChecklist.fulfilled, (state, action) => {
        state.isCreateChecklistError = false;
        state.isCreateChecklistSuccess = true;
        state.isCreateChecklistLoading = false;
        state.checklists.results= [action.payload , ...state.checklists.results];
      })
      .addCase(createChecklist.rejected, (state) => {
        state.isCreateChecklistError = true;
        state.isCreateChecklistSuccess = false;
        state.isCreateChecklistLoading = false;
      })
      .addCase(deleteChecklist.pending, (state) => {
        state.isDeleteChecklistError = false;
        state.isDeleteChecklistSuccess = false;
        state.isDeleteChecklistLoading = true;
      })
      .addCase(deleteChecklist.fulfilled, (state, action) => {
        state.isDeleteChecklistError = false;
        state.isDeleteChecklistSuccess = true;
        state.isDeleteChecklistLoading = false;
        state.checklists.results = state.checklists?.results.filter((checklist) => checklist.id !== action.payload);
      })
      .addCase(deleteChecklist.rejected, (state) => {
        state.isDeleteChecklistError = true;
        state.isDeleteChecklistSuccess = false;
        state.isDeleteChecklistLoading = false;
      })
      .addCase(deleteBulkChecklist.pending, (state) => {
        state.isDeleteChecklistError = false;
        state.isDeleteChecklistSuccess = false;
        state.isDeleteChecklistLoading = true;
      })
      .addCase(deleteBulkChecklist.fulfilled, (state, action) => {
        state.isDeleteChecklistError = false;
        state.isDeleteChecklistSuccess = true;
        state.isDeleteChecklistLoading = false;
        state.checklists.results = state.checklists.results.filter(item => !action.payload.ids.includes(item.id))
      })
      .addCase(deleteBulkChecklist.rejected, (state) => {
        state.isDeleteChecklistError = true;
        state.isDeleteChecklistSuccess = false;
        state.isDeleteChecklistLoading = false;
      })
      .addCase(updateChecklist.pending, (state) => {
        state.isUpdateChecklistError = false;
        state.isUpdateChecklistSuccess = false;
        state.isUpdateChecklistLoading = true;
      })
      .addCase(updateChecklist.fulfilled, (state, action) => {
        state.isUpdateChecklistError = false;
        state.isUpdateChecklistSuccess = true;
        state.isUpdateChecklistLoading = false;
        state.checklists.results = state.checklists.results.map((checklist) =>
          checklist.id === action.payload.id ? action.payload : checklist,
        );
      })
      .addCase(updateChecklist.rejected, (state) => {
        state.isUpdateChecklistError = true;
        state.isUpdateChecklistSuccess = false;
        state.isUpdateChecklistLoading = false;
      }).addCase(exportChecklist.pending, state => {
        state.isChecklistExported = false;
      })
      .addCase(exportChecklist.fulfilled, (state, action) => {
        state.isChecklistExported = true;
        state.fileExport = action.payload;
      })
      .addCase(exportChecklist.rejected, state => {
        state.isChecklistExported = false;
      }).addCase(importChecklist.pending, state => {
        state.isChecklistImported = false;
        state.isChecklistImportedLoading = true;
        state.isChecklistImportedError = false;
      })
      .addCase(importChecklist.fulfilled, (state, action) => {
        state.isChecklistImportedLoading = false;
        state.isChecklistImported = true;
        state.isChecklistImportedError = false;
        state.fileImport = action.payload;
      })
      .addCase(importChecklist.rejected, state => {
        state.isChecklistImported = false;
        state.isChecklistImportedLoading = false;
        state.isChecklistImportedError = true;
      })
  },
});

export const {
  setChecklist,
  openChecklistModalDelete,
  closeChecklistModalDelete,
  openChecklistModalUpdate,
  closeChecklistModalUpdate,
  openChecklistModalCreate,
  closeChecklistModalCreate,
  setChecklistIsTemplate,
  setChecklistTitle,
  setChecklistItems,
  setChecklistItemCount,
  setChecklistItemNumber,
  setChecklistItemText,
  setSearchValue,
  reset,
  setCurrentPage,
  setRowPerPage 
} = checklistSlice.actions;

export default checklistSlice.reducer;
