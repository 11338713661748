/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import attchmentIcon from "../../assets/img/attachmentIcon.png"
import fileIcon from "../../assets/img/fileIcon.png"
import closeIcon from "../../assets/img/closeIcon.png"
import PopUpdeleteCheckList from './popUpDeleteCheckList';
import {
  updateChecklist,
  deleteChecklist,
} from '../../redux/features/checklist/checklistSlice';
import './ChecklistUpdateModal.scss';
import AttachmentIcon from '../../assets/svg-icons/AttachmentIcon';
import FileIcon from '../../assets/svg-icons/FileIcon';

export default function ChecklistUpdateModal({ show, close }) {
  const [popUp, setPopUp] = useState(false);
  const { checklist } = useSelector(state => state.checklists);
  const [ranges, setRanges] = useState([]);
  const [title, setName] = useState('');
  const [isTemplate, setTemplate] = useState(false);
  useEffect(() => {
    setRanges(checklist?.items || []);
    setName(checklist?.title);
    setTemplate(checklist?.setTemplate);
  }, [checklist, show]);

  const addRange = (e, kind) => {
    e.preventDefault();

    if (kind === 'Number') {
      setRanges([
        ...ranges,

        {
          name: '',
          min: '',
          max: '',
          matric: '',
          key: 0,
          result: '',
          type: kind,
        },
      ]);
    } else if (kind === 'Text') {
      setRanges([
        ...ranges,

        {
          name: '',
          min: '',
          max: '',
          matric: '',
          key: 0,
          result: '',
          type: kind,
        },
      ]);
    }
  };

  const removeInputFields = data => {
    const newData = [...ranges]
    newData.splice(data, 1)
    setRanges(newData);
  };
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = JSON.parse(JSON.stringify(ranges));
    list[index][name] = value;
    setRanges(list);
  };

  const dispatch = useDispatch();
  const {
    checklistModalCreate,
    //  checklistTitle,
    //  checklist
  } = useSelector(state => state.checklists);

  const handleCreateChecklist = () => {
    const data = {
      title: title,
      is_template: isTemplate,
      items: ranges,
    };
    const updatedDta = {
      id: checklist.id,
      userdata: data,
    };
    dispatch(updateChecklist(updatedDta));
    close();
  };
  const handleCloseDelte = () => {
    setPopUp(false);
  };
  const handleDeleteTeam = () => {
    dispatch(deleteChecklist(checklist?.id));
    setPopUp(false);
  };
  return (
    <>
      <PopUpdeleteCheckList
        showModal={popUp}
        handleClose={handleCloseDelte}
        handleSave={handleDeleteTeam}
      />
      <Drawer anchor="right"
        open={show}
        onClose={close}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: '600px',
            height: '100%',
          },
        }}
      >
        <form
          action={checklistModalCreate ? '/checklists' : '/checklists'}
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleCreateChecklist}
        >
          <div className="col-lg-12 col-sm-12 pb-5" id="">
            <div className="navbar sticky-top w-100 m-0 modal-header-background page-profile-header pr-0 pt-3">
              <div className="col-lg-12 col-sm-12 row" style={{ padding: "0px 10px 0px 0px", margin: "0px" }}>
                <div className="col-12 row"
                  style={{
                    padding: "0px", margin: "0px",
                    display: "flex", justifyContent: "space-between", alignItems: "center"
                  }}>
                  <div className="my-auto">
                    <div style={{ fontSize: "22px", fontWeight: "600", marginBottom: "16px" }}>Checklist preview</div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="new-btn btn"
                      style={{ padding: "13px", background: "white", marginRight: "0px" }}
                      onClick={close}
                    >
                      {/* <div className="fas fa-times" /> */}
                      <img src={closeIcon} alt="close" />
                    </button>
                  </div>
                </div>
                <div className='checklist-preview-title' style={{ fontWeight: "600", fontSize: "14px" }}>Checklist</div>
              </div>
            </div>
            <div className="card-box mx-auto row col-lg-12 pb-5" id="CL-view">
              <div className='checklist-header'>
                <div className='mt-4'>
                  <div style={{ fontWeight: "600" }}>Checklist</div>
                  <p>1 Checklist issued</p>
                </div>
                <button type="button" className='new-btn' style={{ fontWeight: "600", fontSize: "14px" }}>Edit Checklist</button>
              </div>
              <div style={{ width: '100%', marginBottom: "20px", marginTop: "20px" }}>
                <div style={{ marginBottom: "15px" }}>
                  <h5 style={{ fontSize: "16px", fontWeight: "600" }}>{title}</h5>
                </div>
                {ranges && ranges.length > 0 && ranges.map((data, index) => (
                  <div className='group-header adjust-group'>
                    <div className='group-name'>
                      {data.name}
                    </div>
                    <div className='checklist-select'>
                      <select disabled>
                        <option value="Status" selected>Status</option>
                      </select>
                    </div>
                    <div className='ml-4'>
                      <FileIcon gray />
                    </div>
                    <div className='ml-4'>
                      <AttachmentIcon gray />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </form>
      </Drawer>
    </>
  );
}
