/* eslint-disable react/prop-types */
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function PopUpActivateModel({
  showModal,
  handleClose,
  handleSave,
  // modalTitle,
  modelType,
  data
}) {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{modelType === 'True' ? 'Inactivate' : 'Activate'} {data.role}</Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="col">
          <div className="row">
            <div className="col-lg-12 col-sm-12 m-0">
              Are you sure you want to {modelType === 'True' ? 'Inactivate' : 'Activate'}
               this {data.role}?
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="danger" onClick={handleSave}>
          {modelType === 'True' ? 'Deactivate' : 'Activate'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PopUpActivateModel;
