/* eslint-disable no-shadow */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getInventoryCategoryByAuthentication,
  closeInventoryCategoryModalCreate,
  closeInventoryCategoryModalDelete,
  closeInventoryCategoryModalUpdate,
  createInventoryCategory,
  deleteInventoryCategory,
  openInventoryCategoryModalCreate,
  openInventoryCategoryModalUpdate,
  setInventory,
  updateInventoryCategory,
  reset,
} from '../../../redux/features/inventoryCategory/inventoryCategorySlice';
import PopUpDeleteModal from '../../popUpModals/editCategory/popUpDeleteModal';
import PopUpEditModal from '../../popUpModals/editCategory/popUpEditModal';
import PopUpAddModal from '../../popUpModals/editCategory/popUpAddModal';

export default function InventoriesTab () {
  const {
    inventories,
    inventoryCategoryModalDelete,
    inventoryCategoryModalUpdate,
    inventoryCategoryModalCreate,
    inventory,
    isInventoryCreateLoading,
    isInventoryCreateError,
    isInventoryCreateSuccess,
    isInventoryDeleteLoading,
    isInventoryDeleteError,
    isInventoryDeleteSuccess,
    isInventoryUpdateLoading,
    isInventoryUpdateError,
    isInventoryUpdateSuccess,
    isInventoryGetLoading,
    isInventoryGetError,
  } = useSelector(state => state.inventoryCategory);
  const dispatch = useDispatch();
  const [inventoryData, setInventoryData] = useState(null);

  const handleDelete = id => {
    dispatch(deleteInventoryCategory(id));
    dispatch(closeInventoryCategoryModalDelete());
  };
  const handleEdit = data => {
    dispatch(openInventoryCategoryModalUpdate());
    dispatch(setInventory(data.id));
    setInventoryData(data);
  };

  const handleEditSave = () => {
    dispatch(
      updateInventoryCategory({
        id: inventory,
        name: inventoryData.name,
      }),
    );
    dispatch(closeInventoryCategoryModalUpdate());
  };

  const handleCreateSave = () => {
    dispatch(createInventoryCategory({ name: inventoryData.name }));
    dispatch(closeInventoryCategoryModalCreate());
    dispatch(reset());
  };

  useEffect(() => {
    dispatch(reset());
  }, [
    inventories,

    inventory,
    isInventoryCreateLoading,
    isInventoryCreateError,
    isInventoryCreateSuccess,
    isInventoryDeleteLoading,
    isInventoryDeleteError,
    isInventoryDeleteSuccess,
    isInventoryUpdateLoading,
    isInventoryUpdateError,
    isInventoryUpdateSuccess,
    isInventoryGetLoading,
    isInventoryGetError,
  ]);
  // const handleConfirm = (id) => {
  //   dispatch(openInventoryCategoryModalDelete());
  //   dispatch(setInventory(id));
  // };
  function displayToast () {
    if (isInventoryCreateSuccess) {
      toast.success('Inventory created successfully', {
        toastId: 'inventoryCreateSuccess',
      });
    }
    if (isInventoryDeleteSuccess) {
      toast.success('Inventory deleted successfully', {
        toastId: 'inventoryDeleteSuccess',
      });
    }
    if (isInventoryUpdateSuccess) {
      toast.success('Inventory updated successfully', {
        toastId: 'inventoryUpdateSuccess',
      });
    }
    if (isInventoryGetError) {
      toast.error('Error getting inventory', {
        toastId: 'inventoryGetError',
      });
    }
    if (isInventoryCreateError) {
      toast.error('Error creating inventory', {
        toastId: 'inventoryCreateError',
      });
    }
    if (isInventoryDeleteError) {
      toast.error('Error deleting inventory', {
        toastId: 'inventoryDeleteError',
      });
    }
    if (isInventoryUpdateError) {
      toast.error('Error updating inventory', {
        toastId: 'inventoryUpdateError',
      });
    }
    if (isInventoryGetError) {
      toast.error('Error getting inventory', {
        toastId: 'inventoryGetError',
      });
    }
  }

  useEffect(() => {
    dispatch(getInventoryCategoryByAuthentication());
    dispatch(reset());
  }, [dispatch]);
  return isInventoryCreateLoading ||
    isInventoryDeleteLoading ||
    isInventoryUpdateLoading ||
    isInventoryGetLoading ? (
    <div id="loader" />
  ) : (
    <>
      {displayToast()}
      {inventoryCategoryModalUpdate && (
        <PopUpEditModal
          handleClose={() => dispatch(closeInventoryCategoryModalUpdate())}
          showModal={inventoryCategoryModalUpdate}
          handleSave={handleEditSave}
          modalTitle="Edit Inventory"
          primaryButtonText="Save Changes"
          modalBodyTextInput={inventoryData.name}
          value={inventoryData && inventoryData.name}
          onChange={e => {
            setInventoryData({
              ...inventoryData,
              name: e.target.value,
            });
          }}
        />
      )}
      {inventoryCategoryModalDelete && (
        <PopUpDeleteModal
          showModal={inventoryCategoryModalDelete}
          handleClose={() => dispatch(closeInventoryCategoryModalDelete())}
          handleConfirm={handleDelete}
          modalTitle="Delete Inventory"
          modalBodyText="Are you sure you want to delete this inventory?"
        />
      )}

      {inventoryCategoryModalCreate && (
        <PopUpAddModal
          showModal={inventoryCategoryModalCreate}
          handleClose={() => dispatch(closeInventoryCategoryModalCreate())}
          modalTitle="Add Inventory"
          primaryButtonText="Create Inventory"
          value={inventoryData && inventoryData.name}
          onChange={e => {
            setInventoryData({
              ...inventoryData,
              name: e.target.value,
            });
          }}
          handleCreateSave={handleCreateSave}
        >
          <div className="col">
            <div className="row">
              <input type="hidden" id="inventory_id" value="" />
              <span className="font-weight-normal">
                Name <span className="font-danger m-0">*</span>
              </span>
              <input
                name="inventory_name"
                type="text"
                className="form-control mb-2"
                placeholder="Enter inventory name"
                // value={inventoryData?.name}
                onChange={e =>
                  setInventoryData({
                    ...inventoryData,
                    name: e.target.value,
                  })
                }
              />
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_name_error" />
              </div>
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_error_msg" />
              </div>
            </div>
          </div>
        </PopUpAddModal>
      )}
      <h3 className="card-title py-3 px-3 col-lg-12">Inventories Fieldset</h3>
      <div className="card-body p-0 m-0" id="location-block">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title edit-category-title-inline">
                  <span>
                    <b>Categories</b>
                    <i>(Option select)</i>
                  </span>
                </span>
                <div className="card-tools">
                  <button
                    type="button"
                    className="font-danger cursor_pointer"
                    data-card-widget="collapse"
                    onClick={() => dispatch(openInventoryCategoryModalCreate())}
                  >
                    Add Category
                  </button>
                </div>
              </div>
              <div className="card-body p-0" id="spareparts-category-block">
                {inventories && inventories.length > 0 ? (
                  inventories?.map(inventory => (
                    <div
                      className="card edit-category-card-inline"
                      id="spareparts-category-block-{{spareparts_category.id}}"
                    >
                      <div className="card-header">
                        <span className="card-title edit-category-card-title-inline">
                          <span
                            title="{{spareparts_category.category}}"
                            data-target="#modal-add-spareparts-category"
                            data-toggle="modal"
                          >
                            <b id="spareparts-category-value-{{spareparts_category.id}}">
                              {inventory?.name}
                            </b>
                          </span>
                        </span>
                        <div className="card-tools">
                          <button
                            type="button"
                            onClick={() => {
                              handleEdit(inventory);
                            }}
                            className="fa fa-edit font-danger cursor_pointer"
                          >
                            {' '}
                          </button>
                          <button
                            type="button"
                            className="btn btn-tool"
                            onClick={() => {
                              handleDelete(inventory?.id);
                            }}
                          >
                            <i
                              className="fa fa-trash m-1 font-danger cursor_pointer"
                              title="Delete Category"
                              data-target="#modal-delete-spareparts-category"
                              data-toggle="modal"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="card edit-category-card-inline">
                    <div className="card-header">
                      <span className="card-title edit-category-card-title-inline">
                        <span
                          title="No Category"
                          data-target="#modal-add-spareparts-category"
                          data-toggle="modal"
                        >
                          <b id="spareparts-category-value-">No Category</b>
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                <input type="hidden" value="{{spareparts_category.category}}" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
