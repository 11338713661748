import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "./epq.css"
// import PlusIcon from '../../assets/svg-icons/PlusIcon';
import BillItem from './billItem';
import { setBillTotalPrice } from '../../redux/features/SOR/SORSlice';
import Tooltip from '../../components/tooltip/tooltip';

/* eslint-disable no-unsafe-optional-chaining */

export default function BillsItems({ pdf }) {
    const dispatch = useDispatch()
    const { billData, billTotalPrice } = useSelector(state => state.SOR)

    useEffect(() => {
        // Calculate total
        const totalSum = billData?.reduce((sum, item) => {
            return sum + parseFloat(item.totalPrice);
        }, 0);

        // Calculate total
        const SumVendorTotalPrice = billData?.reduce((sum, item) => {
            return sum + parseFloat(item.vendorTotalPrice);
        }, 0);

        dispatch(setBillTotalPrice(
            {
                billTotalOrder: Number(totalSum.toFixed(2)),
                billTotalVendor: Number(SumVendorTotalPrice.toFixed(2)),
            }
        ))
    }, [billData])

    return (
        <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <h5 className='mb-0'>Bills</h5>
                {/* <div className='choose-assets-location'>
                    <button type='button'>
                        <span className='mr-2'><PlusIcon color="#D9314A" /> </span> Add Item
                    </button>
                </div> */}
            </div >
            <table className='bills-table' style={{ width: "100%", padding: "16px", borderCollapse: "collapse" }}>
                <thead>
                    <tr>
                        <td style={{ borderBottom: "1px solid #72747814" }} />
                        <td style={{ borderBottom: "1px solid #72747814" }} />
                        <td style={{ borderBottom: "1px solid #72747814" }} />
                        <td style={{ borderBottom: "1px solid #72747814" }} />
                        <td style={{ borderLeft: "1px solid #72747814", borderBottom: "1px solid #72747814" }}>
                            Purchase order/ <br />
                            <span style={{ color: "#727478" }}>Pesanan belian</span>
                        </td>
                        <td style={{ borderBottom: "1px solid #72747814" }} />
                        <td style={{ borderBottom: "1px solid #72747814" }} />
                        <td style={{ borderLeft: "1px solid #72747814", borderBottom: "1px solid #72747814" }} >
                            Vendor claim/ <br />
                            <span style={{ color: "#727478" }}>Tuntutan kontraktor</span>
                        </td>
                        <td style={{ borderBottom: "1px solid #72747814" }} />
                        <td style={{ borderBottom: "1px solid #72747814" }} />

                    </tr>
                    <tr>
                        <td>
                            Code/ <br />
                            <span style={{ color: "#727478" }}>Kod</span>
                        </td>
                        <td style={{ width: "300px" }}>
                            Work description/ <br />
                            <span style={{ color: "#727478" }}>Keterangan kerja</span>
                        </td>
                        <td>
                            Discount(%)/ <br />
                            <span style={{ color: "#727478" }}>Diskaun(%)</span>
                        </td>
                        <td>
                            Unit
                        </td>
                        <td style={{ borderLeft: "1px solid #72747814" }}>
                            Rate <br />
                            <span style={{ color: "#727478" }}>Kadar</span>
                        </td>
                        <td>
                            Quantity <br />
                            <span style={{ color: "#727478" }}>Kuantiti</span>
                        </td>
                        <td>
                            Total price/ <br />
                            <span style={{ color: "#727478" }}>Jumlah harga</span>
                        </td>
                        <td style={{ borderLeft: "1px solid #72747814" }}>
                            Quantity <br />
                            <span style={{ color: "#727478" }}>Kuantiti</span>
                        </td>
                        <td>
                            Total price/ <br />
                            <span style={{ color: "#727478" }}>Jumlah harga</span>
                        </td>
                        <td />
                    </tr>
                </thead>
                <tbody>
                    {billData?.map((item, i) => (
                        <BillItem item={item} index={i} key={item?.id} pdf={pdf} />
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="6" style={{ textAlign: "right", fontWeight: 600 }}>
                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                {!pdf && <Tooltip color="black" content={<span>Total bills = Sum (total price)</span>} />}
                                <span className='ml-2'>Total bills</span>
                            </div>
                        </td>
                        <td style={{ fontWeight: 700 }}>RM{billTotalPrice?.billTotalOrder}</td>
                        <td />
                        <td style={{ fontWeight: 700 }}>RM{billTotalPrice?.billTotalVendor}</td>
                        <td />
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}
