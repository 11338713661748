import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TooltipIcon from '../../assets/svg-icons/TooltipIcon';
import { setPmSchedule } from '../../redux/features/maintenance/maintenanceSlice';


function PMScheduleTabs() {
  const dispatch = useDispatch()
  const
    {
      pmSchedule
    } = useSelector(state => state.maintenance)
  return (
    <div className="pm-schedule-tabs mb-3 mt-3">
      <button type='button' className={pmSchedule === "PM ticket" ? "un-active" : ""}  onClick={()=> dispatch(setPmSchedule("PM Schedule"))}>
        PM Schedule <span><TooltipIcon /></span>
      </button>
      <button type='button' className={pmSchedule === "PM Schedule" ? "un-active" : ""} onClick={()=> dispatch(setPmSchedule("PM ticket"))}>
        PM ticket <span><TooltipIcon /></span>
      </button>
    </div>
  );
}

export default PMScheduleTabs;
