import React from 'react'
/* eslint-disable no-unneeded-ternary  */

function PlusIcon({ color, custom }) {
    return (
        custom ? <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 1V17M17 9L1 9"
                stroke={color ? color : "#36383C"}
                strokeWidth="1.75"
                strokeLinecap="round" />
        </svg> :
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.0013 1.6665V12.3332M12.3346 6.99984L1.66797 6.99984"
                    stroke={color ? color : "#36383C"}
                    strokeWidth="2"
                    strokeLinecap="round" />
            </svg>
    )
}

export default PlusIcon