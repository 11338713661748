/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */


import React from 'react'
import Schedule from '../Schedule'

function PMSchedule(
    {
        selectedDays,
        selectedDay,
        selectTheDay,
        selectTheMonth
    }
) {
    return (
        <div id="schedule-tab-edit" role="tabpanel" aria-labelledby="custom-tabs-two-schedule-tab">
            <div className="card-body ">
                <Schedule
                    editPM
                    selectedDays={selectedDays}
                    selectedDay={selectedDay}
                    selectTheDay={selectTheDay}
                    selectTheMonth={selectTheMonth}
                />
            </div>
        </div>
    )
}

export default PMSchedule