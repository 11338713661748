import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ticketCommentService  from './ticketCommentService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // Ticket Log
  ticketComments :[],
  ticketComment : {},
  summary :{},


  // success states
  isTicketCommentGetSuccess: false,
  isTicketCommentCreateSuccess: false,
  isTicketCommentDeleteSuccess: false,
  isTicketCommentUpdateSuccess: false,
 

  // loading states
  isTicketCommentCreateLoading: false,
  isTicketCommentGetLoading: false,
  isTicketCommentsGetLoading: false,
  isTicketCommentDeleteLoading: false,
  isTicketCommentUpdateLoading: false,


  // error states
  isTicketCommentGetError: false,
  isTicketCommentCreateError: false,
  isTicketCommentDeleteError: false,
  isTicketCommentUpdateError: false,


};

export const createTicketComment = createAsyncThunk('createTicketComment', async (data, thunkAPI) => {
  const createTicketCommentRes = await smartTryCatch(ticketCommentService.createTicketComment, data, thunkAPI);
  return createTicketCommentRes;
});

export const getTicketCommentsByAuthentication = createAsyncThunk(
  'getTicketCommentsByAuthentication',
  async (data, thunkAPI) => {
    const getTicketComments = await smartTryCatch(
      ticketCommentService.getTicketCommentsByAuthentication,
      data,
      thunkAPI,
    );
    return getTicketComments;
  },
);

export const getTicketCommentById = createAsyncThunk('getTicketCommentById', async (data, thunkAPI) => {
  const getTicketComment = await smartTryCatch(ticketCommentService.getTicketCommentById, data, thunkAPI);
  return getTicketComment;
});


export const deleteTicketComment = createAsyncThunk('deleteTicketComment', async (data, thunkAPI) => {
  const deleteTicketCommentRes = await smartTryCatch(ticketCommentService.deleteTicketComment, data, thunkAPI);
  return deleteTicketCommentRes;
});

export const updateTicketComment = createAsyncThunk('updateTicketComment', async (data, thunkAPI) => {
  const updateTicketCommentRes = await smartTryCatch(ticketCommentService.updateTicketComment, data, thunkAPI);
  return updateTicketCommentRes;
});


export const ticketCommentSlice = createSlice({
  name: 'ticketComment',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isTicketCommentCreateError = false;
      state.isTicketCommentCreateSuccess = false;
      state.isTicketCommentCreateLoading = false;
      state.isTicketCommentGetError = false;
      state.isTicketCommentGetSuccess = false;
      state.isTicketCommentGetLoading = false;
      state.isTicketCommentsGetLoading = false;
      state.isTicketCommentDeleteError = false;
      state.isTicketCommentDeleteSuccess = false;
      state.isTicketCommentDeleteLoading = false;
      state.isTicketCommentUpdateError = false;
      state.isTicketCommentUpdateSuccess = false;
      state.isTicketCommentUpdateLoading = false;
      // state.ticketComment = null;
    },
   
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTicketComment.pending, (state) => {
        state.isTicketCommentCreateLoading = true;
        state.isTicketCommentCreateSuccess = false;
        state.isTicketCommentCreateError = false;
      })
      .addCase(createTicketComment.fulfilled, (state, action) => {
        state.isTicketCommentCreateLoading = false;
        state.isTicketCommentCreateSuccess = true;
        state.isTicketCommentCreateError = false;
        state.ticketComments = [action.payload, ...state.ticketComments];
      })
      .addCase(createTicketComment.rejected, (state) => {
        state.isTicketCommentCreateLoading = false;
        state.isTicketCommentCreateSuccess = false;
        state.isTicketCommentCreateError = true;
      })
      .addCase(getTicketCommentsByAuthentication.pending, (state) => {
        state.isTicketCommentsGetLoading = true;
        state.isTicketCommentGetSuccess = false;
        state.isTicketCommentGetError = false;
      })
      .addCase(getTicketCommentsByAuthentication.fulfilled, (state, action) => {
        state.isTicketCommentsGetLoading = false;
        state.isTicketCommentGetSuccess = true;
        state.isTicketCommentGetError = false;
        state.ticketComments = action.payload;
      })
      .addCase(getTicketCommentsByAuthentication.rejected, (state) => {
        state.isTicketCommentsGetLoading = false;
        state.isTicketCommentGetSuccess = false;
        state.isTicketCommentGetError = true;
      })
      .addCase(getTicketCommentById.pending, (state) => {
        state.isTicketCommentGetLoading = true;
        state.isTicketCommentGetSuccess = false;
        state.isTicketCommentGetError = false;
      })
      .addCase(getTicketCommentById.fulfilled, (state, action) => {
        state.isTicketCommentGetLoading = false;
        state.isTicketCommentGetSuccess = true;
        state.isTicketCommentGetError = false;
        state.ticketComment = action.payload;
      })
      .addCase(getTicketCommentById.rejected, (state) => {
        state.isTicketCommentGetLoading = false;
        state.isTicketCommentGetSuccess = false;
        state.isTicketCommentGetError = true;
      })
      .addCase(deleteTicketComment.pending, (state) => {
        state.isTicketCommentDeleteLoading = true;
        state.isTicketCommentDeleteSuccess = false;
        state.isTicketCommentDeleteError = false;
      })
      .addCase(deleteTicketComment.fulfilled, (state, action) => {
        state.isTicketCommentDeleteLoading = false;
        state.isTicketCommentDeleteSuccess = true;
        state.isTicketCommentDeleteError = false;
        state.ticketComments = 
        state.ticketComments.filter((ticketComment) => ticketComment.id !== action.payload);
      })
      .addCase(deleteTicketComment.rejected, (state) => {
        state.isTicketCommentDeleteLoading = false;
        state.isTicketCommentDeleteSuccess = false;
        state.isTicketCommentDeleteError = true;
      })
      .addCase(updateTicketComment.pending, (state) => {
        state.isTicketCommentUpdateLoading = true;
        state.isTicketCommentUpdateSuccess = false;
        state.isTicketCommentUpdateError = false;
      })
      .addCase(updateTicketComment.fulfilled, (state, action) => {
        state.isTicketCommentUpdateLoading = false;
        state.isTicketCommentUpdateSuccess = true;
        state.isTicketCommentUpdateError = false;
        state.ticketComments = state.ticketComments?.map((ticketComment) =>
        ticketComment.id === action.payload.id ? action.payload : ticketComment,
        );
      })
      .addCase(updateTicketComment.rejected, (state) => {
        state.isTicketCommentUpdateLoading = false;
        state.isTicketCommentUpdateSuccess = false;
        state.isTicketCommentUpdateError = true;
      })
  
},
});

export const {
  reset,
} = ticketCommentSlice.actions;

export default ticketCommentSlice.reducer;
