/* eslint-disable import/no-cycle */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from './authService';
import smartTryCatch from '../../../utils/smartTryCatch';

// get user from local storage if it exists
const user = JSON.parse(localStorage.getItem('user'));

const initState = {
  user: user || null,
  isError: false,
  isSuccess: false,
  isConfirmLoading: false,
  isConfirmSuccess: false,
  isConfirmError: false,
  successAlternativeMessage: null,
  isCheckTokenLoading: false,
  isCheckTokenSuccess: false,
  isCheckTokenError: false,
  confirmPasswordData: null,
};

// login User
// async thunk takes route, then async function with params passed by func user
export const login = createAsyncThunk('login', async (data, thunkAPI) => smartTryCatch(authService.login, data, thunkAPI));

export const logout = createAsyncThunk('logout', async () => {
  await authService.logout();
});

export const changePassword = createAsyncThunk('change-password', async (data, thunkAPI) => smartTryCatch(authService.changePassword, data, thunkAPI));

export const confirmPassword = createAsyncThunk('confirm-password', async (data, thunkAPI) => smartTryCatch(authService.confirmPassword, data, thunkAPI));

export const forgotPassword = createAsyncThunk('forgot-password', async (data, thunkAPI) => smartTryCatch(authService.forgotPassword, data, thunkAPI));
export const resetPassword = createAsyncThunk('reset-password', async (data, thunkAPI) => smartTryCatch(authService.resetPassword, data, thunkAPI));
export const checkToken = createAsyncThunk('check-token', async (data, thunkAPI) => smartTryCatch(authService.checkToken, data, thunkAPI));
// export const confirmEmail = createAsyncThunk('confirm-email' , async(data , thunkAPI)=>smartTryCatch())
export const authSlice = createSlice({
  name: 'auth',
  initialState: initState,
  // reducers can not be async
  reducers: {
    resetAuth: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.isCheckTokenLoading = false;
      state.isCheckTokenSuccess = false;
      state.isCheckTokenError = false;
      state.isConfirmLoading = false;
      state.isConfirmSuccess = false;
      state.isConfirmError = false;
    },
    resetSuccessAlternativeMessage: (state) => {
      state.successAlternativeMessage = null;
    },
  },
  // extra reducers can be async
  extraReducers: (builder) => {
    builder
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;       
      })
      .addCase(login.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.user = null;
      })
      .addCase(changePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.successAlternativeMessage = action.payload;
      })
      .addCase(changePassword.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(confirmPassword.pending, (state) => {
        state.isConfirmLoading = true;
        state.isConfirmSuccess = true;
        state.isConfirmError = false;
      })
      .addCase(confirmPassword.fulfilled, (state) => {
        state.isConfirmLoading = false;
        state.isConfirmSuccess = true;
        state.isConfirmError = false;
      })
      .addCase(confirmPassword.rejected, (state, action) => {
        state.isConfirmLoading = false;
        state.isConfirmError = true;
        state.isConfirmSuccess = false;
        state.confirmPasswordData = action.payload;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.successAlternativeMessage = action.payload;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.successAlternativeMessage = action.payload;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(checkToken.pending, (state) => {
        state.isCheckTokenLoading = true;
        state.isCheckTokenSuccess = false;
        state.isCheckTokenError = false;
      })
      .addCase(checkToken.fulfilled, (state) => {
        state.isCheckTokenLoading = false;
        state.isCheckTokenSuccess = true;
        state.isCheckTokenError = false;
      })
      .addCase(checkToken.rejected, (state) => {
        state.isCheckTokenLoading = false;
        state.isCheckTokenSuccess = false;
        state.isCheckTokenError = true;
      });
  },
});

// exporting a singular function
export const { resetAuth, resetSuccessAlternativeMessage } = authSlice.actions;

export default authSlice.reducer;
