/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-useless-return */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable prefer-template */
/* eslint-disable dot-notation */
/* eslint-disable camelcase  */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useRef, useState } from 'react';
// import { BlobProvider } from '@react-pdf/renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationsByAuthentication } from '../../../redux/features/location/locationSlice';
import { getAssetCategoriesByAuthentication } from '../../../redux/features/assetCategory/assetCategorySlice';
import { getAssetStatusByAuthentication } from '../../../redux/features/assetStatus/assetStatusSlice';
import classes from '../addAsset/addAsset.module.css';
import {
  assetSearch,
  deleteAsset,
  deleteAssetInventory,
  deleteAttachment,
  deletePhotos,
  deleteSpecification,
  getAssetById,
  getAssetDepreciation,
  reset,
  setAsset,
  setAssetAssignedTo,
  setAssignedTo,
  updateAsset,
} from '../../../redux/features/asset/assetSlice';
import FetchAssetDetailsForm from './FetchAssetDetailsForm';
import FetchAssetFinanceForm from './FetchAssetFinanceForm';
import FetchAssetDocumentsForm from './FetchAssetDocumentsForm';
import FetchAssetPhotosForm from './FetchAssetPhotosForm';
import FetchAssetSpecificationsForm from './FetchAssetSpecificationsForm';
import FetchAssetSparePartsForm from './FetchAssetSparePartsForm';
import FetchAssetDepricationForm from './FetchAssetDepricationForm';
import { getTeamsByAuthentication } from '../../../redux/features/teams/teamSlice';
import PopUpDeleteModal from '../../../components/popUpModals/editCategory/popUpDeleteModal';
import { selectActions } from '../../../redux/features/selectOptions/selectSlice';
import Loader from '../../../components/loader/loader';
import { getInventorysByAuthentication } from '../../../redux/features/inventory/inventorySlice';
import { getCompanyProfile } from '../../../redux/features/compay/companySlice';
import PopUpAddErrorModal from '../../../components/popUpModals/editCategory/popUpAddErrorModal';
import altCompanyPic from '../../../assets/img/FOX_Logo2.png.webp';
import { getAssetTypesByAuthentication } from '../../../redux/features/assetType/assetTypeSlice';
import { getDepartmentsByAuthentication } from '../../../redux/features/department/departmentSlice';
import PrintAssetQr from './printAssetQr';
import AssetHistory from '../../../components/assetsTable/assetsHistory';
import formatNumber from '../../../utils/formatNumber';

const selectedForm = JSON.parse(localStorage.getItem('selectedForm'));
function FetchAsset() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { profile } = useSelector(state => state.company);
  const [info, setInfo] = useState({
    name: '',
    address: '',
    phone_number: '',
    website: '',
    logo: '',
  });
  const dispatchProfile = useDispatch();
  useEffect(() => {
    dispatchProfile(getCompanyProfile());
  }, []);
  useEffect(() => {
    setInfo({
      name: profile?.name,
      address: profile?.address,
      phone_number: profile?.phone_number,
      website: profile?.website,
      logo: profile?.logo !== null && profile?.logo !== undefined ? profile?.logo : info.logo,
    });
  }, [profile]);

  const [isShowList, setIsShowList] = useState(false);
  const history = useHistory();
  const [selected, setSlected] = useState('Asset Details');

  // asset details
  const [assetDetailsForm, setAssetDetailsForm] = useState({
    id: '',
    loan_eligibility: true,
    assetName: '',
    assetNumber: '',
    serialNumber: '',
    assetType: '',
    brand: '',
    custodian: '',
    description: '',
    department: null,
  });
  const componentRef = useRef();
  const componentRef1 = useRef();
  const handlePrintQr = useReactToPrint({
    content: () => componentRef.current,
  });
  const handlePrint = useReactToPrint({
    content: () => componentRef1.current,
  });
  const {
    isAssetUpdateError,
    isAssetUpdateSuccess,
    // isAssetGetSuccess,
    isAssetGetLoading,
    isAssetDeleteSuccess,
    isAssetDeleteError,
  } = useSelector(state => state.asset);

  const generateAssetNumber = () => {
    const random = Math.floor(Math.random() * 1000000000000);
    setAssetDetailsForm({ ...assetDetailsForm, assetNumber: random });
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteAssetHandler = () => {
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleConfirmDelete = async () => {
    await dispatch(deleteAsset(id));
    dispatch(reset());
  };

  useEffect(() => {
    if (isAssetDeleteSuccess) {
      setTimeout(() => {
        history.push('/assets');
      }, 1000);
    }
  }, [isAssetDeleteSuccess]);

  function displayToast() {
    if (isAssetDeleteError) {
      toast.error('Something went wrong Creating Asset', {
        toastId: 'DeletedAssetError',
      });
      dispatch(reset());
    } else if (isAssetDeleteSuccess) {
      toast.success(' Asset Deleted successfully', {
        toastId: 'Asset Deleted Success',
      });
    }
    if (isAssetUpdateError) {
      toast.error('Something went wrong Updating Asset', {
        toastId: 'UpdatedAssetError',
      });
      dispatch(reset());
    } else if (isAssetUpdateSuccess) {
      toast.success(' Asset Updated successfully', {
        toastId: 'Asset Updateed Successfully',
      });
    }
  }

  const {
    asset,
    // isAttachDeleteSuccess
    //  isSPDeleteSuccess
  } = useSelector(state => state.asset);

  const { assetCategories } = useSelector(state => state.assetCategory);
  const { assetStatuses } = useSelector(state => state.assetStatus);
  const { locations, sublocations } = useSelector(state => state.location);
  const {
    selectedSite,
    idOfSite,
    idOfLocation,
    idOfCategory,
    idOfStatus,
    // assignedTo,
    idOfAssetType,
  } = useSelector(state => state.selectOptions);
  const { assigned_to , assetAssignedTo } = useSelector(state => state.asset);

  const [assetFinanceForm, setAssetFinanceForm] = useState({
    purchased_price: '',
    purchased_date: '',
    vendor: '',
    warranty_expiration: '',
    po_number: '',
  });

  const [assetDocumentsFormFile, setAssetDocumentsFormFile] = useState(null);
  const [assetDocumentsFormFileType, setAssetDocumentsFormFileType] = useState('Manual');
  const [attachTable, setAttachTable] = useState([]);
  const [assetPhotosFormFile, setAssetPhotosFormFile] = useState(null);
  const [photosTable, setPhotosTable] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [isprimary, setIsprimary] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handelChangeDetailsForm = e => {
    setAssetDetailsForm({ ...assetDetailsForm, [e.target.name]: e.target.value });
  };
  const handleChangeLoanEligability = e => {
    setAssetDetailsForm({ ...assetDetailsForm, [e.target.name]: !assetDetailsForm.loan_eligibility });
  };

  const handelChangeFinanceForm = e => {
    if (e.target.name === "purchased_price") {
      const input = e.target;
      const {value} = input;
      const formattedValue = formatNumber(value)[0];
      setAssetFinanceForm({ ...assetFinanceForm, [e.target.name]: formattedValue });
    }else{
     setAssetFinanceForm({ ...assetFinanceForm, [e.target.name]: e.target.value });
    }
  };

  const handelChangeDocumentsFormFile = e => {
    setAssetDocumentsFormFile(e.target.files[0]);
  };
  const handelChangeDocumentsFormFileType = e => {
    setAssetDocumentsFormFileType(e.target.value);
  };

  const handelChangePhotosFormFile = e => {
    setAssetPhotosFormFile(e.target.files[0]);
    setPhoto(URL.createObjectURL(e.target.files[0]));
  };

  const handelChangePrimary = () => {
    setIsprimary(!isprimary);
  };

  const handleCreateSaveAttach = () => {
    if (assetDocumentsFormFile === null) {
      return;
    }
    setAttachTable([...attachTable, { assetDocumentsFormFile, assetDocumentsFormFileType }]);
    setAssetDocumentsFormFile(null);
    setShowModal(false);
  };

  const deleteAttach = async (id, i) => {
    const list = [...attachTable];
    await dispatch(deleteAttachment({ assetId: asset?.uuid, id }));
    list.splice(i, 1);
    setAttachTable(list);
  };

  const handleCreateSavePhoto = () => {
    if (assetPhotosFormFile === null && !isprimary) {
      return;
    } else if (assetPhotosFormFile !== null) {
      setPhotosTable([...photosTable, { assetPhotosFormFile, isprimary, photo }]);
      setAssetPhotosFormFile(null);
      setShowModal(false);
    }
  };
  const deletePhoto = async (id, i) => {
    const list = [...photosTable];
    await dispatch(deletePhotos({ assetId: asset?.uuid, id }));
    list.splice(i, 1);
    setPhotosTable(list);
  };

  // AssetSpecificationsForm
  const [inputSp, setInputSp] = useState({});
  const [inputList, setInputList] = useState([{ field: '', value: '' }]);

  const addInputHandler = () => {
    if (inputList.length === 0) {
      setInputList([...inputList, { field: '', value: '' }]);
    } else if (
      inputList.length > 0 &&
      inputList[inputList.length - 1].field !== '' &&
      inputList[inputList.length - 1].value !== ''
    ) {
      setInputList([...inputList, { field: '', value: '' }]);
    }
  };
  const removeInputHandler = async (id, i) => {
    await dispatch(deleteSpecification({ assetId: asset?.uuid, id }));
    const list = [...inputList];
    list.splice(i, 1);
    setInputList(list);
  };

  const handelSpChange = e => {
    setInputSp(e.target.value);
  };

  // Spare Parts
  const [showModalOfSpare, setShowModalOfSpare] = useState(false);
  const [sparePart, setSparePart] = useState([]);
  const [sparePartErr, setSparePartErr] = useState(null);
  const [spareTable, setSpareTable] = useState([]);
  const handleSaveSpare = () => {
    if (sparePart !== null) {
      setSparePartErr(false);
      setSpareTable(sparePart);
      setShowModalOfSpare(false);
    } else {
      setSparePartErr(true);
    }
  };

  const handelInventory = data => {
    setSparePartErr(false);
    if (sparePart?.length > 0) {
      const arrOfIds = [];
      sparePart.map(i => arrOfIds.push(i?.id));
      if (arrOfIds.includes(data?.id)) {
        setSparePart(sparePart.filter(i => i.id !== data?.id));
      } else {
        setSparePart([...sparePart, data]);
      }
    } else {
      setSparePart(sparePart?.concat(data));
    }
  };

  const deleteSpare = async (uuid, i) => {
    if (uuid) {
      await dispatch(deleteAssetInventory({ assetId: asset?.uuid, uuid }));
      setSparePart(sparePart.filter(d => d?.uuid !== uuid));
      setSpareTable(spareTable.filter(d => d?.uuid !== uuid));
    } else {
      const list = [...sparePart];
      list.splice(i, 1);
      setSparePart(list);
      setSpareTable(list);
    }
  };

  //  AssetDepricationForm
  const [enableDeprication, setEnableDeprication] = useState(false);
  const [assetDepricationForm, setAssetDepricationForm] = useState({
    salvage_price: null,
    service_life: null,
    start_date: '',
    filter: '',
  });
  const [chartData, setChartData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [viewAndEdit, setViewAndEdit] = useState(true);
  const handelEditDepricationChange = () => {
    setEnableDeprication(!enableDeprication);
  };
  const [showModalAddError, setShowModalAddError] = useState(false);
  const [errWithACtivateDeprication, setErrWithACtivateDeprication] = useState(false);

  const handelCloseModalAddError = () => {
    setShowModalAddError(false);
    setErrWithACtivateDeprication(false);
  };

  const handelChangeDepricationForm = e => {
    if (e.target.name === "salvage_price") {
      const input = e.target;
      const {value} = input;
      const formattedValue = formatNumber(value)[0];
      setAssetDepricationForm({ ...assetDepricationForm, [e.target.name]: formattedValue });
    }else{
      setAssetDepricationForm({ ...assetDepricationForm, [e.target.name]: e.target.value });
    }
  };

  const assetDepreciation = useSelector(state => state.asset?.assetDepreciation);

  useEffect(() => {
    if (assetDepreciation) {
      setSelectedOption(
        (assetDepreciation?.filter_data?.year && 'year') ||
        (assetDepreciation?.filter_data?.month && 'month') ||
        (assetDepreciation?.filter_data?.week && 'week'),
      );
    }
  }, [assetDepreciation]);
  useEffect(() => {
    if (selectedOption === 'month') {
      setChartData({
        labels: assetDepreciation?.asset_depreciation?.asset_depreciation_month?.map(ele =>
          moment(ele[0]).format('MMM YYYY'),
        ),
        datasets: [
          {
            label: 'Depreciation',
            data: assetDepreciation?.asset_depreciation?.asset_depreciation_month?.map(
              ele => ele[1],
            ),
            backgroundColor: 'rgba(248, 127, 145, 1)',
            borderColor: 'rgba(248, 127, 145, 0.9)',
            fill: true,
          },
        ],
      });
    } else if (selectedOption === 'week') {
      setChartData({
        labels: assetDepreciation?.asset_depreciation?.asset_depreciation_week?.map(ele =>
          moment(ele[0]).format('MMM Do YYYY'),
        ),
        datasets: [
          {
            label: 'Depreciation',
            data: assetDepreciation?.asset_depreciation?.asset_depreciation_week?.map(
              ele => ele[1],
            ),
            backgroundColor: 'rgba(248, 127, 145, 1)',
            borderColor: 'rgba(248, 127, 145, 0.9)',
            fill: true,
          },
        ],
      });
    } else if (selectedOption === 'year') {
      setChartData({
        labels: assetDepreciation?.asset_depreciation?.asset_depreciation_year?.map(ele =>
          moment(ele[0]).year(),
        ),
        datasets: [
          {
            label: 'Depreciation',
            data: assetDepreciation?.asset_depreciation?.asset_depreciation_year?.map(
              ele => ele[1],
            ),
            backgroundColor: 'rgba(248, 127, 145, 1)',
            borderColor: 'rgba(248, 127, 145, 0.9)',
            fill: true,
          },
        ],
      });
    }
  }, [selectedOption]);

  const handelFilter = e => {
    setSelectedOption(e.target.value);
  };

  const { user } = useSelector(state => state.user);
  const handelViewAndEdit = () => {
    setViewAndEdit(!viewAndEdit);
  };
  useEffect(() => {
    dispatch(selectActions.setIdOfSite());
    dispatch(getAssetById(id));
    dispatch(getLocationsByAuthentication());
    dispatch(getAssetCategoriesByAuthentication());
    dispatch(getAssetTypesByAuthentication());
    dispatch(getAssetStatusByAuthentication());
    dispatch(getTeamsByAuthentication());
    dispatch(getInventorysByAuthentication());
    dispatch(getDepartmentsByAuthentication());
  }, []);

  const { isAssetCheckoutGetSuccess, isAssetCheckinGetSuccess } = useSelector(state => state.assetLoanStatus);
  useEffect(() => {
    if (isAssetCheckoutGetSuccess || isAssetCheckinGetSuccess) {
      dispatch(getAssetById(id));
    }
  }, [isAssetCheckoutGetSuccess, isAssetCheckinGetSuccess])


  useEffect(() => {
    const getAssetData = async () => {
      if (asset?.id) {
        setAssetDetailsForm({
          id: asset?.id || '',
          assetName: asset?.name || '',
          assetNumber: asset?.asset_number || '',
          serialNumber: asset?.serial_number || '',
          brand: asset?.brand || '',
          description: asset?.description || '',
          department: asset?.department[0]?.id || null,
          loan_eligibility: asset?.loan_eligibility || false,
        });
        dispatch(
          setAssignedTo(
            asset?.assigned_to?.length > 0
              ? asset?.assigned_to?.map(i => ({
                value: i?.id,
                label: `${i?.name} (${i?.role === 'CliAdm' ? 'Admin' : i?.role})`,
                image: i?.image,
              }))
              : [],
          ),
        );
        dispatch(selectActions.setIdOfSite(asset?.location?.id || ''));
        dispatch(selectActions.setIdOfCategory(asset?.category?.id || ''));
        dispatch(selectActions.setIdOfStatus(asset?.status?.id || ''));
        dispatch(selectActions.setIdOfAssetType(asset?.asset_type?.id || ''));
        dispatch(selectActions.setIdOfAssignedTo(asset?.depreciation?.assigned_to || []));
        dispatch(selectActions.setIdOfLocation(asset?.sub_location?.id || ''));
        setAssetFinanceForm({
          purchased_price: asset?.finance?.purchased_price || '',
          purchased_date: moment(asset?.finance?.purchased_date).format('YYYY-MM-DD') || '',
          vendor: asset?.finance?.vendor || '',
          warranty_expiration:
            moment(asset?.finance?.warranty_expiration).format('YYYY-MM-DD') || '',
          po_number: asset?.finance?.po_number || '',
        });
        setEnableDeprication(asset?.show_depreciation || false);
        setSparePart(asset?.inventory);
        setSpareTable(asset?.inventory);
        setPhotosTable(asset?.photos || []);
        setAttachTable(asset?.attachments || []);
        setInputList(asset?.specifications || []);
        setInputSp(asset?.specifications?.field || ' ');
        setAssetDepricationForm({
          salvage_price: asset?.depreciation?.salvage_price || '',
          start_date: moment(asset?.depreciation?.start_date).format('YYYY-MM-DD') || '',
          service_life: asset?.depreciation?.service_life || '',
          filter: asset?.depreciation?.filter || '',
          assigned_to: asset?.depreciation?.assigned_to[0]?.id || '',
        });
        dispatch(
          setAssetAssignedTo(asset?.depreciation?.assigned_to?.length > 0 ? 
            asset?.depreciation?.assigned_to?.map((i) => ({
            value: i?.id,
            label: `${i?.name} (${i?.role === "CliAdm" ? "Admin" : i?.role })`,
            image: i?.image
          })) : [])
        )
        if (asset?.id) {
          dispatch(getAssetDepreciation(asset?.uuid));
          dispatch(assetSearch(asset?.name));
        }
      }
    };
    getAssetData();
  }, [asset]);

  const allSubLocations = [];

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const searchPramId = query.get('parent_id');

  const handelTabs = tab => {
    setSlected(tab);
  };
  const tabs = [
    'Asset Details',
    'Finance',
    'Documents',
    'Photos',
    'Specifications',
    'Spare Parts',
    ' Asset Depreciation',
    'History'
  ];

  useEffect(() => {
    localStorage.setItem('selectedForm', JSON.stringify(selected));
  }, [selected]);

  useEffect(() => {
    if (searchPramId) {
      tabs.slice(0, 5).map(tab => {
        if (selected === tab) {
          setSlected(selected);
        } else {
          setSlected('Asset Details');
        }
      });
    }
  }, [searchPramId]);

  const inventory = [];
  if (spareTable && spareTable?.length > 0) {
    spareTable?.forEach(i => {
      inventory.push(i?.id);
    });
  }
  const submitHandler = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', assetDetailsForm.assetName);
    formData.append('asset_number', assetDetailsForm.assetNumber);
    formData.append('serial_number', assetDetailsForm.serialNumber);
    formData.append('asset_type', idOfAssetType);
    formData.append('brand', assetDetailsForm.brand);
    formData.append('description', assetDetailsForm.description);
    formData.append('show_depreciation', enableDeprication);
    formData.append('location', idOfSite);
    formData.append('sub_location', idOfLocation || '');
    formData.append('category', idOfCategory);
    formData.append('status', idOfStatus);
    if (user.role === 'CliAdm') {
      formData.append('loan_eligibility', assetDetailsForm.loan_eligibility);
    }

    if (assetDetailsForm.department) {
      formData.append('department', assetDetailsForm.department);
    }
    formData.append('specifications.field', inputSp);
    if (assigned_to?.length > 0) {
      assigned_to?.forEach(ele => formData.append('assigned_to', ele?.value));
    }
    if (assetFinanceForm.purchased_price && assetFinanceForm.purchased_date) {
      formData.append('finance.purchased_price', String(assetFinanceForm.purchased_price));
      formData.append(
        'finance.purchased_date',
        assetFinanceForm.purchased_date !== 'Invalid date' && assetFinanceForm.purchased_date !== ''
          ? moment(assetFinanceForm.purchased_date).format()
          : '',
      );
      formData.append('finance.vendor', assetFinanceForm.vendor);
      formData.append(
        'finance.warranty_expiration',
        assetFinanceForm.warranty_expiration !== 'Invalid date' &&
          assetFinanceForm.purchased_date !== ''
          ? moment(assetFinanceForm.warranty_expiration).format()
          : '',
      );
      formData.append('finance.po_number', assetFinanceForm.po_number);
    }
    if (assetDepricationForm.filter !== '' && !searchPramId) {
      formData.append('depreciation.salvage_price', String(assetDepricationForm.salvage_price));
      formData.append(
        'depreciation.start_date',
        assetDepricationForm.start_date !== 'Invalid date' && assetDepricationForm.start_date !== ''
          ? moment(assetDepricationForm.start_date).format()
          : '',
      );
      formData.append('depreciation.service_life', assetDepricationForm.service_life);
      formData.append('depreciation.filter', assetDepricationForm.filter);
      // if (assignedTo.length > 0) {
      //   assignedTo.forEach(ele => formData.append('depreciation.assigned_to', ele.id));
      // }
      if (assetAssignedTo.length > 0) {
        assetAssignedTo.forEach(ele => formData.append('depreciation.assigned_to', ele.value));
      }
    }
    if (searchPramId) {
      formData.append('parent_id', searchPramId);
    }

    /* const arrSp = [];
    if (inputList?.length > 0) {
      inputList?.map(item => {
        if (item?.field) {
          arrSp.push(item);
        }
      });
    }

    if (arrSp.length > 0) {
      const name = 'specifications';
      inputList?.forEach((item, i) => {
        formData.append(name + '[' + i + ']' + ['[field]'], item['field']);
        formData.append(name + '[' + i + ']' + ['[value]'], item['value']);
        formData.append(name + '[' + i + ']' + ['[id]'], item['id']);
      });
    } */

    if (attachTable.length > 0) {
      const name = 'attachments';
      attachTable?.forEach((item, i) => {
        formData.append(
          name + '[' + i + ']' + ['[file_type]'],
          item['assetDocumentsFormFileType'] || item['file_type'],
        );
        formData.append(
          name + '[' + i + ']' + ['[file]'],
          item['assetDocumentsFormFile'] || item['file'],
        );
        formData.append(name + '[' + i + ']' + ['[id]'], item['id']);
      });
    }
    if (photosTable.length > 0) {
      const name = 'photos';
      photosTable?.forEach((item, i) => {
        formData.append(name + '[' + i + ']' + ['[is_primary]'], item['isprimary']);
        formData.append(name + '[' + i + ']' + ['[photo]'], item['assetPhotosFormFile']);
        formData.append(name + '[' + i + ']' + ['[id]'], item['id']);
      });
    }
    if (inventory.length > 0) {
      inventory?.forEach(id => {
        formData.append('inventory', id);
      });
    }
    if (
      assetDetailsForm.assetName &&
      idOfAssetType &&
      assetDetailsForm.assetNumber &&
      idOfSite &&
      idOfCategory &&
      idOfStatus &&
      !enableDeprication
    ) {
      if (!formatNumber(assetFinanceForm.purchased_price)[1] && assetFinanceForm.purchased_price) {
        toast.warning("Please write a correct Number on Purchase Price field (write the decimal part or remove decimal point)", {
          toastId: 'CreatedEventWarninggg',
        });
      }else{
      await dispatch(updateAsset({ id: asset?.uuid, formData }));
      dispatch(reset());
      }
    } else if (
      assetDetailsForm.assetName &&
      idOfAssetType &&
      assetDetailsForm.assetNumber &&
      idOfSite &&
      idOfCategory &&
      idOfStatus &&
      enableDeprication
    ) {
      if (
        !assetFinanceForm.purchased_price ||
        !assetFinanceForm.purchased_date ||
        !assetDepricationForm.salvage_price ||
        !assetDepricationForm.start_date ||
        !assetDepricationForm.service_life ||
        !assetDepricationForm.filter ||
        !assetAssignedTo.length > 0
      ) {
        setErrWithACtivateDeprication(true);
      }
      else if (!formatNumber(assetFinanceForm.purchased_price)[1] && assetFinanceForm.purchased_price) {
        toast.warning("Please write a correct Number on Purchase Price field (write the decimal part or remove decimal point)", {
          toastId: 'CreatedEventWarninggg',
        });
      }
      else if (!formatNumber(assetDepricationForm.salvage_price)[1]) {
        toast.warning("Please write a correct Number on Salvage Price field (write the decimal part or remove decimal point)", {
          toastId: 'CreatedEventWarninggg',
        });
      }
      else {
        await dispatch(updateAsset({ id: asset?.uuid, formData }));
        dispatch(reset());
      }
    } else {
      setShowModalAddError(true);
    }
  };

  const goTo = `/asset-history/${asset?.uuid}`;

  const handelBack = () => {
    dispatch(setAsset({}));
    dispatch(selectActions.reset());
    history.push('/assets');
  };

  let saveContentBtn = (
    <button type="submit" className={classes.addAssetSave}>
      Save
    </button>
  );
  if (user?.role === 'Technical Team' && asset?.created_by?.role !== 'Technical Team') {
    saveContentBtn = '';
  }

  let deleteBtn = (
    <li
      onClick={() => {
        deleteAssetHandler();
        setIsShowList(false);
      }}
    >
      Delete
    </li>
  );
  if (
    user?.role === 'Technical Team' ||
    (user?.role === 'Manager' && asset?.created_by?.role !== 'Manager')
  ) {
    deleteBtn = '';
  }
  return isAssetGetLoading ? (
    <Loader />
  ) : (
    <>
      {displayToast()}
      <div className={classes.addAsset}>
        <form onSubmit={submitHandler} className={classes.form}>
          <div className={classes.addAssetsTop}>
            <div className={classes.AssetInfo}>
              <img src={asset?.qr_code} alt="qr_code" />
              <div className={classes.AssetInfoContent}>
                <p>#{assetDetailsForm.assetNumber}</p>
                <h5 className={classes.AssetTitle}>{asset?.name}</h5>
              </div>
            </div>
            <div className={classes.addAssetActions}>
              <button
                onClick={() => setIsShowList(!isShowList)}
                className={classes.optionsOfAsset}
                type="button"
              >
                <FontAwesomeIcon icon={faEllipsis} />
              </button>
              {isShowList && <div className='tck-overlay' onClick={() => setIsShowList(!isShowList)} />}
              {isShowList && (
                <ul className={classes.listOptions}>
                  {deleteBtn}
                  {/* <li>
                    <Link to={goTo}>History</Link>
                  </li> */}
                  <li
                    onClick={() => {
                      handlePrint();
                      setIsShowList(false);
                    }}
                  >
                    Print
                  </li>
                  <li>
                  <PrintAssetQr asset={asset} company={profile?.logo} />
                  </li>
                </ul>
              )}
              <div
                className="card-body"
                style={{ paddingBottom: '1.25rem', display: 'none' }}
                ref={componentRef}
              >
                <div className="text-center p-1" id="printThisLocation">
                  <center>
                    <img
                      className="qr-print"
                      src={asset?.qr_code}
                      id="location-qr-url"
                      width="144"
                      height="144"
                      alt=""
                    />
                    <div className="qr-print">{`Serial Number  : ${assetDetailsForm.assetNumber} `}</div>
                    <div className="qr-print"> {`name : ${assetDetailsForm.assetName} `}</div>
                    <div className="qr-print">
                      {' '}
                      {`Location : ${selectedSite || asset?.location?.site}`}
                    </div>
                    <br />
                  </center>
                </div>
              </div>
              <div
                className="card-body"
                style={{ paddingBottom: '1.25rem', display: 'none' }}
                ref={componentRef1}
              >
                <div className={classes.assetPdf}>
                  <div className={classes.pdfheader}>
                    <img
                      src={info.logo ? info.logo : altCompanyPic}
                      alt=""
                      className="brand-image img-company"
                      id="result_company_base"
                      height="100"
                      width="100"
                      style={{ marginRight: '20px' }}
                    />
                    <div>
                      {' '}
                      <strong>{info.name}</strong>
                      <br />
                      <span>{info.address}</span> <br />
                      <span>Tel {info.phone_number}</span>{' '}
                      <span style={{ marginLeft: '10px' }}> Website {info.website}</span>
                    </div>
                  </div>
                  <div style={{ fontSize: '20px', borderBottom: '5px solid black' }}>
                    {' '}
                    <strong> ASSET DETAILS </strong>
                  </div>
                  {assetDetailsForm.assetName && (
                    <div className={classes.assetrow}>
                      {' '}
                      {`Name : ${assetDetailsForm.assetName} `}
                    </div>
                  )}
                  {assetDetailsForm.assetNumber && (
                    <div
                      className={classes.assetrow}
                    >{`Serial Number  : ${assetDetailsForm.assetNumber}`}</div>
                  )}
                  {asset?.asset_type?.name && (
                    <div
                      className={classes.assetrow}
                    >{`Asset Type : ${asset?.asset_type?.name}`}</div>
                  )}
                  {asset?.category?.name && (
                    <div className={classes.assetrow}>{`Category : ${asset?.category?.name}`}</div>
                  )}
                  {assetDetailsForm.brand && (
                    <div className={classes.assetrow}>{`Brand : ${assetDetailsForm.brand}`}</div>
                  )}
                  {selectedSite ||
                    (asset?.location?.site && (
                      <div className={classes.assetrow}>
                        {' '}
                        {`Location : ${selectedSite || asset?.location?.site}`}
                      </div>
                    ))}
                  {assetDetailsForm.description && (
                    <div
                      className={classes.assetrow}
                    >{`Description :${assetDetailsForm.description} `}</div>
                  )}
                  <br />
                  <br />
                  <div style={{ fontSize: '20px', borderBottom: '5px solid black' }}>
                    {' '}
                    <strong> FINANCE DETAILS </strong>
                  </div>
                  {assetFinanceForm.purchased_price && (
                    <div className={classes.assetrow}>
                      {' '}
                      {` Purchase Price : ${assetFinanceForm.purchased_price} `}
                    </div>
                  )}
                  {assetFinanceForm.purchased_date && (
                    <div className={classes.assetrow}>
                      {' '}
                      {` Purchase Date : ${assetFinanceForm.purchased_date} `}
                    </div>
                  )}
                  {assetFinanceForm.vendor && (
                    <div className={classes.assetrow}>
                      {' '}
                      {` Vendor : ${assetFinanceForm.vendor} `}
                    </div>
                  )}
                  {assetFinanceForm.warranty_expiration && (
                    <div className={classes.assetrow}>
                      {' '}
                      {` Warranty Expiration : ${assetFinanceForm.warranty_expiration} `}
                    </div>
                  )}
                  {assetFinanceForm.po_number && (
                    <div className={classes.assetrow}>
                      {' '}
                      {` Purchase Order : ${assetFinanceForm.po_number} `}
                    </div>
                  )}
                  {asset?.specifications?.field && (
                    <>
                      <br />
                      <br />
                      <div style={{ fontSize: '20px', borderBottom: '5px solid black' }}>
                        {' '}
                        <strong> SPECIFICATION DETAILS </strong>
                      </div>
                      {asset.specifications && (
                        <div className={classes.assetrow}> {`${asset.specifications.field} `}</div>
                      )}
                    </>
                  )}
                  {asset?.photos && asset?.photos?.length > 0 && (
                    <>
                      <br />
                      <br />
                      <div style={{ fontSize: '20px', borderBottom: '5px solid black' }}>
                        {' '}
                        <strong> Photos </strong>
                      </div>
                      {asset?.photos.map(i => (
                        <img
                          src={i.photo}
                          alt=""
                          className="brand-image img-company"
                          id="result_company_base"
                          height="100"
                          width="100"
                          style={{ marginRight: '20px' }}
                        />
                      ))}
                    </>
                  )}
                  <br />
                  <br />
                  <br />
                  <span className={classes.signature}>Signature</span>{' '}
                  <span
                    style={{
                      position: 'absolute',
                      marginLeft: '43rem',
                      borderTop: '1px solid black',
                      width: '200px',
                    }}
                  >
                    Date
                  </span>
                </div>
              </div>

              <PopUpDeleteModal
                showModal={showDeleteModal}
                handleClose={handleCloseDeleteModal}
                handleConfirm={handleConfirmDelete}
                modalTitle="Delete Asset"
                modalBodyText="Are you sure you want to delete this Asset"
              />
              {saveContentBtn}
              <button type="button" onClick={handelBack} className={classes.addAssetBack}>
                Back
              </button>
            </div>
          </div>
          <ul className={classes.list}>
            {searchPramId || asset?.parent_id
              ? tabs.slice(0, 5).map(tab => {
                const tabClass = selected === tab ? classes.active : classes.li;
                return (
                  <li key={tab}>
                    <button className={tabClass} type="button" onClick={() => handelTabs(tab)}>
                      {tab}
                    </button>
                  </li>
                );
              })
              : tabs.map(tab => {
                const tabClass = selected === tab ? classes.active : classes.li;
                return (
                  <li key={tab}>
                    <button className={tabClass} type="button" onClick={() => handelTabs(tab)}>
                      {tab}
                    </button>
                  </li>
                );
              })}
          </ul>
          {selected === tabs[0] && (
            <FetchAssetDetailsForm
              handelChange={handelChangeDetailsForm}
              assetDetailsForm={assetDetailsForm}
              locations={locations}
              assetCategories={assetCategories}
              assetStatuses={assetStatuses}
              subLocation={sublocations?.results}
              allSubLocations={allSubLocations}
              // generateSerialNumber={generateSerialNumber}
              generateAssetNumber={generateAssetNumber}
              handleChangeLoanEligability={handleChangeLoanEligability}
            />
          )}
          {selected === tabs[1] && (
            <FetchAssetFinanceForm
              handelChangeFinanceForm={handelChangeFinanceForm}
              assetFinanceForm={assetFinanceForm}
            />
          )}
          {selected === tabs[2] && (
            <FetchAssetDocumentsForm
              assetDocumentsFormFile={assetDocumentsFormFile}
              assetDocumentsFormFileType={assetDocumentsFormFileType}
              handelChangeDocumentsFormFile={handelChangeDocumentsFormFile}
              handelChangeDocumentsFormFileType={handelChangeDocumentsFormFileType}
              handleCreateSaveAttach={handleCreateSaveAttach}
              attachTable={attachTable}
              showModal={showModal}
              setShowModal={setShowModal}
              deleteAttach={deleteAttach}
            />
          )}
          {selected === tabs[3] && (
            <FetchAssetPhotosForm
              handelChangePhotosFormFile={handelChangePhotosFormFile}
              handleCreateSavePhoto={handleCreateSavePhoto}
              photosTable={photosTable}
              deletePhoto={deletePhoto}
              showModal={showModal}
              setShowModal={setShowModal}
              isprimary={isprimary}
              handelChangePrimary={handelChangePrimary}
            />
          )}
          {selected === tabs[4] && (
            <FetchAssetSpecificationsForm
              inputSp={inputSp}
              inputList={inputList}
              setInputList={setInputList}
              addInputHandler={addInputHandler}
              removeInputHandler={removeInputHandler}
              handelSpChange={handelSpChange}
            />
          )}
          {selected === tabs[5] && (
            <FetchAssetSparePartsForm
              showModal={showModalOfSpare}
              handleCreateSave={handleSaveSpare}
              setShowModal={setShowModalOfSpare}
              handelInventory={handelInventory}
              sparePartErr={sparePartErr}
              sparePart={sparePart}
              spareTable={spareTable}
              deleteSpare={deleteSpare}
            />
          )}
          {selected === tabs[6] && (
            <FetchAssetDepricationForm
              handelEditDepricationChange={handelEditDepricationChange}
              enableDeprication={enableDeprication}
              handelChangeDepricationForm={handelChangeDepricationForm}
              assetDepricationForm={assetDepricationForm}
              chartData={chartData}
              selectedOption={selectedOption}
              handelFilter={handelFilter}
              assetDepreciation={assetDepreciation}
              handelViewAndEdit={handelViewAndEdit}
              viewAndEdit={viewAndEdit}
              assetFinanceForm={assetFinanceForm}
            />
          )}
          {selected === tabs[7] && (
            <AssetHistory />
          )}
          <PopUpAddErrorModal
            modalTitle="Error With Update Asset"
            handleClose={handelCloseModalAddError}
            showModal={showModalAddError || errWithACtivateDeprication}
          >
            {errWithACtivateDeprication && (
              <span>
                <h5> To Activate Asset Depreciation:</h5> Please fill out the required fields in the
                Finance and Depreciation Form.
              </span>
            )}
            {showModalAddError && (
              <span>
                <h5>Attention:</h5> Please Fill the Required Fields in Asset Details
              </span>
            )}
          </PopUpAddErrorModal>
        </form>
      </div>
    </>
  );
}

export default FetchAsset;
