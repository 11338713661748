
export default function formatNumber(value){
    if (!value) return ''; // Return an empty string if the value is empty
        // Remove commas for processing
        const valueWithoutCommas = value.replace(/,/g, '');

        // Allow only numbers and a single decimal point
        const sanitizedValue = valueWithoutCommas.replace(/[^0-9.]/g, '');
    
        // Split into integer and decimal parts
        const [integerPart, decimalPart] = sanitizedValue.split('.');
    
        // Add commas for thousands in the integer part
        const formattedIntegerPart = parseFloat(integerPart).toLocaleString('en-US');
    
        // Ensure two digits after the decimal point
        const formattedDecimalPart = decimalPart !== undefined ? `.${decimalPart.slice(0, 2)}` : '';    
        // Combine the formatted integer and the decimal part
        const formattedValue = `${formattedIntegerPart}${formattedDecimalPart}`; 
        // Validate the input as a number
        const isValidNumber = !Number.isNaN(parseFloat(formattedValue));
        // Check if both integer and decimal parts are provided
        const hasDecimalPart = decimalPart !== "." && decimalPart !== '';
        if (isValidNumber) {
            return [formattedValue , hasDecimalPart] 
        }
        return ['' , ''];
  };