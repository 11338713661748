/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-target-blank */

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Base64 } from 'js-base64';
import { Marker, Popup, FeatureGroup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  createMarkedAssetById,
  deleteMarkedAssetById,
  updateMarkedAssetById,
  getMarkedAssetById,
  getUnmarkedAssetById,
  setSelectedMarker,
  setAssignassets,
  putAssignassets,
  setDeleteMarker,
  setDeleteMarkerOnSave,
  resetDeleteMarkerOnSave,
} from '../../redux/features/assetMapping/assetMappingSlice';

function CustomMarker({setAssetId}) {
  const { selectedMarker, deleteMarker , locationChange } = useSelector((state) => state.assetsMapping);
  const dispatch = useDispatch();
  const { assetMapping, markedAsset, assignclicked, assignassets } = useSelector(
    (state) => state.assetsMapping,
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  let markerData = [];
  // let deleteMarker = {};

  useEffect(() => {
    if (assetMapping?.id) {
      dispatch(getMarkedAssetById(assetMapping?.id));
    }
  }, [assetMapping]);

  const handleMarkerPost = async (newmarkerData) => {
    await dispatch(createMarkedAssetById(newmarkerData));
    await dispatch(getMarkedAssetById(assetMapping.id));
    await dispatch(getUnmarkedAssetById(locationChange?.id));
    dispatch(setSelectedMarker(null));
    setAssetId()
  };

  const handleMarkerUpdate = async (marker) => {
    markerData.push({
      asset_id: marker.id,
      lat_long: { lat: marker.updatedLat.toFixed(2), lng: marker.updatedLng.toFixed(2) },
    });
  };

  const handleMarkerDelete = async (markerId) => {
    const index = markedAsset.findIndex((item) => item.id === markerId);
    if (index !== -1) {
      await dispatch(setDeleteMarkerOnSave(markerId));
      const updatedItems = [...markedAsset];
      updatedItems.splice(index, 1);
      await dispatch(setDeleteMarker(updatedItems));
    }
  };

  const handleMarkerDragEnd = async (selectedMarkerId, e) => {
    const { target } = e;
    const { lat, lng } = target.getLatLng();
    const newMarkerData = {
      asset_id: selectedMarkerId,
      lat_long: { lat: lat.toFixed(2), lng: lng.toFixed(2) },
    };
    handleMarkerPost(newMarkerData);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleUpdateSaveClick = async () => {
    // Perform save logic here

    if (markerData) {
      markerData.map(async (marker) => {
        await dispatch(updateMarkedAssetById(marker));
        setIsEditing(false);
      });
      await dispatch(getMarkedAssetById(assetMapping.id));
    }

    markerData = [];
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    // Perform cancel logic here
  };

  const handleDeleteClick = () => {
    setIsDelete(true);
  };

  const handleDeleteSaveClick = async () => {
    // Perform save logic here
    if (deleteMarker) {
      await dispatch(deleteMarkedAssetById(deleteMarker));
      await dispatch(getMarkedAssetById(assetMapping.id));
      await dispatch(getUnmarkedAssetById(locationChange?.id));
      await dispatch(resetDeleteMarkerOnSave([]));
      setIsDelete(false);
      setAssetId()
    }
  };

  const handleDeleteCancelClick = async () => {
    await dispatch(getMarkedAssetById(assetMapping.id));
    setIsDelete(false);
    // Perform cancel logic here
  };

  const handelClickonMarker = (markerId) => {
    if (isDelete) {
      handleMarkerDelete(markerId);
    }
    if (Object.keys(assignclicked).length !== 0 && isDelete === false) {
      if (assignassets && assignassets.includes(markerId)) {
        const updatedValues = assignassets.filter((v) => v !== markerId);
        dispatch(putAssignassets(updatedValues));
      } else {
        dispatch(setAssignassets(markerId));
      }
    }
  };

  const blueIcon = new L.Icon({
    iconUrl:
      'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          height: '70px',
          width: '37px',
          marginTop: '100px',
          border: '3px solid #eeeeee',
          backgroundClip: 'padding-box',
          background :"white",
          zIndex : 1000,
        }}
      >
        <div style={{paddingBottom : "5px"  }}>
          <button  style={{ fontSize:"18px" }} type="button" onClick={handleEditClick}>
          <FontAwesomeIcon icon={faPenToSquare}  /> 
          </button>
          {isEditing && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                backgroundColor: '#919187',
                borderLeft: '1px solid #AAA',
                color: '#FFF',
                position: 'absolute',
                top: '5px',
                left: '35px',
                float: 'left',
              }}
            >
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                <button type="button" onClick={handleUpdateSaveClick}>
                  Save
                </button>
              </div>
              <div style={{ marginRight: '10px' }}>
                <button type="button" onClick={handleCancelClick}>
                  Cancel
                </button>
              </div>
              <div className="content">{/* Content of the popup */}</div>
            </div>
          )}
        </div>

        <div>
          <button style={{fontSize:"18px" }}  type="button" onClick={handleDeleteClick}>
          <FontAwesomeIcon icon={faTrashCan}  />
          </button>
          {isDelete && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                backgroundColor: '#919187',
                borderLeft: '1px solid #AAA',
                color: '#FFF',
                position: 'absolute',
                bottom: '5px',
                left: '35px',
                float: 'left',
              }}
            >
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                <button type="button" onClick={handleDeleteSaveClick}>
                  Save
                </button>
              </div>
              <div style={{ marginRight: '10px' }}>
                <button type="button" onClick={handleDeleteCancelClick}>
                  Cancel
                </button>
              </div>
              <div className="content">{/* Content of the popup */}</div>
            </div>
          )}
        </div>
      </div>
      <FeatureGroup>
        {selectedMarker && Object.entries(selectedMarker).length !== 0 && (
          <Marker
            key={selectedMarker.id}
            position={[selectedMarker.lat, selectedMarker.lng]}
            icon={blueIcon}
            eventHandlers={{
              dragend: (e) => handleMarkerDragEnd(selectedMarker.id, e),
            }}
            draggable
          />
        )}
        {markedAsset &&
          markedAsset.map((marker) => {
            let color;
            let customIcon;
            if (marker.lat_long.group === null) {
              if (assignassets.includes(marker.id)) {
                color = assignclicked.color;
                const svg2 = `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>map-pointer-glyph</title><path d="M257.13,125.11c40.21,0,72.52,30.23,72.52,70.43,0,38.59-32.31,70.76-72.52,70.76-40.52,0-72.85-32.17-72.85-70.76,0-40.2,32.33-70.43,72.85-70.43Zm181.54,52.42C438.67,78.79,358,0,257.13,0c-101,0-183.8,78.79-183.8,177.53,0,4.18,0,10.3,2.09,14.15H73.33c0,96.81,183.8,320.32,183.8,320.32S438.67,288.49,438.67,191.68h0V177.53Z" width="270mm" fill="${color}" fill-rule="evenodd"/></svg>`; /* insert your own svg */
                const iconUrl2 = `data:image/svg+xml;base64,${Base64.encode(svg2)}`;
                customIcon = new L.Icon({
                  iconUrl: iconUrl2,
                  shadowUrl:
                    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                  popupAnchor: [1, -34],
                  shadowSize: [41, 41],
                });
              } else {
                customIcon = blueIcon;
              }
            } else {
              if (assignassets && assignassets?.includes(marker.id)) {
                color = assignclicked.color;
              } else {
                color = marker.lat_long.group.color;
              }

              const svg2 = `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>map-pointer-glyph</title><path d="M257.13,125.11c40.21,0,72.52,30.23,72.52,70.43,0,38.59-32.31,70.76-72.52,70.76-40.52,0-72.85-32.17-72.85-70.76,0-40.2,32.33-70.43,72.85-70.43Zm181.54,52.42C438.67,78.79,358,0,257.13,0c-101,0-183.8,78.79-183.8,177.53,0,4.18,0,10.3,2.09,14.15H73.33c0,96.81,183.8,320.32,183.8,320.32S438.67,288.49,438.67,191.68h0V177.53Z" width="270mm" fill="${color}" fill-rule="evenodd"/></svg>`; /* insert your own svg */
              const iconUrl2 = `data:image/svg+xml;base64,${Base64.encode(svg2)}`;
              customIcon = new L.Icon({
                iconUrl: iconUrl2,
                shadowUrl:
                  'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41],
              });
            }
            return (
              <Marker
                key={marker.id}
                position={[marker.lat_long.lat, marker.lat_long.lng]}
                draggable={isEditing}
                icon={customIcon}
                eventHandlers={{
                  click: (e) => {
                    e.originalEvent.stopPropagation();
                    handelClickonMarker(marker.id);
                  },
                  dragend: (e) => {
                    const { target } = e;
                    const { lat, lng } = target.getLatLng();
                    // Check if the marker is newly added or being dragged
                    const updatedLat = lat;
                    const updatedLng = lng;
                    const updatedMarker = { ...marker, updatedLat, updatedLng };
                    handleMarkerUpdate(updatedMarker);
                  },
                }}
              >
                <Popup>
                  <center className="col-12 medium-title font-extrabold p-0 mb-2">
                   #{marker.name} {marker.asset_number}
                  </center>
                  <a href={`assets/${marker?.uuid}`} target="_blank" title="View Asset">
                   View Asset Details
                  </a>
                </Popup>
              </Marker>
            );
          })}
      </FeatureGroup>
    </>
  );
}

export default CustomMarker;
