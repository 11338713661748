import React from 'react'
import "./modals.css"

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */


function NewModal({
    showModal,
    handleClose,
    modalTitle,
    primaryButtonText,
    handleCreateSave,
    children,
}) {
    return (
        <>
        {showModal && <div className='new-modal-overlay' onClick={handleClose} />}
        <div className={`new-modal ${showModal? "modal--open" : "modal--close"}`}>
            <div className="new-modal-header">
                <h5>{modalTitle}</h5>
            </div>
            <div className="new-modal-container">
               {children}
            </div>
            <div className='new-model-footer'>
                <button onClick={handleClose} type='button' className='secondary-btn btn mr-2'>Close</button>
                <button onClick={handleCreateSave} type='button' className='primary-btn btn'>{primaryButtonText}</button>
            </div>
        </div>
        </>
    )
}

export default NewModal