import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap/';
import { useSelector , useDispatch } from 'react-redux';
import { setFloor, setSearchValue, updateFloor } from '../../redux/features/location/locationSlice';

export default function AssociatedLocation({ showPop, onHidePop, primaryButtonText }) {
  const { floor, searchValue , searchonAssociatedLocation } = useSelector((state) => state.location);
  const [checked, setCheced] = useState( []);
  const dispatch = useDispatch()
  function addnremovesub(id) {
    if(checked.includes(id)) {
        const data  = checked.filter(e=>e!==id)
        setCheced(data)}
      else 
      setCheced([...checked, id])
  }


  useEffect(() => {

    if(floor?.associated_location?.length > 0){
      setCheced(floor?.associated_location.map(e=> e?.id ))
    }
  }, [floor?.associated_location]);
  function checkid(id) {
    return checked.includes(id);
  }
function updatefloorData (e){
  e.preventDefault()
  const info ={
    id :floor.id, 
    data :{associated_location:checked}
  }
  
  dispatch(updateFloor(info))
}

const handelSearch = (e)=>{
  dispatch(setSearchValue(e.target.value))
  dispatch(setFloor(e.target.value))
}

  return (
    <Modal
      show={showPop}
      onHide={onHidePop}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        style={{ flexDirection: 'column', maxWidth: '100% !important' }}
        className="associatedHead"
      >
        <Modal.Title id="contained-modal-title-vcenter">Select location</Modal.Title>
        <input
          type="text"
          id="location-list-popup-search"
          placeholder="Search"
          className="rgfloat search-bar float-right p-1 m-2 w-100"
          onChange={handelSearch}
        />
      </Modal.Header>
      <Modal.Body style={{ height: '400px', overflow: 'Auto' }}>
        <table
          id="location-list-popup"
          className="table table-borderless mb-0 dataTable"
          style={{ background: 'transparent', border: 'none !important' }}
        >
          <thead>
            <tr>
              <th>
                Location
                <br />
              </th>
              <th>Block</th>
              <th>Level</th>
            </tr>
          </thead>
          <tbody id="dialog-append-part">
         {
          searchValue === ""  ?
          <>
          {floor?.associated_location?.slice(0,10).map((e) => (
              <tr
                id={e.id}
                onClick={() => addnremovesub(e.id)}
                style={{ background: checkid(e.id) ? ' rgb(255, 235, 238)' : 'none' , cursor : "pointer", color : checkid(e.id)  ? ' rgb(246, 56, 84)' : "black"}}
           
              >
                <td>{e.room}</td>
                {/* <td>{e.suite === '' ? '—' : e.suite}</td> */}
                <td>{e.building === '' ? '—' : e.building}</td>
                <td>{e.level === '' ? '—' : e.level}</td>
              </tr>
            ))}
            {floor?.dissociated_location?.slice(0,10).map((e) => (
              <tr
                id={e.id}
                onClick={() => addnremovesub(e.id)}
                style={{ background: checkid(e.id) ? ' rgb(255, 235, 238)' : 'none' , cursor : "pointer", color : checkid(e.id)  ? ' rgb(246, 56, 84)' : "black"}}
           
              >
                <td>{e.room}</td>
                {/* <td>{e.suite === '' ? '—' : e.suite}</td> */}
                <td>{e.building === '' ? '—' : e.building}</td>
                <td>{e.level === '' ? '—' : e.level}</td>
              </tr>
            ))}
          </> : searchonAssociatedLocation?.slice(0,10).map((e) => (
              <tr
                id={e.id}
                onClick={() => addnremovesub(e.id)}
                style={{ background: checkid(e.id) ? ' rgb(255, 235, 238)' : 'none' , cursor : "pointer", color : checkid(e.id)  ? ' rgb(246, 56, 84)' : "black"}}
           
              >
                <td>{e.room}</td>
                {/* <td>{e.suite === '' ? '—' : e.suite}</td> */}
                <td>{e.building === '' ? '—' : e.building}</td>
                <td>{e.level === '' ? '—' : e.level}</td>
              </tr>
            ))}
          
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHidePop}>
          Close
        </Button>
        <Button variant="primary" onClick={(e)=>{updatefloorData(e); onHidePop()}}>{primaryButtonText}</Button>
      </Modal.Footer>
    </Modal>
  );
}
