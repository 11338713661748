import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { smartTryCatch } from '../../../utils';
import assetLoanService from './assetLoanService';

const initState = {
  // data
  assetLoanStatuses: [],
  assetLoanStatus: null,

  // fields
  assetLoanStatusName: '',

  // modal
  assetLoanStatusModalUpdate: false,
  assetLoanStatusModalCreate: false,
  assetLoanChekinModalCreate: false,

  // success
  isAssetLoanStatusCreateSuccess: false,
  isAssetLoanStatusGetSuccess: false,
  isAssetLoanStatusUpdateSuccess: false,
  isAssetCheckinGetSuccess: false,
  isAssetCheckoutGetSuccess: false,

  // loading
  isAssetLoanStatusCreateLoading: false,
  isAssetLoanStatusUpdateLoading: false,
  isAssetLoanStatusGetLoading: false,
  isAssetCheckinGetLoading: false,
  isAssetCheckoutGetLoading: false,

  // error
  isAssetLoanStatusCreateError: false,
  isAssetLoanStatusGetError: false,
  isAssetLoanStatusUpdateError: false,
  isAssetCheckinGetError: false,
  isAssetCheckoutGetError: false,
};

export const getAssetLoanStatusByAuthentication = createAsyncThunk(
  'getAssetLoanStatusByAuthentication',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      assetLoanService.getAssetLoanStatusByAuthentication,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const getAssetLoanStatusById = createAsyncThunk('getAssetLoanStatusById', async (data, thunkAPI) => {
  const res = await smartTryCatch(assetLoanService.getAssetLoanStatusById, data, thunkAPI);
  return res;
});


export const updateAssetLoanStatus = createAsyncThunk('updateAssetLoanStatus', async (data, thunkAPI) => {
  const res = await smartTryCatch(assetLoanService.updateAssetLoanStatus, data, thunkAPI);
  return res;
});
export const checkOutAsset = createAsyncThunk('checkOutAsset', async (data, thunkAPI) => {
  const res = await smartTryCatch(assetLoanService.checkOutAsset, data, thunkAPI);
  return res;
});
export const checkInAsset = createAsyncThunk('checkInAsset', async (data, thunkAPI) => {
  const res = await smartTryCatch(assetLoanService.checkInAsset, data, thunkAPI);
  return res;
});

export const assetLoanStatusSlice = createSlice({
  name: 'assetLoanStatus',
  initialState: initState,
  reducers: {
    reset: state => {
      state.isAssetLoanStatusCreateError = false;
      state.isAssetLoanStatusCreateSuccess = false;
      state.isAssetLoanStatusCreateLoading = false;
      state.isAssetLoanStatusGetError = false;
      state.isAssetLoanStatusGetSuccess = false;
      state.isAssetLoanStatusGetLoading = false;
      state.isAssetLoanStatusUpdateError = false;
      state.isAssetLoanStatusUpdateSuccess = false;
      state.isAssetLoanStatusUpdateLoading = false;
      state.isAssetCheckinGetError = false;
      state.isAssetCheckinGetSuccess = false;
      state.isAssetCheckinGetLoading = false;
      state.isAssetCheckoutGetError = false;
      state.isAssetCheckoutGetLoading = false;
      state.isAssetCheckoutGetSuccess = false;
    },
    setAssetLoanStatus: (state, action) => {
      state.assetLoanStatus = action.payload;
    },
    closeAssetLoanStatusModalUpdate: state => {
      state.assetLoanStatusModalUpdate = false;
    },
    openAssetLoanStatusModalCreate: state => {
      state.assetLoanStatusModalCreate = true;
    },
    openAssetLoanCheckinModalCreate: state => {
      state.assetLoanChekinModalCreate = true;
    },
    closeAssetLoanCheckinModalCreate: state => {
      state.assetLoanChekinModalCreate = false;
    },
    closeAssetLoanStatusModalCreate: state => {
      state.assetLoanStatusModalCreate = false;
    },
    setAssetLoanStatusName: (state, action) => {
      state.assetLoanStatusName = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAssetLoanStatusByAuthentication.pending, state => {
        state.isAssetLoanStatusGetLoading = true;
        state.isAssetLoanStatusGetSuccess = false;
        state.isAssetLoanStatusGetError = false;
      })
      .addCase(getAssetLoanStatusByAuthentication.fulfilled, (state, action) => {
        state.isAssetLoanStatusGetLoading = false;
        state.isAssetLoanStatusGetSuccess = true;
        state.isAssetLoanStatusGetError = false;
        state.assetLoanStatuses = action.payload;
      })
      .addCase(getAssetLoanStatusByAuthentication.rejected, state => {
        state.isAssetLoanStatusGetLoading = false;
        state.isAssetLoanStatusGetSuccess = false;
        state.isAssetLoanStatusGetError = true;
      })
      .addCase(getAssetLoanStatusById.pending, state => {
        state.isAssetLoanStatusGetLoading = true;
        state.isAssetLoanStatusGetSuccess = false;
        state.isAssetLoanStatusGetError = false;
      })
      .addCase(getAssetLoanStatusById.fulfilled, (state, action) => {
        state.isAssetLoanStatusGetLoading = false;
        state.isAssetLoanStatusGetSuccess = true;
        state.isAssetLoanStatusGetError = false;
        state.assetLoanStatus = action.payload;
      })
      .addCase(getAssetLoanStatusById.rejected, state => {
        state.isAssetLoanStatusGetLoading = false;
        state.isAssetLoanStatusGetSuccess = false;
        state.isAssetLoanStatusGetError = true;
      })
      .addCase(checkOutAsset.pending, state => {
        state.isAssetCheckoutGetLoading = true;
        state.isAssetCheckoutGetSuccess = false;
        state.isAssetCheckoutGetError = false;
      })
      .addCase(checkOutAsset.fulfilled, (state, action) => {
        state.isAssetCheckoutGetLoading = false;
        state.isAssetCheckoutGetSuccess = true;
        state.isAssetCheckoutGetError = false;
        state.assetLoanStatus = action.payload;
      })
      .addCase(checkOutAsset.rejected, state => {
        state.isAssetCheckoutGetLoading = false;
        state.isAssetCheckoutGetSuccess = false;
        state.isAssetCheckoutGetError = true;
      })
      .addCase(checkInAsset.pending, state => {
        state.isAssetCheckinGetLoading = true;
        state.isAssetCheckinGetSuccess = false;
        state.isAssetCheckinGetError = false;
      })
      .addCase(checkInAsset.fulfilled, (state, action) => {
        state.isAssetCheckinGetLoading = false;
        state.isAssetCheckinGetSuccess = true;
        state.isAssetCheckinGetError = false;
        state.assetLoanStatus = action.payload;
      })
      .addCase(checkInAsset.rejected, state => {
        state.isAssetCheckinGetLoading = false;
        state.isAssetCheckinGetSuccess = false;
        state.isAssetCheckinGetError = true;
      })
    //   .addCase(createAssetStatus.pending, state => {
    //     state.isAssetStatusCreateLoading = true;
    //     state.isAssetStatusCreateSuccess = false;
    //     state.isAssetStatusCreateError = false;
    //   })
    //   .addCase(createAssetStatus.fulfilled, (state, action) => {
    //     state.isAssetStatusCreateLoading = false;
    //     state.isAssetStatusCreateSuccess = true;
    //     state.isAssetStatusCreateError = false;
    //     state.assetStatuses.push(action.payload);
    //   })
    //   .addCase(createAssetStatus.rejected, state => {
    //     state.isAssetStatusCreateLoading = false;
    //     state.isAssetStatusCreateSuccess = false;
    //     state.isAssetStatusCreateError = true;
    //   })
    //   .addCase(deleteAssetStatus.pending, state => {
    //     state.isAssetStatusDeleteLoading = true;
    //     state.isAssetStatusDeleteSuccess = false;
    //     state.isAssetStatusDeleteError = false;
    //   })
    //   .addCase(deleteAssetStatus.fulfilled, (state, action) => {
    //     state.isAssetStatusDeleteLoading = false;
    //     state.isAssetStatusDeleteSuccess = true;
    //     state.isAssetStatusDeleteError = false;
    //     state.assetStatuses = state.assetStatuses.filter(
    //       assetStatus => assetStatus.id !== action.payload,
    //     );
    //   })
    //   .addCase(deleteAssetStatus.rejected, state => {
    //     state.isAssetStatusDeleteLoading = false;
    //     state.isAssetStatusDeleteSuccess = false;
    //     state.isAssetStatusDeleteError = true;
    //   })
      .addCase(updateAssetLoanStatus.pending, state => {
        state.isAssetLoanStatusUpdateLoading = true;
        state.isAssetLoanStatusUpdateSuccess = false;
        state.isAssetLoanStatusUpdateError = false;
      })
      .addCase(updateAssetLoanStatus.fulfilled, (state, action) => {
        state.isAssetLoanStatusUpdateLoading = false;
        state.isAssetLoanStatusUpdateSuccess = true;
        state.isAssetLoanStatusUpdateError = false;
        state.assetLoanStatuses = state.assetLoanStatuses.map(assetStatus =>
          assetStatus.id === action.payload.id ? action.payload : assetStatus,
        );
      })
      .addCase(updateAssetLoanStatus.rejected, state => {
        state.isAssetLoanStatusUpdateLoading = false;
        state.isAssetLoanStatusUpdateSuccess = false;
        state.isAssetLoanStatusUpdateError = true;
      });
  },
});

export const {
  setAssetLoanStatus,
  openAssetLoanStatusModalUpdate,
  closeAssetLoanStatusModalUpdate,
  openAssetLoanStatusModalCreate,
  closeAssetLoanStatusModalCreate,
  reset,
  closeAssetLoanCheckinModalCreate,
  openAssetLoanCheckinModalCreate,
} = assetLoanStatusSlice.actions;

export default assetLoanStatusSlice.reducer;
