import React from 'react'

function PrintIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3333 13H3.66667M10.3333 13H10.8687C11.614 13 11.9867 13 12.2713 12.8547C12.5227 12.7267 12.7267 12.5227 12.8547 12.272C13 11.9873 13 11.614 13 10.8687V5.14667C13 4.84667 13 4.69667 12.968 4.554C12.9396 4.42673 12.8926 4.30431 12.8287 4.19067C12.758 4.064 12.658 3.954 12.462 3.736L10.6253 1.69467C10.398 1.44267 10.2827 1.31467 10.1447 1.22267C10.0202 1.14009 9.88272 1.07902 9.738 1.042C9.57533 1 9.4 1 9.05 1H3.13333C2.38667 1 2.01333 1 1.728 1.14533C1.47713 1.27316 1.27316 1.47713 1.14533 1.728C1 2.01333 1 2.38667 1 3.13333V10.8667C1 11.6133 1 11.9867 1.14533 12.2713C1.27333 12.5227 1.47667 12.7267 1.728 12.8547C2.01267 13 2.386 13 3.13133 13H3.66667M10.3333 13V10.4647C10.3333 9.71933 10.3333 9.346 10.188 9.06133C10.06 8.81036 9.85579 8.60638 9.60467 8.47867C9.32 8.33333 8.94667 8.33333 8.2 8.33333H5.8C5.05333 8.33333 4.68 8.33333 4.39467 8.47867C4.1438 8.6065 3.93983 8.81046 3.812 9.06133C3.66667 9.34667 3.66667 9.72 3.66667 10.4667V13M9 3.66667H5" stroke="#36383C"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>
    )
}

export default PrintIcon