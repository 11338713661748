/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable  no-lone-blocks */
/* eslint-disable  no-unused-expressions */

import React, { useState } from 'react'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, useExpanded, useGlobalFilter } from 'react-table'
import classes from "../../../components/assetsTable/assetsTable.module.css"
import Loader from '../../../components/loader/loader';
import { closeMeterModalDelete, deleteMeter, openMeterModalDelete } from '../../../redux/features/meter/meterSlice';
import PopUpDeleteModal from '../../../components/popUpModals/editCategory/popUpDeleteModal';


function MeterTable({ meterData, getSelectedRowwValues, iSShowFilter,
  iSShowCheckboxs,
  setISShowCheckboxs, isLoading }) {
  const [rowId, setRowId] = useState(null)
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { meterModalDelete } = useSelector(state => state.meter);
  const handleDelete = () => {
    dispatch(deleteMeter(rowId))
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Meter Name	',
        accessor: 'name',
      },
      {
        Header: 'Meter #',
        accessor: 'ticketNumber',
      },
      {
        Header: 'Assigned To',
        Cell: ({ row }) => {
          return (
            <span className={classes.imagesWrapper}>
              {row?.original?.assignedPersons !== "—" ?
                row?.original?.assignedPersons?.map((item) => {
                  return (
                    <img
                      className={classes.assignedImage}
                      src={item?.image}
                      alt={item?.name}
                      title={item?.name}
                    />)
                })
                : <span className='ml-4'>—</span>
              }
            </span>
          )

        },
      },

      {
        Header: 'Reading Date',
        Cell: ({ row }) => {
          return (
            row?.original?.readingDate !== '—' ?
              <>
                <span>{moment.utc(row?.original?.readingDate).format("YYYY-MM-DD")}</span>
                {" "}
                {moment().diff(moment(row?.original?.readingDate).format("YYYY-MM-DD"), "seconds") < 0 ?
                  <i className="far fa-question-circle big-font gcolor ml-2" >
                    <span className="tooltiptext bold" style={{ width: "fit-content", padding: "10px", fontWeight: "bold" }}>
                      {moment(row?.original?.readingDate).fromNow(true)} before due
                    </span>
                  </i> : <i className="fas fa-exclamation-circle overdue" >
                    <span className="tooltiptext" style={{ width: "fit-content", padding: "10px" }}>
                      {moment(row?.original?.readingDate).fromNow(true)} overdue
                    </span>
                  </i>}
              </> : row?.original?.readingDate
          )
        },
      },
      {
        Header: 'Created Date',
        Cell: ({ row }) => {
          return  moment(row?.original?.created_at).format("YYYY-MM-DD")
        } 
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
      },
      {
        Header: 'Unit',
        accessor: 'unit',
      },

      {
        Header: 'Location',
        accessor: 'location'
      },
      {
        Header: 'Asset',
        accessor: 'asset'
      },
      {
        Header: 'Priority',
        Cell: ({ row }) => {
          let priorityClass = classes.hPriority
          if (row.original.priority === "Medium") {
            priorityClass = classes.mPriority
          }
          if (row.original.priority === "Low ") {
            priorityClass = classes.lPriority
          }
          return (
            <>
              {row.original.priority ?
                <span className={`${priorityClass} ${classes.labels}`}>{row.original.priority}</span> :
                <span className={classes.pr}>—</span>
              }
              {/* {row.original.category ? <span className={classes.category} title={row.original.category}>{row.original.category?.length > 10 ? `${row.original.category.slice(0,10)}...` : row.original.category}</span> :  
                <span className={classes.pr}>—</span> 
               } */}
            </>
          )

        },
      },
      {
        id: 'delete-table-column',
        Cell: ({ row }) => {
          let deleteBtn = <span title='Delete Meter'
            onClick={() => {
              dispatch(openMeterModalDelete())
              setRowId(row.original.id)
            }}
            id={row.id} className={`tertiary-btn btn ${classes['del-bt']} `}><FontAwesomeIcon icon={faTrashCan} />
          </span>
          if ((user?.role === "Technical Team") ||
            (user?.role === "Manager" && row.original?.createdBy !== "Manager")) {
            deleteBtn = ""
          }
          return deleteBtn
        },
      },

    ],
    [user]
  )

  const data = meterData
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    allColumns,
  } = useTable({
    columns,
    data,
    manualPagination: true
  }, useGlobalFilter, useExpanded)

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {iSShowFilter && <div className={classes.filterTable}>
            <div className={classes.wraper}>
              <h4>Table columns</h4>
              <button type='button' onClick={() => setISShowCheckboxs(!iSShowCheckboxs)}>
                <FontAwesomeIcon icon={faAngleDown} />
              </button>
            </div>
            {iSShowCheckboxs && <div className={classes.checkboxs}>
              {/* Loop through columns data to create checkbox */}
              {allColumns.map((column) => {
                if (column.id !== "delete-table-column") {
                  return <div key={column.id} className={classes.checkbox}>
                    <input type="checkbox" {...column.getToggleHiddenProps()} />
                    <span>{column.Header}</span>
                  </div>
                }
              })}
              <br />
            </div>}
          </div>}



          <div className={classes.scrollX}>
            <table {...getTableProps()} className={classes.table}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.length !== 0 ? rows?.map((row) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>

                      {row.cells.map(cell => {
                        return <td
                          {...cell.getCellProps()}
                          onClick={() => getSelectedRowwValues(row.original, cell)}
                        >
                          {cell.column.Header === "Reading Date" ? <span style={{ color: "#db1f48" }}> {cell.render('Cell')} </span> :
                            cell.render('Cell')}

                        </td>
                      })}
                    </tr>
                  )
                }) : <tr> <td className={classes.noResults} colSpan="9">No Results Found </td></tr>}
              </tbody>
            </table>
          </div>
          {meterModalDelete && (
            <PopUpDeleteModal
              showModal={meterModalDelete}
              handleClose={() => dispatch(closeMeterModalDelete())}
              handleConfirm={handleDelete}
              modalTitle="Delete meter"
              modalBodyText="Are you sure you want to delete this meter?"
            />
          )}
        </>
      )}
    </>
  )
}

export default MeterTable






