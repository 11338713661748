import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import workOrderValidatorService from './workOrderValidatorService';
import { smartTryCatch } from '../../../utils';

const initState = {
  workOrderValidators: [],
  validatorLocation: '',
  validatorPerson: '',
  validatorActive: false,

  workOrderValidatorModalDelete:false,
};

export const createWorkOrderValidators = createAsyncThunk(
  'createWorkOrderValidators',
  async (data, thunkAPI) => {
    const createWO = await smartTryCatch(
      workOrderValidatorService.createWorkOrderValidator,
      data,
      thunkAPI,
    );
    return createWO;
  },
);

export const getWorkOrderValidatorByAuthentication = createAsyncThunk(
  'getWorkOrderValidatorByAuthentication',
  async (data, thunkAPI) => {
    const getWo = await smartTryCatch(
      workOrderValidatorService.getWorkOrderValidatorByAuthentication,
      data,
      thunkAPI,
    );
    return getWo;
  },
);

export const deleteWorkOrderValidators = createAsyncThunk(
  'deleteWorkOrderValidators',
  async (data, thunkAPI) => {
    const deleteWo = await smartTryCatch(
      workOrderValidatorService.deleteWorkOrderValidator,
      data,
      thunkAPI,
    );
    return deleteWo;
  },
);

export const updateWorkOrderValidators = createAsyncThunk(
  'updateWorkOrderValidators',
  async (data, thunkAPI) => {
    const update = await smartTryCatch(
      workOrderValidatorService.updateWorkOrderValidator,
      data,
      thunkAPI,
    );
    return update;
  },
);

export const workOrderValidatorSlice = createSlice({
  name: 'workOrderValidator',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isWorkOrderValidatorCreateError = false;
      state.isWorkOrderValidatorCreateSuccess = false;
      state.isWorkOrderValidatorCreateLoading = false;
      state.isWorkOrderValidatorGetError = false;
      state.isWorkOrderValidatorGetSuccess = false;
      state.isWorkOrderValidatorGetLoading = false;
      state.isWorkOrderValidatorDeleteError = false;
      state.isWorkOrderValidatorDeleteSuccess = false;
      state.isWorkOrderValidatorDeleteLoading = false;
      state.isWorkOrderValidatorUpdateError = false;
      state.isWorkOrderValidatorUpdateSuccess = false;
      state.isWorkOrderValidatorUpdateLoading = false;
      state.workOrderValidatorModalDelete = false;
      // state.workOrderValidator = null;
    },

    openWorkOrderValidatorModalDelete: (state) => {
      state.workOrderValidatorModalDelete = true;
    },
    closeWorkOrderValidatorModalDelete: (state) => {
      state.workOrderValidatorModalDelete = false;
    },
    setWorkOrderValidator: (state, action) => {
      state.workOrderValidator = action.payload;
    },
    setWorkOrderValidatorName: (state, action) => {
      state.workOrderValidatorName = action.payload;
    },
    setValidatorLocation: (state, action) => {
      state.validatorLocation = action.payload;
    },
    setValidatorPerson: (state, action) => {
      state.validatorPerson = action.payload;
    },
    setValidatorActive: (state, action) => {
      state.validatorActive = action.payload;
    },
    resetWorkOrderVaildatorField:(state)=>{
      state.validatorLocation = "";
      state.validatorPerson = "";
      state.validatorActive =false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkOrderValidatorByAuthentication.pending, (state) => {
        state.isWorkOrderValidatorGetLoading = true;
        state.isWorkOrderValidatorGetSuccess = false;
        state.isWorkOrderValidatorGetError = false;
      })
      .addCase(getWorkOrderValidatorByAuthentication.fulfilled, (state, action) => {
        state.isWorkOrderValidatorGetLoading = false;
        state.isWorkOrderValidatorGetSuccess = true;
        state.isWorkOrderValidatorGetError = false;
        state.workOrderValidators = action.payload;
      })
      .addCase(getWorkOrderValidatorByAuthentication.rejected, (state) => {
        state.isWorkOrderValidatorGetLoading = false;
        state.isWorkOrderValidatorGetSuccess = false;
        state.isWorkOrderValidatorGetError = true;
      })
      .addCase(createWorkOrderValidators.pending, (state) => {
        state.isWorkOrderValidatorCreateLoading = true;
        state.isWorkOrderValidatorCreateSuccess = false;
        state.isWorkOrderValidatorCreateError = false;
      })
      .addCase(createWorkOrderValidators.fulfilled, (state, action) => {
        state.isWorkOrderValidatorCreateLoading = false;
        state.isWorkOrderValidatorCreateSuccess = true;
        state.isWorkOrderValidatorCreateError = false;
        state.workOrderValidators.push(action.payload);
      })
      .addCase(createWorkOrderValidators.rejected, (state) => {
        state.isWorkOrderValidatorCreateLoading = false;
        state.isWorkOrderValidatorCreateSuccess = false;
        state.isWorkOrderValidatorCreateError = true;
      })
      .addCase(deleteWorkOrderValidators.pending, (state) => {
        state.isWorkOrderValidatorDeleteLoading = true;
        state.isWorkOrderValidatorDeleteSuccess = false;
        state.isWorkOrderValidatorDeleteError = false;
      })
      .addCase(deleteWorkOrderValidators.fulfilled, (state, action) => {
        state.isWorkOrderValidatorDeleteLoading = false;
        state.isWorkOrderValidatorDeleteSuccess = true;
        state.isWorkOrderValidatorDeleteError = false;
        // state.workOrderValidators = action.payload;
        state.workOrderValidators = state.workOrderValidators.filter(
          (item) => item.id !== action.payload.id,
        );
      })
      .addCase(deleteWorkOrderValidators.rejected, (state) => {
        state.isWorkOrderValidatorDeleteLoading = false;
        state.isWorkOrderValidatorDeleteSuccess = false;
        state.isWorkOrderValidatorDeleteError = true;
      })
      .addCase(updateWorkOrderValidators.pending, (state) => {
        state.isWorkOrderValidatorUpdateLoading = true;
        state.isWorkOrderValidatorUpdateSuccess = false;
        state.isWorkOrderValidatorUpdateError = false;
      })
      .addCase(updateWorkOrderValidators.fulfilled, (state, action) => {
        state.isWorkOrderValidatorUpdateLoading = false;
        state.isWorkOrderValidatorUpdateSuccess = true;
        state.isWorkOrderValidatorUpdateError = false;
        state.workOrderValidators = state.workOrderValidators.map((item) =>
        item.id === action.payload.id ? action.payload : item,
      );
      })
      .addCase(updateWorkOrderValidators.rejected, (state) => {
        state.isWorkOrderValidatorUpdateLoading = false;
        state.isWorkOrderValidatorUpdateSuccess = false;
        state.isWorkOrderValidatorUpdateError = true;
      });
  },
});

export const {
  reset,
  openWorkOrderValidatorModalCreate,
  closeWorkOrderValidatorModalCreate,
  openWorkOrderValidatorModalUpdate,
  closeWorkOrderValidatorModalUpdate,
  openWorkOrderValidatorModalDelete,
  closeWorkOrderValidatorModalDelete,
  setWorkOrderValidator,
  setWorkOrderValidatorName,
  setValidatorLocation,
  setValidatorPerson,
  setValidatorActive,
  resetWorkOrderVaildatorField
} = workOrderValidatorSlice.actions;

export default workOrderValidatorSlice.reducer;