import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import assetTypeService from './assetTypeService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // assetType
  assetTypes: [],
  assetType: {},

  // assetType fields
  assetTypeName: '',

  // modal
  assetTypeModalCreate: false,
  assetTypeModalDelete: false,
  assetTypeModalUpdate: false,

  // success
  isAssetTypeGetSuccess: false,
  isAssetTypeCreateSuccess: false,
  isAssetTypeDeleteSuccess: false,
  isAssetTypeUpdateSuccess: false,

  // loading
  isAssetTypeGetLoading: false,
  isAssetTypeDeleteLoading: false,
  isAssetTypeUpdateLoading: false,
  isAssetTypeCreateLoading: false,

  // error
  isAssetTypeGetError: false,
  isAssetTypeCreateError: false,
  isAssetTypeDeleteError: false,
  isAssetTypeUpdateError: false,
};

export const getAssetTypesByAuthentication = createAsyncThunk(
  'getAssetTypesByAuthentication',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      assetTypeService.getAssetTypesByAuthentication,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const getAssetTypeById = createAsyncThunk(
  'getAssetTypeById',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(assetTypeService.getAssetTypeById, data, thunkAPI);
    return res;
  },
);

export const createAssetType = createAsyncThunk(
  'createAssetType',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(assetTypeService.createAssetType, data, thunkAPI);
    return res;
  },
);

export const deleteAssetType = createAsyncThunk(
  'deleteAssetType',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(assetTypeService.deleteAssetType, data, thunkAPI);
    return res;
  },
);

export const updateAssetType = createAsyncThunk(
  'updateAssetType',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(assetTypeService.updateAssetType, data, thunkAPI);
    return res;
  },
);

export const assetTypeSlice = createSlice({
  name: 'assetType',
  initialState: initState,
  reducers: {
    setAssetType: (state, action) => {
      state.assetType = action.payload;
    },
    openAssetTypeModalDelete: state => {
      state.assetTypeModalDelete = true;
    },
    closeAssetTypeModalDelete: state => {
      state.assetTypeModalDelete = false;
    },
    openAssetTypeModalUpdate: state => {
      state.assetTypeModalUpdate = true;
    },
    closeAssetTypeModalUpdate: state => {
      state.assetTypeModalUpdate = false;
    },
    openAssetTypeModalCreate: state => {
      state.assetTypeModalCreate = true;
    },
    closeAssetTypeModalCreate: state => {
      state.assetTypeModalCreate = false;
    },
    setAssetTypeName: (state, action) => {
      state.assetTypeName = action.payload;
    },
    reset: state => {
      state.isAssetTypeCreateError = false;
      state.isAssetTypeCreateSuccess = false;
      state.isAssetTypeCreateLoading = false;
      state.isAssetTypeGetError = false;
      state.isAssetTypeGetSuccess = false;
      state.isAssetTypeGetLoading = false;
      state.isAssetTypeDeleteError = false;
      state.isAssetTypeDeleteSuccess = false;
      state.isAssetTypeDeleteLoading = false;
      state.isAssetTypeUpdateError = false;
      state.isAssetTypeUpdateSuccess = false;
      state.isAssetTypeUpdateLoading = false;
      state.assetTypeModalCreate = false;
      state.assetTypeModalDelete = false;
      state.assetTypeModalUpdate = false;
      // state.assetType = {};
      // state.assetTypeName = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAssetTypesByAuthentication.pending, state => {
        state.isAssetTypeGetLoading = true;
        state.isAssetTypeGetSuccess = false;
        state.isAssetTypeGetError = false;
      })
      .addCase(getAssetTypesByAuthentication.fulfilled, (state, action) => {
        state.isAssetTypeGetLoading = false;
        state.isAssetTypeGetSuccess = true;
        state.isAssetTypeGetError = false;
        state.assetTypes = action.payload;
      })
      .addCase(getAssetTypesByAuthentication.rejected, state => {
        state.isAssetTypeGetLoading = false;
        state.isAssetTypeGetSuccess = false;
        state.isAssetTypeGetError = true;
      })
      .addCase(getAssetTypeById.pending, state => {
        state.isAssetTypeGetLoading = true;
        state.isAssetTypeGetSuccess = false;
        state.isAssetTypeGetError = false;
      })
      .addCase(getAssetTypeById.fulfilled, (state, action) => {
        state.isAssetTypeGetLoading = false;
        state.isAssetTypeGetSuccess = true;
        state.isAssetTypeGetError = false;
        state.assetTypes = action.payload;
      })
      .addCase(getAssetTypeById.rejected, state => {
        state.isAssetTypeGetLoading = false;
        state.isAssetTypeGetSuccess = false;
        state.isAssetTypeGetError = true;
      })
      .addCase(createAssetType.pending, state => {
        state.isAssetTypeCreateLoading = true;
        state.isAssetTypeCreateSuccess = false;
        state.isAssetTypeCreateError = false;
      })
      .addCase(createAssetType.fulfilled, (state, action) => {
        state.isAssetTypeCreateLoading = false;
        state.isAssetTypeCreateSuccess = true;
        state.isAssetTypeCreateError = false;
        state.assetTypes.push(action.payload);
      })
      .addCase(createAssetType.rejected, state => {
        state.isAssetTypeCreateLoading = false;
        state.isAssetTypeCreateSuccess = false;
        state.isAssetTypeCreateError = true;
      })
      .addCase(deleteAssetType.pending, state => {
        state.isAssetTypeDeleteLoading = true;
        state.isAssetTypeDeleteSuccess = false;
        state.isAssetTypeDeleteError = false;
      })
      .addCase(deleteAssetType.fulfilled, (state, action) => {
        state.isAssetTypeDeleteLoading = false;
        state.isAssetTypeDeleteSuccess = true;
        state.isAssetTypeDeleteError = false;
        state.assetTypes = state.assetTypes.filter(
          assetType => assetType.id !== action.payload,
        );
      })
      .addCase(deleteAssetType.rejected, state => {
        state.isAssetTypeDeleteLoading = false;
        state.isAssetTypeDeleteSuccess = false;
        state.isAssetTypeDeleteError = true;
      })
      .addCase(updateAssetType.pending, state => {
        state.isAssetTypeUpdateLoading = true;
        state.isAssetTypeUpdateSuccess = false;
        state.isAssetTypeUpdateError = false;
      })
      .addCase(updateAssetType.fulfilled, (state, action) => {
        state.isAssetTypeUpdateLoading = false;
        state.isAssetTypeUpdateSuccess = true;
        state.isAssetTypeUpdateError = false;
        state.assetTypes = state.assetTypes.map(assetType =>
          assetType.id === action.payload.id ? action.payload : assetType,
        );
      })
      .addCase(updateAssetType.rejected, state => {
        state.isAssetTypeUpdateLoading = false;
        state.isAssetTypeUpdateSuccess = false;
        state.isAssetTypeUpdateError = true;
      });
  },
});

export const {
  setAssetType,
  openAssetTypeModalDelete,
  closeAssetTypeModalDelete,
  openAssetTypeModalUpdate,
  closeAssetTypeModalUpdate,
  openAssetTypeModalCreate,
  closeAssetTypeModalCreate,
  reset,
} = assetTypeSlice.actions;

export default assetTypeSlice.reducer;
