import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/prefer-default-export
export const changeFavicon = () => {
  const [info, setInfo] = useState({
    name: '',
    address: '',
    phone_number: '',
    website: '',
    logo: '',
  });
  const { user } = useSelector(state => state.user);
  const { profile } = useSelector(state => state.company);

  useEffect(() => {
    setInfo({
      name: profile?.name,
      address: profile?.address,
      phone_number: profile?.phone_number,
      website: profile?.website,
      logo: profile?.logo !== null && profile?.logo !== undefined ? profile?.logo : info?.logo,
    });

    if (profile && user) {
      const favicon = document.querySelector('link[rel="icon"]') || document.createElement('link');
      favicon.rel = 'icon';
      favicon.href = profile?.logo && profile?.logo;
      document.head.appendChild(favicon);
      document.title = profile?.name;
    }
  }, [profile, user]);
};
