import React from 'react';

export default function SettingsIcon({ fillColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
      <path
        d="M16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
        // stroke="black"
        // stroke-width="2"
        stroke={fillColor === "primary" ? "var(--primary-color)" : "var(--grey-content)"}
        strokeWidth="2"
      />
      <path
        d="M14 3.5147C14 2.67815 13.3218 2 12.4853 2H11.5147C10.6781 2 10 2.67814 10 3.51466C10 4.86408 8.36846 5.53985 7.41428 4.58566C6.82275 3.99414 5.86369 3.99413 5.27217 4.58565L4.58589 5.27193C3.99434 5.86348 3.99434 6.82258 4.58589 7.41413C5.54014 8.36838 4.86429 10 3.51478 10C2.67819 10 2 10.6782 2 11.5148V12.4853C2 13.3218 2.67816 14 3.51471 14C4.86415 14 5.53997 15.6315 4.58577 16.5857C3.99425 17.1773 3.99425 18.1363 4.58577 18.7278L5.27209 19.4141C5.86362 20.0057 6.82268 20.0057 7.41422 19.4141C8.36844 18.4599 10 19.1358 10 20.4852C10 21.3218 10.6782 22 11.5147 22H12.4852C13.3218 22 14 21.3218 14 20.4852C14 19.1357 15.6316 18.4598 16.5858 19.4141C17.1774 20.0056 18.1365 20.0056 18.728 19.4141L19.4143 18.7278C20.0058 18.1363 20.0058 17.1772 19.4143 16.5857C18.4601 15.6315 19.1359 14 20.4853 14C21.3219 14 22 13.3219 22 12.4853V11.5147C22 10.6782 21.3218 10 20.4853 10C19.1358 10 18.46 8.3684 19.4142 7.41417C20.0057 6.82262 20.0057 5.86353 19.4142 5.27199L18.7279 4.58572C18.1364 3.99418 17.1773 3.99419 16.5858 4.58573C15.6316 5.53994 14 4.86415 14 3.5147Z"
        stroke={fillColor === "primary" ? "var(--primary-color)" : "var(--grey-content)"}
        strokeWidth="2"
        // stroke="black"
        // stroke-width="2"
      />
    </svg>
  );
}
