import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BoardItem from './BoardItem';

export default function BoardColumn({ title = 'Placeholder', dotColor = '#36383C', data, handleSelectedEvent, getFreQ, isMantenance }) {
  return (
    <div
      style={{
        backgroundColor: '#9DA0A629',
        height: '100%',
        flex: '1',
        borderRadius: '8px',
        padding: '8px 8px 8px 12px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Header Section */}
      <div
        style={{
          fontSize: '16px',
          marginBottom: '8px',
          fontWeight: '600',
          color: '#757980',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <FontAwesomeIcon
          icon="circle"
          style={{ fontSize: '12px', marginRight: '8px' }}
          color={dotColor}
        />
        <span>{title} {data?.length}</span>
      </div>

      {/* Items Section */}
      <div
        style={{
          flex: '1',
          overflowY: 'auto', // Allows scrolling when content exceeds the container height
          paddingRight: '4px', // Add some padding to avoid scrollbar overlap with content
          display: 'flex',
          gap: '8px',
          flexDirection: 'column',
        }}
      >
        {data?.map((d) => (
          <BoardItem key={d.id} id={d.reference_number || d?.ticket_number} type={d.ticket_type} title={d.title} date={d.due_finish} schedule={d.schedule} event ={d} handleSelectedEvent={handleSelectedEvent} getFreQ={getFreQ}
          isMantenance={isMantenance}
          />
        ))}
      </div>
    </div>
  );
}
