import React from "react";
import moment from "moment";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "../../../../assets/svg-icons/DeleteIcon";
import { deleteGenerateMaintenanceTicket, generateMaintenanceTicket, setTempTickets } from "../../../../redux/features/maintenance/maintenanceSlice";
import { getMaintenanceById, openWorkOrderModalUpdate, resetWorkOrder, setOpenFrom, setWorkOrder } from "../../../../redux/features/workOrder/workOrderSlice";
import { setAsset } from "../../../../redux/features/asset/assetSlice";
import { selectActions } from "../../../../redux/features/selectOptions/selectSlice";

/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */

function PMTickets() {

    const dispatch = useDispatch()
    const {
        tempTickets,
        maintenance
    } = useSelector((state) => state.maintenance);

    const frqType = maintenance?.schedule?.frequency
    const frqNumber = maintenance?.schedule?.frequency_number
    const weekDay = maintenance?.schedule?.weekly_day
    const dayOfMonth = maintenance?.schedule?.day_of_month
    const monthOfYear = maintenance?.schedule?.month_of_year

    const THEFRQ = frqType === "day" ? { frequencyType: "Daily", frqSentence: `Every ${frqNumber} Day` } :
    frqType === "week" ? { frequencyType: "Weekly", frqSentence: `Every ${frqNumber} Week${weekDay ? `, on ${weekDay}` : "" }` } :
      frqType === "month" ? { frequencyType: "Monthly", frqSentence: `Every ${frqNumber} Month${dayOfMonth ? `, on ${moment(dayOfMonth, 'D').format('Do')}`: ""}` } :
        { frequencyType: "Yearly", frqSentence: `Every ${frqNumber} Year${(dayOfMonth && monthOfYear) ? `, on ${moment(dayOfMonth, 'D').format('Do')} ${moment(monthOfYear, 'M').format('MMMM')}`: ""}` }

    const handleSelectAll = (e) => {
        const isSelected = e.target.checked;
        dispatch(setTempTickets(tempTickets?.map(item => {
            if (!item.is_generated) {
                return {
                    ...item,
                    selected: isSelected
                }
            }
            return {
                ...item,
                selected: false
            }
        })));
    };

    const handleSelectTicket = (itemId) => {
        dispatch(setTempTickets(tempTickets.map(item =>
            item.id === itemId
                ? {
                    ...item,
                    selected: !item.selected,
                }
                : item
        )));
    };

    function selectedTickets() {
        const selectedTicketsIds = []
        tempTickets.forEach(item => {
            if (item.selected) {
                selectedTicketsIds.push(item.id)
            }
        })
        return selectedTicketsIds
    }
    const handleGenerateTicket = () => {
        dispatch(generateMaintenanceTicket({ temp_ticket_ids: selectedTickets(), main_ticket_id: maintenance?.id }))
        dispatch(setTempTickets(tempTickets.map(item => {
            return { ...item, selected: false }
        })));
    };

    const handleDeleteTicket = async () => {
        await dispatch(deleteGenerateMaintenanceTicket({ "temp_ticket_ids": selectedTickets(), "main_ticket_id": maintenance?.id }))
    };

    const handelOpenIndvidualPM = async (ticketId, ticket) => {
        // const filterData = { site: "", sublocation: "", filterCategory: "", type: "", staff: "", status:""}
        if (ticket?.is_generated) {
            dispatch(setWorkOrder({}))
            dispatch(resetWorkOrder())
            dispatch(setAsset(null))
            dispatch(dispatch(selectActions.reset()))
            // dispatch(
            //     getPaginationPM({ rowPerPage: 10, currentPage: 1, searchValue: ticketTitle, ...filterData }),
            // );
            await dispatch(getMaintenanceById(ticketId));
            dispatch(openWorkOrderModalUpdate());
            dispatch(setOpenFrom("Maintenance"));
        } else {
            toast.warning('This ticket not generated yet', {
                toastId: 'CreatedEventWarning',
            });
        }


    }
    return (
        <div className="schedule-settings">
            <div className="schedule-settings-header">
                <h3>Schedule settings</h3>
                <div>
                    <button disabled={selectedTickets().length === 0} type="button" className="generate-btn" onClick={handleGenerateTicket}>Generate ticket {selectedTickets().length > 0 && `(${selectedTickets().length})`}</button>
                    <button disabled={selectedTickets().length === 0} className="btn new-btn" type="button" onClick={handleDeleteTicket}><DeleteIcon /></button>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th><input type="checkbox"
                            onChange={handleSelectAll}
                            checked={
                                tempTickets?.filter(item => !item?.is_generated).length > 0 &&
                                tempTickets?.filter(item => !item?.is_generated).every(item => item?.selected)
                            }
                        /> </th>
                        <th>PM Ticket</th>
                        <th>Due date</th>
                        <th>PM no.</th>
                    </tr>
                </thead>
                <tbody>
                    {tempTickets?.map((item) => (
                        <tr key={item.id}>
                            <td>
                                <input type="checkbox" checked={item?.selected}
                                    onChange={() => handleSelectTicket(item.id)}
                                    disabled={item?.is_generated}
                                />
                            </td>
                            <td className="schedule-settings-name">
                                <span onClick={() => handelOpenIndvidualPM(item?.id, item)} title="Open PM ticket">{item.title}</span> <br />
                                <span>{THEFRQ?.frequencyType} - {THEFRQ?.frqSentence}</span>
                            </td>
                            <td className="schedule-settings-date">{moment.utc(item?.due_finish).format('DD MMMM YYYY')}</td>
                            <td className={`schedule-settings-${item?.generated ? "generated" : "not-generated"}`}>{item?.is_generated ? item?.ticket_number : "Not generated"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PMTickets