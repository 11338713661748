import React from 'react';

export default function ChartBars({fillColor}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
      <path
        d="M14 15C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13V15ZM3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2H3ZM3.45603 13.7094L3.91002 12.8183H3.91002L3.45603 13.7094ZM2.29065 12.544L3.18166 12.09L3.18166 12.09L2.29065 12.544ZM4.33333 11.3333C4.33333 11.8856 4.78105 12.3333 5.33333 12.3333C5.88562 12.3333 6.33333 11.8856 6.33333 11.3333H4.33333ZM6.33333 8C6.33333 7.44772 5.88562 7 5.33333 7C4.78105 7 4.33333 7.44772 4.33333 8H6.33333ZM7.66667 11.3333C7.66667 11.8856 8.11438 12.3333 8.66667 12.3333C9.21895 12.3333 9.66667 11.8856 9.66667 11.3333H7.66667ZM9.66667 2C9.66667 1.44772 9.21895 1 8.66667 1C8.11438 1 7.66667 1.44772 7.66667 2L9.66667 2ZM11 11.3333C11 11.8856 11.4477 12.3333 12 12.3333C12.5523 12.3333 13 11.8856 13 11.3333H11ZM13 5.33333C13 4.78105 12.5523 4.33333 12 4.33333C11.4477 4.33333 11 4.78105 11 5.33333H13ZM14 13H6.26667V15H14V13ZM3 9.73333V2H1V9.73333H3ZM6.26667 13C5.50343 13 4.99114 12.9992 4.59664 12.967C4.21381 12.9357 4.03076 12.8799 3.91002 12.8183L3.00203 14.6004C3.45172 14.8295 3.92726 14.919 4.43377 14.9603C4.92861 15.0008 5.53643 15 6.26667 15V13ZM1 9.73333C1 10.4636 0.999222 11.0714 1.03965 11.5662C1.08104 12.0727 1.17052 12.5483 1.39964 12.998L3.18166 12.09C3.12013 11.9692 3.06429 11.7862 3.03301 11.4034C3.00078 11.0089 3 10.4966 3 9.73333H1ZM3.91002 12.8183C3.59641 12.6586 3.34144 12.4036 3.18166 12.09L1.39964 12.998C1.75118 13.6879 2.31211 14.2488 3.00203 14.6004L3.91002 12.8183ZM6.33333 11.3333V8H4.33333V11.3333H6.33333ZM9.66667 11.3333L9.66667 2L7.66667 2L7.66667 11.3333H9.66667ZM13 11.3333V5.33333H11V11.3333H13Z"
        fill={fillColor === "primary" ? "var(--primary-color)" : "var(--grey-content)"}
      />
    </svg>
  );
}
