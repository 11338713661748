
import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getAssetLoanStatusByAuthentication = async () => {
  const res = await http.get(
    `${BASE_URL}/asset-status/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of asset status by its company ID failed",
    res
  );
};

const getAssetLoanStatusById = async (assetStatusId) => {
  const res = await http.get(
    `${BASE_URL}/asset-status/${assetStatusId}`
  );

  return assert(
    res,
    res.data,
    "Retrieval of asset status by its ID failed",
    res
  );
};

// const createAssetStatus = async (data) => {
//   const res = await http.post(
//     `${BASE_URL}/asset-status/`,
//     data
//   );

//   return assert(res, res.data, "Creation of asset status failed", res);
// };

const updateAssetLoanStatus = async (data) => {
  const res = await http.put(
    `${BASE_URL}/asset-status/${data.id}/`,
    data
  );
  return assert(res, res.data, "Update of asset status failed", res);
};
const checkOutAsset = async (data) => {
  const res = await http.post(
    `${BASE_URL}/asset-loans/check_out/`,
    data
  );
  return assert(res, res.data, "Asset Checkout Failed", res);
};
const checkInAsset = async (data) => {
  const res = await http.post(
    `${BASE_URL}/asset-loans/check_in/${data.asset}/`,
    {check_in_comment : data.check_in_comment}
  );
  return assert(res, res.data, "Asset Checkout Failed", res);
};

const assetLoanStatusService = {
  getAssetLoanStatusByAuthentication,
  getAssetLoanStatusById,
//   createAssetLoanStatus,
  updateAssetLoanStatus,
  checkOutAsset,
  checkInAsset
};

export default assetLoanStatusService;
