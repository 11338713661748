/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */

import React from 'react'
import {Line} from "react-chartjs-2"
import {Chart as ChartJs} from "chart.js/auto"
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
  } from 'chart.js';

function DeprictionChart ({chartData})  {
ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        
      };
  return (
    <Line  data = {chartData} options={options} />
  )
}

export default DeprictionChart