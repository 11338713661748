import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PMCalendarFilter from './filters/PMCalendarFilter';
import PMCalendarTable from './PMCalendarTable';
import { getMaintenacePlanner } from '../../../redux/features/dashboard/dashboardSlice';
import Loader from '../../../components/loader/loader';

function MaintenancePlannerCalendar(
    {
        getFreQ,
        selectedCalendarMode,
        setSelectedCalendarMode,
    }
) {
    const {
        isEventsGetLoading,
        isEventUpdateLoading,
        maintenanceChartSite,
        maintenanceChartSublocation,
        maintenanceChartStaff,
        maintenanceChartStatus,
        maintenanceChartFrequency,
    } = useSelector((state) => state.dashboard);

    const dispatch = useDispatch()


    const getQueryString = (data, filterName) => {
        return data
            .filter(feature => feature.selected) // Only keep selected items
            .map(feature => `${filterName}=${filterName === "frequency" ? feature.value  :feature.label}`)
            .join('&');
    }

    const getMaintenacePlannerChart = async () => {
        const filters = {
            status: getQueryString(maintenanceChartStatus, "status"),
            frequency: getQueryString(maintenanceChartFrequency, "frequency"),
            site: maintenanceChartSite?.value || null,
            sublocation: maintenanceChartSublocation?.value || null,
            staff: maintenanceChartStaff?.value || null,
            selectedCalendarMode,
        }
        await dispatch(getMaintenacePlanner(filters))
    }

    useEffect(() => {
        getMaintenacePlannerChart()
    }, [selectedCalendarMode])

    return (
        (isEventsGetLoading || isEventUpdateLoading) ? (
            <Loader />
        ) :
            <div style={{ margin: '0 16px' }}>
                    <PMCalendarFilter
                        selectedCalendarMode={selectedCalendarMode}
                        setSelectedCalendarMode={setSelectedCalendarMode}
                        getMaintenacePlannerChart={getMaintenacePlannerChart}
                    />
                    <div style={{ maxHeight: "70vh" ,overflow: 'auto', padding: '0 0 40px', marginTop:"20px" }}>
                        <PMCalendarTable
                            getFreQ={getFreQ}
                            selectedCalendarMode={selectedCalendarMode}
                        />
                    </div>
            </div>
    )
}

export default MaintenancePlannerCalendar