import React, { useState, useEffect }  from 'react';
// import { getTechnicianPositionsByAuthentication } from '../../redux/features/technicianPosition/technicianPositionSlice';
import { useSelector , useDispatch } from 'react-redux';
import { getDepartmentsByAuthentication } from '../../redux/features/department/departmentSlice';
import { updateTeam } from '../../redux/features/teams/teamSlice';

export default function RequestPopup( ) {
  const { departments } = useSelector((state) => state.department);
  const { team } = useSelector((state) => state.teams);
  const [deps, setDeps] = useState([]);

  
  const [userInfo, setUserInfo] = useState({

    first_name: team.first_name,
    last_name: team.last_name,
    profile: {
      department: team.profile.department?.id || 0,
      job_title :team.job_title
    },

  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartmentsByAuthentication());

  }, [dispatch]);



  useEffect(() => {
    setDeps(departments);
  }, [departments]);
  const handleUpdate = (e) => {
    e.preventDefault();
    const data= {'id':team.id , 'userInfo':userInfo }
    dispatch(updateTeam(data));
  };
  return (
    <>
      <div
        className="card-body mx-auto row col-lg-12 pb-0"
        style={{ paddingBottom: '0rem !important' }}
      >
        <div className="col col-lg-12 col-sm-12 mr-2">
          <div className="row mb-0">
            <div className=" col-lg-12 col-sm-12">
              <div className="row mb-0">
                <div className="row mb-0">
                  <div className=" col-lg-6 col-sm-12">
                    {' '}
                    <span className="font-weight-normal">
                      Email <span className="font-danger m-0">*</span>
                    </span>
                    <input
                      name="username"
                      type="email"
                      className="form-control mb-2"
                      required
                      disabled
                      placeholder="Email"
                      id="username"
                      onChange="remove_error_msg('username')"
                      value={team.email}
                    />
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <span className="font-weight-normal">
                      Account Type <span className="font-danger m-0">*</span>
                    </span>

                    <select
                      required
                      className="mb-2"
                      name="role"
                      disabled
                      id="role"
                      onChange="remove_error_msg('role'); show_line_manager(this)"
                    >
                      <option selected>
                        {team.role === 'CliAdm' ? 'Administrator' : team.role}
                      </option>
                    </select>

                    <div className="col-lg-12 col-sm-12 m-0">
                      <span className="font-danger" id="role_error" />
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    {' '}
                    <span className="font-weight-normal">
                      First Name <span className="font-danger m-0">*</span>
                    </span>
                    <input
                      name="first name"
                      type="text"
                      className="form-control mb-2"
                      required
                      placeholder="First Name"
                      id="firstname"
                      onChange={(e) => setUserInfo({ ...userInfo, first_name: e.target.value })}
                      value={userInfo.first_name}
                    />
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    {' '}
                    <span className="font-weight-normal">
                      Last Name <span className="font-danger m-0">*</span>
                    </span>
                    <input
                      name="lastname"
                      type="text"
                      className="form-control mb-2"
                      required
                      placeholder="Last name"
                      id="lastname"
                      onChange={(e) => setUserInfo({ ...userInfo, last_name: e.target.value })}
                      value={userInfo.last_name}
                    />
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <span className="font-weight-normal">
                      Department <span className="font-danger m-0">*</span>
                    </span>

    
                    <select
                      required
                      className="mb-2"
                      name="role"
                      id="role"
                      onChange={(e) =>
                        setUserInfo({ ...userInfo, profile: {...userInfo.profile ,department: +e.target.value } })
                      }
                    >
                      <option selected value={team?.profile?.department?.id || 0}>
                        
                      {team?.profile?.department !==null ? team?.profile?.department?.name: " --SELECT--"}
                       </option>
                      {deps.map((e) => (
                        <option value={e.id}>{e.name}</option>
                      ))}
                    </select>

                    <div className="col-lg-12 col-sm-12 m-0">
                      <span className="font-danger" id="role_error" />
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    {' '}
                    <span className="font-weight-normal">
                      Position <span className="font-danger m-0">*</span>
                    </span>
                    <input
                      name="position"
                      type="text"
                      className="form-control mb-2"
                      required
                      placeholder="None"
                      id="username"
                      onChange={(e) => setUserInfo({ ...userInfo, profile: {...userInfo.profile ,  job_title: e.target.value } })}
                    
                      value={userInfo.profile.job_title}
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" row  m-0 p-2 page-profile-footer" >
        <button className="btn primary-btn" type="submit" onClick={handleUpdate} >
          Save
        </button>
      </div>
    </>
  );
}
