import React from 'react';

export default function ReloadIcon() {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.57189 12.7142C4.17539 15.3177 8.39649 15.3177 11 12.7142C13.6035 10.1107 13.6035 5.88961 11 3.28612C8.39649 0.682622 4.17539 0.682622 1.57189 3.28612M1 1.3335V3.9335C1 3.97032 1.02985 4.00016 1.06667 4.00016H3.66667"
                stroke="#36383C"
                strokeWidth="1.75"
                strokeLinecap="round" />
        </svg>
    );
} 
