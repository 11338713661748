import React from 'react';

export default function Planner({ fillColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
      <path
        d="M8 6.66666L5.85565 8.61132C5.77429 8.68511 5.64238 8.68511 5.56102 8.61132L4.66667 7.80026M8.66667 10.6667H11.3333M5.33333 3.99999V1.33333M10.6667 3.99999V1.33333M6.26667 14.6667H9.73333C11.2268 14.6667 11.9735 14.6667 12.544 14.376C13.0457 14.1204 13.4537 13.7124 13.7094 13.2106C14 12.6402 14 11.8935 14 10.4V6.93333C14 5.43986 14 4.69312 13.7094 4.12269C13.4537 3.62092 13.0457 3.21297 12.544 2.95731C11.9735 2.66666 11.2268 2.66666 9.73333 2.66666H6.26667C4.77319 2.66666 4.02646 2.66666 3.45603 2.95731C2.95426 3.21297 2.54631 3.62092 2.29065 4.12269C2 4.69312 2 5.43985 2 6.93333V10.4C2 11.8935 2 12.6402 2.29065 13.2106C2.54631 13.7124 2.95426 14.1204 3.45603 14.376C4.02646 14.6667 4.77319 14.6667 6.26667 14.6667Z"
        // stroke="#727478"
        stroke={fillColor === "primary" ? "var(--primary-color)" : "var(--grey-content)"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
