/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import { format, parseISO } from 'date-fns';

// Register necessary chart.js components
ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

function WOPMTrendChart({ trendWorkData }) {
  // Format the dates for display on the x-axis
  const formattedCategories = trendWorkData?.workDates.map(dateString => {
    const date = parseISO(dateString);
    return format(date, 'eee, MMM d, yyyy');
  });

  // Prepare the data for react-chartjs-2
  const chartData = {
    labels: formattedCategories,
    datasets: [
      {
        label: 'Work Order Open',
        data: trendWorkData?.open,
        borderColor: 'rgba(139, 92, 244, 0.5)', // Lighter purple color with 50% opacity
        backgroundColor: 'rgba(139, 92, 244, 0.2)', // Lighter background color with 20% opacity
        borderWidth: 2,
        tension: 0.2, // Slight curve to soften the line
        pointRadius: 0, // Remove circles
        pointHoverRadius: 0, // Remove hover circles
      },
      {
        label: 'Work Order Close',
        data: trendWorkData?.close,
        borderColor: 'rgba(163, 163, 163, 0.5)', // Lighter grey color with 50% opacity
        backgroundColor: 'rgba(163, 163, 163, 0.2)', // Lighter background color with 20% opacity
        borderWidth: 2,
        tension: 0.2, // Slight curve to soften the line
        pointRadius: 0, // Remove circles
        pointHoverRadius: 0, // Remove hover circles
      },
    ],
  };

  // Chart.js options equivalent to ApexCharts options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
        labels: {
          font: {
            size: 14,
            family: 'Source Sans 3',
            weight: 500,
          },
          color: '#191919',
        },
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || '';
            const value = context.raw;
            return `${label}: ${value}`;
          },
          title: function (context) {
            return context[0]?.label || '';
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
        },
        ticks: {
          callback: function (value, index, values) {
            const date = new Date(this.getLabelForValue(value));
            const month = date.toLocaleString('default', { month: 'short' });
            const day = date.getDate();
            return `${month}-${day}`;
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Number of Work Orders',
          font: {
            family: 'Source Sans 3',
            weight: 500,
            size: 14,
          },
          color: '#19191966',
        },
        ticks: {
          stepSize: 1, // Make the steps increase by 1
        },
      },
    },
  };

  return (
    <div id="trendChart">
      <Line data={chartData} options={options} height={350} />
    </div>
  );
}

export default WOPMTrendChart;

