import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getUserProfile = async () => {
  const res = await http.get(`${BASE_URL}/user-profile/`);
  return assert(res, res.data, 'Retrieval of User profile failed', res);
};

const updateUserProfile = async (userProfileData) => {
  const res = await http.put(`${BASE_URL}/user-profile/`, userProfileData);
  return assert(res, res.data, 'Update of User profile failed', res);
};
const updateUserProfileImg = async (userProfileData) => {
  const res = await http.putImg(`${BASE_URL}/user-profile-image/`, userProfileData);

  return assert(res, res.data, 'Update of User profile failed', res);
};



const userService = {
  getUserProfile,
  updateUserProfile,
  updateUserProfileImg
 
};

export default userService;
