/* eslint-disable array-callback-return */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Card from "../../../Ui/Card"
import classes from "../addAsset/addAssetForm/assetForm.module.css"
import AddModal from '../../../components/modals/AddModal';

function FetchAssetDocumentsForm({
  handelChangeDocumentsFormFile,
  handelChangeDocumentsFormFileType ,deleteAttach , 
  handleCreateSaveAttach, attachTable 
  ,setShowModal , showModal }) {

  const handleClose = ()=>{
    setShowModal(false)
  }

  return (
    <div className={classes.assetForm}>
       <Card> 
        <div className={classes.assetInfo}>
      <div className={classes.titleAssetDocuments}>
      <h3>Documents</h3>
      <button onClick={()=> setShowModal(true)}  className='primary-btn btn' type='button'>
      <FontAwesomeIcon icon={faPlus} />
      </button>
      </div>
      <div className={classes.scrollX}>
      <table className={classes.table}>
        <thead>
          <tr>
            <td>File</td>
            <td>File Type	</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {attachTable?.map((row , idx) =>{
          return <tr>
            <td>{row.assetDocumentsFormFile?.name || <a href={`${row?.file}`}>{row?.name}</a>}</td>
            <td>{row.assetDocumentsFormFileType || row?.file_type}	</td>
            <td > <button onClick={()=>deleteAttach(row?.id ,idx)} type='button' className='tertiary-btn'>
              <FontAwesomeIcon icon={faTrashCan} />
            </button> 
            </td>
          </tr>
          })}
        </tbody>
      </table>
      </div>
      </div> 
      </Card>
      <AddModal modalTitle = "Add Attachment"
              showModal= {showModal}
              handleClose ={handleClose}
              handleCreateSave ={handleCreateSaveAttach}
              modelToDo = "Upload">
      <div style={{marginBottom: 20}}>
      <p style={{marginBottom: 10}}>Select attachment (Max upload size is 5MB)</p>
      <input style={{maxWidth: "100%"}} type="file" onChange={handelChangeDocumentsFormFile} 
      accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />
        </div>  
        <div>
      <p style={{marginBottom: 10}}>Type</p>
      <select onChange={handelChangeDocumentsFormFileType} >
       { ["Manual" , "Guide" , "Warranty", "Receipt"].map(opt=>(
        <option key={opt} value={opt}>{opt}</option>
       ))}
      </select>
        </div>  
      </AddModal>
    </div>
  )
}

export default FetchAssetDocumentsForm
