/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React, { useEffect} from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import classes from '../assetsPage/assestsPage.module.css';
import EpqHeader from './epqHeader';
import Loader from '../../components/loader/loader';
import { getSORSoucrcesTardes, getSORSummary, reset, resetState, setSingleBill} from '../../redux/features/SOR/SORSlice';
import EpqTabs from './epqTabs';
import SOR from './Pages/SOR';
import BOQ from './Pages/BOQ';
import SORHistory from './Pages/SORHistory';

/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */


export default function ScheduleRate() {
  const dispatch = useDispatch();
  const {
    SORTab,
    isSORImportedLoading,
    isSORImported,
    isSORImportedError,
    singleBill
  } = useSelector(state => state.SOR);

  function displayToast() {
    if (isSORImportedError) {
      toast.error('Something went wrong while Importing SOR', {
        toastId: 'DeletedSORError',
      });
      dispatch(reset());
    } else if (isSORImported) {
      toast.success("Your data import is being processed. You can safely proceed with other tasks while the system completes the import.", {
        toastId: 'integrationSyncStarted',
      })
      dispatch(reset());
    }
  }

  useEffect(() => {
    dispatch(getSORSoucrcesTardes())
    dispatch(getSORSummary())
    dispatch(setSingleBill(null))
    if (singleBill) {
      dispatch(resetState())
    }
  }, [])

  return isSORImportedLoading ? (
    <Loader />
  ) : (
    <>
      {displayToast()}
      <div className={classes.assets}>
        <EpqHeader/>
        <EpqTabs />
            {SORTab === "sor" && <SOR />}
            {SORTab === "boq" && <BOQ />}
            {SORTab === "history" && <SORHistory />}
        
      </div>
    </>
  );
}
