/* eslint-disable camelcase */

import React, { useEffect } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from '@material-ui/core';
import { Button } from 'react-bootstrap';
// import PauseIcon from '../../../../assets/svg-icons/PuaseIcon';
// import PlayIcon from '../../../../assets/svg-icons/PlayIcon';
import { setCreatedBy, setHourlyRate, setDescription, setLogTaskRadio, setTime, resetLog, createLog, setWorkDate } from '../../../../redux/features/ticketLog/ticketLogSlice';
import { SelectPersons } from '../../../../components/select/select';


function LogTime({ tktId }) {
    const { user } = useSelector((state) => state.user);
    const teams = useSelector((state) => state.teams?.teams?.results);
    const { description,
        // hours, 
        // minutes, 
        assigned_to,
        hourly_rate,
        work_date,
        logTaskRadio,
        isRunning,
        time,
        // seconds,
        isLogCreateSuccess
    } = useSelector((state) => state.logTime);

    const dispatch = useDispatch();

    useEffect(() => {
        if (logTaskRadio === "self") {
            dispatch(
                setCreatedBy({
                    value: user?.id,
                    label: `${user?.first_name} ${user?.last_name} (${user?.role})`,
                })
            )
        }
    }, [logTaskRadio])


    // This effect runs every second when the timer is running
    // useEffect(() => {
    //     let interval;
    //     if (isRunning) {
    //         interval = setInterval(() => {
    //             dispatch(setSeconds(seconds + 1));
    //         }, 1000);
    //     } else if (!isRunning && seconds !== 0) {
    //         clearInterval(interval);
    //     }
    //     return () => clearInterval(interval);
    // }, [isRunning, seconds]);


    // Update the hours and minutes based on the total seconds
    // useEffect(() => {
    //     const theTotalMinutes = Math.floor(seconds / 60);
    //     const theHours = Math.floor(theTotalMinutes / 60);
    //     const theMinutes = theTotalMinutes % 60;
    //     dispatch(setTime({ hours: theHours, minutes: theMinutes }));
    // }, [seconds]);

    // Start or stop the timer
    // const toggleTimer = () => {
    //     dispatch(setIsRunning(!isRunning));
    // };

    const handleLogTaskRadio = (event) => {
        dispatch(setLogTaskRadio(event.target.value));
    };

    const handleHours = (e) => {
        dispatch(setTime({ ...time, hours: e.target.value }));
        // setIsError(false);
    };

    const handleMinutes = (e) => {
        dispatch(setTime({ ...time, minutes: e.target.value }));
        // setIsError(false);
    };

    const handleHourlyRate = (e) => {
        dispatch(setHourlyRate(e.target.value));
        // setIsError(false);
    };

    const handelDescription = (e) => {
        dispatch(setDescription(e.target.value));
    };

    const handleWorkDate = (e) => {
        dispatch(setWorkDate(e.target.value));
    };

    const handelAddTime = async () => {
        const data = {
            description,
            hours: time.hours,
            minutes: time.minutes,
            assigned_to: assigned_to?.value,
            hourly_rate,
            work_date: moment.utc(work_date).format("YYYY-MM-DDTHH:mm:ss.SSZ"),
        };

        if (work_date && assigned_to) {
            if (time.hours >= 0 && time.minutes >= 0 && hourly_rate >= 0) {
                await dispatch(createLog({ id: tktId, data }));
            }
        }
    };


    useEffect(() => {
        if (isLogCreateSuccess) {
            dispatch(resetLog());
        }
    }, [isLogCreateSuccess])


    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '22px' }}>
                <div style={{ fontWeight: '600' }}>Log tasks</div>
                <div style={{ display: 'flex', alignItems: 'center' }} className='log-tasks-radio-wrapper'>
                    <Radio
                        checked={logTaskRadio === 'self'}
                        onChange={handleLogTaskRadio}
                        value="self"
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    <div style={{ marginRight: '20px' }}>Self</div>
                    <Radio
                        checked={logTaskRadio === 'others'}
                        onChange={handleLogTaskRadio}
                        value="others"
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'B' }}
                    />
                    <div>Others</div>
                </div>
            </div>

            <div style={{ display: 'flex', width: '100%', gap: '16px', marginBottom: "16px" }}>
                <div style={{ flex: '1' }}>
                    <SelectPersons
                        customForLogTime
                        teams={teams}
                        assignedTo={assigned_to}
                        setAssignedTo={setCreatedBy}
                        stopped={logTaskRadio === 'self'}
                    />
                </div>
                <div>
                    <span style={{ marginRight: '8px' }}>
                        RM
                    </span>
                    <input
                        name="hourly_rate"
                        type="number"
                        min="0"
                        value={hourly_rate}
                        className="form-control"
                        style={{ width: '5em', display: 'inline' }}
                        id="hourly_rate"
                        placeholder="0"
                        onChange={handleHourlyRate}
                    />{' '}
                    <span style={{ marginLeft: '8px' }}>
                        Hourly
                    </span>
                </div>
            </div>
            <div style={{ marginBottom: '16px' }}>
                <textarea
                    className="form-control"
                    name="work_description"
                    id="work_description"
                    placeholder="Add description"
                    value={description}
                    onChange={handelDescription}
                />
            </div>
            <div style={{
                border: '1px solid #E1E1E1',
                borderRadius: '8px',
                padding: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
            }}>
                    <div className="time-container">
                        {/* Date Input */}
                        <div className="input-container">
                            <input
                                type="datetime-local"
                                value={work_date}
                                onChange={handleWorkDate}
                            />
                        </div>

                        {/* Hours Input */}
                        <div className="input-container">
                            <input
                                type="number"
                                min="0"
                                value={time.hours}
                                onChange={handleHours}
                            />
                            <span>Hours</span>
                        </div>

                        {/* Minutes Input */}
                        <div className="input-container">
                            <input
                                type="number"
                                min="0"
                                value={time.minutes}
                                onChange={handleMinutes}
                            />
                            <span>Mins</span>
                        </div>

                        {/* Add Time Button */}
                        {/* <button type='button' className="add-time-btn">Add time</button> */}
                    </div>
            </div>

            <div>
                <Button variant="contained"
                    style={(
                        isRunning
                        || (time.minutes === 0 && time.hours === 0)
                        || !work_date
                        || !assigned_to?.value
                    ) ? {
                        fontSize: '14px',
                        fontWeight: '600',
                        padding: '8px 16px',
                        border: '1px solid #E1E1E1',
                        borderRadius: '8px',
                        width: '100%'
                    } :
                        {
                            backgroundColor: '#36383C',
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: '600',
                            padding: '8px 16px',
                            border: '1px solid #E1E1E1',
                            borderRadius: '8px',
                            width: '100%'
                        }

                    }
                    // className='outlined-button'
                    disabled={
                        isRunning
                        || (time.minutes === 0 && time.hours === 0)
                        || !work_date
                        || !assigned_to?.value
                    }
                    onClick={handelAddTime}
                >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                    }}>
                        <span style={{ paddingTop: '3px' }}>
                            Add to log
                        </span>
                    </div>
                </Button>
            </div>
        </>
    )
}

export default LogTime




    // <>
    //     <div style={{ display: 'flex', fontSize: '16px' }}>
    //         <div style={{ paddingLeft: '8px', paddingRight: '16px', fontWeight: '600' }}>
    //             {/* 17 Aug 2024 */}
    //             {moment(work_date).format("DD MMM YYYY")}
    //         </div>
    //         <div>|</div>
    //         <div style={{ paddingLeft: '16px', paddingRight: '8px' }}>
    //             <span style={{ fontWeight: '600' }}>{time.hours}</span> Hours{" "}
    //             <span style={{ fontWeight: '600' }}>{time.minutes}</span> Mins{" "}
    //             {/* <span style={{ fontWeight: '600' }}>{seconds}</span> Sec */}
    //         </div>
    //     </div>
    //     <Button
    //         variant="contained"
    //         onClick={toggleTimer}
    //         style={isRunning ? {
    //             background: "#4472B8",
    //             color: "white",
    //             fontSize: '14px',
    //             fontWeight: '600',
    //             padding: '8px 16px',
    //             border: '1px solid #E1E1E1',
    //             borderRadius: '8px',
    //         } : {
    //             fontSize: '14px',
    //             fontWeight: '600',
    //             padding: '8px 16px',
    //             border: '1px solid #E1E1E1',
    //             borderRadius: '8px'
    //         }}
    //     >
    //         <div>
    //             {isRunning ? <span style={{
    //                 display: 'flex',
    //                 alignItems: 'center',
    //                 gap: '8px',
    //             }}> <PauseIcon /> Stop timer </span> :
    //                 <span style={{
    //                     display: 'flex',
    //                     alignItems: 'center',
    //                     gap: '8px',
    //                 }}> <PlayIcon /> Start timer </span>}
    //         </div>
    //     </Button>
    // </>
