/* eslint-disable max-len */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import technicianPositionService from './technicianPositionService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // data
  technicians: [],
  technician: {},

  // fields
  technicianName: '',

  // modals
  technicianPositionModalDelete: false,
  technicianPositionModalUpdate: false,
  technicianPositionModalCreate: false,

  // success
  isTechnicianCreateSuccess: false,
  isTechnicianGetSuccess: false,
  isTechnicianDeleteSuccess: false,
  isTechnicianUpdateSuccess: false,

  // loading
  isTechnicianCreateLoading: false,
  isTechnicianGetLoading: false,
  isTechnicianDeleteLoading: false,
  isTechnicianUpdateLoading: false,

  // error
  isTechnicianCreateError: false,
  isTechnicianGetError: false,
  isTechnicianDeleteError: false,
  isTechnicianUpdateError: false,
};

export const createTechnicianPosition = createAsyncThunk(
  'createTechnicianPosition',
  async (data, thunkAPI) => {
    const create = await smartTryCatch(
      technicianPositionService.createTechnicianPosition,
      data,
      thunkAPI,
    );
    return create;
  },
);

export const getTechnicianPositionsByAuthentication = createAsyncThunk(
  'getTechnicianPositionsByAuthentication',
  async (data, thunkAPI) => {
    const getTech = await smartTryCatch(
      technicianPositionService.getTechnicianPositionsByAuthentication,
      data,
      thunkAPI,
    );
    return getTech;
  },
);

export const getTechnicianPositionById = createAsyncThunk(
  'getTechnicianPositionById',
  async (data, thunkAPI) => {
    const getPos = await smartTryCatch(
      technicianPositionService.getTechnicianPositionById,
      data,
      thunkAPI,
    );
    return getPos;
  },
);

export const deleteTechnicianPosition = createAsyncThunk(
  'deleteTechnicianPosition',
  async (data, thunkAPI) => {
    const deletePos = await smartTryCatch(
      technicianPositionService.deleteTechnicianPosition,
      data,
      thunkAPI,
    );
    return deletePos;
  },
);

export const updateTechnicianPosition = createAsyncThunk(
  'updateTechnicianPosition',
  async (data, thunkAPI) => {
    const update = await smartTryCatch(
      technicianPositionService.updateTechnicianPosition,
      data,
      thunkAPI,
    );
    return update;
  },
);

export const technicianPositionSlice = createSlice({
  name: 'technicianPosition',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isTechnicianCreateError = false;
      state.isTechnicianCreateSuccess = false;
      state.isTechnicianCreateLoading = false;
      state.isTechnicianGetError = false;
      state.isTechnicianGetSuccess = false;
      state.isTechnicianGetLoading = false;
      state.isTechnicianDeleteError = false;
      state.isTechnicianDeleteSuccess = false;
      state.isTechnicianDeleteLoading = false;
      state.isTechnicianUpdateError = false;
      state.isTechnicianUpdateSuccess = false;
      state.isTechnicianUpdateLoading = false;
      // state.technician = null;
    },
    openTechnicianPositionModalCreate: (state) => {
      state.technicianPositionModalCreate = true;
    },
    closeTechnicianPositionModalCreate: (state) => {
      state.technicianPositionModalCreate = false;
    },
    openTechnicianPositionModalUpdate: (state) => {
      state.technicianPositionModalUpdate = true;
    },
    closeTechnicianPositionModalUpdate: (state) => {
      state.technicianPositionModalUpdate = false;
    },
    openTechnicianPositionModalDelete: (state) => {
      state.technicianPositionModalDelete = true;
    },
    closeTechnicianPositionModalDelete: (state) => {
      state.technicianPositionModalDelete = false;
    },
    setTechnician: (state, action) => {
      state.technician = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTechnicianPosition.pending, (state) => {
        state.isTechnicianCreateLoading = true;
        state.isTechnicianCreateSuccess = false;
        state.isTechnicianCreateError = false;
      })
      .addCase(createTechnicianPosition.fulfilled, (state, action) => {
        state.isTechnicianCreateLoading = false;
        state.isTechnicianCreateSuccess = true;
        state.isTechnicianCreateError = false;
        state.technicians.push(action.payload);
      })
      .addCase(createTechnicianPosition.rejected, (state) => {
        state.isTechnicianCreateLoading = false;
        state.isTechnicianCreateSuccess = false;
        state.isTechnicianCreateError = true;
      })
      .addCase(getTechnicianPositionsByAuthentication.pending, (state) => {
        state.isTechnicianGetLoading = true;
        state.isTechnicianGetSuccess = false;
        state.isTechnicianGetError = false;
      })
      .addCase(getTechnicianPositionsByAuthentication.fulfilled, (state, action) => {
        state.isTechnicianGetLoading = false;
        state.isTechnicianGetSuccess = true;
        state.isTechnicianGetError = false;
        state.technicians = action.payload;
      })
      .addCase(getTechnicianPositionsByAuthentication.rejected, (state) => {
        state.isTechnicianGetLoading = false;
        state.isTechnicianGetSuccess = false;
        state.isTechnicianGetError = true;
      })
      .addCase(getTechnicianPositionById.pending, (state) => {
        state.isTechnicianGetLoading = true;
        state.isTechnicianGetSuccess = false;
        state.isTechnicianGetError = false;
      })
      .addCase(getTechnicianPositionById.fulfilled, (state, action) => {
        state.isTechnicianGetLoading = false;
        state.isTechnicianGetSuccess = true;
        state.isTechnicianGetError = false;
        state.technicians = action.payload;
      })
      .addCase(getTechnicianPositionById.rejected, (state) => {
        state.isTechnicianGetLoading = false;
        state.isTechnicianGetSuccess = false;
        state.isTechnicianGetError = true;
      })
      .addCase(deleteTechnicianPosition.pending, (state) => {
        state.isTechnicianDeleteLoading = true;
        state.isTechnicianDeleteSuccess = false;
        state.isTechnicianDeleteError = false;
      })
      .addCase(deleteTechnicianPosition.fulfilled, (state, action) => {
        state.isTechnicianDeleteLoading = false;
        state.isTechnicianDeleteSuccess = true;
        state.isTechnicianDeleteError = false;
        state.technicians = state.technicians.filter(
          (technician) => technician.id !== action.payload,
        );
      })
      .addCase(deleteTechnicianPosition.rejected, (state) => {
        state.isTechnicianDeleteLoading = false;
        state.isTechnicianDeleteSuccess = false;
        state.isTechnicianDeleteError = true;
      })
      .addCase(updateTechnicianPosition.pending, (state) => {
        state.isTechnicianUpdateLoading = true;
        state.isTechnicianUpdateSuccess = false;
        state.isTechnicianUpdateError = false;
      })
      .addCase(updateTechnicianPosition.fulfilled, (state, action) => {
        state.isTechnicianUpdateLoading = false;
        state.isTechnicianUpdateSuccess = true;
        state.isTechnicianUpdateError = false;
        state.technicians = state.technicians.map((technician) => (technician.id === action.payload.id ? action.payload : technician));
      })
      .addCase(updateTechnicianPosition.rejected, (state) => {
        state.isTechnicianUpdateLoading = false;
        state.isTechnicianUpdateSuccess = false;
        state.isTechnicianUpdateError = true;
      });
  },
});

export const {
  reset,
  openTechnicianPositionModalCreate,
  closeTechnicianPositionModalCreate,
  openTechnicianPositionModalUpdate,
  closeTechnicianPositionModalUpdate,
  openTechnicianPositionModalDelete,
  setTechnician,
  closeTechnicianPositionModalDelete,
} = technicianPositionSlice.actions;

export default technicianPositionSlice.reducer;
