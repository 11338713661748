import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getMaintenanceCategoryByAuthentication = async () => {
  const res = await http.get(
    `${BASE_URL}/maintenance-category/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of maintenanceCategory by its company ID failed",
    res
  );
};

const getMaintenanceCategoryById = async (maintenanceCategoryId) => {
  const res = await http.get(
    `${BASE_URL}/maintenance-category/${maintenanceCategoryId}`
  );

  return assert(
    res,
    res.data,
    "Retrieval of maintenanceCategory by its ID failed",
    res
  );
};

const createMaintenanceCategory = async (data) => {
  const res = await http.post(
    `${BASE_URL}/maintenance-category/`,
    data
  );

  return assert(res, res.data, "Creation of maintenanceCategory failed", res);
};

const updateMaintenanceCategory = async (data) => {
  const res = await http.put(
    `${BASE_URL}/maintenance-category/${data.id}/`,
    data
  );
  return assert(res, res.data, "Update of maintenanceCategory failed", res);
};

const deleteMaintenanceCategory = async (maintenanceCategoryId) => {
  const res = await http.delete(
    `${BASE_URL}/maintenance-category/${maintenanceCategoryId}/`
  );
  return assert(res, maintenanceCategoryId, "Deletion of maintenanceCategory failed", res);
};

const maintenanceCategoryService = {
  getMaintenanceCategoryByAuthentication,
  getMaintenanceCategoryById,
  createMaintenanceCategory,
  updateMaintenanceCategory,
  deleteMaintenanceCategory,
};

export default maintenanceCategoryService;
