/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable  react/destructuring-assignment */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable */

import React, { useMemo, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTable } from 'react-table';
import parse from 'html-react-parser';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  getInventoryHistoryById,
  getInventoryAdjustmentHistoryById,
} from '../../redux/features/inventory/inventorySlice';
import {
  getWorkOrderByTicketNumber,
  openWorkOrderModalUpdate,
} from '../../redux/features/workOrder/workOrderSlice';
import classes from '../assetsTable/assetsTable.module.css';
import Pagination from '../pagination/pagination';
// import EditWOModal from '../../pages/workOrder/Componets/EditWOModal';
import { setTab } from '../../redux/features/meter/meterSlice';
import classes2 from '../../pages/assetsPage/addAsset/addAsset.module.css';

const tabs = ['Inventory History', 'Adjustment History'];

export default function InventoryHistory() {
  const [selected, setSelected] = useState('Inventory History');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [isAdjustmentselected, setIsAdjsutmentSelected] = useState(false);

  const { inventoryHistory, inventoryAdjustmentHistory } = useSelector(state => state.inventory);
  // const { workOrderModalUpdate} = useSelector(state => state.workOrder)
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleTabs = tab => {
    setSelected(tab);
  };
  useEffect(() => {
    localStorage.setItem('inventoryHistoryTabs', JSON.stringify(selected));
  }, [selected]);

  useEffect(() => {
    const getDataRows = async () => {
      const data = { id, currentPage, rowPerPage, searchValue };
      await dispatch(getInventoryHistoryById(data));
      await dispatch(getInventoryAdjustmentHistoryById(data));
    };

    getDataRows();
  }, [id, rowPerPage, currentPage, searchValue]);

  const handleIconClick = () => {
    history.push('/inventories');
  };

  const getSelectedRowwValues = async (selectedRow, cell) => {
    if (selectedRow.reference != null) {
      if (cell.column.id === 'reference') {
        await dispatch(getWorkOrderByTicketNumber(selectedRow.reference));
        await dispatch(openWorkOrderModalUpdate());
        await dispatch(setTab('service-tab'));
      }
    }
  };

  const handleSearchValue = e => {
    setSearchValue(e.target.value);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'reference',
      },
      {
        Header: 'Date',
        accessor: 'history_date',
      },

      {
        Header: 'Activity',
        accessor: 'history_change_reason',
      },
      {
        Header: 'Updated by',
        accessor: 'updated_by',
      },
    ],
    [],
  );
  const columnsAdjustment = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Date',
        accessor: 'update_at',
      },
      {
        Header: 'Adjustment',
        accessor: 'adjustment',
      },
      {
        Header: 'New',
        accessor: 'new_quantity',
      },
      {
        Header: 'Adjusted by',
        accessor: 'adjusted_by',
      },
      {
        Header: 'Details',
        accessor: 'details',
      },
      {
        Header: 'Attachments',
        accessor: 'attachments',
      },
    ],
    [],
  );

  const data2 = inventoryHistory?.results?.map(d => {
    const date = new Date(d.history_date);
    const formattedDate = date.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return {
      reference: d.reference,
      history_date: formattedDate,
      history_change_reason: d?.changes ? d?.changes : d?.history_change_reason,
      updated_by: d.updated_by,
    };
  });
  const data3 = inventoryAdjustmentHistory?.map((d,index) => {
    const date = new Date(d.created_at);
    const formattedDate = date.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return {
      id: index+1,
      update_at: formattedDate,
      adjustment: d.new_quantity - d.previous_quantity,
      new_quantity: d.new_quantity,
      adjusted_by: d.created_by.first_name + ' ' + d.created_by.last_name,
      details: d.details ? d.details : '--',
      attachments: d.attachment,
    };
  });

  const data = data2 || [];
  const dataAdjustment = data3 || [];

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
    manualPagination: true,
  });
  const {
    getTableProps: getAdjustmentTableProps,
    getTableBodyProps: getTAdjustableBodyProps,
    headerGroups: headerGroupsAdjustment,
    prepareRow: prepareRowAdjustment,
    rows: rowsAdjustment,
  } = useTable({
    columns: columnsAdjustment,
    data: dataAdjustment,
    manualPagination: true,
  });
  const pmDataCount = inventoryHistory?.count;
  return (
    <section className="content">
      <div className="row">
        <div className="col-lg-12 col-sm-12 ">
          <div className="card-header">
            <div role="presentation">
              <i
                onClick={handleIconClick}
                className="fa fa-arrow-alt-circle-left fa-lg float-right ml-3 mt-2 pt-1 mb-2"
              />
              <input
                type="text"
                id="history-search"
                onChange={handleSearchValue}
                placeholder="Search"
                className="rgfloat search-bar p-1"
              />
            </div>
            <div className={classes2.addAsset}>
              <ul
                className={classes2.list}
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                {tabs?.map(tab => {
                  const tabClass =
                    selected === tab
                      ? { color: '#F63854', backgroundColor: '#FFEBEE', fontSize: '16px' }
                      : {
                          cursor: 'pointer',
                          fontSize: '16px',
                        };
                  return (
                    <li key={tab}>
                      <button style={tabClass} type="button" onClick={() => handleTabs(tab)}>
                        {tab}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* <h4 className="card-title mt-2 medium-title"><b>Inventory history</b></h4> */}
          </div>
          {selected === tabs[0] ? (
            <>
              <div className={classes.scrollX}>
                <table {...getTableProps()} className={classes.table}>
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.length !== 0 ? (
                      rows.map(row => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  onClick={() => getSelectedRowwValues(row.original, cell)}
                                >
                                  {cell.column.Header === 'Activity' &&
                                  row.original?.history_change_reason?.includes('<br>') ? (
                                    <p style={{ fontWeight: 'bold', marginBottom: 0 }}>
                                      {parse(row.original.history_change_reason)}
                                    </p>
                                  ) : (
                                    cell.render('Cell')
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        {' '}
                        <td className={classes.noResults} colSpan="7">
                          No Results Found{' '}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                totalRows={pmDataCount}
                rowPerPage={rowPerPage}
                pageChangeHandler={setCurrentPage}
                changeRowsPerPage={setRowPerPage}
                currentPage={currentPage}
              />
            </>
          ) : (
            <>
              <div className={classes.scrollX}>
                <table {...getAdjustmentTableProps()} className={classes.table}>
                  <thead>
                    {headerGroupsAdjustment.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTAdjustableBodyProps()}>
                    {rowsAdjustment.length !== 0 ? (
                      rowsAdjustment.map(row => {
                        prepareRowAdjustment(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  onClick={() => getSelectedRowwValues(row.original, cell)}
                                >
                                  {/* {cell.column.Header === "Activity" && row.original?.history_change_reason?.includes("<br>") ?
                             <p style={{fontWeight:"bold" , marginBottom:0}}>{parse(row.original.history_change_reason)}</p>
                             : cell.render("Cell")} */}
                                  {cell.column.Header === 'Attachments' ? (
                                   
                                    row.original.attachments? <a href={row.original.attachments} target="_blank">
                                    Receipt
                                  </a>:<p>--</p>
                                   
                                  ) : (
                                    cell.render('Cell')
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        {' '}
                        <td className={classes.noResults} colSpan="7">
                          No Results Found{' '}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                totalRows={pmDataCount}
                rowPerPage={rowPerPage}
                pageChangeHandler={setCurrentPage}
                changeRowsPerPage={setRowPerPage}
                currentPage={currentPage}
              />
            </>
          )}

          {/* {workOrderModalUpdate &&  <EditWOModal 
          openModalUpdate = {workOrderModalUpdate} 
          closeModalUpdate={() => dispatch(closeWorkOrderModalUpdate())}
        />} */}
        </div>
      </div>
    </section>
  );

  // return (
  //     <section className="content">
  //       <div className="row">
  //         <div className="col-lg-12 col-sm-12 ">
  //           <div className="card-header">
  //             <div role="presentation" onClick={handleIconClick}>
  //               <i className="fa fa-arrow-alt-circle-left fa-lg float-right ml-3 mt-2 pt-1 mb-2" />
  //             </div>
  //             <h4 className="card-title mt-2 medium-title"><b>Adjustment history</b></h4>
  //             <input type="text" id="history-search" onChange={handleSearchValue} placeholder="Search" className="rgfloat search-bar p-1" />
  //           </div>
  //           <Button onClick={()=>setIsAdjsutmentSelected(false)}>Inventory History</Button>
  //           <div className={classes.scrollX}>
  //             <table {...getAdjustmentTableProps()} className={classes.table}>
  //               <thead>
  //                 {headerGroupsAdjustment.map(headerGroup => (
  //                   <tr {...headerGroup.getHeaderGroupProps()}>
  //                     {headerGroup.headers.map(column => (
  //                       <th {...column.getHeaderProps()}>{column.render('Header')}</th>
  //                     ))}
  //                   </tr>
  //                 ))}
  //               </thead>
  //               <tbody {...getTAdjustableBodyProps()}>
  //                 {rowsAdjustment.length !== 0 ? rowsAdjustment.map((row) => {

  //                   prepareRowAdjustment(row);
  //                   return (
  //                     <tr {...row.getRowProps()}>

  //                       {row.cells.map(cell => {
  //                         return <td
  //                               {...cell.getCellProps()} onClick={() => getSelectedRowwValues(row.original, cell)}>
  //                           {/* {cell.column.Header === "Activity" && row.original?.history_change_reason?.includes("<br>") ?
  //                           <p style={{fontWeight:"bold" , marginBottom:0}}>{parse(row.original.history_change_reason)}</p>
  //                           : cell.render("Cell")} */}
  //                         </td>;
  //                       })}

  //                     </tr>
  //                   );
  //                 }) : <tr> <td className={classes.noResults} colSpan="7">No Results Found </td></tr>}
  //               </tbody>
  //             </table>
  //           </div>
  //           <Pagination
  //             totalRows={pmDataCount}
  //             rowPerPage={rowPerPage}
  //             pageChangeHandler={setCurrentPage}
  //             changeRowsPerPage={setRowPerPage}
  //             currentPage={currentPage}
  //           />

  //       {/* {workOrderModalUpdate &&  <EditWOModal
  //           openModalUpdate = {workOrderModalUpdate}
  //           closeModalUpdate={() => dispatch(closeWorkOrderModalUpdate())}
  //         />} */}
  //         </div>
  //       </div>
  //     </section>
  //   )
}
