import React from "react";
import PlusIcon from "../../../assets/svg-icons/PlusIcon";
/* eslint-disable react/no-array-index-key  */

function Attachments({
    files,
    handleAttachmentFileChange,
    renderFilePreview,
    attachments,
    renderResFilePreview
}) {
    
    return (
        <div className="pm-attach-container">
            <h6>Attach files</h6>
            <p className="pm-attach-container-p">Choose file max 5MB jpg/png/pdf/doc format</p>
            <div className="attachments">
                {attachments?.length > 0 && attachments?.map((file) => renderResFilePreview(file))}
                {files?.length > 0 && files?.map((file, index) => renderFilePreview(file, index))}
                {files?.length < 4 && (
                    <div className="add-more">
                        <label htmlFor="file-upload">
                        <PlusIcon color="#9DA0A6" custom />
                        <input
                            type="file"
                            id="file-upload"
                            multiple
                            onChange={handleAttachmentFileChange}
                            style={{ display: "none" }}
                            accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                        />
                    </label>
                    </div>
                )}
            </div>
        </div>
    );
}


export default Attachments;