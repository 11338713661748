import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getAssetCategoriesByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/asset-category/`);
  return assert(res, res.data, 'Retrieval of asset category by its company ID failed', res);
};

const getAssetCategorybyId = async assetCategoryId => {
  const res = await http.get(`${BASE_URL}/asset-category/${assetCategoryId}`);

  return assert(res, res.data, 'Retrieval of asset category by its ID failed', res);
};

const createAssetCategory = async data => {
  const res = await http.post(`${BASE_URL}/asset-category/`, data);

  return assert(res, res.data, 'Creation of asset category failed', res);
};

const updateAssetCategory = async data => {
  const res = await http.put(`${BASE_URL}/asset-category/${data.id}/`, data);
  return assert(res, res.data, 'Update of asset category failed', res);
};

const deleteAssetCategory = async assetCategoryId => {
  const res = await http.delete(`${BASE_URL}/asset-category/${assetCategoryId}/`);
  return assert(res, assetCategoryId, 'Deletion of asset category failed', res);
};

const assetCategoryService = {
  getAssetCategoriesByAuthentication,
  getAssetCategorybyId,
  createAssetCategory,
  updateAssetCategory,
  deleteAssetCategory,
};

export default assetCategoryService;
