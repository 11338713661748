/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */

import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSORById, getSORByPagination, setBillData, setCurrentPage, setOpenSORModal, setRowPerPage, setSOR } from '../../../redux/features/SOR/SORSlice';
import EpqItem from '../epqItem';
import EpqTable from '../epqTable';
import Loader from '../../../components/loader/loader';
import Pagination from '../../../components/pagination/pagination';


function SOR() {
    const dispatch = useDispatch();
    const {
        openSORModal,
        SORs,
        currentPage,
        rowPerPage,
        searchValue,
        source,
        trade,
        filterWords,
        billData,
        isBookmarked
    } = useSelector(state => state.SOR);

    const [iSShowFilter, setISShowFilter] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const getSelectedRowwValues = async (selectedRow, cell) => {
        if (cell.column.id !== "checkbox") {
            await dispatch(getSORById(selectedRow?.id))
            dispatch(setOpenSORModal(true))
        }
    };

    const handleCheckboxChange = (selectedRow) => {
        const row = selectedRow.row.original;
        // Log the current state of billData
        const findIndex = billData.findIndex(item => item.id === row.id)
        if (findIndex === -1) {
            dispatch(setBillData([...billData, 
                {...row , 
                    discountRate: 0 ,  
                    quantity: 1, 
                    totalPrice: row?.rate,
                    vendorQuantity: 1,
                    vendorTotalPrice: row?.rate,
                }]))
        } else {
            const filteredItem = billData.filter(item => item.id !== row.id)
            dispatch(setBillData(filteredItem))
        }
    };

    const isChecked = (selectedRow) => {
        const row = selectedRow.row.original;
        const billDataCopy = [...billData]
        const findIndex = billDataCopy.findIndex(item => item.id === row.id)
        if (findIndex !== -1) {
            return true
        }
        return false
    }

    const getDataRows = async () => {
        const filters =
        {
            rowPerPage,
            currentPage,
            searchValue,
            source,
            trade,
            all: filterWords.all,
            exact: filterWords.exact,
            any: filterWords.any,
            none: filterWords.none,
            isBookmarked
        }
        await dispatch(getSORByPagination(filters))
        setIsLoading(false)
    }
    useEffect(() => {
        getDataRows()
    }, [rowPerPage, currentPage, searchValue])
    const modifiedData = useMemo(() =>
        SORs?.results?.length > 0 ? SORs?.results?.map(item => {
            return {
                "id": item?.id,
                "sor_id": item?.sor_id,
                "source": item?.source,
                "description": item?.description,
                "rate": item?.rate,
                "unit": item?.unit,
                "trade": item?.trade,
                "discount": item?.discount,
                "year": item?.year,
                "sectionTitle": item?.section_title || "_",
                "is_bookmarked": item?.is_bookmarked,
            }
        }) : []
        , [SORs])

    const cols = React.useMemo(
        () => [
            {
                id: "checkbox",
                Cell: (row) => {
                    return (
                        <input type="checkbox" style={{ width: "20px", height: "20px" }}
                            onChange={() => handleCheckboxChange(row)}
                            checked={isChecked(row)}
                        />
                    )
                },
            },
            {
                Header: 'ID',
                accessor: 'sor_id',
            },
            {
                Header: 'Source',
                accessor: 'source',
            },
            {
                Header: 'Trade',
                accessor: 'trade',
            },
            {
                Header: 'Section title',
                accessor: 'sectionTitle',
            },
            {

                Header: 'Description',
                Cell: ({ row }) => {
                    return row?.original?.description?.split("\n").map(desc => (
                        <>
                            <span>{desc}</span> <br />
                        </>
                    ))
                },
            },
            {
                Header: 'Unit',
                accessor: 'unit',
            },
            {
                Header: 'Rate',
                accessor: 'rate',
            },
        ],
        [billData],
    );

    return (
        isLoading ?
            <Loader /> :
            <>
                <EpqTable
                    iSShowFilter={iSShowFilter}
                    setISShowFilter={setISShowFilter}
                    getSelectedRowwValues={getSelectedRowwValues}
                    data={modifiedData}
                    columns={cols}
                    getDataRows={getDataRows}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
                <Pagination
                    totalRows={SORs?.count || 0}
                    rowPerPage={rowPerPage}
                    pageChangeHandler={setCurrentPage}
                    changeRowsPerPage={setRowPerPage}
                    currentPage={currentPage}
                />
                {openSORModal && <EpqItem />}
            </>
    )
}

export default SOR