/* eslint-disable max-len */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import subLocationService from './subLocationService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // sublocation Data
  sublocations: [],
  sublocation: {},

  // sublocation fields=
  room: '',
  suite: '',
  level: '',
  building: '',
  qrCode: '',

  // pagination
  currentPage: 1,
  rowPerPage: 10,

  // sublocation modal
  sublocationModalDelete: false,
  sublocationModalUpdate: false,
  sublocationModalCreate: false,

  // success sublocation
  isSublocationGetSuccess: false,
  isSublocationCreateSuccess: false,
  isSublocationDeleteSuccess: false,
  isSublocationUpdateSuccess: false,

  // loading sublocation
  isSublocationCreateLoading: false,
  isSublocationGetLoading: false,
  isSublocationDeleteLoading: false,
  isSublocationUpdateLoading: false,

  //  error sublocation
  isSublocationCreateError: false,
  isSublocationGetError: false,
  isSublocationDeleteError: false,
  isSublocationUpdateError: false,
};

export const sublocationAdd = createAsyncThunk('createSublocation', async (data, thunkAPI) => {
  const response = await smartTryCatch(subLocationService.subLocationAdd, data, thunkAPI);
  return response;
});

export const sublocationGet = createAsyncThunk('getSublocation', async (data, thunkAPI) => {
  const response = await smartTryCatch(subLocationService.subLocationGet, data, thunkAPI);
  return response;
});

export const sublocationDelete = createAsyncThunk('deleteSublocation', async (data, thunkAPI) => {
  const response = await smartTryCatch(subLocationService.subLocationDelete, data, thunkAPI);
  return response;
});

export const sublocationUpdate = createAsyncThunk('updateSublocation', async (data, thunkAPI) => {
  const response = await smartTryCatch(subLocationService.subLocationUpdate, data, thunkAPI);
  return response;
});

export const sublocationSlice = createSlice({
  name: 'sublocation',
  initialState: initState,
  reducers: {
    openSublocationModalCreate: (state) => {
      state.sublocationModalCreate = true;
    },
    closeSublocationModalCreate: (state) => {
      state.sublocationModalCreate = false;
    },
    openSublocationModalUpdate: (state) => {
      state.sublocationModalUpdate = true;
    },
    closeSublocationModalUpdate: (state) => {
      state.sublocationModalUpdate = false;
    },
    openSublocationModalDelete: (state) => {
      state.sublocationModalDelete = true;
    },
    closeSublocationModalDelete: (state) => {
      state.sublocationModalDelete = false;
    },
    setSublocation: (state, action) => {
      state.sublocation = action.payload;
    },
    setRoom: (state, action) => {
      state.room = action.payload;
    },
    setSuite: (state, action) => {
      state.suite = action.payload;
    },
    setLevel: (state, action) => {
      state.level = action.payload;
    },
    setBuilding: (state, action) => {
      state.building = action.payload;
    },
    setQrCode: (state, action) => {
      state.qrCode = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowPerPage: (state, action) => {
      state.rowPerPage = action.payload;
    },
    reset: (state) => {
      state.isSublocationCreateError = false;
      state.isSublocationCreateSuccess = false;
      state.isSublocationCreateLoading = false;
      state.isSublocationGetError = false;
      state.isSublocationGetSuccess = false;
      state.isSublocationGetLoading = false;
      state.isSublocationDeleteError = false;
      state.isSublocationDeleteSuccess = false;
      state.isSublocationDeleteLoading = false;
      state.isSublocationUpdateError = false;
      state.isSublocationUpdateSuccess = false;
      state.isSublocationUpdateLoading = false;
      state.sublocation = null;
      state.sublocationModalDelete = false;
      state.sublocationModalUpdate = false;
      state.sublocationModalCreate = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sublocationUpdate.pending, (state) => {
        state.isSublocationUpdateError = true;
        state.isSublocationUpdateSuccess = false;
        state.isSublocationUpdateError = false;
      })
      .addCase(sublocationUpdate.fulfilled, (state, action) => {
        state.isSublocationUpdateLoading = false;
        state.isSublocationUpdateSuccess = true;
        state.isSublocationUpdateError = false;
        state.sublocations = state.sublocations.map((sublocation) => (sublocation.id === action.payload.id ? action.payload : sublocation));
      })
      .addCase(sublocationUpdate.rejected, (state) => {
        state.isSublocationUpdateLoading = false;
        state.isSublocationUpdateSuccess = false;
        state.isSublocationUpdateError = true;
      })
      .addCase(sublocationAdd.pending, (state) => {
        state.isSublocationCreateLoading = true;
        state.isSublocationCreateSuccess = false;
        state.isSublocationCreateError = false;
      })
      .addCase(sublocationAdd.fulfilled, (state, action) => {
        state.isSublocationCreateLoading = false;
        state.isSublocationCreateSuccess = true;
        state.isSublocationCreateError = false;
        state.sublocations.push(action.payload);
      })
      .addCase(sublocationAdd.rejected, (state) => {
        state.isSublocationCreateLoading = false;
        state.isSublocationCreateSuccess = false;
        state.isSublocationCreateError = true;
      })
      .addCase(sublocationDelete.pending, (state) => {
        state.isSublocationDeleteLoading = true;
        state.isSublocationDeleteSuccess = false;
        state.isSublocationDeleteError = false;
      })
      .addCase(sublocationDelete.fulfilled, (state, action) => {
        state.isSublocationDeleteLoading = false;
        state.isSublocationDeleteSuccess = true;
        state.isSublocationDeleteError = false;
        state.sublocations = state.sublocations.map((location) => (location.id === action.payload.id ? action.payload : location));
      })
      .addCase(sublocationDelete.rejected, (state) => {
        state.isSublocationDeleteLoading = false;
        state.isSublocationDeleteSuccess = false;
        state.isSublocationDeleteError = true;
      });
  },
});

export const {
  openSublocationModalCreate,
  closeSublocationModalCreate,
  openSublocationModalUpdate,
  closeSublocationModalUpdate,
  openSublocationModalDelete,
  closeSublocationModalDelete,
  setSublocation,
  setRoom,
  setSuite,
  setLevel,
  setBuilding,
  setQrCode,
  reset,
  setCurrentPage,
  setRowPerPage,
} = sublocationSlice.actions;

export default sublocationSlice.reducer;
