import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getInventoryCategoryByAuthentication = async () => {
  const res = await http.get(
    `${BASE_URL}/inventory-category/`
  );

  return assert(
    res,
    res.data,
    "Retrieval of technician position by its company ID failed",
    res
  );
};

const getInventoryCategoryById = async (inventoryCategoryId) => {
  const res = await http.get(
    `${BASE_URL}/inventory-category/${inventoryCategoryId}`
  );

  return assert(
    res,
    res.data,
    "Retrieval of technician position by its ID failed",
    res
  );
};

const createInventoryCategory = async (inventoryCategoryData) => {
  const res = await http.post(
    `${BASE_URL}/inventory-category/`,
    inventoryCategoryData
  );

  return assert(res, res.data, "Creation of technician position failed", res);
};

const deleteInventoryCategory = async (inventoryCategoryId) => {
  const res = await http.delete(
    `${BASE_URL}/inventory-category/${inventoryCategoryId}/`
  );

  return assert(res, inventoryCategoryId, "Deletion of technician position failed", res);
};

const updateInventoryCategory = async (inventoryCategoryData) => {
  const res = await http.put(
    `${BASE_URL}/inventory-category/${inventoryCategoryData.id}/`,
    inventoryCategoryData
  );

  return assert(res, res.data, "Update of technician position failed", res);
};

const inventoryCategoryService = {
  getInventoryCategoryByAuthentication,
  getInventoryCategoryById,
  createInventoryCategory,
  deleteInventoryCategory,
  updateInventoryCategory,
};

export default inventoryCategoryService;
