import React from 'react'

/* eslint-disable no-unneeded-ternary */

function CorrectIcon({color}) {
    return (
        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 1.5L3.85355 6.64645C3.65829 6.84171 3.34171 6.84171 3.14645 6.64645L1 4.5"
                stroke={color ? color :"#D9314A"}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default CorrectIcon