import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getMeterMeasurementByAuthentication = async () => {
  const res = await http.get(
    `${BASE_URL}/meter-measurement/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of MeterMeasurement by its company ID failed",
    res
  );
};

const getMeterMeasurementById = async (MeterMeasurementId) => {
  const res = await http.get(
    `${BASE_URL}/meter-measurement/${MeterMeasurementId}`
  );

  return assert(
    res,
    res.data,
    "Retrieval of MeterMeasurement by its ID failed",
    res
  );
};

const createMeterMeasurement = async (data) => {
  const res = await http.post(
    `${BASE_URL}/meter-measurement/`,
    data
  );

  return assert(res, res.data, "Creation of MeterMeasurement failed", res);
};

const updateMeterMeasurement = async (data) => {
  const res = await http.put(
    `${BASE_URL}/meter-measurement/${data.id}/`,
    {'name':data.name}
  );
  return assert(res, res.data, "Update of MeterMeasurement failed", res);
};

const deleteMeterMeasurement = async (MeterMeasurementId) => {
  const res = await http.delete(
    `${BASE_URL}/meter-measurement/${MeterMeasurementId}/`
  );
  return assert(res, MeterMeasurementId, "Deletion of MeterMeasurement failed", res);
};

const MeterMeasurementService = {
  getMeterMeasurementByAuthentication,
  getMeterMeasurementById,
  createMeterMeasurement,
  updateMeterMeasurement,
  deleteMeterMeasurement,
};

export default MeterMeasurementService;