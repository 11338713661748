/* eslint-disable import/newline-after-import */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus  } from '@fortawesome/free-solid-svg-icons';

import classes from "../../assetsPage/assestsPage.module.css"


import { selectActions } from '../../../redux/features/selectOptions/selectSlice';
import { openMeterModalCreate, resetMeter, setSearchVal, setTab } from '../../../redux/features/meter/meterSlice';
import { setAsset } from '../../../redux/features/asset/assetSlice';

// const fileDownload = require('js-file-download');

function MeterHeader({setISShowFilter,
  iSShowFilter}) {
  const dispatch = useDispatch()
  const {SearchVal} = useSelector(state => state.meter)

    
    
    const handelChange = (e)=>{
      dispatch(setSearchVal(e.target.value))
    }
  
    const handelReset = ()=>{
      dispatch((resetMeter()))
      dispatch(setAsset(null))
      dispatch(selectActions.reset())
      dispatch(openMeterModalCreate())
      dispatch(setTab("meter-tab"))
    }
    
  return (

    <div className={classes.assetsTop}>
    <h4 className={classes.assetsTitle}>Meter</h4>
    <div className={classes.assetActions}>
     <input type="text" placeholder='Search' value={SearchVal} onChange={handelChange} />
     <button
     onClick={handelReset}
       type='button'  
     className={classes.assetfaPlus}>
      <span><FontAwesomeIcon icon={faPlus}  /></span>
      </button>
     <button type='button' onClick={()=> setISShowFilter(!iSShowFilter) } className={classes.assetFilter}>
       <FontAwesomeIcon icon={faFilter} />
       </button>
    </div>
    </div>
  )
}

export default MeterHeader