/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-template */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faFilter } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as FileSaver from 'file-saver';
// import { BlobProvider } from '@react-pdf/renderer';
// import * as XLSX from "xlsx";
import {
  downloadTemplate,
  exportInventory,
  getInventorysByAuthentication,
  importInventory,
  reset,
  setImportFile,
} from '../../redux/features/inventory/inventorySlice';
import InventoryBulkQrPrint from '../inventory/inventoryBulkQrPrint';

/* eslint-disable prefer-template */

export default function SearchHeader ({
  pageName,
  includeFilterButton,
  includeEllipsisButton,
  children,
  checkedList,
  handleList,
  checkedDate,
  handleDate,
  checkedTask,
  handleTask,
  type,
  invTabs,
  handleInvTabs,
}) {
  const [show, setShow] = useState(false);
  const [showCol, setShowCol] = useState(false);
  const [exportsfiles, setExportFiles] = useState(false);
  const { user } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const fileInputRef = React.useRef(null);
  const { profile } = useSelector(state => state.company);
  //  File Export
  const { fileExport, template, inventorys } = useSelector(state => state.inventory);
  const fileExtension = '.csv';
  const fileExName = 'exported_inventory_data';
  const exportToCSV = async (apiData, fileName) => {
    const headers = Object.keys(apiData[0]);
  
    // Convert data to CSV format
    const csvContent = apiData.map(row => {
      return headers.map(header => {
        // If the field contains a comma or double quote, enclose it in quotes and escape any double quotes
        let field = row[header].replace(/"/g, '""'); // Escape double quotes
        if (field.includes(',') || field.includes('"')|| field.includes('\n')) {
          field = `"${field}"`; // Enclose in quotes if necessary
        }
        return field;
      }).join(',');
    }).join('\n');
  
    // Construct the final CSV content
    const finalCSV = headers.join(',') + '\n' + csvContent;
  
    // Create a Blob from the CSV content with the appropriate Content-Type
    const data = new Blob([finalCSV], { type: 'text/csv' });
  
    // Save the Blob as a file
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    if (fileExport?.length > 0) {
      exportToCSV(fileExport, fileExName);
      dispatch(reset());
    }
  }, [fileExport]);

  //  FileImprot
  const handelImportFile = async e => {
    dispatch(setImportFile(e.target.files[0]));
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    await dispatch(importInventory(formData));
    dispatch(getInventorysByAuthentication());
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  function createCSVContent (headers) {
    const csvContent = headers.join(',') + '\n';
    return csvContent;
  }

  function downloadCSVFile (csvContent, filename) {
    const data = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  }

  useEffect(() => {
    if (template) {
      const handleDownload = () => {
        const csvContent = createCSVContent(template);
        downloadCSVFile(csvContent, 'Inventory-Template.csv');
      };
      handleDownload();
      dispatch(reset());
    }
  }, [template]);
  return (
    <>
      {' '}
      <div className="card-header border-0 p-0 pr-3">
        <h3 className="card-title" style={{fontSize: "24px", fontWeight: "500"}}>
          {pageName}
        </h3>
        <div className="row" />
        <div className="float-right row">
          {children}
          {includeFilterButton ? (
            <button
              data-toggle="collapse"
              data-target="#tablefilters"
              className="btn secondary-btn ml-3 shadow-none rounded-full"
              type="button"
              onClick={() => {
                setShowCol(!showCol);
              }}
            >
              <FontAwesomeIcon icon={faFilter} />
            </button>
          ) : null}
          {includeEllipsisButton ? (
            <button
              data-toggle="collapse"
              data-target="#tabledownload"
              className="btn tertiary-btn ml-3 shadow-none rounded-full"
              title="View More"
              type="button"
              onClick={() => setExportFiles(!exportsfiles)}
            >
              <FontAwesomeIcon icon={faEllipsis} />
            </button>
          ) : null}
        </div>
      </div>
      {showCol && (
        <div id="tablefilters" className=" show">
          <div className="col-lg-4 col-sm-12 p-4">
            <div className="card ">
              <div className="card-header">
                {/* <div className="card-tools"> */}
                <button
                  type="button"
                  className=" filter-cursor"
                  data-card-widget="collapse"
                  aria-hidden="true"
                  onClick={() => setShow(!show)}
                  style={{ cursor: 'pointer', width: '100%' }}
                >
                  <div className="row justify-between">
                    <span className="card-title filter">Table columns</span>

                    <li className="fas fa-angle-down cursor_pointer">{}</li>
                  </div>
                </button>
                {/* </div> */}
              </div>
              {show && type === 'checklsit' && (
                <div className="card-body p-0 pb-2 filter-box flex-col">
                  <div className="row pl-3">
                    <div className="p-2">
                      <input
                        className="mr-1"
                        type="checkbox"
                        id="click-me_checklist"
                        name="cold"
                        checked={checkedList}
                        onChange={handleList}
                      />
                      Checklist <br />
                    </div>
                    <div className="p-2">
                      <input
                        className="mr-1"
                        type="checkbox"
                        id="click-me_date"
                        name="cold"
                        checked={checkedDate}
                        onChange={handleDate}
                      />
                      Created date <br />
                    </div>
                    <div className="p-2">
                      <input
                        className="mr-1"
                        type="checkbox"
                        id="click-me_task"
                        name="cold"
                        checked={checkedTask}
                        onChange={handleTask}
                      />
                      Tasks <br />
                    </div>
                  </div>
                </div>
              )}
              {show && type === 'inventory' && (
                <div className="card-body p-0 pb-2 filter-box flex-col">
                  <div className="row pl-3">
                    <div className="p-2">
                      <input
                        className="mr-1"
                        type="checkbox"
                        id="click-me_serial_number"
                        name="cold"
                        checked={invTabs.serial}
                        onChange={() => handleInvTabs({ ...invTabs, serial: !invTabs.serial })}
                      />
                      Inventory ID <br />
                    </div>
                    <div className="p-2">
                      <input
                        className="mr-1"
                        type="checkbox"
                        id="click-me_name"
                        name="cold"
                        checked={invTabs.name}
                        onChange={() => handleInvTabs({ ...invTabs, name: !invTabs.name })}
                      />
                      Name <br />
                    </div>
                    <div className="p-2">
                      <input
                        className="mr-1"
                        type="checkbox"
                        id="click-me_type"
                        name="cold"
                        checked={invTabs.type}
                        onChange={() => handleInvTabs({ ...invTabs, type: !invTabs.type })}
                      />
                      Type <br />
                    </div>
                    <div className="p-2">
                      <input
                        className="mr-1"
                        type="checkbox"
                        id="click-me_price"
                        name="cold"
                        checked={invTabs.price}
                        onChange={() => handleInvTabs({ ...invTabs, price: !invTabs.price })}
                      />
                      Price <br />
                    </div>
                    <div className="p-2">
                      <input
                        className="mr-1"
                        type="checkbox"
                        id="click-me_quantity"
                        name="cold"
                        checked={invTabs.quantity}
                        onChange={() => handleInvTabs({ ...invTabs, quantity: !invTabs.quantity })}
                      />
                      Quantity <br />
                    </div>
                    <div className="p-2">
                      <input
                        className="mr-1"
                        type="checkbox"
                        id="click-me_minimum"
                        name="cold"
                        checked={invTabs.minquan}
                        onChange={() => handleInvTabs({ ...invTabs, minquan: !invTabs.minquan })}
                      />
                      Minimum Quantity <br />
                    </div>
                    <div className="p-2">
                      <input
                        className="mr-1"
                        type="checkbox"
                        id="click-me_location"
                        name="cold"
                        checked={invTabs.location}
                        onChange={() => handleInvTabs({ ...invTabs, location: !invTabs.location })}
                      />
                      Location <br />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {type !== "checklist" && <div
        id="tabledownload"
        className={exportsfiles ? ' show' : 'collapse show'}
        style={{ marginLeft: '20px' }}
      >
        <div className="col-lg-12 col-sm-12 mb-4">
          <div className="row">
            <form method="post" action="/upload-inventory/">
              {/* <input
                    type="hidden"
                    name="csrfmiddlewaretoken"
                    value="BOOo3fJ65aizTFcfF3OXBsCL3oS12iAUWf24ZxEpqSiYpu1GEogom1I7mWBSLOGV"
                  /> */}
              <input
                required
                type="file"
                className="form-control-file"
                id="csv_file"
                name="file"
                ref={fileInputRef}
                onChange={handelImportFile}
              />
              {user?.role !== 'Technical Team' && (
                <button
                  id="fakebutton"
                  type="button"
                  className="btn tertiary-btn"
                  onClick={handleClick}
                >
                  <div className="fas fa-upload mr-2" aria-hidden="true">
                    {' '}
                  </div>
                  Import From Excel
                </button>
              )}
            </form>

            {user?.role !== 'Technical Team' && (
              <button
                type="button"
                title="Add Sublocation"
                data-toggle="modal"
                data-target="#myModal"
                // onClick="get_location_data(false, 'none')"
                className="btn tertiary-btn ml-2 "
                onClick={() => dispatch(exportInventory({ extension: 'JSON' }))}
              >
                <div className="fas fa-download mr-2" aria-hidden="true">
                  {' '}
                </div>
                Export To Excel
              </button>
            )}
            <button
              id="fakebutton"
              type="button"
              className="btn tertiary-btn"
              onClick={() =>
                dispatch(downloadTemplate({ extension: 'CSV', template_type: 'Inventory' }))
              }
              style={{ marginLeft: '7px' }}
            >
              <div className="fas fa-upload mr-2" aria-hidden="true">
                {' '}
              </div>
              Download Template
            </button>
            <button  type="button" className="btn tertiary-btn" style={{ marginLeft: '7px' }}>
            <InventoryBulkQrPrint inventories={inventorys?.results} company={profile?.logo} />
            </button>
           {/*  <BlobProvider
              document={
                <InventoryBulkQrPrint inventories={inventorys?.results} company={profile?.logo} />
              }
            >
              {({ url, loading, ...rest }) =>
                loading ? (
                  'Loading...'
                ) : (
                  <button type="button" className="btn tertiary-btn" style={{ marginLeft: '7px' }}>
                    <a href={url} target="_blank" style={{ color: 'red' }}>
                      Bulk Qr
                      <FontAwesomeIcon style={{ paddingLeft: '7px' }} icon={faPrint} />
                    </a>
                  </button>
                )
              }
            </BlobProvider> */}
          </div>
        </div>
      </div>}
    </>
  );
}
