import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import assetMappingService from './assetMappingService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // Assets
  assetsMapping: [],
  assetMapping: {},
  selectedMarker: {},
  unmarkedAsset: [],
  markedAsset: [],
  deleteMarker: [],
  planName : "",

  // modals
  addGroupModal: false,

  // Asset Mapping fields
  color: '#f50000',
  groupName: '',
  groups: [],
  room: '',
  selectedPlan : '',
  locationChange : '',
  // success states
  isAssetMappingGetSuccess: false,
  isAssetMappingCreateSuccess: false,
  isAssetMappingDeleteSuccess: false,
  isAssetMappingUpdateSuccess: false,

  // loading states
  isAssetMappingCreateLoading: false,
  isAssetMappingGetLoading: false,
  isAssetMappingDeleteLoading: false,
  isAssetMappingUpdateLoading: false,
  isUnmarkedAssetGetLoading: false,

  // error states
  isAssetMappingGetError: false,
  isAssetMappingCreateError: false,
  isAssetMappingDeleteError: false,
  isAssetMappingUpdateError: false,

  // assign click
  assignclicked: {},
  assignassets: [],
};

export const getAssetsMappingByAuthentication = createAsyncThunk(
  'getAssetsMappingByAuthentication',
  async (data, thunkAPI) => {
    const getAssetsMapping = await smartTryCatch(
      assetMappingService.getAssetsMappingByAuthentication,
      data,
      thunkAPI,
    );
    return getAssetsMapping;
  },
);

export const getUnmarkedAssetById = createAsyncThunk(
  'getUnmarkedAssetById',
  async (data, thunkAPI) => {
    const getUnmarkedAssetByIdRES = await smartTryCatch(
      assetMappingService.getUnmarkedAssetById,
      data,
      thunkAPI,
    );
    return getUnmarkedAssetByIdRES;
  },
);

export const createMarkedAssetById = createAsyncThunk(
  'createMarkedAssetById',
  async (data, thunkAPI) => {
    const createMarkedAssetByIdRES = await smartTryCatch(
      assetMappingService.createMarkedAssetById,
      data,
      thunkAPI,
    );
    return createMarkedAssetByIdRES;
  },
);

export const updateMarkedAssetById = createAsyncThunk(
  'updateMarkedAssetById',
  async (data, thunkAPI) => {
    const updateMarkedAssetByIdRES = await smartTryCatch(
      assetMappingService.updateMarkedAssetById,
      data,
      thunkAPI,
    );
    return updateMarkedAssetByIdRES;
  },
);

export const deleteMarkedAssetById = createAsyncThunk(
  'deleteMarkedAssetById',
  async (data, thunkAPI) => {
    const deleteMarkedAssetByIdRES = await smartTryCatch(
      assetMappingService.deleteMarkedAssetById,
      data,
      thunkAPI,
    );
    return deleteMarkedAssetByIdRES;
  },
);

export const getMarkedAssetById = createAsyncThunk('getMarkedAssetById', async (data, thunkAPI) => {
  const getMarkedAssetByIdRES = await smartTryCatch(
    assetMappingService.getMarkedAssetById,
    data,
    thunkAPI,
  );
  return getMarkedAssetByIdRES;
});

export const getAllGroups = createAsyncThunk('getAllGroups', async (data, thunkAPI) => {
  const getAllGroupsRES = await smartTryCatch(assetMappingService.getAllGroups, data, thunkAPI);
  return getAllGroupsRES;
});

export const createNewGroup = createAsyncThunk('createNewGroup', async (data, thunkAPI) => {
  const createNewGroupRES = await smartTryCatch(assetMappingService.createNewGroup, data, thunkAPI);
  return createNewGroupRES;
});

export const updateGroup = createAsyncThunk('updateGroup', async (data, thunkAPI) => {
  const updateGroupRES = await smartTryCatch(assetMappingService.updateGroup, data, thunkAPI);
  return updateGroupRES;
});

export const deleteGroup = createAsyncThunk('deleteGroup', async (data, thunkAPI) => {
  const deleteGroupRES = await smartTryCatch(assetMappingService.deleteGroup, data, thunkAPI);
  return deleteGroupRES;
});

export const createAssignGroup = createAsyncThunk('createAssignGroup', async (data, thunkAPI) => {
  const createAssignGroupRES = await smartTryCatch(
    assetMappingService.createAssignGroup,
    data,
    thunkAPI,
  );
  return createAssignGroupRES;
});
export const updateAssignGroup = createAsyncThunk('updateAssignGroup', async (data, thunkAPI) => {
  const updateAssignGroupRES = await smartTryCatch(
    assetMappingService.updateAssignGroup,
    data,
    thunkAPI,
  );
  return updateAssignGroupRES;
});

export const assetMappingSlice = createSlice({
  name: 'assetMapping',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isAssetMappingCreateError = false;
      state.isAssetMappingCreateSuccess = false;
      state.isAssetMappingCreateLoading = false;
      state.isAssetMappingGetError = false;
      state.isAssetMappingGetSuccess = false;
      state.isAssetMappingGetLoading = false;
      state.isAssetMappingDeleteError = false;
      state.isAssetMappingDeleteSuccess = false;
      state.isAssetMappingDeleteLoading = false;
      state.isAssetMappingUpdateError = false;
      state.isAssetMappingUpdateSuccess = false;
      state.isAssetMappingUpdateLoading = false;
      state.isUnmarkedAssetGetLoading = false;
    },
    openAddGroupModal: (state) => {
      state.addGroupModal = true;
    },
    closeAddGroupModal: (state) => {
      state.addGroupModal = false;
    },

    setColor: (state, action) => {
      state.color = action.payload;
    },
    setGroupName: (state, action) => {
      state.groupName = action.payload;
    },
    setAddGroup: (state, action) => {
      state.groups.push(action.payload);
    },

    setDeleteGroup: (state, action) => {
      state.groups.splice(action.payload);
    },
    setRemoveAssetFromGroup: (state, action) => {
      state.groups =action.payload
    },

    setUpdateGroup: (state, action) => {
      state.groups[action.payload.idx] = action.payload.data;
    },

    setAssetMapping: (state, action) => {
      state.assetMapping = action.payload;
    },
    setSelectedMarker: (state, action) => {
      state.selectedMarker = action.payload;
    },
    setRoom: (state, action) => {
      state.room = action.payload;
    },
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    setAssignclicked: (state, action) => {
      state.assignclicked = action.payload;
    },
    setAssignassets: (state, action) => {
      state.assignassets.push(action.payload);
    },
    setDeleteMarker: (state, action) => {
      state.markedAsset = action.payload;
    },
    setDeleteMarkerOnSave: (state, action) => {
      state.deleteMarker.push(action.payload);
    },
    resetDeleteMarkerOnSave: (state, action) => {
      state.deleteMarker = action.payload;
    },
    putAssignassets: (state, action) => {
      state.assignassets = action.payload;
    },
    setUnmarkedAsset: (state, action) => {
      state.unmarkedAsset = action.payload;
    },
    setMarkedAsset: (state, action) => {
      state.markedAsset = action.payload;
    },
    setLocationChange: (state, action) => {
      state.locationChange = action.payload;
    },
    setPlanName: (state, action) => {
      state.planName = action.payload;
    },

    resetAssetMapping: (state) => {
      state.assetsMapping = [];
      state.assetMapping = {};
      state.selectedMarker = {};
      state.unmarkedAsset = [];
      state.markedAsset = [];
      state.groups = [];
      state.color = '#f50000';
      state.room = '';
      state.groupName = '';
      state.selectedPlan = '';
      state.locationChange = '';
      state.planName = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssetsMappingByAuthentication.pending, (state) => {
        state.isAssetMappingGetLoading = true;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = false;
      })
      .addCase(getAssetsMappingByAuthentication.fulfilled, (state, action) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = true;
        state.isAssetMappingGetError = false;
        state.assetsMapping = action.payload;
      })
      .addCase(getAssetsMappingByAuthentication.rejected, (state) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = true;
      })

      .addCase(getUnmarkedAssetById.pending, (state) => {
        state.isUnmarkedAssetGetLoading = true;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = false;
      })
      .addCase(getUnmarkedAssetById.fulfilled, (state, action) => {
        state.isUnmarkedAssetGetLoading = false;
        state.isAssetMappingGetSuccess = true;
        state.isAssetMappingGetError = false;
        state.unmarkedAsset = action.payload;
      })
      .addCase(getUnmarkedAssetById.rejected, (state) => {
        state.isUnmarkedAssetGetLoading = false;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = true;
      })
      .addCase(createMarkedAssetById.pending, (state) => {
        state.isAssetMappingGetLoading = true;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = false;
      })
      .addCase(createMarkedAssetById.fulfilled, (state, action) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = true;
        state.isAssetMappingGetError = false;
        state.markedAsset?.push(action.payload);
      })
      .addCase(createMarkedAssetById.rejected, (state) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = true;
      })
      .addCase(updateMarkedAssetById.pending, (state) => {
        state.isAssetMappingGetLoading = true;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = false;
      })
      .addCase(updateMarkedAssetById.fulfilled, (state, action) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = true;
        state.isAssetMappingGetError = false;
        state.markedAsset?.push(action.payload);
      })
      .addCase(updateMarkedAssetById.rejected, (state) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = true;
      })
      .addCase(deleteMarkedAssetById.pending, (state) => {
        state.isAssetMappingGetLoading = true;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = false;
      })
      .addCase(deleteMarkedAssetById.fulfilled, (state, action) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = true;
        state.isAssetMappingGetError = false;
        state.markedAsset = action.payload;
      })
      .addCase(deleteMarkedAssetById.rejected, (state) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = true;
      })
      .addCase(getMarkedAssetById.pending, (state) => {
        state.isAssetMappingGetLoading = true;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = false;
      })
      .addCase(getMarkedAssetById.fulfilled, (state, action) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = true;
        state.isAssetMappingGetError = false;
        state.markedAsset = action.payload;
      })
      .addCase(getMarkedAssetById.rejected, (state) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = true;
      })
      .addCase(createNewGroup.pending, (state) => {
        state.isAssetMappingGetLoading = true;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = false;
      })
      .addCase(createNewGroup.fulfilled, (state, action) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = true;
        state.isAssetMappingGetError = false;
        state.groups?.push(action.payload);
      })
      .addCase(createNewGroup.rejected, (state) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = true;
      })
      .addCase(getAllGroups.pending, (state) => {
        state.isAssetMappingGetLoading = true;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = false;
      })
      .addCase(getAllGroups.fulfilled, (state, action) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = true;
        state.isAssetMappingGetError = false;
        state.groups = action.payload;
      })
      .addCase(getAllGroups.rejected, (state) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = true;
      })
      .addCase(updateGroup.pending, (state) => {
        state.isAssetMappingGetLoading = true;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = false;
      })
      .addCase(updateGroup.fulfilled, (state, action) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = true;
        state.isAssetMappingGetError = false;
        state.groups?.push(action.payload);
      })
      .addCase(updateGroup.rejected, (state) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = true;
      })
      .addCase(deleteGroup.pending, (state) => {
        state.isAssetMappingGetLoading = true;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = false;
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = true;
        state.isAssetMappingGetError = false;
        state.groups?.push(action.payload);
      })
      .addCase(deleteGroup.rejected, (state) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = true;
      })
      .addCase(createAssignGroup.pending, (state) => {
        state.isAssetMappingGetLoading = true;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = false;
      })
      .addCase(createAssignGroup.fulfilled, (state) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = true;
        state.isAssetMappingGetError = false;
        state.assignassets = [];
      })
      .addCase(createAssignGroup.rejected, (state) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = true;
      })
      .addCase(updateAssignGroup.pending, (state) => {
        state.isAssetMappingGetLoading = true;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = false;
      })
      .addCase(updateAssignGroup.fulfilled, (state) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = true;
        state.isAssetMappingGetError = false;
        state.assignassets = [];
      })
      .addCase(updateAssignGroup.rejected, (state) => {
        state.isAssetMappingGetLoading = false;
        state.isAssetMappingGetSuccess = false;
        state.isAssetMappingGetError = true;
      });
  },
});

export const {
  setAssetMapping,
  setSelectedMarker,
  reset,
  openAddGroupModal,
  closeAddGroupModal,
  setColor,
  setGroupName,
  setAddGroup,
  setAssignclicked,
  setAssignassets,
  putAssignassets,
  setDeleteGroup,
  setUpdateGroup,
  setRoom,
  setDeleteMarker,
  setDeleteMarkerOnSave,
  resetDeleteMarkerOnSave,
  resetAssetMapping,
  setSelectedPlan,
  setUnmarkedAsset,
  setLocationChange,
  setPlanName,
  setMarkedAsset,
} = assetMappingSlice.actions;

export default assetMappingSlice.reducer;
