/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { getLocationsByAuthentication, getPagination } from '../../../redux/features/location/locationSlice';
import { getAssetCategoriesByAuthentication } from '../../../redux/features/assetCategory/assetCategorySlice';
import {
  setAssetFilterCategory,
  setAssetFilterSite,
  setAssetFilterSublocation,
} from '../../../redux/features/asset/assetSlice';
import { SelectLocationFilter, SelectSublocationFilter, SelectTypesAndCategories } from '../../../components/select/select';

const AssetFilter = () => {
  const dispatch = useDispatch();
  const [locationsData, setLocations] = useState([]);
  const { locations } = useSelector((state) => state.location);
  const { assetCategories } = useSelector((state) => state.assetCategory);
  const sublocations = useSelector((state) => state.location?.sublocations?.results);
  const { site, sublocation, category } = useSelector(state => state.asset);

  useEffect(() => {
    dispatch(getLocationsByAuthentication());
    dispatch(getAssetCategoriesByAuthentication());
  }, []);
  const handleEraserFilterClick = () => {
    // clear the value of the select
    // document.getElementById('siteSelect').value = '';
    // document.getElementById('locationSelect').value = '';
    // document.getElementById('categorySelect').value = '';
    setLocations([]);
    // reset the state and store
    dispatch(setAssetFilterSite(null));
    dispatch(setAssetFilterSublocation(null));
    dispatch(setAssetFilterCategory(null));
  };

  useEffect(() => {
    setLocations(sublocations || [])
  }, [sublocations])

  return (
    <div className="filters-wrapper-inv">
      <div className="filter-wrapper-inv">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Location</label>
        <SelectLocationFilter locations={locations} site={site} setFilterSite={setAssetFilterSite}
          setFilterSublocation={setAssetFilterSublocation} setLocations={setLocations} />
      </div>
      <div className="filter-wrapper-inv">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Sublocation</label>
        <SelectSublocationFilter sublocationsData={locationsData} sublocation={sublocation} setFilterSublocation={setAssetFilterSublocation} site={site} />
      </div>
      <div className="filter-wrapper-inv">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Category</label>
        <SelectTypesAndCategories dataArray={assetCategories} stateValue={category} setState={setAssetFilterCategory} />
      </div>
      <div className="erase-wrapper-inv">
        <div className="erase-btn">
          <Button onClick={handleEraserFilterClick} title="Erase Filter" variant="outline-danger">
            <FontAwesomeIcon style={{ padding: '2px' }} icon={faEraser} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AssetFilter;
