/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-useless-return */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-cond-assign */
/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable dot-notation */
/* eslint-disable consistent-return */
/* eslint-disable camelcase  */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationsByAuthentication } from '../../../redux/features/location/locationSlice';
import { getAssetCategoriesByAuthentication } from '../../../redux/features/assetCategory/assetCategorySlice';
import { getAssetStatusByAuthentication } from '../../../redux/features/assetStatus/assetStatusSlice';
import AssetDetailsForm from './addAssetForm/assetDetailsForm';
import AssetFinanceForm from './addAssetForm/assetFinanceForm';
import AssetDocumentsForm from './addAssetForm/assetDocumentsForm';
import AssetPhotosForm from './addAssetForm/assetPhotosForm';
import AssetSpecificationsForm from './addAssetForm/assetSpecificationsForm';
import AssetSparePartsForm from './addAssetForm/assetSparePartsForm';
import AssetDepricationForm from './addAssetForm/assetDepricationForm';
import classes from './addAsset.module.css';
import {
  createAsset,
  getAssetsByAuthentication,
  reset,
} from '../../../redux/features/asset/assetSlice';
import { getTeamsByAuthentication } from '../../../redux/features/teams/teamSlice';
import { getInventorysByAuthentication } from '../../../redux/features/inventory/inventorySlice';
import PopUpAddErrorModal from '../../../components/popUpModals/editCategory/popUpAddErrorModal';
import { selectActions } from '../../../redux/features/selectOptions/selectSlice';
import { getAssetTypesByAuthentication } from '../../../redux/features/assetType/assetTypeSlice';
import { getDepartmentsByAuthentication } from '../../../redux/features/department/departmentSlice';
import formatNumber from '../../../utils/formatNumber';

// const selectedForm = JSON.parse(localStorage.getItem('selectedForm'));
function AddAsset () {
  const history = useHistory();
  const [selected, setSlected] = useState('Asset Details');
  const [assetDetailsForm, setAssetDetailsForm] = useState({
    assetName: '',
    loan_eligibility:true,
    assetNumber: '',
    serialNumber: '',
    assetType: '',
    brand: '',
    custodian: '',
    category: '',
    status: '',
    description: '',
    department:null
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartmentsByAuthentication())
    dispatch(getAssetsByAuthentication());
    dispatch(getLocationsByAuthentication());
    dispatch(getAssetCategoriesByAuthentication());
    dispatch(getAssetStatusByAuthentication());
    dispatch(getTeamsByAuthentication());
    dispatch(getInventorysByAuthentication());
    dispatch(getAssetTypesByAuthentication());
    dispatch(selectActions.reset());
  }, []);

  const generateAssetNumber = () => {
    const random = Math.floor(Math.random() * 1000000000000);
    setAssetDetailsForm({ ...assetDetailsForm, assetNumber: random });
  };


  const { assigned_to , assetAssignedTo } = useSelector(state => state.asset);
  const { assetCategories } = useSelector(state => state.assetCategory);
  // const { user } = useSelector(state => state.user);
  const { assetStatuses } = useSelector(state => state.assetStatus);
  const {locations , sublocations} = useSelector(state => state.location)
  const {idOfSite, idOfLocation, idOfCategory, idOfStatus, idOfAssetType } =
    useSelector(state => state.selectOptions);

  const allSubLocations = [];

  const [assetFinanceForm, setAssetFinanceForm] = useState({
    purchased_price: '',
    purchased_date: '',
    vendor: '',
    warranty_expiration: '',
    po_number: '',
  });

  const [assetDocumentsFormFile, setAssetDocumentsFormFile] = useState(null);
  const [assetDocumentsFormFileType, setAssetDocumentsFormFileType] = useState('Manual');
  const [attachTable, setAttachTable] = useState([]);
  const [assetPhotosFormFile, setAssetPhotosFormFile] = useState(null);
  const [isprimary, setIsprimary] = useState(false);
  const [photosTable, setPhotosTable] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalAddError, setShowModalAddError] = useState(false);
  const [errWithACtivateDeprication, setErrWithACtivateDeprication] = useState(false);
  const [inputSp, setInputSp] = useState('');

  const handelCloseModalAddError = () => {
    setShowModalAddError(false);
    setErrWithACtivateDeprication(false);
  };
  const handelChangeDetailsForm = e => {
    setAssetDetailsForm({ ...assetDetailsForm, [e.target.name]: e.target.value });
  };
  

  const handelChangeFinanceForm = e => {
    if (e.target.name === "purchased_price") {
      const input = e.target;
      const {value} = input;
      const formattedValue = formatNumber(value)[0];
      setAssetFinanceForm({ ...assetFinanceForm, [e.target.name]: formattedValue });
    }else{
     setAssetFinanceForm({ ...assetFinanceForm, [e.target.name]: e.target.value });
    }
  };

  const handelChangeDocumentsFormFile = e => {
    setAssetDocumentsFormFile(e.target.files[0]);
  };
  const handelChangeDocumentsFormFileType = e => {
    setAssetDocumentsFormFileType(e.target.value);
  };
  const handleChangeLoanEligability = e => {
    setAssetDetailsForm({...assetDetailsForm,[e.target.name]: !assetDetailsForm.loan_eligibility});
  };

  const handelChangePhotosFormFile = e => {
    setAssetPhotosFormFile(e.target.files[0]);
    setPhoto(URL.createObjectURL(e.target.files[0]));
  };
  const handelChangePrimary = () => {
    setIsprimary(!isprimary);
  };

  const handleCreateSaveAttach = () => {
    if (assetDocumentsFormFile === null) {
      return;
    }
    setAttachTable([...attachTable, { assetDocumentsFormFile, assetDocumentsFormFileType }]);
    setAssetDocumentsFormFile(null);
    setShowModal(false);
  };

  const deleteAttach = i => {
    const list = [...attachTable];
    list.splice(i, 1);
    setAttachTable(list);
  };

  const handleCreateSavePhoto = () => {
    if (assetPhotosFormFile === null && !isprimary) {
      return;
    } else if (assetPhotosFormFile !== null) {
      setPhotosTable([...photosTable, { assetPhotosFormFile, isprimary, photo }]);
      setAssetPhotosFormFile(null);
      setShowModal(false);
    }
  };
  const deletePhoto = i => {
    const list = [...photosTable];
    list.splice(i, 1);
    setPhotosTable(list);
  };

  // AssetSpecificationsForm
  const [inputList, setInputList] = useState([{ field: '', value: '' }]);

  const addInputHandler = () => {
    if (inputList.length === 0) {
      setInputList([...inputList, { field: '', value: '' }]);
    } else if (
      inputList.length > 0 &&
      inputList[inputList.length - 1].field !== '' &&
      inputList[inputList.length - 1].value !== ''
    ) {
      setInputList([...inputList, { field: '', value: '' }]);
    }
  };
  const removeInputHandler = i => {
    const list = [...inputList];
    list.splice(i, 1);
    setInputList(list);
  };

  const handelSpChange = e => {
    const { value } = e.target;
    setAssetDetailsForm({ ...assetDetailsForm, [e.target.name]: e.target.value });
    setInputSp(value);
    /* const change = [...inputList];
    change[i][name] = value;
    setInputList(change); */
  };

  // Spare Parts
  const [showModalOfSpare, setShowModalOfSpare] = useState(false);
  const [sparePart, setSparePart] = useState([]);
  const [sparePartErr, setSparePartErr] = useState(null);
  const [spareTable, setSpareTable] = useState([]);
  const handleSaveSpare = () => {
    if (sparePart !== null) {
      setSparePartErr(false);
      setSpareTable(sparePart);
      setShowModalOfSpare(false);
    } else {
      setSparePartErr(true);
    }
  };

  const handelInventory = data => {
    setSparePartErr(false);
    if (sparePart?.length > 0) {
      const arrOfIds = [];
      sparePart.map(i => arrOfIds.push(i?.id));
      if (arrOfIds.includes(data?.id)) {
        setSparePart(sparePart.filter(i => i.id !== data?.id));
      } else {
        setSparePart([...sparePart, data]);
      }
    } else {
      setSparePart(sparePart?.concat(data));
    }
  };

  const deleteSpare = id => {
    setSparePart(sparePart.filter(d => d.id !== id));
    setSpareTable(spareTable.filter(d => d.id !== id));
  };

  //  AssetDepricationForm
  const [enableDeprication, setEnableDeprication] = useState(false);

  const [assetDepricationForm, setAssetDepricationForm] = useState({
    salvage_price: '',
    service_life: '',
    start_date: '',
    filter: '',
    assigned_to: '',
  });

  const handelEditDepricationChange = () => {
    setEnableDeprication(!enableDeprication);
  };
  const handelChangeDepricationForm = e => {
    if (e.target.name === "salvage_price") {
      const input = e.target;
      const {value} = input;
      const formattedValue = formatNumber(value)[0];
      setAssetDepricationForm({ ...assetDepricationForm, [e.target.name]: formattedValue });
    }else{
      setAssetDepricationForm({ ...assetDepricationForm, [e.target.name]: e.target.value });
    }
  };

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const searchPramId = query.get('parent_id');

  const handelTabs = tab => {
    setSlected(tab);
  };
  const tabs = [
    'Asset Details',
    'Finance',
    'Documents',
    'Photos',
    'Specifications',
    'Spare Parts',
    ' Asset Depreciation',
  ];

  useEffect(() => {
    localStorage.setItem('selectedForm', JSON.stringify(selected));
  }, [selected]);

  useEffect(() => {
    if (searchPramId) {
      tabs.slice(0, 5).map(tab => {
        if (selected === tab) {
          setSlected(selected);
        } else {
          setSlected('Asset Details');
        }
      });
    }
  }, [searchPramId]);

  // const { assignedTo } = useSelector(state => state.selectOptions);

  const inventory = [];
  if (spareTable.length > 0) {
    spareTable.forEach(i => {
      inventory.push(i.id);
    });
  }
  const { user } = useSelector(state => state.user);
  const { isAssetCreateSuccess, isAssetCreateError } = useSelector(state => state.asset);
  const submitHandler = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', assetDetailsForm.assetName);
    formData.append('asset_number', assetDetailsForm.assetNumber);
    formData.append('serial_number', assetDetailsForm.serialNumber);
    formData.append('asset_type', idOfAssetType);
    formData.append('brand', assetDetailsForm.brand);
    formData.append('description', assetDetailsForm.description);
    formData.append('show_depreciation', enableDeprication);
    formData.append('location', idOfSite);
    if (user.role === 'CliAdm') {
      formData.append('loan_eligibility',assetDetailsForm.loan_eligibility);
    }
    if (assetDetailsForm.department) {
      formData.append('department', assetDetailsForm.department);
    }
    
    formData.append('sub_location', idOfLocation || '');
    formData.append('category', idOfCategory);
    formData.append('status', idOfStatus);
    formData.append('specifications.field', inputSp);
    if (assigned_to?.length > 0) {
      assigned_to?.forEach(ele => formData.append('assigned_to', ele?.value));
    }

    formData.append('finance.purchased_price', String(assetFinanceForm.purchased_price));
    formData.append(
      'finance.purchased_date',
      moment(Date(assetFinanceForm.purchased_date)).format(),
    );
    formData.append('finance.vendor', assetFinanceForm.vendor);
    formData.append(
      'finance.warranty_expiration',
      moment(Date(assetFinanceForm.warranty_expiration)).format(),
    );
    formData.append('finance.po_number', assetFinanceForm.po_number);

    if (assetDepricationForm.filter !== '' && !searchPramId) {
      formData.append('depreciation.salvage_price', String(assetDepricationForm.salvage_price));
      formData.append(
        'depreciation.start_date',
        moment(Date(assetDepricationForm.start_date)).format(),
      );
      formData.append('depreciation.service_life', assetDepricationForm.service_life);
      formData.append('depreciation.filter', assetDepricationForm.filter);
      // if (assignedTo.length > 0) {
      //   assignedTo.forEach(ele => formData.append('depreciation.assigned_to', ele.id));
      // }
      if (assetAssignedTo.length > 0) {
        assetAssignedTo.forEach(ele => formData.append('depreciation.assigned_to', ele.value));
      }
    }
    if (searchPramId) {
      formData.append('parent_id', searchPramId);
    }
    const arrSp = [];
    if (inputList?.length > 0) {
      inputList?.map(item => {
        if (item?.field) {
          arrSp.push(item);
        }
      });
    }

    /* if (arrSp.length > 0) {
  const name = "specifications";
        inputList?.forEach((item,i) => {
            formData.append((name + '[' + i + ']'+["[field]"]), item["field"]);
            formData.append((name + '[' + i + ']'+["[value]"]), item["value"]);
            formData.append((name + '[' + i + ']'+["[id]"]), "");
          });

} */

    if (attachTable.length > 0) {
      const name = 'attachments';
      attachTable?.forEach((item, i) => {
        formData.append(name + '[' + i + ']' + ['[file_type]'], item['assetDocumentsFormFileType']);
        formData.append(name + '[' + i + ']' + ['[file]'], item['assetDocumentsFormFile']);
      });
    }
    if (photosTable.length > 0) {
      const name = 'photos';
      photosTable?.forEach((item, i) => {
        formData.append(name + '[' + i + ']' + ['[is_primary]'], item['isprimary']);
        formData.append(name + '[' + i + ']' + ['[photo]'], item['assetPhotosFormFile']);
        formData.append(name + '[' + i + ']' + ['[id]'], '');
      });
    }
    if (inventory.length > 0) {
      inventory?.forEach(id => {
        formData.append('inventory', id);
      });
    }

    if (
      assetDetailsForm.assetName &&
      idOfAssetType &&
      idOfSite &&
      idOfCategory &&
      idOfStatus &&
      assetDetailsForm.assetNumber &&
      !enableDeprication
    ) {
      if (!formatNumber(assetFinanceForm.purchased_price)[1] && assetFinanceForm.purchased_price) {
        toast.warning("Please write a correct Number on Purchase Price field (write the decimal part or remove decimal point)", {
          toastId: 'CreatedEventWarninggg',
        });
      }else{
      await dispatch(createAsset(formData));
      dispatch(reset());
      }
    } else if (
      assetDetailsForm.assetName &&
      idOfAssetType &&
      assetDetailsForm.assetNumber &&
      idOfSite &&
      idOfCategory &&
      idOfStatus &&
      enableDeprication
    ) {
      if (
        !assetFinanceForm.purchased_price ||
        !assetFinanceForm.purchased_date ||
        !assetFinanceForm.vendor ||
        !assetDepricationForm.salvage_price ||
        !assetDepricationForm.start_date ||
        !assetDepricationForm.service_life ||
        !assetDepricationForm.filter ||
        !assetAssignedTo.length > 0
      ) {
          setErrWithACtivateDeprication(true);
      }
        else if (!formatNumber(assetFinanceForm.purchased_price)[1] && assetFinanceForm.purchased_price) {
          toast.warning("Please write a correct Number on Purchase Price field (write the decimal part or remove decimal point)", {
            toastId: 'CreatedEventWarninggg',
          });
        }
        else if (!formatNumber(assetDepricationForm.salvage_price)[1]) {
          toast.warning("Please write a correct Number on Salvage Price field (write the decimal part or remove decimal point)", {
            toastId: 'CreatedEventWarninggg',
          });
        }
        else{
          await dispatch(createAsset(formData));
          dispatch(reset());
        }
    } else {
      setShowModalAddError(true);
    }
  };

  useEffect(() => {
    if (isAssetCreateSuccess) {
      setTimeout(() => {
        history.push('/assets');
      }, 1000);
    }
  }, [isAssetCreateSuccess]);

  function displayToast () {
    if (isAssetCreateError) {
      toast.error('Something went wrong Creating Asset', {
        toastId: 'CreatingAssetError',
      });
      dispatch(reset());
    } else if (isAssetCreateSuccess) {
      toast.success('Creating Asset successfully', {
        toastId: 'Asset Created Success',
      });
    }
  }

  return (
    <>
      {displayToast()}
      <div className={classes.addAsset}>
        <form onSubmit={submitHandler} className={classes.form}>
          <div className={classes.addAssetsTop}>
            <h4 className={classes.addAssetsTitle}>Add Asset Details</h4>
            <div className={classes.addAssetActions}>
              <button type="submit" className={classes.addAssetSave}>
                Save
              </button>
              <button type="button" className={classes.addAssetBack}>
                <Link to="/assets"> Back </Link>
              </button>
            </div>
          </div>
          <ul className={classes.list}>
            {searchPramId
              ? tabs.slice(0, 5).map(tab => {
                  const tabClass = selected === tab ? classes.active : classes.li;
                  return (
                    <li key={tab}>
                      <button className={tabClass} type="button" onClick={() => handelTabs(tab)}>
                        {tab}
                      </button>
                    </li>
                  );
                })
              : tabs.map(tab => {
                  const tabClass = selected === tab ? classes.active : classes.li;
                  return (
                    <li key={tab}>
                      <button className={tabClass} type="button" onClick={() => handelTabs(tab)}>
                        {tab}
                      </button>
                    </li>
                  );
                })}
          </ul>
          {selected === tabs[0] && (
            <AssetDetailsForm
              handelChange={handelChangeDetailsForm}
              assetDetailsForm={assetDetailsForm}
              locations={locations}
              assetCategories={assetCategories}
              assetStatuses={assetStatuses}
              subLocation ={sublocations?.results} 
              allSubLocations={allSubLocations}
              generateAssetNumber={generateAssetNumber}
              handleChangeLoanEligability={handleChangeLoanEligability}
            />
          )}
          {selected === tabs[1] && (
            <AssetFinanceForm
              handelChangeFinanceForm={handelChangeFinanceForm}
              assetFinanceForm={assetFinanceForm}
            />
          )}
          {selected === tabs[2] && (
            <AssetDocumentsForm
              assetDocumentsFormFile={assetDocumentsFormFile}
              assetDocumentsFormFileType={assetDocumentsFormFileType}
              handelChangeDocumentsFormFile={handelChangeDocumentsFormFile}
              handelChangeDocumentsFormFileType={handelChangeDocumentsFormFileType}
              handleCreateSaveAttach={handleCreateSaveAttach}
              attachTable={attachTable}
              showModal={showModal}
              setShowModal={setShowModal}
              deleteAttach={deleteAttach}
            />
          )}
          {selected === tabs[3] && (
            <AssetPhotosForm
              handelChangePhotosFormFile={handelChangePhotosFormFile}
              handleCreateSavePhoto={handleCreateSavePhoto}
              photosTable={photosTable}
              showModal={showModal}
              setShowModal={setShowModal}
              handelChangePrimary={handelChangePrimary}
              isprimary={isprimary}
              deletePhoto={deletePhoto}
            />
          )}
          {selected === tabs[4] && (
            <AssetSpecificationsForm
              inputSp={inputSp}
              inputList={inputList}
              setInputList={setInputList}
              addInputHandler={addInputHandler}
              removeInputHandler={removeInputHandler}
              handelSpChange={handelSpChange}
            />
          )}
          {selected === tabs[5] && (
            <AssetSparePartsForm
              showModal={showModalOfSpare}
              handleCreateSave={handleSaveSpare}
              setShowModal={setShowModalOfSpare}
              handelInventory={handelInventory}
              sparePartErr={sparePartErr}
              sparePart={sparePart}
              spareTable={spareTable}
              deleteSpare={deleteSpare}
            />
          )}
          {selected === tabs[6] && (
            <AssetDepricationForm
              handelEditDepricationChange={handelEditDepricationChange}
              enableDeprication={enableDeprication}
              handelChangeDepricationForm={handelChangeDepricationForm}
              assetDepricationForm={assetDepricationForm}
            />
          )}
          <PopUpAddErrorModal
            modalTitle="Error With Add Asset"
            handleClose={handelCloseModalAddError}
            showModal={showModalAddError || errWithACtivateDeprication}
          >
            {errWithACtivateDeprication && (
              <span>
                <h5> To Activate Asset Depreciation:</h5> Please fill out the required fields in the
                Finance and Depreciation Form.
              </span>
            )}
            {showModalAddError && (
              <span>
                <h5>Attention:</h5> Please Fill the Required Fields in Asset Details
              </span>
            )}
          </PopUpAddErrorModal>
        </form>
      </div>
    </>
  );
}

export default AddAsset;
