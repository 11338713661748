import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import './PlannerTabs.scss';

function PlannerTabs({ setSelectedMenu }) {
  return (
    <div className="">
      <Tabs
        defaultActiveKey="default"
        id="uncontrolled-tab-example"
        className="nav nav-tabs custom_tabs"
        style={{ display: 'flex', gap: '8px' }}
        onSelect={k => setSelectedMenu(k)}
      >
        <Tab
          eventKey="default"
          title="Default"
          tabClassName="tab-item"
        >
          {' '}
        </Tab>
        <Tab
          eventKey="task_planner"
          title="Task planner"
          tabClassName="tab-item"
        >
          {' '}
        </Tab>
        <Tab
          eventKey="maintenance_planner"
          title="Maintenance planner"
          tabClassName="tab-item"
        >
          {' '}
        </Tab>
        {/* <Tab
          eventKey="license"
          title="License"
          tabClassName="tab-item license-tab"
        >
          {' '}
        </Tab>
        <Tab
          eventKey="productivity"
          title="Productivity"
          tabClassName="tab-item productivity-tab"
        >
          {' '}
        </Tab>
        <Tab
          eventKey="sustainability"
          title="Sustainability"
          tabClassName="tab-item sustainability-tab"
        >
          {' '}
        </Tab> */}
      </Tabs>
    </div>
  );
}

export default PlannerTabs;
