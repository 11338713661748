import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getTicketNumberConfigs = async () => {
  const res = await http.get(`${BASE_URL}/ticket-number-configs/`);

  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};


const updateTicketNumberConfigs = async (tckData) => {
  const res = await http.put(
    `${BASE_URL}/ticket-number-configs/${tckData.id}/${tckData.model}/`,
    tckData,
  );
  return assert(res, res.data, 'Update of Log failed', res);
};

const TicketNumberConfig = {
  getTicketNumberConfigs,
  updateTicketNumberConfigs,
};

export default TicketNumberConfig;
