import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';


const getticketinvsByAuthentication = async (id) => {
  const res = await http.get(`${BASE_URL}/ticket-inventory/?ticket_id=${id}`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};



const getticketinvById = async (ticketinvId) => {
  const res = await http.get(`${BASE_URL}/ticket-inventory/${ticketinvId}/`);

  return assert(res, res.data, 'Retrieval of ticketinv by its ID failed', res);
};

const createticketinv = async (payload) => {
  const res = await http.post(`${BASE_URL}/ticket-inventory/?ticket_id=${payload.id}`
  , payload.data);

  return assert(res, res.data, 'Creation of ticketinv failed', res);
};

const deleteticketinv = async (ids) => {
  const res = await http.delete(
    `${BASE_URL}/ticket-inventory/${ids.id}/?ticket_id=${ids.tktId}`);
  return assert(res, ids.id, 'Deletion of ticketinv failed', res);
};

const updateticketinv = async (ticketinvData) => {
  const res = await http.put(
    `${BASE_URL}/ticket-inventory/${ticketinvData.id}/?ticket_id=${ticketinvData.tktId}`,
    ticketinvData.data,
  );
  return assert(res, res.data, 'Update of ticketinv failed', res);
};

const ticketinvService = {
  getticketinvsByAuthentication,
  getticketinvById,
  createticketinv,
  deleteticketinv,
  updateticketinv,
};

export default ticketinvService;
