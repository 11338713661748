import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getAssetsMappingByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/plan/`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};

const getUnmarkedAssetById = async (id) => {
  const res = await http.get(`${BASE_URL}/unmarked-asset/?sub_location_id=${id}`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};

const createMarkedAssetById = async (assetsData) => {
  const res = await http.post(
    `${BASE_URL}/marked-asset/?asset_id=${assetsData.asset_id}`,
    assetsData,
  );
  return assert(res, res.data, 'Creation of Marked assets  failed', res);
};

const updateMarkedAssetById = async (assetsData) => {
  const res = await http.put(
    `${BASE_URL}/marked-asset/?asset_id=${assetsData.asset_id}`,
    assetsData,
  );
  return assert(res, res.data, 'Update of Marked assets  failed', res);
};

const deleteMarkedAssetById = async (assetsData) => {
  const res = await http.put(`${BASE_URL}/delete-marked-asset/`, {
    assets: assetsData,
  });
  return assert(res, res.data, 'Delete of Marked assets  failed', res);
};

const getMarkedAssetById = async (planData) => {
  const res = await http.get(
    `${BASE_URL}/marked-asset/?plan_id=${planData}`,
    planData,
  );
  return assert(res, res.data, 'Get of Marked assets  failed', res);
};

const getAllGroups = async () => {
  const res = await http.get(`${BASE_URL}/asset-group/`);
  return assert(res, res.data, 'Get of Marked assets  failed', res);
};

const createNewGroup = async (groupData) => {
  const res = await http.post(`${BASE_URL}/asset-group/`, groupData);
  return assert(res, res.data, 'Get of Marked assets  failed', res);
};

const updateGroup = async (groupData) => {
  const res = await http.put(`${BASE_URL}/asset-group/${groupData.id}/`, groupData);
  return assert(res, res.data, 'Update of Marked assets  failed', res);
};

const deleteGroup = async (groupDataiId) => {
  const res = await http.delete(`${BASE_URL}/asset-group/${groupDataiId}/`);
  return assert(res, res.data, 'Update of Marked assets  failed', res);
};

const createAssignGroup = async (assignGroupData) => {
  const res = await http.post(`${BASE_URL}/assign-group/`, assignGroupData);
  return assert(res, res.data, 'Get of Marked assets  failed', res);
};
const updateAssignGroup = async (assignGroupData) => {
  const res = await http.put(`${BASE_URL}/assign-group/`, assignGroupData);
  return assert(res, res.data, 'Get of Marked assets failed', res);
};
const AssetMappingService = {
  updateAssignGroup,
  getAssetsMappingByAuthentication,
  getUnmarkedAssetById,
  createMarkedAssetById,
  updateMarkedAssetById,
  deleteMarkedAssetById,
  getMarkedAssetById,
  getAllGroups,
  createNewGroup,
  createAssignGroup,
  updateGroup,
  deleteGroup,
};

export default AssetMappingService;
