import React from 'react'
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenSORModal, setSOR, updateBookmark } from '../../redux/features/SOR/SORSlice';
import BookmarkIcon from '../../assets/svg-icons/BookmarkIcon';

function EpqItem() {
    const { openSORModal, SOR } = useSelector(state => state.SOR);
    const dispatch = useDispatch();
    
    const close = () => {
        dispatch(setOpenSORModal(false))
    }
    const handleBookMark = ()=>{
        dispatch(setSOR({...SOR, is_bookmarked : !SOR.is_bookmarked}))
        dispatch(updateBookmark(SOR?.sor_id))
    }

    return (
        <Drawer anchor="right" open={openSORModal} onClose={close}
            PaperProps={{
                style: {
                    width: '100%',
                    maxWidth: '600px',
                    height: '100%',
                },
            }} >
            <div className="h-100 w-100"
            >
                <div className="col-lg-12 col-sm-12 pb-5" id="">
                    <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">
                        <div className="col-12 row px-2 ">
                            <div className="my-auto" style={{ display: "flex", alignItems: "center" }}>
                                <button type='button' onClick={handleBookMark} className='new-btn  mr-2'>
                                    <BookmarkIcon fill={SOR?.is_bookmarked ? "#36383C" : "none"} />
                                </button>
                                <b>{SOR?.source}
                                </b>
                            </div>
                            <div className="row ml-auto">
                                <button
                                    type="button"
                                    className="btn tertiary-btn ml-2"
                                    data-dismiss="modal"
                                    onClick={close}
                                >
                                    <div className="fas fa-times" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 col-sm-12 pt-3">
                        <label style={{ fontWeight: 500, color: "#36383c" }}>
                            Section title :
                        </label>
                        <input type="text"
                            value={SOR?.section_title}
                            disabled
                            className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                        />
                    </div>
                    <div className="col-lg-12 col-sm-12 pt-3">
                        <label style={{ fontWeight: 500, color: "#36383c" }}>
                            Description:
                        </label>
                        <textarea
                            value={SOR?.description}
                            disabled
                            className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                        />
                    </div>
                    <div className="col-lg-12 col-sm-12 pt-3">
                        <label style={{ fontWeight: 500, color: "#36383c" }}>
                            Trade:
                        </label>
                        <input type="text"
                            value={SOR?.trade}
                            disabled
                            className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                        />
                    </div>
                    <div className="col-lg-12 col-sm-12 pt-3">
                        <label style={{ fontWeight: 500, color: "#36383c" }}>
                            Rate (RM)
                        </label>
                        <input type="text"
                            value={SOR?.rate}
                            disabled
                            className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                        />
                    </div>
                    <div className="col-lg-12 col-sm-12 pt-3">
                        <label style={{ fontWeight: 500, color: "#36383c" }}>
                            Unit:
                        </label>
                        <input type="text"
                            value={SOR?.unit}
                            disabled
                            className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                        />
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default EpqItem