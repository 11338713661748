import React from 'react';

function DueOnDayDropdown({ selectedDay, handleDayChange, editPM }) {
    // Function to get the day suffix
    const getDaySuffix = (day) => {
        if (day === 1 || day === 21 || day === 31) return 'st';
        if (day === 2 || day === 22) return 'nd';
        if (day === 3 || day === 23) return 'rd';
        return 'th';
    };

    // Days array with numbers
    const days = Array.from({ length: 31 }, (_, i) => {
        const day = i + 1;
        return { label: `${day}${getDaySuffix(day)}`, value: day };
    });
    return (
        <div className='mt-3'>
            <label htmlFor="due-day" className="font-weight-normal">
                Due on day <label className="font-danger m-0">*</label>
            </label>
            <select
                disabled={editPM}
                id="due-day"
                value={selectedDay}
                onChange={handleDayChange}
                className="block w-full px-4 py-2 bg-white border border-gray-300 rounded-md"
            >
                {days.map((day) => (
                    <option key={day.value} value={day.value}>
                        {day.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DueOnDayDropdown;
