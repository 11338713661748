/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable array-callback-return */

import React, { useState } from 'react';
import moment from "moment";
import { startOfMonth, endOfMonth, startOfWeek as stWeek, endOfWeek as endWeek } from 'date-fns';
import { useSelector } from 'react-redux';

const commonTickStyle = {
  display: 'inline-block',
  width: '11px',
  height: '11px',
  marginTop: '6px',
  borderRadius: '50%',
  backgroundColor: "gray",
};

const getStatusColor = (status) => {
  switch (status) {
    case 'Open':
      return '#337DBC';
    case 'In-Progress':
      return '#e3871e';
    case 'Completed':
      return '#20BB7D';
    case 'On-Hold':
      return '#D9314A';
    default:
      return 'black';
  }
};

function DotAndTooltip({ ticket, index, selectedCalendarMode }) {
  const [isShowTooltip, setIsShowTooltip] = useState(false)
  return <div className='maintenance-chart-dot'
    onMouseEnter={() => setIsShowTooltip(true)} onMouseLeave={() => setIsShowTooltip(false)}
  >
    <span style={{ ...commonTickStyle, backgroundColor: getStatusColor(ticket?.status) }} />
    {isShowTooltip &&
      <div className={(index < 25 && selectedCalendarMode === "Month") ? 'maintenance-chart-tooltip' : "maintenance-chart-tooltip-right"}>
        <div className='maintenance-chart-tooltip-header'>
          <p>{ticket?.ticket_number || "Not generated"}</p>
          <p> <span style={{ ...commonTickStyle, marginTop: 0, marginRight: "8px", backgroundColor: getStatusColor(ticket?.status) }} />  {ticket?.status}</p>
        </div>
        <div className='maintenance-chart-tooltip-title'>{ticket?.title}</div>
        <div className='maintenance-chart-tooltip-date'>{moment.utc(ticket?.due_finish).format("DD MMM YYYY")}</div>
      </div>}
  </div>

}

export default function PMCalendarTable({ getFreQ, selectedCalendarMode }) {
  const { maintenancePlannerChartData } = useSelector((state) => state.dashboard);

  // Parse dates
  const startDate = startOfMonth(new Date());
  const endDate = endOfMonth(new Date());
  const startOfWeek = stWeek(new Date(), { weekStartsOn: 1 });
  const endOfWeek = endWeek(new Date(), { weekStartsOn: 1 });
  // const dayCount = differenceInDays(endDate, startDate) + 1;

  // Function to generate the date labels based on the start date
  const generateDateLabels = () => {
    const dates = [];
    const currentDate = selectedCalendarMode === 'Month' ? new Date(startDate) : new Date(startOfWeek);
    const end = selectedCalendarMode === 'Month' ? endDate : endOfWeek;

    while (currentDate <= end) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const tableStyle = {
    borderCollapse: 'collapse',
    marginTop: '20px',
    fontSize: '14px',
  };

  const theadStyle = {
    position: "sticky",
    top: "-1px",
    zIndex:10
  };

  const thStyle = {
    backgroundColor: '#F2F2F3',
    fontWeight: '600',
    padding: '10px',
    textAlign: 'left',
    border: '1px solid #E1E1E1',
    paddingLeft: "8px",
    position: "relative",
    zIndex: 1000000
  };

 

  const tdStyle = {
    padding: '2px 8px',
    textAlign: 'center',
    border: '1px solid #F2F2F3',
    fontSize: "12px"
  };

  const dateLabels = generateDateLabels();

  const getTicketForDate = (schedule, date) => {
    const matchingTicket = schedule?.temp_tickets.find(ticket => {
      const ticketDate = new Date(ticket?.due_finish);
      return ticketDate?.toDateString() === date?.toDateString();
    });
    return matchingTicket || null;
  };

  return (
    <table style={tableStyle}>
      <thead style={theadStyle}>
        <tr>
          <th rowSpan={2} style={{ ...thStyle, minWidth: '150px' }}>
            PM Schedule ID
          </th>
          <th rowSpan={2} style={{ ...thStyle, minWidth: '220px' }}>
            PM Schedule Title
          </th>
          <th rowSpan={2} style={{ ...thStyle, minWidth: '150px' }}>
            Asset ID
          </th>
          <th rowSpan={2} style={{ ...thStyle, minWidth: '150px' }}>
            Location
          </th>
          <th rowSpan={2} style={{ ...thStyle, minWidth: '220px' }}>
            Frequency
          </th>
          <th colSpan={dateLabels.length} style={{ ...thStyle, width: "100%", textAlign: "center" }}>
            {selectedCalendarMode === 'Month' ? startDate.toLocaleString('default', { month: 'long', year: 'numeric' }) :
              `${moment(startOfWeek).format("DD MMM")} - ${moment(endOfWeek).format("DD MMM")}`
            }
          </th>
          {/* {selectedCalendarMode === 'Month' ?
            <>
              <th colSpan={7} style={{ ...thStyle, width: "100%", textAlign: "center" }}>
                1Aug-7Aug
              </th>
              <th colSpan={7} style={{ ...thStyle, width: "100%", textAlign: "center" }}>
                1Aug-7Aug
              </th>
              <th colSpan={7} style={{ ...thStyle, width: "100%", textAlign: "center" }}>
                1Aug-7Aug
              </th>
              <th colSpan={7} style={{ ...thStyle, width: "100%", textAlign: "center" }}>
                1Aug-7Aug
              </th>
              <th colSpan={2} style={{ ...thStyle, width: "100%", textAlign: "center" }}>
                1Aug-7Aug
              </th>
            </>
            : <th colSpan={dateLabels.length} style={{ ...thStyle, width: "100%", textAlign: "center" }}>
              {moment(startOfWeek).format("DD MMM")} - {moment(endOfWeek).format("DD MMM")}
            </th>
          } */}
        </tr>
        {dateLabels.map((label, index) => (
          <th key={index} style={{  ...thStyle, textAlign: "center" }}>{label.getDate()} </th>
        ))}
      </thead>
      <tbody>
      {maintenancePlannerChartData?.map((item) => (
          <tr key={item.id}>
            <td style={{ ...tdStyle, textAlign: 'left' }}>{item.reference_number}</td>
            <td style={{ ...tdStyle, textAlign: 'left' }}>{item.title}</td>
            <td style={{ ...tdStyle, textAlign: 'left' }}>{item?.asset?.asset_number || "—" }</td>
            <td style={{ ...tdStyle, textAlign: 'left' }}>{item?.location?.site || "—"}</td>
            <td style={{ ...tdStyle, textAlign: 'left' }}>{getFreQ(item).frequencyType}, {getFreQ(item).frqSentence}</td>
            {dateLabels?.map((date, index) => {
              const ticket = getTicketForDate(item.schedule_data, date);
              return <td
                key={index}
                style={{
                  border: '1px solid #F2F2F3',
                  textAlign: 'center',
                }}
              >
                {ticket ? (
                  <DotAndTooltip ticket={ticket} index={index} selectedCalendarMode={selectedCalendarMode} />
                ) : (
                  ''
                )}
              </td>
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
