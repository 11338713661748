/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function WorkOrderSummary ({ icon, color, title, value, percentage, date, perColor, perBackColor,perIcon,border,inPDF }) {
  return (
    <div style={{ 
      width:"191.2px",
      height:"130px",
      padding: '0px 20px 0px 20px', 
      borderRight: border
    }} 
      className="border-end col-md col-sm-4 col-xs-6 mt-4">
      <div>
        <div
          style={{
            color: color,
            size: '16px',
            fontWeight: 500,
            fontFamily: 'Source Sans 3',
            display:'flex'
          }}
        >
         <div>{icon}</div>  <div style={inPDF ? {marginTop:"-10px"} : {}} className={'ms-2'}> {' '}{title}</div>
        </div>
      </div>
      <div
        style={{
          fontFamily: 'Source Sans 3',
          fontSize: '30px',
          fontWeight: 500,
          lineHeight: '45px',
          letterSpacing: '0.01em',
          textAlign: 'left',
          margin: "15px 0"
        }}
      >
        {value}
      </div>
      <div>
        <div>
          <div
            style={{
              backgroundColor: perBackColor,
              color: perColor,
              width: "fit-content",
              height: 'auto',
              padding: '5px',
              gap: '0px',
              borderRadius: '6px',
              opacity: '0px',
              display: "flex",
              margin: "15px 0",
            }}
          >
            {inPDF ? <div><FontAwesomeIcon icon={perIcon.icon} /></div> :  <FontAwesomeIcon icon={perIcon.icon} />}
            <div  style={{marginLeft:"5px", fontSize: "12px", fontFamily:"Source Sans 3", fontWeight:500 }}>{percentage}</div>
          </div>{' '}
          <span
            style={{
              fontFamily: 'Source Sans 3',
              fontSize: '10.38px',
              fontWeight: 400,
              lineHeight: '12.19px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
            
          >
          
            {date}
          </span>
        </div>
      </div>
      
    </div>
  );
}

export default WorkOrderSummary;


  /* Completed Section */


  /* <div style={{padding:'0px 20px 0px 20px'}} className='border-end col'>
            <div>
              <p
                style={{
                  color: '##76B559',
                  size: '16px',
                  fontWeight: 500,
                  fontFamily: 'Raleway',
                }}
              >
                <FontAwesomeIcon className="me-2" icon={faCircleCheck} /> <span>Completed</span>{' '}
              </p>
            </div>
            <p
              style={{
                fontFamily: 'Poppins',
                fontSize: '30px',
                fontWeight: 600,
                lineHeight: '45px',
                letterSpacing: '0.01em',
                textAlign: 'left',
              }}
            >
              28
            </p>
            <div>
              <p>
                <span
                  style={{
                    backgroundColor: '#6DA5441A',
                    color: '#6DA544',
                    width: 'Hug (49px)px',
                    height: 'Hug (22px)px',
                    padding: '2px',
                    gap: '0px',
                    borderRadius: '6px',
                    opacity: '0px',
                  }}
                >
                  <FontAwesomeIcon icon={faArrowUp} /> <span>+10%</span>
                </span>{' '}
                <span
                  style={{
                    fontFamily: 'Raleway',
                    fontSize: '10.38px',
                    fontWeight: 500,
                    lineHeight: '12.19px',
                    letterSpacing: '0.01em',
                    textAlign: 'left',
                  }}
                >
                  (25 Jan - 25 Mar)
                </span>
              </p>
            </div>
          </div> */

