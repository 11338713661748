import React from 'react';

export default function ChecklistIcon({ fillColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
      <path
        d="M1.50618 9.51515C1.39306 9.02839 1.33325 8.52118 1.33325 8C1.33325 7.47881 1.39306 6.9716 1.50618 6.48484M6.7878 1.44325C7.18086 1.37105 7.58598 1.33333 7.99992 1.33333C11.6818 1.33333 14.6666 4.3181 14.6666 8C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C7.58598 14.6667 7.18086 14.6289 6.7878 14.5567M4.66659 2.22521C3.75727 2.75122 2.98372 3.48584 2.41139 4.36363M2.41139 11.6364C2.98372 12.5141 3.75727 13.2488 4.66659 13.7748M9.99992 6.66666L7.37714 9.28944C7.35241 9.31417 7.31278 9.31559 7.28634 9.29269L5.99992 8.17812"
        stroke={fillColor === 'primary' ? 'var(--primary-color)' : 'var(--grey-content)'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
