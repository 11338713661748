import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function CheckinAssetModal({showLoanModal,handleCheckOut,handleClose , handelComment }) {
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showLoanModal} onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Check-in Asset
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Reason for check-in:</h4>
      </Modal.Body>
        <textarea onChange={handelComment} style={{margin:"12px" , padding: "10px"}} name="reason" id="reason" cols="30" rows="10"/>
      <Modal.Footer>
      <Button variant='primary' onClick={handleCheckOut}>
            Check-in
        </Button>
        <Button variant='secondary' onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

