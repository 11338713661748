import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import maintenanceCategoryService from "./maintenanceCategoryService";
import { smartTryCatch } from "../../../utils";

const initState = {
  // Maintenance Category
  maintenanceCategories: [],
  maintenanceCategory: {},

  // fields
  maintenanceCategoryName: "",

  // modals
  maintenanceCategoryModalCreate: false,
  maintenanceCategoryModalUpdate: false,
  maintenanceCategoryModalDelete: false,

  // success
  isMaintenanceCategoryCreateSuccess: false,
  isMaintenanceCategoryGetSuccess: false,
  isMaintenanceCategoryUpdateSuccess: false,
  isMaintenanceCategoryDeleteSuccess: false,

  // loading
  isMaintenanceCategoryGetLoading: false,
  isMaintenanceCategoryCreateLoading: false,
  isMaintenanceCategoryUpdateLoading: false,
  isMaintenanceCategoryDeleteLoading: false,

  // error
  isMaintenanceCategoryGetError: false,
  isMaintenanceCategoryCreateError: false,
  isMaintenanceCategoryUpdateError: false,
  isMaintenanceCategoryDeleteError: false,
};

export const createMaintenanceCategory = createAsyncThunk(
  "createMaintenanceCategory",
  async (data, thunkAPI) => {
    const create = await smartTryCatch(
      maintenanceCategoryService.createMaintenanceCategory,
      data,
      thunkAPI
    );
    return create;
  }
);

export const getMaintenanceCategoryByAuthentication = createAsyncThunk(
  "getMaintenanceCategoryByAuthentication",
  async (data, thunkAPI) => {
    const getTech = await smartTryCatch(
      maintenanceCategoryService.getMaintenanceCategoryByAuthentication,
      data,
      thunkAPI
    );
    return getTech;
  }
);

export const getMaintenanceCategoryById = createAsyncThunk(
  "getMaintenanceCategoryById",
  async (data, thunkAPI) => {
    const getTech = await smartTryCatch(
      maintenanceCategoryService.getMaintenanceCategoryById,
      data,
      thunkAPI
    );
    return getTech;
  }
);

export const deleteMaintenanceCategory = createAsyncThunk(
  "deleteMaintenanceCategory",
  async (data, thunkAPI) => {
    const deleteTech = await smartTryCatch(
      maintenanceCategoryService.deleteMaintenanceCategory,
      data,
      thunkAPI
    );
    return deleteTech;
  }
);

export const updateMaintenanceCategory = createAsyncThunk(
  "updateMaintenanceCategory",
  async (data, thunkAPI) => {
    const updateTech = await smartTryCatch(
      maintenanceCategoryService.updateMaintenanceCategory,
      data,
      thunkAPI
    );
    return updateTech; 
  }
);

export const maintenanceCategorySlice = createSlice({
  name: "maintenanceCategory",
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isMaintenanceCategoryGetLoading = false;
      state.isMaintenanceCategoryGetSuccess = false;
      state.isMaintenanceCategoryGetError = false;
      state.isMaintenanceCategoryCreateLoading = false;
      state.isMaintenanceCategoryCreateSuccess = false;
      state.isMaintenanceCategoryCreateError = false;
      state.isMaintenanceCategoryUpdateLoading = false;
      state.isMaintenanceCategoryUpdateSuccess = false;
      state.isMaintenanceCategoryUpdateError = false;
      state.isMaintenanceCategoryDeleteLoading = false;
      state.isMaintenanceCategoryDeleteSuccess = false;
      state.isMaintenanceCategoryDeleteError = false;
      state.maintenanceCategory = null;
    },
    openMaintenanceCategoryModalCreate: (state) => {
      state.maintenanceCategoryModalCreate = true;
    },
    closeMaintenanceCategoryModalCreate: (state) => {
      state.maintenanceCategoryModalCreate = false;
    },
    openMaintenanceCategoryModalUpdate: (state) => {
      state.maintenanceCategoryModalUpdate = true;
    },
    closeMaintenanceCategoryModalUpdate: (state) => {
      state.maintenanceCategoryModalUpdate = false;
    },
    openMaintenanceCategoryModalDelete: (state) => {
      state.maintenanceCategoryModalDelete = true;
    },
    closeMaintenanceCategoryModalDelete: (state) => {
      state.maintenanceCategoryModalDelete = false;
    },
    setMaintenanceCategory: (state, action) => {
      state.maintenanceCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMaintenanceCategoryByAuthentication.pending, (state) => {
        state.isMaintenanceCategoryGetLoading = true;
        state.isMaintenanceCategoryGetSuccess = false;
        state.isMaintenanceCategoryGetError = false;
      })
      .addCase(
        getMaintenanceCategoryByAuthentication.fulfilled,
        (state, action) => {
          state.isMaintenanceCategoryGetLoading = false;
          state.isMaintenanceCategoryGetSuccess = true;
          state.isMaintenanceCategoryGetError = false;
          state.maintenanceCategories = action.payload;
        }
      )
      .addCase(getMaintenanceCategoryByAuthentication.rejected, (state) => {
        state.isMaintenanceCategoryGetLoading = false;
        state.isMaintenanceCategoryGetSuccess = false;
        state.isMaintenanceCategoryGetError = true;
      })
      .addCase(getMaintenanceCategoryById.pending, (state) => {
        state.isMaintenanceCategoryGetLoading = true;
        state.isMaintenanceCategoryGetSuccess = false;
        state.isMaintenanceCategoryGetError = false;
      })
      .addCase(getMaintenanceCategoryById.fulfilled, (state, action) => {
        state.isMaintenanceCategoryGetLoading = false;
        state.isMaintenanceCategoryGetSuccess = true;
        state.isMaintenanceCategoryGetError = false;
        state.maintenanceCategories = action.payload;
      })
      .addCase(getMaintenanceCategoryById.rejected, (state) => {
        state.isMaintenanceCategoryGetLoading = false;
        state.isMaintenanceCategoryGetSuccess = false;
        state.isMaintenanceCategoryGetError = true;
      })
      .addCase(createMaintenanceCategory.pending, (state) => {
        state.isMaintenanceCategoryCreateLoading = true;
        state.isMaintenanceCategoryCreateSuccess = false;
        state.isMaintenanceCategoryCreateError = false;
      })
      .addCase(createMaintenanceCategory.fulfilled, (state, action) => {
        state.isMaintenanceCategoryCreateLoading = false;
        state.isMaintenanceCategoryCreateSuccess = true;
        state.isMaintenanceCategoryCreateError = false;
        state.maintenanceCategories.push(action.payload);
      })
      .addCase(createMaintenanceCategory.rejected, (state) => {
        state.isMaintenanceCategoryCreateLoading = false;
        state.isMaintenanceCategoryCreateSuccess = false;
        state.isMaintenanceCategoryCreateError = true;
      })
      .addCase(deleteMaintenanceCategory.pending, (state) => {
        state.isMaintenanceCategoryDeleteLoading = true;
        state.isMaintenanceCategoryDeleteSuccess = false;
        state.isMaintenanceCategoryDeleteError = false;
      })
      .addCase(deleteMaintenanceCategory.fulfilled, (state, action) => {
        state.isMaintenanceCategoryDeleteLoading = false;
        state.isMaintenanceCategoryDeleteSuccess = true;
        state.isMaintenanceCategoryDeleteError = false;
        state.maintenanceCategories = state.maintenanceCategories.filter(
          (maintenanceCategory) => maintenanceCategory.id !== action.payload
        );
      })
      .addCase(deleteMaintenanceCategory.rejected, (state) => {
        state.isMaintenanceCategoryDeleteLoading = false;
        state.isMaintenanceCategoryDeleteSuccess = false;
        state.isMaintenanceCategoryDeleteError = true;
      })
      .addCase(updateMaintenanceCategory.pending, (state) => {
        state.isMaintenanceCategoryUpdateLoading = true;
        state.isMaintenanceCategoryUpdateSuccess = false;
        state.isMaintenanceCategoryUpdateError = false;
      })
      .addCase(updateMaintenanceCategory.fulfilled, (state, action) => {
        state.isMaintenanceCategoryUpdateLoading = false;
        state.isMaintenanceCategoryUpdateSuccess = true;
        state.isMaintenanceCategoryUpdateError = false;
        state.maintenanceCategories = state.maintenanceCategories.map(
          (maintenanceCategory) =>
            maintenanceCategory.id === action.payload.id
              ? action.payload
              : maintenanceCategory
        );
      })
      .addCase(updateMaintenanceCategory.rejected, (state) => {
        state.isMaintenanceCategoryUpdateLoading = false;
        state.isMaintenanceCategoryUpdateSuccess = false;
        state.isMaintenanceCategoryUpdateError = true;
      });
  },
});

export const {
  reset,
  openMaintenanceCategoryModalCreate,
  closeMaintenanceCategoryModalCreate,
  openMaintenanceCategoryModalUpdate,
  closeMaintenanceCategoryModalUpdate,
  openMaintenanceCategoryModalDelete,
  closeMaintenanceCategoryModalDelete,
  setMaintenanceCategory,
} = maintenanceCategorySlice.actions;

export default maintenanceCategorySlice.reducer;
