import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ticketinvService  from './ticketInventoryService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // Ticket Log
  ticketinvs :[],
  ticketinv : {},
  summary :{},


  // success states
  isTicketinvGetSuccess: false,
  isTicketinvCreateSuccess: false,
  isTicketinvDeleteSuccess: false,
  isTicketinvUpdateSuccess: false,
 

  // loading states
  isTicketinvCreateLoading: false,
  isTicketinvGetLoading: false,
  isTicketinvDeleteLoading: false,
  isTicketinvUpdateLoading: false,


  // error states
  isTicketinvGetError: false,
  isTicketinvCreateError: false,
  isTicketinvDeleteError: false,
  isTicketinvUpdateError: false,


};

export const createTicketinv = createAsyncThunk('createTicketinv', async (data, thunkAPI) => {
  const createTicketinvRes = await smartTryCatch(ticketinvService.createticketinv, data, thunkAPI);
  return createTicketinvRes;
});

export const getTicketinvsByAuthentication = createAsyncThunk(
  'getTicketinvsByAuthentication',
  async (data, thunkAPI) => {
    const getTicketinvs = await smartTryCatch(
      ticketinvService.getticketinvsByAuthentication,
      data,
      thunkAPI,
    );
    return getTicketinvs;
  },
);

export const getTicketinvById = createAsyncThunk('getTicketinvById', async (data, thunkAPI) => {
  const getTicketinv = await smartTryCatch(ticketinvService.getticketinvById, data, thunkAPI);
  return getTicketinv;
});


export const deleteTicketinv = createAsyncThunk('deleteTicketinv', async (data, thunkAPI) => {
  const deleteTicketinvRes = await smartTryCatch(ticketinvService.deleteticketinv, data, thunkAPI);
  return deleteTicketinvRes;
});

export const updateTicketinv = createAsyncThunk('updateTicketinv', async (data, thunkAPI) => {
  const updateTicketinvRes = await smartTryCatch(ticketinvService.updateticketinv, data, thunkAPI);
  return updateTicketinvRes;
});


export const ticketinvSlice = createSlice({
  name: 'ticketinv',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isTicketinvCreateError = false;
      state.isTicketinvCreateSuccess = false;
      state.isTicketinvCreateLoading = false;
      state.isTicketinvGetError = false;
      state.isTicketinvGetSuccess = false;
      state.isTicketinvGetLoading = false;
      state.isTicketinvDeleteError = false;
      state.isTicketinvDeleteSuccess = false;
      state.isTicketinvDeleteLoading = false;
      state.isTicketinvUpdateError = false;
      state.isTicketinvUpdateSuccess = false;
      state.isTicketinvUpdateLoading = false;
      // state.ticketinv = null;
    },
   
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTicketinv.pending, (state) => {
        state.isTicketinvCreateLoading = true;
        state.isTicketinvCreateSuccess = false;
        state.isTicketinvCreateError = false;
      })
      .addCase(createTicketinv.fulfilled, (state, action) => {
        state.isTicketinvCreateLoading = false;
        state.isTicketinvCreateSuccess = true;
        state.isTicketinvCreateError = false;
        state.ticketinvs.push(...action.payload);
      })
      .addCase(createTicketinv.rejected, (state) => {
        state.isTicketinvCreateLoading = false;
        state.isTicketinvCreateSuccess = false;
        state.isTicketinvCreateError = true;
      })
      .addCase(getTicketinvsByAuthentication.pending, (state) => {
        state.isTicketinvGetLoading = true;
        state.isTicketinvGetSuccess = false;
        state.isTicketinvGetError = false;
      })
      .addCase(getTicketinvsByAuthentication.fulfilled, (state, action) => {
        state.isTicketinvGetLoading = false;
        state.isTicketinvGetSuccess = true;
        state.isTicketinvGetError = false;
        state.ticketinvs = action.payload;
      })
      .addCase(getTicketinvsByAuthentication.rejected, (state) => {
        state.isTicketinvGetLoading = false;
        state.isTicketinvGetSuccess = false;
        state.isTicketinvGetError = true;
      })
      .addCase(getTicketinvById.pending, (state) => {
        state.isTicketinvGetLoading = true;
        state.isTicketinvGetSuccess = false;
        state.isTicketinvGetError = false;
      })
      .addCase(getTicketinvById.fulfilled, (state, action) => {
        state.isTicketinvGetLoading = false;
        state.isTicketinvGetSuccess = true;
        state.isTicketinvGetError = false;
        state.ticketinv = action.payload;
      })
      .addCase(getTicketinvById.rejected, (state) => {
        state.isTicketinvGetLoading = false;
        state.isTicketinvGetSuccess = false;
        state.isTicketinvGetError = true;
      })
      .addCase(deleteTicketinv.pending, (state) => {
        state.isTicketinvDeleteLoading = true;
        state.isTicketinvDeleteSuccess = false;
        state.isTicketinvDeleteError = false;
      })
      .addCase(deleteTicketinv.fulfilled, (state, action) => {
        state.isTicketinvDeleteLoading = false;
        state.isTicketinvDeleteSuccess = true;
        state.isTicketinvDeleteError = false;
        state.ticketinvs = 
        state.ticketinvs.filter((Ticketinv) => Ticketinv.id !== action.payload);
      })
      .addCase(deleteTicketinv.rejected, (state) => {
        state.isTicketinvDeleteLoading = false;
        state.isTicketinvDeleteSuccess = false;
        state.isTicketinvDeleteError = true;
      })
      .addCase(updateTicketinv.pending, (state) => {
        state.isTicketinvUpdateLoading = true;
        state.isTicketinvUpdateSuccess = false;
        state.isTicketinvUpdateError = false;
      })
      .addCase(updateTicketinv.fulfilled, (state, action) => {
        state.isTicketinvUpdateLoading = false;
        state.isTicketinvUpdateSuccess = true;
        state.isTicketinvUpdateError = false;
        state.ticketinvs = state.ticketinvs?.map((Ticketinv) =>
        Ticketinv.id === action.payload.id ? action.payload : Ticketinv,
        );
      })
      .addCase(updateTicketinv.rejected, (state) => {
        state.isTicketinvUpdateLoading = false;
        state.isTicketinvUpdateSuccess = false;
        state.isTicketinvUpdateError = true;
      })
  
},
});

export const {
  reset,
} = ticketinvSlice.actions;

export default ticketinvSlice.reducer;
