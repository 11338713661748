/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { setINVFilterCategory, setINVFilterSite, setINVFilterSublocation } from '../../redux/features/inventory/inventorySlice';
import { SelectLocationFilter, SelectSublocationFilter, SelectTypesAndCategories } from '../../components/select/select';

const INVFilter = () => {
    const dispatch = useDispatch();
    const [locationsData, setLocations] = useState([]);
    const { locations } = useSelector((state) => state.location);
    const { inventories } = useSelector((state) => state.inventoryCategory);
    const sublocations = useSelector((state) => state.location?.sublocations?.results);
    const 
    {
    filterCategory, 
    filterSite,
    filterSublocation
  } = useSelector(state => state.inventory)
  


  const resetState = ()=>{
    dispatch(setINVFilterSite(null));
    dispatch(setINVFilterSublocation(null));
    dispatch(setINVFilterCategory(null));
  }

  const handleEraserFilterClick = () => {
    setLocations([]);
    // reset the state and store
    resetState()
  };

  useEffect(() => {
    setLocations(sublocations || [])
  }, [sublocations])

  return (
    <div className="filters-wrapper-inv">
      <div className="filter-wrapper-inv">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Location </label>
        <SelectLocationFilter locations={locations} site={filterSite} setFilterSite={setINVFilterSite} 
        setFilterSublocation={setINVFilterSublocation} setLocations={setLocations} />
      </div>
      <div className="filter-wrapper-inv">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Sublocation</label>
        <SelectSublocationFilter  sublocationsData={locationsData} sublocation={filterSublocation} setFilterSublocation={setINVFilterSublocation} site={filterSite}/>
      </div>
      <div className="filter-wrapper-inv">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Category</label>
        <SelectTypesAndCategories dataArray={inventories} stateValue={filterCategory}  setState = {setINVFilterCategory}/>
      </div>
      <div className="erase-wrapper-inv">
        <div className="erase-btn">
          <Button onClick={handleEraserFilterClick} title="Erase Filter" variant="outline-danger">
            <FontAwesomeIcon style={{ padding: '2px' }} icon={faEraser} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default INVFilter;
