/* eslint-disable class-methods-use-this */
import axios from 'axios';

class EasyAxios {
  getUserInAuth = () =>
    localStorage.getItem('userToken') !== undefined
      ? JSON.parse(localStorage.getItem('userToken'))
      : null;

  getHeader = () => {
    const userInAuth = this.getUserInAuth();
    return {
      'Content-type': 'application/json',
      authorization: userInAuth ? `JWT ${userInAuth.access}` : null,
    };
  };

  getHeaderImg = () => {
    const userInAuth = this.getUserInAuth();
    return {
      'Content-Type': `multipart/form-data`,
      authorization: userInAuth ? `JWT ${userInAuth.access}` : null,
    };
  };

  // make a http get request
  async get (url) {
    const get = await axios.get(url, {
      headers: this.getHeader(),
    });
    return get;
  }

  // make a http post request
  async post (url, data) {
    const post = await axios.post(url, data, { headers: this.getHeader() });
    return post;
  }

  async postImg (url, data) {
    const post = await axios.post(url, data, { headers: this.getHeaderImg() });
    return post;
  }

  // Make an http PUT Request
  async put (url, data) {
    const put = await axios.put(url, data, { headers: this.getHeader() });
    return put;
  }
  
  // Make an http PUT Request
  async patch (url, data) {
    const patch = await axios.patch(url, data, { headers: this.getHeader() });
    return patch;
  }

  async putImg (url, data) {
    const put = await axios.put(url, data, { headers: this.getHeaderImg() });
    return put;
  }

  // delete request
  async delete (url) {
    const deleteReq = await axios.delete(url, { headers: this.getHeader() });
    return deleteReq;
  }
  
  async deleteBulk (url, data) {
    const deleteReq = await axios.delete(url,  { 
      headers: this.getHeader(), 
      data
    });
    return deleteReq;
  }
}

const http = new EasyAxios();
export default http;
