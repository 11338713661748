import React from 'react'
import {  useSelector } from 'react-redux'

function WorkDataPdf() {
    const { billWorkData } = useSelector(state => state.SOR)
    return (
        <section className='work-section-pdf'>
            <div className='work-content-epq'>
                <div className='work-title-epq'>WORK ORDER (CMMS)</div>
                <div className='work-data-epq'>{billWorkData?.order}</div>
            </div>
            <div className='work-content-epq'>
                <div className='work-title-epq'>JOB NO</div>
                <div className='work-data-epq'>{billWorkData?.jobNum}</div>
            </div>
            <div className='work-content-epq'>
                <div className='work-title-epq'>LOCATION</div>
                <div className='work-data-epq'>{billWorkData?.location}</div>
            </div>
            <div className='work-content-epq'>
                <div className='work-title-epq'>OUTLINE AGREEMENT NO</div>
                <div className='work-data-epq'>{billWorkData?.agreementNo}</div>
            </div>
            <div className='work-content-epq'>
                <div className='work-title-epq'>WORK ORDER TYPE</div>
                <div className='work-data-epq'>{billWorkData?.orderType}</div>
            </div>
        </section>
    )
}

export default WorkDataPdf