import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getComapnyProfile = async () => {
  const res = await http.get(`${BASE_URL}/company-profile/`);
  return assert(res, res.data, 'Retrieval of Company  Profile failed', res);
};

const updateCompanyProfile = async companyProfileData => {
  const res = await http.put(`${BASE_URL}/company-profile/`, companyProfileData);
  return assert(res, res.data, 'Retrieval of Company  Profile failed', res);
};

const updateCompanyProfileImage = async companyProfileData => {
  const res = await http.putImg(`${BASE_URL}/company-profile-image/`, companyProfileData);
  return assert(res, res.data, 'Retrieval of Company  Profile failed', res);
};

const profileService = {
  getComapnyProfile,
  updateCompanyProfile,
  updateCompanyProfileImage,
};

export default profileService;
