import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getManagerPositionByAuthentication = async () => {
  const res = await http.get(
    `${BASE_URL}/manager-position/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of managerPosition by its company ID failed",
    res
  );
};

const getManagerPositionById = async (managerPositionId) => {
  const res = await http.get(
    `${BASE_URL}/manager-position/${managerPositionId}`
  );

  return assert(
    res,
    res.data,
    "Retrieval of managerPosition by its ID failed",
    res
  );
};

const createManagerPosition = async (data) => {
  const res = await http.post(
    `${BASE_URL}/manager-position/`,
    data
  );

  return assert(res, res.data, "Creation of managerPosition failed", res);
};

const updateManagerPosition = async (data) => {
  const res = await http.put(
    `${BASE_URL}/manager-position/${data.id}/`,
    data
  );
  return assert(res, res.data, "Update of managerPosition failed", res);
};

const deleteManagerPosition = async (managerPositionId) => {
  const res = await http.delete(
    `${BASE_URL}/manager-position/${managerPositionId}/`
  );
  return assert(res, managerPositionId, "Deletion of managerPosition failed", res);
};

const managerPositionService = {
  getManagerPositionByAuthentication,
  getManagerPositionById,
  createManagerPosition,
  updateManagerPosition,
  deleteManagerPosition,
};

export default managerPositionService;
