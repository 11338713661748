import React from 'react';

export default function IconVerticalBox() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.66797 6.33317H16.3346M9.0013 6.33317V16.3332M7.0013 16.3332H11.0013C12.8681 16.3332 13.8016 16.3332 14.5146 15.9699C15.1418 15.6503 15.6517 15.1403 15.9713 14.5131C16.3346 13.8001 16.3346 12.8667 16.3346 10.9998V6.99984C16.3346 5.133 16.3346 4.19957 15.9713 3.48654C15.6517 2.85933 15.1418 2.34939 14.5146 2.02982C13.8016 1.6665 12.8681 1.6665 11.0013 1.6665H7.0013C5.13446 1.6665 4.20104 1.6665 3.488 2.02982C2.86079 2.34939 2.35086 2.85933 2.03128 3.48654C1.66797 4.19957 1.66797 5.133 1.66797 6.99984V10.9998C1.66797 12.8667 1.66797 13.8001 2.03128 14.5131C2.35086 15.1403 2.86079 15.6503 3.488 15.9699C4.20104 16.3332 5.13446 16.3332 7.0013 16.3332Z"
        stroke="#36383C"
        strokeWidth="1.75"
        strokeLinecap="round" />
    </svg>

  );
} 
