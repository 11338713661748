import React from 'react'

/* eslint-disable no-unneeded-ternary */

function ArrowRightIcon({color}) {
    return (
        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.66797 1L5.19656 4.5286C5.45691 4.78894 5.45691 5.21106 5.19656 5.4714L1.66797 9" 
            stroke={color ? color : "#36383C"} 
            strokeWidth="1.75" 
            strokeLinecap="round" />
        </svg>
    )
}

export default ArrowRightIcon