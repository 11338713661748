import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import departmentService from "./departmentService";
import { smartTryCatch } from "../../../utils";

const initState = {
  // data
  departments: [],
  department: {},

  // fields
  departmentName: "",

  // modals
  departmentModalDelete: false,
  departmentModalUpdate: false,
  departmentModalCreate: false,

  // success
  isDepartmentCreateSuccess: false,
  isDepartmentGetSuccess: false,
  isDepartmentDeleteSuccess: false,
  isDepartmentUpdateSuccess: false,

  // loading
  isDepartmentCreateLoading: false,
  isDepartmentGetLoading: false,
  isDepartmentUpdateLoading: false,
  isDepartmentDeleteLoading: false,

  // error
  isDepartmentCreateError: false,
  isDepartmentGetError: false,
  isDepartmentDeleteError: false,
  isDepartmentUpdateError: false,
};

export const createDepartment = createAsyncThunk(
  "createDepartment",
  async (data, thunkAPI) => {
    const create = await smartTryCatch(
      departmentService.createDepartment,
      data,
      thunkAPI
    );
    return create;
  }
);

export const getDepartmentsByAuthentication = createAsyncThunk(
  "getDepartmentsByAuthentication",
  async (data, thunkAPI) => {
    const getTech = await smartTryCatch(
      departmentService.getDepartmentsByAuthentication,
      data,
      thunkAPI
    );
    return getTech;
  }
);

export const getDepartmentById = createAsyncThunk(
  "getDepartmentById",
  async (data, thunkAPI) => {
    const getTech = await smartTryCatch(
      departmentService.getDepartmentById,
      data,
      thunkAPI
    );
    return getTech;
  }
);

export const deleteDepartment = createAsyncThunk(
  "deleteDepartment",
  async (data, thunkAPI) => {
    const deleteTech = await smartTryCatch(
      departmentService.deleteDepartment,
      data,
      thunkAPI
    );
    return deleteTech;
  }
);

export const updateDepartment = createAsyncThunk(
  "updateDepartment",
  async (data, thunkAPI) => {
    const updateTech = await smartTryCatch(
      departmentService.updateDepartment,
      data,
      thunkAPI
    );
    return updateTech;
  }
);

export const departmentSlice = createSlice({
  name: "department",
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isDepartmentCreateError = false;
      state.isDepartmentCreateSuccess = false;
      state.isDepartmentCreateLoading = false;
      state.isDepartmentGetError = false;
      state.isDepartmentGetSuccess = false;
      state.isDepartmentGetLoading = false;
      state.isDepartmentDeleteError = false;
      state.isDepartmentDeleteSuccess = false;
      state.isDepartmentDeleteLoading = false;
      state.isDepartmentUpdateError = false;
      state.isDepartmentUpdateSuccess = false;
      state.isDepartmentUpdateLoading = false;
      state.departmentModalCreate = false;
      state.departmentModalDelete = false;
      state.departmentModalUpdate = false;
     
    },
    openDepartmentModalCreate: (state) => {
      state.departmentModalCreate = true;
    },
    closeDepartmentModalCreate: (state) => {
      state.departmentModalCreate = false;
    },
    openDepartmentModalDelete: (state) => {
      state.departmentModalDelete = true;
    },
    closeDepartmentModalDelete: (state) => {
      state.departmentModalDelete = false;
    },
    openDepartmentModalUpdate: (state) => {
      state.departmentModalUpdate = true;
    },
    closeDepartmentModalUpdate: (state) => {
      state.departmentModalUpdate = false;
    },
    setDepartment: (state, action) => {
      state.department = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDepartmentsByAuthentication.pending, (state) => {
        state.isDepartmentGetLoading = true;
      })
      .addCase(getDepartmentsByAuthentication.fulfilled, (state, action) => {
        state.isDepartmentGetLoading = false;
        state.isDepartmentGetSuccess = true;
        state.isDepartmentGetError = false;
        state.departments = action.payload;
      })
      .addCase(getDepartmentsByAuthentication.rejected, (state) => {
        state.isDepartmentGetLoading = false;
        state.isDepartmentGetError = true;
      })
      .addCase(getDepartmentById.pending, (state) => {
        state.isDepartmentGetLoading = true;
        state.isDepartmentGetSuccess = false;
        state.isDepartmentGetError = false;
      })
      .addCase(getDepartmentById.fulfilled, (state, action) => {
        state.isDepartmentGetLoading = false;
        state.isDepartmentGetSuccess = true;
        state.isDepartmentGetError = false;
        state.departments = action.payload;
      })
      .addCase(getDepartmentById.rejected, (state) => {
        state.isDepartmentGetLoading = false;
        state.isDepartmentGetSuccess = false;
        state.isDepartmentGetError = true;
      })
      .addCase(createDepartment.pending, (state) => {
        state.isDepartmentCreateLoading = true;
        state.isDepartmentCreateSuccess = false;
        state.isDepartmentCreateError = false;
      })
      .addCase(createDepartment.fulfilled, (state, action) => {
        state.isDepartmentCreateLoading = false;
        state.isDepartmentCreateSuccess = true;
        state.isDepartmentCreateError = false;
        state.departments.push(action.payload);
      })
      .addCase(createDepartment.rejected, (state) => {
        state.isDepartmentCreateLoading = false;
        state.isDepartmentCreateSuccess = false;
        state.isDepartmentCreateError = true;
      })
      .addCase(deleteDepartment.pending, (state) => {
        state.isDepartmentDeleteLoading = true;
        state.isDepartmentDeleteSuccess = false;
        state.isDepartmentDeleteError = false;
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        state.isDepartmentDeleteLoading = false;
        state.isDepartmentDeleteSuccess = true;
        state.isDepartmentDeleteError = false;
        state.departments = state.departments.filter(
          (department) => department.id !== action.payload
        );
      })
      .addCase(deleteDepartment.rejected, (state) => {
        state.isDepartmentDeleteLoading = false;
        state.isDepartmentDeleteSuccess = false;
        state.isDepartmentDeleteError = true;
        state.showDepartmentModalDelete = false;
      })
      .addCase(updateDepartment.pending, (state) => {
        state.isDepartmentUpdateLoading = true;
        state.isDepartmentUpdateSuccess = false;
        state.isDepartmentUpdateError = false;
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        state.isDepartmentUpdateLoading = false;
        state.isDepartmentUpdateSuccess = true;
        state.isDepartmentUpdateError = false; 
        state.departments = state.departments.map((department) =>
          department.id === action.payload.id ? action.payload : department
        );
      })
      .addCase(updateDepartment.rejected, (state) => {
        state.isDepartmentUpdateLoading = false;
        state.isDepartmentUpdateSuccess = false;
        state.isDepartmentUpdateError = true;
      });
  },
});

export const {
  reset,
  closeDepartmentModalCreate,
  closeDepartmentModalDelete,
  closeDepartmentModalUpdate,
  setDepartment,
  openDepartmentModalCreate,
  openDepartmentModalDelete,
  openDepartmentModalUpdate,
} = departmentSlice.actions;

export default departmentSlice.reducer;
