import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  getMaintenanceCategoryByAuthentication,
  closeMaintenanceCategoryModalCreate,
  closeMaintenanceCategoryModalDelete,
  closeMaintenanceCategoryModalUpdate,
  createMaintenanceCategory,
  deleteMaintenanceCategory,
  openMaintenanceCategoryModalCreate,
  openMaintenanceCategoryModalDelete,
  openMaintenanceCategoryModalUpdate,
  setMaintenanceCategory,
  updateMaintenanceCategory,
  reset as resetMaintenanceCategory,
} from '../../../redux/features/maintenanceCategory/maintenanceCategorySlice';
import {
  getMaintenanceTypeByAuthentication,
  closeMaintenanceTypeModalCreate,
  closeMaintenanceTypeModalDelete,
  closeMaintenanceTypeModalUpdate,
  createMaintenanceType,
  deleteMaintenanceType,
  openMaintenanceTypeModalCreate,
  openMaintenanceTypeModalDelete,
  openMaintenanceTypeModalUpdate,
  setMaintenanceType,
  updateMaintenanceType,
  reset as resetMaintenanceType,
} from '../../../redux/features/maintenanceType/maintenanceTypeSlice';

import PopUpDeleteModal from '../../popUpModals/editCategory/popUpDeleteModal';
import PopUpEditModal from '../../popUpModals/editCategory/popUpEditModal';
import PopUpAddModal from '../../popUpModals/editCategory/popUpAddModal';
import { setCategoryType } from '../../../redux/features/editCategory/editCategorySlice';


export default function MaintenanceTab () {
  const {
    maintenanceCategories,
    maintenanceCategoryModalCreate,
    maintenanceCategoryModalDelete,
    maintenanceCategoryModalUpdate,
    maintenanceCategory,
    isMaintenanceCategoryCreateLoading,
    isMaintenanceCategoryCreateSuccess,
    isMaintenanceCategoryUpdateLoading,
    isMaintenanceCategoryUpdateSuccess,
    isMaintenanceCategoryDeleteLoading,
    isMaintenanceCategoryDeleteSuccess,
  } = useSelector(state => state.maintenanceCategory);
  const {
    maintenanceTypes,
    maintenanceTypeModalCreate,
    maintenanceTypeModalDelete,
    maintenanceTypeModalUpdate,
    maintenanceType,
    isMaintenanceTypeCreateLoading,
    isMaintenanceTypeCreateSuccess,
    isMaintenanceTypeUpdateLoading,
    isMaintenanceTypeUpdateSuccess,
    isMaintenanceTypeDeleteLoading,
    isMaintenanceTypeDeleteSuccess,
  } = useSelector(state => state.maintenanceType);

  const { categoryType } = useSelector(state => state.editCategory);
  const dispatch = useDispatch();
  const [maintenanceCategoryData, setMaintenanceCategoryData] = useState(null);
  const [maintenanceTypeData, setMaintenanceTypeData] = useState(null);

  const handleConfirm = (id, type) => {
    if (type === 'maintenanceCategory') {
      dispatch(openMaintenanceCategoryModalDelete());
      dispatch(setMaintenanceCategory(id));
    }else if (type === 'maintenance-type') {
      dispatch(openMaintenanceTypeModalDelete());
      dispatch(setMaintenanceType(id));
    }
  };
  const handleDelete = () => {
    if (categoryType === 'maintenanceCategory') {
      dispatch(deleteMaintenanceCategory(maintenanceCategory));
      dispatch(closeMaintenanceCategoryModalDelete());
    } else if (categoryType === 'maintenance-type') {
      dispatch(deleteMaintenanceType(maintenanceType));
      dispatch(closeMaintenanceTypeModalDelete());
    }
  };
  const handleEdit = (data, type) => {
    if (type === 'maintenanceCategory') {
      dispatch(openMaintenanceCategoryModalUpdate());
      dispatch(setMaintenanceCategory(data.id));
      setMaintenanceCategoryData(data);
    }
    if (type === 'maintenance-type') {
      dispatch(openMaintenanceTypeModalUpdate());
      dispatch(setMaintenanceType(data.id));
      setMaintenanceTypeData(data);
    }
  };

  const handleEditSave = () => {
    if (categoryType === 'maintenanceCategory') {
      dispatch(
        updateMaintenanceCategory({
          id: maintenanceCategory,
          name: maintenanceCategoryData.name,
        }),
      );
      dispatch(closeMaintenanceCategoryModalUpdate());
    }
    if (categoryType === 'maintenance-type') {
      dispatch(
        updateMaintenanceType({
          id: maintenanceType,
          name: maintenanceTypeData.name,
        }),
      );
      dispatch(closeMaintenanceTypeModalUpdate());
    }
  };

  const handleCreate = type => {
  if (type === 'maintenanceCategory') {
      dispatch(openMaintenanceCategoryModalCreate());
    } else if (type === 'maintenance-type') {
      dispatch(openMaintenanceTypeModalCreate());
    }
  };

  const handleCreateSave = () => {
    if (categoryType === 'maintenanceCategory') {
      dispatch(
        createMaintenanceCategory({
          name: maintenanceCategoryData.name,
        }),
      );
      dispatch(closeMaintenanceCategoryModalCreate());
      dispatch(resetMaintenanceCategory());
    }
    if (categoryType === 'maintenance-type') {
      dispatch(
        createMaintenanceType({
          name: maintenanceTypeData.name,
        }),
      );
      dispatch(closeMaintenanceTypeModalCreate());
      dispatch(resetMaintenanceType());
    }
  };

  useEffect(() => {
    dispatch(getMaintenanceCategoryByAuthentication());
    dispatch(getMaintenanceTypeByAuthentication());
    dispatch(resetMaintenanceCategory());
    dispatch(resetMaintenanceType());
  }, [dispatch]);

  function displayToast () {
    if (isMaintenanceCategoryCreateSuccess) {
      toast.success('Maintenance Category Created Successfully', {
        toastId: 'maintenanceCategoryCreateSuccess',
      });
      dispatch(resetMaintenanceCategory());
    }
    if (isMaintenanceCategoryUpdateSuccess) {
      toast.success('Maintenance Category Updated Successfully', {
        toastId: 'maintenanceCategoryUpdateSuccess',
      });
      dispatch(resetMaintenanceCategory());
    }
    if (isMaintenanceCategoryDeleteSuccess) {
      toast.success('Maintenance Category Deleted Successfully', {
        toastId: 'maintenanceCategoryDeleteSuccess',
      });
      dispatch(resetMaintenanceCategory());
    }
    if (isMaintenanceTypeCreateSuccess) {
      toast.success('Maintenance Sub Category Created Successfully', {
        toastId: 'maintenanceTypeCreateSuccess',
      });
      dispatch(resetMaintenanceType());
    }
    if (isMaintenanceTypeUpdateSuccess) {
      toast.success('Maintenance Sub Category Updated Successfully', {
        toastId: 'maintenanceTypeUpdateSuccess',
      });
      dispatch(resetMaintenanceType());
    }
    if (isMaintenanceTypeDeleteSuccess) {
      toast.success('Maintenance Sub Category Deleted Successfully', {
        toastId: 'maintenanceTypeDeleteSuccess',
      });
      dispatch(resetMaintenanceType());
    }
  }
  return isMaintenanceCategoryCreateLoading ||
    isMaintenanceCategoryDeleteLoading ||
    isMaintenanceCategoryUpdateLoading ||
    isMaintenanceTypeCreateLoading ||
    isMaintenanceTypeDeleteLoading ||
    isMaintenanceTypeUpdateLoading ? (
    <div id="loader" />
  ) : (
    <>
      {displayToast()}
      {maintenanceCategoryModalCreate && (
        <PopUpAddModal
          showModal={maintenanceCategoryModalCreate}
          handleCreateSave={handleCreateSave}
          handleClose={() => dispatch(closeMaintenanceCategoryModalCreate())}
          modalTitle="Add maintenance Category"
          primaryButtonText="Create Maintenance Category"
          value={maintenanceCategoryData && maintenanceCategoryData.name}
          onChange={e => {
            setMaintenanceCategoryData({
              ...maintenanceCategoryData,
              name: e.target.value,
            });
          }}
        >
          <div className="col">
            <div className="row">
              <input type="hidden" id="inventory_id" value="" />
              <span className="font-weight-normal">
                Name <span className="font-danger m-0">*</span>
              </span>
              <input
                name="inventory_name"
                type="text"
                className="form-control mb-2"
                placeholder="Enter category name"
                // value={maintenanceCategoryData?.name}
                onChange={e =>
                  setMaintenanceCategoryData({
                    ...maintenanceCategoryData,
                    name: e.target.value,
                  })
                }
              />

              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_name_error" />
              </div>
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_error_msg" />
              </div>
            </div>
          </div>
        </PopUpAddModal>
      )}
      {maintenanceCategoryModalUpdate && (
        <PopUpEditModal
          handleClose={() => dispatch(closeMaintenanceCategoryModalUpdate())}
          key={maintenanceCategoryData?.id}
          showModal={maintenanceCategoryModalUpdate}
          handleSave={handleEditSave}
          modalTitle="Edit maintenance category"
          primaryButtonText="Save Changes"
          modalBodyTextInput={maintenanceCategoryData.name}
          value={maintenanceCategoryData && maintenanceCategoryData.name}
          onChange={e => {
            setMaintenanceCategoryData({
              ...maintenanceCategoryData,
              name: e.target.value,
            });
          }}
        />
      )}
      {maintenanceCategoryModalDelete && (
        <PopUpDeleteModal
          showModal={maintenanceCategoryModalDelete}
          handleClose={() => dispatch(closeMaintenanceCategoryModalDelete())}
          handleConfirm={handleDelete}
          modalTitle="Delete maintenance category"
          modalBodyText="Are you sure you want to delete this maintenance category?"
        />
      )}
      {maintenanceTypeModalCreate && (
        <PopUpAddModal
          showModal={maintenanceTypeModalCreate}
          handleCreateSave={handleCreateSave}
          handleClose={() => dispatch(closeMaintenanceTypeModalCreate())}
          modalTitle="Add maintenance sub category"
          primaryButtonText="Create Maintenance sub category"
          value={maintenanceTypeData && maintenanceTypeData.name}
          onChange={e => {
            setMaintenanceTypeData({
              ...maintenanceTypeData,
              name: e.target.value,
            });
          }}
        >
          <div className="col">
            <div className="row">
              <input type="hidden" id="inventory_id" value="" />
              <span className="font-weight-normal">
                Name <span className="font-danger m-0">*</span>
              </span>
              <input
                name="inventory_name"
                type="text"
                className="form-control mb-2"
                placeholder="Enter sub category name"
                // value={maintenanceTypeData?.name}
                onChange={e =>
                  setMaintenanceTypeData({
                    ...maintenanceTypeData,
                    name: e.target.value,
                  })
                }
              />

              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_name_error" />
              </div>
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_error_msg" />
              </div>
            </div>
          </div>
        </PopUpAddModal>
      )}
      {maintenanceTypeModalUpdate && (
        <PopUpEditModal
          handleClose={() => dispatch(closeMaintenanceTypeModalUpdate())}
          key={maintenanceTypeData?.id}
          showModal={maintenanceTypeModalUpdate}
          handleSave={handleEditSave}
          modalTitle="Edit maintenance sub category"
          primaryButtonText="Save Changes"
          modalBodyTextInput={maintenanceTypeData.name}
          value={maintenanceTypeData && maintenanceTypeData.name}
          onChange={e => {
            setMaintenanceTypeData({
              ...maintenanceTypeData,
              name: e.target.value,
            });
          }}
        />
      )}
      {maintenanceTypeModalDelete && (
        <PopUpDeleteModal
          showModal={maintenanceTypeModalDelete}
          handleClose={() => dispatch(closeMaintenanceTypeModalDelete())}
          handleConfirm={handleDelete}
          modalTitle="Delete maintenance sub category"
          modalBodyText="Are you sure you want to delete this maintenance sub category?"
        />
      )}
      <div className="card-body p-0 m-0">
        <div className="row">
          <h3 className="card-title py-3 px-3 col-lg-12">Maintenance Fieldset</h3>
          <div className="col-lg-6 col-sm-12">
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title">
                  <span>
                    <b>Maintenance Categories</b>
                    <i>(Option select)</i>
                  </span>
                </span>
                <div className="card-tools">
                  <button
                    className="mr-2 cursor_pointer"
                    title="Add type"
                    data-target="#modal-add-maintenance-category"
                    data-toggle="modal"
                    onClick={() => {
                      dispatch(setCategoryType('maintenanceCategory'));
                      handleCreate('maintenanceCategory');
                    }}
                    type="button"
                  >
                    Add Category
                  </button>
                </div>
              </div>
              <div className="card-body p-0" id="maintenance-category-block">
                {maintenanceCategories && maintenanceCategories.length > 0 ? (
                  maintenanceCategories.map(category => (
                    <div
                      className="card edit-category-card-inline"
                      id="maintenance-category-block-{{category.id}}"
                    >
                      <div className="card-header">
                        <span className="card-title edit-category-title-inline">
                          <span title="{{category.category}}">
                            <b id="maintenance-category-value-{{category.id}}">{category.name}</b>
                          </span>
                        </span>
                        <div className="card-tools">
                          {category.is_changeable ? (
                            <span>
                              <button
                                className="fa fa-edit m-1 cursor_pointer"
                                title="Edit Category"
                                data-toggle="modal"
                                type="button"
                                data-target="#modal-add-maintenance-category"
                                onClick={() => {
                                  dispatch(setCategoryType('maintenanceCategory'));
                                  handleEdit(category, 'maintenanceCategory');
                                }}
                              >
                                {' '}
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  dispatch(setCategoryType('maintenanceCategory'));
                                  handleConfirm(category.id, 'maintenanceCategory');
                                }}
                              >
                                <i
                                  className="fa fa-trash m-1 font-danger cursor_pointer"
                                  title="Delete Maintenance Category"
                                  data-target="#modal-delete-maintenance-category"
                                  data-toggle="modal"
                                />
                              </button>
                            </span>
                          ) : (
                            <i className="fa fa-lock text-black" />
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="card edit-category-card-inline">
                    <div className="card-header">
                      <span className="card-title edit-category-title-inline">
                        <span title="No Maintenance Category">
                          <b>No Maintenance Category</b>
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                <input
                  type="hidden"
                  id="maintenance-category-{{category.id}}"
                  value="{{category.category}}"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
          <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title">
                  <span>
                    <b>Maintenance Sub Category</b>
                    <i>(Option select)</i>
                  </span>
                </span>
                <div className="card-tools">
                  <button
                    className="mr-2 cursor_pointer"
                    title="Add type"
                    data-target="#modal-add-maintenance-category"
                    data-toggle="modal"
                    onClick={() => {
                      dispatch(setCategoryType('maintenance-type'));
                      handleCreate('maintenance-type');
                    }}
                    type="button"
                  >
                    Add Sub Category
                  </button>
                </div>
              </div>
              <div className="card-body p-0" id="maintenance-category-block">
                {maintenanceTypes && maintenanceTypes.length > 0 ? (
                  maintenanceTypes.map(category => (
                    <div
                      className="card edit-category-card-inline"
                      id="maintenance-category-block-{{category.id}}"
                    >
                      <div className="card-header">
                        <span className="card-title edit-category-title-inline">
                          <span title="{{category.category}}">
                            <b id="maintenance-category-value-{{category.id}}">{category.name}</b>
                          </span>
                        </span>
                        <div className="card-tools">
                          {category.is_changeable ? (
                            <span>
                              <button
                                className="fa fa-edit m-1 cursor_pointer"
                                title="Edit Category"
                                data-toggle="modal"
                                type="button"
                                data-target="#modal-add-maintenance-category"
                                onClick={() => {
                                  dispatch(setCategoryType('maintenance-type'));
                                  handleEdit(category, 'maintenance-type');
                                }}
                              >
                                {' '}
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  dispatch(setCategoryType('maintenance-type'));
                                  handleConfirm(category.id, 'maintenance-type');
                                }}
                              >
                                <i
                                  className="fa fa-trash m-1 font-danger cursor_pointer"
                                  title="Delete Maintenance Category"
                                  data-target="#modal-delete-maintenance-category"
                                  data-toggle="modal"
                                />
                              </button>
                            </span>
                          ) : (
                            <i className="fa fa-lock text-black" />
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="card edit-category-card-inline">
                    <div className="card-header">
                      <span className="card-title edit-category-title-inline">
                        <span title="No Maintenance Category">
                          <b>No Maintenance Sub Category</b>
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                <input
                  type="hidden"
                  id="maintenance-category-{{category.id}}"
                  value="{{category.category}}"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
