import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getAssetTypesByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/asset-type/`);
  return assert(res, res.data, 'Retrieval of asset Type by its company ID failed', res);
};

const getAssetTypebyId = async assetTypeId => {
  const res = await http.get(`${BASE_URL}/asset-type/${assetTypeId}`);

  return assert(res, res.data, 'Retrieval of asset Type by its ID failed', res);
};

const createAssetType = async data => {
  const res = await http.post(`${BASE_URL}/asset-type/`, data);

  return assert(res, res.data, 'Creation of asset Type failed', res);
};

const updateAssetType = async data => {
  const res = await http.put(`${BASE_URL}/asset-type/${data.id}/`, data);
  return assert(res, res.data, 'Update of asset Type failed', res);
};

const deleteAssetType = async assetTypeId => {
  const res = await http.delete(`${BASE_URL}/asset-type/${assetTypeId}/`);
  return assert(res, assetTypeId, 'Deletion of asset Type failed', res);
};

const assetTypeService = {
  getAssetTypesByAuthentication,
  getAssetTypebyId,
  createAssetType,
  updateAssetType,
  deleteAssetType,
};

export default assetTypeService;
