/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */

import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  getBusinessHoursByAuthentication,
  // closeBusinessHourModalDelete,
  closeBusinessHourModalUpdate,
  // deleteBusinessHour,
  // openBusinessHourModalCreate,
  openBusinessHourModalUpdate,
  // setBusinessHour,
  updateBusinessHour,
  reset,
  setBusinessHours,
} from '../../../redux/features/businessHour/businessHourSlice';
import PopUpEditBusinessHour from '../../../components/popUpModals/businessHour/popUpEditBusinessHour';

export default function BusinessHoursTab() {
  const {
    businessHours,
    businessHourModalUpdate,
    businessHour,
    isBusinessHourCreateLoading,
    isBusinessHourCreateError,
    isBusinessHourCreateSuccess,
    isBusinessHourDeleteLoading,
    isBusinessHourDeleteError,
    isBusinessHourDeleteSuccess,
    isBusinessHourUpdateLoading,
    isBusinessHourUpdateError,
    isBusinessHourUpdateSuccess,
    isBusinessHourGetLoading,
    isBusinessHourGetError,
  } = useSelector(state => state.businessHour);
  const dispatch = useDispatch();
  // const [businessHourData, setBusinessHourData] = useState(null);

  // const handleDelete = id => {
  //   dispatch(deleteBusinessHour(id));
  //   dispatch(closeBusinessHourModalDelete());
  // };
  const handleEdit = () => {
    dispatch(openBusinessHourModalUpdate());
    // dispatch(setBusinessHour());
    // setBusinessHourData();
  };

  const handleEditSave = () => {
    dispatch(updateBusinessHour(businessHours));
    dispatch(closeBusinessHourModalUpdate());
  };

  // const handleCreateSave = () => {
  //   dispatch(createBusinessHour({ name: businessHourData.name }));
  //   dispatch(closeBusinessHourModalCreate());
  //   dispatch(reset());
  // };

  useEffect(() => {
    dispatch(reset());
  }, [
    businessHour,
    isBusinessHourCreateLoading,
    isBusinessHourCreateError,
    isBusinessHourCreateSuccess,
    isBusinessHourDeleteLoading,
    isBusinessHourDeleteError,
    isBusinessHourDeleteSuccess,
    isBusinessHourUpdateLoading,
    isBusinessHourUpdateError,
    isBusinessHourUpdateSuccess,
    isBusinessHourGetLoading,
    isBusinessHourGetError,
  ]);
  // const handleConfirm = (id) => {
  //   dispatch(openBusinessHourModalDelete());
  //   dispatch(setBusinessHour(id));
  // };
  function displayToast() {
    if (isBusinessHourCreateSuccess) {
      toast.success('Operation Hours created successfully', {
        toastId: 'businessHourCreateSuccess',
      });
    }
    if (isBusinessHourDeleteSuccess) {
      toast.success('Operation Hours deleted successfully', {
        toastId: 'business Hour Delete Success',
      });
    }
    if (isBusinessHourUpdateSuccess) {
      toast.success('Operation Hours updated successfully', {
        toastId: 'business Hour Update Success',
      });
    }
    if (isBusinessHourGetError) {
      toast.error('Error getting Operation Hours', {
        toastId: 'business Hour GetError',
      });
    }
    if (isBusinessHourCreateError) {
      toast.error('Error creating Operation Hours', {
        toastId: 'businessHourCreateError',
      });
    }
    if (isBusinessHourDeleteError) {
      toast.error('Error deleting Operation Hours', {
        toastId: 'businessHourDeleteError',
      });
    }
    if (isBusinessHourUpdateError) {
      toast.error('Error updating Operation Hours', {
        toastId: 'businessHourUpdateError',
      });
    }
    if (isBusinessHourGetError) {
      toast.error('Error getting Operation Hours', {
        toastId: 'businessHourGetError',
      });
    }
  }

  useEffect(() => {
    dispatch(getBusinessHoursByAuthentication());
    dispatch(reset());
  }, [dispatch]);

  function convertTimeTo12HourFormat(time, type = "toRead") {
    // Split the time string into hours, minutes, and seconds
    let [hours, minutes] = time.split(':');

    // Convert hours to number
    hours = parseInt(hours);
    if (type === "toRead") {
      // Determine AM or PM suffix
      const suffix = hours >= 12 ? 'PM' : 'AM';

      // Adjust hours to 12-hour format
      hours = hours % 12 || 12;

      // Return the formatted time
      return `${hours}:${minutes} ${suffix}`;
    }
    return hours
  }
  const handelChange = (e, id) => {
    const arr = []
    businessHours.map(day => {
      if (day.id === id) {
        if (e.target.name === "is_open") {
          arr.push({ ...day, [e.target.name]: !day.is_open })
        }
        else if (e.target.name === "is_24_hour") {
          arr.push({ ...day, [e.target.name]: !day.is_24_hour })
        }
        else if (e.target.name === "to_hour") {
          const fromHour = convertTimeTo12HourFormat(day?.from_hour, "toCheckHour");
          const toHour = convertTimeTo12HourFormat(e.target.value, "toCheckHour");
          if (fromHour < toHour) {
            arr.push({ ...day, [e.target.name]: e.target.value })
          } else {
            arr.push({ ...day, [e.target.name]: day?.to_hour})
            toast.warning("Opens at should be more than Closes at", {
              toastId: 'businessHourGetErrord',
            });
          }
        }
        else if (e.target.name === "from_hour") {
          const fromHour = convertTimeTo12HourFormat(e.target.value, "toCheckHour");
          const toHour = convertTimeTo12HourFormat(day?.to_hour, "toCheckHour");
          if (fromHour < toHour) {
            arr.push({ ...day, [e.target.name]: e.target.value })
          } else {
            arr.push({ ...day, [e.target.name]: day?.from_hour})
            toast.warning("Opens at should be more Than Closes at", {
              toastId: 'businessHourGetErrord',
            });
          }
        }
      } else arr.push(day)
    })
    dispatch(setBusinessHours(arr))
  }

  return isBusinessHourCreateLoading ||
    isBusinessHourDeleteLoading ||
    isBusinessHourUpdateLoading ||
    isBusinessHourGetLoading ? (
    <div id="loader" />
  ) : (
    <>
      {displayToast()}
      {businessHourModalUpdate && (
        <PopUpEditBusinessHour
          handleClose={() => {
            dispatch(closeBusinessHourModalUpdate())
            dispatch(getBusinessHoursByAuthentication())
          }}
          showModal={businessHourModalUpdate}
          handleSave={handleEditSave}
          modalTitle="Edit Operation Hours"
          primaryButtonText="Save Changes"
          data={businessHours}
          handelChange={handelChange}
        />
      )}
      <h3 className="card-title py-3 px-3 col-lg-12">Operation Hours</h3>
      <div className="card-body p-0 m-0" id="location-block">
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title edit-category-title-inline">
                  <span>
                    <b>Operation Hours Details</b>
                  </span>
                </span>
                <div className="card-tools">
                  <button
                    type="button"
                    className="font-danger cursor_pointer"
                    data-card-widget="collapse"
                    onClick={() => handleEdit()}
                  >
                    Edit
                  </button>
                </div>
              </div>
              <div className="card-body" style={{padding: "10px", paddingLeft: "40px"}}>
                <div className='col'>
                  <div>
                    {businessHours?.length > 0 ? businessHours?.map((dayInfo) => (
                      <div className="mb-3 w-100 flex align-items-center">
                        <div className='w-24'>
                          <h6>{dayInfo?.weekday}</h6>
                        </div>
                        {dayInfo?.is_open && !dayInfo?.is_24_hour ?
                          <div className='w-35 flex' >
                            <h6> {convertTimeTo12HourFormat(dayInfo?.from_hour)}</h6>
                            <h6 className='mr-1 ml-1'>-</h6>
                            <h6>{convertTimeTo12HourFormat(dayInfo?.to_hour)}</h6>
                          </div> :
                          dayInfo?.is_open && dayInfo?.is_24_hour ?
                            <h6 className='w-35'> 24 hours</h6> :
                            <h6 className='w-35'> Closed</h6>

                        }
                      </div>
                    )) : <center><h5>There is No Operation Hours</h5> </center>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
