
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react'
import { Button, Modal } from 'react-bootstrap/';


export default function AddModal({showModal,
    handleClose,
    modalTitle,
    handleCreateSave,
    modelToDo,
    children}) {
      return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header >
              <Modal.Title>{modalTitle}</Modal.Title>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
                onClick={handleClose}
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
              <Button variant='primary' onClick={handleCreateSave}>
                {modelToDo}
              </Button>
              <Button variant='secondary' onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
      );
    }
    
    // onClick={handleClose}
    // onClick={handleCreateSave}
    // onHide={handleClose}
    // closeButton