

import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';


const login = async (userData) => {
  const res = await http.post(`${BASE_URL}/login/`, userData);

  if (res.data) {
    const tokenExpiration = new Date().getTime() + 5 * 60 * 60 * 1000; // 8 hours from now
    localStorage.setItem("userToken", JSON.stringify({...res.data , expiration: tokenExpiration}));
  }

  return res.data;
};

const logout = async () => {
  localStorage.removeItem("userToken");
};

const changePassword = async (userData) => {
  const res = await http.put(
    `${BASE_URL}/password-change/`,
    userData
  );

  return assert(
    res,
    res.data,
    "Error while changing password. Please try again later.",
    res
  );
};

const confirmPassword = async (userData) => {
  const res = await http.post(
    `${BASE_URL}/password-confirm/`,
    userData
  );
  return assert(
    res,
    res.data,
    "Error while confirming password. Please try again later.",
    res
  );
};

const forgotPassword = async (userData) => {
  const res = await http.post(
    `${BASE_URL}/password-forgot/`,
    userData
  );

  return assert(
    res,
    res.data,
    "Error while sending password reset email. Please try again later.",
    res
  );
};



const resetPassword = async (userData) =>{
  const res = await http.put(`${BASE_URL}/password-set/${userData.uidb64}/${userData.token}/`
  ,
  userData.info
  );
  
  return assert(
    res,
    res.data,
    "Error while sending User info. Please try again later.",
    res
  );
}

const checkToken = async (userData) =>{
  const res = await http.get(`${BASE_URL}/password-set/${userData.uidb64}/${userData.token}/`
  );
  return assert(
    res,
    res.data,
    "Error while sending User info. Please try again later.",
    res
  );
}
const authService = {
  login,
  logout,
  changePassword,
  confirmPassword,
  forgotPassword,
  resetPassword,
  checkToken
};

export default authService;
