import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getWorkOrdersByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/workorder/`);

  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};
const exportWorkOrder = async (data) => {
  const res =  await http.post(`${BASE_URL}/workorder-export/` , data);
  return assert(res ,res.data , 'Export of workorder failed', res);
};
const getPagination = async (data) => {
  const filters = `search=${data.searchValue}&page=${data.currentPage}&page_size=${data.rowPerPage}&site=${data.site}&sublocation=${data.sublocation}&filterCategory=${data.filterCategory}&type=${data.type}&submitted_by=${data.staff}&${data.statusAdvance}&asset=${data.asset}&${data.status ? `status=${data.status}` : ""}&start_date=${data?.startDate || ""}&end_date=${data?.endDate || ""}`
  const res = await http.get(`${BASE_URL}/workorder/?${filters}`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
}; 

const getWorkOrderSummary = async () => {
  const res = await http.get(`${BASE_URL}/workorder-summary/`);

  return assert(res, res.data, 'Retrieval of WorkOrder by its ID failed', res);
};

const AcceptAndDecline = async (data) => {
  const res = await http.post(`${BASE_URL}/workorder/${data.id}/${data.status}/`, 
    {
      decline_reason: data.decline_reason
    });

  return assert(res, res.data, 'Retrieval of WorkOrder by its ID failed', res);
};
const adminAcceptAndDecline = async (data) => {
  const res = await http.post(`${BASE_URL}/workorder/${data.id}/admin-accept-decline/`, 
    {
      admin_acceptance_status: data.status,
      decline_reason: data.decline_reason
    });

  return assert(res, res.data, 'Retrieval of WorkOrder by its ID failed', res);
};

const ApproveAndReject = async (data) => {
  const res = await http.post(`${BASE_URL}/workorder-approve/${data.id}/`, 
    {
      approval_status: data.approval_status,
      approval_decline_reason: data.approval_decline_reason
    });

  return assert(res, res.data, 'Retrieval of WorkOrder by its ID failed', res);
};

const getWorkOrderById = async (WorkOrderId) => {
  const res = await http.get(`${BASE_URL}/workorder/${WorkOrderId}/`);

  return assert(res, res.data, 'Retrieval of WorkOrder by its ID failed', res);
};

const createWorkOrder = async (WorkOrderData) => {
  const res = await http.postImg(`${BASE_URL}/workorder/`, WorkOrderData);

  return assert(res, res.data, 'Creation of WorkOrder failed', res);
};

const deleteWorkOrder = async (WorkOrderId) => {
  const res = await http.delete(`${BASE_URL}/workorder/${WorkOrderId}/`);
  return assert(res, WorkOrderId, 'Deletion of WorkOrder failed', res);
};

const updateWorkOrder = async (data) => {
  const res = await http.put(
    `${BASE_URL}/workorder/${data.id}/`,
    data.formData,
  );
  return assert(res, res.data, 'Update of WorkOrder failed', res);
};

const getWorkOrderByTicketNumber = async (WorkOrderTicketNumber) => {
  const res = await http.get(`${BASE_URL}/workorder-by-ticket-number/${WorkOrderTicketNumber}/`);
  return assert(res, res.data, 'Retrieval of WorkOrder by its ID failed', res);
};
const getDueDate = async (payload) => {
  const res = await http.get(`${BASE_URL}/compute-date/${payload.priority}/?due_date=${payload.date}`);
  return assert(res, res.data, 'Retrieval of WorkOrder by its ID failed', res);
};
const addAttachment = async (payload) => {
  const res = await http.post(`${BASE_URL}/ticket-attachment/${payload.ticketId}/`,
    payload.formData
  );
  return assert(res, res.data, 'Retrieval of WorkOrder by its ID failed', res);
};
const editAttachment = async (payload) => {
  const res = await http.put(`${BASE_URL}/ticket-attachment/${payload.ticketId}/${payload.attachmentId}/`,
    payload.formData
  );
  return assert(res, res.data, 'Retrieval of WorkOrder by its ID failed', res);
};

const deleteAttachment = async (payload) => {
  const res = await http.delete(`${BASE_URL}/ticket-attachment/${payload.ticketId}/${payload.attachmentId}/`);
  return assert(res, payload, 'Deletion of WorkOrder failed', res);
};

const workOrderService = {
  getDueDate,
  exportWorkOrder,
  getWorkOrdersByAuthentication,
  getWorkOrderById,
  createWorkOrder,
  deleteWorkOrder,
  updateWorkOrder,
  getWorkOrderByTicketNumber,
  getPagination,
  addAttachment,
  editAttachment,
  deleteAttachment,
  getWorkOrderSummary,
  AcceptAndDecline,
  adminAcceptAndDecline,
  ApproveAndReject
};

export default workOrderService;
