import React from 'react';

export default function WorkOrdersIcon({ fillColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
      <path
        d="M2.4165 3.08329H2.9165M2.4165 3.58329H2.9165M6.66667 3.33333H14M2.4165 7.74996H2.9165M2.4165 8.24996H2.9165M2.4165 12.4166H2.9165M2.4165 12.9166H2.9165M6.66667 8H14M6.66667 12.6667H14M3.33333 3.33333C3.33333 3.70152 3.03486 4 2.66667 4C2.29848 4 2 3.70152 2 3.33333C2 2.96514 2.29848 2.66666 2.66667 2.66666C3.03486 2.66666 3.33333 2.96514 3.33333 3.33333ZM3.33333 8C3.33333 8.36819 3.03486 8.66666 2.66667 8.66666C2.29848 8.66666 2 8.36819 2 8C2 7.63181 2.29848 7.33333 2.66667 7.33333C3.03486 7.33333 3.33333 7.63181 3.33333 8ZM3.33333 12.6667C3.33333 13.0349 3.03486 13.3333 2.66667 13.3333C2.29848 13.3333 2 13.0349 2 12.6667C2 12.2985 2.29848 12 2.66667 12C3.03486 12 3.33333 12.2985 3.33333 12.6667Z"
        stroke={fillColor === "primary" ? "var(--primary-color)" : "var(--grey-content)"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
