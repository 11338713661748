/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { createLocation } from '../../redux/features/location/locationSlice';

export default function AddALocation ({ show, showAdd,setSearchBar }) {
  // const [hasTenants, setHasTenants] = useState(false);
  const [locationinfo, setlocationInfo] = useState({
    site: '',
    street: '',
    district: '',
    city: '',
    state: '',
    postal_code: '',
  });
  const [management, setManagement] = useState({
    tenant: '',
    owner:'',
    start_rent_date: null,
    end_rent_date: null,
    category: '',
    description: null
  });
  const dispatch = useDispatch();

  function resetInputField(){
    document.getElementById("site_name_new").value="";
    document.getElementById("site_street_new").value="";
    document.getElementById("site_district_new").value="";
    document.getElementById("site_city_new").value="";
    document.getElementById("site_state_new").value="";
    document.getElementById("site_postcode_new").value="";
    document.getElementById("tenant_name_new").value="";
    document.getElementById("owner_name_new").value="";
    document.getElementById("site_category").value="";
    document.getElementById("rent_start_date").value="";
    document.getElementById("rent_end_date").value="";
    document.getElementById("description").value="";
  }

  const { profile } = useSelector(state => state.company);
  const  {locationsCount}  = useSelector(state => state.location);
  const limitOfLocations = profile?.subscription?.allowed_locations
  const isLocationLimitEnabled = profile?.subscription?.enable_locations_limit

  const handleCreateLocation = e => {
    e.preventDefault();
    if (isLocationLimitEnabled) {
      if (locationsCount >= limitOfLocations) {
        toast.error('You have reached the maximum number of allowed locations', {
          toastId: 'allowedlocationsError',
        });
      }else{
        dispatch(setSearchBar(true))
        const info = locationinfo;
        info.management = management || null;
        dispatch(createLocation(info));
        resetInputField()
        showAdd();
      }
    }else{
      dispatch(setSearchBar(true))
      const info = locationinfo;
      info.management = management || null;
      dispatch(createLocation(info));
      resetInputField()
      showAdd();
    }
 
  };

  return (
    <div className="card m-4 p-2 none" id="add-site" style={{ display: show ? 'block' : 'none' }}>
      <div className="row px-3 pt-3"/>
      <form onSubmit={handleCreateLocation}>
        <div className="card-header">
          <div className="card-title">
            <h4 className="">
              <b>Add Location</b>
            </h4>
          </div>
        </div>
        <div className="card-body">
          <div className="col-12">
            <div className="row">
              <div className="location_error_msg">
                <span className="font-danger"> </span>
              </div>

              <div className="col-8">
                <label className="font-weight-normal" htmlFor="site_name_new">
                Location/Branch{' '}
                  <label className="m-0 font-danger" htmlFor="site_name_new">
                    *
                  </label>
                </label>
                <input
                  name="site_name"
                  type="text"
                  className="form-control mb-2"
                  placeholder="Location/Branch"
                  id="site_name_new"
                  required
                  onChange={e => setlocationInfo({ ...locationinfo, site: e.target.value })}
                />
              </div>
              <div className="col-4">
                <label className="font-weight-normal" htmlFor="site_street_new">
                  Street
                </label>
                <input
                  name="site_street"
                  type="text"
                  className="form-control mb-2"
                  placeholder="Street (Optional)"
                  id="site_street_new"
                  onChange={e => setlocationInfo({ ...locationinfo, street: e.target.value })}
                />
              </div>
              <div className="col-4">
                <label className="font-weight-normal" htmlFor="site_district_new">
                  District
                </label>
                <input
                  name="site_district"
                  type="text"
                  className="form-control mb-2"
                  placeholder="District (Optional)"
                  id="site_district_new"
                  onChange={e => setlocationInfo({ ...locationinfo, district: e.target.value })}
                />
              </div>
              <div className="col-4">
                <label className="font-weight-normal" htmlFor="site_city_new">
                  City
                </label>
                <input
                  name="site_city"
                  type="text"
                  className="form-control mb-2"
                  placeholder="City (Optional)"
                  id="site_city_new"
                  onChange={e => setlocationInfo({ ...locationinfo, city: e.target.value })}
                />
              </div>
              <div className="col-4">
                <label className="font-weight-normal" htmlFor="site_state_new">
                  State
                </label>
                <input
                  name="site_state"
                  type="text"
                  className="form-control mb-2"
                  placeholder="State (Optional)"
                  id="site_state_new"
                  onChange={e => setlocationInfo({ ...locationinfo, state: e.target.value })}
                />
              </div>

              <div className="col-4">
                <label className="font-weight-normal text-nowrap" htmlFor="site_postcode_new">
                  Postal Code
                </label>
                <input
                  name="site_postcode"
                  type="text"
                  className="form-control mb-2"
                  placeholder="Postal Code (Optional)"
                  id="site_postcode_new"
                  onChange={e => setlocationInfo({ ...locationinfo, postal_code: e.target.value })}
                />
              </div>

              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="location_name_error">
                  {' '}
                </span>
              </div>
            </div>
          </div>
        </div>

        <section style={{ marginTop: '-50px' }}>
          <div className="card-header">
            <div className="card-title">
              <h4 className="">
                <b>Property Information</b>
              </h4>
            </div>
          </div>
          <div style={{ marginBottom: '-50px' }} className="card-body">
            <div className="col-12">
              <div className="row">
                <div className="col-4">
                  <label className="font-weight-normal" htmlFor="owner_name_new">
                    Owner Name{' '}
                  </label>
                  <input
                    name="owner_name"
                    type="text"
                    className="form-control mb-2"
                    placeholder="Owner Name (Optional)"
                    id="owner_name_new"
                    onChange={e =>
                      setManagement({
                        ...management,
                        owner: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-4">
                  <label className="font-weight-normal" htmlFor="tenant_name_new">
                    Tenant Name{' '}
                  </label>
                  <input
                    name="tenant_name"
                    type="text"
                    className="form-control mb-2"
                    placeholder="Tenant Name (Optional)"
                    id="tenant_name_new"
                    onChange={e =>
                      setManagement({
                        ...management,
                        tenant: e.target.value,
                      })
                    }
                  />
                </div>
                <div className=" col-lg-4 col-md-4 ">
                  <label className="font-weight-normal">Property Category</label>
                  <select
                    className=" mb-2 select-meter"
                    name="category"
                    id="site_category"
                    onChange={e =>
                      setManagement({
                        ...management,
                        category: e.target.value,
                      })
                    }
                  >
                    <option value="">Select a Category</option>

                    {['Rented', 'Rental'].map(val => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-12">
                  <label className="font-weight-normal">Start Rental Date</label>
                  <input
                    type="date"
                    className="form-control mb-2"
                    name="service_date"
                    id="rent_start_date"
                    placeholder="Start Date"
                    onChange={e =>
                      setManagement({
                        ...management,
                        start_rent_date: e.target.value,
                      })
                    }
                  />
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-12">
                  <label className="font-weight-normal">End Rental Date</label>
                  <input
                    type="date"
                    className="form-control mb-2"
                    name="service_date"
                    id="rent_end_date"
                    placeholder="End Date"
                    onChange={e =>
                      setManagement({
                        ...management,
                        end_rent_date: e.target.value,
                      })
                    }
                  />
                </div>
                <label className="font-weight-normal">Description</label>
                    <textarea name='description'                    
                      onChange={e =>
                      setManagement({
                        ...management,
                        description: e.target.value,
                      })
                    } 
                    className="form-control mb-2" placeholder="Description" id="description"/>
              </div>
            </div>
          </div>
        </section>

        <div className="card-body">
          <div className="float-right row">
            <div className="row float-right">
              <button title="Add Location" className="btn primary-btn ml-2" type="submit">
                Save
              </button>
              <button
                onClick={()=>{
                  dispatch(setSearchBar(true))
                  showAdd()}}
                className="btn secondary-btn ml-2"
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
