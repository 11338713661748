import React from 'react';

export default function PreventiveManagement({ fillColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.98525 4.56805L6.28943 3.87301C5.58924 3.17361 5.83845 2 6.82866 2C8.16205 2 9.36922 2.53986 10.243 3.41271C11.1168 4.28555 11.6573 5.49137 11.6573 6.82328C11.6573 7.06813 11.639 7.30873 11.6038 7.54377C11.5173 8.12066 11.637 8.73276 12.0551 9.13998L13.5515 10.5973C14.1442 11.1746 14.1503 12.1246 13.5648 12.7093L12.7079 13.5653C12.1277 14.1449 11.187 14.1449 10.6067 13.5653L9.07729 12.0376C8.67423 11.635 8.07799 11.5183 7.51364 11.5984C7.28988 11.6301 7.06119 11.6466 6.82866 11.6466C5.49526 11.6466 4.2881 11.1067 3.41428 10.2339C2.54047 9.36101 2 8.15519 2 6.82328C2 5.83418 3.17492 5.58525 3.8751 6.28465L4.57092 6.97969C5.15113 7.55926 6.09185 7.55926 6.67207 6.97969L6.98524 6.66687C7.56546 6.0873 7.56546 5.14762 6.98525 4.56805Z"
        stroke={fillColor === "primary" ? "var(--primary-color)" : "var(--grey-content)"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
