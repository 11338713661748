import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import assetService from './assetService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // Assets
  assets: [],
  asset: {},
  fileExport: [],
  fileImport: null,
  assetListSearch: [],
  assetHistory: [],
  assetDepreciation: null,
  template: null,

  // filter data
  site: null,
  sublocation: null,
  category: null,

  // modals
  AssetModalDelete: false,
  AssetModalUpdate: false,
  AssetModalCreate: false,

  // Asset states
  isAssetExported: false,
  isAssetImported: false,
  isAssetImportedLoading: false,
  isAssetImportedError: false,

  // Asset fields
  assigned_to: [],
  assetAssignedTo: [],
  assetName: '',
  assetSerialNumber: '',
  assetDescription: '',
  assetPrice: '',
  assetQuantity: '',
  assetMinimumQuantity: '',
  assetLocation: '',
  assetVendor: '',
  assetPurchaseOrder: '',
  assetSite: '',
  assetStock: false,
  assetType: '',
  assetCategory: '',
  assetImage: null,
  assetSearchValue: '',
  loan_eligibility:true,
  
  // pagination
  currentPage: 1,
  rowPerPage: 10,

  // success states
  isAssetGetSuccess: false,
  isAssetCreateSuccess: false,
  isAssetDeleteSuccess: false,
  isAssetUpdateSuccess: false,
  isAssetExportSuccess: false,
  isAssetDownloadSuccess: false,
  isAttachDeleteSuccess: false,
  isSPDeleteSuccess: false,

  // loading states
  isAssetCreateLoading: false,
  isAssetGetLoading: false,
  isAssetDeleteLoading: false,
  isAssetUpdateLoading: false,
  isAssetExportLoading: false,
  isAssetDownloadLoading: false,
  isAssetPaginationGetLoading: false,

  // asset history
  isAssetHistoryGetLoading: false,
  isAssetHistoryGetSuccess: false,
  isAssetHistoryGetError: false,

  // error states
  isAssetGetError: false,
  isAssetCreateError: false,
  isAssetDeleteError: false,
  isAssetUpdateError: false,
  isAssetExportError: false,
  isAssetDownloadError: false,
};

export const createAsset = createAsyncThunk('createAsset', async (data, thunkAPI) => {
  const createAssetRes = await smartTryCatch(assetService.createAsset, data, thunkAPI);
  return createAssetRes;
});

export const getAssetsByAuthentication = createAsyncThunk(
  'getAssetsByAuthentication',
  async (data, thunkAPI) => {
    const getAssets = await smartTryCatch(assetService.getAssetsByAuthentication, data, thunkAPI);
    return getAssets;
  },
);

export const getAssetById = createAsyncThunk('getAssetById', async (data, thunkAPI) => {
  const getAsset = await smartTryCatch(assetService.getAssetById, data, thunkAPI);
  return getAsset;
});

export const getAssetHistoryById = createAsyncThunk(
  'getAssetHistoryById',
  async (data, thunkAPI) => {
    const getAssetHistory = await smartTryCatch(assetService.getAssetHistoryById, data, thunkAPI);
    return getAssetHistory;
  },
);

export const getAssetListSearch = createAsyncThunk('getAssetView', async (data, thunkAPI) => {
  const getAssetList = await smartTryCatch(assetService.getAssetView, data, thunkAPI);
  return getAssetList;
});
export const getAssetsByPageSize = createAsyncThunk(
  'getAssetsByPageSize',
  async (data, thunkAPI) => {
    const getAssets = await smartTryCatch(assetService.getAssetsByPageSize, data, thunkAPI);
    return getAssets;
  },
);

export const getAssetsByRows = createAsyncThunk('getAssetsByRows', async (data, thunkAPI) => {
  const getAssets = await smartTryCatch(assetService.getAssetsByRows, data, thunkAPI);
  return getAssets;
});
export const getAssetDepreciation = createAsyncThunk(
  'getAssetDepreciation',
  async (data, thunkAPI) => {
    const getAssets = await smartTryCatch(assetService.getAssetDepreciation, data, thunkAPI);
    return getAssets;
  },
);
export const assetSearch = createAsyncThunk('assetSearch', async (data, thunkAPI) => {
  const getAsset = await smartTryCatch(assetService.assetSearch, data, thunkAPI);
  return getAsset;
});
export const deleteAsset = createAsyncThunk('deleteAsset', async (data, thunkAPI) => {
  const deleteAssetRes = await smartTryCatch(assetService.deleteAsset, data, thunkAPI);
  return deleteAssetRes;
});
export const deleteAttachment = createAsyncThunk('deleteAttachment', async (data, thunkAPI) => {
  const deleteAttachmentRes = await smartTryCatch(assetService.deleteAttachment, data, thunkAPI);
  return deleteAttachmentRes;
});
export const deletePhotos = createAsyncThunk('deletePhotos', async (data, thunkAPI) => {
  const deletePhotoRes = await smartTryCatch(assetService.deletePhoto, data, thunkAPI);
  return deletePhotoRes;
});
export const deleteSpecification = createAsyncThunk(
  'deleteSpecification',
  async (data, thunkAPI) => {
    const deleteSpecificationRes = await smartTryCatch(
      assetService.deleteSpecification,
      data,
      thunkAPI,
    );
    return deleteSpecificationRes;
  },
);

export const deleteAssetInventory = createAsyncThunk(
  'deleteAssetInventory',
  async (data, thunkAPI) => {
    const deleteAssetInventoryRes = await smartTryCatch(
      assetService.deleteAssetInventory,
      data,
      thunkAPI,
    );
    return deleteAssetInventoryRes;
  },
);

export const updateAsset = createAsyncThunk('updateAsset', async (data, thunkAPI) => {
  const updateAssetRes = await smartTryCatch(assetService.updateAsset, data, thunkAPI);
  return updateAssetRes;
});

export const exportAsset = createAsyncThunk('exportAsset', async (data, thunkAPI) => {
  const exportAssetRes = await smartTryCatch(assetService.exportAsset, data, thunkAPI);
  return exportAssetRes;
});
export const downloadTemplate = createAsyncThunk(
  'downloadAssetTemplate',
  async (data, thunkAPI) => {
    const downloadTemplatetRes = await smartTryCatch(assetService.downloadTemplate, data, thunkAPI);
    return downloadTemplatetRes;
  },
);

export const importAsset = createAsyncThunk('importAsset', async (data, thunkAPI) => {
  const importAssetRes = await smartTryCatch(assetService.importAsset, data, thunkAPI);
  return importAssetRes;
});

export const assetSlice = createSlice({
  name: 'asset',
  initialState: initState,
  reducers: {
    reset: state => {
      state.site = null;
      state.sublocation = null;
      state.category = null;
      state.isAssetCreateError = false;
      state.isAssetCreateSuccess = false;
      state.isAssetCreateLoading = false;
      state.isAssetGetError = false;
      state.isAssetGetSuccess = false;
      state.isAssetGetLoading = false;
      state.isAssetDeleteError = false;
      state.isAssetDeleteSuccess = false;
      state.isAssetDeleteLoading = false;
      state.isAssetUpdateError = false;
      state.isAssetUpdateSuccess = false;
      state.isAssetUpdateLoading = false;
      state.isAssetHistoryGetLoading = false;
      state.isAssetHistoryGetSuccess = false;
      state.isAssetHistoryGetError = false;
      state.isAssetPaginationGetLoading = false;
      // state.Asset = null;
      state.isAssetExported = false;
      state.fileExport = [];
      state.template = null;
      state.isAssetImported = false;
      state.isAssetImportedLoading = false;
      state.isAssetImportedError = false;
      state.isAssetDownloadSuccess = false;
      state.isAssetDownloadLoading = false;
      state.isAssetDownloadError = false;
    },
    openAssetModalCreate: state => {
      state.AssetModalCreate = true;
    },
    closeAssetModalCreate: state => {
      state.assetModalCreate = false;
    },
    openAssetModalUpdate: state => {
      state.assetModalUpdate = true;
    },
    closeAssetModalUpdate: state => {
      state.assetModalUpdate = false;
    },
    openAssetModalDelete: state => {
      state.assetModalDelete = true;
    },
    closeAssetModalDelete: state => {
      state.assetModalDelete = false;
    },
    // store the filter value
    setAssetFilterSite: (state, action) => {
      state.site = action.payload;
    },
    setAssetFilterSublocation: (state, action) => {
      state.sublocation = action.payload;
    },
    setAssetFilterCategory: (state, action) => {
      state.category = action.payload;
    },

    setAsset: (state, action) => {
      state.asset = action.payload;
    },
    setAssetName: (state, action) => {
      state.assetName = action.payload;
    },
    setAssetSerialNumber: (state, action) => {
      state.assetSerialNumber = action.payload;
    },
    setAssetDescription: (state, action) => {
      state.assetDescription = action.payload;
    },
    setAssetAssignedTo: (state, action) => {
      state.assetAssignedTo = action.payload;
    },
    setAssetPrice: (state, action) => {
      state.assetPrice = action.payload;
    },
    setAssetQuantity: (state, action) => {
      state.assetQuantity = action.payload;
    },
    setAssetMinimumQuantity: (state, action) => {
      state.assetMinimumQuantity = action.payload;
    },
    setAssetLocation: (state, action) => {
      state.assetLocation = action.payload;
    },
    setAssetLoanEligibility: (state, action) => {
      state.loan_eligibility = action.payload;
    },
    setAssetVendor: (state, action) => {
      state.assetVendor = action.payload;
    },
    setAssetType: (state, action) => {
      state.assetType = action.payload;
    },
    setAssetStock: (state, action) => {
      state.assetStock = action.payload;
    },
    setAssetSite: (state, action) => {
      state.assetSite = action.payload;
    },
    setAssetPurchaseOrder: (state, action) => {
      state.assetPurchaseOrder = action.payload;
    },
    setAssetCategory: (state, action) => {
      state.assetCategory = action.payload;
    },
    setAssetImage: (state, action) => {
      state.assetImage = action.payload;
    },
    setAssetSearcVal: (state, action) => {
      state.assetSearchValue = action.payload;
    },
    seSizetPageOfAsset: (state, action) => {
      state.pageSize = action.payload.pageSize;
      state.rowPerPage = action.payload.rowPerPage;
    },
    setImportFile: (state, action) => {
      state.fileImport = action.payload;
    },
    setAssignedTo: (state, action) => {
      state.assigned_to = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowPerPage: (state, action) => {
      state.rowPerPage = action.payload;
    },

    resetAsset: state => {
      state.assetName = '';
      state.loan_eligibility = true;
      state.assetSerialNumber = '';
      state.assetDescription = '';
      state.assetAssignedTo = [];
      state.assetPrice = '';
      state.assetQuantity = '';
      state.assetMinimumQuantity = '';
      state.assetLocation = '';
      state.assetVendor = '';
      state.assetPurchaseOrder = '';
      state.assetSite = '';
      state.assetStock = '';
      state.assetType = '';
      state.assetCategory = '';
      state.assetDepreciation = null;
      state.assigned_to = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createAsset.pending, state => {
        state.isAssetCreateLoading = true;
        state.isAssetCreateSuccess = false;
        state.isAssetCreateError = false;
      })
      .addCase(createAsset.fulfilled, (state, action) => {
        state.isAssetCreateLoading = false;
        state.isAssetCreateSuccess = true;
        state.isAssetCreateError = false;
        state.assets.results =[action.payload , ...state.assets.results];
      })
      .addCase(createAsset.rejected, state => {
        state.isAssetCreateLoading = false;
        state.isAssetCreateSuccess = false;
        state.isAssetCreateError = true;
      })
      .addCase(getAssetsByAuthentication.pending, state => {
        state.isAssetGetLoading = true;
        state.isAssetGetSuccess = false;
        state.isAssetGetError = false;
      })
      .addCase(getAssetsByAuthentication.fulfilled, (state, action) => {
        state.isAssetGetLoading = false;
        state.isAssetGetSuccess = true;
        state.isAssetGetError = false;
        state.assets = action.payload;
      })
      .addCase(getAssetsByAuthentication.rejected, state => {
        state.isAssetGetLoading = false;
        state.isAssetGetSuccess = false;
        state.isAssetGetError = true;
      })
      .addCase(getAssetListSearch.pending, state => {
        state.isAssetGetLoading = true;
        state.isAssetGetSuccess = false;
        state.isAssetGetError = false;
      })
      .addCase(getAssetListSearch.fulfilled, (state, action) => {
        state.isAssetGetLoading = false;
        state.isAssetGetSuccess = true;
        state.isAssetGetError = false;
        state.assetListSearch = action.payload?.results;
      })
      .addCase(getAssetListSearch.rejected, state => {
        state.isAssetGetLoading = false;
        state.isAssetGetSuccess = false;
        state.isAssetGetError = true;
      })
      .addCase(getAssetsByPageSize.pending, state => {
        state.isAssetGetLoading = true;
        state.isAssetGetSuccess = false;
        state.isAssetGetError = false;
      })
      .addCase(getAssetsByPageSize.fulfilled, (state, action) => {
        state.isAssetGetLoading = false;
        state.isAssetGetSuccess = true;
        state.isAssetGetError = false;
        state.assets = action.payload;
      })
      .addCase(getAssetsByRows.rejected, state => {
        state.isAssetPaginationGetLoading = false;
        state.isAssetGetSuccess = false;
        state.isAssetGetError = true;
      })
      .addCase(getAssetsByRows.pending, state => {
        state.isAssetPaginationGetLoading = true;
        state.isAssetGetSuccess = false;
        state.isAssetGetError = false;
      })
      .addCase(getAssetsByRows.fulfilled, (state, action) => {
        state.isAssetPaginationGetLoading = false;
        state.isAssetGetSuccess = true;
        state.isAssetGetError = false;
        state.assets = action.payload;
        state.assetListSearch = action.payload?.results;
      })
      .addCase(getAssetsByPageSize.rejected, state => {
        state.isAssetGetLoading = false;
        state.isAssetGetSuccess = false;
        state.isAssetGetError = true;
      })
      .addCase(getAssetById.pending, state => {
        state.isAssetGetLoading = true;
        state.isAssetGetSuccess = false;
        state.isAssetGetError = false;
      })
      .addCase(getAssetById.fulfilled, (state, action) => {
        state.isAssetGetLoading = false;
        state.isAssetGetSuccess = true;
        state.isAssetGetError = false;
        state.asset = action.payload;
      })
      .addCase(getAssetById.rejected, state => {
        state.isAssetGetLoading = false;
        state.isAssetGetSuccess = false;
        state.isAssetGetError = true;
      })
      .addCase(getAssetHistoryById.pending, state => {
        state.isAssetHistoryGetLoading = true;
        state.isAssetHistoryGetSuccess = false;
        state.isAssetHistoryGetError = false;
      })
      .addCase(getAssetHistoryById.fulfilled, (state, action) => {
        state.isAssetHistoryGetLoading = false;
        state.isAssetHistoryGetSuccess = true;
        state.isAssetHistoryGetError = false;
        state.assetHistory = action.payload;
      })
      .addCase(getAssetHistoryById.rejected, state => {
        state.isAssetHistoryGetLoading = false;
        state.isAssetHistoryGetSuccess = false;
        state.isAssetHistoryGetError = true;
      })
      .addCase(deleteAsset.pending, state => {
        state.isAssetDeleteLoading = true;
        state.isAssetDeleteSuccess = false;
        state.isAssetDeleteError = false;
      })
      .addCase(deleteAsset.fulfilled, (state, action) => {
        state.isAssetDeleteLoading = false;
        state.isAssetDeleteSuccess = true;
        state.isAssetDeleteError = false;
        state.assets.results = state.assets?.results.filter(asset => asset.id !== action.payload);
      })
      .addCase(deleteAsset.rejected, state => {
        state.isAssetDeleteLoading = false;
        state.isAssetDeleteSuccess = false;
        state.isAssetDeleteError = true;
      })
      .addCase(deleteAttachment.pending, state => {
        state.isAttachDeleteLoading = true;
        state.isAttachDeleteSuccess = false;
        state.isAttachDeleteError = false;
      })
      .addCase(deleteAttachment.fulfilled, state => {
        state.isAttachDeleteLoading = false;
        state.isAttachDeleteSuccess = true;
        state.isAttachDeleteError = false;
      })
      .addCase(deleteAttachment.rejected, state => {
        state.isAttachDeleteLoading = false;
        state.isAttachDeleteSuccess = false;
        state.isAttachDeleteError = true;
      })
      .addCase(deleteSpecification.pending, state => {
        state.isSPDeleteLoading = true;
        state.isSPDeleteSuccess = false;
        state.isSPDeleteError = false;
      })
      .addCase(deleteSpecification.fulfilled, state => {
        state.isSPDeleteLoading = false;
        state.isSPDeleteSuccess = true;
        state.isSPDeleteError = false;
      })
      .addCase(deleteSpecification.rejected, state => {
        state.isSPDeleteLoading = false;
        state.isSPDeleteSuccess = false;
        state.isSPDeleteError = true;
      })
      .addCase(updateAsset.pending, state => {
        state.isAssetUpdateLoading = true;
        state.isAssetUpdateSuccess = false;
        state.isAssetUpdateError = false;
      })
      .addCase(updateAsset.fulfilled, (state, action) => {
        state.isAssetUpdateLoading = false;
        state.isAssetUpdateSuccess = true;
        state.isAssetUpdateError = false;
        state.assets.results = state.assets?.results?.map(asset =>
          asset.id === action.payload.id ? action.payload : asset,
        );
        state.asset = action.payload;
      })
      .addCase(updateAsset.rejected, state => {
        state.isAssetUpdateLoading = false;
        state.isAssetUpdateSuccess = false;
        state.isAssetUpdateError = true;
      })
      .addCase(exportAsset.pending, state => {
        state.isAssetExported = false;
      })
      .addCase(exportAsset.fulfilled, (state, action) => {
        state.isAssetExported = true;
        state.fileExport = action.payload;
      })
      .addCase(exportAsset.rejected, state => {
        state.isAssetExported = false;
      })
      .addCase(downloadTemplate.pending, state => {
        state.isAssetDownloadLoading = true;
        state.isAssetDownloadSuccess = false;
        state.isAssetDownloadError = false;
      })
      .addCase(downloadTemplate.fulfilled, (state, action) => {
        state.isAssetDownloadLoading = false;
        state.isAssetDownloadSuccess = true;
        state.isAssetDownloadError = false;
        state.template = action.payload.split(',');
      })
      .addCase(downloadTemplate.rejected, state => {
        state.isAssetDownloadLoading = false;
        state.isAssetDownloadSuccess = false;
        state.isAssetDownloadError = true;
      })
      .addCase(importAsset.pending, state => {
        state.isAssetImported = false;
        state.isAssetImportedLoading = true;
        state.isAssetImportedError = false;
      })
      .addCase(importAsset.fulfilled, (state, action) => {
        state.isAssetImportedLoading = false;
        state.isAssetImported = true;
        state.isAssetImportedError = false;
        state.fileImport = action.payload;
      })
      .addCase(importAsset.rejected, state => {
        state.isAssetImported = false;
        state.isAssetImportedLoading = false;
        state.isAssetImportedError = true;
      })
      .addCase(assetSearch.fulfilled, (state, action) => {
        state.assets = action.payload;
      })
      .addCase(getAssetDepreciation.fulfilled, (state, action) => {
        state.assetDepreciation = action.payload;
      });
  },
});

export const {
  reset,
  seSizetPageOfAsset,
  openAssetModalCreate,
  closeAssetModalCreate,
  openAssetModalUpdate,
  closeAssetModalUpdate,
  openAssetModalDelete,
  setAsset,
  closeAssetModalDelete,
  setAssetSearcVal,
  setAssetName,
  setAssetSerialNumber,
  setAssetDescription,
  setAssetAssignedTo,
  setAssetPrice,
  setAssetQuantity,
  setAssetMinimumQuantity,
  setAssetLocation,
  setAssetVendor,
  setAssetLoanEligibility,
  setAssetPurchaseOrder,
  setAssetSite,
  setAssetStock,
  setAssetType,
  setAssetCategory,
  setAssetImage,
  resetAsset,
  setImportFile,
  setAssignedTo,
  setAssetFilterSite,
  setAssetFilterSublocation,
  setAssetFilterCategory,
  setCurrentPage,
  setRowPerPage 
} = assetSlice.actions;

export default assetSlice.reducer;
