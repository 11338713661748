import React, { useEffect } from 'react'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import BoardColumn from './BoardColumn';
import TaskBoardFilter from './filters/TaskBoardFilter';
import { getTaskPlannerBoard, setTaskPlannerBoardData, setTaskPlannerBoardDataOrigin } from '../../../redux/features/dashboard/dashboardSlice';
import Loader from '../../../components/loader/loader';

function TaskPlannerBoard({ selectedMenu, handleSelectedEvent }) {
    const dispatch = useDispatch()
    const {
        isEventsGetLoading,
        isEventUpdateLoading,
        taskPlannerBoardDataOrigin,
        taskPlannerBoardData,
        servicesType,
        tasksBoardSite,
        tasksBoardSublocation,
        tasksBoardStaff,
        taskDateFilterState
    } = useSelector((state) => state.dashboard);

    const filterTaskBoard = async () => {
        const filters = {
            startDate: taskDateFilterState?.find(item => item.startDate)?.startDate ?
                moment(taskDateFilterState?.find(item => item.startDate)?.startDate).format("YYYY-MM-DD") :
                moment.utc(new Date()).startOf("isoWeek").format("YYYY-MM-DD"),
            endDate: taskDateFilterState?.find(item => item.startDate)?.startDate ?
                moment(taskDateFilterState?.find(item => item.endDate)?.endDate).format("YYYY-MM-DD") :
                moment.utc(new Date()).endOf("isoWeek").format("YYYY-MM-DD"),
            feature: `${servicesType ? `ticket_type=${servicesType}` :""}`,
            site: tasksBoardSite?.value || null,
            sublocation: tasksBoardSublocation?.value || null,
            staff: tasksBoardStaff?.value || null
        }
        await dispatch(getTaskPlannerBoard(filters));
    }

    useEffect(() => {
        filterTaskBoard()
    }, [servicesType])

    useEffect(() => {
        dispatch(setTaskPlannerBoardData(
            [
                { title: "Open", dotColor: "#4472B8", data: taskPlannerBoardDataOrigin?.filter(ev => ev.status === "Open") },
                { title: "In progress", dotColor: "#e3871e", data: taskPlannerBoardDataOrigin?.filter(ev => ev.status === "In-Progress") },
                { title: "Complete", dotColor: "#20BB7D", data: taskPlannerBoardDataOrigin?.filter(ev => ev.status === "Completed") },
                { title: "On hold", dotColor: "#D9314A", data: taskPlannerBoardDataOrigin?.filter(ev => ev.status === "On-Hold") }
            ]
        ))
    }, [taskPlannerBoardDataOrigin])

    const { workOrder } = useSelector((state) => state.workOrder);

    useEffect(() => {
        if (workOrder?.id) {
            dispatch(
                setTaskPlannerBoardDataOrigin(
                    taskPlannerBoardDataOrigin?.map((item) =>
                        item.id === workOrder?.id && item?.ticket_type === 'workorder' ? workOrder : item,
                    ),
                ),
            );
        }
    }, [workOrder]);

    return (
        (isEventsGetLoading || isEventUpdateLoading) ? (
            <Loader />
        ) :
            <>
                <TaskBoardFilter selectedMenu={selectedMenu} filterTaskBoard={filterTaskBoard} />
                <div
                    style={{
                        height: 'calc(100vh - 220px)',
                        padding: '16px',
                        display: 'flex',
                        gap: '16px',
                    }}
                >
                    {taskPlannerBoardData?.map(task =>
                        <BoardColumn title={task?.title} dotColor={task?.dotColor} data={task?.data} handleSelectedEvent={handleSelectedEvent} />
                    )}
                </div>
            </>
    )
}

export default TaskPlannerBoard