import React from 'react'

/* eslint-disable no-unneeded-ternary */

function ArrowLeftIcon({ color }) {
    return (
        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.33323 1L1.80464 4.5286C1.54429 4.78894 1.54429 5.21106 1.80464 5.4714L5.33323 9"
                stroke={color ? color : "#36383C"} 
                strokeWidth="1.75"
                strokeLinecap="round" />
        </svg>
    )
}
export default ArrowLeftIcon