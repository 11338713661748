import React from 'react'

function SaveIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.67562 7L6.92052 7M3.57708 6.61983L1.10237 2.16666C0.728483 1.49386 1.44846 0.742122 2.13706 1.08632L12.5671 6.29983C13.1443 6.58832 13.1443 7.41168 12.5671 7.70017L2.13706 12.9137C1.44845 13.2579 0.728482 12.5061 1.10237 11.8333L3.57708 7.38017C3.70847 7.14374 3.70847 6.85626 3.57708 6.61983Z"
                stroke="#F8F9F9"
                strokeWidth="1.75"
                strokeLinecap="round" />
        </svg>
    )
}

export default SaveIcon