import React from 'react'

function BookmarkIcon({fill}) {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill ={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M1.66602 3.33349V13.8578C1.66602 14.2295 2.05717 14.4713 2.38962 14.305L6.10492 12.4474C6.66798 12.1658 7.33072 12.1658 7.89378 12.4474L11.6091 14.305C11.9415 14.4713 12.3327 14.2295 12.3327 13.8578V3.3335C12.3327 2.22893 11.4372 1.3335 10.3327 1.3335H3.66602C2.56145 1.3335 1.66602 2.22892 1.66602 3.33349Z"
                stroke="#36383C"
                strokeWidth="1.75"
                strokeLinecap="round" />
        </svg>
    )
}

export default BookmarkIcon