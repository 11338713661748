/* eslint-disable react/prop-types */
import React from 'react';
// import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./popUpEditBusinessHour.css"

function PopUpEditBusinessHour({
  showModal,
  handleClose,
  handleSave,
  modalTitle,
  data,
  primaryButtonText,
  handelChange
}) {


  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
        <button
          type='button'
          className='close'
          data-dismiss='modal'
          aria-label='Close'
          onClick={handleClose}
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className='col'>
            {data?.length > 0 ? data?.map((dayInfo) => (
              <div className="mb-3 flex align-items-center">
                <div style={{ fontSize: "14px", width: "17%" }}>
                  {dayInfo?.weekday}
                </div>
                <div className=' flex' style={{ fontSize: "14px", width: "16%" }}>
                  <input type="checkbox" checked={dayInfo?.is_open} onChange={(e) => handelChange(e, dayInfo?.id)} name='is_open' className='businessHours-status' />
                  <span style={{ fontSize: "14px" }}>{dayInfo?.is_open ? "Open" : "Closed"}</span>
                </div>
                {dayInfo?.is_open &&
                  <>
                    <div className=' flex' style={{ fontSize: "14px", width: "17%", marginLeft: "10px" }}>
                      <input type="checkbox" checked={dayInfo?.is_24_hour} onChange={(e) => handelChange(e, dayInfo?.id)} name='is_24_hour' />
                      <span style={{ fontSize: "14px", marginLeft: "5px" }}>24 hours</span>
                    </div>
                    {!dayInfo?.is_24_hour &&
                    <div className="flex" style={{ fontSize: "14px", width: "44%" }}>
                      <input style={{ fontSize: "14px" }} type="time" name='from_hour' value={dayInfo?.from_hour} onChange={(e) => handelChange(e, dayInfo?.id)} />
                      <span className='m-1'>TO</span>
                      <input style={{ fontSize: "14px" }} type="time" name='to_hour' value={dayInfo?.to_hour} onChange={(e) => handelChange(e, dayInfo?.id)} />
                    </div>}
                  </>
                }

              </div>
            )) : <center><h5>There is No Operation Hours </h5> </center>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        {data?.length > 0 && <Button variant='primary' onClick={handleSave}>
          {primaryButtonText}
        </Button>
        }
      </Modal.Footer>
    </Modal>
  );
}

export default PopUpEditBusinessHour;
