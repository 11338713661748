import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getDepartmentsByAuthentication = async () => {
  const res = await http.get(
    `${BASE_URL}/department/`
  );

  return assert(
    res,
    res.data,
    "Retrieval of department by its company ID failed",
    res
  );
};

const getDepartmentById = async (departmentId) => {
  const res = await http.get(
    `${BASE_URL}/department/${departmentId}`
  );

  return assert(res, res.data, "Retrieval of department by its ID failed", res);
};

const createDepartment = async (departmentData) => {
  const res = await http.post(
    `${BASE_URL}/department/`,
    departmentData
  );

  return assert(res, res.data, "Creation of department failed", res);
};

const deleteDepartment = async (departmentId) => {
  const res = await http.delete(
    `${BASE_URL}/department/${departmentId}/`
  );

  return assert(res, departmentId, "Deletion of department failed", res);
};

const updateDepartment = async (departmentData) => {
  const res = await http.put(
    `${BASE_URL}/department/${departmentData.id}/`,
    departmentData
  );

  return assert(res, res.data, "Update of department failed", res);
};

const departmentService = {
  getDepartmentsByAuthentication,
  getDepartmentById,
  createDepartment,
  deleteDepartment,
  updateDepartment,
};

export default departmentService;
