/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import { Drawer } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  createInventory,
  setInventoryAssignedTo,
  setInventoryCategory,
  setInventoryLocation,
  setInventoryDescription,
  setInventoryName,
  setInventoryPrice,
  setInventorySerialNumber,
  setInventoryIdNumber,
  setInventoryQuantity,
  setInventorySite,
  setInventoryMinimumQuantity,
  setInventoryType,
  setInventoryPurchaseOrder,
  setInventoryStock,
  setInventoryVendor,
  setInventoryImage,
  setInventoryPhoto,
  setDepartment,
} from '../../redux/features/inventory/inventorySlice';
import { getInventoryCategoryByAuthentication } from '../../redux/features/inventoryCategory/inventoryCategorySlice';
import { getTeamsByAuthentication } from '../../redux/features/teams/teamSlice';
import { getLocationsByAuthentication } from '../../redux/features/location/locationSlice';
import { SelectLocation, SelectPersons, SelectSite } from '../select/select';
import { selectActions } from '../../redux/features/selectOptions/selectSlice';
import formatNumber from '../../utils/formatNumber';

export default function InventoryCreateModal({ openModalCreate, closeModalCreate, inventoryData }) {
  const {
    inventoryName,
    inventorySerialNumber,
    inventoryIdNumber,
    inventoryDescription,
    inventoryAssignedTo,
    inventoryPrice,
    inventoryQuantity,
    inventoryMinimumQuantity,
    // inventoryLocation,
    inventoryVendor,
    inventoryPurchaseOrder,
    // inventorySite,
    inventoryStock,
    inventoryType,
    inventoryCategory,
    inventoryImage,
    inventoryPhoto,
    department
  } = useSelector(state => state.inventory);
  const { departments } = useSelector(state => state.department);


  // const { locations } = useSelector(state => state.location);
  const { user } = useSelector(state => state.user);
  const { inventories } = useSelector(state => state.inventoryCategory);
  const { teams } = useSelector(state => state.teams);
  // const [users, setUsers] = useState([]);
  const [invers, setInventories] = useState(inventories || []);
  // const [locationsData, setLocations] = useState([]);
  const [filename, setfilename] = useState('');
  const [reloadModal, setReloadModal] = useState(false);
  const dispatch = useDispatch();

  const generateSerialNumber = () => {
    const random = Math.floor(Math.random() * 100000000000000000);
    dispatch(setInventoryIdNumber(random));
  };
  //  Applying multiple assign_to users.
  // const filteredUsers =
  //   users !== undefined && users.filter(e => e?.is_active === 'True' && e?.first_name);
  // const options = filteredUsers.map(i => ({
  //   value: i.first_name,
  //   label: i.first_name,
  //   image: i.profile.image,
  // }));
  // const [selectedOptions, setSelectedOptions] = useState([]);
  // const handleMulti = e => {
  //   setSelectedOptions(e);
  // };
  //  For getting instantly selected names
  // useEffect(() => {
  //   const selectedNames = selectedOptions.map(option => option.value);
  //   const selectedUserIds = filteredUsers
  //     .filter(u => selectedNames.includes(u.first_name))
  //     .map(u => u.id);
  //   dispatch(setInventoryAssignedTo(selectedUserIds));
  // }, [selectedOptions]);
  // const formatOptionLabel = ({ label, image }) => (
  //   <div style={{ display: 'flex', alignItems: 'center' }}>
  //     <img src={image} alt={label} style={{ width: '32px', height: '32px', marginRight: '8px' }} />
  //     <span>{label}</span>
  //   </div>
  // );

  const { locations, sublocations } = useSelector(state => state.location)
  const { selectedSite, idOfSite, idOfLocation } = useSelector(state => state.selectOptions)

  const handelPriceChange = (e) => {
    const input = e.target;
    const { value } = input;
    const formattedValue = formatNumber(value)[0];
    dispatch(setInventoryPrice(formattedValue));
  }

  const handleCreateInventory = e => {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append('name', inventoryName);
    formdata.append('serial_number', inventorySerialNumber);
    formdata.append('inventory_id', inventoryIdNumber);
    if (department) {
      formdata.append('department', [department]);
    }
    if (inventoryDescription !== undefined) {
      formdata.append('description', inventoryDescription);
    }
    inventoryAssignedTo?.forEach(element => {
      formdata.append('assigned_to', element.value);
    });
    formdata.append('price', inventoryPrice);
    formdata.append('total', inventoryQuantity);
    formdata.append('minimum_inventory', inventoryMinimumQuantity);
    // formdata.append('location', inventoryLocation || 0);
    // if (inventorySite !== undefined) {
    //   formdata.append('sub_location', inventorySite);
    // }
    if (idOfSite) {
      formdata.append("location", idOfSite)
    }
    if (idOfLocation) {
      formdata.append("sub_location", idOfLocation)
    }
    if (inventoryVendor !== undefined) {
      formdata.append('vendor', inventoryVendor);
    }
    if (inventoryPurchaseOrder !== undefined) {
      formdata.append('po_number', inventoryPurchaseOrder);
    }
    formdata.append('non_stock', inventoryStock || false);
    formdata.append('type', inventoryType);
    formdata.append('category', inventoryCategory);
    if (inventoryImage !== null) {
      formdata.append('image', inventoryImage);
    }
    if (!formatNumber(inventoryPrice)[1] && inventoryPrice) {
      toast.warning("Please write a correct Number on Price field (write the decimal part or remove decimal point)", {
        toastId: 'CreatedEventWarninggg',
      });
    } else {
      dispatch(createInventory(formdata));
      dispatch(setInventoryName(''));
      dispatch(setInventorySerialNumber(''));
      dispatch(setInventoryIdNumber(''));
      dispatch(setInventoryDescription(''));
      dispatch(setInventoryAssignedTo(''));
      dispatch(setInventoryPrice(''));
      dispatch(setInventoryQuantity(''));
      dispatch(setInventoryMinimumQuantity(''));
      dispatch(setInventoryLocation(''));
      dispatch(setInventoryVendor(''));
      dispatch(setInventoryPurchaseOrder(''));
      dispatch(setInventorySite(''));
      dispatch(setInventoryStock(''));
      dispatch(setInventoryType(''));
      dispatch(setInventoryCategory(''));
      dispatch(setInventoryImage(''));
      dispatch(setDepartment(''));
      closeModalCreate();
      dispatch(selectActions.reset())
    }
  };
  useEffect(() => {
    dispatch(getInventoryCategoryByAuthentication());
    dispatch(getTeamsByAuthentication());
    dispatch(getLocationsByAuthentication());
  }, [dispatch]);
  useEffect(() => {
    setInventories(inventories);
  }, [inventories]);

  // useEffect(() => {
  //   setUsers(teams.results || []);
  // }, [teams.results]);

  useEffect(() => {
    if (inventoryData.length === 0) {
      setReloadModal(true);
    }
  }, [inventoryData]);

  useEffect(() => {
    if (reloadModal) {
      dispatch(setInventoryName(''));
      dispatch(setInventorySerialNumber(''));
      dispatch(setInventoryPrice(''));
      dispatch(setInventoryQuantity(''));
      dispatch(setInventoryMinimumQuantity(''));
      setReloadModal(false);
    }
  }, [reloadModal]);


  return (
    <Drawer
      anchor="right"
      open={openModalCreate}
      onClose={closeModalCreate}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '600px',
          height: '100%',
        },
      }}
    >
      <form
        action="{{action}}"
        className="h-100"
        method="POST"
        // encType="multipart/form-data"
        onSubmit={handleCreateInventory}
      >
        <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">
          <div className="col-12 row px-2">
            <div className="my-auto">Add Inventories/Parts</div>

            <div className="row ml-auto">
              <div className="dropdown">
                {/* <button
                  type="button"
                  data-toggle="dropdown"
                  id="dropdownMenu1"
                  className="btn tertiary-btn ml-2"
                >
                  <div className="fas fa-ellipsis-h" />
                </button> */}
                <ul className="dropdown-menu" role="menu" aria-labelledby="dropdownMenu1">
                  <li role="presentation">
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#modal-delete-part"
                      title="Delete Spare part"
                      className="font-danger"
                    >
                      Delete
                    </button>
                  </li>

                  <li role="presentation">
                    <a
                      className="history-link"
                      href="/spare-part-history/{{obj_s.id}}/"
                      title="View Inventory History"
                    >
                      History
                    </a>
                  </li>

                  <li role="presentation">
                    <button href="#" title="Print QR Code" className="text-dark" type="button">
                      Print
                    </button>
                  </li>
                </ul>
              </div>

              <button
                type="button"
                className="btn secondary-btn ml-2"
                data-dismiss="modal"
                onClick={closeModalCreate}
              >
                <div className="fas fa-times" />
              </button>
            </div>
          </div>
        </div>

        <div className="col-12 pb-5">
          <div className="card-body row">
            <div className="col-lg-6 col-sm-12">
              <span className="font-weight-normal row col-12">
                Name
                <div className="font-danger">*</div>
              </span>
              <input
                name="spareparts"
                type="text"
                className="form-control mb-2"
                required
                placeholder="Inventories/Parts Name"
                id="spareparts_id"
                value={inventoryName}
                onChange={e => {
                  dispatch(setInventoryName(e.target.value));
                }}
              />
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="spareparts_error" />
              </div>
            </div>


            <div className="col-lg-6 col-sm-12">
              <span className="font-weight-normal row col-12">
                Inventory ID
                <div className="font-danger">*</div>
              </span>
              <input
                name="inventory_id"
                type="text"
                className="form-control"
                required
                placeholder="Inventory ID"
                id="inventory_id"
                value={inventoryIdNumber}
                onChange={e => {
                  dispatch(setInventoryIdNumber(e.target.value));
                }}
              />
              <button
                className="a-color"
                id="auto_serial_number_btn"
                type="button"
                onClick={generateSerialNumber}
              >
                <span className="button__text">Generate Inventory ID </span>
                <i className="fa fa-refresh" aria-hidden="true">
                  {' '}
                </i>
              </button>
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="serial_number_error" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 mb-2">
              <span className="font-weight-normal row col-12">
                Serial number
                {/* <div className="font-danger">*</div> */}
              </span>
              <input
                name="serial_number"
                type="text"
                className="form-control"
                // required
                placeholder="Serial Number"
                id="serial_number"
                value={inventorySerialNumber}
                onChange={e => {
                  dispatch(setInventorySerialNumber(e.target.value));
                }}
              />
              {/* <button
                className="a-color"
                id="auto_serial_number_btn"
                type="button"
                onClick={generateSerialNumber}
              >
                <span className="button__text">Generate serial number </span>
                <i className="fa fa-refresh" aria-hidden="true">
                  {' '}
                </i>
              </button> */}
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="serial_number_error" />
              </div>
            </div>
            <div className="col-lg-12 col-sm-12">
              <span className="font-weight-normal">Description</span>
              <textarea
                name="description"
                className="form-control mb-3"
                placeholder="Description"
                id="description"
                value={inventoryDescription}
                onChange={e => {
                  dispatch(setInventoryDescription(e.target.value));
                }}
              />
            </div>

            <div className="col-lg-12 col-sm-12 pt-3">
              <label className="font-weight-normal">
                Assigned to <span className="font-danger">*</span>
              </label>
              {/* <div className="col-12 p-0">
                <Select
                  isMulti
                  options={options}
                  onChange={handleMulti}
                  value={selectedOptions}
                  id="assigned_to"
                  formatOptionLabel={formatOptionLabel}
                />
              </div> */}
              <SelectPersons teams={teams?.results} assignedTo={inventoryAssignedTo} setAssignedTo={setInventoryAssignedTo} />
            </div>
            <div className="col-lg-12 col-sm-12">
              <div className="font-weight-normal">
                Photos
                <span className="text-secondary small-font">(Max upload size is 5MB)</span>
                <span id="file-name">{filename}</span>
                <div className="col-12 p-0 m-0">
                  <input
                    name="image"
                    type="file"
                    className="none"
                    id="add-image"
                    accept="image/*"
                    onChange={e => {
                      dispatch(setInventoryImage(e.target.files[0]));
                      setfilename(e.target.files[0].name);
                      dispatch(setInventoryPhoto(URL.createObjectURL(e.target.files[0])));
                    }}
                  />
                  {inventoryPhoto ? (
                    <div className="text-center col-12 p-0">
                      <label htmlFor="add-image">
                        <img
                          className="image p-2 photo"
                          height="120"
                          width="120"
                          alt="qr code"
                          src={inventoryPhoto}
                          id="preview-image"
                        />
                      </label>
                    </div>
                  ) : (
                    <div className="container col-2 m-0 my-2 text-center">
                      <label
                        htmlFor="add-image"
                        className="custom-file-upload justify-content-center"
                        style={{
                          border: '1px solid #ccc',
                          color: '#999999',
                          background: '#f8f8f8',
                          borderRadius: '8px',
                          fontWeight: 'normal',
                          width: '4em',
                          height: '4em',
                          aspectRatio: 'auto 100 / 100',
                          boxShadow:
                            'rgb(214 218 221 / 48%) 3px 3px 3px, white -4px -4px 4px !important',
                          cursor: 'pointer',
                        }}
                      >
                        <i className="fas fa-upload col-12 mt-4" />
                        <div className="col-12">Upload</div>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row col-12 mt-4 pr-0">
              <div className="col-lg-6 col-sm-12">
                <span className="font-weight-normal">
                  Price <span className="font-danger">*</span>
                </span>
                <input
                  required
                  name="price"
                  type="text"
                  className="form-control mb-2"
                  id="price"
                  placeholder="RM 0"
                  value={inventoryPrice}
                  onChange={handelPriceChange}
                />
                <div className="col-lg-12 col-sm-12 m-0">
                  <span className="font-danger" id="price_error" />
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <span className="font-weight-normal">
                  Quantity <span className="font-danger">*</span>
                </span>
                <input
                  required
                  name="inventory"
                  type="number"
                  min="0"
                  className="form-control mb-2"
                  id="inventory"
                  placeholder="Quantity"
                  value={inventoryQuantity}
                  onChange={e => {
                    dispatch(setInventoryQuantity(+e.target.value));
                  }}
                />
                <div className="col-lg-12 col-sm-12 m-0">
                  <span className="font-danger" id="inventory_error" />
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 pr-0">
                <span className="font-weight-normal">
                  Min.Quantity <span className="font-danger">*</span>
                </span>
                <input
                  required
                  name="minimum_inventory"
                  type="number"
                  min="0"
                  onInput={e => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                  className="form-control mb-2"
                  id="minimum_inventory"
                  placeholder="Minimum Quantity"
                  value={inventoryMinimumQuantity}
                  onChange={e => {
                    dispatch(setInventoryMinimumQuantity(+e.target.value));
                  }}
                />
                <div className="col-lg-12 col-sm-12 m-0">
                  <span className="font-danger" id="minimum_inventory_error" />
                </div>
              </div>
              <div className="col-lg-12 col-sm-12 pr-0 custom_Checkbox">
                <input
                  name="non_stock_checkbox"
                  type="checkbox"
                  id="non_stock_checkbox"
                  className="ml-2"
                  style={{ height: '20px', width: '10px !important', margin: '12px 10px' }}
                  onChange={e => {
                    dispatch(setInventoryStock(e.target.checked));
                  }}
                />
                <input
                  type="hidden"
                  id="non_stock"
                  name="non_stock"
                  value={inventoryStock}
                  style={{
                    height: ' 20px',
                  }}
                />
                <span className="font-weight-normal ml-3">Non-Stock</span>
              </div>
              <div className="col-lg-6 col-sm-6">
                <span className="font-weight-normal">Vendor</span>
                <input
                  name="vendor"
                  type="text"
                  className="form-control mb-2"
                  id="vendor"
                  placeholder="Vendor Name"
                  value={inventoryVendor}
                  onChange={e => {
                    dispatch(setInventoryVendor(e.target.value));
                  }}
                />
                <div className="col-lg-12 col-sm-12 m-0">
                  <span className="font-danger" id="vendor_error" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 pr-0">
                <span className="font-weight-normal">Purchase Order</span>
                <input
                  name="purchase_order"
                  type="text"
                  className="form-control mb-2"
                  id="purchase_order"
                  placeholder="Purchase Order Number"
                  value={inventoryPurchaseOrder}
                  onChange={e => {
                    dispatch(setInventoryPurchaseOrder(e.target.value));
                  }}
                />
                <div className="col-lg-12 col-sm-12 m-0">
                  <span className="font-danger" id="purchase_order_error" />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-12 pt-3">
              <div id="ifLocation" className="col-12 p-0">
                <SelectSite locations={locations} width="100%" />
                {selectedSite &&
                  <div className="col-12 p-0" id="building_show">
                    <SelectLocation
                      subLocation={sublocations?.results}
                      allSubLocations={[]}
                      width="100%"
                      noStar
                    />
                  </div>
                }
              </div>
            </div>
            <div className="col-lg-12 col-sm-12 pt-3 pb-3">
              {user.role === 'CliAdm' &&
                <div className="col-lg-12 col-sm-12 p-0">
                  <label className="font-weight-normal">Inventory Department </label>
                  <div className="col-12 p-0">
                    <select
                      name='department'
                      value={department}
                      onChange={e => {
                        dispatch(setDepartment(e.target.value));
                      }}
                      className='form-control select-dash'
                    >
                      <option value="" disabled selected> --Select-- </option>
                      {departments?.map((singleDepartment) => (
                        <option key={departments.id} value={singleDepartment.id}>{singleDepartment.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              }
            </div>


            <div className="col-lg-6 col-sm-6">
              <span className="font-weight-normal">
                Type <span className="font-danger">*</span>
              </span>
              <div className="col-12 p-0">
                <select
                  required
                  className="custom-select2 mb-2"
                  style={{ width: '100%' }}
                  name="spareparts_type"
                  id="spareparts_type"
                  onChange={e => {
                    dispatch(setInventoryType(e.target.value));
                  }}
                >
                  <option value="" disabled selected>
                    --SELECT--
                  </option>
                  <option value="Consumable">Consumable</option>
                  <option value="Non-Consumable">Non-Consumable</option>
                </select>
                <div className="col-lg-12 col-sm-12 m-0">
                  <span className="font-danger" id="spareparts_type_error" />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-sm-6">
              <span className="font-weight-normal">
                Category <span className="font-danger">*</span>
              </span>
              <div className="col-12 p-0">
                <select
                  required
                  className="custom-select2 mb-2"
                  style={{ width: '100% !important' }}
                  name="category"
                  id="category"
                  onChange={e => {
                    dispatch(setInventoryCategory(+e.target.value));
                  }}
                >
                  <option value="" disabled selected>
                    --SELECT--
                  </option>
                  {invers.map(e => (
                    <option value={e.id}>{e.name}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="category_error" />
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12 m-0 p-2 page-profile-footer">
          {user?.role === 'CliAdm' ||
            user?.role === 'Manager' ||
            user?.role === 'Technical Team' ? (
            <button
              className="btn button-btn pmd-btn-fab primary-btn"
              // onClick="this.classList.toggle('button--loading')"
              type="submit"
            >
              <span className="button__text">Save</span>
            </button>
          ) : (
            <button
              className="btn button-btn pmd-btn-fab primary-btn"
              // onClick="this.classList.toggle('button--loading')"
              type="submit"
            >
              <span className="button__text">Save</span>
            </button>
          )}
        </div>
      </form>
    </Drawer>
  );
}
