/* eslint-disable react/prop-types */
/* eslint-disable  no-unneeded-ternary */
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function PopUpEditModal({
  showModal,
  handleClose,
  handleSave,
  modalTitle,
  modalBodyTextInput,
  primaryButtonText,
  onChange,
  value,
  children
}) {
  return (
    <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={handleClose}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
        {children ? children : 
          <div className='col'>
            <div className='row'>
              <input type='hidden' id='department_id' value='' />
              <span className='font-weight-normal'>
                Name <span className='font-danger m-0'>*</span>
              </span>
              <input
                name='department_name'
                type='text'
                className='form-control mb-2'
                placeholder={modalBodyTextInput}
                value={value}
                onChange={onChange}
              />
              <div className='col-lg-12 col-sm-12 m-0'>
                <span className='font-danger' id='department_name_error' />
              </div>
              <div className='col-lg-12 col-sm-12 m-0'>
                <span className='font-danger' id='department_error_msg' />
              </div>
            </div>
          </div>
        }
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSave}>
            {primaryButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default PopUpEditModal;
