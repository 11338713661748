import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { smartTryCatch } from '../../../utils';
import profileService from './companyService';

const initState = {
  profile: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  department: [],
};

export const getCompanyProfile = createAsyncThunk('getComapnyProfile', async (data, thunkAPI) => {
  const get = await smartTryCatch(profileService.getComapnyProfile, data, thunkAPI);
  return get;
});

export const updateCompanyProfile = createAsyncThunk(
  'updateCompanyProfile',
  async (data, thunkAPI) => {
    const get = await smartTryCatch(profileService.updateCompanyProfile, data, thunkAPI);
    return get;
  },
);
export const updateCompanyProfileImage = createAsyncThunk(
  'updateCompanyProfileImage',
  async (data, thunkAPI) => {
    const get = await smartTryCatch(profileService.updateCompanyProfileImage, data, thunkAPI);
    return get;
  },
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState: initState,
  reducers: {
    reset: state => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getCompanyProfile.pending, state => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getCompanyProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
        state.profile = action.payload;
      })
      .addCase(getCompanyProfile.rejected, state => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(updateCompanyProfileImage.pending, state => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateCompanyProfileImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.profile.logo = action.payload.logo;
      })
      .addCase(updateCompanyProfileImage.rejected, state => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(updateCompanyProfile.pending, state => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateCompanyProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.profile = action.payload;
      })
      .addCase(updateCompanyProfile.rejected, state => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      });
  },
});

export const { reset } = profileSlice.actions;
export default profileSlice.reducer;
