/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */

import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register necessary chart.js elements
ChartJS.register(ArcElement, Tooltip, Legend);

function WoOrderPieChart({ data, pdfReport }) {
  // Prepare the data for react-chartjs-2
  const total = data.reduce((acc, value) => acc + value, 0);

  const chartData = {
    labels: ['PM', 'WO'],
    datasets: [
      {
        label: 'Work Order Distribution',
        data: data ? data : [],
        backgroundColor: ['#A7C9EC', '#215F9A'],
        borderColor: ['#FFFFFF', '#FFFFFF'],
        borderWidth: 1,
      },
    ],
  };

  // Chart.js options equivalent to ApexCharts options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          font: {
            size: 18,
            family: 'Source Sans 3',
            weight: 600,
            lineHeight: 1.5,
          },
          color: '#000000',
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            const currentValue = dataset.data[tooltipItem.dataIndex];
            const percentage = ((currentValue / total) * 100).toFixed(2);
            return `${tooltipItem.label}: ${percentage}%`;
          },
        },
      },
    },
  };

  return (
    <div>
      <div id="chart">
        {!pdfReport && (
          <Pie data={chartData} options={options} width={400} height={300} />
        )}
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default WoOrderPieChart;
