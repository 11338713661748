/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

const CustomDatePicker = ({ show, handleClose, handleUpdate, state, setState }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} style={{ left: '-8%' }}>
        <Modal.Header>
          <Modal.Title>Custom Date</Modal.Title>
          <Button variant="primary" onClick={handleClose}>
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className='analytics-date-picker'>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
              months={2}
              direction="vertical"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomDatePicker;
