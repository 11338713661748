import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TaskCalendarFilter from './components/filters/TaskCalendarFilter';
import "./newCalendar.css";
import { setCalendarView } from "../../redux/features/dashboard/dashboardSlice";

/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */


const calculateTimeLinePositionWithinHour = () => {
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinutes = now.getMinutes();
    const totalMinutesInHour = 60;
    const totalHeightPerHour = 80; // Assuming each hour cell is 80px tall

    // Calculate the percentage of the hour passed and convert it to pixels
    const percentageOfHour = currentMinutes / totalMinutesInHour;
    const positionInHour = percentageOfHour * totalHeightPerHour;

    return {
        hour: currentHour,
        positionInHour
    };
};


const getEventColor = (status) => {
    switch (status) {
        case 'In-Progress':
            return { backgroundColor: 'rgba(227, 135, 30, 0.12)', color: "#E3871E" };
        case 'Open':
            return { backgroundColor: 'rgba(68, 114, 184, 0.12)', color: "#337DBC" };
        case 'Completed':
            return { backgroundColor: 'rgba(32, 187, 125, 0.12)', color: "#20BB7D" };
        case 'On-Hold':
            return { backgroundColor: 'rgba(217, 49, 74, 0.12)', color: "#D9314A" };
        // case 'meter':
        //     return { backgroundColor: 'rgba(102, 158, 205, 0.16)', color: "#337DBC" };
        // case 'event':
        //     return { backgroundColor: 'rgba(32, 187, 125, 0.12)', color: "#20BB7D" };
        default:
            return { backgroundColor: 'black', color: "white" };
    }
};


// const getStatusColor = (status) => {
//     switch (status) {
//       case 'Open':
//         return '#337DBC';
//       case 'In-Progress':
//         return '#e3871e';
//       case 'Completed':
//         return '#20BB7D';
//       case 'On-Hold':
//         return '#D9314A';
//       default:
//         return 'yellow';
//     }
//   };

function Popup({ events, onClose, position, onSelectEvent }) {
    return <div className="popup-content" style={{ top: `${position.top - 50}px`, left: `${position.left + 50}px` }}>
        <div className="popup-overlay" onClick={onClose} />
        <ul className="mb-0">
            {events.map((event, index) => (
                <li key={index} className="custom-calendar-event"
                    style={{
                        backgroundColor: getEventColor(event?.status).backgroundColor,
                        color: getEventColor(event?.status).color,
                        marginBottom: "2px", // Add a 2px margin between events
                        height: "22px", // Fixed height for each event
                        position: "relative", // Make it relative within the cell
                        left: "0px",
                    }}
                    onClick={() => {
                        onSelectEvent(event)
                        onClose()
                    }}
                >
                    <span style={{ backgroundColor: getEventColor(event?.status).color }} /> <p>{event.title}</p>
                </li>
            ))}
        </ul>
    </div>
};

const hoursOfDay = Array.from({ length: 24 }, (_, i) => {
    const hour = i % 12 === 0 ? 12 : i % 12; // Convert 0 to 12 for 12-hour format
    const period = i < 12 ? "AM" : "PM";
    return `${hour} ${period}`;
});

const getEventTopPosition = (index) => {
    return index * 30; // Stack events vertically with a small gap
};

const getStartOfWeek = (date) => {
    const newDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())); // Create a UTC-based date
    const day = newDate.getUTCDay();
    const difference = newDate.getUTCDate() - day + (day === 0 ? -6 : 1); // Adjust when the day is Sunday
    newDate.setUTCDate(difference);
    newDate.setUTCHours(0, 0, 0, 0); // Set to midnight in UTC
    return newDate;
};

const getStartOfDay = (date) => {
    const newDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())); // Create a UTC-based date
    newDate.setUTCHours(0, 0, 0, 0); // Set to midnight in UTC
    return newDate;
};


const getFormattedDate = (date) => {
    return date.toLocaleDateString("default", { month: "long", day: "numeric" });
};

const getWeekDays = (startDate) => {
    const days = [];
    for (let i = 0; i < 7; i += 1) {
        const day = new Date(startDate);
        day.setDate(startDate.getDate() + i);
        days.push(day);
    }
    return days;
};

export default function CustomCalendar({filterFunction, handleSelectedEvent}) {
    const dispatch = useDispatch();
    const { selectedDateOnCalendar, events, calendarView } = useSelector((state) => state.dashboard);

    const [currentDate, setCurrentDate] = useState(getStartOfWeek(new Date()));
    const [popupEvents, setPopupEvents] = useState([]);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
    const [popupSlotKey, setPopupSlotKey] = useState(null);

    // Change view handler
    const handleViewChange = (e) => {
        dispatch(setCalendarView(e.target.value));
        if (e.target.value === "week") {
            setCurrentDate(getStartOfWeek(selectedDateOnCalendar));
        } else {
            setCurrentDate(getStartOfDay(selectedDateOnCalendar));
        }
    };

    const toggleExpand = (day, hour, allEvents, event) => {
        // Create a unique key combining the day and hour
        const key = `${day.toDateString()}-${hour}`;

        // Get the button's position
        const rect = event.target.getBoundingClientRect();
        setPopupPosition({
            top: rect.top + window.scrollY + rect.height, // Align below the button
            left: rect.left + window.scrollX, // Align horizontally with the button
        });

        // Set the popup events and state
        setPopupEvents(allEvents); // Pass all events to the popup
        setPopupSlotKey(key);
        setIsPopupVisible(true);
    };


    const statusOrder = {
        "In-Progress": 1,
        "On-Hold": 2,
        "Open": 3,
        "Completed": 4,
    };

    const weekDays = getWeekDays(currentDate);
    const todayDate = new Date();

    const renderEvents = (currentDay, hour) => {
        // Generate a unique key for each slot using date and hour
        const slotKey = `${currentDay.toUTCString()}-${hour}`;

        const currentEvents = events.filter((event) => {
            const eventDate = new Date(event?.due_finish ? event?.due_finish : event?.reading_date);

            // Check if the event occurs on the same UTC day
            const isSameDay = eventDate.getUTCFullYear() === currentDay.getUTCFullYear() &&
                eventDate.getUTCMonth() === currentDay.getUTCMonth() &&
                eventDate.getUTCDate() === currentDay.getUTCDate();

            // Extract hour and period (AM/PM) from the current cell
            const [calendarHourStr, period] = hour.split(" ");
            const calendarHour = parseInt(calendarHourStr, 10);
            const isAM = period === "AM";

            // Convert event hours to 12-hour format using UTC hours
            const eventHour = eventDate.getUTCHours();
            const eventHour12Format = eventHour % 12 === 0 ? 12 : eventHour % 12;
            const eventIsAM = eventHour < 12;

            // Only render if this hour matches the event's start hour and period in UTC
            return (
                isSameDay &&
                eventHour12Format === calendarHour &&
                isAM === eventIsAM
            );
        }).sort((a, b) => {
            // Sort events by minute within the same hour using UTC minutes
            const statusComparison = statusOrder[a.status] - statusOrder[b.status];
            if (statusComparison === 0) {
                const startA = new Date(a.due_finish || a.reading_date);
                const startB = new Date(b.due_finish || b.reading_date);
                return startA.getUTCMinutes() - startB.getUTCMinutes();
            }
            return statusComparison;
        });

        // Determine the events to display in the cell (limit to 2) and hide all others when the popup is open for this slot key
        const eventsToShowInCell = popupSlotKey === slotKey ? currentEvents.slice(0, 2) : currentEvents.slice(0, 2);
        return (
            <>
                {eventsToShowInCell.map((event, index) => (
                    <div
                        onClick={() => handleSelectedEvent(event)}
                        key={`${event.id}-${index}`} // Add an index to ensure unique keys
                        className="custom-calendar-event"
                        style={{
                            backgroundColor: getEventColor(event?.status).backgroundColor,
                            color: getEventColor(event?.status).color,
                            marginBottom: "2px", // Add a 2px margin between events
                            height: "22px", // Fixed height for each event
                            position: "relative", // Make it relative within the cell
                            left: "0px",
                        }}
                    >
                        <span style={{ backgroundColor: getEventColor(event?.status).color }} />
                        <p title={event.title}>{event.title}</p>
                    </div>
                ))}
                {currentEvents.length > 2 && (
                    <button
                        type="button"
                        className="show-more-btn"
                        onClick={(e) => toggleExpand(currentDay, hour, currentEvents.slice(2), e)}
                    >
                        {`+${currentEvents.length - 2} More`}
                    </button>
                )}
            </>
        );
    };

    // Render logic for Day View or Week View
    const renderCalendarBody = () => {
        const { hour: currentHour, positionInHour } = calculateTimeLinePositionWithinHour();

        if (calendarView === "week") {
            return (
                <div className="custom-calendar-body">
                    {hoursOfDay.map((hour, index) => (
                        <div key={hour} className="custom-calendar-row-2">
                            {/* Add the line if this is the current hour and today falls in this week */}
                            {todayDate.toDateString() === selectedDateOnCalendar.toDateString() &&
                                index === currentHour && (
                                    <div
                                        className="current-time-line"
                                        style={{ top: `${positionInHour}px` }}
                                    />
                            )}
                            <div className="custom-calendar-cell time-cell">
                                <span>{hour}</span>
                            </div>
                            {weekDays.map((day) => (
                                <div key={day + hour} className="custom-calendar-cell" style={{ position: "relative" }}>
                                    {renderEvents(day, hour)}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <div className="custom-calendar-body full-width-day-view">
                {hoursOfDay.map((hour, index) => (
                    <div key={hour} className="custom-calendar-row-2 day-view-row">
                        {/* Add the line if this is the current hour and today is being displayed */}
                        {selectedDateOnCalendar.toDateString() === todayDate.toDateString() &&
                            index === currentHour && (
                                <div
                                    className="current-time-line"
                                    style={{ top: `${positionInHour}px` }}
                                />
                            )}
                        <div className="custom-calendar-cell time-cell">
                            <span>{hour}</span>
                        </div>
                        <div className="custom-calendar-cell full-width-cell" style={{ position: "relative" }}>
                            {renderEvents(currentDate, hour)}
                        </div>
                    </div>
                ))}
            </div>
        );
    };


    // Use effect to set the current date based on selected view and selectedDateOnCalendar
    useEffect(() => {
        const weekStartDate = getStartOfWeek(new Date(selectedDateOnCalendar));
        const dayStartDate = getStartOfDay(new Date(selectedDateOnCalendar));
        if (calendarView === "week") {
            setCurrentDate(weekStartDate);
        } else {
            setCurrentDate(dayStartDate);
        }
    }, [selectedDateOnCalendar, calendarView]);

    return (
        <div className="custom-calendar-container">
            {isPopupVisible && <Popup events={popupEvents} onClose={() => setIsPopupVisible(false)} position={popupPosition} onSelectEvent={handleSelectedEvent} />}
            <div className="custom-calendar-header">
                <TaskCalendarFilter handleViewChange={handleViewChange} selectedCalendarMode={calendarView} filterFunction={filterFunction} />
                <h6 className="count-service"><span>{events?.length} </span> Services activities</h6>
            </div>
            <div className="custom-calendar">
                <div className="custom-calendar-header-2">
                    <div className="custom-calendar-cell time-header" />
                    {calendarView === "week" && weekDays.map((day) => (
                        <div key={day.toDateString()} className={`custom-calendar-cell day-header ${day.toDateString() === todayDate.toDateString() ? "today" : ""}`}>
                            {day.toLocaleDateString("default", { weekday: "short" })} {day.getDate()}
                        </div>
                    ))}
                </div>
                {calendarView === "day" && (
                    <div className={`custom-calendar-cell day-header ${selectedDateOnCalendar.toDateString() === todayDate.toDateString() ? "today" : ""} full-width-header`}>
                        {currentDate.toLocaleDateString("default", { weekday: "long", month: "long", day: "numeric" })}
                    </div>
                )}
                {renderCalendarBody()}
            </div>
        </div>
    );
}