/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable */
// import React from 'react';
// import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';

// const styles = StyleSheet.create({
//   detailsText: {
//     textAlign: 'left',
//     fontSize: '10px',
//     marginBottom: '5px',
//     // fontFamily: 'Open Sans, sans-serif',
//     fontWeight: '500',
//   },
// });

// const formateAssetName = name => {
//   if (name.length >= 40) {
//     return `${name.slice(0, 37)}...`;
//   }
//   return name;
// };

// const InventoryBulkQrPrint = ({ inventories, company }) => {
//   return (
//     <Document>
//       {inventories?.map(inventory => (
//         <Page key={inventory?.id} size={{ width: 227, height: 113 }}>
//           <View
//             style={{
//               flexDirection: 'row',
//               justifyContent: 'space-between',
//             }}
//           >
//             <View>
//               <Image
//                 style={{ width: '100px', height: '105px' }}
//                 src={`${inventory?.qr_code}?noChache=shimon9696`}
//                 source={{
//                   header: {
//                     'Access-Control-Allow-Origin': '*',
//                   },
//                 }}
//                 alt="Qr code"
//               />
//             </View>
//             <View style={{ paddingLeft: '3px', paddingRight: '3px', width: 110 }}>
//               <View
//                 style={{
//                   flexDirection: 'row',
//                   justifyContent: 'center',
//                 }}
//               >
//                 {/* <Image src="https://foxasset-development.s3.amazonaws.com/media/company_logos/example.png?noChache=shimon969696" source={{
//                       header:{
//                         'Access-Control-Allow-Origin': '*'
//                       }
//                     }} /> */}
//                 <Image
//                   style={{ width: '70px', height: '45px', marginTop: '-4px' }}
//                   src={`${company}?noChache=shimon969696`}
//                   source={{
//                     header: {
//                       'Access-Control-Allow-Origin': '*',
//                     },
//                   }}
//                   alt="Company logo"
//                 />
//               </View>
//               <View>
//                 <Text style={styles.detailsText}>
//                   Inventory:{inventory?.name ? formateAssetName(inventory?.name) : '--'}
//                 </Text>
//                 <Text style={styles.detailsText}>ID:{inventory?.inventory_id}</Text>

//                 <Text style={styles.detailsText}>
//                   LOC:{inventory?.location ? inventory?.location?.site : '--'}
//                 </Text>
//               </View>
//             </View>
//           </View>
//         </Page>
//       ))}
//     </Document>
//   );
// };

// export default InventoryBulkQrPrint;
import React from 'react';
import { jsPDF } from "jspdf";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPrint } from '@fortawesome/free-solid-svg-icons';
// import imageSample from '../../assets/img/inventory_qr_code.png'
// import companyLogo from '../../assets/img/download (1).png'

const formateAssetName = name => {
    if (name.length >= 15) {
      return `${name.slice(0, 15)}...`;
    }
    return name;
  };
const inventoryBulkQrPrint = ({ inventories, company }) => {

    const generatePDF = () => {
        const pdf = new jsPDF({
          orientation: 'landscape', // or 'landscape'
          unit: 'pt',
          format: [245,140]// specify your custom page size in points
        });
    
        inventories.forEach((inventory,index) => {
          pdf.setFontSize(11);
          // const companyLogoUrl = companyLogo;
          const companyLogoUrl = `${company}?noChache=shimon969696`;
          pdf.addImage(companyLogoUrl, 'PNG', 110, 17,50,50);
          
          // const qrCodeDataUrl = imageSample;
          const qrCodeDataUrl = `${inventory?.qr_code}?noChache=shimon9696`;
          pdf.addImage(qrCodeDataUrl, 'PNG', 0, 7, 110, 118)
          
          pdf.text(110, 80, 'Inventory: ' + (inventory?.name ? formateAssetName(inventory?.name) : '--'));
          pdf.text(110, 95, 'ID: ' + inventory?.inventory_id);
        
          pdf.text(110, 110, 'LOC: ' + (inventory?.location ? inventory?.location?.site : '--'));
          // pdf.text(110, 110, 'LOC: ' + index);
          if(index<inventories.length-1){
            pdf.addPage();
          }
          // const companyLogoUrl = `${company}?noChache=shimon969696`;
          // pdf.addImage(companyLogoUrl, 'PNG', 20, 90, 70, 43);
    
          // const qrCodeDataUrl =imageSample;
          // pdf.addImage(qrCodeDataUrl, 'PNG', 200,20,150,150);
        });
    
        // Save the generated PDF and open in a new window
        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
    
        window.open(pdfUrl, '_blank');
      };


    return (
        <button type="button" onClick={generatePDF}>Bulk QR Printing <FontAwesomeIcon style={{ paddingLeft: '7px' }} icon={faPrint} /></button>
    );
};

export default inventoryBulkQrPrint;
