import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  getMeterCategoryByAuthentication,
  closeMeterCategoryModalDelete,
  closeMeterCategoryModalUpdate,
  createMeterCategory,
  deleteMeterCategory,
  openMeterCategoryModalCreate,
  openMeterCategoryModalDelete,
  setMeterCategory,
  openMeterCategoryModalUpdate,
  closeMeterCategoryModalCreate,
  updateMeterCategory,
  reset as resetMeterCategory,
} from '../../../redux/features/meterCategory/meterCategorySlice';
import {
  getMeterMeasurementByAuthentication,
  createMeterMeasurement,
  deleteMeterMeasurement,
  updateMeterMeasurement,
  setMeterMeasurement,
  closeMeterMeasurementModalCreate,
  closeMeterMeasurementModalDelete,
  closeMeterMeasurementModalUpdate,
  openMeterMeasurementModalCreate,
  openMeterMeasurementModalDelete,
  openMeterMeasurementModalUpdate,
  reset as resetMeterMeasurement,
} from '../../../redux/features/meterMeasurement/meterMeasurementSlice';
import PopUpDeleteModal from '../../popUpModals/editCategory/popUpDeleteModal';
import PopUpEditModal from '../../popUpModals/editCategory/popUpEditModal';
import PopUpAddModal from '../../popUpModals/editCategory/popUpAddModal';
import { setCategoryType } from '../../../redux/features/editCategory/editCategorySlice';

export default function MeterTab() {
  const {
    meterCategories,
    meterCategoryModalCreate,
    meterCategoryModalDelete,
    meterCategoryModalUpdate,
    meterCategory,
    isMeterCategoryCreateLoading,
    isMeterCategoryCreateSuccess,
    isMeterCategoryUpdateLoading,
    isMeterCategoryUpdateSuccess,
    isMeterCategoryDeleteLoading,
    isMeterCategoryDeleteSuccess,
  } = useSelector(state => state.meterCategory);
  const {
    meterMeasurements,
    meterMeasurementModalCreate,
    meterMeasurementModalDelete,
    meterMeasurementModalUpdate,
    meterMeasurement,
    isMeterMeasurementCreateLoading,
    isMeterMeasurementCreateSuccess,
    isMeterMeasurementUpdateLoading,
    isMeterMeasurementUpdateSuccess,
    isMeterMeasurementDeleteLoading,
    isMeterMeasurementDeleteSuccess,
  } = useSelector(state => state.meterMeasurement);
  const { categoryType } = useSelector(state => state.editCategory);
  const dispatch = useDispatch();
  const [meterMeasurementData, setMeterMeasurementData] = useState(null);
  const [meterCategoryData, setMeterCategoryData] = useState(null);

  const handleConfirm = (id, type) => {
    if (type === 'meterMeasurement') {
      dispatch(openMeterMeasurementModalDelete());
      dispatch(setMeterMeasurement(id));
    } else if (type === 'meterCategory') {
      dispatch(openMeterCategoryModalDelete());
      dispatch(setMeterCategory(id));
    }
  };
  const handleDelete = () => {
    if (categoryType === 'meterMeasurement') {
      dispatch(deleteMeterMeasurement(meterMeasurement));
      dispatch(closeMeterMeasurementModalDelete());
    } else if (categoryType === 'meterCategory') {
      dispatch(deleteMeterCategory(meterCategory));
      dispatch(closeMeterCategoryModalDelete());
    }
  };
  const handleEdit = (data, type) => {
    if (type === 'meterMeasurement') {
      dispatch(openMeterMeasurementModalUpdate());
      dispatch(setMeterMeasurement(data.id));
      setMeterMeasurementData(data);
    }
    if (type === 'meterCategory') {
      dispatch(openMeterCategoryModalUpdate());
      dispatch(setMeterCategory(data.id));
      setMeterCategoryData(data);
    }
  };

  const handleEditSave = () => {
    if (categoryType === 'meterMeasurement') {
      dispatch(
        updateMeterMeasurement({
          id: meterMeasurement,
          name: meterMeasurementData.name,
        }),
      );
      dispatch(closeMeterMeasurementModalUpdate());
    }
    if (categoryType === 'meterCategory') {
      dispatch(
        updateMeterCategory({
          id: meterCategory,
          name: meterCategoryData.name,
        }),
      );
      dispatch(closeMeterCategoryModalUpdate());
    }
  };

  const handleCreate = type => {
    if (type === 'meterMeasurement') {
      dispatch(openMeterMeasurementModalCreate());
    } else if (type === 'meterCategory') {
      dispatch(openMeterCategoryModalCreate());
    }
  };

  const handleCreateSave = () => {
    if (categoryType === 'meterMeasurement') {
      dispatch(
        createMeterMeasurement({
          name: meterMeasurementData.name,
        }),
      );
      dispatch(closeMeterMeasurementModalCreate());
      dispatch(resetMeterMeasurement());
    }
    if (categoryType === 'meterCategory') {
      dispatch(
        createMeterCategory({
          name: meterCategoryData.name,
        }),
      );
      dispatch(closeMeterCategoryModalCreate());
      dispatch(resetMeterCategory());
    }
  };

  useEffect(() => {
    dispatch(getMeterCategoryByAuthentication());
    dispatch(getMeterMeasurementByAuthentication());

    dispatch(resetMeterCategory());
    dispatch(resetMeterMeasurement());
  }, [dispatch]);

  function displayToast() {
    if (isMeterMeasurementCreateSuccess) {
      toast.success('Meter Measurement Created Successfully', {
        toastId: 'meterMeasurementCreateSuccess',
      });
      dispatch(resetMeterMeasurement());
    }
    if (isMeterMeasurementUpdateSuccess) {
      toast.success('Meter Measurement Updated Successfully', {
        toastId: 'meterMeasurementUpdateSuccess',
      });
      dispatch(resetMeterMeasurement());
    }
    if (isMeterMeasurementDeleteSuccess) {
      toast.success('Meter Measurement Deleted Successfully', {
        toastId: 'meterMeasurementDeleteSuccess',
      });
      dispatch(resetMeterMeasurement());
    }
    if (isMeterCategoryCreateSuccess) {
      toast.success('Meter Category Created Successfully', {
        toastId: 'meterCategoryCreateSuccess',
      });
      dispatch(resetMeterCategory());
    }
    if (isMeterCategoryUpdateSuccess) {
      toast.success('Meter Category Updated Successfully', {
        toastId: 'meterCategoryUpdateSuccess',
      });
      dispatch(resetMeterCategory());
    }
    if (isMeterCategoryDeleteSuccess) {
      toast.success('Meter Category Deleted Successfully', {
        toastId: 'meterCategoryDeleteSuccess',
      });
      dispatch(resetMeterCategory());
    }
  }
  return isMeterCategoryCreateLoading ||
    isMeterCategoryDeleteLoading ||
    isMeterCategoryUpdateLoading ||
    isMeterMeasurementCreateLoading ||
    isMeterMeasurementDeleteLoading ||
    isMeterMeasurementUpdateLoading ? (
    <div id="loader" />
  ) : (
    <>
      {displayToast()}
      {categoryType === 'meterMeasurement' && meterMeasurementModalCreate && (
        <PopUpAddModal
          showModal={meterMeasurementModalCreate}
          handleCreateSave={handleCreateSave}
          handleClose={() => dispatch(closeMeterMeasurementModalCreate())}
          modalTitle="Add meter measurement"
          primaryButtonText="Create meter measurement"
          value={meterMeasurementData && meterMeasurementData.name}
          onChange={e => {
            setMeterMeasurementData({
              ...meterMeasurementData,
              name: e.target.value,
            });
          }}
        >
          <div className="col">
            <div className="row">
              <input type="hidden" id="inventory_id" value="" />
              <span className="font-weight-normal">
                Name <span className="font-danger m-0">*</span>
              </span>
              <input
                name="inventory_name"
                type="text"
                className="form-control mb-2"
                placeholder="Enter measurement name"
                // value={meterMeasurementData?.name}
                onChange={e =>
                  setMeterMeasurementData({
                    ...meterMeasurementData,
                    name: e.target.value,
                  })
                }
              />

              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_name_error" />
              </div>
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_error_msg" />
              </div>
            </div>
          </div>
        </PopUpAddModal>
      )}
      {meterMeasurementModalUpdate && (
        <PopUpEditModal
          handleClose={() => dispatch(closeMeterMeasurementModalUpdate())}
          showModal={meterMeasurementModalUpdate}
          handleSave={handleEditSave}
          modalTitle="Edit meter measurement"
          primaryButtonText="Save Changes"
          modalBodyTextInput={meterMeasurementData.name}
          value={meterMeasurementData && meterMeasurementData.name}
          onChange={e => {
            setMeterMeasurementData({
              ...meterMeasurementData,
              name: e.target.value,
            });
          }}
        />
      )}
      {meterMeasurementModalDelete && (
        <PopUpDeleteModal
          showModal={meterMeasurementModalDelete}
          handleClose={() => dispatch(closeMeterMeasurementModalDelete())}
          handleConfirm={handleDelete}
          modalTitle="Delete meter measurement"
          modalBodyText="Are you sure you want to delete this meter measurement?"
        />
      )}
      {meterCategoryModalCreate && (
        <PopUpAddModal
          showModal={meterCategoryModalCreate}
          handleCreateSave={handleCreateSave}
          handleClose={() => dispatch(closeMeterCategoryModalCreate())}
          modalTitle="Add meter category"
          primaryButtonText="Create meter category"
          value={meterCategoryData && meterCategoryData.name}
          onChange={e => {
            setMeterCategoryData({
              ...meterCategoryData,
              name: e.target.value,
            });
          }}
        >
          <div className="col">
            <div className="row">
              <input type="hidden" id="inventory_id" value="" />
              <span className="font-weight-normal">
                Name <span className="font-danger m-0">*</span>
              </span>
              <input
                name="inventory_name"
                type="text"
                className="form-control mb-2"
                placeholder="Enter category name"
                // value={meterCategoryData?.name}
                onChange={e =>
                  setMeterCategoryData({
                    ...meterCategoryData,
                    name: e.target.value,
                  })
                }
              />

              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_name_error" />
              </div>
              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="inventory_error_msg" />
              </div>
            </div>
          </div>
        </PopUpAddModal>
      )}
      {meterCategoryModalUpdate && (
        <PopUpEditModal
          handleClose={() => dispatch(closeMeterCategoryModalUpdate())}
          showModal={meterCategoryModalUpdate}
          handleSave={handleEditSave}
          modalTitle="Edit meter category"
          primaryButtonText="Save Changes"
          modalBodyTextInput={meterCategoryData.name}
          value={meterCategoryData && meterCategoryData.name}
          onChange={e => {
            setMeterCategoryData({
              ...meterCategoryData,
              name: e.target.value,
            });
          }}
        />
      )}
      {meterCategoryModalDelete && (
        <PopUpDeleteModal
          showModal={meterCategoryModalDelete}
          handleClose={() => dispatch(closeMeterCategoryModalDelete())}
          handleConfirm={handleDelete}
          key={meterCategoryData?.id}
          modalTitle="Delete meter category"
          modalBodyText="Are you sure you want to delete this meter category?"
        />
      )}
      <div className="card-body p-0 m-0">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h3 className="card-title py-3 px-3 col-lg-12">Meter Fieldset</h3>
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title edit-category-title-inline">
                  <span>
                    <b>Meter Categories</b>
                    <i>(Option select)</i>
                  </span>
                </span>
                <div className="card-tools">
                  <button
                    className="mr-2 cursor_pointer"
                    title="Add category"
                    data-target="#modal-add-metercategory"
                    data-toggle="modal"
                    type="button"
                    onClick={() => {
                      dispatch(setCategoryType('meterCategory'));
                      handleCreate('meterCategory');
                    }}
                  >
                    Add Category
                  </button>
                </div>
              </div>
              <div className="card-body p-0 " id="metercategory-block">
                {meterCategories && meterCategories.length > 0 ? (
                  meterCategories.map(category => (
                    <div
                      className="card edit-category-card-inline"
                      id="metercategory-block-{{category.id}}"
                    >
                      <div className="card-header">
                        <span className="card-title edit-category-title-inline">
                          <button
                            title="Edit Meter Category"
                            data-target="#modal-add-metercategory"
                            data-toggle="modal"
                            type="button"
                            onClick={() => {
                              dispatch(setCategoryType('meterCategory'));
                              handleEdit(category, 'meterCategory');
                            }}
                          >
                            <b id="metercategory-value-{{category.id}}">{category.name}</b>
                          </button>
                        </span>
                        <div className="card-tools">
                          <button
                            className="fa fa-edit m-1 cursor_pointer"
                            title="Edit Category"
                            onClick={() => {
                              dispatch(setCategoryType('meterCategory'));
                              handleEdit(category, 'meterCategory');
                            }}
                            type="button"
                          >
                            {' '}
                          </button>
                          <button
                            title="Edit Meter Category"
                            type="button"
                            className="fa fa-trash m-1 cursor_pointer"
                            onClick={() => {
                              dispatch(setCategoryType('meterCategory'));
                              handleConfirm(category.id, 'meterCategory');
                            }}
                          >
                            {' '}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="card edit-category-card-inline">
                    <div className="card-header">
                      <span className="card-title">
                        <span title="No Meter Category">
                          <b>No Meter Category</b>
                        </span>
                      </span>
                    </div>
                  </div>
                )}

                <input
                  type="hidden"
                  id="metercategory-{{category.id}}"
                  value="{{category.category}}"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <h3 className="card-title py-3 px-3 col-lg-12">Meter Measurement Fieldset</h3>
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title">
                  <span>
                    <b>Meter Measurement</b>
                    <i>(Option select)</i>
                  </span>
                </span>
                <div className="card-tools">
                  <button
                    className="mr-2 cursor_pointer"
                    title="Add measurement"
                    type="button"
                    data-target="#modal-add-meter_measurement"
                    data-toggle="modal"
                    onClick={() => {
                      dispatch(setCategoryType('meterMeasurement'));
                      handleCreate('meterMeasurement');
                    }}
                  >
                    Add Measurement
                  </button>
                </div>
              </div>
              <div className="card-body p-0 " id="meter_measurement-block">
                {meterMeasurements && meterMeasurements.length > 0 ? (
                  meterMeasurements.map(measurement => (
                    <div
                      className="card edit-category-card-inline"
                      id="meter_measurement-block-{{measurement.id}}"
                    >
                      <div className="card-header">
                        <span className="card-title">
                          <button
                            title="Edit Meter Measurement"
                            data-target="#modal-add-meter_measurement"
                            data-toggle="modal"
                            type="button"
                            onClick={() => {
                              dispatch(setCategoryType('meterMeasurement'));
                              handleEdit(measurement, 'meterMeasurement');
                            }}
                          >
                            <b id="meter_measurement-value-{{measurement.id}}">
                              {measurement.name}
                            </b>
                          </button>
                        </span>
                        <div className="card-tools">
                          {measurement?.is_changeable ? (
                            <span>
                              <button
                                className="fa fa-edit m-1 cursor_pointer"
                                title="Edit Category"
                                onClick={() => {
                                  dispatch(setCategoryType('meterMeasurement'));
                                  handleEdit(measurement, 'meterMeasurement');
                                }}
                                type="button"
                              >
                                {' '}
                              </button>

                              <button
                                className="mr-2 cursor_pointer"
                                type="button"
                                onClick={() => {
                                  dispatch(setCategoryType('meterMeasurement'));
                                  handleConfirm(measurement.id, 'meterMeasurement');
                                }}
                              >
                                <i
                                  className="fa fa-trash m-1 font-danger cursor_pointer"
                                  title="Delete Meter Measurement"
                                  data-target="#modal-delete-meter_measurement"
                                  data-toggle="modal"
                                />
                              </button>
                            </span>
                          ) : (
                            <i className="fa fa-lock text-black" />
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="card edit-category-card-inline">
                    <div className="card-header">
                      <span className="card-title">
                        <span title="No Meter Measurement">
                          <b>No Meter Measurement</b>
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                <input
                  type="hidden"
                  id="meter_measurement-{{measurement.id}}"
                  value="{{measurement.unit}}"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
