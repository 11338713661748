import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSORTab } from '../../redux/features/SOR/SORSlice';
import Tooltip from '../../components/tooltip/tooltip';



function EpqTabs() {
    const dispatch = useDispatch()
    const
        {
            SORTab,
            summaryCounts
        } = useSelector(state => state.SOR)

    return (
        <div className="pm-schedule-tabs mb-3 mt-3">
            <button type='button' className={SORTab === "sor" ? "" : "un-active"} onClick={() => dispatch(setSORTab("sor"))}>
                Schedule of rate ( {summaryCounts?.schedule_of_rates} ) <span>
                    <Tooltip content= {<span>View and select rate items to <br/> include in the BOQ.</span>} />
                    </span>
            </button>
            <button type='button' className={SORTab === "boq" ? "" : "un-active"} onClick={() => dispatch(setSORTab("boq"))}>
                Bill of quantity ( {summaryCounts?.bill_of_quantity} ) <span>
                <Tooltip content={<span>View all BOQs, including drafts <br/> and published versions.</span>}  />
                </span>
            </button>
            <button type='button' className={SORTab === "history" ? "" : "un-active"} onClick={() => dispatch(setSORTab("history"))}>
                History ( {summaryCounts?.history} ) <span>
                <Tooltip content={<span>Track changes to SOR content</span>}  />
                </span>
            </button>
        </div>
    );
}

export default EpqTabs;