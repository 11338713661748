import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setBillWorkData } from '../../redux/features/SOR/SORSlice'

const inputStyle = "block w-full px-2 py-2 bg-white border border-gray-300 rounded-md mb-2"
function WorkData() {
    const { billWorkData } = useSelector(state => state.SOR)
    const dispatch = useDispatch()
    const handleInputChange = (e) => {
        dispatch(setBillWorkData({ ...billWorkData, [e.target.name]: e.target.value }))
    }
    
    return (
        <section className='work-info row p-3'>
            <div className="col-lg-12 col-sm-12">
                <label style={{ fontSize: "14px" }} className="font-weight-normal">
                    Work title / Tajuk kerja <label className="font-danger m-0">*</label>
                </label>
                <input
                    required
                    name="title"
                    type="text"
                    className={inputStyle}
                    placeholder="Work title / Tajuk kerja"
                    value={billWorkData.title}
                    onChange={handleInputChange}
                />
            </div>
            <div className="col-lg-12 col-sm-12">
                <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                    Work description / Keterangan kerja <label className="font-danger m-0">*</label>
                </label>
                <textarea
                    required
                    name="description"
                    type="text"
                    className={inputStyle}
                    placeholder="Work description / Keterangan kerja"
                    value={billWorkData.description}
                    onChange={handleInputChange}
                />
            </div>
            <div className="col-lg-6 col-sm-12">
                <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                    Outline agreement no <label className="font-danger m-0">*</label>
                </label>
                <input
                    required
                    name="agreementNo"
                    type="text"
                    className={inputStyle}
                    placeholder="Outline agreement no"
                    value={billWorkData.agreementNo}
                    onChange={handleInputChange}
                />
            </div>
            <div className="col-lg-6 col-sm-12">
                <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                    Job num / No job <label className="font-danger m-0">*</label>
                </label>
                <input
                    required
                    name="jobNum"
                    type="text"
                    className={inputStyle}
                    placeholder="Job num / No job"
                    value={billWorkData.jobNum}
                    onChange={handleInputChange}
                />
            </div>
            <div className="col-lg-6 col-sm-12">
                <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                    Work order (CMMS) / Perintah kerja <label className="font-danger m-0">*</label>
                </label>
                <input
                    required
                    name="order"
                    type="text"
                    className={inputStyle}
                    placeholder="Work order (CMMS) / Perintah kerja"
                    value={billWorkData.order}
                    onChange={handleInputChange}
                />
            </div>
            <div className="col-lg-6 col-sm-12">
                <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                    Work order type <label className="font-danger m-0">*</label>
                </label>
                <input
                    required
                    name="orderType"
                    type="text"
                    className={inputStyle}
                    placeholder="Work order type"
                    value={billWorkData.orderType}
                    onChange={handleInputChange}
                />
            </div>
            <div className="col-lg-6 col-sm-12">
                <label className="font-weight-normal" style={{ fontSize: "14px" }}>
                    Location / Lokasi <label className="font-danger m-0">*</label>
                </label>
                <input
                    required
                    name="location"
                    type="text"
                    className={inputStyle}
                    placeholder="Location / Lokasi"
                    value={billWorkData.location}
                    onChange={handleInputChange}
                />
            </div>
        </section>
    )
}

export default WorkData