import React from 'react';
import { useSelector } from 'react-redux';




/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable  jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */



export default function AddGroup({handleClose, addNewGroup , handelGroupName, handelColor , isError}) {
    const {color , groupName} = useSelector((state) => state.assetsMapping)
  return (
    <div className="modal" data-backdrop="false" tabIndex="1" role="dialog" 
    style={{top: "10% !important" , display:'block'}} id="grouping-modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Group Assets</h4>
            <button type="button" className="close" data-dismiss="modal"  onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group"  id="create_and_assign_group">
              <h4>Create and assign a group:</h4>
            <form id="create_group_form">
                {isError && <p style={{color : "red"}}>Please Add Group Name*</p>}
              <div className="col-lg-12 col-sm-12">
                <label className="font-weight-normal">Group Name <span className="font-danger m-0">*</span></label>
                <input name='group_name' onChange={handelGroupName} 
                value={groupName}
                type="text" className="form-control mb-2" placeholder="Group Name" required/>
              </div>
              <div className=" col-lg-12 col-sm-12">
                <label className="font-weight-normal">Group Color</label>
                <div className="offset-md-4  col-lg-3 col-sm-3">
                <input type="color" onChange={handelColor} className="form-control mb-2" name="group_color" value={color || "#ff0000"}/>
                </div>
              </div>
            </form>
          </div>

          </div>
          <div className="modal-footer float-right">
                <button type="button" className="btn primary-btn" onClick={addNewGroup} >
                    <span className="button__text" >Save</span>
                </button>
              <button type="button" className="btn secondary-btn" data-dismiss="modal" onClick={handleClose}>Cancel</button>
              </div>
            </div>
          </div>
      </div>
  );
}