import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getSLAsByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/service-level-agreement/`);
  return assert(res, res.data, 'Retrieval of SLA by its company ID failed', res);
};

const getSLAbyId = async SLA => {
  const res = await http.get(`${BASE_URL}/service-level-agreement/${SLA}`);
  return assert(res, res.data, 'Retrieval of SLA by its ID failed', res);
};

const createSLA = async data => {
  const res = await http.post(`${BASE_URL}/service-level-agreement/`, data);
  return assert(res, res.data, 'Creation of SLA failed', res);
};

const updateSLA = async data => {
  const res = await http.put(`${BASE_URL}/service-level-agreement/${data.id}/`, data);
  return assert(res, res.data, 'Update of SLA failed', res);
};

const deleteSLA = async SLA => {
  const res = await http.delete(`${BASE_URL}/service-level-agreement/${SLA}/`);
  return assert(res, SLA, 'Deletion of SLA failed', res);
};

const SLAservice = {
  getSLAsByAuthentication,
  getSLAbyId,
  createSLA,
  updateSLA,
  deleteSLA,
};

export default SLAservice;
