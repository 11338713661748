import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import workOrderCategoryService from './workOrderCategoryService';
import { smartTryCatch } from '../../../utils';

const initState = {
  workOrderCategories: [],
  workOrderCategory: {},

  // fields
  workOrderCategoryName: '',

  // modal
  workOrderCategoryModalCreate: false,
  workOrderCategoryModalUpdate: false,
  workOrderCategoryModalDelete: false,

  // success
  isWorkOrderCategoryCreateSuccess: false,
  isWorkOrderCategoryGetSuccess: false,
  isWorkOrderCategoryDeleteSuccess: false,
  isWorkOrderCategoryUpdateSuccess: false,

  // loading
  isWorkOrderCategoryCreateLoading: false,
  isWorkOrderCategoryGetLoading: false,
  isWorkOrderCategoryDeleteLoading: false,
  isWorkOrderCategoryUpdateLoading: false,

  // error
  isWorkOrderCategoryCreateError: false,
  isWorkOrderCategoryGetError: false,
  isWorkOrderCategoryDeleteError: false,
  isWorkOrderCategoryUpdateError: false,
};

export const createWorkOrderCategories = createAsyncThunk(
  'createWorkOrderCategories',
  async (data, thunkAPI) => {
    const createWO = await smartTryCatch(
      workOrderCategoryService.createWorkOrderCategory,
      data,
      thunkAPI,
    );
    return createWO;
  },
);

export const getWorkOrderCategoryByAuthentication = createAsyncThunk(
  'getWorkOrderCategoryByAuthentication',
  async (data, thunkAPI) => {
    const getWo = await smartTryCatch(
      workOrderCategoryService.getWorkOrderCategoryByAuthentication,
      data,
      thunkAPI,
    );
    return getWo;
  },
);

export const getWorkOrderCategoriesById = createAsyncThunk(
  'getWorkOrderCategoriesById',
  async (data, thunkAPI) => {
    const getWo = await smartTryCatch(
      workOrderCategoryService.getWorkOrderCategoryById,
      data,
      thunkAPI,
    );
    return getWo;
  },
);

export const deleteWorkOrderCategories = createAsyncThunk(
  'deleteWorkOrderCategories',
  async (data, thunkAPI) => {
    const deleteWo = await smartTryCatch(
      workOrderCategoryService.deleteWorkOrderCategory,
      data,
      thunkAPI,
    );
    return deleteWo;
  },
);

export const updateWorkOrderCategories = createAsyncThunk(
  'updateWorkOrderCategories',
  async (data, thunkAPI) => {
    const update = await smartTryCatch(
      workOrderCategoryService.updateWorkOrderCategory,
      data,
      thunkAPI,
    );
    return update;
  },
);

export const workOrderCategorySlice = createSlice({
  name: 'workOrderCategory',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isWorkOrderCategoryCreateError = false;
      state.isWorkOrderCategoryCreateSuccess = false;
      state.isWorkOrderCategoryCreateLoading = false;
      state.isWorkOrderCategoryGetError = false;
      state.isWorkOrderCategoryGetSuccess = false;
      state.isWorkOrderCategoryGetLoading = false;
      state.isWorkOrderCategoryDeleteError = false;
      state.isWorkOrderCategoryDeleteSuccess = false;
      state.isWorkOrderCategoryDeleteLoading = false;
      state.isWorkOrderCategoryUpdateError = false;
      state.isWorkOrderCategoryUpdateSuccess = false;
      state.isWorkOrderCategoryUpdateLoading = false;
      // state.workOrderCategory = null;
    },
    openWorkOrderCategoryModalCreate: (state) => {
      state.workOrderCategoryModalCreate = true;
    },
    closeWorkOrderCategoryModalCreate: (state) => {
      state.workOrderCategoryModalCreate = false;
    },
    openWorkOrderCategoryModalUpdate: (state) => {
      state.workOrderCategoryModalUpdate = true;
    },
    closeWorkOrderCategoryModalUpdate: (state) => {
      state.workOrderCategoryModalUpdate = false;
    },
    openWorkOrderCategoryModalDelete: (state) => {
      state.workOrderCategoryModalDelete = true;
    },
    closeWorkOrderCategoryModalDelete: (state) => {
      state.workOrderCategoryModalDelete = false;
    },
    setWorkOrderCategory: (state, action) => {
      state.workOrderCategory = action.payload;
    },
    setWorkOrderCategoryName: (state, action) => {
      state.workOrderCategoryName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkOrderCategoryByAuthentication.pending, (state) => {
        state.isWorkOrderCategoryGetLoading = true;
        state.isWorkOrderCategoryGetSuccess = false;
        state.isWorkOrderCategoryGetError = false;
      })
      .addCase(getWorkOrderCategoryByAuthentication.fulfilled, (state, action) => {
        state.isWorkOrderCategoryGetLoading = false;
        state.isWorkOrderCategoryGetSuccess = true;
        state.isWorkOrderCategoryGetError = false;
        state.workOrderCategories=action.payload;
      })
      .addCase(getWorkOrderCategoryByAuthentication.rejected, (state) => {
        state.isWorkOrderCategoryGetLoading = false;
        state.isWorkOrderCategoryGetSuccess = false;
        state.isWorkOrderCategoryGetError = true;
      })
      .addCase(getWorkOrderCategoriesById.pending, (state) => {
        state.isWorkOrderCategoryGetLoading = true;
        state.isWorkOrderCategoryGetSuccess = false;
        state.isWorkOrderCategoryGetError = false;
      })
      .addCase(getWorkOrderCategoriesById.fulfilled, (state, action) => {
        state.isWorkOrderCategoryGetLoading = false;
        state.isWorkOrderCategoryGetSuccess = true;
        state.isWorkOrderCategoryGetError = false;
        state.workOrderCategory = action.payload;
      })
      .addCase(getWorkOrderCategoriesById.rejected, (state) => {
        state.isWorkOrderCategoryGetLoading = false;
        state.isWorkOrderCategoryGetSuccess = false;
        state.isWorkOrderCategoryGetError = true;
      })
      .addCase(createWorkOrderCategories.pending, (state) => {
        state.isWorkOrderCategoryCreateLoading = true;
        state.isWorkOrderCategoryCreateSuccess = false;
        state.isWorkOrderCategoryCreateError = false;
      })
      .addCase(createWorkOrderCategories.fulfilled, (state, action) => {
        state.isWorkOrderCategoryCreateLoading = false;
        state.isWorkOrderCategoryCreateSuccess = true;
        state.isWorkOrderCategoryCreateError = false;
        state.workOrderCategories.push(action.payload);
      })
      .addCase(createWorkOrderCategories.rejected, (state) => {
        state.isWorkOrderCategoryCreateLoading = false;
        state.isWorkOrderCategoryCreateSuccess = false;
        state.isWorkOrderCategoryCreateError = true;
      })
      .addCase(deleteWorkOrderCategories.pending, (state) => {
        state.isWorkOrderCategoryDeleteLoading = true;
        state.isWorkOrderCategoryDeleteSuccess = false;
        state.isWorkOrderCategoryDeleteError = false;
      })
      .addCase(deleteWorkOrderCategories.fulfilled, (state, action) => {
        state.isWorkOrderCategoryDeleteLoading = false;
        state.isWorkOrderCategoryDeleteSuccess = true;
        state.isWorkOrderCategoryDeleteError = false;
        // state.workOrderCategories = action.payload;
        state.workOrderCategories = state.workOrderCategories.filter(
          (workOrderCategory) => workOrderCategory.id !== action.payload,
        );
      })
      .addCase(deleteWorkOrderCategories.rejected, (state) => {
        state.isWorkOrderCategoryDeleteLoading = false;
        state.isWorkOrderCategoryDeleteSuccess = false;
        state.isWorkOrderCategoryDeleteError = true;
      })
      .addCase(updateWorkOrderCategories.pending, (state) => {
        state.isWorkOrderCategoryUpdateLoading = true;
        state.isWorkOrderCategoryUpdateSuccess = false;
        state.isWorkOrderCategoryUpdateError = false;
      })
      .addCase(updateWorkOrderCategories.fulfilled, (state, action) => {
        state.isWorkOrderCategoryUpdateLoading = false;
        state.isWorkOrderCategoryUpdateSuccess = true;
        state.isWorkOrderCategoryUpdateError = false;
        state.workOrderCategories = state.workOrderCategories.map((workOrderCategory) =>
        workOrderCategory.id === action.payload.id ? action.payload : workOrderCategory,
      );
      })
      .addCase(updateWorkOrderCategories.rejected, (state) => {
        state.isWorkOrderCategoryUpdateLoading = false;
        state.isWorkOrderCategoryUpdateSuccess = false;
        state.isWorkOrderCategoryUpdateError = true;
      });
  },
});

export const {
  reset,
  openWorkOrderCategoryModalCreate,
  closeWorkOrderCategoryModalCreate,
  openWorkOrderCategoryModalUpdate,
  closeWorkOrderCategoryModalUpdate,
  openWorkOrderCategoryModalDelete,
  closeWorkOrderCategoryModalDelete,
  setWorkOrderCategory,
  setWorkOrderCategoryName,
} = workOrderCategorySlice.actions;

export default workOrderCategorySlice.reducer;
