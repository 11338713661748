import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getMeterCategoryByAuthentication = async () => {
  const res = await http.get(
    `${BASE_URL}/meter-category/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of meterCategory by its company ID failed",
    res
  );
};

const getMeterCategoryById = async (meterCategoryId) => {
  const res = await http.get(
    `${BASE_URL}/meter-category/${meterCategoryId}`
  );

  return assert(
    res,
    res.data,
    "Retrieval of meterCategory by its ID failed",
    res
  );
};

const createMeterCategory = async (data) => {
  const res = await http.post(
    `${BASE_URL}/meter-category/`,
    data
  );

  return assert(res, res.data, "Creation of meterCategory failed", res);
};

const updateMeterCategory = async (data) => {
  const res = await http.put(
    `${BASE_URL}/meter-category/${data.id}/`,
    data
  );
  return assert(res, res.data, "Update of meterCategory failed", res);
};

const deleteMeterCategory = async (meterCategoryId) => {
  const res = await http.delete(
    `${BASE_URL}/meter-category/${meterCategoryId}/`
  );
  return assert(res, meterCategoryId, "Deletion of meterCategory failed", res);
};

const meterCategoryService = {
  getMeterCategoryByAuthentication,
  getMeterCategoryById,
  createMeterCategory,
  updateMeterCategory,
  deleteMeterCategory,
};

export default meterCategoryService;
