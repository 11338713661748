import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getLogsByAuthentication = async (id) => {
  const res = await http.get(`${BASE_URL}/ticket-timelog/?ticket_id=${id}`);

  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};


const getLogById = async (LogId) => {
  const res = await http.get(`${BASE_URL}/ticket-timelog/${LogId}/`);

  return assert(res, res.data, 'Retrieval of Log by its ID failed', res);
};

const createLog = async (payload) => {
  const res = await http.post(`${BASE_URL}/ticket-timelog/?ticket_id=${payload.id}`
  , payload.data);

  return assert(res, res.data, 'Creation of Log failed', res);
};

const deleteLog = async (LogId) => {
  const res = await http.delete(`${BASE_URL}/ticket-timelog/${LogId}/`);
  return assert(res, LogId, 'Deletion of Log failed', res);
};

const updateLog = async (LogData) => {
  const res = await http.put(
    `${BASE_URL}/ticket-timelog/${LogData.id}/`,
    LogData,
  );
  return assert(res, res.data, 'Update of Log failed', res);
};

const logService = {
  getLogsByAuthentication,
  getLogById,
  createLog,
  deleteLog,
  updateLog,
};

export default logService;
