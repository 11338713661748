import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton } from '@material-ui/core';
import PlusIcon from '../../assets/svg-icons/PlusIcon'
import MinusIcon from '../../assets/svg-icons/MinusIcon'
import { setBillData, setBillQty } from '../../redux/features/SOR/SORSlice'
import DeleteIcon from '../../assets/svg-icons/DeleteIcon'
import Tooltip from '../../components/tooltip/tooltip';

/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */


function BillItem({ item, pdf, index }) {
    const { billData } = useSelector(state => state.SOR)
    const dispatch = useDispatch()

    const handleQty = (type, typeOfOrder) => {
        dispatch(setBillQty({ type, item, typeOfOrder }))
    }
    const handleInputQTYChange = (e, typeOfOrder) => {
        dispatch(setBillQty({ type: "QTYInput", qty: e.target.value, item, typeOfOrder }))
    }
    const handleInputDiscountChange = (e) => {
        dispatch(setBillQty({ type: "DiscountInput", dis: e.target.value, item }))
    }
    const deleteItemHandler = () => {
        dispatch(dispatch(setBillData(billData.filter((_, i) => i !== index))))
    }

    return (
        <tr>
            <td>{item?.source}</td>
            <td>{item?.description}</td>
            <td>{item?.discount === "Y" ?
                (!pdf ? <input type="number" className='bill-item-discount' onChange={handleInputDiscountChange} value={item.discountRate} /> : <span>{item?.discountRate}%</span>) :
                (!pdf ? 
                <Tooltip input content="This field can't be edited" />
                : <span>0%</span>)}</td>
            <td>{item?.unit}</td>
            <td>RM{item?.rate}</td>
            <td style={!pdf ? {
                display: "flex",
                alignItems: "center",
                marginTop: "15px"
            } : {}}>
                <span className='mr-2 ml-2'>
                    {!pdf && <button type='button' className='new-btn' style={{ height: "36px" }} onClick={() => handleQty("dec", "client")}><MinusIcon /></button>}
                    {!pdf ? <input type="number" className='bill-item-discount' onChange={(e) => handleInputQTYChange(e, "client")}
                        value={item?.quantity} min="1" /> : <span>{item?.quantity}</span>}
                </span>
                {!pdf && <button type='button' className='new-btn' onClick={() => handleQty("inc", "client")}><PlusIcon /></button>}
            </td>
            <td>RM{(Number(item?.totalPrice) || 0)?.toFixed(2)}</td>
            <td style={!pdf ? {
                display: "flex",
                alignItems: "center",
                marginTop: "15px"
            } : {}}>
                <span className='mr-2 ml-2'>
                    {!pdf && <button type='button' className='new-btn' style={{ height: "36px" }} onClick={() => handleQty("dec", "vendor")}><MinusIcon /></button>}
                    {!pdf ? <input type="number" className='bill-item-discount' onChange={(e) => handleInputQTYChange(e, "vendor")}
                        value={item?.vendorQuantity} min="1" /> : <span>{item?.vendorQuantity}</span>}
                </span>
                {!pdf && <button type='button' className='new-btn' onClick={() => handleQty("inc", "vendor")}><PlusIcon /></button>}
            </td>
            <td>RM{(Number(item?.vendorTotalPrice) || 0)?.toFixed(2)}</td>
            <td>
            {!pdf &&<IconButton
                    style={{
                        backgroundColor: '#F8F9F9',
                        borderRadius: '8px',
                        border: '1px solid #E1E1E1',
                        padding: "9px"
                    }}
                    variant="contained"
                    title="Delete item"
                    className='outlined-button mr-2'
                    onClick={() => deleteItemHandler()}
                ><DeleteIcon />
                </IconButton>}
            </td>
        </tr>
    )
}

export default BillItem