

import React from 'react';

export default function IconCloseCross({color = 'black'}) {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 6L6 18M18 18L6 6.00001" stroke={color} strokeWidth="2" strokeLinecap="round"/>
    </svg>
  );
};
