import React from 'react';

export default function ExclamationIcon({ fillColor }) {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99967 11.1666V8.49992M7.99967 5.83325H8.00634M14.6663 8.49992C14.6663 12.1818 11.6816 15.1666 7.99967 15.1666C4.31778 15.1666 1.33301 12.1818 1.33301 8.49992C1.33301 4.81802 4.31778 1.83325 7.99967 1.83325C11.6816 1.83325 14.6663 4.81802 14.6663 8.49992Z"
                stroke={fillColor === 'primary' ? 'var(--primary-color)' : 'var(--grey-content)'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>
    );
}