/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sendIcon from '../../../../../assets/img/send-svg.svg';
import { createTicketComment, reset } from '../../../../../redux/features/ticketComment/ticketCommentSlice';
import SingleComment from './SingleComment';
import CommentInput from './CommentInput';

function CommentSection() {
    const dispatch = useDispatch();
    const { workOrder } = useSelector(state => state.workOrder)
    const { isTicketCommentCreateSuccess, ticketComments } = useSelector(state => state.ticketComment)
    const [comment, setComment] = useState("");

    const handleSubmitComment = async () => {
        const ids = comment.match(/\((\d+)\)/g)?.map(id => +id.replace(/\(|\)/g, ''));
        if (comment) {
            await dispatch(createTicketComment({
                id: workOrder?.id,
                description: comment,
                mentioned_users: ids
            }))
        }
    };

    useEffect(() => {
        if (isTicketCommentCreateSuccess) {
            setComment("")
            dispatch(reset())
        }
    }, [isTicketCommentCreateSuccess])

    

    return (
        <div className="comment-section">
            <div className="comment-section-add-comment">
                <CommentInput state={comment} setState={setComment} />
                {/* <span onClick={handleSubmitComment} className="comment-section-comment-icon"> {sendIcon} </span> */}
                <img src ={sendIcon} onClick={handleSubmitComment} className="comment-section-comment-icon" alt='send'/>  
            </div>
            {ticketComments?.length > 0 && ticketComments?.map((item, index )=> (
                <SingleComment key={item?.id} item={item} index={index} />
            ))}

            <div className="comment-section-show-all">All comments shown</div>
        </div>
    );
}


export default CommentSection;
