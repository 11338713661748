import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import meterCategoryService from './meterCategoryService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // data
  meterCategories: [],
  meterCategory: {},

  // fields
  meterCategoryName: '',

  // modal
  meterCategoryModalCreate: false,
  meterCategoryModalUpdate: false,
  meterCategoryModalDelete: false,

  // success
  isMeterCategoryCreateSuccess: false,
  isMeterCategoryGetSuccess: false,
  isMeterCategoryDeleteSuccess: false,
  isMeterCategoryUpdateSuccess: false,

  // loading
  isMeterCategoryCreateLoading: false,
  isMeterCategoryDeleteLoading: false,
  isMeterCategoryGetLoading: false,
  isMeterCategoryUpdateLoading: false,

  // error
  isMeterCategoryCreateError: false,
  isMeterCategoryGetError: false,
  isMeterCategoryDeleteError: false,
  isMeterCategoryUpdateError: false,
};

export const createMeterCategory = createAsyncThunk(
  'createMeterCategory',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      meterCategoryService.createMeterCategory,
      data,
      thunkAPI,
    );
    return res;
  }
);

export const getMeterCategoryByAuthentication = createAsyncThunk(
  'getMeterCategoryByAuthentication',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      meterCategoryService.getMeterCategoryByAuthentication,
      data,
      thunkAPI,
    );
    return res;
  }
);

export const getMeterCategoryById = createAsyncThunk(
  'getMeterCategoryById',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      meterCategoryService.getMeterCategoryById,
      data,
      thunkAPI,
    );
    return res;
  }
);

export const deleteMeterCategory = createAsyncThunk(
  'deleteMeterCategory',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      meterCategoryService.deleteMeterCategory,
      data,
      thunkAPI,
    );
    return res;
  }
);

export const updateMeterCategory = createAsyncThunk(
  'updateMeterCategory',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      meterCategoryService.updateMeterCategory,
      data,
      thunkAPI,
    );
    return res;
  }
);

export const meterCategorySlice = createSlice({
  name: 'meterCategory',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isMeterCategoryCreateError = false;
      state.isMeterCategoryCreateSuccess = false;
      state.isMeterCategoryCreateLoading = false;
      state.isMeterCategoryGetError = false;
      state.isMeterCategoryGetSuccess = false;
      state.isMeterCategoryGetLoading = false;
      state.isMeterCategoryDeleteError = false;
      state.isMeterCategoryDeleteSuccess = false;
      state.isMeterCategoryDeleteLoading = false;
      state.isMeterCategoryUpdateError = false;
      state.isMeterCategoryUpdateSuccess = false;
      state.isMeterCategoryUpdateLoading = false;
      state.meterCategory = null;
    },
    closeMeterCategoryModalCreate: (state) => {
      state.meterCategoryModalCreate = false;
    },
    openMeterCategoryModalCreate: (state) => {
      state.meterCategoryModalCreate = true;
    },
    closeMeterCategoryModalUpdate: (state) => {
      state.meterCategoryModalUpdate = false;
    },
    openMeterCategoryModalUpdate: (state) => {
      state.meterCategoryModalUpdate = true;
    },
    closeMeterCategoryModalDelete: (state) => {
      state.meterCategoryModalDelete = false;
    },
    openMeterCategoryModalDelete: (state) => {
      state.meterCategoryModalDelete = true;
    },
    setMeterCategory: (state, action) => {
      state.meterCategory = action.payload;
    },
    setMeterCategoryName: (state, action) => {
      state.meterCategoryName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMeterCategoryByAuthentication.pending, (state) => {
        state.isMeterCategoryGetLoading = true;
        state.isMeterCategoryGetSuccess = false;
        state.isMeterCategoryGetError = false;
      })
      .addCase(getMeterCategoryByAuthentication.fulfilled, (state, action) => {
        state.isMeterCategoryGetLoading = false;
        state.isMeterCategoryGetSuccess = true;
        state.isMeterCategoryGetError = false;
        state.meterCategories = action.payload;
      })
      .addCase(getMeterCategoryByAuthentication.rejected, (state) => {
        state.isMeterCategoryGetLoading = false;
        state.isMeterCategoryGetSuccess = false;
        state.isMeterCategoryGetError = true;
      })
      .addCase(getMeterCategoryById.pending, (state) => {
        state.isMeterCategoryGetLoading = true;
        state.isMeterCategoryGetSuccess = false;
        state.isMeterCategoryGetError = false;
      })
      .addCase(getMeterCategoryById.fulfilled, (state, action) => {
        state.isMeterCategoryGetLoading = false;
        state.isMeterCategoryGetSuccess = true;
        state.isMeterCategoryGetError = false;
        state.meterCategories = action.payload;
      })
      .addCase(getMeterCategoryById.rejected, (state) => {
        state.isMeterCategoryGetLoading = false;
        state.isMeterCategoryGetSuccess = false;
        state.isMeterCategoryGetError = true;
      })
      .addCase(createMeterCategory.pending, (state) => {
        state.isMeterCategoryCreateLoading = true;
        state.isMeterCategoryCreateSuccess = false;
        state.isMeterCategoryCreateError = false;
      })
      .addCase(createMeterCategory.fulfilled, (state, action) => {
        state.isMeterCategoryCreateLoading = false;
        state.isMeterCategoryCreateSuccess = true;
        state.isMeterCategoryCreateError = false;
        state.meterCategories.push(action.payload);
      })
      .addCase(createMeterCategory.rejected, (state) => {
        state.isMeterCategoryCreateLoading = false;
        state.isMeterCategoryCreateSuccess = false;
        state.isMeterCategoryCreateError = true;
      })
      .addCase(deleteMeterCategory.pending, (state) => {
        state.isMeterCategoryDeleteLoading = true;
        state.isMeterCategoryDeleteSuccess = false;
        state.isMeterCategoryDeleteError = false;
      })
      .addCase(deleteMeterCategory.fulfilled, (state, action) => {
        state.isMeterCategoryDeleteLoading = false;
        state.isMeterCategoryDeleteSuccess = true;
        state.isMeterCategoryDeleteError = false;
        state.meterCategories = state.meterCategories.filter(
          (meterCategory) => meterCategory.id !== action.payload,
        );
      })
      .addCase(deleteMeterCategory.rejected, (state) => {
        state.isMeterCategoryDeleteLoading = false;
        state.isMeterCategoryDeleteSuccess = false;
        state.isMeterCategoryDeleteError = true;
      })
      .addCase(updateMeterCategory.pending, (state) => {
        state.isMeterCategoryUpdateLoading = true;
        state.isMeterCategoryUpdateSuccess = false;
        state.isMeterCategoryUpdateError = false;
      })
      .addCase(updateMeterCategory.fulfilled, (state, action) => {
        state.isMeterCategoryUpdateLoading = false;
        state.isMeterCategoryUpdateSuccess = true;
        state.isMeterCategoryUpdateError = false;
        state.meterCategories = state.meterCategories.map((meterCategory) => (meterCategory.id === action.payload.id
          ? action.payload
          : meterCategory));
      })
      .addCase(updateMeterCategory.rejected, (state) => {
        state.isMeterCategoryUpdateLoading = false;
        state.isMeterCategoryUpdateSuccess = false;
        state.isMeterCategoryUpdateError = true;
      });
  },
});

export const {
  reset,
  closeMeterCategoryModalCreate,
  openMeterCategoryModalCreate,
  closeMeterCategoryModalUpdate,
  openMeterCategoryModalUpdate,
  closeMeterCategoryModalDelete,
  openMeterCategoryModalDelete,
  setMeterCategory,
  setMeterCategoryName,
} = meterCategorySlice.actions;

export default meterCategorySlice.reducer;
