/* eslint-disable import/no-unresolved */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-target-blank */

import React, { useState, useEffect, useRef } from 'react';
// import { BlobProvider } from '@react-pdf/renderer';
import { Drawer } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
// import { useReactToPrint } from 'react-to-print';
import { useHistory } from 'react-router-dom';
import {
  closeSubLocationModalUpdate,
  getSubLocationHistoryById,
  updateSubLocation,
  deleteSubLocation,
  openSubLocationModalDelete,
  closeSubLocationModalDelete,
} from '../../redux/features/location/locationSlice';
import SubLocationQrPrint from './subLocationQrPrint';
import PopUpDeleteModal from '../popUpModals/editCategory/popUpDeleteModal';
// import SubLocationQrPrint from './subLocationQrPrint';

export default function UpdateSiteDrawer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.company);
  const { showSubLocationModalUpdate , showSubLocationModalDelete } = useSelector(state => state.location);
  const [isLinkCopied, setLinkCopied] = useState(false);
  const [locationURl, setLocationURL] = useState('');
  const [show, setShow] = useState(showSubLocationModalUpdate);
  const [showmenu, setShowMenu] = useState(false);
  const { sublocation } = useSelector(state => state.location);
  const { user } = useSelector(state => state.user);
  const [subloc, setSubLoc] = useState({
    room: '',
    suite: '',
    level: '',
    building: '',
  });
  useEffect(() => {
    setShow(showSubLocationModalUpdate);
    dispatch(getSubLocationHistoryById(sublocation.id));
  }, [showSubLocationModalUpdate, sublocation.id]);
  useEffect(() => {
    setSubLoc({
      room: sublocation.room,
      suite: sublocation.suite,
      level: sublocation.level,
      building: sublocation.building,
    });
  }, [sublocation]);
  useEffect(() => {
    // Get the main URL (base URL)
    const mainUrl = window.location.origin;
    const uuid = sublocation?.qr_code?.split('/')[5]?.split('-qr')[0];
    // Do something with the main URL
    setLocationURL(`${mainUrl}/site-service/?uuid=${uuid}/`);
  }, [sublocation]);

  function handleUpdate(e) {
    e.preventDefault();
    const info = {
      id: sublocation.id,
      data: subloc,
    };

    dispatch(updateSubLocation(info));
    dispatch(closeSubLocationModalUpdate());
  }

  const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

    // Delete 
    const handelOpenDeletePopup = () => {
      dispatch(openSubLocationModalDelete())
      setShowMenu(!showmenu)
    };
  
    const handleDelete = () => {
      dispatch(deleteSubLocation(sublocation.id));
      dispatch(closeSubLocationModalUpdate());
        toast.success('Sub Location Deleted Successfully', {
          toastId: 'DeletedSubLocationError',
        });
        dispatch(closeSubLocationModalDelete())
    }

  let deleteLocationBtn = (
    <li role="presentation">
      <button
        type="button"
        data-toggle="modal"
        data-target="#modal-delete-part"
        title="Delete Spare part"
        className="font-danger"
        onClick={handelOpenDeletePopup}
      >
        Delete
      </button>
    </li>
  );
  

  let editDisabled = false;
  if (
    user?.role === 'Technical Team' ||
    (user?.role === 'Manager' && sublocation?.created_by?.role !== 'Manager')
  ) {
    deleteLocationBtn = '';
    editDisabled = true;
  }
  const goTo = `/sub-location-history/${sublocation.id}`;
  const handleLinkCopy = async () => {
    await navigator.clipboard.writeText(locationURl);
    setLinkCopied(true);
  };

  function displayToast() {
    if (isLinkCopied) {
      toast.success('Copied', {
        toastId: 'DeletedWorkOrderError',
      });
      setLinkCopied(false);
    }
  }
  return (
    <Drawer
      anchor="right"
      open={show}
      onClose={() => dispatch(closeSubLocationModalUpdate())}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '600px',
          height: '100%',
        },
      }}
    >
      {displayToast()}
      <form
        action="{{action}}"
        className="h-100 w-100"
        method="POST"
        onSubmit={handleUpdate}
      >
        <div className="navbar col-lg-12 m-0 modal-header-background page-profile-header pr-0">
          <div className="col-12 mb-2 row px-2">
            <div className="my-auto">
              <h5>
              Update Sublocation
              </h5>
            </div>
            <div className="row ml-auto">
              <div className="dropdown" onClick={()=> setShowMenu(!showmenu)}>
              {showmenu && <div className='tck-overlay' onClick={()=> setShowMenu(!showmenu)}></div>}
                <button
                  type='button' data-toggle="dropdown" id="dropdownMenu1" className="btn tertiary-btn ml-2 " title="View More"
                >
                  <div className="fas fa-ellipsis-h" />
                </button>
                <ul
                  className={`dropdown-menu ${showmenu && "dropdown-menu-active"}`} role="menu" aria-labelledby="dropdownMenu1"
                >
                  {deleteLocationBtn}
                  <li role="presentation">
                    <button
                      title="Location History"
                      type="button"
                      className="text-primary"
                      onClick={() => {
                        setShowMenu(false);
                        history.push(goTo);
                      }}
                    >
                      History
                    </button>
                    {/* <Link to={goTo}>History</Link> */}
                  </li>
                  <li>
                    <button type="button"
                    >  <SubLocationQrPrint location={sublocation} company={profile?.logo} /></button>
                  </li>
                </ul>
              </div>

              <button
                type="button"
                className="btn secondary-btn ml-2"
                data-dismiss="modal"
                onClick={() => dispatch(closeSubLocationModalUpdate())}
              >
                <div className="fas fa-times" />
              </button>
            </div>
          </div>
        </div>
        <div ref={componentRef}>
          <div className="text-center p-1" id="printThisLocation">
            <center>
              <img
                className="qr-print"
                src={sublocation.qr_code}
                id="location-qr-url"
                width="144"
                height="144"
                alt=""
              />
              <div className="qr-print"> {sublocation?.room}</div>
              <br />
            </center>
          </div>
        </div>
        <div className="col-lg-12 col-sm-12 row">
          <div className="col-12 text-center flex justify-content-center mt-3">
            <img
              className="icon img-hover text-center"
              height="120"
              width="120"
              alt="profile"
              src={sublocation?.qr_code}
            />
          </div>
          <div className="col-12 text-center flex justify-content-center">
            <button className="btn tertiary-btn" type="button" onClick={handleLinkCopy}>
              Copy link
            </button>
          </div>
        </div>
        <div className="col-12 pb-5">
          <div className="card-body row">
            <div className="col-lg-12 col-sm-12">
              <span className="font-weight-normal m-0 asset">Block(Optional)</span>
              <input
                name="spareparts"
                type="text"
                className="form-control mb-2"
                placeholder="e.g. D,3,III"
                id="spareparts_id"
                value={subloc.building}
                onChange={e => {
                  setSubLoc({ ...subloc, building: e.target.value });
                }}
                disabled={editDisabled}
              />
            </div>
            <div className="col-lg-12 col-sm-12">
              <span className="font-weight-normal m-0 asset">Level (Optional)</span>
              <input
                name="spareparts"
                type="text"
                className="form-control mb-2"
                placeholder="e.g Lg,G,123"
                id="spareparts_id"
                value={subloc.level}
                onChange={e => {
                  setSubLoc({ ...subloc, level: e.target.value });
                }}
                disabled={editDisabled}
              />
            </div>
            <div className="col-lg-12 col-sm-12">
              <span className="font-weight-normal m-0 asset">Suite (Optional)</span>
              <input
                name="spareparts"
                type="text"
                className="form-control mb-2"
                placeholder="e.g Lobby"
                id="spareparts_id"
                value={subloc.suite}
                onChange={e => {
                  setSubLoc({ ...subloc, suite: e.target.value });
                }}
                disabled={editDisabled}
              />
            </div>
            <div className="col-lg-12 col-sm-12">
              <span className="font-weight-normal row col-12">
                Room
                <div className="font-danger">*</div>
              </span>
              <input
                name="spareparts"
                type="text"
                className="form-control mb-2"
                required
                placeholder="e.g 218"
                id="spareparts_id"
                value={subloc.room}
                disabled={editDisabled}
                onChange={e => {
                  setSubLoc({ ...subloc, room: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="row  m-0 p-2 page-profile-footer">
          <button
            className="btn button-btn pmd-btn-fab primary-btn"
            // onClick="this.classList.toggle('button--loading')"
            type="submit"
            disabled={editDisabled}
          >
            <span className="button__text">Update</span>
          </button>
        </div>
      </form>
      {showSubLocationModalDelete && (
        <PopUpDeleteModal
          showModal={showSubLocationModalDelete}
          handleClose={() => dispatch(closeSubLocationModalDelete())}
          handleConfirm={handleDelete}
          modalTitle="Delete sub location"
          modalBodyText="Are you sure you want to delete this sub location?"
        />
      )}
    </Drawer>
  );
}
