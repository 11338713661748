import React from 'react';
import moment from 'moment';

// 
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

export default function BoardItem({ event, id, type, title, date, handleSelectedEvent, getFreQ, isMantenance }) {
  return (
    <div style={{ padding: '12px', backgroundColor: '#F8F9F9', borderRadius: '8px', fontSize: '12px', cursor: "pointer" }}
      onClick={() => handleSelectedEvent(event)}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <div style={{ color: '#757980' }}>{id}</div>
        {!isMantenance && <div style={{ color: '#757980' }}>{type === "workorder" ? "Work order" : type}</div>}
      </div>
      <div style={{ fontSize: '14px', fontWeight: '600', color: '#36383C' }}>{title}</div>
      <div style={{ marginTop: '18px', color: '#757980' }}>{moment.utc(date).format("DD MMM YYYY")}</div>
      {isMantenance && <div style={{ color: '#36383C' }}>{getFreQ(event).frequencyType}, {getFreQ(event).frqSentence}</div>}
    </div>
  );
}
