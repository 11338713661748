import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import assetStatusService from './assetStatusService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // data
  assetStatuses: [],
  assetStatus: null,

  // fields
  assetStatusName: '',

  // modal
  assetStatusModalDelete: false,
  assetStatusModalUpdate: false,
  assetStatusModalCreate: false,

  // success
  isAssetStatusCreateSuccess: false,
  isAssetStatusGetSuccess: false,
  isAssetStatusDeleteSuccess: false,
  isAssetStatusUpdateSuccess: false,

  // loading
  isAssetStatusCreateLoading: false,
  isAssetStatusDeleteLoading: false,
  isAssetStatusUpdateLoading: false,
  isAssetStatusGetLoading: false,

  // error
  isAssetStatusCreateError: false,
  isAssetStatusGetError: false,
  isAssetStatusDeleteError: false,
  isAssetStatusUpdateError: false,
};

export const getAssetStatusByAuthentication = createAsyncThunk(
  'getAssetStatusByAuthentication',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      assetStatusService.getAssetStatusByAuthentication,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const getAssetStatusById = createAsyncThunk('getAssetStatusById', async (data, thunkAPI) => {
  const res = await smartTryCatch(assetStatusService.getAssetStatusById, data, thunkAPI);
  return res;
});

export const createAssetStatus = createAsyncThunk('createAssetStatus', async (data, thunkAPI) => {
  const res = await smartTryCatch(assetStatusService.createAssetStatus, data, thunkAPI);
  return res;
});

export const deleteAssetStatus = createAsyncThunk('deleteAssetStatus', async (data, thunkAPI) => {
  const res = await smartTryCatch(assetStatusService.deleteAssetStatus, data, thunkAPI);
  return res;
});

export const updateAssetStatus = createAsyncThunk('updateAssetStatus', async (data, thunkAPI) => {
  const res = await smartTryCatch(assetStatusService.updateAssetStatus, data, thunkAPI);
  return res;
});

export const assetStatusSlice = createSlice({
  name: 'assetStatus',
  initialState: initState,
  reducers: {
    reset: state => {
      state.isAssetStatusCreateError = false;
      state.isAssetStatusCreateSuccess = false;
      state.isAssetStatusCreateLoading = false;
      state.isAssetStatusGetError = false;
      state.isAssetStatusGetSuccess = false;
      state.isAssetStatusGetLoading = false;
      state.isAssetStatusDeleteError = false;
      state.isAssetStatusDeleteSuccess = false;
      state.isAssetStatusDeleteLoading = false;
      state.isAssetStatusUpdateError = false;
      state.isAssetStatusUpdateSuccess = false;
      state.isAssetStatusUpdateLoading = false;
    },
    setAssetStatus: (state, action) => {
      state.assetStatus = action.payload;
    },
    openAssetStatusModalDelete: state => {
      state.assetStatusModalDelete = true;
    },
    closeAssetStatusModalDelete: state => {
      state.assetStatusModalDelete = false;
    },
    openAssetStatusModalUpdate: state => {
      state.assetStatusModalUpdate = true;
    },
    closeAssetStatusModalUpdate: state => {
      state.assetStatusModalUpdate = false;
    },
    openAssetStatusModalCreate: state => {
      state.assetStatusModalCreate = true;
    },
    closeAssetStatusModalCreate: state => {
      state.assetStatusModalCreate = false;
    },
    setAssetStatusName: (state, action) => {
      state.assetStatusName = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAssetStatusByAuthentication.pending, state => {
        state.isAssetStatusGetLoading = true;
        state.isAssetStatusGetSuccess = false;
        state.isAssetStatusGetError = false;
      })
      .addCase(getAssetStatusByAuthentication.fulfilled, (state, action) => {
        state.isAssetStatusGetLoading = false;
        state.isAssetStatusGetSuccess = true;
        state.isAssetStatusGetError = false;
        state.assetStatuses = action.payload;
      })
      .addCase(getAssetStatusByAuthentication.rejected, state => {
        state.isAssetStatusGetLoading = false;
        state.isAssetStatusGetSuccess = false;
        state.isAssetStatusGetError = true;
      })
      .addCase(getAssetStatusById.pending, state => {
        state.isAssetStatusGetLoading = true;
        state.isAssetStatusGetSuccess = false;
        state.isAssetStatusGetError = false;
      })
      .addCase(getAssetStatusById.fulfilled, (state, action) => {
        state.isAssetStatusGetLoading = false;
        state.isAssetStatusGetSuccess = true;
        state.isAssetStatusGetError = false;
        state.assetStatus = action.payload;
      })
      .addCase(getAssetStatusById.rejected, state => {
        state.isAssetStatusGetLoading = false;
        state.isAssetStatusGetSuccess = false;
        state.isAssetStatusGetError = true;
      })
      .addCase(createAssetStatus.pending, state => {
        state.isAssetStatusCreateLoading = true;
        state.isAssetStatusCreateSuccess = false;
        state.isAssetStatusCreateError = false;
      })
      .addCase(createAssetStatus.fulfilled, (state, action) => {
        state.isAssetStatusCreateLoading = false;
        state.isAssetStatusCreateSuccess = true;
        state.isAssetStatusCreateError = false;
        state.assetStatuses.push(action.payload);
      })
      .addCase(createAssetStatus.rejected, state => {
        state.isAssetStatusCreateLoading = false;
        state.isAssetStatusCreateSuccess = false;
        state.isAssetStatusCreateError = true;
      })
      .addCase(deleteAssetStatus.pending, state => {
        state.isAssetStatusDeleteLoading = true;
        state.isAssetStatusDeleteSuccess = false;
        state.isAssetStatusDeleteError = false;
      })
      .addCase(deleteAssetStatus.fulfilled, (state, action) => {
        state.isAssetStatusDeleteLoading = false;
        state.isAssetStatusDeleteSuccess = true;
        state.isAssetStatusDeleteError = false;
        state.assetStatuses = state.assetStatuses.filter(
          assetStatus => assetStatus.id !== action.payload,
        );
      })
      .addCase(deleteAssetStatus.rejected, state => {
        state.isAssetStatusDeleteLoading = false;
        state.isAssetStatusDeleteSuccess = false;
        state.isAssetStatusDeleteError = true;
      })
      .addCase(updateAssetStatus.pending, state => {
        state.isAssetStatusUpdateLoading = true;
        state.isAssetStatusUpdateSuccess = false;
        state.isAssetStatusUpdateError = false;
      })
      .addCase(updateAssetStatus.fulfilled, (state, action) => {
        state.isAssetStatusUpdateLoading = false;
        state.isAssetStatusUpdateSuccess = true;
        state.isAssetStatusUpdateError = false;
        state.assetStatuses = state.assetStatuses.map(assetStatus =>
          assetStatus.id === action.payload.id ? action.payload : assetStatus,
        );
      })
      .addCase(updateAssetStatus.rejected, state => {
        state.isAssetStatusUpdateLoading = false;
        state.isAssetStatusUpdateSuccess = false;
        state.isAssetStatusUpdateError = true;
      });
  },
});

export const {
  setAssetStatus,
  openAssetStatusModalDelete,
  closeAssetStatusModalDelete,
  openAssetStatusModalUpdate,
  closeAssetStatusModalUpdate,
  openAssetStatusModalCreate,
  closeAssetStatusModalCreate,
  reset,
} = assetStatusSlice.actions;

export default assetStatusSlice.reducer;
