/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import WorkOrderAnalytics from '../WordOdersAnalytics/WorkOrderAnalytics';
import getDataAnalyticPdf from '../../utils/getDataAnalyticPdf';
import AnalyticReportPdf from '../WordOdersAnalytics/AnalyticReportPdf/AnalyticReportPdf';
import { getAnalyticsForDashboard } from '../../redux/features/Analytics/analyticsSlice';
import AnalyticDashboard from '../WordOdersAnalytics/AnalyticDashboard';

export default function AnalyticsHeader () {
  const dispatch = useDispatch();
  const workOrderInfo = getDataAnalyticPdf();
  return (
    <div className="">
      {/* <div className="card-title">
        <BlobProvider
          document={<AnalyticReportPdf workOrderInfo={workOrderInfo} chartImage={chartImage} />}
        >
          {({ url, loading }) =>
            loading ? (
              'Loading Document....'
            ) : (
              <a href={url} target="_blank">
                <button style={{ color: 'black' }} type="button" className="btn btn-outline-danger">
                  Download PDF
                </button>
              </a>
            )
          }
        </BlobProvider>
      </div> */}
      <AnalyticDashboard/>
      {/* <WorkOrderAnalytics /> */}
    </div>
  );
}
