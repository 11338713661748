import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TicketNumberConfig from './referenceNumberService';
import { smartTryCatch } from '../../../utils';

const initState = {

  ticketNumberConfigs: [],


  // Running Number Format fields
  model: "",


  // success states
  isTicketNumberConfigUpdateSuccess: false,


  // loading states
  isTicketNumberConfigUpdateLoading: false,


  // error states
  isTicketNumberConfigUpdateError: false,

};



export const getTicketNumberConfigs = createAsyncThunk(
  'getTicketNumberConfigs',
  async (data, thunkAPI) => {
    const getTicketNumberConfig = await smartTryCatch(
      TicketNumberConfig.getTicketNumberConfigs,
      data,
      thunkAPI,
    );
    return getTicketNumberConfig;
  },
);



export const updateTicketNumberConfigs = createAsyncThunk('updateTicketNumberConfigs', async (data, thunkAPI) => {
  const updateLogRes = await smartTryCatch(TicketNumberConfig.updateTicketNumberConfigs, data, thunkAPI);
  return updateLogRes;
});


export const ticketNumberConfig = createSlice({
  name: 'ticketNumberConfig',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isTicketNumberConfigUpdateLoading = false;
        state.isTicketNumberConfigUpdateSuccess = false;
        state.isTicketNumberConfigUpdateError = false;
      // state.Log = null;
    },
    setModel: (state, action) => {
      state.model = action.payload;
    },
    setTicketNumberConfigs: (state, action) => {
      state.ticketNumberConfigs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTicketNumberConfigs.fulfilled, (state, action) => {
        state.ticketNumberConfigs = action.payload;
    })
      .addCase(updateTicketNumberConfigs.pending, (state) => {
        state.isTicketNumberConfigUpdateLoading = true;
        state.isTicketNumberConfigUpdateSuccess = false;
        state.isTicketNumberConfigUpdateError = false;
      })
      .addCase(updateTicketNumberConfigs.fulfilled, (state) => {
        state.isTicketNumberConfigUpdateLoading = false;
        state.isTicketNumberConfigUpdateSuccess = true;
        state.isTicketNumberConfigUpdateError = false;
      })
      .addCase(updateTicketNumberConfigs.rejected, (state) => {
        state.isTicketNumberConfigUpdateLoading = false;
        state.isTicketNumberConfigUpdateSuccess = false;
        state.isTicketNumberConfigUpdateError = true;
      })

  },
});

export const {
  reset,
  setModel,
  setTicketNumberConfigs,
} = ticketNumberConfig.actions;

export default ticketNumberConfig.reducer;