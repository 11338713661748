/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/order */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import ReloadIcon from '../../../../assets/svg-icons/ReloadIcon';
import DateRangeFilter from '../../../viewMaintenance/Componets/DateRangeFilter';
import moment from 'moment';
import { ButtonBase, Modal } from '@material-ui/core';
import {
  SelectLocationFilter,
  SelectStaff,
  SelectSublocationFilter,
} from '../../../../components/select/select';
import {
  setPMFilterSite,
  setPMFilterSublocation,
} from '../../../../redux/features/maintenance/maintenanceSlice';
import {
  setTasksBoardSite,
  setTasksBoardSublocation,
  setTasksBoardStaff,
  setServicesType,
  setTaskDateFilterState,
  setTaskFilterStartDate,
  setTaskFilterEndDate,
  getTaskPlannerBoard,
} from '../../../../redux/features/dashboard/dashboardSlice';
import { endOfWeek, startOfWeek } from 'date-fns';

export default function TaskBoardFilter({ selectedMenu, filterTaskBoard }) {
  const dispatch = useDispatch();

  // BEGIN date period things
  const [show, setShow] = useState(false);
  const {
    tasksBoardSite,
    tasksBoardSublocation,
    tasksBoardStaff,
    servicesType,
    taskDateFilterState,
    taskFilterStartDate,
    taskFilterEndDate
  } = useSelector((state) => state.dashboard);
  const { teams } = useSelector((state) => state.teams);

  const handleUpdate = async () => {
    setShow(false);
    dispatch(setTaskFilterStartDate(taskDateFilterState?.find((item) => item.startDate)?.startDate));
    dispatch(setTaskFilterEndDate(taskDateFilterState?.find((item) => item.endDate)?.endDate));
    await filterTaskBoard()
  };
  const handleClose = () => {
    setShow(false);
  };
  // END date period things

  // BEGIN Advance filter dialog
  const [openAdvFilter, setOpenAdvFilter] = useState(false);

  const handleOpenAdvFilter = () => {
    setOpenAdvFilter(true);
  };

  const handleCloseAdvFilter = () => {
    setOpenAdvFilter(false);
  };
  // END Advance filter dialog

  // BEGIN Inside advance filter
  const [locationsData, setLocations] = useState([]);
  const sublocations = useSelector((state) => state.location?.sublocations?.results);
  const { locations } = useSelector((state) => state.location);

  const resetState = () => {
    dispatch(setTasksBoardSite(null))
    dispatch(setTasksBoardSublocation(null))
    dispatch(setTasksBoardStaff(null))
  };


  const handleReset = async () => {
    dispatch(setServicesType(""))
    dispatch(setTaskDateFilterState(
      [
        {
          startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
          endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
          key: 'selection',
        },
      ]
    ))
    dispatch(setTaskFilterStartDate(moment.utc(new Date()).startOf("isoWeek").format("YYYY-MM-DD")))
    dispatch(setTaskFilterEndDate(moment.utc(new Date()).endOf("isoWeek").format("YYYY-MM-DD")))

    const filters = {
      startDate: moment.utc(new Date()).startOf("isoWeek").format("YYYY-MM-DD"),
      endDate: moment.utc(new Date()).endOf("isoWeek").format("YYYY-MM-DD"),
      feature: '',
      site: tasksBoardSite?.value || null,
      sublocation: tasksBoardSublocation?.value || null,
      staff: tasksBoardStaff?.value || null
  }
  await dispatch(getTaskPlannerBoard(filters));
  }


  const handleEraserFilterClick = () => {
    setLocations([]);
    resetState(); // reset the state and store
  };
  const handleActiveAdvFilter = async () => {
    handleCloseAdvFilter();
    await filterTaskBoard()
  };

  useEffect(() => {
    setLocations(sublocations || [])
  }, [sublocations])
  // END Inside advance filter

  const buttonStyle = {
    padding: '8px 16px',
    fontSize: '14px',
    fontWeight: '600',
    color: '#36383C',
    backgroundColor: '#F8F9F9',
    border: '1px solid #E1E1E1',
    borderRadius: '8px',
  };

  const buttonDisabledStyle = {
    padding: '8px 16px',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    backgroundColor: '#D9314A',
    borderRadius: '8px',
  };

  return (
    <div style={{ margin: '16px 16px 0 16px', display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', gap: '16px' }}>
        {selectedMenu === 'task_planner' && (
          <div style={{ width: '222px' }}>
            {/* <label style={{ color: '#d63c3c', fontWeight: 500 }}>Location </label> */}
            <label style={{ color: '#36383C', fontWeight: 500 }}>Services type </label>
            <select
              // className="form-control select-dash custom-select2 mb-2"
              className="planner_select_mode"
              name="planner_mode"
              id="planner_mode"
              style={{ width: '100%' }}
              onChange={(e) => dispatch(setServicesType(e.target.value))}
              value={servicesType}
            >
              <option value="" selected>
                All services
              </option>
              {[{ value: "workorder", label: "Work order" },
              // { value: "meter", label: "Meter" },
              // { value: "event", label: "Event" },
              ].map((val) => (
                <option key={val.value} value={val.value}>
                  {val.label}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="filters-left-wrapper-period" style={{ width: '222px' }}>
          <label style={{ color: '#36383C', fontWeight: 500 }}>Period </label>
          <div
            className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md "
            style={{ fontSize: '14px', cursor: 'pointer' }}
            onClick={() => setShow(!show)}
          >
            {taskFilterStartDate ? (
              <>
                <span>{moment(taskFilterStartDate).format('DD MMM YYYY')}</span> -{' '}
                <span>{moment(taskFilterEndDate).format('DD MMM YYYY')}</span>{' '}
              </>
            ) : (
              'All time'
            )}
          </div>
          {show && (
            <>
              <div className="tck-overlay" onClick={() => setShow(!show)} />
              <DateRangeFilter
                show={show}
                handleUpdate={handleUpdate}
                handleClose={handleClose}
                state={taskDateFilterState}
                setState={setTaskDateFilterState}
              />
            </>
          )}
        </div>
        <button
          type="button"
          style={{ alignSelf: 'flex-end' }}
          className="new-btn btn"
          title="Reset"
          onClick={handleReset}
        >
          <ReloadIcon />
        </button>
      </div>
      <div style={{ alignSelf: 'end' }}>
        <Button
          variant="contained"
          style={{
            backgroundColor: '#36383C',
            color: '#F8F9F9',
            fontSize: '14px',
            fontWeight: '600',
            padding: '8px 16px',
            borderRadius: '8px',
          }}
          onClick={handleOpenAdvFilter}
          className="adv-button"
        >
          Advance filter
        </Button>
      </div>
      <Modal
        open={openAdvFilter}
        onClose={handleCloseAdvFilter}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '480px',
            padding: '12px 16px',
            backgroundColor: '#F8F9F9',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              fontWeight: '600',
              fontSize: '21px',
              marginBottom: '20px',
            }}
          >
            Advance filter
          </div>
          <div
            className="wo-filter-wrapper"
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            <div className="filter-wrapper">
              <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Assigned to </label>
              <SelectStaff staff={tasksBoardStaff} setStaff={setTasksBoardStaff} teams={teams?.results} />
            </div>
            <div className="filter-wrapper" style={{ width: '100%', display: 'flex', gap: '16px' }}>
              <div style={{ flex: 1 }}>
                <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>
                  Location
                </label>
                <SelectLocationFilter
                  locations={locations}
                  site={tasksBoardSite}
                  setFilterSite={setTasksBoardSite}
                  setFilterSublocation={setTasksBoardSublocation}
                  setLocations={setLocations}
                />
              </div>
              <div style={{ flex: 1 }}>
                <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>
                  Sub location
                </label>
                <SelectSublocationFilter
                  sublocationsData={locationsData}
                  sublocation={tasksBoardSublocation}
                  setFilterSublocation={setTasksBoardSublocation}
                  site={tasksBoardSite}
                />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
            <ButtonBase autoCapitalize="none" onClick={handleEraserFilterClick} style={buttonStyle}>
              Reset
            </ButtonBase>
            <div style={{ display: 'flex', gap: '12px' }}>
              <ButtonBase onClick={handleCloseAdvFilter} style={buttonStyle}>
                Close
              </ButtonBase>
              <ButtonBase onClick={handleActiveAdvFilter} style={buttonDisabledStyle}>
                Activate
              </ButtonBase>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
