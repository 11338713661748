import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus , faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Card from "../../../../Ui/Card"
import classes from "./assetForm.module.css"
import AddModal from '../../../../components/modals/AddModal';
/* eslint-disable array-callback-return */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */

function AssetDocumentsForm({
  handelChangeDocumentsFormFile,
  handelChangeDocumentsFormFileType , handleCreateSaveAttach, attachTable, showModal,
  setShowModal , deleteAttach }) {

  const handleClose = ()=>{
    setShowModal(false)
  }

  return (
    <div className={classes.assetForm}>
       <Card> 
        <div className={classes.assetInfo}>
      <div className={classes.titleAssetDocuments}>
      <h3>Documents</h3>
      <button onClick={()=> setShowModal(true)}  className='primary-btn btn' type='button'>
      <FontAwesomeIcon icon={faPlus} />
      </button>
      </div>
      <div className={classes.scrollX}>
      <table className={classes.table}>
        <thead>
          <tr>
            <td>File</td>
            <td>File Type	</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {attachTable?.map((row ,idx) =>{
          return  <tr key={idx}>
            <td>{row.assetDocumentsFormFile?.name}</td>
            <td>{row.assetDocumentsFormFileType}	</td>
             <td > <button onClick={()=>deleteAttach(idx)} type='button' className='tertiary-btn'>
              <FontAwesomeIcon icon={faTrashCan} />
            </button> 
            </td>
          </tr>
          })}
        </tbody>
      </table>
      </div>
      </div> 
      </Card>
      <AddModal modalTitle = "Add Attachment"
              showModal= {showModal}
              handleClose ={handleClose}
              handleCreateSave ={handleCreateSaveAttach}
              modelToDo = "Upload">
      <div style={{marginBottom: 20}}>
      <p style={{marginBottom: 10}}>Select attachment (Max upload size is 5MB)</p>
      <input type="file"  onChange={handelChangeDocumentsFormFile}  
       accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
        </div>  
        <div>
      <p style={{marginBottom: 10}}>Type</p>
      <select onChange={handelChangeDocumentsFormFileType} >
       { ["Manual" , "Guide" , "Warranty", "Receipt"].map(opt=>(
        <option key={opt} value={opt}>{opt}</option>
       ))}
      </select>
        </div>  
      </AddModal>
    </div>
  )
}

export default AssetDocumentsForm



