/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable */

// import React from 'react';
// import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
// // import imageURL from '../../../assets/img/qr_code.png';
// // import companyLogo from '../../../assets/img/companyLogo.png'

// const styles = StyleSheet.create({
//   detailsText: {
//     textAlign: 'left',
//     fontSize: '9px',
//     // fontFamily: 'Open Sans, sans-serif',
//     fontWeight: '500',
//   },
// });

// const formateDate = date => {
//   const currentDate = new Date(date);
//   const result = currentDate.getFullYear();
//   return result;
// };

// const formateAssetName = (name) =>{
//   if(name.length>=40){
//     return `${name.slice(0,37)}...`
//   }
//     return name;
  
// }


// const PrintAssetQr = ({ asset, company }) => {
//   return (
//     <Document>
//       <Page size={{ width: 227, height: 113 }}>
//         <View
//           style={{
//             flexDirection: 'row',
//             justifyContent: 'space-between',
//           }}
//         >
//           <View>
//             <Image
//               style={{ width: '100px', height: '105px' }}
//               src={`${asset?.qr_code}?noChache=shimon9696`}
//               source={{
//                 header: {
//                   'Access-Control-Allow-Origin': '*',
//                 },
//               }}
//               alt="Qr code"
//             />
//           </View>
//           <View style={{ paddingLeft: '3px', paddingRight: '3px', width: 110 }}>
//             <View
//               style={{
//                 flexDirection: 'row',
//                 justifyContent: 'center',
//               }}
//             >
//               {/* <Image src="https://foxasset-development.s3.amazonaws.com/media/company_logos/example.png?noChache=shimon969696" source={{
//               header:{
//                 'Access-Control-Allow-Origin': '*'
//               }
//             }} /> */}
//               <Image
//                 style={{ width: '70px', height: '43px', marginTop: '-4px' }}
//                 src={`${company}?noChache=shimon969696`}
//                 source={{
//                   header: {
//                     'Access-Control-Allow-Origin': '*',
//                   },
//                 }}
//                 alt="Company logo"
//               />
//             </View>
//             <View>
//               <Text style={styles.detailsText}>ASSET:{asset?.name?formateAssetName(asset?.name) : "--"}</Text>
//               <Text style={styles.detailsText}>ID:{asset?.asset_number}</Text>
//               <Text style={styles.detailsText}>
//                 YEAR:
//                 {asset?.finance ? formateDate(asset?.finance?.purchased_date?.split('T')[0]) : '--'}
//               </Text>
//               <Text style={styles.detailsText}>
//                 LOC:{asset?.location ? asset?.location?.site : '--'}
//               </Text>
//             </View>
//           </View>
//         </View>
//       </Page>
//     </Document>
//   );
// };

// export default PrintAssetQr;
import React from 'react';
import { jsPDF } from "jspdf";
import imageSample from '../../../assets/img/inventory_qr_code.png'
import companyLogo from '../../../assets/img/download (1).png'

const formateDate = date => {
  const currentDate = new Date(date);
  const result = currentDate.getFullYear();
  return result;
};

const formateAssetName = name => {
  if (name.length >= 15) {
    return `${name.slice(0, 15)}...`;
  }
  return name;
};
const printAssetQr = ({ asset, company }) => {
    const generatePDF = () => {
        const pdf = new jsPDF({
          orientation: 'landscape', // or 'landscape'
          unit: 'pt',
          format: [245,140]// specify your custom page size in points
        });
    
        // assets.forEach((asset,index) => {
          pdf.setFontSize(12);
          // const companyLogoUrl = companyLogo;
          const companyLogoUrl = `${company}?noChache=shimon969696`;
          pdf.addImage(companyLogoUrl, 'PNG', 110,10,50,50);
          
          // const qrCodeDataUrl = imageSample;
          const qrCodeDataUrl = `${asset?.qr_code}?noChache=shimon9696`;
          pdf.addImage(qrCodeDataUrl, 'PNG',0, 7, 110, 118)
          
          pdf.text(110, 75, 'ASSET: ' + (asset.name ? formateAssetName(asset.name) : '--'));
          pdf.text(110, 90, 'ID: ' + asset.asset_number);
          pdf.text(
            110,
            105,
            'YEAR: ' + (asset.finance ? formateDate(asset.finance.purchased_date.split('T')[0]) : '--'),
          );
          pdf.text(110, 120, 'LOC: ' + (asset.location ? asset.location.site : '--'));
          
        // Save the generated PDF and open in a new window
        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
    
        window.open(pdfUrl, '_blank');
      };
    return (
        <span  onClick={generatePDF}>QR</span>
    );
};

export default printAssetQr;