/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import './FilterField.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamsByAuthentication } from '../../../redux/features/teams/teamSlice';
import {
  getLocationsByAuthentication,
  getPagination,
} from '../../../redux/features/location/locationSlice';

import {
  getAnalyticsForDashboard,
  setCategoryFilter,
  setCustomDate,
  setCustomEnd,
  setCustomStart,
  setDate,
  setLocation,
  setSite,
  setStaff,
  setType,
  setdateWord,
} from '../../../redux/features/Analytics/analyticsSlice';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import format from 'date-fns/format';
import { addDays } from 'date-fns';
import { faRotateLeft, faRotate } from '@fortawesome/free-solid-svg-icons';
import { getWorkOrderTypeByAuthentication } from '../../../redux/features/workOrderType/workOrderTypeSlice';
import { getWorkOrderCategoryByAuthentication } from '../../../redux/features/workOrderCategory/workOrderCategorySlice';
import {
  SelectDateFilter,
  SelectLocationFilter,
  SelectStaff,
  SelectSublocationFilter,
  SelectTypesAndCategories,
} from '../../select/select';

const FilterField = () => {
  const dispatch = useDispatch();
  const { workOrderCategories } = useSelector(state => state.workOrderCategory);
  const { workOrderTypes } = useSelector(state => state.workOrderType);
  const [locationsData, setLocations] = useState([]);
  const [show, setShow] = useState(false);
  const [isReseted, setIsReseted] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const sublocations = useSelector(state => state.location?.sublocations?.results);
  const [dateState, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);
  const { teams } = useSelector(state => state.teams);
  const { locations } = useSelector(state => state.location);
  const {
    site,
    location: loc,
    staff: staf,
    categoryFilter,
    typeFilter,
    filter,
    customDate,
  } = useSelector(state => state.analytics);



  const resetState = () => {
    dispatch(setDate(null));
    dispatch(setSite(null));
    dispatch(setLocation(null));
    dispatch(setType(''));
    dispatch(setCategoryFilter(''));
    dispatch(setStaff(null));
  };

  useEffect(() => {
    dispatch(getTeamsByAuthentication());
    dispatch(getLocationsByAuthentication());
    dispatch(getWorkOrderTypeByAuthentication());
    dispatch(getWorkOrderCategoryByAuthentication());
  }, []);

  useEffect(() => {
    setLocations(sublocations || []);
  }, [sublocations]);

  
  const handleClose = () => {
    dispatch(setDate(null));
    setShow(false);
    setShowDate(false);
  };
  const handleUpdate = () => {
    setShow(false);
    dispatch(setdateWord('custom'));
    // dispatch(
    //   setDate(
    //     `custom&custom_start=${format(dateState[0].startDate, 'yyyy-MM-dd')}&custom_end=${format(
    //       dateState[0].endDate,
    //       'yyyy-MM-dd',
    //     )}`,
    //   ),
    // );
    dispatch(
      setCustomDate(
        `custom&custom_start=${format(dateState[0].startDate, 'yyyy-MM-dd')}&custom_end=${format(
          dateState[0].endDate,
          'yyyy-MM-dd',
        )}`,
      ),
    );
    dispatch(setCustomStart(format(dateState[0].startDate, 'yyyy-MM-dd')));
    dispatch(setCustomEnd(format(dateState[0].endDate, 'yyyy-MM-dd')));
  };

  const handleShow = () => setShow(!show);
  const handleDate = e => {
    const word = e.value;
    if (word === '') {
      dispatch(setDate(null));
    } else {
      dispatch(setDate(e));
    }
    if (word === 'custom') {
      handleShow();
      dispatch(setDate(e));
      setShowDate(true);
    } else {
      setShowDate(false);
    }
  };

  const handleEraserFilterClick = () => {
    setShowDate(false);
    setLocations([]);
    // reset the state as needed
    resetState();
    setIsReseted(!isReseted)
  };

  useEffect(() => {
      dispatch(
        getAnalyticsForDashboard({
          filter:
            filter?.value === 'custom' ? customDate : filter?.value ? filter?.value : 'last7days',
          site: site?.value || null,
          location: loc?.value || null,
          staff: staf?.value || null,
          type: typeFilter?.label === 'All' || !typeFilter?.label ? null : typeFilter?.label,
          category:
            categoryFilter?.label === 'All' || !categoryFilter?.label ? null : categoryFilter?.label,
        }),
      );
  }, [isReseted])
  
  const handleFetchFilterClick = () => {
    dispatch(
      getAnalyticsForDashboard({
        filter:
          filter?.value === 'custom' ? customDate : filter?.value ? filter?.value : 'last7days',
        site: site?.value || null,
        location: loc?.value || null,
        staff: staf?.value || null,
        type: typeFilter?.label === 'All' || !typeFilter?.label ? null : typeFilter?.label,
        category:
          categoryFilter?.label === 'All' || !categoryFilter?.label ? null : categoryFilter?.label,
      }),
    );
  };
  const dateOptions = [
    { value: '', label: 'Last 7 days' },
    { value: 'last15days', label: 'Last 15 days' },
    { value: 'last30days', label: 'Last 30 days' },
    { value: 'last365days', label: 'Last 365 days' },
    { value: 'custom', label: 'Custom' },
  ];
  return (
    <div className="filters-wrapper">
      <div className="filter-wrapper">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Date</label>
        <SelectDateFilter dateOptions={dateOptions} filter={filter} handleDate={handleDate} />
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Location </label>
        <SelectLocationFilter
          locations={locations}
          site={site}
          setFilterSite={setSite}
          setFilterSublocation={setLocation}
          setLocations={setLocations}
        />
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Sublocation</label>
        <SelectSublocationFilter
          sublocationsData={locationsData}
          sublocation={loc}
          setFilterSublocation={setLocation}
          site={site}
        />
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Category</label>
        <SelectTypesAndCategories
          dataArray={workOrderCategories}
          stateValue={categoryFilter}
          setState={setCategoryFilter}
        />
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Sub Category</label>
        <SelectTypesAndCategories
          dataArray={workOrderTypes}
          stateValue={typeFilter}
          setState={setType}
        />
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Staff</label>
        <SelectStaff staff={staf} setStaff={setStaff} teams={teams?.results} />
      </div>
      <div className="erase-wrapper-analytics">
        <div className="erase-btn">
          <Button onClick={handleFetchFilterClick} title="Reload" variant="outline-light">
            <FontAwesomeIcon icon={faRotate} style={{ color: 'black', padding: '2px' }} />
          </Button>
        </div>
        <div className="erase-btn ml-2">
          <Button onClick={handleEraserFilterClick} title="Reset" variant="outline-light">
            <FontAwesomeIcon style={{ color: 'black', padding: '2px' }} icon={faRotateLeft} />
          </Button>
      </div>
      </div>
      {/* <div className="col-12">
        {showDate && (
          <div className="input-group input-group-sm mt-1" style={{ width: '350px' }}>
            <span
              className="input-group-text"
              id="inputGroup-sizing-sm"
              style={{ height: '30px', marginRight: '5px' }}
            >
              Date Range
            </span>
            <input
              type="text"
              readOnly="true"
              onClick={handleShow}
              className="form-control date-input"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              style={{
                fontSize: '16px', // Change to your desired font size
                fontWeight: 'bold', // Change to your desired font weight
                textAlign: 'center',
                color: '#000',
              }}
              value={`${format(dateState[0].startDate, 'MM-dd-yyyy')} to ${format(
                dateState[0].endDate,
                'MM-dd-yyyy',
              )}`}
            />
          </div>
        )}
      </div> */}
      <div className="px-2">
        <CustomDatePicker
          show={show}
          handleUpdate={handleUpdate}
          handleClose={handleClose}
          state={dateState}
          setState={setState}
        />
      </div>
    </div>
  );
};

export default FilterField;
