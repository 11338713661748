import React from 'react'

function MinusIcon() {
    return (
        <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3346 1L1.66797 1" 
            stroke="#36383C" 
            strokeWidth="1.75" 
            strokeLinecap="round" />
        </svg>
    )
}

export default MinusIcon