import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import workOrderTypeService from './workOrderTypeService';
import { smartTryCatch } from '../../../utils';

const initState = {
  workOrderTypes: [],
  workOrderType: {},

  // fields
  workOrderTypeName: '',

  // modal
  workOrderTypeModalCreate: false,
  workOrderTypeModalUpdate: false,
  workOrderTypeModalDelete: false,

  // success
  isWorkOrderTypeCreateSuccess: false,
  isWorkOrderTypeGetSuccess: false,
  isWorkOrderTypeDeleteSuccess: false,
  isWorkOrderTypeUpdateSuccess: false,

  // loading
  isWorkOrderTypeCreateLoading: false,
  isWorkOrderTypeGetLoading: false,
  isWorkOrderTypeDeleteLoading: false,
  isWorkOrderTypeUpdateLoading: false,

  // error
  isWorkOrderTypeCreateError: false,
  isWorkOrderTypeGetError: false,
  isWorkOrderTypeDeleteError: false,
  isWorkOrderTypeUpdateError: false,
};

export const createWorkOrderTypes = createAsyncThunk(
  'createWorkOrderTypes',
  async (data, thunkAPI) => {
    const createWO = await smartTryCatch(
      workOrderTypeService.createWorkOrderType,
      data,
      thunkAPI,
    );
    return createWO;
  },
);

export const getWorkOrderTypeByAuthentication = createAsyncThunk(
  'getWorkOrderTypeByAuthentication',
  async (data, thunkAPI) => {
    const getWo = await smartTryCatch(
      workOrderTypeService.getWorkOrderTypeByAuthentication,
      data,
      thunkAPI,
    );
    return getWo;
  },
);

export const getWorkOrderTypesById = createAsyncThunk(
  'getWorkOrderTypesById',
  async (data, thunkAPI) => {
    const getWo = await smartTryCatch(
      workOrderTypeService.getWorkOrderTypeById,
      data,
      thunkAPI,
    );
    return getWo;
  },
);

export const deleteWorkOrderTypes = createAsyncThunk(
  'deleteWorkOrderTypes',
  async (data, thunkAPI) => {
    const deleteWo = await smartTryCatch(
      workOrderTypeService.deleteWorkOrderType,
      data,
      thunkAPI,
    );
    return deleteWo;
  },
);

export const updateWorkOrderTypes = createAsyncThunk(
  'updateWorkOrderTypes',
  async (data, thunkAPI) => {
    const update = await smartTryCatch(
      workOrderTypeService.updateWorkOrderType,
      data,
      thunkAPI,
    );
    return update;
  },
);

export const workOrderTypeSlice = createSlice({
  name: 'workOrderType',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isWorkOrderTypeCreateError = false;
      state.isWorkOrderTypeCreateSuccess = false;
      state.isWorkOrderTypeCreateLoading = false;
      state.isWorkOrderTypeGetError = false;
      state.isWorkOrderTypeGetSuccess = false;
      state.isWorkOrderTypeGetLoading = false;
      state.isWorkOrderTypeDeleteError = false;
      state.isWorkOrderTypeDeleteSuccess = false;
      state.isWorkOrderTypeDeleteLoading = false;
      state.isWorkOrderTypeUpdateError = false;
      state.isWorkOrderTypeUpdateSuccess = false;
      state.isWorkOrderTypeUpdateLoading = false;
      // state.workOrderType = null;
    },
    openWorkOrderTypeModalCreate: (state) => {
      state.workOrderTypeModalCreate = true;
    },
    closeWorkOrderTypeModalCreate: (state) => {
      state.workOrderTypeModalCreate = false;
    },
    openWorkOrderTypeModalUpdate: (state) => {
      state.workOrderTypeModalUpdate = true;
    },
    closeWorkOrderTypeModalUpdate: (state) => {
      state.workOrderTypeModalUpdate = false;
    },
    openWorkOrderTypeModalDelete: (state) => {
      state.workOrderTypeModalDelete = true;
    },
    closeWorkOrderTypeModalDelete: (state) => {
      state.workOrderTypeModalDelete = false;
    },
    setWorkOrderType: (state, action) => {
      state.workOrderType = action.payload;
    },
    setWorkOrderTypeName: (state, action) => {
      state.workOrderTypeName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkOrderTypeByAuthentication.pending, (state) => {
        state.isWorkOrderTypeGetLoading = true;
        state.isWorkOrderTypeGetSuccess = false;
        state.isWorkOrderTypeGetError = false;
      })
      .addCase(getWorkOrderTypeByAuthentication.fulfilled, (state, action) => {
        state.isWorkOrderTypeGetLoading = false;
        state.isWorkOrderTypeGetSuccess = true;
        state.isWorkOrderTypeGetError = false;
        state.workOrderTypes=action.payload;
      })
      .addCase(getWorkOrderTypeByAuthentication.rejected, (state) => {
        state.isWorkOrderTypeGetLoading = false;
        state.isWorkOrderTypeGetSuccess = false;
        state.isWorkOrderTypeGetError = true;
      })
      .addCase(getWorkOrderTypesById.pending, (state) => {
        state.isWorkOrderTypeGetLoading = true;
        state.isWorkOrderTypeGetSuccess = false;
        state.isWorkOrderTypeGetError = false;
      })
      .addCase(getWorkOrderTypesById.fulfilled, (state, action) => {
        state.isWorkOrderTypeGetLoading = false;
        state.isWorkOrderTypeGetSuccess = true;
        state.isWorkOrderTypeGetError = false;
        state.workOrderType = action.payload;
      })
      .addCase(getWorkOrderTypesById.rejected, (state) => {
        state.isWorkOrderTypeGetLoading = false;
        state.isWorkOrderTypeGetSuccess = false;
        state.isWorkOrderTypeGetError = true;
      })
      .addCase(createWorkOrderTypes.pending, (state) => {
        state.isWorkOrderTypeCreateLoading = true;
        state.isWorkOrderTypeCreateSuccess = false;
        state.isWorkOrderTypeCreateError = false;
      })
      .addCase(createWorkOrderTypes.fulfilled, (state, action) => {
        state.isWorkOrderTypeCreateLoading = false;
        state.isWorkOrderTypeCreateSuccess = true;
        state.isWorkOrderTypeCreateError = false;
        state.workOrderTypes.push(action.payload);
      })
      .addCase(createWorkOrderTypes.rejected, (state) => {
        state.isWorkOrderTypeCreateLoading = false;
        state.isWorkOrderTypeCreateSuccess = false;
        state.isWorkOrderTypeCreateError = true;
      })
      .addCase(deleteWorkOrderTypes.pending, (state) => {
        state.isWorkOrderTypeDeleteLoading = true;
        state.isWorkOrderTypeDeleteSuccess = false;
        state.isWorkOrderTypeDeleteError = false;
      })
      .addCase(deleteWorkOrderTypes.fulfilled, (state, action) => {
        state.isWorkOrderTypeDeleteLoading = false;
        state.isWorkOrderTypeDeleteSuccess = true;
        state.isWorkOrderTypeDeleteError = false;
        // state.workOrderTypes = action.payload;
        state.workOrderTypes = state.workOrderTypes.filter(
          (workOrderType) => workOrderType.id !== action.payload,
        );
      })
      .addCase(deleteWorkOrderTypes.rejected, (state) => {
        state.isWorkOrderTypeDeleteLoading = false;
        state.isWorkOrderTypeDeleteSuccess = false;
        state.isWorkOrderTypeDeleteError = true;
      })
      .addCase(updateWorkOrderTypes.pending, (state) => {
        state.isWorkOrderTypeUpdateLoading = true;
        state.isWorkOrderTypeUpdateSuccess = false;
        state.isWorkOrderTypeUpdateError = false;
      })
      .addCase(updateWorkOrderTypes.fulfilled, (state, action) => {
        state.isWorkOrderTypeUpdateLoading = false;
        state.isWorkOrderTypeUpdateSuccess = true;
        state.isWorkOrderTypeUpdateError = false;
        state.workOrderTypes = state.workOrderTypes.map((workOrderType) =>
        workOrderType.id === action.payload.id ? action.payload : workOrderType,
      );
      })
      .addCase(updateWorkOrderTypes.rejected, (state) => {
        state.isWorkOrderTypeUpdateLoading = false;
        state.isWorkOrderTypeUpdateSuccess = false;
        state.isWorkOrderTypeUpdateError = true;
      });
  },
});

export const {
  reset,
  openWorkOrderTypeModalCreate,
  closeWorkOrderTypeModalCreate,
  openWorkOrderTypeModalUpdate,
  closeWorkOrderTypeModalUpdate,
  openWorkOrderTypeModalDelete,
  closeWorkOrderTypeModalDelete,
  setWorkOrderType,
  setWorkOrderTypeName,
} = workOrderTypeSlice.actions;

export default workOrderTypeSlice.reducer;
