/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-template */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classes from '../assetsPage/assestsPage.module.css';
import IconSearch from '../workOrder/Componets/icons/IconSearch';
import { setSearchValue } from '../../redux/features/SOR/SORSlice';
import BillIcon from '../../assets/svg-icons/BillIcon';



function epqHeader() {
    const dispatch = useDispatch();

    const { searchValue, billData, isPrint } = useSelector(state => state.SOR)

    const handelChange = e => {
        dispatch(setSearchValue(e.target.value));
    };


    return (
        <div className={classes.assetsTop}>
            <h4 className={classes.assetsTitle}>Schedule of rate</h4>
            <div className={classes.assetActions}>
                <div style={{
                    display: 'flex',
                    backgroundColor: '#F8F9F9',
                    border: '1px solid #E1E1E1',
                    borderRadius: '8px',
                    alignItems: 'center',
                    paddingRight: '16px'
                    /* width: 250px; */
                }}>
                    <input type="text" placeholder='Search' value={searchValue} onChange={handelChange} />
                    <IconSearch />
                </div>

                <Link to="/ebq/bill">
                    <button type="button"
                        className="icon-button"
                        style={{
                            border: "none",
                            outline: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <span style={{ color: "#36383C" }}><BillIcon /></span>
                        <span className="icon-button__badge">
                            <p style={{ paddingTop: "15px", fontSize: "10px" }}>{isPrint ? 0 : billData?.length}</p>
                        </span>
                    </button>
                </Link>

            </div>
        </div>
    );
}

export default epqHeader;
