import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getTriggersByAuthentication = async (id) => {
  const res = await http.get(`${BASE_URL}/meter-trigger-by-meter-id/${id}/`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};


const getTriggerById = async (TriggerId) => {
  const res = await http.get(`${BASE_URL}/meter-trigger/${TriggerId}/`);

  return assert(res, res.data, 'Retrieval of Trigger by its ID failed', res);
};

const createTrigger = async (payload) => {
  const res = await http.post(`${BASE_URL}/meter-trigger/`
  , payload);

  return assert(res, res.data, 'Creation of Trigger failed', res);
};

const deleteTrigger = async (triggerId) => {
  const res = await http.delete(`${BASE_URL}/meter-trigger/${triggerId}/`);
  return assert(res, triggerId, 'Deletion of Trigger failed', res);
};

const updateTrigger = async (triggerData) => {
  const res = await http.put(
    `${BASE_URL}/meter-trigger/${triggerData.triggerId}/`,
    triggerData,
  );
  return assert(res, res.data, 'Update of Trigger failed', res);
};

const triggerService = {
  getTriggersByAuthentication,
  getTriggerById,
  createTrigger,
  deleteTrigger,
  updateTrigger,
};

export default triggerService;
