import React, { useEffect } from 'react'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getEventsByFilters, setFilterFeatures } from '../../../redux/features/dashboard/dashboardSlice';
import Loader from '../../../components/loader/loader';
import NewCalendar from '../NewCalendar';
import CustomDatePicker from './CustomDatePicker';

function TaskPlannerCalendar({ handleSelectedEvent }) {
    const dispatch = useDispatch()
    const {
        isEventsGetLoading,
        isEventUpdateLoading,
        filterFeatures,
        selectedDateOnCalendar,
        calendarFilterAdvanceStatus,
        calendarSite,
        calendarSublocation,
        calendarStaff,
        calendarView
    } = useSelector((state) => state.dashboard);

    const handelCategoryChange = (item) => {
        const updatedFilterFeatures = filterFeatures.map(ele => {
            if (ele.label === item.label) {
                return { ...ele, selected: !item.selected }
            }
            return ele
        })
        dispatch(setFilterFeatures(updatedFilterFeatures))
    }

    const getQueryWords = (filterName, label) => {
        if (filterName === "status") {
            return label
        }
        if (label === "Work order") {
            return "workorder"
        }
        return label.toLowerCase()
    }

    const getQueryString = (data, filterName) => {
        return data
            .filter(feature => feature.selected) // Only keep selected items
            .map(feature => `${filterName}=${getQueryWords(filterName, feature.label)}`)
            .join('&');
    }    

    const filterFunction = async () => {
        const filters = {
            startDate: calendarView === "week"
                ? moment.utc(selectedDateOnCalendar).startOf("isoWeek").format("YYYY-MM-DD")
                : moment.utc(selectedDateOnCalendar).format("YYYY-MM-DD"),
            endDate: calendarView === "week"
                ? moment.utc(selectedDateOnCalendar).endOf("isoWeek").format("YYYY-MM-DD")
                : moment.utc(selectedDateOnCalendar).format("YYYY-MM-DD"),
            feature: getQueryString(filterFeatures, "ticket_type"),
            statusAdvance: getQueryString(calendarFilterAdvanceStatus, "status"),
            site: calendarSite?.value || null,
            sublocation: calendarSublocation?.value || null,
            staff: calendarStaff?.value || null
        }
        await dispatch(getEventsByFilters(filters));
    }

    useEffect(() => {
        filterFunction()
    }, [filterFeatures, selectedDateOnCalendar, calendarView])

    

    return (
        <div style={{ margin: '0 16px' }} className='task-planner-container-calendar'>
            <div style={{ marginRight: '20px', marginTop: "50px" }}>
                <CustomDatePicker />
                <div className='toggle-category'>
                    <h4>Show category</h4>
                    {filterFeatures.map(item => (
                        <div className="filter-wrapper-status" style={{ width: "100%", marginBottom: "10px" }}>
                            <div>
                                <span className='cat-circle' style={{ backgroundColor: item.backgroundColor }} />
                                <span className="weekly-frequency-label">{item.label}</span>
                            </div>
                            <label className="weekly-frequency-switch black-switch">
                                <input
                                    type="checkbox"
                                    checked={item.selected}
                                    onChange={() => handelCategoryChange(item)}
                                />
                                <span className="weekly-frequency-slider" />
                            </label>
                        </div>
                    ))}
                </div>
            </div>
            {isEventsGetLoading || isEventUpdateLoading ? (
                <Loader />
            ) :
                <NewCalendar filterFunction={filterFunction} handleSelectedEvent={handleSelectedEvent} />
            }
        </div>
    )
}

export default TaskPlannerCalendar