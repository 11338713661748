import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getSublocationById = async (LocationId) => {
  const res = await http.get(
    `${BASE_URL}/sub-location/${LocationId}/`
  );

  return assert(
    res,
    res.data,
    "Retrieval of sub location  by its ID failed",
    res
  );
};

const subLocationAdd = async (LocationData) => {
  const res = await http.post(
    `${BASE_URL}/sub-location-add/${LocationData.id}`,
    LocationData
  );

  return assert(res, res.data, "Creation of sub location  failed", res);
};

const subLocationUpdate = async (LocationData) => {
  const res = await http.put(
    `${BASE_URL}/sub-location-edit-delete/${LocationData.id}`,
    LocationData
  );

  return assert(res, res.data, "Update of sub location  failed", res);
};

const subLocationDelete = async (LocationId) => {
  const res = await http.delete(
    `${BASE_URL}/sub-location-edit-delete/${LocationId}`
  );

  return assert(res, LocationId, "Deletion of sub location  failed", res);
};

const subLocationService = {
  getSublocationById,
  subLocationAdd,
  subLocationUpdate,
  subLocationDelete,
};

export default subLocationService;
