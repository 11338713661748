import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LeafletMap from '../../components/leafletMap/leaftletMap';
import './assetMapping.scss';
import {
  closeAddGroupModal,
  getAssetsMappingByAuthentication,
  getUnmarkedAssetById,
  getMarkedAssetById,
  openAddGroupModal,
  resetAssetMapping,
  createNewGroup,
  updateGroup,
  setAssignclicked,
  setAssetMapping,
  setSelectedMarker,
  createAssignGroup,
  setColor,
  deleteGroup,
  getAllGroups,
  setGroupName,
  setRoom,
  putAssignassets,
  setUnmarkedAsset,
  setLocationChange,
  updateAssignGroup,
  setMarkedAsset,
  setPlanName
} from '../../redux/features/assetMapping/assetMappingSlice';
import AddGroup from './addGroup';
import { SelectOptions } from '../../components/select/select';

/* eslint-disable arrow-body-style */
/* eslint-disable prefer-template */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */



export default function AssetMapping() {
  const [isError, setIsError] = useState(false);
  const [groupId, setGroupId] = useState('');
  const [assetId, setAssetId] = useState('');
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [assetSearchValue, setAssetSearchValue] = useState('');
  const [assetData, setAssetData] = useState([]);

  const dispatch = useDispatch();

  const {
    assetsMapping,
    assetMapping,
    addGroupModal,
    groups,
    color,
    groupName,
    assignassets,
    unmarkedAsset,
    room,
    markedAsset,
    isUnmarkedAssetGetLoading,
    locationChange,
    planName
  } = useSelector((state) => state.assetsMapping);

  useEffect(() => {
    const getAssetMapData = async ()=>{
       dispatch(resetAssetMapping())
      await dispatch(getAssetsMappingByAuthentication());
      await dispatch(getAllGroups());
    }
    getAssetMapData()
  }, []);


  const handleToggle = (idx) => {
    const tog = document.getElementById(`toggle${idx}`);

    if (isCardVisible) {
      // Add a class to the div
      tog.classList.add('group-view');

      // Remove a class from the div
      tog.classList.remove('collapsed-card');
    } else {
      // Add a class to the div
      tog.classList.add('collapsed-card');

      // Remove a class from the div
      tog.classList.remove('group-view');
    }
    setIsCardVisible(!isCardVisible);
  };

  const handelPlanChange = async (e) =>  {
    setAssetSearchValue('')
    if (e.value === '') {
      dispatch(resetAssetMapping());
    }
    dispatch(setMarkedAsset([]))
    dispatch(setSelectedMarker());
    setAssetId();
    dispatch(setPlanName(e))
    await dispatch(getMarkedAssetById(assetMapping?.id));
    const asset = assetsMapping?.find((map) => map.id === e.value);
    dispatch(setRoom(""))
    dispatch(setUnmarkedAsset([]))
    dispatch(setLocationChange(""))
    dispatch(setAssetMapping(asset));
  };

  const handelLocationChange = (e) => {
    dispatch(setLocationChange(e))
    dispatch(setRoom(assetMapping.associated_location.find((r) => r.id === +e.value)));
    dispatch(getUnmarkedAssetById(e.value));
  };
 

  // Group
  const handelAddGroup = () => {
    dispatch(openAddGroupModal());
    dispatch(setGroupName(""));
    dispatch(setColor("#f50000"));
    setIsError(false);
  };
  const handelCloseGroup = () => {
    dispatch(closeAddGroupModal());

  };

  const handelGroupName = (e) => {
    setIsError(false);
    dispatch(setGroupName(e.target.value));
  };
  const handelColor = (e) => {
    dispatch(setColor(e.target.value));
  };

  const handelEditGroup = (idx, group) => {
    setGroupId(idx);
    dispatch(setGroupName(group.name));
    dispatch(setColor(group.color));

    const tog = document.getElementById(`toggle${idx}`);
    tog.classList.add('group-view');
    // Remove a class from the div
    tog.classList.remove('collapsed-card');
    setIsCardVisible(true);
  };

  const addNewGroup = () => {
    if (!groupName) {
      setIsError(true);
      return;
    }

    const name = groupName;
    const newGroup = {
      name,
      color,
    };
    dispatch(createNewGroup(newGroup));
    dispatch(closeAddGroupModal());
    setGroupId('');
  };

  const handelDeleteGroup = async (idx) => {
    await dispatch(deleteGroup(idx));
    await dispatch(getAllGroups());
    await dispatch(getMarkedAssetById(assetMapping.id));
  };

  const handelUpdateGroup = async (idx) => {
    // const groupData = { id: idx, name: groupName, color: color };
    const name = groupName;
    const id = idx;
    await dispatch(updateGroup({ id, name, color }));
    await dispatch(getMarkedAssetById(assetMapping.id));
    await dispatch(getAllGroups());
    setGroupId('');
    const tog = document.getElementById(`toggle${idx}`);
    // Add a class to the div
    tog.classList.add('collapsed-card');
    // Remove a class from the div
    tog.classList.remove('group-view');
    setIsCardVisible(false);
  };

  // unMarked Assets
const [bounds, setbounds] = useState([
  [-6.0, 5.0],
  [6.0, -5.0]
])

  const handelUnMarkedClick = (id) => {
    setAssetId(id);
    // const lng = 6.3;
    // const lat = -5.0;
  const lng = bounds[1][1]; // Longitude for the far right position
  const lat = bounds[0][0];
    dispatch(setSelectedMarker({ id, lat, lng }));
  };
  

  

  const handelAssignClick = async (idx, groupColor) => {
    const button = document.getElementById(`assign${idx}`);
    if (button.innerText === 'Assign') {
      button.innerText = 'Finish';
      await dispatch(setAssignclicked({ id: idx, color: groupColor }));
    } else {
      const assignGroupData = {
        assets: assignassets,
        group: idx,
      };
      await dispatch(createAssignGroup(assignGroupData));
      await dispatch(getMarkedAssetById(assetMapping?.id))
      button.innerText = 'Assign';
      await dispatch(setAssignclicked({}));
      await dispatch(putAssignassets([]));
    }
  };


  const removeAseetFromGroup = async(myGroupId , myAssetId)=>{

    await dispatch(updateAssignGroup({group : myGroupId , assets: [myAssetId]}))
    await dispatch(getMarkedAssetById(assetMapping.id));
  }

  const handelAssetChange = (e)=>{
    setAssetSearchValue(e.target.value)
  }

  useEffect(() => {
    const data = []
      if (unmarkedAsset?.length > 0) {
        unmarkedAsset.map((e) => {
            if (e?.name?.toLowerCase()?.includes(assetSearchValue?.toLowerCase()) ||
            e?.asset_number?.toLowerCase()?.includes(assetSearchValue?.toLowerCase())) {
            data.push(e)
          }
      })
      }
    setAssetData(data)
  }, [assetSearchValue , unmarkedAsset])
  

  return (
    <>
      <div className="row col-12 sticky-top p-2 m-0 sticky">
        <div className="col-lg-3 col-md-12 col-sm-12 medium-title pl-3 pb-3">
          <b>Advanced Asset-Location Mapping</b>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 pb-2">
          {/* <select
            className=" col-lg-6 col-md-12 col-sm-12 search-bar select"
            required
            name="status"
            id="status"
            onChange={handelPlanChange}
          >
            <option value="" disabled selected>--SELECT--</option>
            {assetsMapping?.map((asset) => (
              <option value={asset.id}>{asset.name}</option>
            ))}
          </select> */}
          <SelectOptions stateValue={planName} reusableData={assetsMapping} handelChange={handelPlanChange}  />
        </div>
      </div>

      <div className="row col-12 m-0 pr-0">
        <div className="col-lg-3 col-md-12 col-sm-12 mt-3 row">
          <div className="card  col-lg-12 col-md-6 col-sm-12 my-cards">
            <div className="card-header ">
              <div className="card-title">
                <b> Assets </b>
              </div>
              <div className="col-lg-12 col-sm-12 ">
                <label className="font-weight-normal col-12">Filter by Sublocation</label>
                <SelectOptions stateValue={locationChange} locations reusableData={assetMapping?.associated_location} handelChange={handelLocationChange}  />
              </div>
            </div>
            {locationChange && <div className="card-body" id="asset_list">
              <div className="mt-2">
                <b>{room?.room}</b>
                <span className="text-secondary small-font">
                  {room && ` ${room?.suite ? `(${room?.suite}${room?.level && ", " +room?.level})` : ""}`}
                </span>
              </div>
               <div className="col-lg-12 col-sm-12 ">
                <label className="font-weight-normal col-12">Asset Filter</label>
                <input type="text" style={{width: "100%" , height: "40px" , padding: "10px" , border: "1px solid"}} placeholder='Search' value={assetSearchValue} onChange={handelAssetChange} />
              </div>
              {!isUnmarkedAssetGetLoading ?
               (assetData?.length > 0 ? assetData?.slice(0,5)?.map((asset) => (
                  <button
                    className={`col-12 p-2 mt-2  
                                  ${
                                    assetId === asset.id
                                      ? 'unmarked-assets-clicked'
                                      : 'unmarked-assets'
                                  }`}
                    id={asset.id}
                    onClick={() => handelUnMarkedClick(asset.id)}
                    title="click to map asset to location map"
                    type="button"
                  >
                    {`${asset?.asset_number} ${asset?.name}`}
                  </button>
                  // Draw Here
                )): <p style={{padding : "20px"}}>No Assets Found</p>) : <p>...Loading</p> }
            </div>
            }

            <div className="card-body none" id="filtered_asset_list" />
          </div>
          
          <div className="card col-lg-12 col-md-12 col-sm-12 my-cards">
            <div className="card-header ">
              <div className="card-title">Groups</div>
              <div className="float-right">
                <div id="group-edit-btn">
                  <button
                    className="btn btn-primary"
                    id="openModal"
                    data-toggle="modal"
                    data-target="#grouping-modal"
                    type="button"
                    onClick={handelAddGroup}
                  >
                    {' '}
                    Add Group
                  </button>
                </div>
              </div>
            </div>
            <div id="groups-list" className="card-body p-2">
              {groups?.length > 0 &&
                groups?.map((group) => {
                  const idx = group.id;
                  const filteredAssets =
                    markedAsset &&
                    markedAsset?.filter((asset) => {
                      return asset.lat_long.group !== null && asset.lat_long.group.id === idx;
                    });
                  return (
                    <div
                      id={`toggle${group.id}`}
                      className="card collapsed-card
                      bg-white	border-none rounded"
                    >
                      {groupId !== idx ? (
                        <div>
                          <div className="card-header row px-4 py-2">
                            <div className="card-title row align-items-center">
                              <button
                                className="card-toggle"
                                onClick={() => handleToggle(idx)}
                                type="button"
                              >
                                <i className="fas fa-angle-down cursor_pointer" />
                              </button>

                              <div className="my-0 ml-3 row align-items-center">
                                <div className="col">
                                  <i
                                    className="fas fa-map-marker-alt"
                                    style={{ color: group.color }}
                                  />
                                </div>
                                <div className="text-lg">{group.name}</div>
                              </div>
                            </div>
                            <div className="card-tools row  my-auto ml-auto">
                              <button
                                onClick={() => handelEditGroup(idx, group)}
                                className="thead btn secondary-btn"
                                title="Edit Group"
                                type="button"
                              >
                                <i className="fas fa-edit" />
                              </button>
                              <button
                                id={`assign${idx}`}
                                className="btn tertiary-btn"
                                type="button"
                                value="Assign"
                                onClick={() => {
                                  handelAssignClick(idx, group.color);
                                }}
                              >
                                Assign
                              </button>
                            </div>
                          </div>

                          <div className="card-body">
                            <div className="col-12 py-2">
                              {filteredAssets &&
                                filteredAssets?.map((asset) => <div className='mb-2'>{asset.name}</div>)}
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {groupId === idx && (
                        <div className="card group-edit  none" >
                          <div className="card-header p-2 pb-0">
                            <div className="card-title row col-8 p-0">
                              <input
                                name="group_name"
                                value={groupName}
                                className="form-control col-8"
                                placeholder="Group Name"
                                required
                                onChange={handelGroupName}
                              />
                              <input
                                type="color"
                                className="form-control col-3 ml-2"
                                name="group_color"
                                value={color}
                                onChange={handelColor}
                              />
                            </div>
                            <div className="card-tools row col-4 p-0 m-0 justify-content-end">
                              <button
                                onClick={() => handelDeleteGroup(idx)} // use The Id
                                data-toggle="modal"
                                data-target="#modal-default"
                                title="Delete Group"
                                className="my-auto mr-2"
                                type="button"
                              >
                                Delete
                              </button>
                              <button
                                onClick={() => handelUpdateGroup(idx)} // use The Id
                                className="btn primary-btn float-right"
                                type="button"
                              >
                                Save
                              </button>
                               </div>
                               </div>
                              <div className="card bg-white	border-none rounded">
                                <div className="card-body p-2">
                                  <div className="col-12 py-2">
                                    {filteredAssets &&
                                      filteredAssets?.map((asset) =>
                                      <div className="col-12 row py-2">
                                      <div>{asset.name}</div>
                                    <button
                                      title="Remove asset from group "
                                      className="font-danger my-auto ml-auto small-font"
                                      type="button"
                                      onClick={()=> removeAseetFromGroup(idx ,asset?.id )}
                                    >
                                      {' '}
                                      Remove
                                    </button>
                                  </div>
                                  )}
                                  </div>
                                </div>
                              </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {addGroupModal && (
          <AddGroup
            addNewGroup={addNewGroup}
            handleClose={handelCloseGroup}
            handelGroupName={handelGroupName}
            handelColor={handelColor}
            isError={isError}
          />
        )}

        <div className="col-lg-9 col-md-12 col-sm-12 pr-0">
          <LeafletMap setbounds={setbounds} bounds={bounds} setAssetId= {setAssetId} img={assetMapping?.image} />
        </div>
      </div>
    </>
  );
}
