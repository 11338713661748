import { setBillData, setBillTotalPrice, setBillWorkData, setPenaltyData, setPenaltyTotalPrice, setSubTotal, setSurchargeData, setSurchargeTotalPrice } from "../../redux/features/SOR/SORSlice"

/* eslint-disable no-unsafe-optional-chaining */

export default function migrateBillData(singleBill, dispatch){
    dispatch(setBillData(
        singleBill.bill_sor_items?.map(item => {
          return {
            "id": item.sor,
            "quantity": +item.quantity_first,
            "totalPrice": +item.total_price_first,
            "vendorQuantity": +item.quantity_second,
            "vendorTotalPrice": +item.total_price_second,
            "discountRate": +item.discount_rate,
            "rate": +item.rate,
            "unit": item.unit,
            "source": item.source,
            "description": item.description,
            "discount": item.discount,
          }
        }),
      ))
      dispatch(setSurchargeData(
        singleBill.surcharges?.map(item => {
          return {
            "code": item.code,
            "description": item.description,
            "rate": +item.rate,
          }
        }),
      ))
      dispatch(setPenaltyData(
        singleBill.penalties?.map(item => {
          return {
            "parameter": item.parameter,
            "description": item.description,
            "active": item.is_active,
            "penaltyQuantumRate": +item.penalty_value,
            "startDate": item.period_start ,
            "endDate": item.period_end,
            "penaltyQuantumType": item.penalty_type === "PRICE" ? "price" : "percentage",
          }
        }),
      ))
      dispatch(setBillWorkData(
        {
          title: singleBill?.work_title,
          description: singleBill?.work_description,
          agreementNo: singleBill?.outline_agreement_no,
          jobNum: singleBill?.job_num,
          order: singleBill?.work_order_cmms,
          orderType: singleBill?.work_order_type,
          location: singleBill?.location,
        }
    ))
    dispatch(setBillTotalPrice(
        {
            billTotalOrder: parseFloat(singleBill?.total_bills_first),
            billTotalVendor:parseFloat(singleBill?.total_bills_second) 
        }
    ))

    dispatch(setSurchargeTotalPrice(
        {
            surchargeTotalOrder: +singleBill?.total_surcharge_first,
            surchargeTotalVendor: +singleBill?.total_surcharge_second,
        }
    ))
    dispatch(
        setSubTotal(
            {
                subTotalOrder: +(+singleBill?.total_bills_first  + (+singleBill?.total_surcharge_first)),
                subTotalVendor: +(+singleBill?.total_bills_second  + (+singleBill?.total_surcharge_second)) 
            }
        )
    )
    dispatch(setPenaltyTotalPrice(+singleBill?.total_penalty ))
    }
