import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import QRService from './qrCodeService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // QRService
  service: {},
  locationService: {},
  tkt: {},

  // QR fields
  title: '',
  name: '',
  email: '',
  phone: '',
  description: '',
  category: '',
  attachment: null,

  // success states
  isQRGetSuccess: false,
  isQRCreateSuccess: false,

  // loading states
  isQRCreateLoading: false,
  isQRGetLoading: false,

  // error states
  isQRGetError: false,
  isQRCreateError: false,
};

export const createQR = createAsyncThunk('createQR', async (data, thunkAPI) => {
  const createQRRes = await smartTryCatch(QRService.createQR, data, thunkAPI);
  return createQRRes;
});

export const getQRById = createAsyncThunk('getQRById', async (data, thunkAPI) => {
  const getQR = await smartTryCatch(QRService.getQRById, data, thunkAPI);
  return getQR;
});

export const createQRLoc = createAsyncThunk('createQRLoc', async (data, thunkAPI) => {
  const createQRLocRes = await smartTryCatch(QRService.createQRLoc, data, thunkAPI);
  return createQRLocRes;
});

export const getQRLocById = createAsyncThunk('getQRLocById', async (data, thunkAPI) => {
  const getQRLoc = await smartTryCatch(QRService.getQRLocById, data, thunkAPI);
  return getQRLoc;
});

export const getWorkOrderInfo = createAsyncThunk('getWorkOrderInfo', async (data, thunkAPI) => {
  const getWorkOrder = await smartTryCatch(QRService.getWorkOrderInfo, data, thunkAPI);
  return getWorkOrder;
});

export const QRSlice = createSlice({
  name: 'QRService',
  initialState: initState,
  reducers: {
    reset: state => {
      state.isQRCreateError = false;
      state.isQRCreateSuccess = false;
      state.isQRCreateLoading = false;
      state.isQRGetError = false;
      state.isQRGetSuccess = false;
      state.isQRGetLoading = false;
      state.isQRDeleteError = false;
      state.isQRDeleteSuccess = false;
      state.isQRDeleteLoading = false;
      state.isQRUpdateError = false;
      state.isQRUpdateSuccess = false;
      state.isQRUpdateLoading = false;
      // state.QR = null;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setQrCategory: (state, action) => {
      state.category = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setFile: (state, action) => {
      state.attachment = action.payload;
    },
    removeFile:(state)=>{
      state.attachment = null;
    },
    resetQR: state => {
      state.title = '';
      state.name = '';
      state.email = '';
      state.phone = '';
      state.description = '';
      state.category = '';
      state.attachment = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createQR.pending, state => {
        state.isQRCreateLoading = true;
        state.isQRCreateSuccess = false;
        state.isQRCreateError = false;
      })
      .addCase(createQR.fulfilled, (state, action) => {
        state.isQRCreateLoading = false;
        state.isQRCreateSuccess = true;
        state.isQRCreateError = false;
        state.service = action.payload;
      })
      .addCase(createQR.rejected, state => {
        state.isQRCreateLoading = false;
        state.isQRCreateSuccess = false;
        state.isQRCreateError = true;
      })
      .addCase(getQRById.pending, state => {
        state.isQRGetLoading = true;
        state.isQRGetSuccess = false;
        state.isQRGetError = false;
      })
      .addCase(getQRById.fulfilled, (state, action) => {
        state.isQRGetLoading = false;
        state.isQRGetSuccess = true;
        state.isQRGetError = false;
        state.service = action.payload;
      })
      .addCase(getQRById.rejected, state => {
        state.isQRGetLoading = false;
        state.isQRGetSuccess = false;
        state.isQRGetError = true;
      })
      .addCase(createQRLoc.pending, state => {
        state.isQRCreateLoading = true;
        state.isQRCreateSuccess = false;
        state.isQRCreateError = false;
      })
      .addCase(createQRLoc.fulfilled, (state, action) => {
        state.isQRCreateLoading = false;
        state.isQRCreateSuccess = true;
        state.isQRCreateError = false;
        state.locationService = action.payload;
      })
      .addCase(createQRLoc.rejected, state => {
        state.isQRCreateLoading = false;
        state.isQRCreateSuccess = false;
        state.isQRCreateError = true;
      })
      .addCase(getQRLocById.pending, state => {
        state.isQRGetLoading = true;
        state.isQRGetSuccess = false;
        state.isQRGetError = false;
      })
      .addCase(getQRLocById.fulfilled, (state, action) => {
        state.isQRGetLoading = false;
        state.isQRGetSuccess = true;
        state.isQRGetError = false;
        state.locationService = action.payload;
      })
      .addCase(getQRLocById.rejected, state => {
        state.isQRGetLoading = false;
        state.isQRGetSuccess = false;
        state.isQRGetError = true;
      })

      .addCase(getWorkOrderInfo.pending, state => {
        state.isQRGetLoading = true;
        state.isQRGetSuccess = false;
        state.isQRGetError = false;
      })
      .addCase(getWorkOrderInfo.fulfilled, (state, action) => {
        state.isQRGetLoading = false;
        state.isQRGetSuccess = true;
        state.isQRGetError = false;
        state.tkt = action.payload;
      })
      .addCase(getWorkOrderInfo.rejected, state => {
        state.isQRGetLoading = false;
        state.isQRGetSuccess = false;
        state.isQRGetError = true;
      });
  },
});

export const {
  resetQR,
  setTitle,
  setDescription,
  setName,
  setEmail,
  setPhone,
  setFile,
  removeFile,
  setQrCategory,
} = QRSlice.actions;

export default QRSlice.reducer;
