import React from 'react';

export default function BellIcon() {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="14" height="4" viewBox="0 0 14 4" fill="none">
    //   <path
    //     d="M6.74992 1.74997H7.24992M6.74992 2.24997H7.24992M2.08325 1.74997H2.58325M2.08325 2.24997H2.58325M11.4166 1.74997H11.9166M11.4166 2.24997H11.9166M7.66675 2.00001C7.66675 2.3682 7.36827 2.66668 7.00008 2.66668C6.63189 2.66668 6.33341 2.3682 6.33341 2.00001C6.33341 1.63182 6.63189 1.33334 7.00008 1.33334C7.36827 1.33334 7.66675 1.63182 7.66675 2.00001ZM3.00008 2.00001C3.00008 2.3682 2.7016 2.66668 2.33341 2.66668C1.96523 2.66668 1.66675 2.3682 1.66675 2.00001C1.66675 1.63182 1.96523 1.33334 2.33341 1.33334C2.7016 1.33334 3.00008 1.63182 3.00008 2.00001ZM12.3334 2.00001C12.3334 2.3682 12.0349 2.66668 11.6667 2.66668C11.2986 2.66668 11.0001 2.3682 11.0001 2.00001C11.0001 1.63182 11.2986 1.33334 11.6667 1.33334C12.0349 1.33334 12.3334 1.63182 12.3334 2.00001Z"
    //     stroke="#36383C"
    //     strokeWidth="1.75"
    //     strokeLinecap="round"
    //   />
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.00005 14.6667H10M3.33338 6.00001C3.33338 3.42268 5.42272 1.33334 8.00005 1.33334C10.5774 1.33334 12.6667 3.42268 12.6667 6.00001V7.71854C12.6667 8.77147 12.9784 9.80085 13.5624 10.6769L13.8889 11.1666C13.9449 11.2506 13.9057 11.3648 13.8099 11.3967C10.0387 12.6538 5.96144 12.6538 2.19021 11.3967C2.09444 11.3648 2.05524 11.2506 2.11124 11.1666L2.43765 10.6769C3.02171 9.80085 3.33338 8.77147 3.33338 7.71854V6.00001Z"
        stroke="#36383C"
        strokeWidth="1.75"
        strokeLinecap="round"
      />
    </svg>
  );
}
