/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/order */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDateFilterState,
  setFilterEndDate,
  setFilterStartDate,
} from '../../../../redux/features/workOrder/workOrderSlice';
import { Button } from 'react-bootstrap';
import ReloadIcon from '../../../../assets/svg-icons/ReloadIcon';
import DateRangeFilter from '../../../viewMaintenance/Componets/DateRangeFilter';
import moment from 'moment';
import { ButtonBase, Modal } from '@material-ui/core';
import {
  SelectLocationFilter,
  SelectStaff,
  SelectSublocationFilter,
} from '../../../../components/select/select';
import {
  setPMFilterSite,
  setPMFilterSublocation,
} from '../../../../redux/features/maintenance/maintenanceSlice';
import { setCalendarFilterAdvanceStatus, setCalendarFilterSite, setCalendarFilterSublocation, setCalendarStaff, setCalendarView } from '../../../../redux/features/dashboard/dashboardSlice';

export default function TaskCalendarFilter({
  selectedMenu,
  selectedCalendarMode,
  setSelectedCalendarMode,
  handleViewChange,
  filterFunction
}) {

  const dispatch = useDispatch();
  // BEGIN date period things
  const [show, setShow] = useState(false);
  const { dateFilterState, filterStartDate, filterEndDate } = useSelector(
    (state) => state.workOrder,
  );
  const handleUpdate = async () => {
    setShow(false);
    dispatch(setFilterStartDate(dateFilterState?.find((item) => item.startDate)?.startDate));
    dispatch(setFilterEndDate(dateFilterState?.find((item) => item.endDate)?.endDate));
  };
  const handleClose = () => {
    setShow(false);
  };
  // END date period things

  // BEGIN Advance filter dialog
  const [openAdvFilter, setOpenAdvFilter] = useState(false);

  const handleOpenAdvFilter = () => {
    setOpenAdvFilter(true);
  };

  const handleCloseAdvFilter = () => {
    setOpenAdvFilter(false);
  };
  // END Advance filter dialog

  // BEGIN Inside advance filter
  const [locationsData, setLocations] = useState([]);
  const { locations } = useSelector((state) => state.location);
  const sublocations = useSelector((state) => state.location?.sublocations?.results);
  const { calendarSite, calendarSublocation, calendarStaff, calendarFilterAdvanceStatus } = useSelector((state) => state.dashboard);
  const { teams } = useSelector((state) => state.teams);


  const handelStatusChange = (item) => {
    const updatedStatus = calendarFilterAdvanceStatus.map(ele => {
      if (ele.label === item.label) {
        return { ...ele, selected: !item.selected }
      }
      return ele
    })
    dispatch(setCalendarFilterAdvanceStatus(updatedStatus))
  }

  useEffect(() => {
    setLocations(sublocations || [])
}, [sublocations])


  const resetState = () => {
    dispatch(setCalendarFilterSite(null));
    dispatch(setCalendarFilterSublocation(null));
    dispatch(setCalendarStaff(null));
    dispatch(setCalendarFilterAdvanceStatus( [
      {label : "Open", selected: true},
      {label : "In-Progress", selected: true},
      {label : "On-Hold", selected: true},
      {label : "Completed", selected: true}
    ],));
  };
  const handleEraserFilterClick = () => {
    setLocations([]);
    resetState(); // reset the state and store
  };
  const handleActiveAdvFilter = async () => {
    handleCloseAdvFilter();
    await filterFunction();
  };
  // END Inside advance filter

  const buttonStyle = {
    padding: '8px 16px',
    fontSize: '14px',
    fontWeight: '600',
    color: '#36383C',
    backgroundColor: '#F8F9F9',
    border: '1px solid #E1E1E1',
    borderRadius: '8px',
  };

  const buttonDisabledStyle = {
    padding: '8px 16px',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    backgroundColor: '#D9314A',
    borderRadius: '8px',
  };


  return (
    <div style={{ margin: '16px 16px 0 0', display: 'flex' }}>
      <div style={{ display: 'flex', gap: '16px' }}>
        <div>
          <select
            // className="form-control select-dash custom-select2 mb-2"
            className="planner_select_mode"
            name="planner_mode"
            id="planner_mode"
            value={selectedCalendarMode}
            onChange={handleViewChange}
            style={{ width: "120px" }}
          >
            <option value="week">Week</option>
            <option value="day">Day</option>
          </select>
        </div>
      </div>
      <div style={{ alignSelf: 'end' }}>
        <Button
          variant="contained"
          style={{
            backgroundColor: '#36383C',
            color: '#F8F9F9',
            fontSize: '14px',
            fontWeight: '600',
            padding: '8px 16px',
            borderRadius: '8px',
            marginLeft: '16px',
          }}
          onClick={handleOpenAdvFilter}
          className="adv-button"
        >
          Advance filter
        </Button>
      </div>
      <Modal
        open={openAdvFilter}
        onClose={handleCloseAdvFilter}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '480px',
            padding: '12px 16px',
            backgroundColor: '#F8F9F9',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              fontWeight: '600',
              fontSize: '21px',
              marginBottom: '20px',
            }}
          >
            Advance filter
          </div>
          <div
            className="wo-filter-wrapper"
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            <div className="filter-wrapper">
              <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Assigned to </label>
              <SelectStaff staff={calendarStaff} setStaff={setCalendarStaff} teams={teams?.results} />
            </div>
            <div className="filter-wrapper" style={{ width: '100%', display: 'flex', gap: '16px' }}>
              <div style={{ flex: 1 }}>
                <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>
                  Location
                </label>
                <SelectLocationFilter
                  locations={locations}
                  site={calendarSite}
                  setFilterSite={setCalendarFilterSite}
                  setFilterSublocation={setCalendarFilterSublocation}
                  setLocations={setLocations}
                />
              </div>
              <div style={{ flex: 1 }}>
                <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>
                  Sub location
                </label>
                <SelectSublocationFilter
                  sublocationsData={locationsData}
                  sublocation={calendarSublocation}
                  setFilterSublocation={setCalendarFilterSublocation}
                  site={calendarSite}
                />
              </div>
            </div>
            <div>
              <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>Status</label>
              <div
                style={{
                  marginBottom: '16px',
                  width: '100%',
                  display: 'flex',
                  gap: '16px',
                  flexWrap: 'wrap',
                }}
              >
                {calendarFilterAdvanceStatus.map((item) => (
                  <div className="filter-wrapper-status">
                    <label className="weekly-frequency-switch black-switch">
                      <input
                        type="checkbox"
                        onChange={() => handelStatusChange(item)}
                        checked={item.selected}
                      />
                      <span className="weekly-frequency-slider" />
                    </label>
                    <span className="weekly-frequency-label">{item.label}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
            <ButtonBase autoCapitalize="none" onClick={handleEraserFilterClick} style={buttonStyle}>
              Reset
            </ButtonBase>
            <div style={{ display: 'flex', gap: '12px' }}>
              <ButtonBase onClick={handleCloseAdvFilter} style={buttonStyle}>
                Close
              </ButtonBase>
              <ButtonBase onClick={handleActiveAdvFilter} style={buttonDisabledStyle}>
                Activate
              </ButtonBase>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
