import React from 'react'

function FileIcon({gray}) {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.33464 7.33325H4.33464M5.66797 9.99992H4.33464M9.66797 4.66659H4.33464M12.3346 4.53325V11.4666C12.3346 12.5867 12.3346 13.1467 12.1166 13.5746C11.9249 13.9509 11.6189 14.2569 11.2426 14.4486C10.8148 14.6666 10.2547 14.6666 9.13463 14.6666H4.86797C3.74786 14.6666 3.18781 14.6666 2.75999 14.4486C2.38366 14.2569 2.0777 13.9509 1.88596 13.5746C1.66797 13.1467 1.66797 12.5867 1.66797 11.4666V4.53325C1.66797 3.41315 1.66797 2.85309 1.88596 2.42527C2.0777 2.04895 2.38366 1.74299 2.75999 1.55124C3.18781 1.33325 3.74786 1.33325 4.86797 1.33325H9.13463C10.2547 1.33325 10.8148 1.33325 11.2426 1.55124C11.6189 1.74299 11.9249 2.04895 12.1166 2.42527C12.3346 2.85309 12.3346 3.41315 12.3346 4.53325Z"
                stroke= {!gray ? "#D9314A" : "#999999"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>
    )
}

export default FileIcon