import React from 'react';

export default function LocationsIcon({ fillColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
      <path
        d="M10.6667 6.66667C10.6667 8.13943 9.47284 9.33333 8.00008 9.33333C6.52732 9.33333 5.33341 8.13943 5.33341 6.66667C5.33341 5.19391 6.52732 4 8.00008 4C9.47284 4 10.6667 5.19391 10.6667 6.66667Z"
        stroke={fillColor === "primary" ? "var(--primary-color)" : "var(--grey-content)"}
        strokeWidth="2"
      />
      <path
        d="M13.3334 6.66667C13.3334 9.61219 9.33341 14.6667 8.00008 14.6667C6.66675 14.6667 2.66675 9.61219 2.66675 6.66667C2.66675 3.72115 5.05456 1.33333 8.00008 1.33333C10.9456 1.33333 13.3334 3.72115 13.3334 6.66667Z"
        stroke={fillColor === "primary" ? "var(--primary-color)" : "var(--grey-content)"}
        strokeWidth="2"
      />
    </svg>
  );
}
