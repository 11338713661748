import React from 'react';

export default function AssetsIcon({ fillColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
      <path
        d="M2 14H2.66667M14 14H13.3333M2.66667 14V6.3009C2.66667 4.84322 2.66667 4.11439 2.97075 3.62793C3.23707 3.2019 3.65398 2.89171 4.13857 2.75904C4.69188 2.60756 5.38998 2.81699 6.78618 3.23585L7.05285 3.31585C7.8711 3.56133 8.28022 3.68407 8.58353 3.92697C8.85128 4.1414 9.05938 4.42109 9.18783 4.73916C9.33333 5.09948 9.33333 5.52662 9.33333 6.3809V6.66667M2.66667 14H6M9.33333 14V6.66667M9.33333 14H13.3333M9.33333 14H6M9.33333 6.66667L11.2274 6.03532C11.9384 5.79832 12.2939 5.67981 12.5769 5.75087C12.8246 5.81307 13.0391 5.96769 13.1764 6.18301C13.3333 6.429 13.3333 6.80374 13.3333 7.55321V14M6 6V6.00667M6 7.99333V8M11.3333 8.66V8.66667M11.3333 10.6667V10.6733M6 14V10.6667"
        stroke={fillColor === 'primary' ? 'var(--primary-color)' : 'var(--grey-content)'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
