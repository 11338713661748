import React, { useEffect } from 'react'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getTicketNumberConfigs, setTicketNumberConfigs, updateTicketNumberConfigs , reset, setModel } from '../../../redux/features/referenceNumber/referenceNumberSlice';

/* eslint-disable camelcase */

function ReferenceNumber() {
    const {
        ticketNumberConfigs,
        isTicketNumberConfigUpdateSuccess,
        isTicketNumberConfigUpdateError,
        model
    } = useSelector((state) => state.ticketNumberConfig);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getTicketNumberConfigs())
    }, [])

    function displayToast() {
        if (isTicketNumberConfigUpdateSuccess) {
            toast.success(model === "workorder" ? 'Work Order Number Updated successfully' 
            : 'Preventive Maintenance Number Updated successfully', {
                toastId: 'WorkOrderNumberCreateSuccess',
            });
        }
        if (isTicketNumberConfigUpdateError) {
            toast.error(model === "workorder" ? 'Error getting Work Order Number' 
            : 'Error getting Preventive Maintenance Number', {
                toastId: 'WorkOrderNumberGetError',
            });
        }
    }

    useEffect(() => {
        displayToast()
        dispatch(reset())
    }, [isTicketNumberConfigUpdateSuccess , isTicketNumberConfigUpdateError])
    

    const handelChange = (e, id ) => {
        const { name, value } = e.target
        const updatedTck = ticketNumberConfigs.map(item => {
            if (item.id === id) {
                if (name !== "prefix" && name !== "running_number_format" && name !== "year_format") {
                    return { ...item, [name]: !item[name] }
                }
                return { ...item, [name]: value }
            }
            return item
        })
        dispatch(setTicketNumberConfigs(updatedTck))
    }

    const updateTckConfige = (tck) => {
        dispatch(setModel(tck.model))
        dispatch(updateTicketNumberConfigs(tck))
    }

    return (
        ticketNumberConfigs?.length > 0 && ticketNumberConfigs?.map(tck => {
            const arrayOfFields = [
                tck.include_prefix ? tck.prefix : false,
                tck.include_year ? tck.year_format : false,
                tck.include_month ? "MM" : false,
                tck.include_day ? "DD" : false,
                tck.running_number_format,
            ]

            const arrayOfFilterdFields = arrayOfFields.filter(item => item !== false && item !== "")
            return tck.model === "workorder" ? <div className="col-lg-12 col-sm-12 p-3">
                <div className="card mx-1 p-0 shadow-none" id="filter-set">
                    <div className="card-header">
                        <span className="card-title edit-category-title-inline">
                            <span>
                                <b>Work Order Number</b>
                            </span>
                        </span>
                    </div>
                    <div className="card-body p-3" id="wocategory-block">
                        <div>
                            <label>Work Order Number Format</label>
                            <div style={{ width: "100%", padding: " 10px", background: "#f7f7f7", borderRadius: "10px" }}>
                                {arrayOfFilterdFields.join("/")}
                            </div>
                        </div>
                        <div className='mt-2' style={{ display: "flex", alignItems: "center" }}>
                            <div>
                                <input type="checkbox" className='mr-2'
                                    name='include_prefix'
                                    checked={tck?.include_prefix}
                                    onChange={(e) => handelChange(e, tck.id)} />
                                <input type="text" value={tck.prefix} className='pt-1 pb-1 pl-2'
                                    name='prefix'
                                    onChange={(e) => handelChange(e, tck.id)} />
                            </div>
                            <div className='ml-4' style={{ display: "flex", alignItems: "center" }}>
                                <input type="checkbox" className='mr-2'
                                    name='include_year'
                                    checked={tck.include_year}
                                    onChange={(e) => handelChange(e, tck.id)}
                                />
                                <span>Year</span>
                                <select name='year_format' onChange={(e) => handelChange(e, tck.id)}
                                style={{marginLeft:"5px" , paddingLeft: "5px", paddingRight: "5px" }} value={tck.year_format}
                                disabled={!tck.include_year}> 
                                    <option value="YY">YY</option>
                                    <option value="YYYY">YYYY</option>
                                </select>
                                {/* <div className='ml-2'>
                                <input type="radio" className='mr-2'
                                    name='year_format'
                                    checked={tck.year_format === "YYYY"}
                                    onChange={(e) => handelChange(e, tck.id , "YYYY")}
                                />
                                YYYY
                            </div>
                            <div className='ml-2'>
                                <input type="radio" className='mr-2'
                                    name='year_format'
                                    checked={tck.year_format === "YY"}
                                    onChange={(e) => handelChange(e, tck.id , "YY")}
                                />
                                YY
                            </div> */}
                            </div>
                            <div className='ml-5'>
                                <input type="checkbox" className='mr-2'
                                    checked={tck.include_month}
                                    name='include_month'
                                    onChange={(e) => handelChange(e, tck.id)} />
                                Month(MM)
                            </div>
                            <div className='ml-4'>
                                <input type="checkbox" className='mr-2'
                                    name='include_day'
                                    checked={tck.include_day}
                                    onChange={(e) => handelChange(e, tck.id)} />
                                Day(DD)
                            </div>
                        </div>
                        <div className='mt-2'>
                            <label>Starting Work Order Number</label>
                            <div>
                                <input type="number" style={{ width: "50%", padding: "10px" }}
                                    value={tck.running_number_format}
                                    name='running_number_format'
                                    onChange={(e) => handelChange(e, tck.id)} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: 'flex-end', padding: "10px" }}>
                        <button style={{ width: "fit-content" }} className='btn-primary btn' type='button'
                            onClick={() => updateTckConfige(tck)}>
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
                :
                <div className="col-lg-12 col-sm-12 p-3">
                    <div className="card mx-1 p-0 shadow-none" id="filter-set">
                        <div className="card-header">
                            <span className="card-title edit-category-title-inline">
                                <span>
                                    <b>Preventive Maintenance Number</b>
                                </span>
                            </span>
                        </div>
                        <div className="card-body p-3" id="wocategory-block">
                            <div>
                                <label>Preventive Maintenance Number Format</label>
                                <div style={{ width: "100%", padding: " 10px", background: "#f7f7f7", borderRadius: "10px" }}>
                                    {arrayOfFilterdFields.join("/")}
                                </div>
                            </div>
                            <div className='mt-2' style={{ display: "flex", alignItems: "center" }}>
                                <div>
                                    <input type="checkbox" className='mr-2'
                                        name='include_prefix'
                                        checked={tck?.include_prefix}
                                        onChange={(e) => handelChange(e, tck.id)} />
                                    <input type="text" value={tck.prefix} className='pt-1 pb-1 pl-2'
                                        name='prefix'
                                        onChange={(e) => handelChange(e, tck.id)} />
                                </div>
                                <div className='ml-4' style={{ display: "flex", alignItems: "center" }}>
                                <input type="checkbox" className='mr-2'
                                    name='include_year'
                                    checked={tck.include_year}
                                    onChange={(e) => handelChange(e, tck.id)}
                                />
                                <span>Year</span>
                                <select name='year_format' onChange={(e) => handelChange(e, tck.id)}
                                 style={{marginLeft:"5px" , paddingLeft: "5px", paddingRight: "5px" }} value={tck.year_format}
                                 disabled={!tck.include_year}> 
                                    <option value="YY">YY</option>
                                    <option value="YYYY">YYYY</option>
                                </select>
                                {/* <div className='ml-2'>
                                <input type="radio" className='mr-2'
                                    name='year_format'
                                    checked={tck.year_format === "YYYY"}
                                    onChange={(e) => handelChange(e, tck.id , "YYYY")}
                                />
                                YYYY
                            </div>
                            <div className='ml-2'>
                                <input type="radio" className='mr-2'
                                    name='year_format'
                                    checked={tck.year_format === "YY"}
                                    onChange={(e) => handelChange(e, tck.id , "YY")}
                                />
                                YY
                            </div> */}
                            </div>
                                <div className='ml-4'>
                                    <input type="checkbox" className='mr-2'
                                        checked={tck.include_month}
                                        name='include_month'
                                        onChange={(e) => handelChange(e, tck.id)} />
                                    Month(MM)
                                </div>
                                <div className='ml-4'>
                                    <input type="checkbox" className='mr-2'
                                        name='include_day'
                                        checked={tck.include_day}
                                        onChange={(e) => handelChange(e, tck.id)} />
                                    Day(DD)
                                </div>
                            </div>
                            <div className='mt-2'>
                                <label>Starting Preventive Maintenance Number</label>
                                <div>

                                    <input type="number" style={{ width: "50%", padding: "10px" }}
                                        value={tck.running_number_format}
                                        name='running_number_format'
                                        onChange={(e) => handelChange(e, tck.id)} />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: 'flex-end', padding: "10px" }}>
                            <button style={{ width: "fit-content" }} className='btn-primary btn' type='button'
                                onClick={() => updateTckConfige(tck)}>
                                Save Changes
                            </button>
                        </div>
                    </div>

                </div>
        })
    )
}

export default ReferenceNumber