import React from 'react';

export default function IconCalendar({ fillColor }) {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    //   <path
    //     d="M3.66675 13.6667H14.3334M9.00008 11C10.4728 11 11.6667 12.1939 11.6667 13.6667M9.00008 11C7.52732 11 6.33341 12.1939 6.33341 13.6667M9.00008 11L7.66675 7.66666M3.66675 9H4.33341M13.6667 9H14.3334M9.00008 3.66666V4.33333M12.6667 5.33333L12.0001 6M5.33341 5.33333L6.00008 6M16.3334 9C16.3334 13.0501 13.0502 16.3333 9.00008 16.3333C4.94999 16.3333 1.66675 13.0501 1.66675 9C1.66675 4.94991 4.94999 1.66666 9.00008 1.66666C13.0502 1.66666 16.3334 4.94991 16.3334 9Z"
    //     stroke={fillColor === "primary" ? "var(--primary-color)" : "var(--grey-content)"}
    //     strokeWidth="2"
    //     strokeLinecap="round"
    //   />
    // </svg>
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 10H21M8 6V2M16 6V2M12 10V22M21 16L3 16M9.4 22H14.6C16.8402 22 17.9603 22 18.816 21.564C19.5686 21.1805 20.1805 20.5686 20.564 19.816C21 18.9603 21 17.8402 21 15.6V10.4C21 8.15979 21 7.03969 20.564 6.18404C20.1805 5.43139 19.5686 4.81947 18.816 4.43597C17.9603 4 16.8402 4 14.6 4H9.4C7.15979 4 6.03968 4 5.18404 4.43597C4.43139 4.81947 3.81947 5.43139 3.43597 6.18404C3 7.03969 3 8.15979 3 10.4V15.6C3 17.8402 3 18.9603 3.43597 19.816C3.81947 20.5686 4.43139 21.1805 5.18404 21.564C6.03968 22 7.15979 22 9.4 22Z"
        stroke={fillColor === 'primary' ? 'var(--primary-color)' : 'var(--grey-content)'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
