/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */

const icons ={
    total:<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8337 5.83333L9.90404 3.9741C9.63649 3.439 9.50271 3.17144 9.30313 2.97597C9.12664 2.80311 8.91393 2.67164 8.68039 2.59109C8.4163 2.5 8.11716 2.5 7.5189 2.5H4.33366C3.40024 2.5 2.93353 2.5 2.57701 2.68166C2.2634 2.84144 2.00844 3.09641 1.84865 3.41002C1.66699 3.76654 1.66699 4.23325 1.66699 5.16667V5.83333M1.66699 5.83333H14.3337C15.7338 5.83333 16.4339 5.83333 16.9686 6.10582C17.439 6.3455 17.8215 6.72795 18.0612 7.19836C18.3337 7.73314 18.3337 8.4332 18.3337 9.83333V13.5C18.3337 14.9001 18.3337 15.6002 18.0612 16.135C17.8215 16.6054 17.439 16.9878 16.9686 17.2275C16.4339 17.5 15.7338 17.5 14.3337 17.5H5.66699C4.26686 17.5 3.5668 17.5 3.03202 17.2275C2.56161 16.9878 2.17916 16.6054 1.93948 16.135C1.66699 15.6002 1.66699 14.9001 1.66699 13.5V5.83333Z" stroke="#8B5CF4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>,

    completed: <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.70052 9.9974L9.36719 11.6641L13.1172 7.91406M6.31199 3.17964C6.98186 3.12619 7.61778 2.86278 8.12924 2.42691C9.32279 1.40978 11.0782 1.40978 12.2718 2.42691C12.7833 2.86278 13.4192 3.12619 14.089 3.17964C15.6522 3.30439 16.8935 4.54568 17.0183 6.10887C17.0717 6.77873 17.3351 7.41466 17.771 7.92612C18.7881 9.11967 18.7881 10.8751 17.771 12.0687C17.3351 12.5801 17.0717 13.2161 17.0183 13.8859C16.8935 15.4491 15.6522 16.6904 14.089 16.8151C13.4192 16.8686 12.7833 17.132 12.2718 17.5679C11.0782 18.585 9.32279 18.585 8.12924 17.5679C7.61778 17.132 6.98186 16.8686 6.31199 16.8151C4.74881 16.6904 3.50751 15.4491 3.38277 13.8859C3.32931 13.2161 3.0659 12.5801 2.63004 12.0687C1.6129 10.8751 1.6129 9.11967 2.63004 7.92612C3.0659 7.41466 3.32931 6.77873 3.38277 6.10887C3.50751 4.54568 4.74881 3.30439 6.31199 3.17964Z" stroke="#76B559" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>,

    open:<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.6499 10L9.1499 12.5L14.1499 7.5M6.8999 17.5H13.8999C15.3 17.5 16.0001 17.5 16.5349 17.2275C17.0053 16.9878 17.3877 16.6054 17.6274 16.135C17.8999 15.6002 17.8999 14.9001 17.8999 13.5V6.5C17.8999 5.09987 17.8999 4.3998 17.6274 3.86502C17.3877 3.39462 17.0053 3.01217 16.5349 2.77248C16.0001 2.5 15.3 2.5 13.8999 2.5H6.8999C5.49977 2.5 4.79971 2.5 4.26493 2.77248C3.79452 3.01217 3.41207 3.39462 3.17239 3.86502C2.8999 4.3998 2.8999 5.09987 2.8999 6.5V13.5C2.8999 14.9001 2.8999 15.6002 3.17239 16.135C3.41207 16.6054 3.79452 16.9878 4.26493 17.2275C4.79971 17.5 5.49977 17.5 6.8999 17.5Z" stroke="#439EE0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>,
    
    hold:<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.51676 12.5V7.5M12.6834 12.5V7.5M7.1001 17.5H14.1001C15.5002 17.5 16.2003 17.5 16.7351 17.2275C17.2055 16.9878 17.5879 16.6054 17.8276 16.135C18.1001 15.6002 18.1001 14.9001 18.1001 13.5V6.5C18.1001 5.09987 18.1001 4.3998 17.8276 3.86502C17.5879 3.39462 17.2055 3.01217 16.7351 2.77248C16.2003 2.5 15.5002 2.5 14.1001 2.5H7.1001C5.69997 2.5 4.9999 2.5 4.46512 2.77248C3.99472 3.01217 3.61226 3.39462 3.37258 3.86502C3.1001 4.3998 3.1001 5.09987 3.1001 6.5V13.5C3.1001 14.9001 3.1001 15.6002 3.37258 16.135C3.61226 16.6054 3.99472 16.9878 4.46512 17.2275C4.9999 17.5 5.69997 17.5 7.1001 17.5Z" stroke="#D63C3C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    ,

    progress: <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7998 1.875V3.95833M10.7998 15V18.3333M5.59147 10H2.6748M18.5081 10H17.2581M16.1807 15.3809L15.5915 14.7917M16.3533 4.51316L15.1748 5.69167M4.90112 15.8987L7.25814 13.5417M5.0737 4.34057L6.84147 6.10833" stroke="#DD6B17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>,

    companyLogo:"https://s3-alpha-sig.figma.com/img/1ff2/60db/f906b8a569631598281f1c64a6f46d62?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=old10fMa0C6ysTYAdRSk2vABq~BIHrhaJVYUNHqJ0VWZfEK~-liHEhldw4YXj3mmJ4WAdRIcAijNIUKKIPsvbfLchV13wsstrwDkfK0QN7copdc90L8gmMPjdg9KNUbioa3qQssvgvWNCnqjLAfvD8za7mAU7NGwc2mUNfotlBrEY2V8ZxEsKxT2-LWsDrGoDhv~Bn72Ojsm8-scqwFtAol2dxq9kXaAhR6XWSIy4a7qsZI~Q3YzU8ih5DqBuft0PAV9rESuEebuYkYbdIUedF0X~9i23IezoGuQisqOHiJ55jwCbwZzVKlR7GlhbVKxFKWPAEKDd7mA4DWH4Iv~5Q__",

    doneLogo : <svg width="175" height="176" viewBox="0 0 175 176" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="87.5" cy="88" r="87.25" fill="#EBF7F9"/>
    <circle cx="86.4738" cy="89.0258" r="59.5353" fill="#D6EBF6"/>
    <circle cx="86.4734" cy="89.0242" r="36.9529" fill="#2BABDE"/>
    <path d="M102.898 76.7144L80.3155 99.2967L70.0508 89.032" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
    
}

export default icons
