import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import inventoryCategoryService from './inventoryCategoryService';
import { smartTryCatch } from '../../../utils';

const initState = {
  inventories: [],
  inventory: {},

  // modals
  inventoryCategoryModalDelete: false,
  inventoryCategoryModalUpdate: false,
  inventoryCategoryModalCreate: false,

  // success
  isInventoryCreateSuccess: false,
  isInventoryGetSuccess: false,
  isInventoryDeleteSuccess: false,
  isInventoryUpdateSuccess: false,

  // loading
  isInventoryCreateLoading: false,
  isInventoryGetLoading: false,
  isInventoryDeleteLoading: false,
  isInventoryUpdateLoading: false,

  // error
  isInventoryCreateError: false,
  isInventoryGetError: false,
  isInventoryDeleteError: false,
  isInventoryUpdateError: false,
};

export const createInventoryCategory = createAsyncThunk(
  'createInventoryCategory',
  async (data, thunkAPI) => {
    const create = await smartTryCatch(
      inventoryCategoryService.createInventoryCategory,
      data,
      thunkAPI,
    );
    return create;
  },
);

export const getInventoryCategoryByAuthentication = createAsyncThunk(
  'getInventoryCategoryByAuthentication',
  async (data, thunkAPI) => {
    const getTech = await smartTryCatch(
      inventoryCategoryService.getInventoryCategoryByAuthentication,
      data,
      thunkAPI,
    );
    return getTech;
  },
);

export const getInventoryCategoryById = createAsyncThunk(
  'getInventoryCategoryById',
  async (data, thunkAPI) => {
    const getTech = await smartTryCatch(
      inventoryCategoryService.getInventoryCategoryById,
      data,
      thunkAPI,
    );
    return getTech;
  },
);

export const deleteInventoryCategory = createAsyncThunk(
  'deleteInventoryCategory',
  async (data, thunkAPI) => {
    const deleteTech = await smartTryCatch(
      inventoryCategoryService.deleteInventoryCategory,
      data,
      thunkAPI,
    );
    return deleteTech;
  },
);

export const updateInventoryCategory = createAsyncThunk(
  'updateInventoryCategory',
  async (data, thunkAPI) => {
    const updateTech = await smartTryCatch(
      inventoryCategoryService.updateInventoryCategory,
      data,
      thunkAPI,
    );
    return updateTech;
  },
);

export const inventoryCategorySlice = createSlice({
  name: 'inventoryCategory',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isInventoryCreateError = false;
      state.isInventoryCreateSuccess = false;
      state.isInventoryCreateLoading = false;
      state.isInventoryGetError = false;
      state.isInventoryGetSuccess = false;
      state.isInventoryGetLoading = false;
      state.isInventoryDeleteError = false;
      state.isInventoryDeleteSuccess = false;
      state.isInventoryDeleteLoading = false;
      state.isInventoryUpdateError = false;
      state.isInventoryUpdateSuccess = false;
      state.isInventoryUpdateLoading = false;
   
    },
    setInventory: (state, action) => {
      state.inventory = action.payload;
    },
    openInventoryCategoryModalDelete: (state) => {
      state.inventoryCategoryModalDelete = true;
    },
    openInventoryCategoryModalUpdate: (state) => {
      state.inventoryCategoryModalUpdate = true;
    },
    openInventoryCategoryModalCreate: (state) => {
      state.inventoryCategoryModalCreate = true;
    },
    closeInventoryCategoryModalDelete: (state) => {
      state.inventoryCategoryModalDelete = false;
    },
    closeInventoryCategoryModalUpdate: (state) => {
      state.inventoryCategoryModalUpdate = false;
    },
    closeInventoryCategoryModalCreate: (state) => {
      state.inventoryCategoryModalCreate = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createInventoryCategory.pending, (state) => {
        state.isInventoryCreateError = false;
        state.isInventoryCreateSuccess = false;
        state.isInventoryCreateLoading = true;
      })
      .addCase(createInventoryCategory.fulfilled, (state, action) => {
        state.isInventoryCreateError = false;
        state.isInventoryCreateSuccess = true;
        state.isInventoryCreateLoading = false;
        state.inventories.push(action.payload);
      })
      .addCase(createInventoryCategory.rejected, (state) => {
        state.isInventoryCreateError = true;
        state.isInventoryCreateSuccess = false;
        state.isInventoryCreateLoading = false;
      })
      .addCase(getInventoryCategoryById.pending, (state) => {
        state.isInventoryGetError = false;
        state.isInventoryGetSuccess = false;
        state.isInventoryGetLoading = true;
      })
      .addCase(getInventoryCategoryById.fulfilled, (state, action) => {
        state.isInventoryGetError = false;
        state.isInventoryGetSuccess = true;
        state.isInventoryGetLoading = false;
        state.inventories = action.payload;
      })
      .addCase(getInventoryCategoryById.rejected, (state) => {
        state.isInventoryGetError = true;
        state.isInventoryGetSuccess = false;
        state.isInventoryGetLoading = false;
      })
      .addCase(getInventoryCategoryByAuthentication.pending, (state) => {
        state.isInventoryGetLoading = true;
        state.isInventoryGetSuccess = false;
        state.isInventoryGetError = false;
      })
      .addCase(getInventoryCategoryByAuthentication.fulfilled, (state, action) => {
        state.isInventoryGetError = false;
        state.isInventoryGetSuccess = true;
        state.isInventoryGetLoading = false;
        state.inventories = action.payload;
      })
      .addCase(getInventoryCategoryByAuthentication.rejected, (state) => {
        state.isInventoryGetError = true;
        state.isInventoryGetSuccess = false;
        state.isInventoryGetLoading = false;
      })
      .addCase(deleteInventoryCategory.pending, (state) => {
        state.isInventoryDeleteError = false;
        state.isInventoryDeleteSuccess = false;
        state.isInventoryDeleteLoading = true;
      })
      .addCase(deleteInventoryCategory.fulfilled, (state, action) => {
        state.isInventoryDeleteError = false;
        state.isInventoryDeleteSuccess = true;
        state.isInventoryDeleteLoading = false;
        state.inventories = state.inventories.filter(
          (inventory) => inventory.id !== action.payload,
        );
      })
      .addCase(deleteInventoryCategory.rejected, (state) => {
        state.isInventoryDeleteError = true;
        state.isInventoryDeleteSuccess = false;
        state.isInventoryDeleteLoading = false;
      })
      .addCase(updateInventoryCategory.pending, (state) => {
        state.isInventoryUpdateError = false;
        state.isInventoryUpdateSuccess = false;
        state.isInventoryUpdateLoading = true;
      })
      .addCase(updateInventoryCategory.fulfilled, (state, action) => {
        state.isInventoryUpdateError = false;
        state.isInventoryUpdateSuccess = true;
        state.isInventoryUpdateLoading = false;
        state.inventories = state.inventories.map((inventory) =>
          inventory.id === action.payload.id ? action.payload : inventory,
        );
      })
      .addCase(updateInventoryCategory.rejected, (state) => {
        state.isInventoryUpdateError = true;
        state.isInventoryUpdateSuccess = false;
        state.isInventoryUpdateLoading = false;
      });
  },
});

export const {
  reset,
  setInventory,
  openInventoryCategoryModalDelete,
  openInventoryCategoryModalUpdate,
  openInventoryCategoryModalCreate,
  closeInventoryCategoryModalDelete,
  closeInventoryCategoryModalUpdate,
  closeInventoryCategoryModalCreate,
} = inventoryCategorySlice.actions;

export default inventoryCategorySlice.reducer;
