import React from 'react';

export default function TeamsIcon({ fillColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
      <path
        d="M8 9.83333C9.61083 9.83333 10.9167 8.52749 10.9167 6.91666C10.9167 5.30583 9.61083 3.99999 8 3.99999C6.38917 3.99999 5.08333 5.30583 5.08333 6.91666C5.08333 8.52749 6.38917 9.83333 8 9.83333ZM8 9.83333C10.2734 9.83333 12.1671 11.0525 12.5821 12.6667M8 9.83333C5.72663 9.83333 3.83294 11.0525 3.41789 12.6667M14.6666 8C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8C1.33325 4.3181 4.31802 1.33333 7.99992 1.33333C11.6818 1.33333 14.6666 4.3181 14.6666 8Z"
        stroke={fillColor === "primary" ? "var(--primary-color)" : "var(--grey-content)"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
