import React from 'react'

function AttachmentIcon({gray}) {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1025 7.26622L7.09206 13.2766C5.72523 14.6435 3.50915 14.6435 2.14231 13.2766C0.775479 11.9098 0.775479 9.69372 2.14231 8.32688L8.15272 2.31647C9.06394 1.40525 10.5413 1.40525 11.4526 2.31647C12.3638 3.2277 12.3638 4.70508 11.4526 5.61631L5.67785 11.391C5.22224 11.8466 4.48354 11.8466 4.02793 11.391C3.57232 10.9354 3.57232 10.1967 4.02793 9.74109L9.09553 4.6735" 
                stroke= {!gray ? "#D9314A" : "#999999"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>
    )
}

export default AttachmentIcon