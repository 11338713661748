/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyProfile } from '../redux/features/compay/companySlice';
import { getAnalyticsForDashboard } from '../redux/features/Analytics/analyticsSlice';

function getDataAnalyticPdf () {
  const formatDate = date => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  const today = new Date();
  const formattedDate = formatDate(today);
  const [workOrderInfo, setWorkOrderInfo] = useState({
    date: formattedDate,
    totalWorkOrder: 0,
    openWorkOrder: 0,
    overDueWorkOrder: 0,
    totalHours: 0,
    workOrder: {
      total: 0,
      open: 0,
      completed: 0,
    },
    pm: {
      total: 0,
      open: 0,
      completed: 0,
    },
  });
  const dispatch = useDispatch();
  const { analytics } = useSelector(state => state.analytics);
  // const { profile, isLoading, isError, isSuccess } = useSelector(state => state.company);
  // const { user } = useSelector(state => state.user);
  useEffect(() => {
    dispatch(getAnalyticsForDashboard());
    // dispatch(getCompanyProfile());
  }, []);
  useEffect(() => {
    setWorkOrderInfo({
      date: formattedDate,
      totalWorkOrder: analytics?.tickets_count?.total_tickets,
      openWorkOrder: analytics?.tickets_count?.total_open_tickets,
      overDueWorkOrder: analytics?.tickets_count?.total_open_tickets_overdue,
      totalHours: analytics?.tickets_count?.total_man_hours,
      workOrder: {
        total: analytics?.ticket_count_by_category?.total_workorder,
        open: analytics?.ticket_count_by_category?.total_open_workorder,
        completed: analytics?.ticket_count_by_category?.total_completed_workorder,
      },
      pm: {
        total: analytics?.ticket_count_by_category?.total_pm,
        open: analytics?.ticket_count_by_category?.total_open_pm,
        completed: analytics?.ticket_count_by_category?.total_completed_pm,
      },
    });
    // setInfo({
    //   name: profile?.name,
    //   logo: profile?.logo !== null && profile?.logo !== undefined ? profile?.logo : info.logo,
    //   address: profile?.address,
    //   phone: profile?.phone_number,
    //   user: user.first_name + ' ' + user.last_name,
    //   date: formattedDate,
    // });
  }, [analytics]);
  return workOrderInfo;
}

export default getDataAnalyticPdf;
