import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getWorkOrderValidatorByAuthentication  = async () => {
  const res = await http.get(
    `${BASE_URL}/location-approvers/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of workOrderType by its company ID failed",
    res
  );
};

const createWorkOrderValidator = async (data) => {
  const res = await http.post(
    `${BASE_URL}/location-approvers/`,
    data
  );

  return assert(res, res.data, "Creation of workOrderType failed", res);
};

const updateWorkOrderValidator = async (data) => {
  const res = await http.put(
    `${BASE_URL}/location-approvers/${data.id}/`,
    data
  );
  return assert(res, res.data, "Update of workOrderType failed", res);
};

const deleteWorkOrderValidator = async (data) => {
  const res = await http.delete(
    `${BASE_URL}/location-approvers/${data.id}/`
  );
  return assert(res, data, "Deletion of workOrderType failed", res);
};

const workOrderTypeService = {
  getWorkOrderValidatorByAuthentication,
  createWorkOrderValidator,
  deleteWorkOrderValidator,
  updateWorkOrderValidator
};

export default workOrderTypeService;