import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SLAservice from "./SLAService";
import { smartTryCatch } from '../../../utils';

const initState = {
  // SLA
  SLAs:[],
  SLA: {},
  errorMessage : "",

  title : "",
  priority: "",
  interval: "",
  timeUnit: "",


  // modal
  SLAModalCreate: false,
  SLAModalDelete: false,
  SLAModalUpdate: false,

  // success
  isSLAGetSuccess: false,
  isSLACreateSuccess: false,
  isSLADeleteSuccess: false,
  isSLAUpdateSuccess: false,

  // loading
  isSLAGetLoading: false,
  isSLADeleteLoading: false,
  isSLAUpdateLoading: false,
  isSLACreateLoading: false,

  // error
  isSLAGetError: false,
  isSLACreateError: false,
  isSLADeleteError: false,
  isSLAUpdateError: false,
};

export const getSLAsByAuthentication = createAsyncThunk(
  'getSLAsByAuthentication',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      SLAservice.getSLAsByAuthentication,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const getSLAById = createAsyncThunk(
  'getSLAById',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(SLAservice.getSLAById, data, thunkAPI);
    return res;
  },
);

export const createSLA = createAsyncThunk(
  'createSLA',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(SLAservice.createSLA, data, thunkAPI);
    return res;
  },
);

export const deleteSLA = createAsyncThunk('deleteSLA', async (data, thunkAPI) => {
    const res = await smartTryCatch(SLAservice.deleteSLA, data, thunkAPI);
    return res; 
  },
);

export const updateSLA = createAsyncThunk(
  'updateSLA',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(SLAservice.updateSLA, data, thunkAPI);
    return res;
  },
);

export const SLAslice = createSlice({
  name: 'SLA',
  initialState: initState,
  reducers: {
    setSLA: (state, action) => {
      state.SLA = action.payload;
    },
    openSLAModalDelete: state => {
      state.SLAModalDelete = true;
    },
    closeSLAModalDelete: state => {
      state.SLAModalDelete = false;
    },
    openSLAModalUpdate: state => {
      state.SLAModalUpdate = true;
    },
    closeSLAModalUpdate: state => {
      state.SLAModalUpdate = false;
    },
    openSLAModalCreate: state => {
      state.SLAModalCreate = true;
    },
    closeSLAModalCreate: state => {
      state.SLAModalCreate = false;
    },

    setTitle : (state, action) => {
      state.title = action.payload;
    },
    setPriority : (state, action) => {
      state.priority = action.payload;
    },
    setInterval : (state, action) => {
      state.interval = action.payload;
    },
    setTimeUnit : (state, action) => {
      state.timeUnit = action.payload;
    },

    reset: state => {
      state.isSLACreateError = false;
      state.isSLACreateSuccess = false;
      state.isSLACreateLoading = false;
      state.isSLAGetError = false;
      state.isSLAGetSuccess = false;
      state.isSLAGetLoading = false;
      state.isSLADeleteError = false;
      state.isSLADeleteSuccess = false;
      state.isSLADeleteLoading = false;
      state.isSLAUpdateError = false;
      state.isSLAUpdateSuccess = false;
      state.isSLAUpdateLoading = false;
      state.SLAModalCreate = false;
      state.SLAModalDelete = false;
      state.SLAModalUpdate = false;
      // state.SLA = {};
      // state.SLAName = '';
    },
    resetSLA :state => {
      state.title = ""
      state.priority = ""
      state.interval = ""
      state.timeUnit = ""
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getSLAsByAuthentication.pending, state => {
        state.isSLAGetLoading = true;
        state.isSLAGetSuccess = false;
        state.isSLAGetError = false;
      })
      .addCase(getSLAsByAuthentication.fulfilled, (state, action) => {
        state.isSLAGetLoading = false;
        state.isSLAGetSuccess = true;
        state.isSLAGetError = false;
        state.SLAs = action.payload;
      })
      .addCase(getSLAsByAuthentication.rejected, state => {
        state.isSLAGetLoading = false;
        state.isSLAGetSuccess = false;
        state.isSLAGetError = true;
      })
      .addCase(getSLAById.pending, state => {
        state.isSLAGetLoading = true;
        state.isSLAGetSuccess = false;
        state.isSLAGetError = false;
      })
      .addCase(getSLAById.fulfilled, (state, action) => {
        state.isSLAGetLoading = false;
        state.isSLAGetSuccess = true;
        state.isSLAGetError = false;
        state.SLAs = action.payload;
      })
      .addCase(getSLAById.rejected, state => {
        state.isSLAGetLoading = false;
        state.isSLAGetSuccess = false;
        state.isSLAGetError = true;
      })
      .addCase(createSLA.pending, state => {
        state.isSLACreateLoading = true;
        state.isSLACreateSuccess = false;
        state.isSLACreateError = false;
      })
      .addCase(createSLA.fulfilled, (state, action) => {
        state.isSLACreateLoading = false;
        state.isSLACreateSuccess = true;
        state.isSLACreateError = false;
        state.SLAs.push(action.payload);
      })
      .addCase(createSLA.rejected, (state, action) => {
        state.isSLACreateLoading = false;
        state.isSLACreateSuccess = false;
        state.isSLACreateError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteSLA.pending, state => {
        state.isSLADeleteLoading = true;
        state.isSLADeleteSuccess = false;
        state.isSLADeleteError = false;
      })
      .addCase(deleteSLA.fulfilled, (state, action) => {
        state.isSLADeleteLoading = false;
        state.isSLADeleteSuccess = true;
        state.isSLADeleteError = false;
        state.SLAs = state.SLAs.filter(
          SLA => SLA.id !== action.payload,
        );
      })
      .addCase(deleteSLA.rejected, state => {
        state.isSLADeleteLoading = false;
        state.isSLADeleteSuccess = false;
        state.isSLADeleteError = true;
      })
      .addCase(updateSLA.pending, state => {
        state.isSLAUpdateLoading = true;
        state.isSLAUpdateSuccess = false;
        state.isSLAUpdateError = false;
      })
      .addCase(updateSLA.fulfilled, (state, action) => {
        state.isSLAUpdateLoading = false;
        state.isSLAUpdateSuccess = true;
        state.isSLAUpdateError = false;
        state.SLAs = state.SLAs.map(SLA =>
          SLA.id === action.payload.id ? action.payload : SLA,
        );
      })
      .addCase(updateSLA.rejected, state => {
        state.isSLAUpdateLoading = false;
        state.isSLAUpdateSuccess = false;
        state.isSLAUpdateError = true;
      });
  },
});

export const {
  setSLA,
  openSLAModalDelete,
  closeSLAModalDelete,
  openSLAModalUpdate,
  closeSLAModalUpdate,
  openSLAModalCreate,
  closeSLAModalCreate,
  reset,
  resetSLA,
  setTitle,
  setPriority,
  setInterval,
  setTimeUnit,
} = SLAslice.actions;

export default SLAslice.reducer;
