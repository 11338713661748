import React, { useState } from 'react';
import { toast } from "react-toastify"
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Inbox } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import CheckoutAssetModal from '../popUpModals/assetLoanModal/checkoutAsset';
import { openAssetLoanStatusModalCreate, closeAssetLoanStatusModalCreate, checkOutAsset, openAssetLoanCheckinModalCreate, closeAssetLoanCheckinModalCreate, checkInAsset, reset } from '../../redux/features/assetLoanStatus/assetLoanStatusSlice';
import CheckinAssetModal from '../popUpModals/assetLoanModal/checkinAsset';


export default function AssetLoan({ id }) {
  const dispatch = useDispatch()
  const { assetLoanStatusModalCreate, assetLoanChekinModalCreate, isAssetCheckoutGetError, isAssetCheckoutGetSuccess,
    isAssetCheckinGetError, isAssetCheckinGetSuccess } = useSelector(state => state.assetLoanStatus);
  const { asset } = useSelector(state => state.asset)
  const [CheckOutComment, setCheckOutComment] = useState('')
  const [CheckInComment, setCheckInComment] = useState('')

  const handleOpenModal = () => {
    dispatch(openAssetLoanStatusModalCreate())
  }
  const handleOpenCheckinModal = () => {
    dispatch(openAssetLoanCheckinModalCreate())
  }
  const handleCloseCheckinModal = () => {
    dispatch(closeAssetLoanCheckinModalCreate())
    setCheckInComment('')
  }
  const handleCloseModal = () => {
    dispatch(closeAssetLoanStatusModalCreate())
    setCheckOutComment('')
  }

  const handleCheckoutComment = (e) => {
    setCheckOutComment(e.target.value)
  }
  const handleCheckInComment = (e) => {
    setCheckInComment(e.target.value)
  }

  const handleCheckout = () => {
    dispatch(checkOutAsset({ asset: id, check_out_comment: CheckOutComment }))
  }
  const handleCheckin = () => {
    dispatch(checkInAsset({ asset: id, check_in_comment: CheckInComment }))
  }

  function displayToast() {
    if (isAssetCheckoutGetError) {
      toast.error('Something went wrong on Asset Check-Out', {
        toastId: 'CreatedAssetError',
      });
      dispatch(reset())
    } if (isAssetCheckinGetError) {
      toast.error('Something went wrong on Asset Check-In', {
        toastId: 'CreatedAssetError',
      });
      dispatch(reset())
    } else if (isAssetCheckoutGetSuccess) {
      toast.success('Asset Check-Out Created successfully', {
        toastId: 'Asset Created Success',
      });
      dispatch(closeAssetLoanStatusModalCreate())
      dispatch(reset())
    }
    else if (isAssetCheckinGetSuccess) {
      toast.success('Asset Check-In Created successfully', {
        toastId: 'Asset Created Success',
      });
      dispatch(closeAssetLoanCheckinModalCreate())
      dispatch(reset())
    }
  }

  return (
    <Container fluid className="mb-0">
      {displayToast()}
      <p>Current Status:</p>
      <Row className="align-items-center">
        <Col xs={12} sm={6} md={4} lg={3} xl={2}>
          <Button
            size="lg"
            style={{margin:0, padding: 10,minWidth:'120%',whiteSpace:'nowrap' }}
            disabled
            variant={asset?.loan_status !== "Checked In" ? "danger" : "success"}
          >
            {asset?.loan_status}
          </Button>
        </Col>
        <Col
          xs={12}
          sm={6}
          md={8}
          lg={9}
          xl={10}
          className="d-flex justify-content-center justify-content-md-end"
        >
          {asset?.loan_status !== "Checked In" ? (
            <Button
              style={{ padding:5, marginLeft: '5px' }}
              size="sm"
              variant="light"
              onClick={handleOpenCheckinModal}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                <Inbox size={10} />
                <span style={{ marginTop: '5px', color: 'black' }}>Check-In</span>
              </div>
            </Button>
          ) : (
            <Button
              style={{ padding: 5, marginLeft: '5px' }}
              size="sm"
              variant="light"
              onClick={handleOpenModal}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                <Inbox size={10} />
                <span style={{ marginTop: '5px', color: 'black' }}>Check-Out</span>
              </div>
            </Button>
          )}
        </Col>
      </Row>
      <CheckoutAssetModal
        handelComment={handleCheckoutComment}
        showLoanModal={assetLoanStatusModalCreate}
        handleCheckOut={handleCheckout}
        handleClose={handleCloseModal}
      />
      <CheckinAssetModal
        handelComment={handleCheckInComment}
        showLoanModal={assetLoanChekinModalCreate}
        handleCheckOut={handleCheckin}
        handleClose={handleCloseCheckinModal}
      />
    </Container>
  );
};

