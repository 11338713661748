// const exampleDataPM = [
//   {
//     id: 67,
//     title: 'test weekly on specific',
//     assignedPersons: [
//       {
//         id: 2,
//         name: 'some name',
//         image: '/media/no_profile.png',
//         role: 'CliAdm',
//       },
//     ],
//     start_date: null,
//     created_at: '2024-10-15T14:42:48.356129+08:00',
//     asset: 'asset A',
//     assetId: '771218961918',
//     status: 'In-Progress',
//     priority: 'Low',
//     category: 'Preventive',
//     location: 'location A',
//     completedDate: '—',
//     frequencyNumber: 'Every 1 Week, on Tuesday',
//     frequencyType: 'Weekly',
//     startSchedule: '2024-10-01T21:42:00+08:00',
//     endSchedule: '2024-10-31T21:42:00+08:00',
//     ticketNumber: 'PM2410150004',
//   },
// ];

const exampleDataEvent = [
  {
    id: 1,
    title: 'Quarterly Financial Review',
    description: 'Review the company\'s financial performance for the past quarter.',
    startSchedule: '2024-01-01',
    endSchedule: '2024-01-03',
    reminder: '6 Hour before',
    created_by: 'Tegar',
    status: 'In-Progress'
  }
]

export default exampleDataEvent;