/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Card from '../../../Ui/Card';
import classes from '../addAsset/addAssetForm/assetForm.module.css';

function FetchAssetSpecificationsForm ({
  inputSp,
  inputList,
  addInputHandler,
  removeInputHandler,
  handelSpChange,
}) {
  return (
    <div className={classes.assetForm}>
      <Card>
        <div className={classes.assetInfo}>
          <div className={classes.titleAssetDocuments}>
            <h3>Specifications</h3>
          </div>

          {/* <div className={classes.controlsSp}> */}
            {/* <div className={classes.controlSp}>
              <p>Field</p>
              <input
                name="field"
                type="text"
                // eslint-disable-next-line no-unneeded-ternary
                value={inputSp ? inputSp : null}
                onChange={e => handelSpChange(e)}
                placeholder="Field"
              />
            </div> */}
                      <div className={classes.textarea}>
            <p>
              Field
            </p>
            <textarea style={{
              width: '100%',
              height: '100px'
            }} value={inputSp ||null} onChange={e => handelSpChange(e)} name='field' placeholder="Field" />
          </div>
            {/* <button
                    className={classes.controlSpBtn}
                    onClick={() => removeInputHandler(input?.id, i)}
                    type="button"
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button> */}
          {/* </div> */}

          {/* <div className={classes.actions}>
            <button onClick={addInputHandler} className="primary-btn btn" type="button">
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div> */}
        </div>
      </Card>
    </div>
  );
}

export default FetchAssetSpecificationsForm;
