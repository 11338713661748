import React from 'react'
import { useSelector } from 'react-redux'
import DueOnDateDropdown from './DueOnDateDropdown'
import DueOnDayDropdown from './DueOnDayDropdown'
import WeeklyTicketSwitches from './WeeklyTicketSwitches'
import { SelectRepetitionAndPriority } from '../../../components/select/select'
import MinusIcon from '../../../assets/svg-icons/MinusIcon'
import PlusIcon from '../../../assets/svg-icons/PlusIcon'
import { setFrequency } from '../../../redux/features/maintenance/maintenanceSlice'

/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */

function Schedule({
    handelStartDate,
    handelEndDate,
    handelInterval,
    toggleDay,
    selectedDays,
    selectedDay,
    handleDayChange,
    selectTheDay,
    selectTheMonth,
    handleSelectDayChange,
    handleSelectMonthChange,
    handelCounter,
    editPM
}) {
    const repetitionData = [
        { value: "day", label: "Daily" },
        { value: "week", label: "Weekly" },
        { value: "month", label: "Monthly" },
        { value: "year", label: "Yearly" }
    ]

    const {
        start_date,
        end_date,
        frequency_number,
        frequency,
    } = useSelector(state => state.maintenance);

    return (
        <div className="row">
            <div className=" col-lg-12 col-md-12 col-sm-12 note-for-pm">
                The system will automatically generate preventive maintenance tickets according to the selected dates and frequency.
            </div>
            <div className=" col-lg-6 col-md-6 col-sm-12 pt-3">
                <label className="font-weight-normal">
                    Start date<label className="font-danger m-0">*</label>
                </label>
                <input
                    type="datetime-local"
                    className="form-control mb-2 the-fixed-input"
                    name="service_date"
                    id="meter_service_date"
                    placeholder="Start Date"
                    required
                    value={start_date}
                    onChange={handelStartDate}
                    disabled={editPM}
                />
            </div>
            <div className=" col-lg-6 col-md-6 col-sm-12 pt-3">
                <label className="font-weight-normal">
                    End date<label className="font-danger m-0">*</label>
                </label>
                <input
                    type="datetime-local"
                    className="form-control mb-2 the-fixed-input"
                    name="service_date"
                    id="PM_service_date"
                    placeholder="End Date"
                    required
                    value={end_date}
                    onChange={handelEndDate}
                    disabled={editPM}
                />
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 pt-3">
                <label className="font-weight-normal">
                    Choose Repetition<label className="font-danger m-0">*</label>
                </label>
                <div className='repetition'>
                    <SelectRepetitionAndPriority editPM={editPM} data={repetitionData} setState={setFrequency} state={frequency} />
                </div>
            </div>
            <div className=" col-lg-12 col-md-12 col-sm-12 pt-3 frq-content">
                <h5>Frequency</h5>
                <label className="font-weight-normal pt-3">
                    Set daily frequency <label className="font-danger m-0">*</label>
                </label>
                <div className='repeat-every'>
                    <div className='repeat-every-box'>
                        <span>Repeat every</span>
                        <input disabled={editPM} className='no-spinner' type="number" value={frequency_number} onChange={handelInterval}
                        />
                        <span>{frequency === "day" ? "Days" : frequency === "week" ? "Weeks" : frequency === "month" ? "Months" : "Years"}</span>
                    </div>
                    <button disabled={editPM} onClick={() => handelCounter("decrease")} type='button'><MinusIcon /></button>
                    <button disabled={editPM} onClick={() => handelCounter("increase")} type='button'><PlusIcon /></button>
                </div>
                {frequency === "week" &&
                    <WeeklyTicketSwitches
                        toggleDay={toggleDay}
                        selectedDays={selectedDays}
                        editPM={editPM}
                    />
                }
                {frequency === "month" &&
                    <DueOnDayDropdown
                        selectedDay={selectedDay}
                        handleDayChange={handleDayChange}
                        editPM={editPM}
                    />
                }
                {frequency === "year" &&
                    <DueOnDateDropdown
                        selectTheDay={selectTheDay}
                        selectTheMonth={selectTheMonth}
                        handleSelectDayChange={handleSelectDayChange}
                        handleSelectMonthChange={handleSelectMonthChange}
                        editPM={editPM}
                    />
                }
                {/* <div className='note-for-weekend pt-3'>
                    <span>NOTE: </span>
                    <p> If the due date falls on a weekend, it will move to the nearest weekday, which is Monday.</p>
                </div> */}
            </div>
        </div>
    )
}

export default Schedule