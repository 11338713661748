/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable  no-lone-blocks */
/* eslint-disable  no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable no-unsafe-optional-chaining */

import React from 'react';
import parse from 'html-react-parser';
import { useTable, useExpanded, useGlobalFilter } from 'react-table';
import classes from '../../components/assetsTable/assetsTable.module.css';
import EpqFilter from './epqFilter';

function EpqTable({
    getSelectedRowwValues,
    iSShowFilter,
    setISShowFilter,
    setIsLoading,
    getDataRows,
    data,
    columns
}) {

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, allColumns } = useTable(
        {
            columns,
            data,
            manualPagination: true,
        },
        useGlobalFilter,
        useExpanded,
    );

    return (
        <>
            <EpqFilter
                getDataRows={getDataRows}
                setISShowFilter={setISShowFilter}
                iSShowFilter={iSShowFilter}
                allColumns={allColumns}
                setIsLoading={setIsLoading} />
            <div className={classes.scrollX}>
                <table {...getTableProps()} className={classes.table} style={{ whiteSpace: "wrap" }}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>{column?.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.length !== 0 ? (
                            rows?.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    onClick={() => getSelectedRowwValues ? getSelectedRowwValues(row.original, cell) : ""}
                                                    style={cell.column?.Header === 'Source' ? { whiteSpace: "nowrap" } : {}}
                                                >
                                                    {cell.column.Header === "Activity" && row.original?.activity !== null ?
                                                        <p style={{ fontWeight: "bold", marginBottom: 0 }}>{parse(row?.original?.activity)}</p>
                                                        : cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                {' '}
                                <td className={classes.noResults} colSpan="6">
                                    No Results Found{' '}
                                </td>{' '}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default EpqTable;
