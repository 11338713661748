/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable  no-lone-blocks */
/* eslint-disable  no-unused-expressions */
/* eslint-disable  react/self-closing-comp */
/* eslint-disable no-unused-vars */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencil } from '@fortawesome/free-solid-svg-icons';
import { useTable, useExpanded, useGlobalFilter } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import classes from '../assetsTable/assetsTable.module.css';
import { openSiteModeul, setLocation } from '../../redux/features/location/locationSlice';

function LocationTable ({closeAdd,handlehow,isShowAdd, locations,setSearchBar }) {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
 const { locations : locs } = useSelector(state => state.location);

    const handleLocation = (id) =>{
      dispatch(setSearchBar(false))
        const location = locs.find(e=>e?.id===id)
        dispatch(setLocation(location))
        handlehow()
        closeAdd()
        dispatch(openSiteModeul())
    }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Location',
        accessor: 'site',
      },
      {
        Header: 'Owner',
        accessor: 'owner',
      },
      {
        Header: 'Tenant',
        accessor: 'tenant',
      },
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Duration',
        accessor: 'duration',
      },
    ],
    [user],
  );

  const data = locations;
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, allColumns } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    useGlobalFilter,
    useExpanded,
  );

  return (
    <>
      <div className={classes.scrollX}>
        <table {...getTableProps()} className={classes.table}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.length !== 0 ? (
              rows?.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}
                            onClick={() => handleLocation(row.original.id)}
                        >
                          <span>{cell.render('Cell')}</span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                {' '}
                <td className={classes.noResults} colSpan="9">
                  No Results Found{' '}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default LocationTable;
