import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import { Link, useParams, useLocation } from 'react-router-dom'
import { IconButton } from '@material-ui/core';

import WorkData from '../workData'
import BillsItems from '../billsItems'
import Penalty from '../penalty'
import Summary from '../epqSummry'
import BackWordArrowIcon from '../../../assets/svg-icons/BackWordArrowIcon'
import Surcharge from '../surcharge'
import SaveIcon from '../../../assets/svg-icons/SaveIcon'
import { createBill, getBillById, reset, resetState, setIsPrint, updateBill } from '../../../redux/features/SOR/SORSlice'
import Loader from '../../../components/loader/loader'
import migrateBillData from '../ebqUtils';
import EbqPdf from '../ebqPdf';
import NewModal from '../../../components/modals/NewModal';
import PrintIcon from '../../../assets/svg-icons/PrintIcon';


/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unescaped-entities */

function bill() {
  const dispatch = useDispatch()
  const { id } = useParams();
  const { pathname } = useLocation()
  const pdf = true
  const isPreview = pathname?.split("/")[3] === "preview"
  const [savePopUp, setSavePopUp] = useState("")
  const [modalbodyMsg, setModalBodyMsg] = useState("")
  const [modalTitle, setModalTitle] = useState("")


  const {
    billData,
    billTotalPrice,
    billWorkData,
    surchargeRates,
    penaltyData,
    subTotal,
    surchargeTotalPrice,
    penaltyTotalPrice,
    isBillCreateSuccess,
    isBillCreateError,
    singleBill,
    isBillGetLoading,
    isPrint
  } = useSelector(state => state.SOR)

  useEffect(() => {
    if (id) {
      dispatch(getBillById(id))
    }
  }, [id])





  useEffect(() => {
    if (singleBill) {
      migrateBillData(singleBill, dispatch)
    }
  }, [singleBill])

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    onAfterPrint: () => {
      dispatch(resetState())
    }
  });

  const SaveDraft = () => {
    setSavePopUp("Draft")
    setModalBodyMsg("You can still edit the bill when you save it as a draft.")
    setModalTitle("Save as draft?")
  }
  const SavePublished = () => {
    dispatch(setIsPrint(true))
    setSavePopUp("Published")
    setModalBodyMsg("Once published, you can't edit the form anymore. To make changes, you will need to create a new bill.")
    setModalTitle("Publish the bill?")
  }

  const handleSave = () => {
    const objData = {
      "work_title": billWorkData.title,
      "work_description": billWorkData.description,
      "outline_agreement_no": billWorkData.agreementNo,
      "job_num": billWorkData.jobNum,
      "work_order_cmms": billWorkData.order,
      "work_order_type": billWorkData.orderType,
      "location": billWorkData.location,
      "surcharges": surchargeRates?.map(item => {
        return {
          "code": item.code,
          "description": item.description,
          "rate": item.rate,
          "total_price_first": +item.totalRateBysource,
          "total_price_second": +item.totalRateBysourceVendor
        }
      }),
      "penalties": penaltyData?.map(item => {
        return {
          "parameter": item.parameter,
          "period_start": item.startDate || null,
          "period_end": item.endDate || null,
          "is_active": item.active,
          "penalty_type": item.penaltyQuantumType === "price" ? "PRICE" : "PERCENTAGE",
          "penalty_value": (+item.penaltyQuantumRate).toFixed(2),
          "total_price": +(
            item?.penaltyQuantumType === "price"
              ? parseFloat(item?.penaltyQuantumRate || 0) // Ensure penaltyQuantumRate is a valid number
              : (parseFloat(item?.penaltyQuantumRate || 0) * parseFloat(subTotal?.subTotalVendor || 0)) / 100
          ).toFixed(2)
        }
      }),
      "bill_sor_items": billData?.map(item => {
        return {
          "sor": item.id,
          "quantity_first": +item.quantity,
          "total_price_first": +item.totalPrice,
          "quantity_second": +item.vendorQuantity,
          "total_price_second": +item.vendorTotalPrice,
          "discount_rate": (+item.discountRate).toFixed(2),
        }
      }),
      "total_bills_first": +billTotalPrice?.billTotalOrder?.toFixed(2),
      "total_bills_second": +billTotalPrice?.billTotalVendor?.toFixed(2),
      "total_surcharge_first": +surchargeTotalPrice?.surchargeTotalOrder.toFixed(2),
      "total_surcharge_second": +surchargeTotalPrice?.surchargeTotalVendor.toFixed(2),
      "sub_total_first": +subTotal?.subTotalOrder.toFixed(2),
      "sub_total_second": +subTotal?.subTotalVendor.toFixed(2),
      "total_penalty": +penaltyTotalPrice.toFixed(2),
      "grand_total_first": +subTotal?.subTotalOrder.toFixed(2),
      "grand_total_second": +(subTotal?.subTotalVendor - penaltyTotalPrice).toFixed(2),
      "status": "draft",
    }
    if (savePopUp === "Published") {
      objData.status = "published"
    }
    if (id) {
      dispatch(updateBill({ id, ...objData }))
    } else {
      dispatch(createBill(objData))
    }
  }

  useEffect(() => {
    if (isBillCreateSuccess) {
      if (isPrint) {
        handlePrint()
      }
      dispatch(resetState())
      dispatch(reset())
      setSavePopUp("")
      setModalBodyMsg("")
      setModalTitle("")
    }
    else if (isBillCreateError) {
      toast.error('Something went wrong Please fill the required fields and try again', {
        toastId: 'integrationSyncStarted',
      })
      setSavePopUp("")
      setModalBodyMsg("")
      setModalTitle("")
      dispatch(reset())
    }
  }, [isBillCreateSuccess, isBillCreateError])

  return (
    isBillGetLoading ?
      <Loader /> :
      <>
        {savePopUp && (
          <NewModal
            showModal={savePopUp}
            handleClose={() => setSavePopUp("")}
            handleCreateSave={handleSave}
            primaryButtonText="Save"
            modalTitle={modalTitle}
          >
            {modalbodyMsg}
          </NewModal>
        )}
        <div style={isPreview ? { display: "none" } : {}} className='p-2'>
          {(billData?.length > 0 && singleBill?.status !== "published") ?
            <>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link to="/ebq">
                    <button type='button' className='new-btn'><BackWordArrowIcon /></button>
                  </Link>
                  <h5 className='ml-2 mb-0'>Bills summary</h5>
                </div>
                {singleBill?.status === "draft" &&
                  <button className='new-btn'
                    style={{ backgroundColor: "#72747829", borderColor: "white", width: "70px", padding: "4px 8px" }}
                    type='button'
                  >
                    Draft
                  </button>}
              </div>
              <WorkData />
              <hr />
              <BillsItems />
              <hr />
              <Surcharge />
              <hr />
              <Penalty />
              <hr />
              <Summary />
              <div style={{
                marginBottom: "100px",
                display: 'flex',
                justifyContent: "flex-end",
                marginTop: "20px"
              }}>
                <IconButton variant="contained"
                  style={{
                    backgroundColor: "white",
                    border: '1px solid #E1E1E1',
                    borderRadius: '8px',
                  }}
                  onClick={SaveDraft}
                  className='outlined-button  mr-2'
                >
                  <PrintIcon />
                </IconButton>
                <IconButton variant="contained"
                  style={{
                    backgroundColor: "#D9314A",
                    border: '1px solid #white',
                    borderRadius: '8px',
                  }}
                  onClick={SavePublished}
                  className='outlined-button'
                >
                  <SaveIcon />
                </IconButton>
              </div>
            </> :
            <div style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center"
            }}>
              <h4>{singleBill?.status === "published" ? "This bill is already published" : "There's no items on the bill"}</h4>
              <Link to="/ebq">
                <button type='button' className='new-btn' >
                  Back to SOR Page
                </button>
              </Link>
            </div>
          }
        </div>
        <EbqPdf isPreview={isPreview} ref={componentRef} pdf={pdf} billWorkData={billWorkData} />

      </>
  )
}

export default bill