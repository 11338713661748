/* eslint-disable max-len */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import meterMeasurementService from './meterMeasurementService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // data
  meterMeasurements: [],
  meterMeasurement: {},

  // fields
  meterMeasurementName: '',

  // modal
  meterMeasurementModalCreate: false,
  meterMeasurementModalUpdate: false,
  meterMeasurementModalDelete: false,

  // success
  isMeterMeasurementGetSuccess: false,
  isMeterMeasurementDeleteSuccess: false,
  isMeterMeasurementCreateSuccess: false,
  isMeterMeasurementUpdateSuccess: false,

  // loading
  isMeterMeasurementCreateLoading: false,
  isMeterMeasurementDeleteLoading: false,
  isMeterMeasurementGetLoading: false,
  isMeterMeasurementUpdateLoading: false,

  // error
  isMeterMeasurementCreateError: false,
  isMeterMeasurementGetError: false,
  isMeterMeasurementDeleteError: false,
  isMeterMeasurementUpdateError: false,
};

export const createMeterMeasurement = createAsyncThunk(
  'createMeterMeasurement',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(meterMeasurementService.createMeterMeasurement, data, thunkAPI);
    return res;
  },
);

export const getMeterMeasurementByAuthentication = createAsyncThunk(
  'getMeterMeasurementByAuthentication',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      meterMeasurementService.getMeterMeasurementByAuthentication,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const getMeterMeasurementById = createAsyncThunk(
  'getMeterMeasurementById',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      meterMeasurementService.getMeterMeasurementById,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const deleteMeterMeasurement = createAsyncThunk(
  'deleteMeterMeasurement',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(meterMeasurementService.deleteMeterMeasurement, data, thunkAPI);
    return res;
  },
);

export const updateMeterMeasurement = createAsyncThunk(
  'updateMeterMeasurement',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(meterMeasurementService.updateMeterMeasurement, data, thunkAPI);
    return res;
  },
);

export const meterMeasurementSlice = createSlice({
  name: 'meterMeasurement',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isMeterMeasurementCreateError = false;
      state.isMeterMeasurementCreateSuccess = false;
      state.isMeterMeasurementCreateLoading = false;
      state.isMeterMeasurementGetError = false;
      state.isMeterMeasurementGetSuccess = false;
      state.isMeterMeasurementGetLoading = false;
      state.isMeterMeasurementDeleteError = false;
      state.isMeterMeasurementDeleteSuccess = false;
      state.isMeterMeasurementDeleteLoading = false;
      state.isMeterMeasurementUpdateError = false;
      state.isMeterMeasurementUpdateSuccess = false;
      state.isMeterMeasurementUpdateLoading = false;
      state.meterMeasurement = null;
    },
    openMeterMeasurementModalCreate: (state) => {
      state.meterMeasurementModalCreate = true;
    },
    closeMeterMeasurementModalCreate: (state) => {
      state.meterMeasurementModalCreate = false;
    },
    openMeterMeasurementModalUpdate: (state) => {
      state.meterMeasurementModalUpdate = true;
    },
    closeMeterMeasurementModalUpdate: (state) => {
      state.meterMeasurementModalUpdate = false;
    },
    openMeterMeasurementModalDelete: (state) => {
      state.meterMeasurementModalDelete = true;
    },
    closeMeterMeasurementModalDelete: (state) => {
      state.meterMeasurementModalDelete = false;
    },
    setMeterMeasurement: (state, action) => {
      state.meterMeasurement = action.payload;
    },
    setMeterMeasurementName: (state, action) => {
      state.meterMeasurementName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMeterMeasurementByAuthentication.pending, (state) => {
        state.isMeterMeasurementGetLoading = true;
        state.isMeterMeasurementGetSuccess = false;
        state.isMeterMeasurementGetError = false;
      })
      .addCase(getMeterMeasurementByAuthentication.fulfilled, (state, action) => {
        state.isMeterMeasurementGetLoading = false;
        state.isMeterMeasurementGetSuccess = true;
        state.isMeterMeasurementGetError = false;
        state.meterMeasurements = action.payload;
      })
      .addCase(getMeterMeasurementByAuthentication.rejected, (state) => {
        state.isMeterMeasurementGetLoading = false;
        state.isMeterMeasurementGetSuccess = false;
        state.isMeterMeasurementGetError = true;
      })
      .addCase(getMeterMeasurementById.pending, (state) => {
        state.isMeterMeasurementGetLoading = true;
        state.isMeterMeasurementGetSuccess = false;
        state.isMeterMeasurementGetError = false;
      })
      .addCase(getMeterMeasurementById.fulfilled, (state, action) => {
        state.isMeterMeasurementGetLoading = false;
        state.isMeterMeasurementGetSuccess = true;
        state.isMeterMeasurementGetError = false;
        state.meterMeasurements = action.payload;
      })
      .addCase(getMeterMeasurementById.rejected, (state) => {
        state.isMeterMeasurementGetLoading = false;
        state.isMeterMeasurementGetSuccess = false;
        state.isMeterMeasurementGetError = true;
      })
      .addCase(createMeterMeasurement.pending, (state) => {
        state.isMeterMeasurementCreateLoading = true;
        state.isMeterMeasurementCreateSuccess = false;
        state.isMeterMeasurementCreateError = false;
      })
      .addCase(createMeterMeasurement.fulfilled, (state, action) => {
        state.isMeterMeasurementCreateLoading = false;
        state.isMeterMeasurementCreateSuccess = true;
        state.isMeterMeasurementCreateError = false;
        state.meterMeasurements.push(action.payload);
      })
      .addCase(createMeterMeasurement.rejected, (state) => {
        state.isMeterMeasurementCreateLoading = false;
        state.isMeterMeasurementCreateSuccess = false;
        state.isMeterMeasurementCreateError = true;
      })
      .addCase(deleteMeterMeasurement.pending, (state) => {
        state.isMeterMeasurementDeleteLoading = true;
        state.isMeterMeasurementDeleteSuccess = false;
        state.isMeterMeasurementDeleteError = false;
      })
      .addCase(deleteMeterMeasurement.fulfilled, (state, action) => {
        state.isMeterMeasurementDeleteLoading = false;
        state.isMeterMeasurementDeleteSuccess = true;
        state.isMeterMeasurementDeleteError = false;
        state.meterMeasurements = state.meterMeasurements.filter(
          (meterMeasurement) => meterMeasurement.id !== action.payload,
        );
      })
      .addCase(deleteMeterMeasurement.rejected, (state) => {
        state.isMeterMeasurementDeleteLoading = false;
        state.isMeterMeasurementDeleteSuccess = false;
        state.isMeterMeasurementDeleteError = true;
      })
      .addCase(updateMeterMeasurement.pending, (state) => {
        state.isMeterMeasurementUpdateLoading = true;
        state.isMeterMeasurementUpdateSuccess = false;
        state.isMeterMeasurementUpdateError = false;
      })
      .addCase(updateMeterMeasurement.fulfilled, (state, action) => {
        state.isMeterMeasurementUpdateLoading = false;
        state.isMeterMeasurementUpdateSuccess = true;
        state.isMeterMeasurementUpdateError = false;
        state.meterMeasurements = state.meterMeasurements.map((meterMeasurement) => (meterMeasurement.id === action.payload.id ? action.payload : meterMeasurement));
      })
      .addCase(updateMeterMeasurement.rejected, (state) => {
        state.isMeterMeasurementUpdateLoading = false;
        state.isMeterMeasurementUpdateSuccess = false;
        state.isMeterMeasurementUpdateError = true;
      });
  },
});

export const {
  reset,
  openMeterMeasurementModalCreate,
  closeMeterMeasurementModalCreate,
  openMeterMeasurementModalUpdate,
  closeMeterMeasurementModalUpdate,
  openMeterMeasurementModalDelete,
  closeMeterMeasurementModalDelete,
  setMeterMeasurement,
  setMeterMeasurementName,
} = meterMeasurementSlice.actions;

export default meterMeasurementSlice.reducer;