/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Modal } from 'react-bootstrap/';

function PopUpAddErrorModal({
  showModal,
  handleClose,
  modalTitle,
  children,
}) {
  return (
    <Modal show={showModal} onHide={handleClose}>
        <Modal.Header style={{background : "#F63854"}} closeButton>
          <Modal.Title style={{color : "white"}}>{modalTitle}</Modal.Title>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={handleClose}
          >
            <span style={{color : "white"}} aria-hidden='true'>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default PopUpAddErrorModal;