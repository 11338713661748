import React from 'react'
import { Mention, MentionsInput } from 'react-mentions';
import { useDispatch } from 'react-redux';
import defaultMentionStyle from './defaultMentionStyle'
import defaultStyle from './defaultStyle'
import { getPagination } from '../../../../../redux/features/teams/teamSlice';

function CommentInput({state, setState }) {
    const dispatch = useDispatch();
    const fetchUsers = async (query, callback) => {
        const res = await dispatch(
            getPagination({ rowPerPage: 5, currentPage: 1, searchValue: query }),
        );
        const filteredUsers = res.payload.results.map((member) => {
            return { id: member.id, display: `${member?.first_name} ${member?.last_name}` }
        })
        callback(filteredUsers);
    };
    return (
        <div className="comment-section-comment-input" >
            <MentionsInput
                style={defaultStyle}
                value={state}
                onChange={(e) => setState(e.target.value)}
                // singleLine
                placeholder="Add a comment"
            >
                <Mention style={defaultMentionStyle} data={fetchUsers} />
            </MentionsInput>
        </div>
    )
}

export default CommentInput