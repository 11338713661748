import React from 'react'
/* eslint-disable no-unneeded-ternary  */

function TooltipIcon({ color }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.9987 10.6733V7.34001M7.9987 5.34001V5.33334M14.6654 8.00016C14.6654 11.6821 11.6806 14.6668 7.9987 14.6668C4.3168 14.6668 1.33203 11.6821 1.33203 8.00016C1.33203 4.31826 4.3168 1.3335 7.9987 1.3335C11.6806 1.3335 14.6654 4.31826 14.6654 8.00016Z" 
        stroke={color ? color : "#9DA0A6"} 
        strokeWidth="1.5" 
        strokeLinecap="round"/>
        </svg>
    )
}

export default TooltipIcon