/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-template */

import React from 'react';
import { Drawer } from '@material-ui/core';
import './EditPMModal.css';
import moment from 'moment';
// import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

function EditPMModal({ openModalUpdate, closeModalUpdate }) {
  const { maintenance } = useSelector((state) => state.maintenance);

  const handelSubmit = async (e) => {
    e.preventDefault();
  }

  return (
    <Drawer
      anchor="right"
      open={openModalUpdate}
      onClose={closeModalUpdate}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '600px',
          height: '100%',
        },
      }}
    >
      <div className="h-100 w-100">
        <div className="navbar col-lg-12 m-0 modal-header-background pr-0">
          <div className="col-12 mb-2 row px-2">
            <div className="my-auto">
              <h1 style={{ fontSize: '24px' }}>{maintenance?.ticket_number}</h1>
            </div>
            <div className="row ml-auto">
              <div>
                <button
                  onClick={() => closeModalUpdate()}
                  type="button"
                  className="btn secondary-btn ml-2"
                  data-dismiss="modal"
                >
                  <div className="fas fa-times"></div>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className=" row m-0 p-2 page-profile-footer">
          <button className="btn btn-primary" type="submit" onClick={handelSubmit}>
            <span className="button__text">Save</span>
          </button>
          <div className="pl-2 my-auto gcolor">
            <span>Last Save {moment(maintenance.modified_at).format('MMMM Do YYYY, h:mm a')}</span>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default EditPMModal;
