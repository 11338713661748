import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getMetersByAuthentication = async () => {
  const res = await http.get(
    `${BASE_URL}/meter/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of Meter by its company ID failed",
    res
  );
};
const getMetersByPagination = async (data) => {
  const res = await http.get(
    `${BASE_URL}/meter/?search=${data.SearchVal}&page=${data.currentPage}&page_size=${data.rowPerPage}`
  );
  return assert(
    res,
    res.data,
    "Retrieval of Meter by its company ID failed",
    res
  );
};

const getMeterById = async (MeterId) => {
  const res = await http.get(
    `${BASE_URL}/meter/${MeterId}/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of Meter by its ID failed",
    res
  );
};

const createMeter = async (data) => {
  const res = await http.postImg(
    `${BASE_URL}/meter/`, data
  );

  return assert(res, res.data, "Creation of Meter failed", res);
};

const updateMeter = async (data) => {
  const res = await http.putImg(
    `${BASE_URL}/meter/${data.id}/`, data.formData
  );
  return assert(res, res.data, "Update of Meter failed", res);
};
const patchMeter = async (data) => {
  const res = await http.patch(
    `${BASE_URL}/meter/${data.id}/`, data
  );
  return assert(res, res.data, "Update of Meter failed", res);
};

const deleteMeter = async (meterId) => {
  const res = await http.delete(
    `${BASE_URL}/meter/${meterId}/`
  );
  return assert(res, meterId, "Deletion of Meter failed", res);
};
const deleteMeterAttachment = async (attachId) => {
  const res = await http.delete(`${BASE_URL}/meter-attachment/${attachId}/`);
  return assert(res, attachId, 'Deletion of WorkOrder failed', res);
};
const MeterService = {
  getMetersByAuthentication,
  getMetersByPagination,
  getMeterById,
  createMeter,
  updateMeter,
  patchMeter,
  deleteMeter,
  deleteMeterAttachment
};

export default MeterService;
