import React from 'react'

function BackWordArrowIcon() {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.9998 7H1.99985M6.99985 1L1.70696 6.29289C1.31643 6.68342 1.31643 7.31658 1.70696 7.70711L6.99985 13" 
            stroke="#36383C" 
            strokeWidth="2" 
            strokeLinecap="round" />
        </svg>
    )
}

export default BackWordArrowIcon