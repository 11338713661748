import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash,
  faEnvelope,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login, resetAuth } from '../../redux/features/auth/authSlice';
import useApiCallStatusNotificationHandler from '../../utils/apiCallStatusHandler';
import './login.scss';
import foxLogo from '../../assets/img/FOX-Logo3.png';
import Protected from '../../utils/protected';
import BookDemo from '../../components/bookDemo/BookDemo';
// import IconWhatsapp from './icons/IconWhatsapp';

import { getCompanyProfile } from '../../redux/features/compay/companySlice';

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

export default function Login() {
  const history = useHistory();
  const { user, isLoading, isError, isSuccess } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isShow, setIsShow] = useState(false);
  const handleSubmit = (e) => {
    const userInfo = { username, password };
    e.preventDefault();
    dispatch(login(userInfo));
  };

  const { showSpinner } = useApiCallStatusNotificationHandler({
    isLoading,
    isError,
  });

  async function getCompanyData(){
    const company = await dispatch(getCompanyProfile())
    return company?.payload?.name
  }

  useEffect(() => {

    const redirectToMainPage = async ()=>{
      if (isSuccess) {
        const companyName = await getCompanyData()
         setTimeout(() => {
           history.push(companyName === "TNB PROPERTY SERVICES DEPARTMENT" ? "/ebq" : "/");
         }, 100)
       }
    }
    redirectToMainPage()
    
  }, [isSuccess])
  

  useEffect(() => {
    dispatch(resetAuth());
  }, [user, isError, isSuccess, isLoading, dispatch, history]);

  // Advance filter dialog start
  const [openAdvFilter, setOpenAdvFilter] = useState(false);

  const handleOpenAdvFilter = () => {
    setOpenAdvFilter(true);
  };

  const handleCloseAdvFilter = () => {
    setOpenAdvFilter(false);
  };
  // Advance filter dialog end

  return (
    <Protected>
      <BookDemo 
        handleCloseAdvFilter={handleCloseAdvFilter}
        openAdvFilter={openAdvFilter}
        handleOpenAdvFilter={handleOpenAdvFilter}
      />
      <div style={{ height: '100vh', backgroundColor: '#F5F6F7', overflowX: 'hidden' }}>
        {showSpinner ? <div id="loader" /> : null}
        <div className="pt-4 ml-5 mb-3">
          <img alt="logo" src={foxLogo} style={{ height: '50px' }} />
        </div>
        <div
          className="row justify-content-center align-items-center"
          style={{
            height: '80%',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              maxWidth: '500px',
              border: '1px solid #E1E1E1',
              borderRadius: '16px',
              padding: '40px',
            }}
          >
            <div className="mb-3">
              <div style={{ color: '#36383C', fontSize: '32px', fontWeight: '700' }}>
                Plan and Implement
              </div>
              <div style={{ color: '#727478', fontSize: '18px', fontWeight: '400' }}>
                Log in to your FOX account
              </div>
            </div>

            <div className="">
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="username"
                    style={{
                      fontWeight: '400',
                    }}
                  >
                    Email
                  </label>
                  <div className="input-group">
                    <div className="input-group-append">
                      <div className="input-group-text inline-show-password pointer">
                        <span style={{ cursor: 'pointer' }} id="eye">
                          <FontAwesomeIcon icon={faEnvelope} />
                        </span>
                      </div>
                    </div>
                    <input
                      name="username"
                      type="text"
                      className="form-control inline-username"
                      placeholder="Enter the registered email"
                      id="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-12 col-sm-12 m-0">
                    <span className="font-danger" id="username_error" />
                  </div>
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="password"
                    style={{
                      fontWeight: '400',
                    }}
                  >
                    Password
                  </label>
                  <div className="input-group mb-2">
                    <div className="input-group-append">
                      <div className="input-group-text inline-show-password pointer password-icon-left">
                        <span style={{ cursor: 'pointer' }} id="eye">
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                      </div>
                    </div>
                    <input
                      name="password"
                      value={password}
                      type={!isShow ? 'password' : 'text'}
                      className="form-control inline-password"
                      placeholder="Password"
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text inline-show-password pointer inline-show-password-right">
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => setIsShow(!isShow)}
                          title={!isShow ? 'Show password' : 'Hide password'}
                          id="eye"
                        >
                          {isShow ? (
                            <FontAwesomeIcon icon={faEye} />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}{' '}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-12 m-0">
                      <span className="font-danger" id="password_error" />
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-12 col-sm-12 m-0">
                    <button type="submit" className="btn btn-block inline-login-btn">
                      Login
                    </button>
                  </div>
                </div>

                <a href="/forgot-password">Forgot password?</a>
                <div
                  className="mt-2 mb-3"
                  style={{
                    borderBottom: '1px solid #E1E1E1',
                    width: '100%',
                  }}
                />
                <div style={{ color: '#727478' }}>
                  If you don&apos;t have any registered account,{' '}
                  <span onClick={handleOpenAdvFilter} className="login-contact">
                    contact our team
                  </span>{' '}
                  to experience FOX.
                </div>
              </form>
            </div>
            {/* <div className="footer text-center col-lg-12 mt-3 inline-footer">
              {' '}
              © 2023 Infinity Wave Sdn. Bhd.
            </div> */}
          </div>
        </div>
      </div>
    </Protected>
  );
}
