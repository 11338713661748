/* eslint-disable no-shadow */
/* eslint-disable react/jsx-curly-brace-presence */
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  getDepartmentsByAuthentication,
  reset as resetDepartment,
  deleteDepartment,
  updateDepartment,
  openDepartmentModalDelete,
  closeDepartmentModalDelete,
  openDepartmentModalUpdate,
  closeDepartmentModalUpdate,
  closeDepartmentModalCreate,
  openDepartmentModalCreate,
  setDepartment,
  createDepartment,
} from '../../../redux/features/department/departmentSlice';
import PopUpDeleteModal from '../../popUpModals/editCategory/popUpDeleteModal';
import PopUpEditModal from '../../popUpModals/editCategory/popUpEditModal';
import PopUpAddModal from '../../popUpModals/editCategory/popUpAddModal';
import {
  getManagerPositionByAuthentication,
  openManagerPositionModalCreate,
  createManagerPosition,
  closeManagerPositionModalCreate,
  closeManagerPositionModalDelete,
  closeManagerPositionModalUpdate,
  openManagerPositionModalDelete,
  deleteManagerPosition,
  openManagerPositionModalUpdate,
  setManager,
  updateManagerPosition,
  reset as resetManager,
} from '../../../redux/features/managerPosition/managerPositionSlice';
import {
  getTechnicianPositionsByAuthentication,
  openTechnicianPositionModalCreate,
  createTechnicianPosition,
  closeTechnicianPositionModalCreate,
  closeTechnicianPositionModalDelete,
  closeTechnicianPositionModalUpdate,
  openTechnicianPositionModalDelete,
  deleteTechnicianPosition,
  openTechnicianPositionModalUpdate,
  setTechnician,
  updateTechnicianPosition,
  reset as resetTechnician,
} from '../../../redux/features/technicianPosition/technicianPositionSlice';
import { setTeamType } from '../../../redux/features/editCategory/editCategorySlice';

export default function TeamsTab () {
  const {
    departments,
    departmentModalDelete,
    departmentModalUpdate,
    departmentModalCreate,
    department,
    isDepartmentCreateError,
    isDepartmentCreateLoading,
    isDepartmentCreateSuccess,
    isDepartmentDeleteError,
    isDepartmentDeleteLoading,
    isDepartmentDeleteSuccess,
    isDepartmentUpdateError,
    isDepartmentUpdateLoading,
    isDepartmentUpdateSuccess,
  } = useSelector(state => state.department);
  const {
    technicians,
    technicianPositionModalDelete,
    technicianPositionModalUpdate,
    technicianPositionModalCreate,
    technician,
    isTechnicianCreateLoading,
    isTechnicianCreateError,
    isTechnicianCreateSuccess,
    isTechnicianDeleteLoading,
    isTechnicianDeleteError,
    isTechnicianDeleteSuccess,
    isTechnicianUpdateLoading,
    isTechnicianUpdateError,
    isTechnicianUpdateSuccess,
  } = useSelector(state => state.technicianPosition);
  const {
    managers,
    managerPositionModalDelete,
    managerPositionModalUpdate,
    managerPositionModalCreate,
    manager,
    isManagerCreateLoading,
    isManagerCreateError,
    isManagerCreateSuccess,
    isManagerDeleteLoading,
    isManagerDeleteError,
    isManagerDeleteSuccess,
    isManagerUpdateLoading,
    isManagerUpdateError,
    isManagerUpdateSuccess,
  } = useSelector(state => state.managerPosition);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetTechnician());

  }, [
    technicians,
    // technicianPositionModalDelete,
    // technicianPositionModalUpdate,
    // technicianPositionModalCreate,
    technician,
    isTechnicianCreateLoading,
    isTechnicianCreateError,
    isTechnicianCreateSuccess,
    isTechnicianDeleteLoading,
    isTechnicianDeleteError,
    isTechnicianDeleteSuccess,
    isTechnicianUpdateLoading,
    isTechnicianUpdateError,
    isTechnicianUpdateSuccess,
    // dispatch
  ]);
  useEffect(() => {
    dispatch(resetDepartment());


  }, [
    departments,
    // departmentModalDelete,
    // departmentModalUpdate,
    // departmentModalCreate,
    department,
    isDepartmentCreateError,
    isDepartmentCreateLoading,
    isDepartmentCreateSuccess,
    isDepartmentDeleteError,
    isDepartmentDeleteLoading,
    isDepartmentDeleteSuccess,
    isDepartmentUpdateError,
    isDepartmentUpdateLoading,
    isDepartmentUpdateSuccess,
  ]);
  useEffect(() => {
    dispatch(resetManager());
  }, [
    managers,
    // managerPositionModalDelete,
    // managerPositionModalUpdate,
    // managerPositionModalCreate,
    manager,
    isManagerCreateLoading,
    isManagerCreateError,
    isManagerCreateSuccess,
    isManagerDeleteLoading,
    isManagerDeleteError,
    isManagerDeleteSuccess,
    isManagerUpdateLoading,
    isManagerUpdateError,
    isManagerUpdateSuccess,
  ]);

  const { teamType } = useSelector(state => state.editCategory);
  const [departmentData, setDepartmentData] = useState(null);
  const [technicianData, setTechnicianData] = useState(null);
  const [managerData, setManagerData] = useState(null);

  const handleConfirm = (id, type) => {
    if (type === 'department') {
      dispatch(openDepartmentModalDelete());
      dispatch(setDepartment(id));
    }
    if (type === 'technician') {
      dispatch(openTechnicianPositionModalDelete());
      dispatch(setTechnician(id));
    } else if (type === 'manager') {
      dispatch(openManagerPositionModalDelete());
      dispatch(setManager(id));
    }
  };
  const handleDelete = () => {
    if (teamType === 'department') {
      dispatch(deleteDepartment(department));
      dispatch(closeDepartmentModalDelete());
    } else if (teamType === 'technician') {
      dispatch(deleteTechnicianPosition(technician));
      dispatch(closeTechnicianPositionModalDelete());
    } else if (teamType === 'manager') {
      dispatch(deleteManagerPosition(manager));
      dispatch(closeManagerPositionModalDelete());
    }
  };
  const handleEdit = (data, type) => {
    if (type === 'department') {
      dispatch(openDepartmentModalUpdate());
      dispatch(setDepartment(data.id));
      setDepartmentData(data);
    }
    if (type === 'technician') {
      dispatch(openTechnicianPositionModalUpdate());
      dispatch(setTechnician(data.id));
      setTechnicianData(data);
    }
    if (type === 'manager') {
      dispatch(openManagerPositionModalUpdate());
      dispatch(setManager(data.id));
      setManagerData(data);
    }
  };

  const handleEditSave = () => {
    if (teamType === 'department') {
      dispatch(updateDepartment({ id: department, name: departmentData.name }));
      dispatch(closeDepartmentModalUpdate());
    }
    if (teamType === 'technician') {
      dispatch(
        updateTechnicianPosition({
          id: technician,
          name: technicianData.name,
        }),
      );
      dispatch(closeTechnicianPositionModalUpdate());
    }
    if (teamType === 'manager') {
      dispatch(updateManagerPosition({ id: manager, name: managerData.name }));
      dispatch(closeManagerPositionModalUpdate());
    }
  };

  const handleCreate = type => {
    if (type === 'department') {
      dispatch(openDepartmentModalCreate());
    } else if (type === 'technician') {
      dispatch(openTechnicianPositionModalCreate());
    } else if (type === 'manager') {
      dispatch(openManagerPositionModalCreate());
    }
  };

  const handleCreateSave = () => {
    if (teamType === 'department') {
      dispatch(createDepartment({ name: departmentData.name }));
      dispatch(closeDepartmentModalCreate());
      dispatch(resetDepartment());
    } else if (teamType === 'technician') {
      dispatch(createTechnicianPosition({ name: technicianData.name }));
      dispatch(closeTechnicianPositionModalCreate());
      dispatch(resetTechnician());
    } else if (teamType === 'manager') {
      dispatch(createManagerPosition({ name: managerData.name }));
      dispatch(closeManagerPositionModalCreate());
      dispatch(resetManager());
    }
  };

  useEffect(() => {
    dispatch(resetDepartment());
    dispatch(resetTechnician());
    dispatch(resetManager());
    dispatch(getDepartmentsByAuthentication());
    dispatch(getTechnicianPositionsByAuthentication());
    dispatch(getManagerPositionByAuthentication());
  }, [dispatch]);

  function displayToast () {
    if (isDepartmentCreateError) {
      toast.error('Something went wrong with creating the department', {
        toastId: 'departmentCreateError',
      });
    } else if (isDepartmentDeleteError) {
      toast.error('Something went wrong with deleting the department', {
        toastId: 'departmentDeleteError',
      });
    } else if (isDepartmentUpdateError) {
      toast.error('Something went wrong with updating the department', {
        toastId: 'departmentUpdateError',
      });
    } else if (isTechnicianCreateError) {
      toast.error('Something went wrong with creating the technician', {
        toastId: 'technicianCreateError',
      });
    } else if (isTechnicianDeleteError) {
      toast.error('Something went wrong with deleting the technician', {
        toastId: 'technicianDeleteError',
      });
    } else if (isTechnicianUpdateError) {
      toast.error('Something went wrong with updating the technician', {
        toastId: 'technicianUpdateError',
      });
    } else if (isManagerCreateError) {
      toast.error('Something went wrong with creating the manager', {
        toastId: 'managerCreateError',
      });
    } else if (isManagerDeleteError) {
      toast.error('Something went wrong with deleting the manager', {
        toastId: 'managerDeleteError',
      });
    } else if (isManagerUpdateError) {
      toast.error('Something went wrong with updating the manager', {
        toastId: 'managerUpdateError',
      });
    } else if (isDepartmentCreateSuccess) {
      toast.success('Department created successfully', {
        toastId: 'departmentCreateSuccess',
      });
    } else if (isDepartmentDeleteSuccess) {
      toast.success('Department deleted successfully', {
        toastId: 'departmentDeleteSuccess',
      });
    } else if (isDepartmentUpdateSuccess) {
      toast.success('Department updated successfully', {
        toastId: 'departmentUpdateSuccess',
      });
    } else if (isTechnicianCreateSuccess) {
      toast.success('Technician created successfully', {
        toastId: 'technicianCreateSuccess',
      });
    } else if (isTechnicianDeleteSuccess) {
      toast.success('Technician deleted successfully', {
        toastId: 'technicianDeleteSuccess',
      });
    } else if (isTechnicianUpdateSuccess) {
      toast.success('Technician updated successfully', {
        toastId: 'technicianUpdateSuccess',
      });
    } else if (isManagerCreateSuccess) {
      toast.success('Manager created successfully', {
        toastId: 'managerCreateSuccess',
      });
    } else if (isManagerDeleteSuccess) {
      toast.success('Manager deleted successfully', {
        toastId: 'managerDeleteSuccess',
      });
    } else if (isManagerUpdateSuccess) {
      toast.success('Manager updated successfully', {
        toastId: 'managerUpdateSuccess',
      });
    }
  }

  return isDepartmentCreateLoading ||
    isDepartmentDeleteLoading ||
    isDepartmentUpdateLoading ||
    isTechnicianCreateLoading ||
    isTechnicianDeleteLoading ||
    isTechnicianUpdateLoading ||
    isManagerCreateLoading ||
    isManagerDeleteLoading ||
    isManagerUpdateLoading ? (
    <div id="loader" />
  ) : (
    <>
      {displayToast()}
      <h3 className="card-title py-3 px-3 col-lg-12">Teams Fieldset</h3>
      <div className="card-body p-0 m-0">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title edit-category-title-inline">
                  <span>
                    <b>Department</b>
                    <i>(Option select)</i>
                  </span>
                </span>
                <div className="card-tools">
                  <button
                    type="button"
                    className="mr-2 cursor_pointer"
                    title="Add Department"
                    onClick={() => {
                      dispatch(setTeamType('department'));
                      handleCreate('department');
                    }}
                  >
                    Add Department
                  </button>
                </div>
              </div>
              {departmentModalUpdate && (
                <PopUpEditModal
                  handleClose={() => dispatch(closeDepartmentModalUpdate())}
                  showModal={departmentModalUpdate}
                  handleSave={handleEditSave}
                  modalTitle="Edit Department"
                  primaryButtonText="Save Changes"
                  modalBodyTextInput={departmentData.name}
                  value={departmentData && departmentData.name}
                  key={departmentData?.id}
                  onChange={e => {
                    setDepartmentData({
                      ...departmentData,
                      name: e.target.value,
                    });
                  }}
                />
              )}
              {managerPositionModalUpdate && (
                <PopUpEditModal
                  handleClose={() => dispatch(closeManagerPositionModalUpdate())}
                  showModal={managerPositionModalUpdate}
                  handleSave={handleEditSave}
                  key={managerData?.id}
                  modalTitle="Edit Manager"
                  primaryButtonText="Save Changes"
                  modalBodyTextInput={managerData.name}
                  value={managerData && managerData.name}
                  onChange={e => {
                    setManagerData({
                      ...managerData,
                      name: e.target.value,
                    });
                  }}
                />
              )}
              {technicianPositionModalUpdate && (
                <PopUpEditModal
                  handleClose={() => dispatch(closeTechnicianPositionModalUpdate())}
                  showModal={technicianPositionModalUpdate}
                  handleSave={handleEditSave}
                  modalTitle="Edit Technician"
                  primaryButtonText="Save Changes"
                  modalBodyTextInput={technicianData.name}
                  value={technicianData && technicianData.name}
                  key={technicianData?.id}
                  onChange={e => {
                    setTechnicianData({
                      ...technicianData,
                      name: e.target.value,
                    });
                  }}
                />
              )}
              {departmentModalDelete && (
                <PopUpDeleteModal
                  showModal={departmentModalDelete}
                  handleClose={() => dispatch(closeDepartmentModalDelete())}
                  handleConfirm={handleDelete}
                  modalTitle="Delete Department"
                  key={departmentData?.id}
                  modalBodyText="Are you sure you want to delete this department?"
                />
              )}
              {managerPositionModalDelete && (
                <PopUpDeleteModal
                  showModal={managerPositionModalDelete}
                  handleClose={() => dispatch(closeManagerPositionModalDelete())}
                  handleConfirm={handleDelete}
                  modalTitle="Delete Manager"
                  key={managerData?.id}
                  modalBodyText="Are you sure you want to delete this manager?"
                />
              )}
              {technicianPositionModalDelete && (
                <PopUpDeleteModal
                  key={technicianData?.id}
                  showModal={technicianPositionModalDelete}
                  handleClose={() => dispatch(closeTechnicianPositionModalDelete())}
                  handleConfirm={handleDelete}
                  modalTitle="Delete Technician"
                  modalBodyText="Are you sure you want to delete this technician?"
                />
              )}

              {teamType === 'department' && departmentModalCreate && (
                <PopUpAddModal
                  key={departmentData?.id}
                  showModal={departmentModalCreate}
                  handleClose={() => dispatch(closeDepartmentModalCreate())}
                  modalTitle="Add Department"
                  primaryButtonText="Create Department"
                  value={departmentData && departmentData.name}
                  onChange={e => {
                    setDepartmentData({
                      ...departmentData,
                      name: e.target.value,
                    });
                  }}
                  handleCreateSave={handleCreateSave}
                >
                  <div className="col">
                    <div className="row">
                      <input type="hidden" id="department_id" value="" />
                      <span className="font-weight-normal">
                        Name <span className="font-danger m-0">*</span>
                      </span>
                      <input
                        name="department_name"
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter department name"
                        // value={departmentData?.name}
                        onChange={e =>
                          setDepartmentData({
                            ...departmentData,
                            name: e.target.value,
                          })
                        }
                      />
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="department_name_error" />
                      </div>
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="department_error_msg" />
                      </div>
                    </div>
                  </div>
                </PopUpAddModal>
              )}
              {teamType === 'technician' && technicianPositionModalCreate && (
                <PopUpAddModal
                  showModal={technicianPositionModalCreate}
                  handleClose={() => dispatch(closeTechnicianPositionModalCreate())}
                  modalTitle="Add Technician Position"
                  primaryButtonText="Create Technician Position"
                  value={technicianData && technicianData.name}
                  onChange={e => {
                    setTechnicianData({
                      ...technicianData,
                      name: e.target.value,
                    });
                  }}
                  handleCreateSave={handleCreateSave}
                >
                  <div className="col">
                    <div className="row">
                      <input type="hidden" id="department_id" value="" />
                      <span className="font-weight-normal">
                        Name <span className="font-danger m-0">*</span>
                      </span>
                      <input
                        name="department_name"
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter technician position name"
                        // value={technicianData?.name}
                        onChange={e =>
                          setTechnicianData({
                            ...technicianData,
                            name: e.target.value,
                          })
                        }
                      />
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="department_name_error" />
                      </div>
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="department_error_msg" />
                      </div>
                    </div>
                  </div>
                </PopUpAddModal>
              )}
              {teamType === 'manager' && managerPositionModalCreate && (
                <PopUpAddModal
                  showModal={managerPositionModalCreate}
                  handleClose={() => dispatch(closeManagerPositionModalCreate())}
                  modalTitle="Add Manager Position"
                  primaryButtonText="Create Manager Position"
                  value={managerData && managerData.name}
                  onChange={e => {
                    setManagerData({
                      ...managerData,
                      name: e.target.value,
                    });
                  }}
                  handleCreateSave={handleCreateSave}
                >
                  <div className="col">
                    <div className="row">
                      <input type="hidden" id="department_id" value="" />
                      <span className="font-weight-normal">
                        Name <span className="font-danger m-0">*</span>
                      </span>
                      <input
                        name="department_name"
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter position name"
                        // value={managerData?.name}
                        onChange={e =>
                          setManagerData({
                            ...managerData,
                            name: e.target.value,
                          })
                        }
                      />
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="department_name_error" />
                      </div>
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="department_error_msg" />
                      </div>
                    </div>
                  </div>
                </PopUpAddModal>
              )}
              <div className="card-body p-0">
                {departments && departments.length > 0 ? (
                  // eslint-disable-next-line no-shadow
                  departments.map(department => (
                    <div
                      className="card edit-category-card-inline"
                      id="department-block-{{department.id}}"
                    >
                      <div className="card-header">
                        <span className="card-title edit-category-card-title-inline">
                          <span
                            title="{{department.department}}"
                            data-target="#modal-add-department"
                            data-toggle="modal"
                          >
                            <b id="department-name-value-{{department.id}}">{department.name}</b>
                          </span>
                        </span>
                        {/* <div className="card-tools">
                          <button
                            className="fa fa-edit m-1 cursor_pointer"
                            title="Edit Department"
                            type="button"
                            onClick={() => {
                              dispatch(setTeamType('department'));
                              handleEdit(department, 'department');
                            }}
                          >
                            {' '}
                          </button>
                          <button
                            className="fa fa-trash m-1 font-danger cursor_pointer"
                            title="Delete Department"
                            type="button"
                            onClick={() => {
                              dispatch(setTeamType('department'));
                              handleConfirm(department.id, 'department');
                            }}
                          >
                            {' '}
                          </button>
                        </div> */}

                        <div className="card-tools">
                          {department?.is_changeable ? (
                            <span>
                              <button
                                className="fa fa-edit m-1 cursor_pointer"
                                title="Edit Status"
                                data-toggle="modal"
                                data-target="#modal-add-type"
                                type="button"
                                onClick={() => {
                                  dispatch(setTeamType('department'));
                                  handleEdit(department, 'department');
                                }}
                              >
                                {' '}
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  dispatch(setTeamType('department'));
                                  handleConfirm(department.id, 'department');
                                }}
                              >
                                <i
                                  className="fa fa-trash m-1 font-danger cursor_pointer"
                                  title="Delete Status"
                                  data-target="#modal-delete-type"
                                  data-toggle="modal"
                                />
                              </button>
                            </span>
                          ) : (
                            <i className="fa fa-lock text-black" />
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="card edit-category-card-inline custom-card">
                    <div className="card-header">
                      <span className="card-title edit-category-title-inline">
                        <span title="No Work Order Category">
                          <b>No department Category</b>
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                <input
                  type="hidden"
                  id="department-name-{{department.id}}"
                  value="{{department.department}}"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title edit-category-title-inline">
                  <span>
                    <b>Manager Position</b>
                    <i>(Option select)</i>
                  </span>
                </span>
                <div className="card-tools">
                  <button
                    className="mr-2 cursor_pointer"
                    title="Add Position"
                    type="button"
                    onClick={() => {
                      dispatch(setTeamType('manager'));
                      handleCreate('manager');
                    }}
                  >
                    Add Position
                  </button>
                </div>
              </div>
              <div className="card-body p-0 ">
                <div className="card-body p-0 m-0" id="manager_position-block">
                  {managers && managers.length > 0 ? (
                    managers.map(manager => (
                      <div
                        className="card edit-category-card-inline"
                        id={`manager_position-block-${manager.id}`}
                      >
                        <div className="card-header">
                          <span className="card-title edit-category-card-title-inline">
                            <span
                              title={`${manager.name}`}
                              data-target="#modal-add-manager_position"
                              data-toggle="modal"
                            >
                              <b id={`manager_position-name-value-${manager.id}`}>{manager.name}</b>
                            </span>
                          </span>
                          <div className="card-tools">
                            <button
                              type="button"
                              className="fa fa-edit m-1 cursor_pointer"
                              title="Edit Position"
                              data-toggle="modal"
                              data-target="#modal-add-manager_position"
                              onClick={() => {
                                dispatch(setTeamType('manager'));
                                handleEdit(manager, 'manager');
                              }}
                            >
                              {' '}
                            </button>
                            <button
                              type="button"
                              className="fa fa-trash m-1 font-danger cursor_pointer"
                              title="Delete Position"
                              data-target="#modal-delete-manager_position"
                              data-toggle="modal"
                              onClick={() => {
                                dispatch(setTeamType('manager'));
                                handleConfirm(manager.id, 'manager');
                              }}
                            >
                              {' '}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="card edit-category-card-inline">
                      <div className="card-header">
                        <span className="card-title edit-category-title-inline">
                          <span title="No Work Order Category">
                            <b>No Manager Position</b>
                          </span>
                        </span>
                      </div>
                    </div>
                  )}
                  <input
                    type="hidden"
                    id="manager_position-name-{{manager_position.id}}"
                    value="{{manager_position.position}}"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title">
                  <b>Technician Position</b>
                  <i>(Option select)</i>
                </span>
                <div className="card-tools">
                  <button
                    type="button"
                    className="mr-2 cursor_pointer"
                    title="Add Position"
                    data-target="#modal-add-technician_position"
                    data-toggle="modal"
                    onClick={() => {
                      dispatch(setTeamType('technician'));
                      handleCreate('technician');
                    }}
                  >
                    Add Position
                  </button>
                </div>
              </div>
              <div className="card-body p-0 ">
                <div className="card-body p-0 m-0" id="technician_position-block">
                  {technicians && technicians.length > 0 ? (
                    technicians?.map(technicianPosition => (
                      <div
                        className="card edit-category-card-inline"
                        id={`technician_position-block-${technicianPosition.id}`}
                      >
                        <div className="card-header">
                          <span className="card-title edit-category-card-title-inline">
                            <span title={technicianPosition.name}>
                              <b id={`technician_position-name-value-${technicianPosition.id}`}>
                                {technicianPosition.name}
                              </b>
                            </span>
                          </span>
                          <div className="card-tools">
                            <button
                              type="button"
                              className="fa fa-edit m-1 cursor_pointer"
                              title="Edit Position"
                              data-target="#modal-add-technician_position"
                              data-toggle="modal"
                              onClick={() => {
                                dispatch(setTeamType('technician'));
                                handleEdit(technicianPosition, 'technician');
                              }}
                            >
                              {' '}
                            </button>
                            <button
                              type="button"
                              className="fa fa-trash m-1 font-danger cursor_pointer"
                              title="Delete Position"
                              data-target="#modal-delete-technician_position"
                              data-toggle="modal"
                              onClick={() => {
                                dispatch(setTeamType('technician'));
                                handleConfirm(technicianPosition.id, 'technician');
                              }}
                            >
                              {' '}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="card edit-category-card-inline">
                      <div className="card-header">
                        <span className="card-title edit-category-title-inline">
                          <span title="No Work Order Category">
                            <b>No Technician Position</b>
                          </span>
                        </span>
                      </div>
                    </div>
                  )}
                  <input
                    type="hidden"
                    id="technician_position-name-{{technician_position.id}}"
                    value="{{technician_position.position}}"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
