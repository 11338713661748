import React, { useState } from 'react'
import { Drawer } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenSurchargeModal, setSurchargeData } from '../../redux/features/SOR/SORSlice';
import PlusIcon from '../../assets/svg-icons/PlusIcon';
import DeleteIcon from '../../assets/svg-icons/DeleteIcon';

function AddNewSurchargeModal() {
    const { openSurchargeModal, billData, surchargeData } = useSelector(state => state.SOR);
    const [surchargeList, setSurchargeList] = useState([])
    const [code, setCode] = useState("")
    const dispatch = useDispatch();

    const uniqueSources = [...new Set(billData.map(item => item.source))];

    const close = () => {
        dispatch(setOpenSurchargeModal(false))
    }    
    const hadelAddNewData = () => {
        const surchargeItem = { code, description: "", rate: 0 }
        if (code && surchargeList.length === 0) {
            setSurchargeList([...surchargeList, surchargeItem])
        } else if (!code) {
            toast.warning('Please fill Code / Kod field first', {
                toastId: 'CreatedEventWarning',
            });
        } else if (surchargeList.every(item => item.description && item.rate >= 0)) {
            setSurchargeList([...surchargeList, surchargeItem])
        } else {
            toast.warning('Please fill Surcharged description and Rate fields first', {
                toastId: 'CreatedEventWarning',
            });
        }
    }


    const handleDelete = (index) => {
        const updatedSurcharge = [...surchargeList]
        updatedSurcharge.splice(index, 1)
        setSurchargeList(updatedSurcharge)
    }

    const handleChange = (e, index) => {
        const { value, name } = e.target
        const updatedSurcharge = [...surchargeList]
        updatedSurcharge[index][name] = value
        setSurchargeList(updatedSurcharge)
    }


    const handleSave = () => {
        if (code && surchargeList.length > 0 && surchargeList.every(item => item.description && item.rate >= 0)) {
            dispatch(setSurchargeData([...surchargeData, ...surchargeList]))
            close()
        }else{
            toast.warning('Please fill Code / Kod and add Surcharge data or close the pop-up without saving', {
                toastId: 'CreatedEventWarning',
            });
        }
    }


    return (
        <Drawer anchor="right" open={openSurchargeModal} onClose={close}
            PaperProps={{
                style: {
                    width: '100%',
                    maxWidth: '600px',
                    height: '100%',
                },
            }} >
            <div className="h-100 w-100"
            >
                <div className="col-lg-12 col-sm-12 pb-5" id="">
                    <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">
                        <div className="col-12 row px-2 ">
                            <div className="my-auto">
                                <b>Surcharge</b>
                            </div>
                            <div className="row ml-auto">
                                <button
                                    type="button"
                                    className="btn tertiary-btn ml-2"
                                    data-dismiss="modal"
                                    onClick={close}
                                >
                                    <div className="fas fa-times" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal ">Code / <span style={{ color: "#727478" }}>Kod</span> <label className="font-danger m-0">*</label></label>
                        <select
                            value={code}
                            className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                            onChange={(e) => setCode(e.target.value)}
                        >
                            <option value="" disabled selected>
                                --SELECT--
                            </option>
                            {uniqueSources?.map((val) => (
                                <option key={val} value={val}>
                                    {val}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-lg-12 col-sm-12 pt-3  choose-assets-location">
                        <div> {surchargeList?.length} Surcharge registered</div>
                        <button type='button'
                            onClick={hadelAddNewData}
                        >
                            <span className='mr-2'><PlusIcon color="#D9314A" /> </span> Add new
                        </button>
                    </div>

                    {surchargeList.map((item, index) => (
                        <div className='card-add-new'>
                            <div className='heading-of-card-add-new'>
                                <h6> Surcharged #{index + 1} </h6>
                                <button type='button' onClick={() => handleDelete(index)}><DeleteIcon /></button>
                            </div>
                            <div className='row'>
                                <div className="col-lg-12 col-sm-12 pt-2">
                                    <div className="col-12 p-0">
                                        <label className="font-weight-normal">Surcharged description<label className="font-danger m-0">*</label></label>
                                        <textarea
                                            name='description'
                                            placeholder='Add description'
                                            className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                                            value={item.description}
                                            onChange={(e) => handleChange(e, index)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-12 pt-2">
                                    <div className="col-12 p-0">
                                        <label className="font-weight-normal">Rate (%)<label className="font-danger m-0">*</label></label>
                                        <input
                                            name='rate'
                                            placeholder='Set rate'
                                            className='block w-full px-2 py-2 bg-white border border-gray-300 rounded-md '
                                            type='number'
                                            min="0"
                                            value={item.rate}
                                            onChange={(e) => handleChange(e, index)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
                <div className=" m-0 p-2 page-profile-footer">
                    <button onClick={handleSave} className="btn primary-btn" type="submit">
                        Save
                    </button>
                </div>
            </div>
        </Drawer>
    )
}

export default AddNewSurchargeModal