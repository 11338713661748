import React from 'react';

export default function MeterIcon({ fillColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M3.66675 13.6667H14.3334M9.00008 11C10.4728 11 11.6667 12.1939 11.6667 13.6667M9.00008 11C7.52732 11 6.33341 12.1939 6.33341 13.6667M9.00008 11L7.66675 7.66666M3.66675 9H4.33341M13.6667 9H14.3334M9.00008 3.66666V4.33333M12.6667 5.33333L12.0001 6M5.33341 5.33333L6.00008 6M16.3334 9C16.3334 13.0501 13.0502 16.3333 9.00008 16.3333C4.94999 16.3333 1.66675 13.0501 1.66675 9C1.66675 4.94991 4.94999 1.66666 9.00008 1.66666C13.0502 1.66666 16.3334 4.94991 16.3334 9Z"
        stroke={fillColor === "primary" ? "var(--primary-color)" : "var(--grey-content)"}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
