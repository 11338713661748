import React, { useState } from 'react'
import { toast } from "react-toastify"
import { useDispatch, useSelector } from 'react-redux'
import deleteIcon from "../../../assets/img/delete.png"
import editIcon from "../../../assets/img/edit.png"
import correctIcon from "../../../assets/img/correct.png"
import wrongIcon from "../../../assets/img/wrong.png"
import { SelectLocationFilter, SelectStaff } from '../../select/select'
import { updateWorkOrderValidators } from '../../../redux/features/workOrderValidator/workOrderValidatorSlice'
import { setCategoryType } from '../../../redux/features/editCategory/editCategorySlice'

function ValidatorTable({ item , handleConfirm}) {
    const { teams } = useSelector(state => state.teams);
    const { locations } = useSelector(state => state.location);
    const dispatch = useDispatch()

    const [isEdit, setIsEdit] = useState(false)
    const [location, setLocation] = useState(null)
    const [person, setPerson] = useState(null)
    const [active, setActive] = useState(false)

    const handelEditValidator = () => {
        setIsEdit(true)
        setActive(item.is_active)
        setLocation({ label: item.location.site, value: item.location.id })
        setPerson({ label: item.user.name, value: item.user.id })
    }

    const handelSaveValidator = async () => {
        const validatorObj = {
            id: item?.id,
            location: location?.value,
            user: person?.value,
            is_active: active,
        }
        if (location?.value && person?.value) {
            // if (checkLocationExist(location?.value)) {
            //     toast.warning('This location already have authorized person ', {
            //         toastId: 'WorkOrder vaildator',
            //     });
            //     return;
            // }
            await dispatch(updateWorkOrderValidators(validatorObj))
        } else {
            toast.warning('Please fill the location and pic name and try again', {
                toastId: 'WorkOrder vaildator',
            });
        }
    }



    return (
        !isEdit ? <tr key={item?.location}>
            <td>{item.location.site}</td>
            <td>{item.user.name}</td>
            <td>
                <div className="col-lg-12 col-sm-12 pt-2">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>
                            {item?.is_active ? <img src={correctIcon} alt="Correct Icon" /> : <img src={wrongIcon} alt="Wrong Icon" />}
                        </span>
                    </div>
                </div>
            </td>
            <td>
                <button type='button' className='mr-2' onClick={() => handelEditValidator(item)}>
                    <img src={editIcon} alt="Edit Icon" />
                </button>
                <button type='button'
                    // onClick={() => handeDeleteValidator(item)}
                    onClick={() => {
                        dispatch(setCategoryType('workOrder-vaildator'));
                        handleConfirm({
                            id: item.id,
                        }, 'workOrder-vaildator');
                    }}
                >
                    <img src={deleteIcon} alt="Delete Icon" />
                </button>
            </td>
        </tr> : <tr>
            <td>
                <SelectLocationFilter locations={locations} site={location} setLocation={setLocation} validator validatorComp />
            </td>
            <td>
                <SelectStaff teams={teams?.results} staff={person} setStaff={setPerson} validator validatorComp />
            </td>
            <td>
                <div className="col-lg-12 col-sm-12 pt-2">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span>
                            <input type="checkbox" className='cost-status'
                                onChange={() => setActive(!active)} checked={active} />
                        </span>
                    </div>
                </div>
            </td>
            <td>
                <button type='button' className='mr-2' onClick={handelSaveValidator}>Save</button>
                <button type='button' onClick={() => setIsEdit(false)}>X</button>
            </td>
        </tr>
    )
}

export default ValidatorTable