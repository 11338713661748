import React from 'react'
// import { useSelector } from 'react-redux';
// import moment from "moment";
import classes from "../addAsset/addAssetForm/assetForm.module.css"
import Card from "../../../Ui/Card";

function FetchAssetFinanceForm({assetFinanceForm , handelChangeFinanceForm}) {
  // const asset = useSelector(state => state.asset?.asset)
  
  return (
      <div className={classes.assetForm}>
       <Card> 
        <div className={classes.assetInfo}>
        <h3>Finance</h3>
      <div className={classes.controls}>
       <div className={classes.control}>
          <p>
          Purchase Price<span>*</span> 
          </p>
        <input 
        onChange={handelChangeFinanceForm} 
        name="purchased_price" 
        value={assetFinanceForm.purchased_price}
        type="text" placeholder='RM 0.00'  />
        </div>
        <div className={classes.control}>
          <p>
          Purchase Date<span>*</span> 
          </p>
        <input onChange={handelChangeFinanceForm}
        name="purchased_date"
         value={ assetFinanceForm.purchased_date}
         type="date" />
        </div>
        <div className={classes.control}>
          <p>
          Vendor
          </p>
        <input onChange={handelChangeFinanceForm}
          name="vendor"
         value={  assetFinanceForm.vendor}
         type="text" 
         placeholder='Vendor Name'  />
        </div>
        </div>
        <div className={classes.controls}>
        <div className={classes.control}>
          <p>
          Warranty Expiration
          </p>
        <input onChange={handelChangeFinanceForm} 
        name="warranty_expiration"
        value={assetFinanceForm.warranty_expiration}
        type="date" />
        </div>
        <div className={classes.control}>
          <p>
          Purchase Order
          </p>
        <input onChange={handelChangeFinanceForm}
         name="po_number"
         defaultValue={assetFinanceForm.po_number}
         type="text" 
         placeholder='Purchase Order Number'  />
        </div>
       </div>
      </div> 
      </Card>
    </div>
  )
}

export default FetchAssetFinanceForm