// write everything here similar to the rest of the redux stores in the project.
import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getAssetStatusByAuthentication = async () => {
  const res = await http.get(
    `${BASE_URL}/asset-status/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of asset status by its company ID failed",
    res
  );
};

const getAssetStatusById = async (assetStatusId) => {
  const res = await http.get(
    `${BASE_URL}/asset-status/${assetStatusId}`
  );

  return assert(
    res,
    res.data,
    "Retrieval of asset status by its ID failed",
    res
  );
};

const createAssetStatus = async (data) => {
  const res = await http.post(
    `${BASE_URL}/asset-status/`,
    data
  );

  return assert(res, res.data, "Creation of asset status failed", res);
};

const updateAssetStatus = async (data) => {
  const res = await http.put(
    `${BASE_URL}/asset-status/${data.id}/`,
    data
  );
  return assert(res, res.data, "Update of asset status failed", res);
};

const deleteAssetStatus = async (assetStatusId) => {
  const res = await http.delete(
    `${BASE_URL}/asset-status/${assetStatusId}/`
  );
  return assert(res, assetStatusId, "Deletion of asset status failed", res);
};

const assetStatusService = {
  getAssetStatusByAuthentication,
  getAssetStatusById,
  createAssetStatus,
  updateAssetStatus,
  deleteAssetStatus,
};

export default assetStatusService;
